import LoginResult from "./LoginResult";
import Env from "./Env";
import { ApolloClient } from "@apollo/client";
import * as __d3ett from "../rocket/D3ETemplate";
import { D3ETemplate } from "../rocket/D3ETemplate";
import LeadsList from "./LeadsList";
import FindApolloId from "./FindApolloId";
import GraphQLClientInit from "../utils/GraphQLClientInit";
import LocalDataStore from "../utils/LocalDataStore";
import User from "../models/User";
import LeadInteractions from "./LeadInteractions";
import MessageDispatch from "../rocket/MessageDispatch";
import UsersList from "./UsersList";
import LeadAssignment from "../models/LeadAssignment";
import FindApolloIdRequest from "../models/FindApolloIdRequest";
import LeadsListRequest from "../models/LeadsListRequest";
import Lead from "../models/Lead";
import LeadAssignedUsersRequest from "../models/LeadAssignedUsersRequest";
import Interactions from "./Interactions";
import InteractionsRequest from "../models/InteractionsRequest";
import LeadsAssignedListRequest from "../models/LeadsAssignedListRequest";
import LeadInteractionsRequest from "../models/LeadInteractionsRequest";
import LeadAssignedUsers from "./LeadAssignedUsers";
import ActiveTwilioNumbersRequest from "../models/ActiveTwilioNumbersRequest";
import ReferenceCatch from "../utils/ReferenceCatch";
import LeadsAssignedList from "./LeadsAssignedList";
import ActiveTwilioNumbers from "./ActiveTwilioNumbers";
import MailBox from "./MailBox";
import MailBoxRequest from "../models/MailBoxRequest";
import RecentCreatedLeads from "./RecentCreatedLeads";
import LeadMailBoxRequest from "../models/LeadMailBoxRequest";
import UsersListRequest from "../models/UsersListRequest";
import BaseUser from "../models/BaseUser";
import Interaction from "../models/Interaction";
import RecentCreatedLeadsRequest from "../models/RecentCreatedLeadsRequest";
import LeadMailBox from "./LeadMailBox";
import MailMessage from "../models/MailMessage";

export default class Query {
  private _client: ApolloClient<any>;
  private static _queryObject: Query;
  private _referenceCatch: ReferenceCatch;
  private constructor() {}
  private static _init() {
    let q = new Query();

    q._client = GraphQLClientInit.get();

    q._referenceCatch = ReferenceCatch.get();

    return q;
  }
  public static get() {
    if (Query._queryObject == null) {
      Query._queryObject = Query._init();
    }

    return Query._queryObject;
  }
  public async currentUser(): Promise<BaseUser> {
    GraphQLClientInit.token = await LocalDataStore.get().getToken();

    return await LocalDataStore.get().currentUser();
  }
  public async logout(): Promise<boolean> {
    LocalDataStore.get().setUser(null, null);

    await MessageDispatch.get().logout();

    return true;
  }
  public async getInteractionById(
    usage: number,
    id: number
  ): Promise<Interaction> {
    return MessageDispatch.get().query(__d3ett.INTERACTION, id, usage);
  }
  public async getLeadById(usage: number, id: number): Promise<Lead> {
    return MessageDispatch.get().query(__d3ett.LEAD, id, usage);
  }
  public async getUserById(usage: number, id: number): Promise<User> {
    return MessageDispatch.get().query(__d3ett.USER, id, usage);
  }
  public async loginManagerUserWithEmailAndPassword(
    usage: number,
    d3eParams?: Partial<{
      email: string;
      password: string;
      deviceToken: string;
    }>
  ): Promise<LoginResult> {
    let email = d3eParams?.email;

    let password = d3eParams?.password;

    let deviceToken = d3eParams?.deviceToken;

    return MessageDispatch.get().login(
      "loginManagerUserWithEmailAndPassword",
      usage,
      { email: email, password: password, deviceToken: deviceToken }
    );
  }
  public async loginWithOTP(
    usage: number,
    d3eParams?: Partial<{ token: string; code: string; deviceToken: string }>
  ): Promise<LoginResult> {
    let token = d3eParams?.token;

    let code = d3eParams?.code;

    let deviceToken = d3eParams?.deviceToken;

    return MessageDispatch.get().login("loginWithOTP", usage, {
      token: token,
      code: code,
      deviceToken: deviceToken,
    });
  }
  public async getActiveTwilioNumbers(
    usage: number,
    request: ActiveTwilioNumbersRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<ActiveTwilioNumbers> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "ActiveTwilioNumbers",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getFindApolloId(
    usage: number,
    request: FindApolloIdRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<FindApolloId> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "FindApolloId",
      usage,
      true,
      request
    );
  }
  public async getInteractions(
    usage: number,
    request: InteractionsRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<Interactions> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "Interactions",
      usage,
      true,
      request
    );
  }
  public async getLeadAssignedUsers(
    usage: number,
    request: LeadAssignedUsersRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<LeadAssignedUsers> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "LeadAssignedUsers",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getLeadInteractions(
    usage: number,
    request: LeadInteractionsRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<LeadInteractions> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "LeadInteractions",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getLeadMailBox(
    usage: number,
    request: LeadMailBoxRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<LeadMailBox> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "LeadMailBox",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getLeadsAssignedList(
    usage: number,
    request: LeadsAssignedListRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<LeadsAssignedList> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "LeadsAssignedList",
      usage,
      true,
      request
    );
  }
  public async getLeadsList(
    usage: number,
    request: LeadsListRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<LeadsList> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery("LeadsList", usage, true, request);
  }
  public async getMailBox(
    usage: number,
    request: MailBoxRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<MailBox> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery("MailBox", usage, true, request, {
      synchronize: synchronize,
    });
  }
  public async getRecentCreatedLeads(
    usage: number,
    request: RecentCreatedLeadsRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<RecentCreatedLeads> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery(
      "RecentCreatedLeads",
      usage,
      true,
      request,
      { synchronize: synchronize }
    );
  }
  public async getUsersList(
    usage: number,
    request: UsersListRequest,
    d3eParams?: Partial<{ synchronize: boolean }>
  ): Promise<UsersList> {
    let synchronize = d3eParams?.synchronize;

    return MessageDispatch.get().dataQuery("UsersList", usage, true, request, {
      synchronize: synchronize,
    });
  }
}
