import ListWrapper from "../utils/ListWrapper";
import LeadStatus from "./LeadStatus";
import DBObject from "../utils/DBObject";
import CollectionUtils from "../utils/CollectionUtils";
import D3EDate from "./D3EDate";

export default class LeadResultItem extends DBObject {
  private _id: number = DBObject.nextStructId();
  private static readonly _APOLLOID: number = 0;
  private static readonly _BIRTHDATE: number = 1;
  private static readonly _COMPANY: number = 2;
  private static readonly _COMPANYREVENUE: number = 3;
  private static readonly _COMPANYSIZE: number = 4;
  private static readonly _COMPANYWEBSITE: number = 5;
  private static readonly _CURRENTPOSITION: number = 6;
  private static readonly _DECISIONMAKINGROLE: number = 7;
  private static readonly _EMAIL: number = 8;
  private static readonly _EMAILSTATUS: number = 9;
  private static readonly _FACEBOOKPROFILE: number = 10;
  private static readonly _INDUSTRY: number = 11;
  private static readonly _INTERACTIONDATE: number = 12;
  private static readonly _LANGUAGES: number = 13;
  private static readonly _LINKEDINPROFILE: number = 14;
  private static readonly _LOCATION: number = 15;
  private static readonly _NAME: number = 16;
  private static readonly _PHONE: number = 17;
  private static readonly _PREVIOUSPOSITIONS: number = 18;
  private static readonly _SKILLS: number = 19;
  private static readonly _SOURCE: number = 20;
  private static readonly _STATUS: number = 21;
  private static readonly _TIMEZONE: number = 22;
  private static readonly _TWITTERHANDLE: number = 23;
  private _apolloId: string = "";
  private _name: string = "";
  private _email: string = "";
  private _phone: string = "";
  private _company: string = "";
  private _status: LeadStatus = LeadStatus.New;
  private _currentPosition: string = "";
  private _industry: string = "";
  private _companySize: string = "";
  private _companyRevenue: string = "";
  private _companyWebsite: string = "";
  private _linkedInProfile: string = "";
  private _twitterHandle: string = "";
  private _facebookProfile: string = "";
  private _location: string = "";
  private _timeZone: string = "";
  private _previousPositions: Array<string> = ListWrapper.primitive(
    this,
    "previousPositions",
    LeadResultItem._PREVIOUSPOSITIONS
  );
  private _skills: Array<string> = ListWrapper.primitive(
    this,
    "skills",
    LeadResultItem._SKILLS
  );
  private _source: string = "";
  private _decisionMakingRole: string = "";
  private _languages: Array<string> = ListWrapper.primitive(
    this,
    "languages",
    LeadResultItem._LANGUAGES
  );
  private _birthDate: D3EDate = null;
  private _interactionDate: D3EDate = null;
  private _emailStatus: string = "";
  public constructor(
    d3eParams?: Partial<{
      apolloId: string;
      birthDate: D3EDate;
      company: string;
      companyRevenue: string;
      companySize: string;
      companyWebsite: string;
      currentPosition: string;
      decisionMakingRole: string;
      email: string;
      emailStatus: string;
      facebookProfile: string;
      industry: string;
      interactionDate: D3EDate;
      languages: Array<string>;
      linkedInProfile: string;
      location: string;
      name: string;
      phone: string;
      previousPositions: Array<string>;
      skills: Array<string>;
      source: string;
      status: LeadStatus;
      timeZone: string;
      twitterHandle: string;
    }>
  ) {
    super();

    if (d3eParams?.apolloId) {
      this.setApolloId(d3eParams?.apolloId);
    }

    if (d3eParams?.birthDate) {
      this.setBirthDate(d3eParams?.birthDate);
    }

    if (d3eParams?.company) {
      this.setCompany(d3eParams?.company);
    }

    if (d3eParams?.companyRevenue) {
      this.setCompanyRevenue(d3eParams?.companyRevenue);
    }

    if (d3eParams?.companySize) {
      this.setCompanySize(d3eParams?.companySize);
    }

    if (d3eParams?.companyWebsite) {
      this.setCompanyWebsite(d3eParams?.companyWebsite);
    }

    if (d3eParams?.currentPosition) {
      this.setCurrentPosition(d3eParams?.currentPosition);
    }

    if (d3eParams?.decisionMakingRole) {
      this.setDecisionMakingRole(d3eParams?.decisionMakingRole);
    }

    if (d3eParams?.email) {
      this.setEmail(d3eParams?.email);
    }

    if (d3eParams?.emailStatus) {
      this.setEmailStatus(d3eParams?.emailStatus);
    }

    if (d3eParams?.facebookProfile) {
      this.setFacebookProfile(d3eParams?.facebookProfile);
    }

    if (d3eParams?.industry) {
      this.setIndustry(d3eParams?.industry);
    }

    if (d3eParams?.interactionDate) {
      this.setInteractionDate(d3eParams?.interactionDate);
    }

    if (d3eParams?.languages) {
      this.setLanguages(d3eParams?.languages);
    }

    if (d3eParams?.linkedInProfile) {
      this.setLinkedInProfile(d3eParams?.linkedInProfile);
    }

    if (d3eParams?.location) {
      this.setLocation(d3eParams?.location);
    }

    if (d3eParams?.name) {
      this.setName(d3eParams?.name);
    }

    if (d3eParams?.phone) {
      this.setPhone(d3eParams?.phone);
    }

    if (d3eParams?.previousPositions) {
      this.setPreviousPositions(d3eParams?.previousPositions);
    }

    if (d3eParams?.skills) {
      this.setSkills(d3eParams?.skills);
    }

    if (d3eParams?.source) {
      this.setSource(d3eParams?.source);
    }

    if (d3eParams?.status) {
      this.setStatus(d3eParams?.status);
    }

    if (d3eParams?.timeZone) {
      this.setTimeZone(d3eParams?.timeZone);
    }

    if (d3eParams?.twitterHandle) {
      this.setTwitterHandle(d3eParams?.twitterHandle);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "LeadResultItem";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get apolloId(): string {
    return this._apolloId;
  }
  public setApolloId(val: string): void {
    let isValChanged: boolean = this._apolloId !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._APOLLOID, this._apolloId);

    this._apolloId = val;

    this.fire("apolloId", this);
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get email(): string {
    return this._email;
  }
  public setEmail(val: string): void {
    let isValChanged: boolean = this._email !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._EMAIL, this._email);

    this._email = val;

    this.fire("email", this);
  }
  public get phone(): string {
    return this._phone;
  }
  public setPhone(val: string): void {
    let isValChanged: boolean = this._phone !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._PHONE, this._phone);

    this._phone = val;

    this.fire("phone", this);
  }
  public get company(): string {
    return this._company;
  }
  public setCompany(val: string): void {
    let isValChanged: boolean = this._company !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._COMPANY, this._company);

    this._company = val;

    this.fire("company", this);
  }
  public get status(): LeadStatus {
    return this._status;
  }
  public setStatus(val: LeadStatus): void {
    let isValChanged: boolean = this._status !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._STATUS, this._status.index);

    this._status = val;

    this.fire("status", this);
  }
  public get currentPosition(): string {
    return this._currentPosition;
  }
  public setCurrentPosition(val: string): void {
    let isValChanged: boolean = this._currentPosition !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      LeadResultItem._CURRENTPOSITION,
      this._currentPosition
    );

    this._currentPosition = val;

    this.fire("currentPosition", this);
  }
  public get industry(): string {
    return this._industry;
  }
  public setIndustry(val: string): void {
    let isValChanged: boolean = this._industry !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._INDUSTRY, this._industry);

    this._industry = val;

    this.fire("industry", this);
  }
  public get companySize(): string {
    return this._companySize;
  }
  public setCompanySize(val: string): void {
    let isValChanged: boolean = this._companySize !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._COMPANYSIZE, this._companySize);

    this._companySize = val;

    this.fire("companySize", this);
  }
  public get companyRevenue(): string {
    return this._companyRevenue;
  }
  public setCompanyRevenue(val: string): void {
    let isValChanged: boolean = this._companyRevenue !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._COMPANYREVENUE, this._companyRevenue);

    this._companyRevenue = val;

    this.fire("companyRevenue", this);
  }
  public get companyWebsite(): string {
    return this._companyWebsite;
  }
  public setCompanyWebsite(val: string): void {
    let isValChanged: boolean = this._companyWebsite !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._COMPANYWEBSITE, this._companyWebsite);

    this._companyWebsite = val;

    this.fire("companyWebsite", this);
  }
  public get linkedInProfile(): string {
    return this._linkedInProfile;
  }
  public setLinkedInProfile(val: string): void {
    let isValChanged: boolean = this._linkedInProfile !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      LeadResultItem._LINKEDINPROFILE,
      this._linkedInProfile
    );

    this._linkedInProfile = val;

    this.fire("linkedInProfile", this);
  }
  public get twitterHandle(): string {
    return this._twitterHandle;
  }
  public setTwitterHandle(val: string): void {
    let isValChanged: boolean = this._twitterHandle !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._TWITTERHANDLE, this._twitterHandle);

    this._twitterHandle = val;

    this.fire("twitterHandle", this);
  }
  public get facebookProfile(): string {
    return this._facebookProfile;
  }
  public setFacebookProfile(val: string): void {
    let isValChanged: boolean = this._facebookProfile !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      LeadResultItem._FACEBOOKPROFILE,
      this._facebookProfile
    );

    this._facebookProfile = val;

    this.fire("facebookProfile", this);
  }
  public get location(): string {
    return this._location;
  }
  public setLocation(val: string): void {
    let isValChanged: boolean = this._location !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._LOCATION, this._location);

    this._location = val;

    this.fire("location", this);
  }
  public get timeZone(): string {
    return this._timeZone;
  }
  public setTimeZone(val: string): void {
    let isValChanged: boolean = this._timeZone !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._TIMEZONE, this._timeZone);

    this._timeZone = val;

    this.fire("timeZone", this);
  }
  public get previousPositions(): Array<string> {
    return this._previousPositions;
  }
  public setPreviousPositions(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._previousPositions,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(LeadResultItem._PREVIOUSPOSITIONS)) {
      let _old: Array<string> = Array.from(this._previousPositions);

      this.updateD3EChanges(LeadResultItem._PREVIOUSPOSITIONS, _old);
    }

    this._previousPositions.clear();

    this._previousPositions.addAll(val);

    this.fire("previousPositions", this);
  }
  public addToPreviousPositions(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      LeadResultItem._PREVIOUSPOSITIONS
    );

    if (_isNewChange) {
      _old = Array.from(this._previousPositions);
    }

    if (index === -1) {
      if (!this._previousPositions.contains(val))
        this._previousPositions.add(val);
    } else {
      this._previousPositions.remove(this._previousPositions.elementAt(index));

      this._previousPositions.add(val);
    }

    this.fire("previousPositions", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(LeadResultItem._PREVIOUSPOSITIONS, _old);
    }
  }
  public removeFromPreviousPositions(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      LeadResultItem._PREVIOUSPOSITIONS
    );

    if (_isNewChange) {
      _old = Array.from(this._previousPositions);
    }

    this._previousPositions.remove(val);

    this.fire("previousPositions", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(LeadResultItem._PREVIOUSPOSITIONS, _old);
    }
  }
  public get skills(): Array<string> {
    return this._skills;
  }
  public setSkills(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this._skills, val);

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(LeadResultItem._SKILLS)) {
      let _old: Array<string> = Array.from(this._skills);

      this.updateD3EChanges(LeadResultItem._SKILLS, _old);
    }

    this._skills.clear();

    this._skills.addAll(val);

    this.fire("skills", this);
  }
  public addToSkills(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      LeadResultItem._SKILLS
    );

    if (_isNewChange) {
      _old = Array.from(this._skills);
    }

    if (index === -1) {
      if (!this._skills.contains(val)) this._skills.add(val);
    } else {
      this._skills.remove(this._skills.elementAt(index));

      this._skills.add(val);
    }

    this.fire("skills", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(LeadResultItem._SKILLS, _old);
    }
  }
  public removeFromSkills(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      LeadResultItem._SKILLS
    );

    if (_isNewChange) {
      _old = Array.from(this._skills);
    }

    this._skills.remove(val);

    this.fire("skills", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(LeadResultItem._SKILLS, _old);
    }
  }
  public get source(): string {
    return this._source;
  }
  public setSource(val: string): void {
    let isValChanged: boolean = this._source !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._SOURCE, this._source);

    this._source = val;

    this.fire("source", this);
  }
  public get decisionMakingRole(): string {
    return this._decisionMakingRole;
  }
  public setDecisionMakingRole(val: string): void {
    let isValChanged: boolean = this._decisionMakingRole !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      LeadResultItem._DECISIONMAKINGROLE,
      this._decisionMakingRole
    );

    this._decisionMakingRole = val;

    this.fire("decisionMakingRole", this);
  }
  public get languages(): Array<string> {
    return this._languages;
  }
  public setLanguages(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._languages,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(LeadResultItem._LANGUAGES)) {
      let _old: Array<string> = Array.from(this._languages);

      this.updateD3EChanges(LeadResultItem._LANGUAGES, _old);
    }

    this._languages.clear();

    this._languages.addAll(val);

    this.fire("languages", this);
  }
  public addToLanguages(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      LeadResultItem._LANGUAGES
    );

    if (_isNewChange) {
      _old = Array.from(this._languages);
    }

    if (index === -1) {
      if (!this._languages.contains(val)) this._languages.add(val);
    } else {
      this._languages.remove(this._languages.elementAt(index));

      this._languages.add(val);
    }

    this.fire("languages", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(LeadResultItem._LANGUAGES, _old);
    }
  }
  public removeFromLanguages(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      LeadResultItem._LANGUAGES
    );

    if (_isNewChange) {
      _old = Array.from(this._languages);
    }

    this._languages.remove(val);

    this.fire("languages", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(LeadResultItem._LANGUAGES, _old);
    }
  }
  public get birthDate(): D3EDate {
    return this._birthDate;
  }
  public setBirthDate(val: D3EDate): void {
    let isValChanged: boolean = this._birthDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._BIRTHDATE, this._birthDate);

    this._birthDate = val;

    this.fire("birthDate", this);
  }
  public get interactionDate(): D3EDate {
    return this._interactionDate;
  }
  public setInteractionDate(val: D3EDate): void {
    let isValChanged: boolean = this._interactionDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      LeadResultItem._INTERACTIONDATE,
      this._interactionDate
    );

    this._interactionDate = val;

    this.fire("interactionDate", this);
  }
  public get emailStatus(): string {
    return this._emailStatus;
  }
  public setEmailStatus(val: string): void {
    let isValChanged: boolean = this._emailStatus !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadResultItem._EMAILSTATUS, this._emailStatus);

    this._emailStatus = val;

    this.fire("emailStatus", this);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case LeadResultItem._APOLLOID: {
        this.setApolloId(value as string);
        break;
      }

      case LeadResultItem._NAME: {
        this.setName(value as string);
        break;
      }

      case LeadResultItem._EMAIL: {
        this.setEmail(value as string);
        break;
      }

      case LeadResultItem._PHONE: {
        this.setPhone(value as string);
        break;
      }

      case LeadResultItem._COMPANY: {
        this.setCompany(value as string);
        break;
      }

      case LeadResultItem._STATUS: {
        this.setStatus(LeadStatus.values[value as number]);
        break;
      }

      case LeadResultItem._CURRENTPOSITION: {
        this.setCurrentPosition(value as string);
        break;
      }

      case LeadResultItem._INDUSTRY: {
        this.setIndustry(value as string);
        break;
      }

      case LeadResultItem._COMPANYSIZE: {
        this.setCompanySize(value as string);
        break;
      }

      case LeadResultItem._COMPANYREVENUE: {
        this.setCompanyRevenue(value as string);
        break;
      }

      case LeadResultItem._COMPANYWEBSITE: {
        this.setCompanyWebsite(value as string);
        break;
      }

      case LeadResultItem._LINKEDINPROFILE: {
        this.setLinkedInProfile(value as string);
        break;
      }

      case LeadResultItem._TWITTERHANDLE: {
        this.setTwitterHandle(value as string);
        break;
      }

      case LeadResultItem._FACEBOOKPROFILE: {
        this.setFacebookProfile(value as string);
        break;
      }

      case LeadResultItem._LOCATION: {
        this.setLocation(value as string);
        break;
      }

      case LeadResultItem._TIMEZONE: {
        this.setTimeZone(value as string);
        break;
      }

      case LeadResultItem._PREVIOUSPOSITIONS: {
        this.setPreviousPositions(
          (value as Array<any>).cast<string>().toList()
        );
        break;
      }

      case LeadResultItem._SKILLS: {
        this.setSkills((value as Array<any>).cast<string>().toList());
        break;
      }

      case LeadResultItem._SOURCE: {
        this.setSource(value as string);
        break;
      }

      case LeadResultItem._DECISIONMAKINGROLE: {
        this.setDecisionMakingRole(value as string);
        break;
      }

      case LeadResultItem._LANGUAGES: {
        this.setLanguages((value as Array<any>).cast<string>().toList());
        break;
      }

      case LeadResultItem._BIRTHDATE: {
        this.setBirthDate(value as D3EDate);
        break;
      }

      case LeadResultItem._INTERACTIONDATE: {
        this.setInteractionDate(value as D3EDate);
        break;
      }

      case LeadResultItem._EMAILSTATUS: {
        this.setEmailStatus(value as string);
        break;
      }
    }
  }
  public get(field: number): any {
    switch (field) {
      case LeadResultItem._APOLLOID: {
        return this._apolloId;
      }

      case LeadResultItem._NAME: {
        return this._name;
      }

      case LeadResultItem._EMAIL: {
        return this._email;
      }

      case LeadResultItem._PHONE: {
        return this._phone;
      }

      case LeadResultItem._COMPANY: {
        return this._company;
      }

      case LeadResultItem._STATUS: {
        return this._status.index;
      }

      case LeadResultItem._CURRENTPOSITION: {
        return this._currentPosition;
      }

      case LeadResultItem._INDUSTRY: {
        return this._industry;
      }

      case LeadResultItem._COMPANYSIZE: {
        return this._companySize;
      }

      case LeadResultItem._COMPANYREVENUE: {
        return this._companyRevenue;
      }

      case LeadResultItem._COMPANYWEBSITE: {
        return this._companyWebsite;
      }

      case LeadResultItem._LINKEDINPROFILE: {
        return this._linkedInProfile;
      }

      case LeadResultItem._TWITTERHANDLE: {
        return this._twitterHandle;
      }

      case LeadResultItem._FACEBOOKPROFILE: {
        return this._facebookProfile;
      }

      case LeadResultItem._LOCATION: {
        return this._location;
      }

      case LeadResultItem._TIMEZONE: {
        return this._timeZone;
      }

      case LeadResultItem._PREVIOUSPOSITIONS: {
        return this._previousPositions;
      }

      case LeadResultItem._SKILLS: {
        return this._skills;
      }

      case LeadResultItem._SOURCE: {
        return this._source;
      }

      case LeadResultItem._DECISIONMAKINGROLE: {
        return this._decisionMakingRole;
      }

      case LeadResultItem._LANGUAGES: {
        return this._languages;
      }

      case LeadResultItem._BIRTHDATE: {
        return this._birthDate;
      }

      case LeadResultItem._INTERACTIONDATE: {
        return this._interactionDate;
      }

      case LeadResultItem._EMAILSTATUS: {
        return this._emailStatus;
      }
      default: {
        return null;
      }
    }
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof LeadResultItem &&
        this._apolloId === other._apolloId &&
        this._name === other._name &&
        this._email === other._email &&
        this._phone === other._phone &&
        this._company === other._company &&
        this._status === other._status &&
        this._currentPosition === other._currentPosition &&
        this._industry === other._industry &&
        this._companySize === other._companySize &&
        this._companyRevenue === other._companyRevenue &&
        this._companyWebsite === other._companyWebsite &&
        this._linkedInProfile === other._linkedInProfile &&
        this._twitterHandle === other._twitterHandle &&
        this._facebookProfile === other._facebookProfile &&
        this._location === other._location &&
        this._timeZone === other._timeZone &&
        this._previousPositions === other._previousPositions &&
        this._skills === other._skills &&
        this._source === other._source &&
        this._decisionMakingRole === other._decisionMakingRole &&
        this._languages === other._languages &&
        this._birthDate === other._birthDate &&
        this._interactionDate === other._interactionDate &&
        this._emailStatus === other._emailStatus)
    );
  }
  public get hashCode(): number {
    return (
      (this._apolloId?.hashCode ?? 0) +
      (this._name?.hashCode ?? 0) +
      (this._email?.hashCode ?? 0) +
      (this._phone?.hashCode ?? 0) +
      (this._company?.hashCode ?? 0) +
      (this._status?.hashCode ?? 0) +
      (this._currentPosition?.hashCode ?? 0) +
      (this._industry?.hashCode ?? 0) +
      (this._companySize?.hashCode ?? 0) +
      (this._companyRevenue?.hashCode ?? 0) +
      (this._companyWebsite?.hashCode ?? 0) +
      (this._linkedInProfile?.hashCode ?? 0) +
      (this._twitterHandle?.hashCode ?? 0) +
      (this._facebookProfile?.hashCode ?? 0) +
      (this._location?.hashCode ?? 0) +
      (this._timeZone?.hashCode ?? 0) +
      (this._previousPositions?.hashCode ?? 0) +
      (this._skills?.hashCode ?? 0) +
      (this._source?.hashCode ?? 0) +
      (this._decisionMakingRole?.hashCode ?? 0) +
      (this._languages?.hashCode ?? 0) +
      (this._birthDate?.hashCode ?? 0) +
      (this._interactionDate?.hashCode ?? 0) +
      (this._emailStatus?.hashCode ?? 0)
    );
  }
}
