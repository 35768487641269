import React from "react";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps from "../native/ui/BaseUIProps";

interface AudioPlayerProps extends BaseUIProps {
  url?: string;
}

class _AudioPlayer extends ObservableComponent<AudioPlayerProps> {
  public constructor(props: AudioPlayerProps) {
    super(props);
    this.initState();
  }

  initState(): void {
    this.render();
  }

  public get url(): string {
    return this.props.url;
  }

  public render() {
    return React.createElement(
      "audio",
      {
        controls: true,
      },
      React.createElement("source", {
        src: this.url,
        type: "audio/mpeg",
      })
    );
  }
}

export default function AudioPlayer(props: AudioPlayerProps) {
  return React.createElement(_AudioPlayer, props);
}
