import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import LeadNeedsAndPainsEditView from "./LeadNeedsAndPainsEditView";
import Lead from "../models/Lead";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import Result from "../classes/Result";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _AddButtonOnPressed = (d3eState: PainsCardViewRefs) => void;

type _InputFieldOnChanged = (text: string, d3eState: PainsCardViewRefs) => void;

export interface PainsCardViewProps extends BaseUIProps {
  key?: string;
  lead: Lead;
}
/// To store state data for PainsCardView
class PainsCardViewRefs {
  public addButton: AddButtonState = new AddButtonState();
  inputFieldController: ui.TextEditingController =
    new ui.TextEditingController();
  inputFieldFocusNode: ui.FocusNode = new ui.FocusNode();
}

interface AddButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: PainsCardViewRefs;
  _addButtonHandler?: _AddButtonOnPressed;
}

class AddButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _AddButtonWithState extends ObservableComponent<AddButtonWithStateProps> {
  addButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AddButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get addButton(): AddButtonState {
    return this.props.d3eState.addButton;
  }
  public get d3eState(): PainsCardViewRefs {
    return this.props.d3eState;
  }
  public get _addButtonHandler(): _AddButtonOnPressed {
    return this.props._addButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("addButton", null, this.addButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["addButton", "addButton.", "addButton.hover"], this.rebuild);
  }
  public addButtonOnEnter(event): void {
    return this.addButton.setHover(true);
  }
  public addButtonOnExit(event): void {
    return this.addButton.setHover(false);
  }
  public dispose(): void {
    this.addButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.addButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.addButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.addButton.disable,
        onPressed: () => {
          this._addButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Add" }),
        onEnter: (event) => {
          this.addButtonOnEnter(event);
        },
        onExit: (event) => {
          this.addButtonOnExit(event);
        },
      }),
      className: "x045",
    });
  }
}
function AddButtonWithState(props: AddButtonWithStateProps) {
  return React.createElement(_AddButtonWithState, props);
}

class _PainsCardViewState extends ObservableComponent<PainsCardViewProps> {
  static defaultProps = { lead: null };
  d3eState: PainsCardViewRefs = new PainsCardViewRefs();
  needsAndPainsItem: string = "";
  isNote: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PainsCardViewProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.on(
      ["isNote", "lead", "lead.pains", "needsAndPainsItem"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: PainsCardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }
  }
  public setNeedsAndPainsItem(val: string): void {
    let isValChanged: boolean = this.needsAndPainsItem !== val;

    if (!isValChanged) {
      return;
    }

    this.needsAndPainsItem = val;

    this.fire("needsAndPainsItem", this);
  }
  public setIsNote(val: boolean): void {
    let isValChanged: boolean = this.isNote !== val;

    if (!isValChanged) {
      return;
    }

    this.isNote = val;

    this.fire("isNote", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      mainAxisAlignment: ui.MainAxisAlignment.start,
      children: [
        ui.Row({
          children: [
            IconView({
              size: 18,
              icon: MaterialIcons.list,
              color: cStyle.c1,
              className: "x3d4",
              key: "0",
            }),
            TextView({
              data: "Pains",
              style: new ui.TextStyle({ fontWeight: ui.FontWeight.bold }),
              className: "xde4",
              key: "1",
            }),
          ],
          className: "x725",
          key: "0",
        }),
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            this.lead.pains.expand((pain) => [
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 5.0,
                  transitions: new Map(),
                }),
                child: LeadNeedsAndPainsEditView({
                  needsAndPainsItem: pain,
                  needItem: false,
                  lead: this.lead,
                }),
                key: pain?.toString(),
                className: "xa9d h",
              }),
            ]),
            ui.InputField({
              dense: true,
              inActiveColor: cStyle.c17,
              value: this.needsAndPainsItem,
              activeColor: cStyle.c1,
              padding: ui.EdgeInsets.fromLTRB(5.0, 0.0, 0.0, 0.0, new Map()),
              placeHolder: "Add Pains here",
              maxLines: 5,
              minLines: 3,
              keyboardType: ui.TextInputType.multiline,
              controller: this.d3eState.inputFieldController,
              onChanged: (text) => {
                this.inputFieldonChanged(text, this.d3eState);
              },
              onFocusChange: (val) => {},
              focusNode: this.d3eState.inputFieldFocusNode,
              className: "xe33 vc h",
              key: "1",
            }),
            this.isNote
              ? TextView({
                  data: "Pain is required",
                  style: new ui.TextStyle({ color: cStyle.c10 }),
                  className: "x4f6b",
                })
              : [],
            ui.Row({
              children: [
                AddButtonWithState({
                  d3eState: this.d3eState,
                  _addButtonHandler: this.addButtonHandler,
                  key: "0",
                }),
              ],
              className: "x7c9",
              key: "3",
            }),
          ],
          className: "x1e6 vc h v",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "PainsCardView xdef vc h v"),
      ...copyBaseUIProps(this.props),
    });
  }
  public addButtonHandler = async (
    d3eState: PainsCardViewRefs
  ): Promise<void> => {
    if (this.needsAndPainsItem === "") {
      this.setIsNote(true);

      return;
    }

    this.lead.pains.add(this.needsAndPainsItem);

    let result: Result<Lead> = await this.lead.save();

    this.setNeedsAndPainsItem("");

    d3eState.inputFieldController.text = "";
  };
  public inputFieldonChanged = (
    val: string,
    d3eState: PainsCardViewRefs
  ): void => {
    this.setNeedsAndPainsItem(val);
  };
  public get addButton() {
    return this.d3eState.addButton;
  }
}
export default function PainsCardView(props: PainsCardViewProps) {
  return React.createElement(_PainsCardViewState, {
    ..._PainsCardViewState.defaultProps,
    ...props,
  });
}
