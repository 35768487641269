import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import Loader from "./Loader";
import ResultStatus from "../classes/ResultStatus";
import EventBus from "../utils/EventBus";
import MaterialIcons from "../icons/MaterialIcons";
import FailureMessage from "../classes/FailureMessage";
import Interaction from "../models/Interaction";
import IconButton from "./IconButton";
import Result from "../classes/Result";
import RPCServices from "../rpc/RPCServices";
import AudioPlayer from "./AudioPlayer";
import { BuildContext } from "../classes/BuildContext";

export interface VideoPlayWidgetProps extends BaseUIProps {
  key?: string;
  interaction: Interaction;
}

class _VideoPlayWidgetState extends ObservableComponent<VideoPlayWidgetProps> {
  static defaultProps = { interaction: null };
  isLoader: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: VideoPlayWidgetProps) {
    super(props);

    this.initState();
  }
  public get interaction(): Interaction {
    return this.props.interaction;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("interaction", this.props.interaction);

    this.on(
      [
        "interaction",
        "interaction.conversation",
        "interaction.file",
        "isLoader",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: VideoPlayWidgetProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.interaction !== this.props.interaction) {
      this.updateObservable(
        "interaction",
        prevProps.interaction,
        this.props.interaction
      );

      this.fire("interaction", this);
    }
  }
  public setIsLoader(val: boolean): void {
    let isValChanged: boolean = this.isLoader !== val;

    if (!isValChanged) {
      return;
    }

    this.isLoader = val;

    this.fire("isLoader", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          children: [
            AudioPlayer({
              url: this.interaction.file.downloadUrl + "?inline=true",
              className: "x644",
              key: "0",
            }),
            ui.Container({ className: "x4f6c hc", key: "1" }),
            this.isLoader
              ? Loader({})
              : this.interaction.conversation === null ||
                this.interaction.conversation.isEmpty
              ? IconButton({
                  icon: MaterialIcons.sync,
                  tooltip: "Convert Audio to Text",
                  size: 24,
                  onPressed: () => {
                    this.onPressedConvertToTextHandler();
                  },
                })
              : [],
          ],
          className: "x0a9b",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "VideoPlayWidget"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedConvertToTextHandler = async (): Promise<void> => {
    this.setIsLoader(true);

    this.interaction.setConversation(
      await RPCServices.getCallService().getAudioText(
        this.interaction.file.downloadUrl
      )
    );

    if (this.interaction.conversation === "") {
      this.setIsLoader(false);

      EventBus.get().fire(
        new FailureMessage({ message: "Failed to convert audio to text" })
      );

      return;
    }

    if (
      this.interaction.conversation !== null &&
      this.interaction.conversation.isNotEmpty
    ) {
      let result: Result<Interaction> = await this.interaction.save();

      if (result.status === ResultStatus.Success) {
        this.setIsLoader(false);
      } else {
        this.setIsLoader(false);

        EventBus.get().fire(
          new FailureMessage({ message: result.errors.join(", ") })
        );
      }
    } else {
      this.setIsLoader(false);

      EventBus.get().fire(
        new FailureMessage({ message: "Failed to convert audio to text" })
      );
    }
  };
}
export default function VideoPlayWidget(props: VideoPlayWidgetProps) {
  return React.createElement(_VideoPlayWidgetState, {
    ..._VideoPlayWidgetState.defaultProps,
    ...props,
  });
}
