import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _LabelWithDoubleInputFieldOnChanged = (value: number) => void;

export interface LabelWithDoubleInputFieldProps extends BaseUIProps {
  key?: string;
  name?: string;
  placeHolder?: string;
  value: number;
  keyboardType?: ui.TextInputType;
  isRequired?: boolean;
  decimalCount?: number;
  errors?: Array<string>;
  fieldColor?: ui.Color;
  disable?: boolean;
  inputFormatter?: string;
  dense?: boolean;
  cornerRadius?: number;
  _errorsHash?: number;
  onChanged?: _LabelWithDoubleInputFieldOnChanged;
}

class _LabelWithDoubleInputFieldState extends ObservableComponent<LabelWithDoubleInputFieldProps> {
  static defaultProps = {
    name: "",
    placeHolder: "",
    value: 0.0,
    keyboardType: ui.TextInputType.text,
    isRequired: false,
    decimalCount: 2,
    fieldColor: null,
    disable: false,
    inputFormatter: "",
    dense: false,
    cornerRadius: 4.0,
    errors: [],
    onChanged: null,
  };
  fieldController: ui.TextEditingController = new ui.TextEditingController();
  focusNode: ui.FocusNode = null;
  active: boolean = false;
  nullValue: number = 0.0;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LabelWithDoubleInputFieldProps) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get value(): number {
    return this.props.value;
  }
  public get keyboardType(): ui.TextInputType {
    return this.props.keyboardType;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get decimalCount(): number {
    return this.props.decimalCount;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get fieldColor(): ui.Color {
    return this.props.fieldColor;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get inputFormatter(): string {
    return this.props.inputFormatter;
  }
  public get dense(): boolean {
    return this.props.dense;
  }
  public get cornerRadius(): number {
    return this.props.cornerRadius;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.errors, "errors");

    this.on(
      [
        "cornerRadius",
        "dense",
        "disable",
        "errors",
        "fieldColor",
        "focusNode",
        "inputFormatter",
        "isRequired",
        "keyboardType",
        "name",
        "nullValue",
        "placeHolder",
        "value",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LabelWithDoubleInputFieldProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.keyboardType !== this.props.keyboardType) {
      this.fire("keyboardType", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.decimalCount !== this.props.decimalCount) {
      this.fire("decimalCount", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.fieldColor !== this.props.fieldColor) {
      this.fire("fieldColor", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.inputFormatter !== this.props.inputFormatter) {
      this.fire("inputFormatter", this);
    }

    if (prevProps.dense !== this.props.dense) {
      this.fire("dense", this);
    }

    if (prevProps.cornerRadius !== this.props.cornerRadius) {
      this.fire("cornerRadius", this);
    }
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this.active !== val;

    if (!isValChanged) {
      return;
    }

    this.active = val;

    this.fire("active", this);
  }
  public setNullValue(val: number): void {
    let isValChanged: boolean = this.nullValue !== val;

    if (!isValChanged) {
      return;
    }

    this.nullValue = val;

    this.fire("nullValue", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: cStyle.tColumnFieldStyleCrossAxisAlignmentOn,
      children: [
        this.name !== null && this.name.isNotEmpty
          ? ui.Row({
              children: [
                TextView({
                  data: this.name,
                  style: new ui.TextStyle({
                    color: cStyle.tTextViewLabelTextColorOn,
                    fontSize: cStyle.tTextViewLabelTextFontSizeOn,
                    fontWeight: cStyle.tTextViewLabelTextFontWeightOn,
                  }),
                  className: "xe400",
                  key: "0",
                }),
                this.isRequired
                  ? TextView({
                      data: "*",
                      style: new ui.TextStyle({
                        color: new ui.Color(0xffc20f2f),
                        fontSize: cStyle.tTextViewLabelTextFontSizeOn,
                        fontWeight: cStyle.tTextViewLabelTextFontWeightOn,
                      }),
                      className: "x3023",
                    })
                  : [],
              ],
              className: "x437 hc h",
            })
          : [],
        ui.InputField({
          value: this.value !== this.nullValue ? this.value.toString() : "",
          focusNode: this.focusNode,
          padding: ui.EdgeInsets.symmetric({
            horizontal: 10.0,
            vertical: 0.0,
            transitions: new Map(),
          }),
          placeHolder: this.placeHolder,
          inputFormatter:
            this.inputFormatter !== null
              ? this.inputFormatter
              : "^[\\d+\\-\\.](\\d+)?\\.?\\d{0,9}",
          disable: this.disable,
          dense: this.dense,
          keyboardType: this.keyboardType,
          cornerRadius: this.cornerRadius,
          backgroundColor: this.disable
            ? new ui.Color(0xffb4b4b4)
            : this.fieldColor === null
            ? new ui.Color(0xffffffff)
            : cStyle.tInputFieldDefaultInputFieldBackgroundColorOn,
          activeColor: cStyle.tInputFieldDefaultInputFieldActiveColorOn,
          style: cStyle.tInputFieldDefaultInputFieldStyleOn,
          placeHolderColor:
            cStyle.tInputFieldDefaultInputFieldPlaceHolderColorOn,
          inActiveColor: this.errors.isNotEmpty
            ? new ui.Color(0xffd2d2d2)
            : cStyle.tInputFieldDefaultInputFieldInActiveColorOn,
          controller: this.fieldController,
          onChanged: (text) => {
            this.onChangeText(text);
          },
          onFocusChange: (val) => {},
          states: ui.joinStates({ "data-c2": this.errors.isNotEmpty }, {}),
          className: "x75e hc vc h",
          key: "1",
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    className: "x186 hc",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "x860 hc",
            })
          : [],
      ],
      className: ui.join(
        this.props.className,
        "LabelWithDoubleInputField x0f9 hc h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setNullValue(null);

    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);

    this.fieldController.text =
      this.value !== this.nullValue
        ? this.value.toStringAsFixed(this.decimalCount)
        : "";
  };
  public onChangeFocus = (): void => {
    this.setActive(this.focusNode.hasFocus);
  };
  public onChangeText = (text: string): void => {
    if (text.isEmpty) {
      this.onChanged(0.0);

      return;
    }

    if (this.onChanged !== null) {
      this.onChanged(Number.parseFloat(text));
    }
  };
  public get onChanged(): _LabelWithDoubleInputFieldOnChanged {
    return this.props.onChanged;
  }
}
export default function LabelWithDoubleInputField(
  props: LabelWithDoubleInputFieldProps
) {
  return React.createElement(
    _LabelWithDoubleInputFieldState,
    { ..._LabelWithDoubleInputFieldState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
