import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallData from "../classes/CallData";
import TeamProfileWidget from "./TeamProfileWidget";
import Popup from "./Popup";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import Query from "../classes/Query";
import AddUserView from "./AddUserView";
import MessageDispatch from "../rocket/MessageDispatch";
import SearchComponent from "./SearchComponent";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import UsersList from "../classes/UsersList";
import ScrollView2 from "./ScrollView2";
import Company from "../models/Company";
import UsersListRequest from "../models/UsersListRequest";
import TwilioClient from "../classes/TwilioClient";
import TextView from "./TextView";
import CollectionUtils from "../utils/CollectionUtils";
import Divider from "./Divider";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _UserButtonOnPressed = (d3eState: TeamViewRefs) => void;

export interface TeamViewProps extends BaseUIProps {
  key?: string;
  company: Company;
  client: TwilioClient;
  data: CallData;
}
/// To store state data for TeamView
class TeamViewRefs {
  columnScrollController: ui.ScrollController = new ui.ScrollController();
  public userButton: UserButtonState = new UserButtonState();
}

interface UserButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: TeamViewRefs;
  _onAddUserHandler?: _UserButtonOnPressed;
}

class UserButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _UserButtonWithState extends ObservableComponent<UserButtonWithStateProps> {
  userButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: UserButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get userButton(): UserButtonState {
    return this.props.d3eState.userButton;
  }
  public get d3eState(): TeamViewRefs {
    return this.props.d3eState;
  }
  public get _onAddUserHandler(): _UserButtonOnPressed {
    return this.props._onAddUserHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("userButton", null, this.userButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["userButton", "userButton.", "userButton.hover"], this.rebuild);
  }
  public userButtonOnEnter(event): void {
    return this.userButton.setHover(true);
  }
  public userButtonOnExit(event): void {
    return this.userButton.setHover(false);
  }
  public dispose(): void {
    this.userButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 120,
      child: Button({
        padding: this.userButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.userButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.userButton.disable,
        onPressed: () => {
          this._onAddUserHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Add User" }),
        onEnter: (event) => {
          this.userButtonOnEnter(event);
        },
        onExit: (event) => {
          this.userButtonOnExit(event);
        },
      }),
      className: "x4e2 hc",
    });
  }
}
function UserButtonWithState(props: UserButtonWithStateProps) {
  return React.createElement(_UserButtonWithState, props);
}

class _TeamViewState extends ObservableComponent<TeamViewProps> {
  static defaultProps = { company: null, client: null, data: null };
  d3eState: TeamViewRefs = new TeamViewRefs();
  usersList: UsersList = null;
  request: UsersListRequest = null;
  users: Array<User> = ListWrapper.widget(this, "users");
  searchText: string = "";
  addUserPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: TeamViewProps) {
    super(props);

    this.initState();
  }
  public get company(): Company {
    return this.props.company;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.on(["request", "request.searchText"], this.computeUsersList);

    this.computeUsersList();

    this.on(["usersList", "usersList.items"], this.computeUsers);

    this.computeUsers();

    this.updateSyncProperty("company", this.props.company);

    this.updateSyncProperty("data", this.props.data);

    this.on(["users"], this.rebuild);
  }
  public componentDidUpdate(prevProps: TeamViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.company !== this.props.company) {
      this.updateObservable("company", prevProps.company, this.props.company);

      this.fire("company", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public setUsersList(val: UsersList): void {
    let isValChanged: boolean = this.usersList !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("usersList", this.usersList, val);

    MessageDispatch.get().dispose(this.usersList);

    this.usersList = val;

    this.fire("usersList", this);
  }
  public computeUsersList = async (): Promise<void> => {
    try {
      this.setUsersList(
        await Query.get().getUsersList(
          UsageConstants.QUERY_GETUSERSLIST_TEAMVIEW_PROPERTIES_USERSLIST_COMPUTATION,
          this.request,
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeUsersList : " + exception.toString());

      this.setUsersList(null);
    }
  };
  public setRequest(val: UsersListRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setUsers(val: Array<User>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.users, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("users", this.users, val);

    this.users.clear();

    this.users.addAll(val);

    this.fire("users", this);
  }
  public addToUsers(val: User, index: number = -1): void {
    if (index === -1) {
      if (!this.users.contains(val)) this.users.add(val);
    } else {
      this.users.remove(this.users.elementAt(index));

      this.users.add(val);
    }

    this.fire("users", this, val, true);

    this.updateObservable("users", null, val);
  }
  public removeFromUsers(val: User): void {
    this.users.remove(val);

    this.fire("users", this, val, false);

    this.removeObservable("users", val);
  }
  public computeUsers = (): void => {
    try {
      this.setUsers(
        Array.from(this.usersList.items.isNotEmpty ? this.usersList.items : [])
      );
    } catch (exception) {
      console.log(" exception in computeUsers : " + exception.toString());

      this.setUsers([]);
    }
  };
  public setSearchText(val: string): void {
    let isValChanged: boolean = this.searchText !== val;

    if (!isValChanged) {
      return;
    }

    this.searchText = val;

    this.fire("searchText", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              children: [
                ui.Container({
                  width: 300,
                  child: SearchComponent({
                    placeholder: "Search User",
                    onChanged: (value) => {
                      this.onSearchHandler(value, this.d3eState);
                    },
                  }),
                  key: "0",
                  className: "x4dde hc",
                }),
                ui.Row({
                  children: [
                    UserButtonWithState({
                      d3eState: this.d3eState,
                      _onAddUserHandler: this.onAddUserHandler,
                      key: "0",
                    }),
                  ],
                  key: "1",
                }),
              ],
              className: "xee2 hc h",
              key: "0",
            }),
            ui.Container({
              height: 1,
              margin: ui.EdgeInsets.symmetric({
                horizontal: 0.0,
                vertical: 20.0,
                transitions: new Map(),
              }),
              child: Divider({}),
              key: "1",
              className: "xe8f hc vc h",
            }),
            ScrollView2({
              child: ui.Column({
                crossAxisAlignment: ui.CrossAxisAlignment.start,
                children: [
                  ui.Wrap({
                    spacing: 5,
                    runSpacing: 10,
                    children: [
                      this.users.expand((forItem) => [
                        ui.Container({
                          height: 300,
                          margin: ui.EdgeInsets.fromLTRB(
                            0.0,
                            0.0,
                            10.0,
                            0.0,
                            new Map()
                          ),
                          width: 250,
                          child: TeamProfileWidget({
                            user: forItem,
                            onClick: () => {
                              this.teamProfileWidgetHandler(
                                forItem,
                                this.d3eState
                              );
                            },
                          }),
                          key: forItem?.ident,
                          className: "x9b4 hc vc",
                        }),
                      ]),
                    ],
                    className: "xf5d hc",
                    key: "0",
                  }),
                ],
                key: "2",
              }),
              scrollDirection: ui.Axis.vertical,
              className: "x36f hc vc v",
              controller: this.d3eState.columnScrollController,
            }),
          ],
          className: "x0a66 hc vc h v",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "TeamView xa78 hc vc h v"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(new UsersListRequest());
  };
  public onSearchHandler = (value: string, d3eState: TeamViewRefs): void => {
    this.setSearchText(value);

    this.request.setSearchText(value);

    this.setRequest(this.request.deepClone());
  };
  public onAddUserHandler = (d3eState: TeamViewRefs): void => {
    this.showAddUserPopup();
  };
  public teamProfileWidgetHandler = (
    forItem: User,
    d3eState: TeamViewRefs
  ): void => {
    this.navigator.pushTeamOverviewPage({
      user: forItem,
      client: this.client,
      data: this.data,
      target: "main",
      replace: false,
    });
  };
  public dispose(): void {
    MessageDispatch.get().dispose(this.usersList);

    this.addUserPopupPopup?.dispose();

    super.dispose();
  }
  public showAddUserPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.addUserPopupPopup?.dispose();

    this.addUserPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 550,
        child: AddUserView({ user: new User({ company: this.company }) }),
        className: "x48a hc vc",
      }),
    });

    this.addUserPopupPopup.showPopup(this.context);
  }
  public hideAddUserPopup(): void {
    this.addUserPopupPopup?.dispose();
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get userButton() {
    return this.d3eState.userButton;
  }
}
export default function TeamView(props: TeamViewProps) {
  return React.createElement(_TeamViewState, {
    ..._TeamViewState.defaultProps,
    ...props,
  });
}
