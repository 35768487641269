export default class EmailStatus {
  public static readonly Unavailable = new EmailStatus(
    "Unavailable",
    "Unavailable",
    0
  );
  public static readonly Verified = new EmailStatus("Verified", "Verified", 1);
  public static readonly Unverified = new EmailStatus(
    "Unverified",
    "Unverified",
    2
  );
  public static readonly UpdateRequired = new EmailStatus(
    "UpdateRequired",
    "UpdateRequired",
    3
  );
  public static readonly LikelyToEngage = new EmailStatus(
    "LikelyToEngage",
    "LikelyToEngage",
    4
  );
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): EmailStatus[] {
    return [
      EmailStatus.Unavailable,
      EmailStatus.Verified,
      EmailStatus.Unverified,
      EmailStatus.UpdateRequired,
      EmailStatus.LikelyToEngage,
    ];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
