import DBObject from "../utils/DBObject";

export default class DataPoint extends DBObject {
  private _id: number = DBObject.nextStructId();
  private _value: number = 0.0;
  public constructor(d3eParams?: Partial<{ value: number }>) {
    super();

    if (d3eParams?.value) {
      this.setValue(d3eParams?.value);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "DataPoint";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get value(): number {
    return this._value;
  }
  public setValue(val: number): void {
    let isValChanged: boolean = this._value !== val;

    if (!isValChanged) {
      return;
    }

    this._value = val;

    this.fire("value", this);
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof DataPoint && this._value === other._value)
    );
  }
  public get hashCode(): number {
    return this._value?.hashCode ?? 0;
  }
}
