import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import LeadStatus from "../classes/LeadStatus";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import SortedHeader from "./SortedHeader";
import DataSet from "../classes/DataSet";
import Doughnut2DChart from "./Doughnut2DChart";
import LeadUtils from "../classes/LeadUtils";
import PaginationView from "./PaginationView";
import Query from "../classes/Query";
import ChartDataHelper from "../classes/ChartDataHelper";
import DashboardCardWidget from "./DashboardCardWidget";
import ListWrapper from "../utils/ListWrapper";
import ScrollView2 from "./ScrollView2";
import LeadsListRequest from "../models/LeadsListRequest";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import LeadsList from "../classes/LeadsList";
import TextView from "./TextView";
import LabelDropdown from "./LabelDropdown";
import CollectionUtils from "../utils/CollectionUtils";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

export interface DashboardViewProps extends BaseUIProps {
  key?: string;
  client: TwilioClient;
  data: CallData;
  user: User;
}

class _DashboardViewState extends ObservableComponent<DashboardViewProps> {
  static defaultProps = { client: null, data: null, user: null };
  column2ScrollController: ui.ScrollController = new ui.ScrollController();
  request: LeadsListRequest = null;
  leadsList: LeadsList = null;
  leads: Array<Lead> = ListWrapper.widget(this, "leads");
  filterByStatus: string = "All";
  filterByRange: string = "Today";
  doughnut2DChart: Array<DataSet> = ListWrapper.widget(this, "doughnut2DChart");
  leadsCount: string = "";
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DashboardViewProps) {
    super(props);

    this.initState();
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.on(
      [
        "request",
        "request.applyStatus",
        "request.ascending",
        "request.fromDate",
        "request.offset",
        "request.orderBy",
        "request.pageSize",
        "request.status",
        "request.toDate",
        "request.user",
      ],
      this.computeLeadsList
    );

    this.computeLeadsList();

    this.on(["leadsList", "leadsList.items"], this.computeLeads);

    this.computeLeads();

    this.on(["leads", "leads.status"], this.computeDoughnut2DChart);

    this.computeDoughnut2DChart();

    this.on(
      ["doughnut2DChart", "doughnut2DChart.data", "doughnut2DChart.data.value"],
      this.computeLeadsCount
    );

    this.computeLeadsCount();

    this.updateSyncProperty("data", this.props.data);

    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "doughnut2DChart",
        "filterByRange",
        "filterByStatus",
        "leads",
        "leads.company",
        "leads.email",
        "leads.leadAssignment",
        "leads.leadAssignment.assignedTo",
        "leads.leadAssignment.assignedTo.firstName",
        "leads.leadAssignment.assignedTo.lastName",
        "leads.name",
        "leads.phone",
        "leads.status",
        "leadsCount",
        "request",
        "request.ascending",
        "request.offset",
        "request.orderBy",
        "request.pageSize",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: DashboardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public setRequest(val: LeadsListRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setLeadsList(val: LeadsList): void {
    let isValChanged: boolean = this.leadsList !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("leadsList", this.leadsList, val);

    this.leadsList = val;

    this.fire("leadsList", this);
  }
  public computeLeadsList = async (): Promise<void> => {
    try {
      this.setLeadsList(
        await Query.get().getLeadsList(
          UsageConstants.QUERY_GETLEADSLIST_DASHBOARDVIEW_PROPERTIES_LEADSLIST_COMPUTATION,
          this.request
        )
      );
    } catch (exception) {
      console.log(" exception in computeLeadsList : " + exception.toString());

      this.setLeadsList(null);
    }
  };
  public setLeads(val: Array<Lead>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.leads, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("leads", this.leads, val);

    this.leads.clear();

    this.leads.addAll(val);

    this.fire("leads", this);
  }
  public addToLeads(val: Lead, index: number = -1): void {
    if (index === -1) {
      if (!this.leads.contains(val)) this.leads.add(val);
    } else {
      this.leads.remove(this.leads.elementAt(index));

      this.leads.add(val);
    }

    this.fire("leads", this, val, true);

    this.updateObservable("leads", null, val);
  }
  public removeFromLeads(val: Lead): void {
    this.leads.remove(val);

    this.fire("leads", this, val, false);

    this.removeObservable("leads", val);
  }
  public computeLeads = (): void => {
    try {
      this.setLeads(
        Array.from(this.leadsList.items.isNotEmpty ? this.leadsList.items : [])
      );
    } catch (exception) {
      console.log(" exception in computeLeads : " + exception.toString());

      this.setLeads([]);
    }
  };
  public setFilterByStatus(val: string): void {
    let isValChanged: boolean = this.filterByStatus !== val;

    if (!isValChanged) {
      return;
    }

    this.filterByStatus = val;

    this.fire("filterByStatus", this);
  }
  public setFilterByRange(val: string): void {
    let isValChanged: boolean = this.filterByRange !== val;

    if (!isValChanged) {
      return;
    }

    this.filterByRange = val;

    this.fire("filterByRange", this);
  }
  public setDoughnut2DChart(val: Array<DataSet>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.doughnut2DChart,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("doughnut2DChart", this.doughnut2DChart, val);

    this.doughnut2DChart.clear();

    this.doughnut2DChart.addAll(val);

    this.fire("doughnut2DChart", this);
  }
  public addToDoughnut2DChart(val: DataSet, index: number = -1): void {
    if (index === -1) {
      if (!this.doughnut2DChart.contains(val)) this.doughnut2DChart.add(val);
    } else {
      this.doughnut2DChart.remove(this.doughnut2DChart.elementAt(index));

      this.doughnut2DChart.add(val);
    }

    this.fire("doughnut2DChart", this, val, true);

    this.updateObservable("doughnut2DChart", null, val);
  }
  public removeFromDoughnut2DChart(val: DataSet): void {
    this.doughnut2DChart.remove(val);

    this.fire("doughnut2DChart", this, val, false);

    this.removeObservable("doughnut2DChart", val);
  }
  public computeDoughnut2DChart = async (): Promise<void> => {
    try {
      this.setDoughnut2DChart(
        Array.from(await ChartDataHelper.getStatusBasedChartData(this.leads))
      );
    } catch (exception) {
      console.log(
        " exception in computeDoughnut2DChart : " + exception.toString()
      );

      this.setDoughnut2DChart([]);
    }
  };
  public setLeadsCount(val: string): void {
    let isValChanged: boolean = this.leadsCount !== val;

    if (!isValChanged) {
      return;
    }

    this.leadsCount = val;

    this.fire("leadsCount", this);
  }
  public computeLeadsCount = (): void => {
    try {
      this.setLeadsCount(ChartDataHelper.getCount(this.doughnut2DChart));
    } catch (exception) {
      console.log(" exception in computeLeadsCount : " + exception.toString());

      this.setLeadsCount("");
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ScrollView2({
      child: ui.Column({
        children: [
          ui.Row({
            mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
            children: [
              TextView({
                data: "Overview",
                softWrap: true,
                style: new ui.TextStyle({
                  fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                  fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                }),
                className: "xd9a",
                key: "0",
              }),
              ui.Row({
                children: [
                  ui.Container({
                    margin: ui.EdgeInsets.fromLTRB(
                      0.0,
                      0.0,
                      10.0,
                      0.0,
                      new Map()
                    ),
                    child: LabelDropdown<string>({
                      value: this.filterByStatus,
                      items: [
                        "All",
                        "New",
                        "Inprogress",
                        "Contacted",
                        "Closed",
                      ],
                      onChanged: (value) => {
                        this.onStatusFilterDropdownOnChangedHandler(value);
                      },
                      builder: (context, item) => {
                        return TextView({ data: item });
                      },
                    }),
                    key: "0",
                    className: "x17d",
                  }),
                  LabelDropdown<string>({
                    value: this.filterByRange,
                    items: [
                      "Today",
                      "Yesterday",
                      "Current Week",
                      "Current Month",
                      "Last 3 Months",
                      "Last 6 Months",
                      "This Year",
                    ],
                    onChanged: (value) => {
                      this.onDateFilterDropdownOnChangedHandler(value);
                    },
                    builder: (context, item) => {
                      return TextView({ data: item });
                    },
                    key: "1",
                  }),
                ],
                key: "1",
              }),
            ],
            className: "x83 hc h",
            key: "0",
          }),
          ui.Row({
            crossAxisAlignment: ui.CrossAxisAlignment.start,
            children: [
              ui.Column({
                crossAxisAlignment: ui.CrossAxisAlignment.start,
                children: [
                  ui.Row({
                    children: [
                      ui.Container({
                        expand: true,
                        margin: ui.EdgeInsets.fromLTRB(
                          0.0,
                          0.0,
                          10.0,
                          0.0,
                          new Map()
                        ),
                        child: DashboardCardWidget({
                          bgcolor: cStyle.c4,
                          title: "New",
                          statusCount: this.leads.where(
                            (c) => c.status.name === "New"
                          ).length,
                        }),
                        key: "0",
                        className: "x58 hc h",
                      }),
                      ui.Container({
                        expand: true,
                        child: DashboardCardWidget({
                          bgcolor: cStyle.c5,
                          title: "InProgress",
                          statusCount: this.leads.where(
                            (c) => c.status.name === "Inprogress"
                          ).length,
                        }),
                        key: "1",
                        className: "xc04 hc h",
                      }),
                    ],
                    className: "x7ec5 hc h",
                    key: "0",
                  }),
                  ui.Row({
                    children: [
                      ui.Container({
                        expand: true,
                        margin: ui.EdgeInsets.fromLTRB(
                          0.0,
                          0.0,
                          10.0,
                          0.0,
                          new Map()
                        ),
                        child: DashboardCardWidget({
                          bgcolor: cStyle.c6,
                          title: "Contacted",
                          statusCount: this.leads.where(
                            (c) => c.status.name === "Contacted"
                          ).length,
                        }),
                        key: "0",
                        className: "xb44 hc h",
                      }),
                      ui.Container({
                        expand: true,
                        margin: ui.EdgeInsets.zero,
                        child: DashboardCardWidget({
                          bgcolor: cStyle.c7,
                          title: "Closed",
                          statusCount: this.leads.where(
                            (c) => c.status.name === "Closed"
                          ).length,
                        }),
                        key: "1",
                        className: "x318 hc h",
                      }),
                    ],
                    className: "x737 hc h",
                    key: "1",
                  }),
                ],
                className: "x5623 hc h",
                key: "0",
              }),
              ui.Column({
                crossAxisAlignment: ui.CrossAxisAlignment.center,
                mainAxisAlignment: ui.MainAxisAlignment.center,
                children: [
                  TextView({
                    data: "Status Overview",
                    states: ui.joinStates(
                      { "data-visibility": this.leads.isNotEmpty },
                      {}
                    ),
                    style: new ui.TextStyle({
                      fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                      fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                    }),
                    className: "x326 hc",
                    key: "0",
                  }),
                  this.leads.isNotEmpty
                    ? ui.Container({
                        alignment: ui.Alignment.center,
                        child: ui.Container({
                          constraints: new ui.BoxConstraints({
                            maxHeight: 240,
                            maxWidth: 500,
                          }),
                          child: Doughnut2DChart({
                            data: this.doughnut2DChart,
                            key: this.leadsCount,
                          }),
                          className: "xe40 hc vc",
                        }),
                        className: "x6d8 hc vc",
                      })
                    : ui.Container({
                        alignment: ui.Alignment.center,
                        child: ui.Row({
                          mainAxisAlignment: ui.MainAxisAlignment.center,
                          children: [
                            TextView({
                              data: "No Data Available",
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                              }),
                              className: "x68 vc",
                              key: "0",
                            }),
                          ],
                          className: "x7f1 hc vc h",
                        }),
                        className: "x333 hc vc h",
                      }),
                ],
                className: "xa34 hc h",
                key: "1",
              }),
            ],
            className: "xef2 hc h",
            key: "1",
          }),
          ui.Column({
            crossAxisAlignment: ui.CrossAxisAlignment.start,
            children: [
              ui.Row({
                mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                children: [
                  TextView({
                    data: "Recent Leads",
                    style: new ui.TextStyle({
                      fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                      fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                    }),
                    className: "x2df",
                    key: "0",
                  }),
                ],
                className: "x8b2 hc h",
                key: "0",
              }),
              ui.Table({
                defaultColumnWidth: new ui.FlexColumnWidth(1),
                columnWidths: {
                  0: new ui.FlexColumnWidth(1),
                  1: new ui.FlexColumnWidth(1),
                  2: new ui.FlexColumnWidth(1),
                  3: new ui.FlexColumnWidth(1),
                  4: new ui.FlexColumnWidth(1),
                  5: new ui.FlexColumnWidth(1),
                },
                children: [
                  ui.TableRow({
                    children: [
                      ui.TableCell({
                        child: SortedHeader({
                          name: "Name",
                          assending: this.request.ascending,
                          sorted: this.request.orderBy === "name",
                          onChanged: (value) => {
                            this.onNameSortedHeaderOnChangedHandler(value);
                          },
                          className: "x6e5 hc h",
                        }),
                        className: "xa86 hc h",
                        key: "0",
                      }),
                      ui.TableCell({
                        child: TextView({ data: "Company", className: "hc" }),
                        className: "xfad hc",
                        key: "1",
                      }),
                      ui.TableCell({
                        child: SortedHeader({
                          name: "Phone",
                          assending: this.request.ascending,
                          sorted: this.request.orderBy === "phone",
                          onChanged: (value) => {
                            this.onPhoneSortedHeaderOnChangedHandler(value);
                          },
                          className: "x546 hc h",
                        }),
                        className: "xeda hc h",
                        key: "2",
                      }),
                      ui.TableCell({
                        child: SortedHeader({
                          name: "Email",
                          assending: this.request.ascending,
                          sorted: this.request.orderBy === "email",
                          onChanged: (value) => {
                            this.onEmailSortedHeaderOnChangedHandler(value);
                          },
                          className: "x5fc7 hc h",
                        }),
                        className: "xfb3 hc h",
                        key: "3",
                      }),
                      ui.TableCell({
                        child: TextView({
                          data: "Assigned To",
                          className: "hc",
                        }),
                        className: "x3ec hc",
                        key: "4",
                      }),
                      ui.TableCell({
                        child: SortedHeader({
                          name: "Status",
                          assending: this.request.ascending,
                          sorted: this.request.orderBy === "status",
                          onChanged: (value) => {
                            this.onStatusSortedHeaderOnChangedHandler(value);
                          },
                          className: "x67c hc h",
                        }),
                        className: "x488 hc h",
                        key: "5",
                      }),
                    ],
                    className: "x3db hc h",
                    key: "0",
                  }),
                  this.leads.expand((forItem) => [
                    ui.TableRow({
                      children: [
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: forItem.name,
                            className: "hc",
                          }),
                          className: "hc",
                          key: "0",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: forItem.company,
                            className: "hc",
                          }),
                          className: "hc",
                          key: "1",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: forItem.phone,
                            className: "hc",
                          }),
                          className: "hc",
                          key: "2",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: forItem.email,
                            className: "hc",
                          }),
                          className: "hc",
                          key: "3",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data:
                              (forItem.leadAssignment?.assignedTo?.firstName ??
                                "") +
                              " " +
                              (forItem.leadAssignment?.assignedTo?.lastName ??
                                ""),
                            className: "hc",
                          }),
                          className: "hc",
                          key: "4",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: ui.Row({
                            mainAxisAlignment: ui.MainAxisAlignment.start,
                            children: [
                              ui.Container({
                                alignment: ui.Alignment.center,
                                states: ui.joinStates(
                                  {
                                    "data-c0": forItem.status.name === "New",
                                    "data-c1":
                                      forItem.status.name === "Inprogress",
                                    "data-c2": forItem.status.name === "Closed",
                                    "data-c3":
                                      forItem.status.name === "Contacted",
                                  },
                                  {}
                                ),
                                padding:
                                  forItem.status.name === "New"
                                    ? ui.EdgeInsets.symmetric({
                                        horizontal: 20.0,
                                        vertical: 5.0,
                                        transitions: new Map(),
                                      })
                                    : forItem.status.name === "Inprogress"
                                    ? ui.EdgeInsets.symmetric({
                                        horizontal: 20.0,
                                        vertical: 5.0,
                                        transitions: new Map(),
                                      })
                                    : forItem.status.name === "Closed"
                                    ? ui.EdgeInsets.symmetric({
                                        horizontal: 20.0,
                                        vertical: 5.0,
                                        transitions: new Map(),
                                      })
                                    : forItem.status.name === "Contacted"
                                    ? ui.EdgeInsets.symmetric({
                                        horizontal: 20.0,
                                        vertical: 5.0,
                                        transitions: new Map(),
                                      })
                                    : null,
                                decoration:
                                  forItem.status.name === "New"
                                    ? new ui.BoxDecoration({
                                        color: cStyle.c4,
                                        borderRadius:
                                          ui.BorderRadius.circular(3.0),
                                      })
                                    : forItem.status.name === "Inprogress"
                                    ? new ui.BoxDecoration({
                                        color: cStyle.c5,
                                        borderRadius:
                                          ui.BorderRadius.circular(3.0),
                                      })
                                    : forItem.status.name === "Closed"
                                    ? new ui.BoxDecoration({
                                        color: cStyle.c7,
                                        borderRadius:
                                          ui.BorderRadius.circular(3.0),
                                      })
                                    : forItem.status.name === "Contacted"
                                    ? new ui.BoxDecoration({
                                        color: cStyle.c6,
                                        borderRadius:
                                          ui.BorderRadius.circular(3.0),
                                      })
                                    : new ui.BoxDecoration({}),
                                child: TextView({
                                  data: forItem.status.name,
                                  style: new ui.TextStyle({
                                    color: cStyle.c14,
                                  }),
                                  className: "xebd hc",
                                }),
                                className: "xafc hc",
                                key: "0",
                              }),
                              ui.Container({ key: "1" }),
                            ],
                            className: "x1b hc h",
                          }),
                          className: "x5e81 hc h",
                          key: "5",
                        }),
                      ],
                      onTap: (e) => {
                        e.stopPropagation();

                        this.onLeadSelectHandler(forItem);
                      },
                      className: "x21f hc h",
                      key: forItem?.ident,
                    }),
                  ]),
                ],
                className: "xaaa hc h",
                key: "1",
              }),
              ui.Row({
                mainAxisAlignment: ui.MainAxisAlignment.end,
                children: [
                  PaginationView({
                    pageSize: this.request.pageSize,
                    total: this.leads !== null ? this.leads.length : 0,
                    offset: this.request.offset,
                    onChange: (pageSize, offset) => {
                      this.onChangePaginationViewHandler(pageSize, offset);
                    },
                    key: "0",
                  }),
                ],
                className: "x4fe hc h",
                key: "2",
              }),
            ],
            className: "x0ab hc h",
            key: "2",
          }),
        ],
        className: ui.join(this.props.className, "DashboardView xed hc vc h v"),
        ...copyBaseUIProps(this.props),
      }),
      scrollDirection: ui.Axis.vertical,
      className: "DashboardView xed hc vc h v",
      controller: this.column2ScrollController,
    });
  }
  public onInit = (): void => {
    this.setFilterByRange("Current Week");

    this.setRequest(
      new LeadsListRequest({
        pageSize: 10,
        offset: 0,
        orderBy: "",
        ascending: true,
        fromDate: LeadUtils.getFromDateFilter(this.filterByRange),
        toDate: LeadUtils.getToDateFilter(this.filterByRange),
        applyStatus: false,
      })
    );
  };
  public onChangePaginationViewHandler = (
    pageSize: number,
    offset: number
  ): void => {
    this.request.setPageSize(pageSize);

    this.request.setOffset(offset);

    this.setRequest(this.request.deepClone());
  };
  public onLeadSelectHandler = (forItem: Lead): void => {
    this.navigator.pushLeadOverviewPage({
      lead: forItem,
      user: this.user,
      client: this.client,
      data: this.data,
      replace: false,
      target: "main",
    });
  };
  public onNameSortedHeaderOnChangedHandler = (value: boolean): void => {
    this.request.setOrderBy("name");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onPhoneSortedHeaderOnChangedHandler = (value: boolean): void => {
    this.request.setOrderBy("phone");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onEmailSortedHeaderOnChangedHandler = (value: boolean): void => {
    this.request.setOrderBy("email");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onStatusSortedHeaderOnChangedHandler = (value: boolean): void => {
    this.request.setOrderBy("status");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onStatusFilterDropdownOnChangedHandler = (value: string): void => {
    this.setFilterByStatus(value);

    if (value !== "All") {
      this.request.setStatus(
        LeadStatus.values.firstWhere((e) => e.name === value, {
          orElse: () => null,
        })
      );
    }

    this.request.setApplyStatus(value === "All" ? false : true);

    this.setRequest(this.request.deepClone());
  };
  public onDateFilterDropdownOnChangedHandler = (value: string): void => {
    this.setFilterByRange(value);

    this.request.setFromDate(LeadUtils.getFromDateFilter(this.filterByRange));

    this.request.setToDate(LeadUtils.getToDateFilter(this.filterByRange));

    this.setRequest(this.request.deepClone());
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function DashboardView(props: DashboardViewProps) {
  return React.createElement(_DashboardViewState, {
    ..._DashboardViewState.defaultProps,
    ...props,
  });
}
