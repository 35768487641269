import ListWrapper from "../utils/ListWrapper";
import DBObject from "../utils/DBObject";
import CollectionUtils from "../utils/CollectionUtils";

export default class SearchCriteria extends DBObject {
  private _id: number = DBObject.nextStructId();
  private static readonly _ACCOUNTLOCATION: number = 0;
  private static readonly _COMPANYTAGIDS: number = 1;
  private static readonly _DEPARTMENT: number = 2;
  private static readonly _FULLNAME: number = 3;
  private static readonly _FUNDINGFROMDATE: number = 4;
  private static readonly _FUNDINGTODATE: number = 5;
  private static readonly _JOBPOSTEDFROMDATE: number = 6;
  private static readonly _JOBPOSTEDTODATE: number = 7;
  private static readonly _JOBPOSTINGHIRINGFOR: number = 8;
  private static readonly _JOBPOSTINGLOCATION: number = 9;
  private static readonly _JOBTITLES: number = 10;
  private static readonly _KEYWORDS: number = 11;
  private static readonly _LANGUAGES: number = 12;
  private static readonly _MAXEMPLOYEERANGE: number = 13;
  private static readonly _MAXFOUNDEDYEAR: number = 14;
  private static readonly _MAXFUNDINGAMOUNT: number = 15;
  private static readonly _MAXGROWPERCENTAGE: number = 16;
  private static readonly _MAXJOBPOSTING: number = 17;
  private static readonly _MAXMONTHCURRENTROLE: number = 18;
  private static readonly _MAXRETAILLOCATION: number = 19;
  private static readonly _MAXYEARCURRENTROLE: number = 20;
  private static readonly _MAXYEAREXP: number = 21;
  private static readonly _MINEMPLOYEERANGE: number = 22;
  private static readonly _MINFOUNDEDYEAR: number = 23;
  private static readonly _MINFUNDINGAMOUNT: number = 24;
  private static readonly _MINGROWPERCENTAGE: number = 25;
  private static readonly _MINJOBPOSTING: number = 26;
  private static readonly _MINMONTHCURRENTROLE: number = 27;
  private static readonly _MINRETAILLOCATION: number = 28;
  private static readonly _MINYEARCURRENTROLE: number = 29;
  private static readonly _MINYEAREXP: number = 30;
  private static readonly _PAGE: number = 31;
  private static readonly _PERPAGE: number = 32;
  private static readonly _PERSONLOCATIONS: number = 33;
  private static readonly _SICCODES: number = 34;
  private static readonly _SEARCHTEXT: number = 35;
  private static readonly _TECHNOLOGIES: number = 36;
  private static readonly _TIMEFRAME: number = 37;
  private static readonly _TIMEZONES: number = 38;
  private _page: number = 1;
  private _perPage: number = 20;
  private _searchText: string = "";
  private _fullName: string = "";
  private _personLocations: Array<string> = ListWrapper.primitive(
    this,
    "personLocations",
    SearchCriteria._PERSONLOCATIONS
  );
  private _timeZones: Array<string> = ListWrapper.primitive(
    this,
    "timeZones",
    SearchCriteria._TIMEZONES
  );
  private _accountLocation: Array<string> = ListWrapper.primitive(
    this,
    "accountLocation",
    SearchCriteria._ACCOUNTLOCATION
  );
  private _jobPostingHiringFor: Array<string> = ListWrapper.primitive(
    this,
    "jobPostingHiringFor",
    SearchCriteria._JOBPOSTINGHIRINGFOR
  );
  private _jobPostingLocation: Array<string> = ListWrapper.primitive(
    this,
    "jobPostingLocation",
    SearchCriteria._JOBPOSTINGLOCATION
  );
  private _jobTitles: Array<string> = ListWrapper.primitive(
    this,
    "jobTitles",
    SearchCriteria._JOBTITLES
  );
  private _languages: Array<string> = ListWrapper.primitive(
    this,
    "languages",
    SearchCriteria._LANGUAGES
  );
  private _keywords: Array<string> = ListWrapper.primitive(
    this,
    "keywords",
    SearchCriteria._KEYWORDS
  );
  private _companyTagIDs: Array<string> = ListWrapper.primitive(
    this,
    "companyTagIDs",
    SearchCriteria._COMPANYTAGIDS
  );
  private _technologies: Array<string> = ListWrapper.primitive(
    this,
    "technologies",
    SearchCriteria._TECHNOLOGIES
  );
  private _minYearExp: string = "";
  private _maxYearExp: string = "";
  private _minYearCurrentRole: string = "";
  private _maxYearCurrentRole: string = "";
  private _minMonthCurrentRole: string = "";
  private _maxMonthCurrentRole: string = "";
  private _sICCodes: Array<string> = ListWrapper.primitive(
    this,
    "sICCodes",
    SearchCriteria._SICCODES
  );
  private _minGrowPercentage: string = "";
  private _maxGrowPercentage: string = "";
  private _timeFrame: string = "";
  private _department: string = "";
  private _fundingFromDate: string = "";
  private _fundingToDate: string = "";
  private _minFundingAmount: string = "";
  private _maxFundingAmount: string = "";
  private _minFoundedYear: string = "";
  private _maxFoundedYear: string = "";
  private _minRetailLocation: string = "";
  private _maxRetailLocation: string = "";
  private _minJobPosting: string = "";
  private _maxJobPosting: string = "";
  private _jobPostedFromDate: string = "";
  private _jobPostedToDate: string = "";
  private _minEmployeeRange: string = "";
  private _maxEmployeeRange: string = "";
  public constructor(
    d3eParams?: Partial<{
      accountLocation: Array<string>;
      companyTagIDs: Array<string>;
      department: string;
      fullName: string;
      fundingFromDate: string;
      fundingToDate: string;
      jobPostedFromDate: string;
      jobPostedToDate: string;
      jobPostingHiringFor: Array<string>;
      jobPostingLocation: Array<string>;
      jobTitles: Array<string>;
      keywords: Array<string>;
      languages: Array<string>;
      maxEmployeeRange: string;
      maxFoundedYear: string;
      maxFundingAmount: string;
      maxGrowPercentage: string;
      maxJobPosting: string;
      maxMonthCurrentRole: string;
      maxRetailLocation: string;
      maxYearCurrentRole: string;
      maxYearExp: string;
      minEmployeeRange: string;
      minFoundedYear: string;
      minFundingAmount: string;
      minGrowPercentage: string;
      minJobPosting: string;
      minMonthCurrentRole: string;
      minRetailLocation: string;
      minYearCurrentRole: string;
      minYearExp: string;
      page: number;
      perPage: number;
      personLocations: Array<string>;
      sICCodes: Array<string>;
      searchText: string;
      technologies: Array<string>;
      timeFrame: string;
      timeZones: Array<string>;
    }>
  ) {
    super();

    if (d3eParams?.accountLocation) {
      this.setAccountLocation(d3eParams?.accountLocation);
    }

    if (d3eParams?.companyTagIDs) {
      this.setCompanyTagIDs(d3eParams?.companyTagIDs);
    }

    if (d3eParams?.department) {
      this.setDepartment(d3eParams?.department);
    }

    if (d3eParams?.fullName) {
      this.setFullName(d3eParams?.fullName);
    }

    if (d3eParams?.fundingFromDate) {
      this.setFundingFromDate(d3eParams?.fundingFromDate);
    }

    if (d3eParams?.fundingToDate) {
      this.setFundingToDate(d3eParams?.fundingToDate);
    }

    if (d3eParams?.jobPostedFromDate) {
      this.setJobPostedFromDate(d3eParams?.jobPostedFromDate);
    }

    if (d3eParams?.jobPostedToDate) {
      this.setJobPostedToDate(d3eParams?.jobPostedToDate);
    }

    if (d3eParams?.jobPostingHiringFor) {
      this.setJobPostingHiringFor(d3eParams?.jobPostingHiringFor);
    }

    if (d3eParams?.jobPostingLocation) {
      this.setJobPostingLocation(d3eParams?.jobPostingLocation);
    }

    if (d3eParams?.jobTitles) {
      this.setJobTitles(d3eParams?.jobTitles);
    }

    if (d3eParams?.keywords) {
      this.setKeywords(d3eParams?.keywords);
    }

    if (d3eParams?.languages) {
      this.setLanguages(d3eParams?.languages);
    }

    if (d3eParams?.maxEmployeeRange) {
      this.setMaxEmployeeRange(d3eParams?.maxEmployeeRange);
    }

    if (d3eParams?.maxFoundedYear) {
      this.setMaxFoundedYear(d3eParams?.maxFoundedYear);
    }

    if (d3eParams?.maxFundingAmount) {
      this.setMaxFundingAmount(d3eParams?.maxFundingAmount);
    }

    if (d3eParams?.maxGrowPercentage) {
      this.setMaxGrowPercentage(d3eParams?.maxGrowPercentage);
    }

    if (d3eParams?.maxJobPosting) {
      this.setMaxJobPosting(d3eParams?.maxJobPosting);
    }

    if (d3eParams?.maxMonthCurrentRole) {
      this.setMaxMonthCurrentRole(d3eParams?.maxMonthCurrentRole);
    }

    if (d3eParams?.maxRetailLocation) {
      this.setMaxRetailLocation(d3eParams?.maxRetailLocation);
    }

    if (d3eParams?.maxYearCurrentRole) {
      this.setMaxYearCurrentRole(d3eParams?.maxYearCurrentRole);
    }

    if (d3eParams?.maxYearExp) {
      this.setMaxYearExp(d3eParams?.maxYearExp);
    }

    if (d3eParams?.minEmployeeRange) {
      this.setMinEmployeeRange(d3eParams?.minEmployeeRange);
    }

    if (d3eParams?.minFoundedYear) {
      this.setMinFoundedYear(d3eParams?.minFoundedYear);
    }

    if (d3eParams?.minFundingAmount) {
      this.setMinFundingAmount(d3eParams?.minFundingAmount);
    }

    if (d3eParams?.minGrowPercentage) {
      this.setMinGrowPercentage(d3eParams?.minGrowPercentage);
    }

    if (d3eParams?.minJobPosting) {
      this.setMinJobPosting(d3eParams?.minJobPosting);
    }

    if (d3eParams?.minMonthCurrentRole) {
      this.setMinMonthCurrentRole(d3eParams?.minMonthCurrentRole);
    }

    if (d3eParams?.minRetailLocation) {
      this.setMinRetailLocation(d3eParams?.minRetailLocation);
    }

    if (d3eParams?.minYearCurrentRole) {
      this.setMinYearCurrentRole(d3eParams?.minYearCurrentRole);
    }

    if (d3eParams?.minYearExp) {
      this.setMinYearExp(d3eParams?.minYearExp);
    }

    if (d3eParams?.page) {
      this.setPage(d3eParams?.page);
    }

    if (d3eParams?.perPage) {
      this.setPerPage(d3eParams?.perPage);
    }

    if (d3eParams?.personLocations) {
      this.setPersonLocations(d3eParams?.personLocations);
    }

    if (d3eParams?.sICCodes) {
      this.setSICCodes(d3eParams?.sICCodes);
    }

    if (d3eParams?.searchText) {
      this.setSearchText(d3eParams?.searchText);
    }

    if (d3eParams?.technologies) {
      this.setTechnologies(d3eParams?.technologies);
    }

    if (d3eParams?.timeFrame) {
      this.setTimeFrame(d3eParams?.timeFrame);
    }

    if (d3eParams?.timeZones) {
      this.setTimeZones(d3eParams?.timeZones);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "SearchCriteria";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get page(): number {
    return this._page;
  }
  public setPage(val: number): void {
    let isValChanged: boolean = this._page !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._PAGE, this._page);

    this._page = val;

    this.fire("page", this);
  }
  public get perPage(): number {
    return this._perPage;
  }
  public setPerPage(val: number): void {
    let isValChanged: boolean = this._perPage !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._PERPAGE, this._perPage);

    this._perPage = val;

    this.fire("perPage", this);
  }
  public get searchText(): string {
    return this._searchText;
  }
  public setSearchText(val: string): void {
    let isValChanged: boolean = this._searchText !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._SEARCHTEXT, this._searchText);

    this._searchText = val;

    this.fire("searchText", this);
  }
  public get fullName(): string {
    return this._fullName;
  }
  public setFullName(val: string): void {
    let isValChanged: boolean = this._fullName !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._FULLNAME, this._fullName);

    this._fullName = val;

    this.fire("fullName", this);
  }
  public get personLocations(): Array<string> {
    return this._personLocations;
  }
  public setPersonLocations(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._personLocations,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._PERSONLOCATIONS)) {
      let _old: Array<string> = Array.from(this._personLocations);

      this.updateD3EChanges(SearchCriteria._PERSONLOCATIONS, _old);
    }

    this._personLocations.clear();

    this._personLocations.addAll(val);

    this.fire("personLocations", this);
  }
  public addToPersonLocations(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._PERSONLOCATIONS
    );

    if (_isNewChange) {
      _old = Array.from(this._personLocations);
    }

    if (index === -1) {
      if (!this._personLocations.contains(val)) this._personLocations.add(val);
    } else {
      this._personLocations.remove(this._personLocations.elementAt(index));

      this._personLocations.add(val);
    }

    this.fire("personLocations", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._PERSONLOCATIONS, _old);
    }
  }
  public removeFromPersonLocations(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._PERSONLOCATIONS
    );

    if (_isNewChange) {
      _old = Array.from(this._personLocations);
    }

    this._personLocations.remove(val);

    this.fire("personLocations", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._PERSONLOCATIONS, _old);
    }
  }
  public get timeZones(): Array<string> {
    return this._timeZones;
  }
  public setTimeZones(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._timeZones,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._TIMEZONES)) {
      let _old: Array<string> = Array.from(this._timeZones);

      this.updateD3EChanges(SearchCriteria._TIMEZONES, _old);
    }

    this._timeZones.clear();

    this._timeZones.addAll(val);

    this.fire("timeZones", this);
  }
  public addToTimeZones(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._TIMEZONES
    );

    if (_isNewChange) {
      _old = Array.from(this._timeZones);
    }

    if (index === -1) {
      if (!this._timeZones.contains(val)) this._timeZones.add(val);
    } else {
      this._timeZones.remove(this._timeZones.elementAt(index));

      this._timeZones.add(val);
    }

    this.fire("timeZones", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._TIMEZONES, _old);
    }
  }
  public removeFromTimeZones(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._TIMEZONES
    );

    if (_isNewChange) {
      _old = Array.from(this._timeZones);
    }

    this._timeZones.remove(val);

    this.fire("timeZones", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._TIMEZONES, _old);
    }
  }
  public get accountLocation(): Array<string> {
    return this._accountLocation;
  }
  public setAccountLocation(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._accountLocation,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._ACCOUNTLOCATION)) {
      let _old: Array<string> = Array.from(this._accountLocation);

      this.updateD3EChanges(SearchCriteria._ACCOUNTLOCATION, _old);
    }

    this._accountLocation.clear();

    this._accountLocation.addAll(val);

    this.fire("accountLocation", this);
  }
  public addToAccountLocation(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._ACCOUNTLOCATION
    );

    if (_isNewChange) {
      _old = Array.from(this._accountLocation);
    }

    if (index === -1) {
      if (!this._accountLocation.contains(val)) this._accountLocation.add(val);
    } else {
      this._accountLocation.remove(this._accountLocation.elementAt(index));

      this._accountLocation.add(val);
    }

    this.fire("accountLocation", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._ACCOUNTLOCATION, _old);
    }
  }
  public removeFromAccountLocation(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._ACCOUNTLOCATION
    );

    if (_isNewChange) {
      _old = Array.from(this._accountLocation);
    }

    this._accountLocation.remove(val);

    this.fire("accountLocation", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._ACCOUNTLOCATION, _old);
    }
  }
  public get jobPostingHiringFor(): Array<string> {
    return this._jobPostingHiringFor;
  }
  public setJobPostingHiringFor(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._jobPostingHiringFor,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._JOBPOSTINGHIRINGFOR)) {
      let _old: Array<string> = Array.from(this._jobPostingHiringFor);

      this.updateD3EChanges(SearchCriteria._JOBPOSTINGHIRINGFOR, _old);
    }

    this._jobPostingHiringFor.clear();

    this._jobPostingHiringFor.addAll(val);

    this.fire("jobPostingHiringFor", this);
  }
  public addToJobPostingHiringFor(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._JOBPOSTINGHIRINGFOR
    );

    if (_isNewChange) {
      _old = Array.from(this._jobPostingHiringFor);
    }

    if (index === -1) {
      if (!this._jobPostingHiringFor.contains(val))
        this._jobPostingHiringFor.add(val);
    } else {
      this._jobPostingHiringFor.remove(
        this._jobPostingHiringFor.elementAt(index)
      );

      this._jobPostingHiringFor.add(val);
    }

    this.fire("jobPostingHiringFor", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._JOBPOSTINGHIRINGFOR, _old);
    }
  }
  public removeFromJobPostingHiringFor(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._JOBPOSTINGHIRINGFOR
    );

    if (_isNewChange) {
      _old = Array.from(this._jobPostingHiringFor);
    }

    this._jobPostingHiringFor.remove(val);

    this.fire("jobPostingHiringFor", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._JOBPOSTINGHIRINGFOR, _old);
    }
  }
  public get jobPostingLocation(): Array<string> {
    return this._jobPostingLocation;
  }
  public setJobPostingLocation(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._jobPostingLocation,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._JOBPOSTINGLOCATION)) {
      let _old: Array<string> = Array.from(this._jobPostingLocation);

      this.updateD3EChanges(SearchCriteria._JOBPOSTINGLOCATION, _old);
    }

    this._jobPostingLocation.clear();

    this._jobPostingLocation.addAll(val);

    this.fire("jobPostingLocation", this);
  }
  public addToJobPostingLocation(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._JOBPOSTINGLOCATION
    );

    if (_isNewChange) {
      _old = Array.from(this._jobPostingLocation);
    }

    if (index === -1) {
      if (!this._jobPostingLocation.contains(val))
        this._jobPostingLocation.add(val);
    } else {
      this._jobPostingLocation.remove(
        this._jobPostingLocation.elementAt(index)
      );

      this._jobPostingLocation.add(val);
    }

    this.fire("jobPostingLocation", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._JOBPOSTINGLOCATION, _old);
    }
  }
  public removeFromJobPostingLocation(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._JOBPOSTINGLOCATION
    );

    if (_isNewChange) {
      _old = Array.from(this._jobPostingLocation);
    }

    this._jobPostingLocation.remove(val);

    this.fire("jobPostingLocation", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._JOBPOSTINGLOCATION, _old);
    }
  }
  public get jobTitles(): Array<string> {
    return this._jobTitles;
  }
  public setJobTitles(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._jobTitles,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._JOBTITLES)) {
      let _old: Array<string> = Array.from(this._jobTitles);

      this.updateD3EChanges(SearchCriteria._JOBTITLES, _old);
    }

    this._jobTitles.clear();

    this._jobTitles.addAll(val);

    this.fire("jobTitles", this);
  }
  public addToJobTitles(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._JOBTITLES
    );

    if (_isNewChange) {
      _old = Array.from(this._jobTitles);
    }

    if (index === -1) {
      if (!this._jobTitles.contains(val)) this._jobTitles.add(val);
    } else {
      this._jobTitles.remove(this._jobTitles.elementAt(index));

      this._jobTitles.add(val);
    }

    this.fire("jobTitles", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._JOBTITLES, _old);
    }
  }
  public removeFromJobTitles(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._JOBTITLES
    );

    if (_isNewChange) {
      _old = Array.from(this._jobTitles);
    }

    this._jobTitles.remove(val);

    this.fire("jobTitles", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._JOBTITLES, _old);
    }
  }
  public get languages(): Array<string> {
    return this._languages;
  }
  public setLanguages(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._languages,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._LANGUAGES)) {
      let _old: Array<string> = Array.from(this._languages);

      this.updateD3EChanges(SearchCriteria._LANGUAGES, _old);
    }

    this._languages.clear();

    this._languages.addAll(val);

    this.fire("languages", this);
  }
  public addToLanguages(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._LANGUAGES
    );

    if (_isNewChange) {
      _old = Array.from(this._languages);
    }

    if (index === -1) {
      if (!this._languages.contains(val)) this._languages.add(val);
    } else {
      this._languages.remove(this._languages.elementAt(index));

      this._languages.add(val);
    }

    this.fire("languages", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._LANGUAGES, _old);
    }
  }
  public removeFromLanguages(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._LANGUAGES
    );

    if (_isNewChange) {
      _old = Array.from(this._languages);
    }

    this._languages.remove(val);

    this.fire("languages", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._LANGUAGES, _old);
    }
  }
  public get keywords(): Array<string> {
    return this._keywords;
  }
  public setKeywords(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._keywords,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._KEYWORDS)) {
      let _old: Array<string> = Array.from(this._keywords);

      this.updateD3EChanges(SearchCriteria._KEYWORDS, _old);
    }

    this._keywords.clear();

    this._keywords.addAll(val);

    this.fire("keywords", this);
  }
  public addToKeywords(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._KEYWORDS
    );

    if (_isNewChange) {
      _old = Array.from(this._keywords);
    }

    if (index === -1) {
      if (!this._keywords.contains(val)) this._keywords.add(val);
    } else {
      this._keywords.remove(this._keywords.elementAt(index));

      this._keywords.add(val);
    }

    this.fire("keywords", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._KEYWORDS, _old);
    }
  }
  public removeFromKeywords(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._KEYWORDS
    );

    if (_isNewChange) {
      _old = Array.from(this._keywords);
    }

    this._keywords.remove(val);

    this.fire("keywords", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._KEYWORDS, _old);
    }
  }
  public get companyTagIDs(): Array<string> {
    return this._companyTagIDs;
  }
  public setCompanyTagIDs(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._companyTagIDs,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._COMPANYTAGIDS)) {
      let _old: Array<string> = Array.from(this._companyTagIDs);

      this.updateD3EChanges(SearchCriteria._COMPANYTAGIDS, _old);
    }

    this._companyTagIDs.clear();

    this._companyTagIDs.addAll(val);

    this.fire("companyTagIDs", this);
  }
  public addToCompanyTagIDs(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._COMPANYTAGIDS
    );

    if (_isNewChange) {
      _old = Array.from(this._companyTagIDs);
    }

    if (index === -1) {
      if (!this._companyTagIDs.contains(val)) this._companyTagIDs.add(val);
    } else {
      this._companyTagIDs.remove(this._companyTagIDs.elementAt(index));

      this._companyTagIDs.add(val);
    }

    this.fire("companyTagIDs", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._COMPANYTAGIDS, _old);
    }
  }
  public removeFromCompanyTagIDs(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._COMPANYTAGIDS
    );

    if (_isNewChange) {
      _old = Array.from(this._companyTagIDs);
    }

    this._companyTagIDs.remove(val);

    this.fire("companyTagIDs", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._COMPANYTAGIDS, _old);
    }
  }
  public get technologies(): Array<string> {
    return this._technologies;
  }
  public setTechnologies(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._technologies,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._TECHNOLOGIES)) {
      let _old: Array<string> = Array.from(this._technologies);

      this.updateD3EChanges(SearchCriteria._TECHNOLOGIES, _old);
    }

    this._technologies.clear();

    this._technologies.addAll(val);

    this.fire("technologies", this);
  }
  public addToTechnologies(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._TECHNOLOGIES
    );

    if (_isNewChange) {
      _old = Array.from(this._technologies);
    }

    if (index === -1) {
      if (!this._technologies.contains(val)) this._technologies.add(val);
    } else {
      this._technologies.remove(this._technologies.elementAt(index));

      this._technologies.add(val);
    }

    this.fire("technologies", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._TECHNOLOGIES, _old);
    }
  }
  public removeFromTechnologies(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._TECHNOLOGIES
    );

    if (_isNewChange) {
      _old = Array.from(this._technologies);
    }

    this._technologies.remove(val);

    this.fire("technologies", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._TECHNOLOGIES, _old);
    }
  }
  public get minYearExp(): string {
    return this._minYearExp;
  }
  public setMinYearExp(val: string): void {
    let isValChanged: boolean = this._minYearExp !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._MINYEAREXP, this._minYearExp);

    this._minYearExp = val;

    this.fire("minYearExp", this);
  }
  public get maxYearExp(): string {
    return this._maxYearExp;
  }
  public setMaxYearExp(val: string): void {
    let isValChanged: boolean = this._maxYearExp !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._MAXYEAREXP, this._maxYearExp);

    this._maxYearExp = val;

    this.fire("maxYearExp", this);
  }
  public get minYearCurrentRole(): string {
    return this._minYearCurrentRole;
  }
  public setMinYearCurrentRole(val: string): void {
    let isValChanged: boolean = this._minYearCurrentRole !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MINYEARCURRENTROLE,
      this._minYearCurrentRole
    );

    this._minYearCurrentRole = val;

    this.fire("minYearCurrentRole", this);
  }
  public get maxYearCurrentRole(): string {
    return this._maxYearCurrentRole;
  }
  public setMaxYearCurrentRole(val: string): void {
    let isValChanged: boolean = this._maxYearCurrentRole !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MAXYEARCURRENTROLE,
      this._maxYearCurrentRole
    );

    this._maxYearCurrentRole = val;

    this.fire("maxYearCurrentRole", this);
  }
  public get minMonthCurrentRole(): string {
    return this._minMonthCurrentRole;
  }
  public setMinMonthCurrentRole(val: string): void {
    let isValChanged: boolean = this._minMonthCurrentRole !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MINMONTHCURRENTROLE,
      this._minMonthCurrentRole
    );

    this._minMonthCurrentRole = val;

    this.fire("minMonthCurrentRole", this);
  }
  public get maxMonthCurrentRole(): string {
    return this._maxMonthCurrentRole;
  }
  public setMaxMonthCurrentRole(val: string): void {
    let isValChanged: boolean = this._maxMonthCurrentRole !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MAXMONTHCURRENTROLE,
      this._maxMonthCurrentRole
    );

    this._maxMonthCurrentRole = val;

    this.fire("maxMonthCurrentRole", this);
  }
  public get sICCodes(): Array<string> {
    return this._sICCodes;
  }
  public setSICCodes(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._sICCodes,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(SearchCriteria._SICCODES)) {
      let _old: Array<string> = Array.from(this._sICCodes);

      this.updateD3EChanges(SearchCriteria._SICCODES, _old);
    }

    this._sICCodes.clear();

    this._sICCodes.addAll(val);

    this.fire("sICCodes", this);
  }
  public addToSICCodes(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._SICCODES
    );

    if (_isNewChange) {
      _old = Array.from(this._sICCodes);
    }

    if (index === -1) {
      if (!this._sICCodes.contains(val)) this._sICCodes.add(val);
    } else {
      this._sICCodes.remove(this._sICCodes.elementAt(index));

      this._sICCodes.add(val);
    }

    this.fire("sICCodes", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._SICCODES, _old);
    }
  }
  public removeFromSICCodes(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      SearchCriteria._SICCODES
    );

    if (_isNewChange) {
      _old = Array.from(this._sICCodes);
    }

    this._sICCodes.remove(val);

    this.fire("sICCodes", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(SearchCriteria._SICCODES, _old);
    }
  }
  public get minGrowPercentage(): string {
    return this._minGrowPercentage;
  }
  public setMinGrowPercentage(val: string): void {
    let isValChanged: boolean = this._minGrowPercentage !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MINGROWPERCENTAGE,
      this._minGrowPercentage
    );

    this._minGrowPercentage = val;

    this.fire("minGrowPercentage", this);
  }
  public get maxGrowPercentage(): string {
    return this._maxGrowPercentage;
  }
  public setMaxGrowPercentage(val: string): void {
    let isValChanged: boolean = this._maxGrowPercentage !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MAXGROWPERCENTAGE,
      this._maxGrowPercentage
    );

    this._maxGrowPercentage = val;

    this.fire("maxGrowPercentage", this);
  }
  public get timeFrame(): string {
    return this._timeFrame;
  }
  public setTimeFrame(val: string): void {
    let isValChanged: boolean = this._timeFrame !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._TIMEFRAME, this._timeFrame);

    this._timeFrame = val;

    this.fire("timeFrame", this);
  }
  public get department(): string {
    return this._department;
  }
  public setDepartment(val: string): void {
    let isValChanged: boolean = this._department !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._DEPARTMENT, this._department);

    this._department = val;

    this.fire("department", this);
  }
  public get fundingFromDate(): string {
    return this._fundingFromDate;
  }
  public setFundingFromDate(val: string): void {
    let isValChanged: boolean = this._fundingFromDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._FUNDINGFROMDATE,
      this._fundingFromDate
    );

    this._fundingFromDate = val;

    this.fire("fundingFromDate", this);
  }
  public get fundingToDate(): string {
    return this._fundingToDate;
  }
  public setFundingToDate(val: string): void {
    let isValChanged: boolean = this._fundingToDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._FUNDINGTODATE, this._fundingToDate);

    this._fundingToDate = val;

    this.fire("fundingToDate", this);
  }
  public get minFundingAmount(): string {
    return this._minFundingAmount;
  }
  public setMinFundingAmount(val: string): void {
    let isValChanged: boolean = this._minFundingAmount !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MINFUNDINGAMOUNT,
      this._minFundingAmount
    );

    this._minFundingAmount = val;

    this.fire("minFundingAmount", this);
  }
  public get maxFundingAmount(): string {
    return this._maxFundingAmount;
  }
  public setMaxFundingAmount(val: string): void {
    let isValChanged: boolean = this._maxFundingAmount !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MAXFUNDINGAMOUNT,
      this._maxFundingAmount
    );

    this._maxFundingAmount = val;

    this.fire("maxFundingAmount", this);
  }
  public get minFoundedYear(): string {
    return this._minFoundedYear;
  }
  public setMinFoundedYear(val: string): void {
    let isValChanged: boolean = this._minFoundedYear !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._MINFOUNDEDYEAR, this._minFoundedYear);

    this._minFoundedYear = val;

    this.fire("minFoundedYear", this);
  }
  public get maxFoundedYear(): string {
    return this._maxFoundedYear;
  }
  public setMaxFoundedYear(val: string): void {
    let isValChanged: boolean = this._maxFoundedYear !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._MAXFOUNDEDYEAR, this._maxFoundedYear);

    this._maxFoundedYear = val;

    this.fire("maxFoundedYear", this);
  }
  public get minRetailLocation(): string {
    return this._minRetailLocation;
  }
  public setMinRetailLocation(val: string): void {
    let isValChanged: boolean = this._minRetailLocation !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MINRETAILLOCATION,
      this._minRetailLocation
    );

    this._minRetailLocation = val;

    this.fire("minRetailLocation", this);
  }
  public get maxRetailLocation(): string {
    return this._maxRetailLocation;
  }
  public setMaxRetailLocation(val: string): void {
    let isValChanged: boolean = this._maxRetailLocation !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MAXRETAILLOCATION,
      this._maxRetailLocation
    );

    this._maxRetailLocation = val;

    this.fire("maxRetailLocation", this);
  }
  public get minJobPosting(): string {
    return this._minJobPosting;
  }
  public setMinJobPosting(val: string): void {
    let isValChanged: boolean = this._minJobPosting !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._MINJOBPOSTING, this._minJobPosting);

    this._minJobPosting = val;

    this.fire("minJobPosting", this);
  }
  public get maxJobPosting(): string {
    return this._maxJobPosting;
  }
  public setMaxJobPosting(val: string): void {
    let isValChanged: boolean = this._maxJobPosting !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(SearchCriteria._MAXJOBPOSTING, this._maxJobPosting);

    this._maxJobPosting = val;

    this.fire("maxJobPosting", this);
  }
  public get jobPostedFromDate(): string {
    return this._jobPostedFromDate;
  }
  public setJobPostedFromDate(val: string): void {
    let isValChanged: boolean = this._jobPostedFromDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._JOBPOSTEDFROMDATE,
      this._jobPostedFromDate
    );

    this._jobPostedFromDate = val;

    this.fire("jobPostedFromDate", this);
  }
  public get jobPostedToDate(): string {
    return this._jobPostedToDate;
  }
  public setJobPostedToDate(val: string): void {
    let isValChanged: boolean = this._jobPostedToDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._JOBPOSTEDTODATE,
      this._jobPostedToDate
    );

    this._jobPostedToDate = val;

    this.fire("jobPostedToDate", this);
  }
  public get minEmployeeRange(): string {
    return this._minEmployeeRange;
  }
  public setMinEmployeeRange(val: string): void {
    let isValChanged: boolean = this._minEmployeeRange !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MINEMPLOYEERANGE,
      this._minEmployeeRange
    );

    this._minEmployeeRange = val;

    this.fire("minEmployeeRange", this);
  }
  public get maxEmployeeRange(): string {
    return this._maxEmployeeRange;
  }
  public setMaxEmployeeRange(val: string): void {
    let isValChanged: boolean = this._maxEmployeeRange !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      SearchCriteria._MAXEMPLOYEERANGE,
      this._maxEmployeeRange
    );

    this._maxEmployeeRange = val;

    this.fire("maxEmployeeRange", this);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case SearchCriteria._PAGE: {
        this.setPage(value as number);
        break;
      }

      case SearchCriteria._PERPAGE: {
        this.setPerPage(value as number);
        break;
      }

      case SearchCriteria._SEARCHTEXT: {
        this.setSearchText(value as string);
        break;
      }

      case SearchCriteria._FULLNAME: {
        this.setFullName(value as string);
        break;
      }

      case SearchCriteria._PERSONLOCATIONS: {
        this.setPersonLocations((value as Array<any>).cast<string>().toList());
        break;
      }

      case SearchCriteria._TIMEZONES: {
        this.setTimeZones((value as Array<any>).cast<string>().toList());
        break;
      }

      case SearchCriteria._ACCOUNTLOCATION: {
        this.setAccountLocation((value as Array<any>).cast<string>().toList());
        break;
      }

      case SearchCriteria._JOBPOSTINGHIRINGFOR: {
        this.setJobPostingHiringFor(
          (value as Array<any>).cast<string>().toList()
        );
        break;
      }

      case SearchCriteria._JOBPOSTINGLOCATION: {
        this.setJobPostingLocation(
          (value as Array<any>).cast<string>().toList()
        );
        break;
      }

      case SearchCriteria._JOBTITLES: {
        this.setJobTitles((value as Array<any>).cast<string>().toList());
        break;
      }

      case SearchCriteria._LANGUAGES: {
        this.setLanguages((value as Array<any>).cast<string>().toList());
        break;
      }

      case SearchCriteria._KEYWORDS: {
        this.setKeywords((value as Array<any>).cast<string>().toList());
        break;
      }

      case SearchCriteria._COMPANYTAGIDS: {
        this.setCompanyTagIDs((value as Array<any>).cast<string>().toList());
        break;
      }

      case SearchCriteria._TECHNOLOGIES: {
        this.setTechnologies((value as Array<any>).cast<string>().toList());
        break;
      }

      case SearchCriteria._MINYEAREXP: {
        this.setMinYearExp(value as string);
        break;
      }

      case SearchCriteria._MAXYEAREXP: {
        this.setMaxYearExp(value as string);
        break;
      }

      case SearchCriteria._MINYEARCURRENTROLE: {
        this.setMinYearCurrentRole(value as string);
        break;
      }

      case SearchCriteria._MAXYEARCURRENTROLE: {
        this.setMaxYearCurrentRole(value as string);
        break;
      }

      case SearchCriteria._MINMONTHCURRENTROLE: {
        this.setMinMonthCurrentRole(value as string);
        break;
      }

      case SearchCriteria._MAXMONTHCURRENTROLE: {
        this.setMaxMonthCurrentRole(value as string);
        break;
      }

      case SearchCriteria._SICCODES: {
        this.setSICCodes((value as Array<any>).cast<string>().toList());
        break;
      }

      case SearchCriteria._MINGROWPERCENTAGE: {
        this.setMinGrowPercentage(value as string);
        break;
      }

      case SearchCriteria._MAXGROWPERCENTAGE: {
        this.setMaxGrowPercentage(value as string);
        break;
      }

      case SearchCriteria._TIMEFRAME: {
        this.setTimeFrame(value as string);
        break;
      }

      case SearchCriteria._DEPARTMENT: {
        this.setDepartment(value as string);
        break;
      }

      case SearchCriteria._FUNDINGFROMDATE: {
        this.setFundingFromDate(value as string);
        break;
      }

      case SearchCriteria._FUNDINGTODATE: {
        this.setFundingToDate(value as string);
        break;
      }

      case SearchCriteria._MINFUNDINGAMOUNT: {
        this.setMinFundingAmount(value as string);
        break;
      }

      case SearchCriteria._MAXFUNDINGAMOUNT: {
        this.setMaxFundingAmount(value as string);
        break;
      }

      case SearchCriteria._MINFOUNDEDYEAR: {
        this.setMinFoundedYear(value as string);
        break;
      }

      case SearchCriteria._MAXFOUNDEDYEAR: {
        this.setMaxFoundedYear(value as string);
        break;
      }

      case SearchCriteria._MINRETAILLOCATION: {
        this.setMinRetailLocation(value as string);
        break;
      }

      case SearchCriteria._MAXRETAILLOCATION: {
        this.setMaxRetailLocation(value as string);
        break;
      }

      case SearchCriteria._MINJOBPOSTING: {
        this.setMinJobPosting(value as string);
        break;
      }

      case SearchCriteria._MAXJOBPOSTING: {
        this.setMaxJobPosting(value as string);
        break;
      }

      case SearchCriteria._JOBPOSTEDFROMDATE: {
        this.setJobPostedFromDate(value as string);
        break;
      }

      case SearchCriteria._JOBPOSTEDTODATE: {
        this.setJobPostedToDate(value as string);
        break;
      }

      case SearchCriteria._MINEMPLOYEERANGE: {
        this.setMinEmployeeRange(value as string);
        break;
      }

      case SearchCriteria._MAXEMPLOYEERANGE: {
        this.setMaxEmployeeRange(value as string);
        break;
      }
    }
  }
  public get(field: number): any {
    switch (field) {
      case SearchCriteria._PAGE: {
        return this._page;
      }

      case SearchCriteria._PERPAGE: {
        return this._perPage;
      }

      case SearchCriteria._SEARCHTEXT: {
        return this._searchText;
      }

      case SearchCriteria._FULLNAME: {
        return this._fullName;
      }

      case SearchCriteria._PERSONLOCATIONS: {
        return this._personLocations;
      }

      case SearchCriteria._TIMEZONES: {
        return this._timeZones;
      }

      case SearchCriteria._ACCOUNTLOCATION: {
        return this._accountLocation;
      }

      case SearchCriteria._JOBPOSTINGHIRINGFOR: {
        return this._jobPostingHiringFor;
      }

      case SearchCriteria._JOBPOSTINGLOCATION: {
        return this._jobPostingLocation;
      }

      case SearchCriteria._JOBTITLES: {
        return this._jobTitles;
      }

      case SearchCriteria._LANGUAGES: {
        return this._languages;
      }

      case SearchCriteria._KEYWORDS: {
        return this._keywords;
      }

      case SearchCriteria._COMPANYTAGIDS: {
        return this._companyTagIDs;
      }

      case SearchCriteria._TECHNOLOGIES: {
        return this._technologies;
      }

      case SearchCriteria._MINYEAREXP: {
        return this._minYearExp;
      }

      case SearchCriteria._MAXYEAREXP: {
        return this._maxYearExp;
      }

      case SearchCriteria._MINYEARCURRENTROLE: {
        return this._minYearCurrentRole;
      }

      case SearchCriteria._MAXYEARCURRENTROLE: {
        return this._maxYearCurrentRole;
      }

      case SearchCriteria._MINMONTHCURRENTROLE: {
        return this._minMonthCurrentRole;
      }

      case SearchCriteria._MAXMONTHCURRENTROLE: {
        return this._maxMonthCurrentRole;
      }

      case SearchCriteria._SICCODES: {
        return this._sICCodes;
      }

      case SearchCriteria._MINGROWPERCENTAGE: {
        return this._minGrowPercentage;
      }

      case SearchCriteria._MAXGROWPERCENTAGE: {
        return this._maxGrowPercentage;
      }

      case SearchCriteria._TIMEFRAME: {
        return this._timeFrame;
      }

      case SearchCriteria._DEPARTMENT: {
        return this._department;
      }

      case SearchCriteria._FUNDINGFROMDATE: {
        return this._fundingFromDate;
      }

      case SearchCriteria._FUNDINGTODATE: {
        return this._fundingToDate;
      }

      case SearchCriteria._MINFUNDINGAMOUNT: {
        return this._minFundingAmount;
      }

      case SearchCriteria._MAXFUNDINGAMOUNT: {
        return this._maxFundingAmount;
      }

      case SearchCriteria._MINFOUNDEDYEAR: {
        return this._minFoundedYear;
      }

      case SearchCriteria._MAXFOUNDEDYEAR: {
        return this._maxFoundedYear;
      }

      case SearchCriteria._MINRETAILLOCATION: {
        return this._minRetailLocation;
      }

      case SearchCriteria._MAXRETAILLOCATION: {
        return this._maxRetailLocation;
      }

      case SearchCriteria._MINJOBPOSTING: {
        return this._minJobPosting;
      }

      case SearchCriteria._MAXJOBPOSTING: {
        return this._maxJobPosting;
      }

      case SearchCriteria._JOBPOSTEDFROMDATE: {
        return this._jobPostedFromDate;
      }

      case SearchCriteria._JOBPOSTEDTODATE: {
        return this._jobPostedToDate;
      }

      case SearchCriteria._MINEMPLOYEERANGE: {
        return this._minEmployeeRange;
      }

      case SearchCriteria._MAXEMPLOYEERANGE: {
        return this._maxEmployeeRange;
      }
      default: {
        return null;
      }
    }
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof SearchCriteria &&
        this._page === other._page &&
        this._perPage === other._perPage &&
        this._searchText === other._searchText &&
        this._fullName === other._fullName &&
        this._personLocations === other._personLocations &&
        this._timeZones === other._timeZones &&
        this._accountLocation === other._accountLocation &&
        this._jobPostingHiringFor === other._jobPostingHiringFor &&
        this._jobPostingLocation === other._jobPostingLocation &&
        this._jobTitles === other._jobTitles &&
        this._languages === other._languages &&
        this._keywords === other._keywords &&
        this._companyTagIDs === other._companyTagIDs &&
        this._technologies === other._technologies &&
        this._minYearExp === other._minYearExp &&
        this._maxYearExp === other._maxYearExp &&
        this._minYearCurrentRole === other._minYearCurrentRole &&
        this._maxYearCurrentRole === other._maxYearCurrentRole &&
        this._minMonthCurrentRole === other._minMonthCurrentRole &&
        this._maxMonthCurrentRole === other._maxMonthCurrentRole &&
        this._sICCodes === other._sICCodes &&
        this._minGrowPercentage === other._minGrowPercentage &&
        this._maxGrowPercentage === other._maxGrowPercentage &&
        this._timeFrame === other._timeFrame &&
        this._department === other._department &&
        this._fundingFromDate === other._fundingFromDate &&
        this._fundingToDate === other._fundingToDate &&
        this._minFundingAmount === other._minFundingAmount &&
        this._maxFundingAmount === other._maxFundingAmount &&
        this._minFoundedYear === other._minFoundedYear &&
        this._maxFoundedYear === other._maxFoundedYear &&
        this._minRetailLocation === other._minRetailLocation &&
        this._maxRetailLocation === other._maxRetailLocation &&
        this._minJobPosting === other._minJobPosting &&
        this._maxJobPosting === other._maxJobPosting &&
        this._jobPostedFromDate === other._jobPostedFromDate &&
        this._jobPostedToDate === other._jobPostedToDate &&
        this._minEmployeeRange === other._minEmployeeRange &&
        this._maxEmployeeRange === other._maxEmployeeRange)
    );
  }
  public get hashCode(): number {
    return (
      (this._page?.hashCode ?? 0) +
      (this._perPage?.hashCode ?? 0) +
      (this._searchText?.hashCode ?? 0) +
      (this._fullName?.hashCode ?? 0) +
      (this._personLocations?.hashCode ?? 0) +
      (this._timeZones?.hashCode ?? 0) +
      (this._accountLocation?.hashCode ?? 0) +
      (this._jobPostingHiringFor?.hashCode ?? 0) +
      (this._jobPostingLocation?.hashCode ?? 0) +
      (this._jobTitles?.hashCode ?? 0) +
      (this._languages?.hashCode ?? 0) +
      (this._keywords?.hashCode ?? 0) +
      (this._companyTagIDs?.hashCode ?? 0) +
      (this._technologies?.hashCode ?? 0) +
      (this._minYearExp?.hashCode ?? 0) +
      (this._maxYearExp?.hashCode ?? 0) +
      (this._minYearCurrentRole?.hashCode ?? 0) +
      (this._maxYearCurrentRole?.hashCode ?? 0) +
      (this._minMonthCurrentRole?.hashCode ?? 0) +
      (this._maxMonthCurrentRole?.hashCode ?? 0) +
      (this._sICCodes?.hashCode ?? 0) +
      (this._minGrowPercentage?.hashCode ?? 0) +
      (this._maxGrowPercentage?.hashCode ?? 0) +
      (this._timeFrame?.hashCode ?? 0) +
      (this._department?.hashCode ?? 0) +
      (this._fundingFromDate?.hashCode ?? 0) +
      (this._fundingToDate?.hashCode ?? 0) +
      (this._minFundingAmount?.hashCode ?? 0) +
      (this._maxFundingAmount?.hashCode ?? 0) +
      (this._minFoundedYear?.hashCode ?? 0) +
      (this._maxFoundedYear?.hashCode ?? 0) +
      (this._minRetailLocation?.hashCode ?? 0) +
      (this._maxRetailLocation?.hashCode ?? 0) +
      (this._minJobPosting?.hashCode ?? 0) +
      (this._maxJobPosting?.hashCode ?? 0) +
      (this._jobPostedFromDate?.hashCode ?? 0) +
      (this._jobPostedToDate?.hashCode ?? 0) +
      (this._minEmployeeRange?.hashCode ?? 0) +
      (this._maxEmployeeRange?.hashCode ?? 0)
    );
  }
}
