import * as __d3ett from "./D3ETemplateTypes";
import GlobalFunctions from "../utils/GlobalFunctions";
import FindApolloId from "../classes/FindApolloId";
import ResultStatus from "../classes/ResultStatus";
import ActiveTwilioNumbers from "../classes/ActiveTwilioNumbers";
import MaritalStatus from "../classes/MaritalStatus";
import CallStatus from "../classes/CallStatus";
import GradientType from "../classes/GradientType";
import InteractionType from "../classes/InteractionType";
import InteractionsRequest from "../models/InteractionsRequest";
import OneTimePassword from "../models/OneTimePassword";
import LeadInteractionsRequest from "../models/LeadInteractionsRequest";
import MicroSoftAcc from "../models/MicroSoftAcc";
import CompanySearchItem from "../classes/CompanySearchItem";
import LeadInteractions from "../classes/LeadInteractions";
import MessageStatus from "../classes/MessageStatus";
import MailBoxRequest from "../models/MailBoxRequest";
import FamilyInfo from "../models/FamilyInfo";
import UsersList from "../classes/UsersList";
import Company from "../models/Company";
import LeadMailBoxRequest from "../models/LeadMailBoxRequest";
import MailMessage from "../models/MailMessage";
import User from "../models/User";
import EmailStatus from "../classes/EmailStatus";
import UserRole from "../classes/UserRole";
import LeadResultItem from "../classes/LeadResultItem";
import Education from "../models/Education";
import PhoneNumberDetails from "../classes/PhoneNumberDetails";
import SearchCriteria from "../classes/SearchCriteria";
import LeadsAssignedList from "../classes/LeadsAssignedList";
import MailBox from "../classes/MailBox";
import HRMInputFieldType from "../classes/HRMInputFieldType";
import LeadAssignment from "../models/LeadAssignment";
import Child from "../models/Child";
import LeadImportFile from "../models/LeadImportFile";
import FindApolloIdRequest from "../models/FindApolloIdRequest";
import LoginResult from "../classes/LoginResult";
import LeadsListRequest from "../models/LeadsListRequest";
import Lead from "../models/Lead";
import LeadsList from "../classes/LeadsList";
import LeadMailBox from "../classes/LeadMailBox";
import ConnectionStatus from "../classes/ConnectionStatus";
import LeadAssignedUsersRequest from "../models/LeadAssignedUsersRequest";
import LeadsAssignedListRequest from "../models/LeadsAssignedListRequest";
import RecentCreatedLeads from "../classes/RecentCreatedLeads";
import ChartTheme from "../classes/ChartTheme";
import IndustryResultItem from "../classes/IndustryResultItem";
import LeadStatus from "../classes/LeadStatus";
import ChangePasswordRequest from "../models/ChangePasswordRequest";
import ActiveTwilioNumbersRequest from "../models/ActiveTwilioNumbersRequest";
import Interactions from "../classes/Interactions";
import LeadAssignedUsers from "../classes/LeadAssignedUsers";
import UsersListRequest from "../models/UsersListRequest";
import InputFieldType from "../classes/InputFieldType";
import Interaction from "../models/Interaction";
import IconType from "../classes/IconType";
import RecentCreatedLeadsRequest from "../models/RecentCreatedLeadsRequest";

export const ACTIVETWILIONUMBERS: number = 0;

export const ACTIVETWILIONUMBERSREQUEST: number = 1;

export const BASEUSER: number = 2;

export const BLOB: number = 3;

export const BOOLEAN: number = 4;

export const CALLSTATUS: number = 5;

export const CHANGEPASSWORDREQUEST: number = 6;

export const CHARTTHEME: number = 7;

export const CHILD: number = 8;

export const COMPANY: number = 9;

export const COMPANYSEARCHITEM: number = 10;

export const CONNECTIONSTATUS: number = 11;

export const DFILE: number = 12;

export const DATE: number = 13;

export const DATETIME: number = 14;

export const DOUBLE: number = 15;

export const DURATION: number = 16;

export const EDUCATION: number = 17;

export const EMAILSTATUS: number = 18;

export const FAMILYINFO: number = 19;

export const FINDAPOLLOID: number = 20;

export const FINDAPOLLOIDREQUEST: number = 21;

export const GEOLOCATION: number = 22;

export const GRADIENTTYPE: number = 23;

export const HRMINPUTFIELDTYPE: number = 24;

export const ICONTYPE: number = 25;

export const INDUSTRYRESULTITEM: number = 26;

export const INPUTFIELDTYPE: number = 27;

export const INTEGER: number = 28;

export const INTERACTION: number = 29;

export const INTERACTIONTYPE: number = 30;

export const INTERACTIONS: number = 31;

export const INTERACTIONSREQUEST: number = 32;

export const LEAD: number = 33;

export const LEADASSIGNEDUSERS: number = 34;

export const LEADASSIGNEDUSERSREQUEST: number = 35;

export const LEADASSIGNMENT: number = 36;

export const LEADIMPORTFILE: number = 37;

export const LEADINTERACTIONS: number = 38;

export const LEADINTERACTIONSREQUEST: number = 39;

export const LEADMAILBOX: number = 40;

export const LEADMAILBOXREQUEST: number = 41;

export const LEADRESULTITEM: number = 42;

export const LEADSTATUS: number = 43;

export const LEADSASSIGNEDLIST: number = 44;

export const LEADSASSIGNEDLISTREQUEST: number = 45;

export const LEADSLIST: number = 46;

export const LEADSLISTREQUEST: number = 47;

export const LOGINRESULT: number = 48;

export const MAILBOX: number = 49;

export const MAILBOXREQUEST: number = 50;

export const MAILMESSAGE: number = 51;

export const MARITALSTATUS: number = 52;

export const MESSAGESTATUS: number = 53;

export const MICROSOFTACC: number = 54;

export const ONETIMEPASSWORD: number = 55;

export const PHONENUMBERDETAILS: number = 56;

export const RECENTCREATEDLEADS: number = 57;

export const RECENTCREATEDLEADSREQUEST: number = 58;

export const RESULTSTATUS: number = 59;

export const SEARCHCRITERIA: number = 60;

export const STRING: number = 61;

export const TIME: number = 62;

export const TYPE: number = 63;

export const USER: number = 64;

export const USERROLE: number = 65;

export const USERSLIST: number = 66;

export const USERSLISTREQUEST: number = 67;

export const VOID: number = 68;

export class D3ETemplate {
  public static HASH: string = "e6c694cf8e68463cea74db9decd4af61";
  private static _usages: Array<__d3ett.D3EUsage> = [
    new __d3ett.D3EUsage(
      "Query_currentUser_LandingPage_eventHandlers_onInit_block",
      [
        new __d3ett.D3ETypeUsage(USER, [
          new __d3ett.D3EFieldUsage(0, [new __d3ett.D3ETypeUsage(COMPANY, [])]),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(7, []),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, [
            new __d3ett.D3ETypeUsage(DFILE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, []),
        ]),
        new __d3ett.D3ETypeUsage(BASEUSER, []),
      ],
      "2990db663041b1f2d8587b0b55d8b57a"
    ),
    new __d3ett.D3EUsage(
      "Query_getActiveTwilioNumbers_SettingsPage_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(ACTIVETWILIONUMBERS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(USER, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(COMPANY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "9c7ebc8f81b07ca24b2a2ffcfd5c4731"
    ),
    new __d3ett.D3EUsage(
      "Query_getFindApolloId_ApolloLeadsPage_eventHandlers_onChangedCheckboxHandler_block",
      [
        new __d3ett.D3ETypeUsage(FINDAPOLLOID, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(LEAD, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, [
                new __d3ett.D3ETypeUsage(EDUCATION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, []),
              new __d3ett.D3EFieldUsage(13, []),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, [
                new __d3ett.D3ETypeUsage(FAMILYINFO, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(CHILD, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(1, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(16, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(17, []),
              new __d3ett.D3EFieldUsage(18, []),
              new __d3ett.D3EFieldUsage(19, []),
              new __d3ett.D3EFieldUsage(21, []),
              new __d3ett.D3EFieldUsage(22, []),
              new __d3ett.D3EFieldUsage(23, []),
              new __d3ett.D3EFieldUsage(24, [
                new __d3ett.D3ETypeUsage(INTERACTION, []),
              ]),
              new __d3ett.D3EFieldUsage(26, []),
              new __d3ett.D3EFieldUsage(27, []),
              new __d3ett.D3EFieldUsage(28, []),
              new __d3ett.D3EFieldUsage(29, []),
              new __d3ett.D3EFieldUsage(30, []),
              new __d3ett.D3EFieldUsage(31, []),
              new __d3ett.D3EFieldUsage(32, []),
              new __d3ett.D3EFieldUsage(33, []),
              new __d3ett.D3EFieldUsage(34, []),
              new __d3ett.D3EFieldUsage(35, []),
              new __d3ett.D3EFieldUsage(36, []),
              new __d3ett.D3EFieldUsage(37, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "c40c0d5b16c5dc95d6458186e36638d3"
    ),
    new __d3ett.D3EUsage(
      "Query_getInteractionById_CallProgressView_interaction_fetchData",
      [
        new __d3ett.D3ETypeUsage(INTERACTION, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, []),
          new __d3ett.D3EFieldUsage(7, [
            new __d3ett.D3ETypeUsage(DFILE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, [
            new __d3ett.D3ETypeUsage(USER, []),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, [new __d3ett.D3ETypeUsage(LEAD, [])]),
          new __d3ett.D3EFieldUsage(12, []),
          new __d3ett.D3EFieldUsage(13, []),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, []),
          new __d3ett.D3EFieldUsage(16, []),
          new __d3ett.D3EFieldUsage(17, []),
          new __d3ett.D3EFieldUsage(18, []),
        ]),
      ],
      "03bc261a0d648f9c8c1e7f816922a901"
    ),
    new __d3ett.D3EUsage(
      "Query_getInteractionById_ProfileWidget_interaction_fetchData",
      [
        new __d3ett.D3ETypeUsage(INTERACTION, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, []),
          new __d3ett.D3EFieldUsage(7, [
            new __d3ett.D3ETypeUsage(DFILE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, [
            new __d3ett.D3ETypeUsage(USER, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(COMPANY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, [new __d3ett.D3ETypeUsage(LEAD, [])]),
          new __d3ett.D3EFieldUsage(12, []),
          new __d3ett.D3EFieldUsage(13, []),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, []),
          new __d3ett.D3EFieldUsage(16, []),
          new __d3ett.D3EFieldUsage(17, []),
          new __d3ett.D3EFieldUsage(18, []),
        ]),
      ],
      "22470d9928a2fb82b68449ba9e23a7f9"
    ),
    new __d3ett.D3EUsage(
      "Query_getInteractions_RoutePage_eventHandlers_onIncomingCallRecived_block",
      [
        new __d3ett.D3ETypeUsage(INTERACTIONS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(INTERACTION, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, [
                new __d3ett.D3ETypeUsage(LEAD, []),
              ]),
              new __d3ett.D3EFieldUsage(12, []),
              new __d3ett.D3EFieldUsage(13, []),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
              new __d3ett.D3EFieldUsage(17, []),
              new __d3ett.D3EFieldUsage(18, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "c9ca9d4bc387b8cb09199a2ea4fe9945"
    ),
    new __d3ett.D3EUsage(
      "Query_getLeadAssignedUsers_SalesDashboardView_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(LEADASSIGNEDUSERS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(LEADASSIGNMENT, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(3, [
                new __d3ett.D3ETypeUsage(LEAD, []),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "3c4d8dc4a35265394e80330ecc2d8aad"
    ),
    new __d3ett.D3EUsage(
      "Query_getLeadById_LeadOverviewWidget_lead_fetchData",
      [
        new __d3ett.D3ETypeUsage(LEAD, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, [
            new __d3ett.D3ETypeUsage(USER, []),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(7, []),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, []),
          new __d3ett.D3EFieldUsage(10, [
            new __d3ett.D3ETypeUsage(EDUCATION, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(11, []),
          new __d3ett.D3EFieldUsage(12, []),
          new __d3ett.D3EFieldUsage(13, []),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, [
            new __d3ett.D3ETypeUsage(FAMILYINFO, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(CHILD, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(16, [
            new __d3ett.D3ETypeUsage(USER, []),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(17, []),
          new __d3ett.D3EFieldUsage(18, []),
          new __d3ett.D3EFieldUsage(19, []),
          new __d3ett.D3EFieldUsage(21, []),
          new __d3ett.D3EFieldUsage(22, []),
          new __d3ett.D3EFieldUsage(23, []),
          new __d3ett.D3EFieldUsage(24, [
            new __d3ett.D3ETypeUsage(INTERACTION, []),
          ]),
          new __d3ett.D3EFieldUsage(26, []),
          new __d3ett.D3EFieldUsage(27, []),
          new __d3ett.D3EFieldUsage(28, []),
          new __d3ett.D3EFieldUsage(29, []),
          new __d3ett.D3EFieldUsage(30, []),
          new __d3ett.D3EFieldUsage(31, []),
          new __d3ett.D3EFieldUsage(32, []),
          new __d3ett.D3EFieldUsage(33, []),
          new __d3ett.D3EFieldUsage(34, []),
          new __d3ett.D3EFieldUsage(35, []),
          new __d3ett.D3EFieldUsage(36, []),
          new __d3ett.D3EFieldUsage(37, []),
        ]),
      ],
      "2cdfe417e83f40778aa35c8ad6ea2d89"
    ),
    new __d3ett.D3EUsage(
      "Query_getLeadInteractions_InteractionHistoriesView_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(LEADINTERACTIONS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(INTERACTION, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(USER, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(COMPANY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(7, []),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, []),
                ]),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, [
                new __d3ett.D3ETypeUsage(LEAD, []),
              ]),
              new __d3ett.D3EFieldUsage(12, []),
              new __d3ett.D3EFieldUsage(13, []),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
              new __d3ett.D3EFieldUsage(17, []),
              new __d3ett.D3EFieldUsage(18, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "d4176d1e1905879bca7a854f6a6cd27f"
    ),
    new __d3ett.D3EUsage(
      "Query_getLeadMailBox_LeadMailsPage_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(LEADMAILBOX, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(MAILMESSAGE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(LEAD, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "a161f73382cc490deed9e13219704623"
    ),
    new __d3ett.D3EUsage(
      "Query_getLeadsAssignedList_LeadManagementView_properties_leadsList_computation",
      [
        new __d3ett.D3ETypeUsage(LEADSASSIGNEDLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(LEAD, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, [
                new __d3ett.D3ETypeUsage(EDUCATION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, []),
              new __d3ett.D3EFieldUsage(13, []),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, [
                new __d3ett.D3ETypeUsage(FAMILYINFO, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(CHILD, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(1, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(16, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(17, []),
              new __d3ett.D3EFieldUsage(18, []),
              new __d3ett.D3EFieldUsage(19, []),
              new __d3ett.D3EFieldUsage(20, [
                new __d3ett.D3ETypeUsage(INTERACTION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                  new __d3ett.D3EFieldUsage(7, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, [
                    new __d3ett.D3ETypeUsage(USER, []),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, [
                    new __d3ett.D3ETypeUsage(LEAD, []),
                  ]),
                  new __d3ett.D3EFieldUsage(12, []),
                  new __d3ett.D3EFieldUsage(13, []),
                  new __d3ett.D3EFieldUsage(14, []),
                  new __d3ett.D3EFieldUsage(15, []),
                  new __d3ett.D3EFieldUsage(16, []),
                  new __d3ett.D3EFieldUsage(17, []),
                  new __d3ett.D3EFieldUsage(18, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(21, []),
              new __d3ett.D3EFieldUsage(22, []),
              new __d3ett.D3EFieldUsage(23, []),
              new __d3ett.D3EFieldUsage(24, [
                new __d3ett.D3ETypeUsage(INTERACTION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                  new __d3ett.D3EFieldUsage(7, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, [
                    new __d3ett.D3ETypeUsage(USER, []),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, [
                    new __d3ett.D3ETypeUsage(LEAD, []),
                  ]),
                  new __d3ett.D3EFieldUsage(12, []),
                  new __d3ett.D3EFieldUsage(13, []),
                  new __d3ett.D3EFieldUsage(14, []),
                  new __d3ett.D3EFieldUsage(15, []),
                  new __d3ett.D3EFieldUsage(16, []),
                  new __d3ett.D3EFieldUsage(17, []),
                  new __d3ett.D3EFieldUsage(18, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(25, [
                new __d3ett.D3ETypeUsage(LEADASSIGNMENT, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(USER, [
                      new __d3ett.D3EFieldUsage(0, [
                        new __d3ett.D3ETypeUsage(COMPANY, []),
                      ]),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(4, []),
                      new __d3ett.D3EFieldUsage(5, []),
                      new __d3ett.D3EFieldUsage(7, []),
                      new __d3ett.D3EFieldUsage(8, []),
                      new __d3ett.D3EFieldUsage(9, [
                        new __d3ett.D3ETypeUsage(DFILE, [
                          new __d3ett.D3EFieldUsage(0, []),
                          new __d3ett.D3EFieldUsage(3, []),
                          new __d3ett.D3EFieldUsage(1, []),
                          new __d3ett.D3EFieldUsage(2, []),
                        ]),
                      ]),
                      new __d3ett.D3EFieldUsage(10, []),
                      new __d3ett.D3EFieldUsage(11, []),
                    ]),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, [
                    new __d3ett.D3ETypeUsage(USER, [
                      new __d3ett.D3EFieldUsage(0, [
                        new __d3ett.D3ETypeUsage(COMPANY, []),
                      ]),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(4, []),
                      new __d3ett.D3EFieldUsage(5, []),
                      new __d3ett.D3EFieldUsage(7, []),
                      new __d3ett.D3EFieldUsage(8, []),
                      new __d3ett.D3EFieldUsage(9, [
                        new __d3ett.D3ETypeUsage(DFILE, [
                          new __d3ett.D3EFieldUsage(0, []),
                          new __d3ett.D3EFieldUsage(3, []),
                          new __d3ett.D3EFieldUsage(1, []),
                          new __d3ett.D3EFieldUsage(2, []),
                        ]),
                      ]),
                      new __d3ett.D3EFieldUsage(10, []),
                      new __d3ett.D3EFieldUsage(11, []),
                    ]),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(3, [
                    new __d3ett.D3ETypeUsage(LEAD, []),
                  ]),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(26, []),
              new __d3ett.D3EFieldUsage(27, []),
              new __d3ett.D3EFieldUsage(28, []),
              new __d3ett.D3EFieldUsage(29, []),
              new __d3ett.D3EFieldUsage(30, []),
              new __d3ett.D3EFieldUsage(31, []),
              new __d3ett.D3EFieldUsage(32, []),
              new __d3ett.D3EFieldUsage(33, []),
              new __d3ett.D3EFieldUsage(34, []),
              new __d3ett.D3EFieldUsage(35, []),
              new __d3ett.D3EFieldUsage(36, []),
              new __d3ett.D3EFieldUsage(37, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "eea17a44343a85756b98ae1e1e5618ee"
    ),
    new __d3ett.D3EUsage(
      "Query_getLeadsList_DashboardView_properties_leadsList_computation",
      [
        new __d3ett.D3ETypeUsage(LEADSLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(LEAD, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, [
                new __d3ett.D3ETypeUsage(EDUCATION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, []),
              new __d3ett.D3EFieldUsage(13, []),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, [
                new __d3ett.D3ETypeUsage(FAMILYINFO, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(CHILD, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(1, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(16, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(17, []),
              new __d3ett.D3EFieldUsage(18, []),
              new __d3ett.D3EFieldUsage(19, []),
              new __d3ett.D3EFieldUsage(21, []),
              new __d3ett.D3EFieldUsage(22, []),
              new __d3ett.D3EFieldUsage(23, []),
              new __d3ett.D3EFieldUsage(24, [
                new __d3ett.D3ETypeUsage(INTERACTION, []),
              ]),
              new __d3ett.D3EFieldUsage(25, [
                new __d3ett.D3ETypeUsage(LEADASSIGNMENT, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(USER, [
                      new __d3ett.D3EFieldUsage(0, [
                        new __d3ett.D3ETypeUsage(COMPANY, []),
                      ]),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(4, []),
                      new __d3ett.D3EFieldUsage(5, []),
                      new __d3ett.D3EFieldUsage(7, []),
                      new __d3ett.D3EFieldUsage(8, []),
                      new __d3ett.D3EFieldUsage(9, [
                        new __d3ett.D3ETypeUsage(DFILE, [
                          new __d3ett.D3EFieldUsage(0, []),
                          new __d3ett.D3EFieldUsage(3, []),
                          new __d3ett.D3EFieldUsage(1, []),
                          new __d3ett.D3EFieldUsage(2, []),
                        ]),
                      ]),
                      new __d3ett.D3EFieldUsage(10, []),
                      new __d3ett.D3EFieldUsage(11, []),
                    ]),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, [
                    new __d3ett.D3ETypeUsage(USER, [
                      new __d3ett.D3EFieldUsage(0, [
                        new __d3ett.D3ETypeUsage(COMPANY, []),
                      ]),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(4, []),
                      new __d3ett.D3EFieldUsage(5, []),
                      new __d3ett.D3EFieldUsage(7, []),
                      new __d3ett.D3EFieldUsage(8, []),
                      new __d3ett.D3EFieldUsage(9, [
                        new __d3ett.D3ETypeUsage(DFILE, [
                          new __d3ett.D3EFieldUsage(0, []),
                          new __d3ett.D3EFieldUsage(3, []),
                          new __d3ett.D3EFieldUsage(1, []),
                          new __d3ett.D3EFieldUsage(2, []),
                        ]),
                      ]),
                      new __d3ett.D3EFieldUsage(10, []),
                      new __d3ett.D3EFieldUsage(11, []),
                    ]),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(3, [
                    new __d3ett.D3ETypeUsage(LEAD, []),
                  ]),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(26, []),
              new __d3ett.D3EFieldUsage(27, []),
              new __d3ett.D3EFieldUsage(28, []),
              new __d3ett.D3EFieldUsage(29, []),
              new __d3ett.D3EFieldUsage(30, []),
              new __d3ett.D3EFieldUsage(31, []),
              new __d3ett.D3EFieldUsage(32, []),
              new __d3ett.D3EFieldUsage(33, []),
              new __d3ett.D3EFieldUsage(34, []),
              new __d3ett.D3EFieldUsage(35, []),
              new __d3ett.D3EFieldUsage(36, []),
              new __d3ett.D3EFieldUsage(37, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "92e6baa4727170c3b4d387ad1cde478b"
    ),
    new __d3ett.D3EUsage(
      "Query_getLeadsList_LeadAssignPopupView_properties_leadsList_computation",
      [
        new __d3ett.D3ETypeUsage(LEADSLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(LEAD, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, [
                new __d3ett.D3ETypeUsage(EDUCATION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, []),
              new __d3ett.D3EFieldUsage(13, []),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, [
                new __d3ett.D3ETypeUsage(FAMILYINFO, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(CHILD, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(1, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(16, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(17, []),
              new __d3ett.D3EFieldUsage(18, []),
              new __d3ett.D3EFieldUsage(19, []),
              new __d3ett.D3EFieldUsage(21, []),
              new __d3ett.D3EFieldUsage(22, []),
              new __d3ett.D3EFieldUsage(23, []),
              new __d3ett.D3EFieldUsage(24, [
                new __d3ett.D3ETypeUsage(INTERACTION, []),
              ]),
              new __d3ett.D3EFieldUsage(26, []),
              new __d3ett.D3EFieldUsage(27, []),
              new __d3ett.D3EFieldUsage(28, []),
              new __d3ett.D3EFieldUsage(29, []),
              new __d3ett.D3EFieldUsage(30, []),
              new __d3ett.D3EFieldUsage(31, []),
              new __d3ett.D3EFieldUsage(32, []),
              new __d3ett.D3EFieldUsage(33, []),
              new __d3ett.D3EFieldUsage(34, []),
              new __d3ett.D3EFieldUsage(35, []),
              new __d3ett.D3EFieldUsage(36, []),
              new __d3ett.D3EFieldUsage(37, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "be9c888372a8c42a4b4295c52d132106"
    ),
    new __d3ett.D3EUsage(
      "Query_getLeadsList_TeamOverviewView_properties_leadsList_computation",
      [
        new __d3ett.D3ETypeUsage(LEADSLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(LEAD, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, [
                new __d3ett.D3ETypeUsage(EDUCATION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, []),
              new __d3ett.D3EFieldUsage(13, []),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, [
                new __d3ett.D3ETypeUsage(FAMILYINFO, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(CHILD, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(1, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(16, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(17, []),
              new __d3ett.D3EFieldUsage(18, []),
              new __d3ett.D3EFieldUsage(19, []),
              new __d3ett.D3EFieldUsage(20, [
                new __d3ett.D3ETypeUsage(INTERACTION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                  new __d3ett.D3EFieldUsage(7, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, [
                    new __d3ett.D3ETypeUsage(USER, []),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, [
                    new __d3ett.D3ETypeUsage(LEAD, []),
                  ]),
                  new __d3ett.D3EFieldUsage(12, []),
                  new __d3ett.D3EFieldUsage(13, []),
                  new __d3ett.D3EFieldUsage(14, []),
                  new __d3ett.D3EFieldUsage(15, []),
                  new __d3ett.D3EFieldUsage(16, []),
                  new __d3ett.D3EFieldUsage(17, []),
                  new __d3ett.D3EFieldUsage(18, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(21, []),
              new __d3ett.D3EFieldUsage(22, []),
              new __d3ett.D3EFieldUsage(23, []),
              new __d3ett.D3EFieldUsage(24, [
                new __d3ett.D3ETypeUsage(INTERACTION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                  new __d3ett.D3EFieldUsage(7, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, [
                    new __d3ett.D3ETypeUsage(USER, []),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, [
                    new __d3ett.D3ETypeUsage(LEAD, []),
                  ]),
                  new __d3ett.D3EFieldUsage(12, []),
                  new __d3ett.D3EFieldUsage(13, []),
                  new __d3ett.D3EFieldUsage(14, []),
                  new __d3ett.D3EFieldUsage(15, []),
                  new __d3ett.D3EFieldUsage(16, []),
                  new __d3ett.D3EFieldUsage(17, []),
                  new __d3ett.D3EFieldUsage(18, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(25, [
                new __d3ett.D3ETypeUsage(LEADASSIGNMENT, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(USER, [
                      new __d3ett.D3EFieldUsage(0, [
                        new __d3ett.D3ETypeUsage(COMPANY, []),
                      ]),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(4, []),
                      new __d3ett.D3EFieldUsage(5, []),
                      new __d3ett.D3EFieldUsage(7, []),
                      new __d3ett.D3EFieldUsage(8, []),
                      new __d3ett.D3EFieldUsage(9, [
                        new __d3ett.D3ETypeUsage(DFILE, [
                          new __d3ett.D3EFieldUsage(0, []),
                          new __d3ett.D3EFieldUsage(3, []),
                          new __d3ett.D3EFieldUsage(1, []),
                          new __d3ett.D3EFieldUsage(2, []),
                        ]),
                      ]),
                      new __d3ett.D3EFieldUsage(10, []),
                      new __d3ett.D3EFieldUsage(11, []),
                    ]),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, [
                    new __d3ett.D3ETypeUsage(USER, [
                      new __d3ett.D3EFieldUsage(0, [
                        new __d3ett.D3ETypeUsage(COMPANY, []),
                      ]),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(4, []),
                      new __d3ett.D3EFieldUsage(5, []),
                      new __d3ett.D3EFieldUsage(7, []),
                      new __d3ett.D3EFieldUsage(8, []),
                      new __d3ett.D3EFieldUsage(9, [
                        new __d3ett.D3ETypeUsage(DFILE, [
                          new __d3ett.D3EFieldUsage(0, []),
                          new __d3ett.D3EFieldUsage(3, []),
                          new __d3ett.D3EFieldUsage(1, []),
                          new __d3ett.D3EFieldUsage(2, []),
                        ]),
                      ]),
                      new __d3ett.D3EFieldUsage(10, []),
                      new __d3ett.D3EFieldUsage(11, []),
                    ]),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(3, [
                    new __d3ett.D3ETypeUsage(LEAD, []),
                  ]),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(26, []),
              new __d3ett.D3EFieldUsage(27, []),
              new __d3ett.D3EFieldUsage(28, []),
              new __d3ett.D3EFieldUsage(29, []),
              new __d3ett.D3EFieldUsage(30, []),
              new __d3ett.D3EFieldUsage(31, []),
              new __d3ett.D3EFieldUsage(32, []),
              new __d3ett.D3EFieldUsage(33, []),
              new __d3ett.D3EFieldUsage(34, []),
              new __d3ett.D3EFieldUsage(35, []),
              new __d3ett.D3EFieldUsage(36, []),
              new __d3ett.D3EFieldUsage(37, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "3b091be23526a0939822f651786dbb80"
    ),
    new __d3ett.D3EUsage(
      "Query_getMailBox_MailsPage_properties_query_computation",
      [
        new __d3ett.D3ETypeUsage(MAILBOX, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(MAILMESSAGE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(LEAD, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "e88a397514a8f838b32fb878c8aad4f3"
    ),
    new __d3ett.D3EUsage(
      "Query_getRecentCreatedLeads_RecentConnectionView_properties_leadsList_computation",
      [
        new __d3ett.D3ETypeUsage(RECENTCREATEDLEADS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(LEAD, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, [
                new __d3ett.D3ETypeUsage(EDUCATION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, []),
              new __d3ett.D3EFieldUsage(13, []),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, [
                new __d3ett.D3ETypeUsage(FAMILYINFO, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(CHILD, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(1, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(16, [
                new __d3ett.D3ETypeUsage(USER, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(COMPANY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(7, []),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, []),
                ]),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(17, []),
              new __d3ett.D3EFieldUsage(18, []),
              new __d3ett.D3EFieldUsage(19, []),
              new __d3ett.D3EFieldUsage(20, [
                new __d3ett.D3ETypeUsage(INTERACTION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                  new __d3ett.D3EFieldUsage(7, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, [
                    new __d3ett.D3ETypeUsage(USER, []),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, [
                    new __d3ett.D3ETypeUsage(LEAD, []),
                  ]),
                  new __d3ett.D3EFieldUsage(12, []),
                  new __d3ett.D3EFieldUsage(13, []),
                  new __d3ett.D3EFieldUsage(14, []),
                  new __d3ett.D3EFieldUsage(15, []),
                  new __d3ett.D3EFieldUsage(16, []),
                  new __d3ett.D3EFieldUsage(17, []),
                  new __d3ett.D3EFieldUsage(18, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(21, []),
              new __d3ett.D3EFieldUsage(22, []),
              new __d3ett.D3EFieldUsage(23, []),
              new __d3ett.D3EFieldUsage(24, [
                new __d3ett.D3ETypeUsage(INTERACTION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                  new __d3ett.D3EFieldUsage(7, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, [
                    new __d3ett.D3ETypeUsage(USER, []),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, [
                    new __d3ett.D3ETypeUsage(LEAD, []),
                  ]),
                  new __d3ett.D3EFieldUsage(12, []),
                  new __d3ett.D3EFieldUsage(13, []),
                  new __d3ett.D3EFieldUsage(14, []),
                  new __d3ett.D3EFieldUsage(15, []),
                  new __d3ett.D3EFieldUsage(16, []),
                  new __d3ett.D3EFieldUsage(17, []),
                  new __d3ett.D3EFieldUsage(18, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(25, [
                new __d3ett.D3ETypeUsage(LEADASSIGNMENT, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(USER, [
                      new __d3ett.D3EFieldUsage(0, [
                        new __d3ett.D3ETypeUsage(COMPANY, []),
                      ]),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(4, []),
                      new __d3ett.D3EFieldUsage(5, []),
                      new __d3ett.D3EFieldUsage(7, []),
                      new __d3ett.D3EFieldUsage(8, []),
                      new __d3ett.D3EFieldUsage(9, [
                        new __d3ett.D3ETypeUsage(DFILE, [
                          new __d3ett.D3EFieldUsage(0, []),
                          new __d3ett.D3EFieldUsage(3, []),
                          new __d3ett.D3EFieldUsage(1, []),
                          new __d3ett.D3EFieldUsage(2, []),
                        ]),
                      ]),
                      new __d3ett.D3EFieldUsage(10, []),
                      new __d3ett.D3EFieldUsage(11, []),
                    ]),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, [
                    new __d3ett.D3ETypeUsage(USER, [
                      new __d3ett.D3EFieldUsage(0, [
                        new __d3ett.D3ETypeUsage(COMPANY, []),
                      ]),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(4, []),
                      new __d3ett.D3EFieldUsage(5, []),
                      new __d3ett.D3EFieldUsage(7, []),
                      new __d3ett.D3EFieldUsage(8, []),
                      new __d3ett.D3EFieldUsage(9, [
                        new __d3ett.D3ETypeUsage(DFILE, [
                          new __d3ett.D3EFieldUsage(0, []),
                          new __d3ett.D3EFieldUsage(3, []),
                          new __d3ett.D3EFieldUsage(1, []),
                          new __d3ett.D3EFieldUsage(2, []),
                        ]),
                      ]),
                      new __d3ett.D3EFieldUsage(10, []),
                      new __d3ett.D3EFieldUsage(11, []),
                    ]),
                    new __d3ett.D3ETypeUsage(BASEUSER, []),
                  ]),
                  new __d3ett.D3EFieldUsage(3, [
                    new __d3ett.D3ETypeUsage(LEAD, []),
                  ]),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(26, []),
              new __d3ett.D3EFieldUsage(27, []),
              new __d3ett.D3EFieldUsage(28, []),
              new __d3ett.D3EFieldUsage(29, []),
              new __d3ett.D3EFieldUsage(30, []),
              new __d3ett.D3EFieldUsage(31, []),
              new __d3ett.D3EFieldUsage(32, []),
              new __d3ett.D3EFieldUsage(33, []),
              new __d3ett.D3EFieldUsage(34, []),
              new __d3ett.D3EFieldUsage(35, []),
              new __d3ett.D3EFieldUsage(36, []),
              new __d3ett.D3EFieldUsage(37, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "ce7cd8802d1e401f5b117c7d3530ab59"
    ),
    new __d3ett.D3EUsage(
      "Query_getUserById_LeadMailsPage_user_fetchData",
      [
        new __d3ett.D3ETypeUsage(USER, [
          new __d3ett.D3EFieldUsage(0, [new __d3ett.D3ETypeUsage(COMPANY, [])]),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, [
            new __d3ett.D3ETypeUsage(MICROSOFTACC, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, [
                new __d3ett.D3ETypeUsage(USER, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(COMPANY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(7, []),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, []),
                ]),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(7, []),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, [
            new __d3ett.D3ETypeUsage(DFILE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, []),
        ]),
        new __d3ett.D3ETypeUsage(BASEUSER, []),
      ],
      "5c613685eae94eed4c41180ef9897ec3"
    ),
    new __d3ett.D3EUsage(
      "Query_getUsersList_BasicView_properties_users_computation",
      [
        new __d3ett.D3ETypeUsage(USERSLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(USER, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(COMPANY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "9dede12fb8f08427bae256a5f5329a45"
    ),
    new __d3ett.D3EUsage(
      "Query_getUsersList_TeamView_properties_usersList_computation",
      [
        new __d3ett.D3ETypeUsage(USERSLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(USER, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(COMPANY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "9dede12fb8f08427bae256a5f5329a45"
    ),
    new __d3ett.D3EUsage(
      "Query_loginManagerUserWithEmailAndPassword_LoginPage_eventHandlers_authenticatingUser_block",
      [
        new __d3ett.D3ETypeUsage(LOGINRESULT, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, [
            new __d3ett.D3ETypeUsage(USER, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(COMPANY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
        ]),
      ],
      "d82c5d89663e9c0b601a63a734342efe"
    ),
    new __d3ett.D3EUsage(
      "Query_loginWithOTP_ForgotPasswordPage_eventHandlers_verifyOTP_block",
      [
        new __d3ett.D3ETypeUsage(LOGINRESULT, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, [
            new __d3ett.D3ETypeUsage(USER, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(COMPANY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
        ]),
      ],
      "d82c5d89663e9c0b601a63a734342efe"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onActiveTwilioNumbersChange_SettingsPage_query_synchronise",
      [
        new __d3ett.D3ETypeUsage(ACTIVETWILIONUMBERS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(USER, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(COMPANY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "9c7ebc8f81b07ca24b2a2ffcfd5c4731"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onCallInteractionChange_CallProgressView_callInteraction_synchronise",
      [],
      "d41d8cd98f00b204e9800998ecf8427e"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onInteractionChange_CallProgressView_interaction_synchronise",
      [
        new __d3ett.D3ETypeUsage(INTERACTION, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, []),
          new __d3ett.D3EFieldUsage(7, [
            new __d3ett.D3ETypeUsage(DFILE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, [
            new __d3ett.D3ETypeUsage(USER, []),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, [new __d3ett.D3ETypeUsage(LEAD, [])]),
          new __d3ett.D3EFieldUsage(12, []),
          new __d3ett.D3EFieldUsage(13, []),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, []),
          new __d3ett.D3EFieldUsage(16, []),
          new __d3ett.D3EFieldUsage(17, []),
          new __d3ett.D3EFieldUsage(18, []),
        ]),
      ],
      "03bc261a0d648f9c8c1e7f816922a901"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onInteractionChange_ProfileViewWidget_lastInteraction_synchronise",
      [
        new __d3ett.D3ETypeUsage(INTERACTION, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, []),
          new __d3ett.D3EFieldUsage(7, [
            new __d3ett.D3ETypeUsage(DFILE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, [
            new __d3ett.D3ETypeUsage(USER, []),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, [new __d3ett.D3ETypeUsage(LEAD, [])]),
          new __d3ett.D3EFieldUsage(12, []),
          new __d3ett.D3EFieldUsage(13, []),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, []),
          new __d3ett.D3EFieldUsage(16, []),
          new __d3ett.D3EFieldUsage(17, []),
          new __d3ett.D3EFieldUsage(18, []),
        ]),
      ],
      "03bc261a0d648f9c8c1e7f816922a901"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onInteractionChange_ProfileWidget_interaction_synchronise",
      [
        new __d3ett.D3ETypeUsage(INTERACTION, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, []),
          new __d3ett.D3EFieldUsage(7, [
            new __d3ett.D3ETypeUsage(DFILE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, [
            new __d3ett.D3ETypeUsage(USER, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(COMPANY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, [new __d3ett.D3ETypeUsage(LEAD, [])]),
          new __d3ett.D3EFieldUsage(12, []),
          new __d3ett.D3EFieldUsage(13, []),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, []),
          new __d3ett.D3EFieldUsage(16, []),
          new __d3ett.D3EFieldUsage(17, []),
          new __d3ett.D3EFieldUsage(18, []),
        ]),
      ],
      "22470d9928a2fb82b68449ba9e23a7f9"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onInteractionChange_RecentInteractionView_lastInteraction_synchronise",
      [
        new __d3ett.D3ETypeUsage(INTERACTION, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, []),
          new __d3ett.D3EFieldUsage(7, [
            new __d3ett.D3ETypeUsage(DFILE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, [
            new __d3ett.D3ETypeUsage(USER, []),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, [new __d3ett.D3ETypeUsage(LEAD, [])]),
          new __d3ett.D3EFieldUsage(12, []),
          new __d3ett.D3EFieldUsage(13, []),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, []),
          new __d3ett.D3EFieldUsage(16, []),
          new __d3ett.D3EFieldUsage(17, []),
          new __d3ett.D3EFieldUsage(18, []),
        ]),
      ],
      "03bc261a0d648f9c8c1e7f816922a901"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onLeadChange_LeadOverviewWidget_lead_synchronise",
      [
        new __d3ett.D3ETypeUsage(LEAD, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, [
            new __d3ett.D3ETypeUsage(USER, []),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(7, []),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, []),
          new __d3ett.D3EFieldUsage(10, [
            new __d3ett.D3ETypeUsage(EDUCATION, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(11, []),
          new __d3ett.D3EFieldUsage(12, []),
          new __d3ett.D3EFieldUsage(13, []),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, [
            new __d3ett.D3ETypeUsage(FAMILYINFO, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(CHILD, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(16, [
            new __d3ett.D3ETypeUsage(USER, []),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(17, []),
          new __d3ett.D3EFieldUsage(18, []),
          new __d3ett.D3EFieldUsage(19, []),
          new __d3ett.D3EFieldUsage(21, []),
          new __d3ett.D3EFieldUsage(22, []),
          new __d3ett.D3EFieldUsage(23, []),
          new __d3ett.D3EFieldUsage(24, [
            new __d3ett.D3ETypeUsage(INTERACTION, []),
          ]),
          new __d3ett.D3EFieldUsage(26, []),
          new __d3ett.D3EFieldUsage(27, []),
          new __d3ett.D3EFieldUsage(28, []),
          new __d3ett.D3EFieldUsage(29, []),
          new __d3ett.D3EFieldUsage(30, []),
          new __d3ett.D3EFieldUsage(31, []),
          new __d3ett.D3EFieldUsage(32, []),
          new __d3ett.D3EFieldUsage(33, []),
          new __d3ett.D3EFieldUsage(34, []),
          new __d3ett.D3EFieldUsage(35, []),
          new __d3ett.D3EFieldUsage(36, []),
          new __d3ett.D3EFieldUsage(37, []),
        ]),
      ],
      "2cdfe417e83f40778aa35c8ad6ea2d89"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onLeadChange_RecentConnectionItemProfileView_lead_synchronise",
      [
        new __d3ett.D3ETypeUsage(LEAD, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(6, [
            new __d3ett.D3ETypeUsage(USER, []),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(7, []),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, []),
          new __d3ett.D3EFieldUsage(10, [
            new __d3ett.D3ETypeUsage(EDUCATION, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(11, []),
          new __d3ett.D3EFieldUsage(12, []),
          new __d3ett.D3EFieldUsage(13, []),
          new __d3ett.D3EFieldUsage(14, []),
          new __d3ett.D3EFieldUsage(15, [
            new __d3ett.D3ETypeUsage(FAMILYINFO, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, [
                new __d3ett.D3ETypeUsage(CHILD, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(16, [
            new __d3ett.D3ETypeUsage(USER, []),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(17, []),
          new __d3ett.D3EFieldUsage(18, []),
          new __d3ett.D3EFieldUsage(19, []),
          new __d3ett.D3EFieldUsage(21, []),
          new __d3ett.D3EFieldUsage(22, []),
          new __d3ett.D3EFieldUsage(23, []),
          new __d3ett.D3EFieldUsage(24, [
            new __d3ett.D3ETypeUsage(INTERACTION, []),
          ]),
          new __d3ett.D3EFieldUsage(26, []),
          new __d3ett.D3EFieldUsage(27, []),
          new __d3ett.D3EFieldUsage(28, []),
          new __d3ett.D3EFieldUsage(29, []),
          new __d3ett.D3EFieldUsage(30, []),
          new __d3ett.D3EFieldUsage(31, []),
          new __d3ett.D3EFieldUsage(32, []),
          new __d3ett.D3EFieldUsage(33, []),
          new __d3ett.D3EFieldUsage(34, []),
          new __d3ett.D3EFieldUsage(35, []),
          new __d3ett.D3EFieldUsage(36, []),
          new __d3ett.D3EFieldUsage(37, []),
        ]),
      ],
      "2cdfe417e83f40778aa35c8ad6ea2d89"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onLeadInteractionsChange_InteractionHistoriesView_query_synchronise",
      [
        new __d3ett.D3ETypeUsage(LEADINTERACTIONS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(INTERACTION, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(USER, [
                  new __d3ett.D3EFieldUsage(0, [
                    new __d3ett.D3ETypeUsage(COMPANY, []),
                  ]),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(7, []),
                  new __d3ett.D3EFieldUsage(8, []),
                  new __d3ett.D3EFieldUsage(9, [
                    new __d3ett.D3ETypeUsage(DFILE, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(3, []),
                      new __d3ett.D3EFieldUsage(1, []),
                      new __d3ett.D3EFieldUsage(2, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(10, []),
                  new __d3ett.D3EFieldUsage(11, []),
                ]),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, [
                new __d3ett.D3ETypeUsage(LEAD, []),
              ]),
              new __d3ett.D3EFieldUsage(12, []),
              new __d3ett.D3EFieldUsage(13, []),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, []),
              new __d3ett.D3EFieldUsage(16, []),
              new __d3ett.D3EFieldUsage(17, []),
              new __d3ett.D3EFieldUsage(18, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "d4176d1e1905879bca7a854f6a6cd27f"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onLeadMailBoxChange_LeadMailsPage_query_synchronise",
      [
        new __d3ett.D3ETypeUsage(LEADMAILBOX, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(MAILMESSAGE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(LEAD, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "a161f73382cc490deed9e13219704623"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onMailBoxChange_MailsPage_query_synchronise",
      [
        new __d3ett.D3ETypeUsage(MAILBOX, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(MAILMESSAGE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, [
                new __d3ett.D3ETypeUsage(LEAD, []),
              ]),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "e88a397514a8f838b32fb878c8aad4f3"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onRecentCreatedLeadsChange_RecentConnectionView_leadsList_synchronise",
      [
        new __d3ett.D3ETypeUsage(RECENTCREATEDLEADS, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, [
            new __d3ett.D3ETypeUsage(LEAD, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(6, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, []),
              new __d3ett.D3EFieldUsage(10, [
                new __d3ett.D3ETypeUsage(EDUCATION, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(11, []),
              new __d3ett.D3EFieldUsage(12, []),
              new __d3ett.D3EFieldUsage(13, []),
              new __d3ett.D3EFieldUsage(14, []),
              new __d3ett.D3EFieldUsage(15, [
                new __d3ett.D3ETypeUsage(FAMILYINFO, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(1, [
                    new __d3ett.D3ETypeUsage(CHILD, [
                      new __d3ett.D3EFieldUsage(0, []),
                      new __d3ett.D3EFieldUsage(1, []),
                    ]),
                  ]),
                  new __d3ett.D3EFieldUsage(2, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(4, []),
                  new __d3ett.D3EFieldUsage(5, []),
                  new __d3ett.D3EFieldUsage(6, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(16, [
                new __d3ett.D3ETypeUsage(USER, []),
                new __d3ett.D3ETypeUsage(BASEUSER, []),
              ]),
              new __d3ett.D3EFieldUsage(17, []),
              new __d3ett.D3EFieldUsage(18, []),
              new __d3ett.D3EFieldUsage(19, []),
              new __d3ett.D3EFieldUsage(21, []),
              new __d3ett.D3EFieldUsage(22, []),
              new __d3ett.D3EFieldUsage(23, []),
              new __d3ett.D3EFieldUsage(24, [
                new __d3ett.D3ETypeUsage(INTERACTION, []),
              ]),
              new __d3ett.D3EFieldUsage(26, []),
              new __d3ett.D3EFieldUsage(27, []),
              new __d3ett.D3EFieldUsage(28, []),
              new __d3ett.D3EFieldUsage(29, []),
              new __d3ett.D3EFieldUsage(30, []),
              new __d3ett.D3EFieldUsage(31, []),
              new __d3ett.D3EFieldUsage(32, []),
              new __d3ett.D3EFieldUsage(33, []),
              new __d3ett.D3EFieldUsage(34, []),
              new __d3ett.D3EFieldUsage(35, []),
              new __d3ett.D3EFieldUsage(36, []),
              new __d3ett.D3EFieldUsage(37, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(3, []),
        ]),
      ],
      "43d06f28063514946dca3db15d75c429"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onUserChange_RoutePage_user_synchronise",
      [
        new __d3ett.D3ETypeUsage(USER, [
          new __d3ett.D3EFieldUsage(0, [
            new __d3ett.D3ETypeUsage(COMPANY, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(1, []),
          new __d3ett.D3EFieldUsage(2, []),
          new __d3ett.D3EFieldUsage(3, []),
          new __d3ett.D3EFieldUsage(4, []),
          new __d3ett.D3EFieldUsage(5, []),
          new __d3ett.D3EFieldUsage(7, []),
          new __d3ett.D3EFieldUsage(8, []),
          new __d3ett.D3EFieldUsage(9, [
            new __d3ett.D3ETypeUsage(DFILE, [
              new __d3ett.D3EFieldUsage(0, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
            ]),
          ]),
          new __d3ett.D3EFieldUsage(10, []),
          new __d3ett.D3EFieldUsage(11, []),
        ]),
        new __d3ett.D3ETypeUsage(BASEUSER, []),
      ],
      "9dd86d9dce37236dbda6fbba55c7650c"
    ),
    new __d3ett.D3EUsage(
      "Subscription_onUsersListChange_TeamView_usersList_synchronise",
      [
        new __d3ett.D3ETypeUsage(USERSLIST, [
          new __d3ett.D3EFieldUsage(0, []),
          new __d3ett.D3EFieldUsage(1, [
            new __d3ett.D3ETypeUsage(USER, [
              new __d3ett.D3EFieldUsage(0, [
                new __d3ett.D3ETypeUsage(COMPANY, []),
              ]),
              new __d3ett.D3EFieldUsage(1, []),
              new __d3ett.D3EFieldUsage(2, []),
              new __d3ett.D3EFieldUsage(3, []),
              new __d3ett.D3EFieldUsage(4, []),
              new __d3ett.D3EFieldUsage(5, []),
              new __d3ett.D3EFieldUsage(7, []),
              new __d3ett.D3EFieldUsage(8, []),
              new __d3ett.D3EFieldUsage(9, [
                new __d3ett.D3ETypeUsage(DFILE, [
                  new __d3ett.D3EFieldUsage(0, []),
                  new __d3ett.D3EFieldUsage(3, []),
                  new __d3ett.D3EFieldUsage(1, []),
                  new __d3ett.D3EFieldUsage(2, []),
                ]),
              ]),
              new __d3ett.D3EFieldUsage(10, []),
              new __d3ett.D3EFieldUsage(11, []),
            ]),
            new __d3ett.D3ETypeUsage(BASEUSER, []),
          ]),
          new __d3ett.D3EFieldUsage(2, []),
        ]),
      ],
      "9dede12fb8f08427bae256a5f5329a45"
    ),
  ];
  private static _types: Array<__d3ett.D3ETemplateType> = [
    new __d3ett.D3ETemplateType(
      "ActiveTwilioNumbers",
      "fa3a4c821eb73eb021e90e0cf151190a",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField("items", USER, __d3ett.D3EFieldType.Ref, {
          "collection": true,
        }),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new ActiveTwilioNumbers(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "ActiveTwilioNumbersRequest",
      "d3e38314930d54a647a4d415190a6d8b",
      [
        new __d3ett.D3ETemplateField(
          "ascending",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new ActiveTwilioNumbersRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "BaseUser",
      "5116c054754a25edc5a13eddbd47e85a",
      [],
      { "abstract": true, "refType": __d3ett.D3ERefType.Model }
    ),
    new __d3ett.D3ETemplateType(
      "Blob",
      "e8016c85ada38bdc5fac616ec1318047",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Boolean",
      "27226c864bac7454a8504f8edb15d95b",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "CallStatus",
      "24e78f95912d2940bb6f62466a0ceb42",
      [
        new __d3ett.D3ETemplateField("Initiated", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "InProgress",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField("Ringing", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Answered", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Completed", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Failed", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Busy", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "Unanswered",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField("Unknown", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "Connecting",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField("Cancelled", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Rejected", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "ChangePasswordRequest",
      "7bd88617205355840f720948d2b93298",
      [
        new __d3ett.D3ETemplateField(
          "newPassword",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new ChangePasswordRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "ChartTheme",
      "e342912451bba89ca33768533f538057",
      [
        new __d3ett.D3ETemplateField("Fusion", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Gammel", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Candy", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Ocean", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Zune", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Carbon", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Umber", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "Child",
      "928974a540ab11dda3bd0be7f35c84b2",
      [
        new __d3ett.D3ETemplateField(
          "ageGroup",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new Child() }
    ),
    new __d3ett.D3ETemplateType(
      "Company",
      "5b7609cec474e2120a7e925ccdcb0d6d",
      [
        new __d3ett.D3ETemplateField(
          "enableMessaging",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "isLeadService",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "messageServiceId",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new Company() }
    ),
    new __d3ett.D3ETemplateType(
      "CompanySearchItem",
      "3337a4b2626079653ff2254cf62cdf96",
      [
        new __d3ett.D3ETemplateField(
          "logoUrl",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "orgID",
          STRING,
          __d3ett.D3EFieldType.String
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new CompanySearchItem(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "ConnectionStatus",
      "0d5c2bfbc6b6e414981c0c67321165d5",
      [
        new __d3ett.D3ETemplateField(
          "Connecting",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField("Connected", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "ConnectionBusy",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "ConnectionNormal",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "ConnectionFailed",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "RestoreFailed",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "AuthFailed",
          0,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "DFile",
      "71a781845a8ebe8adf67352a573af199",
      [
        new __d3ett.D3ETemplateField("id", STRING, __d3ett.D3EFieldType.String),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "size",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "mimeType",
          STRING,
          __d3ett.D3EFieldType.String
        ),
      ],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Date",
      "44749712dbec183e983dcd78a7736c41",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "DateTime",
      "8cf10d2341ed01492506085688270c1e",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Double",
      "d909d38d705ce75386dd86e611a82f5b",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Duration",
      "e02d2ae03de9d493df2b6b2d2813d302",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Education",
      "6f5f11679c5eebe02d0a5bd54b6484e2",
      [
        new __d3ett.D3ETemplateField(
          "degree",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "endDate",
          DATE,
          __d3ett.D3EFieldType.Date,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "fieldOfStudy",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "institution",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "startDate",
          DATE,
          __d3ett.D3EFieldType.Date,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new Education() }
    ),
    new __d3ett.D3ETemplateType(
      "EmailStatus",
      "255622c92da1799f25b64ed0e12b111b",
      [
        new __d3ett.D3ETemplateField(
          "Unavailable",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField("Verified", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "Unverified",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "UpdateRequired",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "LikelyToEngage",
          0,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "FamilyInfo",
      "8188fd6f7fea58654a2eee54e53031b8",
      [
        new __d3ett.D3ETemplateField(
          "annisversaryDate",
          DATE,
          __d3ett.D3EFieldType.Date,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "children",
          CHILD,
          __d3ett.D3EFieldType.Ref,
          { "child": true, "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "familyIncome",
          DOUBLE,
          __d3ett.D3EFieldType.Double,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "familyMembers",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "hasElderlyCareResponsibility",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "marritalStatus",
          MARITALSTATUS,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "spouseName",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new FamilyInfo() }
    ),
    new __d3ett.D3ETemplateType(
      "FindApolloId",
      "22f240000a07babda62c88453fad9f74",
      [
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField("items", LEAD, __d3ett.D3EFieldType.Ref, {
          "collection": true,
        }),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new FindApolloId(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "FindApolloIdRequest",
      "c7c29ede9c25aabdf08e159ceb8057a5",
      [
        new __d3ett.D3ETemplateField(
          "apolloId",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new FindApolloIdRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "Geolocation",
      "323d4eb70b252acb4a04eaf9e0882597",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "GradientType",
      "dfc80f7e140e2dac94987925914062a8",
      [
        new __d3ett.D3ETemplateField("Color", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "LinearGradient",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "SweepGradient",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "RadialGradient",
          0,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "HRMInputFieldType",
      "c83ed25e708464f4e384a485eff434eb",
      [
        new __d3ett.D3ETemplateField("Rectangle", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Circle", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Underline", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("None", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "IconType",
      "4529ef025543fdd73b83290b6c7b541f",
      [
        new __d3ett.D3ETemplateField("SVG", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Font", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "IndustryResultItem",
      "bf265700e3f7a4a2f955520ae3a6c3a0",
      [
        new __d3ett.D3ETemplateField(
          "keyID",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new IndustryResultItem(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "InputFieldType",
      "568d09fd20b1f156d646d0308f64e3b6",
      [
        new __d3ett.D3ETemplateField("Rectangle", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Circle", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Underline", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("None", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "Integer",
      "a0faef0851b4294c06f2b94bb1cb2044",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Interaction",
      "2312248ceb7772c84dbb773f1f70b651",
      [
        new __d3ett.D3ETemplateField(
          "body",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "callStatus",
          CALLSTATUS,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "conversation",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "details",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "duration",
          DURATION,
          __d3ett.D3EFieldType.Duration,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "endTime",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "file",
          DFILE,
          __d3ett.D3EFieldType.DFile,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "fromNumber",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "handledBy",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "isIncoming",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "lead",
          LEAD,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "messageStatus",
          MESSAGESTATUS,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "notes",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "recordingURL",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "sid",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "startTime",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "toNumber",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "type",
          INTERACTIONTYPE,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new Interaction(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "InteractionType",
      "b75a1561c88c8bb72a715d66ae585182",
      [
        new __d3ett.D3ETemplateField("Call", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("SMS", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("WhatsApp", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("VideoCall", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Email", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Meeting", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Demo", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Webinar", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "Conference",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField("Other", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "Interactions",
      "971fc9ca68a57022018cbf612bf0ffec",
      [
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          INTERACTION,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new Interactions(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "InteractionsRequest",
      "5cbb9a30f1a600c183e8ff8a60fb39ae",
      [
        new __d3ett.D3ETemplateField(
          "sid",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new InteractionsRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "Lead",
      "1d512ad96a8dbf7532ec1a8af368275a",
      [
        new __d3ett.D3ETemplateField(
          "apolloId",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "budget",
          DOUBLE,
          __d3ett.D3EFieldType.Double,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "company",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "companyRevenue",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "companySize",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "companyWebsite",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdBy",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "decisionMakingRole",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "details",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "educationHistory",
          EDUCATION,
          __d3ett.D3EFieldType.Ref,
          { "child": true, "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "email",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "emailStatus",
          EMAILSTATUS,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "expressedNeeds",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "facebookProfile",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "familyInfo",
          FAMILYINFO,
          __d3ett.D3EFieldType.Ref,
          { "child": true }
        ),
        new __d3ett.D3ETemplateField(
          "handlingUser",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "identifiedPainPoints",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "industry",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "interactionDate",
          DATE,
          __d3ett.D3EFieldType.Date,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "interactionHistory",
          INTERACTION,
          __d3ett.D3EFieldType.Ref,
          { "collection": true, "inverse": true }
        ),
        new __d3ett.D3ETemplateField(
          "interactionType",
          INTERACTIONTYPE,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "interests",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "languages",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "lastInteraction",
          INTERACTION,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "leadAssignment",
          LEADASSIGNMENT,
          __d3ett.D3EFieldType.Ref,
          { "inverse": true }
        ),
        new __d3ett.D3ETemplateField(
          "linkedInProfile",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "needs",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "pains",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "phone",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "preferredContactMethod",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "previousPositions",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "skills",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          LEADSTATUS,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "timeZone",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "twitterHandle",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "updatedDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
      ],
      { "refType": __d3ett.D3ERefType.Model, "creator": () => new Lead() }
    ),
    new __d3ett.D3ETemplateType(
      "LeadAssignedUsers",
      "af63ec7b4ade4c5539d2e5259f70bd13",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          LEADASSIGNMENT,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new LeadAssignedUsers(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadAssignedUsersRequest",
      "c9cd7078ad7b0ec0a85531a34c2e6c9a",
      [
        new __d3ett.D3ETemplateField(
          "user",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new LeadAssignedUsersRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadAssignment",
      "b33dd75eda80f54bd1c86f54266825f7",
      [
        new __d3ett.D3ETemplateField(
          "assignedBy",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "assignedDate",
          DATE,
          __d3ett.D3EFieldType.Date,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "assignedTo",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "lead",
          LEAD,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new LeadAssignment(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadImportFile",
      "3a70089b7a1c4bf79bdc686bcae83040",
      [
        new __d3ett.D3ETemplateField(
          "file",
          DFILE,
          __d3ett.D3EFieldType.DFile,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new LeadImportFile(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadInteractions",
      "9b529ff2be6ca74affd91022057c542f",
      [
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          INTERACTION,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new LeadInteractions(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadInteractionsRequest",
      "8ce8d38b2681a0b58a047a7f4338864c",
      [
        new __d3ett.D3ETemplateField(
          "handledBy",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "lead",
          LEAD,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new LeadInteractionsRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadMailBox",
      "c82edf8489e71de9ce9f9625a2bdc683",
      [
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          MAILMESSAGE,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new LeadMailBox(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadMailBoxRequest",
      "4a9981c9270d41c2d43a45bbc38d2977",
      [
        new __d3ett.D3ETemplateField(
          "lead",
          LEAD,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "searchMail",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new LeadMailBoxRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadResultItem",
      "13d0287f866654a367befbb2dd65b878",
      [
        new __d3ett.D3ETemplateField(
          "apolloId",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "birthDate",
          DATE,
          __d3ett.D3EFieldType.Date
        ),
        new __d3ett.D3ETemplateField(
          "company",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "companyRevenue",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "companySize",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "companyWebsite",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "currentPosition",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "decisionMakingRole",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "email",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "emailStatus",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "facebookProfile",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "industry",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "interactionDate",
          DATE,
          __d3ett.D3EFieldType.Date
        ),
        new __d3ett.D3ETemplateField(
          "languages",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "linkedInProfile",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "location",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "name",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "phone",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "previousPositions",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "skills",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "source",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "status",
          LEADSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField(
          "timeZone",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "twitterHandle",
          STRING,
          __d3ett.D3EFieldType.String
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new LeadResultItem(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadStatus",
      "e25e377b68ed0bdefa9c0e57cbad6338",
      [
        new __d3ett.D3ETemplateField("New", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Contacted", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "Inprogress",
          0,
          __d3ett.D3EFieldType.Enum
        ),
        new __d3ett.D3ETemplateField("Closed", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "LeadsAssignedList",
      "94bc45c1546ae53b3a1ebcd5279bea8e",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField("items", LEAD, __d3ett.D3EFieldType.Ref, {
          "collection": true,
        }),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new LeadsAssignedList(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadsAssignedListRequest",
      "8f81f5c2821daea544302e26e4e57cdb",
      [
        new __d3ett.D3ETemplateField(
          "applyStatus",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "ascending",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "orderBy",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "searchValue",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "status",
          LEADSTATUS,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "user",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new LeadsAssignedListRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LeadsList",
      "79558ca33cca383b8d94491600d38989",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField("items", LEAD, __d3ett.D3EFieldType.Ref, {
          "collection": true,
        }),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Struct, "creator": () => new LeadsList() }
    ),
    new __d3ett.D3ETemplateType(
      "LeadsListRequest",
      "25e6dbf5d3a20e6c0e2ff13bc07d93f4",
      [
        new __d3ett.D3ETemplateField(
          "applyStatus",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "ascending",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "fromDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "orderBy",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "status",
          LEADSTATUS,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "toDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "user",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new LeadsListRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "LoginResult",
      "43b15c92fa28924318ec2dd9b20d65d3",
      [
        new __d3ett.D3ETemplateField(
          "failureMessage",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "success",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean
        ),
        new __d3ett.D3ETemplateField(
          "token",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "userObject",
          BASEUSER,
          __d3ett.D3EFieldType.Ref
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new LoginResult(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "MailBox",
      "92517038275cedc339e735732ee442fe",
      [
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "items",
          MAILMESSAGE,
          __d3ett.D3EFieldType.Ref,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Struct, "creator": () => new MailBox() }
    ),
    new __d3ett.D3ETemplateType(
      "MailBoxRequest",
      "12da9abe35562a99afb1395134ca09b7",
      [
        new __d3ett.D3ETemplateField(
          "offset",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "pageSize",
          INTEGER,
          __d3ett.D3EFieldType.Integer,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "searchMail",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new MailBoxRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "MailMessage",
      "e66071f4f213cbb3ebf52b19e4e36885",
      [
        new __d3ett.D3ETemplateField(
          "body",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "content",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "inbound",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "lead",
          LEAD,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "receivedDateTime",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "recipientEmail",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "recipientName",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "senderEmail",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "senderName",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "sentDateTime",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "subject",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new MailMessage(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "MaritalStatus",
      "91f5d80e8de310888d79ec3c5a77c7a0",
      [
        new __d3ett.D3ETemplateField("Single", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Married", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Divorced", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Widowed", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "PreferNotToSay",
          0,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "MessageStatus",
      "69667ad2f142786efc6d155df0c21538",
      [
        new __d3ett.D3ETemplateField("Sent", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Delivered", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Failed", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Unknown", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "MicroSoftAcc",
      "f58439783f69c661fe17e36a6f0aba1e",
      [
        new __d3ett.D3ETemplateField(
          "accessToken",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "tokenExpersAt",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "user",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new MicroSoftAcc(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "OneTimePassword",
      "51ff4b4311b22fe068e4103b21e0b0b8",
      [
        new __d3ett.D3ETemplateField(
          "input",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "inputType",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "token",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "userType",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new OneTimePassword(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "PhoneNumberDetails",
      "4486c0dfed27669cdef40b2d729d8be3",
      [
        new __d3ett.D3ETemplateField(
          "areaCode",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "country",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "countryCode",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "locality",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "monthlyPrice",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "phoneNumber",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "sid",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "status",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new PhoneNumberDetails(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RecentCreatedLeads",
      "53a75c26e4267a4969fa40c76b15c064",
      [
        new __d3ett.D3ETemplateField(
          "count",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField("items", LEAD, __d3ett.D3EFieldType.Ref, {
          "collection": true,
        }),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new RecentCreatedLeads(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "RecentCreatedLeadsRequest",
      "53d8cba6b311dab56459e57d34753b32",
      [
        new __d3ett.D3ETemplateField(
          "user",
          USER,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new RecentCreatedLeadsRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "ResultStatus",
      "b3e4a20b998b614cb3f7bd669946ae7f",
      [
        new __d3ett.D3ETemplateField("Success", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField("Errors", 0, __d3ett.D3EFieldType.Enum),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "SearchCriteria",
      "d907ca82577d7563dbeec40f53184b7d",
      [
        new __d3ett.D3ETemplateField(
          "accountLocation",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "companyTagIDs",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "department",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "fullName",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "fundingFromDate",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "fundingToDate",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "jobPostedFromDate",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "jobPostedToDate",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "jobPostingHiringFor",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "jobPostingLocation",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "jobTitles",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "keywords",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "languages",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "maxEmployeeRange",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "maxFoundedYear",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "maxFundingAmount",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "maxGrowPercentage",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "maxJobPosting",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "maxMonthCurrentRole",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "maxRetailLocation",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "maxYearCurrentRole",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "maxYearExp",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "minEmployeeRange",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "minFoundedYear",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "minFundingAmount",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "minGrowPercentage",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "minJobPosting",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "minMonthCurrentRole",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "minRetailLocation",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "minYearCurrentRole",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "minYearExp",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "page",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "perPage",
          INTEGER,
          __d3ett.D3EFieldType.Integer
        ),
        new __d3ett.D3ETemplateField(
          "personLocations",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "sICCodes",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "searchText",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "technologies",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField(
          "timeFrame",
          STRING,
          __d3ett.D3EFieldType.String
        ),
        new __d3ett.D3ETemplateField(
          "timeZones",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Struct,
        "creator": () => new SearchCriteria(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "String",
      "27118326006d3829667a400ad23d5d98",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Time",
      "a76d4ef5f3f6a672bbfab2865563e530",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "Type",
      "a1fa27779242b4902f7ae3bdd5c6d508",
      [],
      {}
    ),
    new __d3ett.D3ETemplateType(
      "User",
      "8eff99c60e296b64414fe47353db0839",
      [
        new __d3ett.D3ETemplateField(
          "company",
          COMPANY,
          __d3ett.D3EFieldType.Ref,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "createdDate",
          DATETIME,
          __d3ett.D3EFieldType.DateTime,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "email",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "firstName",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "isRememberMe",
          BOOLEAN,
          __d3ett.D3EFieldType.Boolean,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "lastName",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "mAccount",
          MICROSOFTACC,
          __d3ett.D3EFieldType.Ref,
          { "inverse": true }
        ),
        new __d3ett.D3ETemplateField(
          "password",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "phoneNumber",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "profile",
          DFILE,
          __d3ett.D3EFieldType.DFile,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "role",
          USERROLE,
          __d3ett.D3EFieldType.Enum,
          {}
        ),
        new __d3ett.D3ETemplateField(
          "twilioNumber",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "parent": 2,
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new User(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "UserRole",
      "9858c067f0b6985ebca268b1dc84b5cc",
      [
        new __d3ett.D3ETemplateField("Manager", 0, __d3ett.D3EFieldType.Enum),
        new __d3ett.D3ETemplateField(
          "SalesPerson",
          0,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Enum }
    ),
    new __d3ett.D3ETemplateType(
      "UsersList",
      "74e93c234e300308bd87be88cb3a2b64",
      [
        new __d3ett.D3ETemplateField(
          "errors",
          STRING,
          __d3ett.D3EFieldType.String,
          { "collection": true }
        ),
        new __d3ett.D3ETemplateField("items", USER, __d3ett.D3EFieldType.Ref, {
          "collection": true,
        }),
        new __d3ett.D3ETemplateField(
          "status",
          RESULTSTATUS,
          __d3ett.D3EFieldType.Enum
        ),
      ],
      { "refType": __d3ett.D3ERefType.Struct, "creator": () => new UsersList() }
    ),
    new __d3ett.D3ETemplateType(
      "UsersListRequest",
      "ec400172355c19501405996dbb8e8fc4",
      [
        new __d3ett.D3ETemplateField(
          "searchText",
          STRING,
          __d3ett.D3EFieldType.String,
          {}
        ),
      ],
      {
        "refType": __d3ett.D3ERefType.Model,
        "creator": () => new UsersListRequest(),
      }
    ),
    new __d3ett.D3ETemplateType(
      "void",
      "cab8111fd0b710a336c898e539090e34",
      [],
      {}
    ),
  ];
  private static readonly _typeMap: Map<string, number> = Map.fromIterables(
    D3ETemplate._types.map((x) => x.name),
    Array.generate(D3ETemplate._types.length, (index) => index)
  );
  public static allFields(type: number): Array<number> {
    return Array.generate(
      D3ETemplate._types[type].fields.length,
      (index) => index
    );
  }
  public static get types(): Array<__d3ett.D3ETemplateType> {
    return D3ETemplate._types;
  }
  public static get usages(): Array<__d3ett.D3EUsage> {
    return D3ETemplate._usages;
  }
  public static typeString(val: number): string {
    return D3ETemplate._types[val].name;
  }
  public static typeInt(val: string): number {
    return D3ETemplate._typeMap.get(val);
  }
  public static _getField(type: number, val: number): __d3ett.D3ETemplateField {
    let _type: __d3ett.D3ETemplateType = D3ETemplate._types[type];

    /*
_type will have fields with index starting from _type.parentFields.
Anything less needs to be looked up in _type.parent.
*/

    if (val < _type.parentFields) {
      return D3ETemplate._getField(_type.parent, val);
    }

    /*
The field cannot be in _type's child, so subtract _type.parentField from val, and use that as index.
*/

    let adjustedIndex: number = val - _type.parentFields;

    return _type.fields[adjustedIndex];
  }
  public static fieldString(type: number, val: number): string {
    return D3ETemplate._getField(type, val).name;
  }
  public static fieldType(type: number, val: number): number {
    return D3ETemplate._getField(type, val).type;
  }
  public static isChild(type: number, val: number): boolean {
    return D3ETemplate._getField(type, val).child;
  }
  public static fieldInt(type: number, val: string): number {
    let _type: __d3ett.D3ETemplateType = D3ETemplate._types[type];

    if (_type.fieldMap.containsKey(val)) {
      return _type.fieldMap[val];
    }

    if (_type.parent != null) {
      return D3ETemplate.fieldInt(_type.parent, val);
    }

    return null;
  }
  public static isEmbedded(type: number): boolean {
    return D3ETemplate._types[type].embedded;
  }
  public static isAbstract(type: number): boolean {
    return D3ETemplate._types[type].abstract;
  }
  public static hasParent(type: number): boolean {
    return D3ETemplate._types[type].parent != null;
  }
  public static parent(type: number): number {
    return D3ETemplate._types[type].parent;
  }
  public static getEnumField<T>(type: number, field: number): T {
    switch (type) {
      case CALLSTATUS: {
        return CallStatus.values[field] as unknown as T;
      }

      case CHARTTHEME: {
        return ChartTheme.values[field] as unknown as T;
      }

      case CONNECTIONSTATUS: {
        return ConnectionStatus.values[field] as unknown as T;
      }

      case EMAILSTATUS: {
        return EmailStatus.values[field] as unknown as T;
      }

      case GRADIENTTYPE: {
        return GradientType.values[field] as unknown as T;
      }

      case HRMINPUTFIELDTYPE: {
        return HRMInputFieldType.values[field] as unknown as T;
      }

      case ICONTYPE: {
        return IconType.values[field] as unknown as T;
      }

      case INPUTFIELDTYPE: {
        return InputFieldType.values[field] as unknown as T;
      }

      case INTERACTIONTYPE: {
        return InteractionType.values[field] as unknown as T;
      }

      case LEADSTATUS: {
        return LeadStatus.values[field] as unknown as T;
      }

      case MARITALSTATUS: {
        return MaritalStatus.values[field] as unknown as T;
      }

      case MESSAGESTATUS: {
        return MessageStatus.values[field] as unknown as T;
      }

      case RESULTSTATUS: {
        return ResultStatus.values[field] as unknown as T;
      }

      case USERROLE: {
        return UserRole.values[field] as unknown as T;
      }
    }
  }
}

export class UsageConstants {
  public static readonly QUERY_CURRENTUSER_LANDINGPAGE_EVENTHANDLERS_ONINIT_BLOCK: number = 0;
  public static readonly QUERY_GETACTIVETWILIONUMBERS_SETTINGSPAGE_PROPERTIES_QUERY_COMPUTATION: number = 1;
  public static readonly QUERY_GETFINDAPOLLOID_APOLLOLEADSPAGE_EVENTHANDLERS_ONCHANGEDCHECKBOXHANDLER_BLOCK: number = 2;
  public static readonly QUERY_GETINTERACTIONBYID_CALLPROGRESSVIEW_INTERACTION_FETCHDATA: number = 3;
  public static readonly QUERY_GETINTERACTIONBYID_PROFILEWIDGET_INTERACTION_FETCHDATA: number = 4;
  public static readonly QUERY_GETINTERACTIONS_ROUTEPAGE_EVENTHANDLERS_ONINCOMINGCALLRECIVED_BLOCK: number = 5;
  public static readonly QUERY_GETLEADASSIGNEDUSERS_SALESDASHBOARDVIEW_PROPERTIES_QUERY_COMPUTATION: number = 6;
  public static readonly QUERY_GETLEADBYID_LEADOVERVIEWWIDGET_LEAD_FETCHDATA: number = 7;
  public static readonly QUERY_GETLEADINTERACTIONS_INTERACTIONHISTORIESVIEW_PROPERTIES_QUERY_COMPUTATION: number = 8;
  public static readonly QUERY_GETLEADMAILBOX_LEADMAILSPAGE_PROPERTIES_QUERY_COMPUTATION: number = 9;
  public static readonly QUERY_GETLEADSASSIGNEDLIST_LEADMANAGEMENTVIEW_PROPERTIES_LEADSLIST_COMPUTATION: number = 10;
  public static readonly QUERY_GETLEADSLIST_DASHBOARDVIEW_PROPERTIES_LEADSLIST_COMPUTATION: number = 11;
  public static readonly QUERY_GETLEADSLIST_LEADASSIGNPOPUPVIEW_PROPERTIES_LEADSLIST_COMPUTATION: number = 12;
  public static readonly QUERY_GETLEADSLIST_TEAMOVERVIEWVIEW_PROPERTIES_LEADSLIST_COMPUTATION: number = 13;
  public static readonly QUERY_GETMAILBOX_MAILSPAGE_PROPERTIES_QUERY_COMPUTATION: number = 14;
  public static readonly QUERY_GETRECENTCREATEDLEADS_RECENTCONNECTIONVIEW_PROPERTIES_LEADSLIST_COMPUTATION: number = 15;
  public static readonly QUERY_GETUSERBYID_LEADMAILSPAGE_USER_FETCHDATA: number = 16;
  public static readonly QUERY_GETUSERSLIST_BASICVIEW_PROPERTIES_USERS_COMPUTATION: number = 17;
  public static readonly QUERY_GETUSERSLIST_TEAMVIEW_PROPERTIES_USERSLIST_COMPUTATION: number = 18;
  public static readonly QUERY_LOGINMANAGERUSERWITHEMAILANDPASSWORD_LOGINPAGE_EVENTHANDLERS_AUTHENTICATINGUSER_BLOCK: number = 19;
  public static readonly QUERY_LOGINWITHOTP_FORGOTPASSWORDPAGE_EVENTHANDLERS_VERIFYOTP_BLOCK: number = 20;
  public static readonly SUBSCRIPTION_ONACTIVETWILIONUMBERSCHANGE_SETTINGSPAGE_QUERY_SYNCHRONISE: number = 21;
  public static readonly SUBSCRIPTION_ONCALLINTERACTIONCHANGE_CALLPROGRESSVIEW_CALLINTERACTION_SYNCHRONISE: number = 22;
  public static readonly SUBSCRIPTION_ONINTERACTIONCHANGE_CALLPROGRESSVIEW_INTERACTION_SYNCHRONISE: number = 23;
  public static readonly SUBSCRIPTION_ONINTERACTIONCHANGE_PROFILEVIEWWIDGET_LASTINTERACTION_SYNCHRONISE: number = 24;
  public static readonly SUBSCRIPTION_ONINTERACTIONCHANGE_PROFILEWIDGET_INTERACTION_SYNCHRONISE: number = 25;
  public static readonly SUBSCRIPTION_ONINTERACTIONCHANGE_RECENTINTERACTIONVIEW_LASTINTERACTION_SYNCHRONISE: number = 26;
  public static readonly SUBSCRIPTION_ONLEADCHANGE_LEADOVERVIEWWIDGET_LEAD_SYNCHRONISE: number = 27;
  public static readonly SUBSCRIPTION_ONLEADCHANGE_RECENTCONNECTIONITEMPROFILEVIEW_LEAD_SYNCHRONISE: number = 28;
  public static readonly SUBSCRIPTION_ONLEADINTERACTIONSCHANGE_INTERACTIONHISTORIESVIEW_QUERY_SYNCHRONISE: number = 29;
  public static readonly SUBSCRIPTION_ONLEADMAILBOXCHANGE_LEADMAILSPAGE_QUERY_SYNCHRONISE: number = 30;
  public static readonly SUBSCRIPTION_ONMAILBOXCHANGE_MAILSPAGE_QUERY_SYNCHRONISE: number = 31;
  public static readonly SUBSCRIPTION_ONRECENTCREATEDLEADSCHANGE_RECENTCONNECTIONVIEW_LEADSLIST_SYNCHRONISE: number = 32;
  public static readonly SUBSCRIPTION_ONUSERCHANGE_ROUTEPAGE_USER_SYNCHRONISE: number = 33;
  public static readonly SUBSCRIPTION_ONUSERSLISTCHANGE_TEAMVIEW_USERSLIST_SYNCHRONISE: number = 34;
}

export class ChannelConstants {
  public static readonly TOTAL_CHANNEL_COUNT: number = 0;
  public static readonly channels: Array<__d3ett.D3ETemplateClass> = [];
}

export class RPCConstants {
  public static readonly UniqueChecker: number = 0;
  public static readonly UniqueCheckerCheckTokenUniqueInOneTimePassword: number = 0;
  public static readonly UniqueCheckerCheckUserEmailUniqueInCompany: number = 1;
  public static readonly UNIQUECHECKER_PROCEDURE_COUNT: number = 2;
  public static readonly FileService: number = 1;
  public static readonly FILESERVICE_PROCEDURE_COUNT: number = 0;
  public static readonly ExportFileUtils: number = 2;
  public static readonly ExportFileUtilsCreateSampleCSVForLeadData: number = 0;
  public static readonly EXPORTFILEUTILS_PROCEDURE_COUNT: number = 1;
  public static readonly CallService: number = 3;
  public static readonly CallServiceGetAccessToken: number = 0;
  public static readonly CallServiceGetAudioText: number = 1;
  public static readonly CallServiceSearchAvailableNumbers: number = 2;
  public static readonly CallServiceBuyNumber: number = 3;
  public static readonly CallServiceConfigureNumberWebhooks: number = 4;
  public static readonly CallServiceGetUnconfiguredNumbers: number = 5;
  public static readonly CallServiceReleaseNumber: number = 6;
  public static readonly CallServiceSendSMS: number = 7;
  public static readonly CallServiceRemoveCallBacks: number = 8;
  public static readonly CALLSERVICE_PROCEDURE_COUNT: number = 9;
  public static readonly LeadService: number = 4;
  public static readonly LeadServiceReadEmailsFromLead: number = 0;
  public static readonly LeadServiceCreateLink: number = 1;
  public static readonly LeadServiceRefreshAccessToken: number = 2;
  public static readonly LEADSERVICE_PROCEDURE_COUNT: number = 3;
  public static readonly ApolloRPCService: number = 5;
  public static readonly ApolloRPCServiceSearchLeads: number = 0;
  public static readonly ApolloRPCServiceGetJobTitleSuggestions: number = 1;
  public static readonly ApolloRPCServiceGetLocationSuggestions: number = 2;
  public static readonly ApolloRPCServiceGetTechnologySuggestions: number = 3;
  public static readonly ApolloRPCServiceGetOrgCompanies: number = 4;
  public static readonly ApolloRPCServiceGetIndustriesSuggestions: number = 5;
  public static readonly ApolloRPCServiceSaveLeads: number = 6;
  public static readonly APOLLORPCSERVICE_PROCEDURE_COUNT: number = 7;
  public static readonly TOTAL_RPC_CLASS_COUNT: number = 6;
  public static readonly classes: Array<__d3ett.D3ETemplateClass> = [
    new __d3ett.D3ETemplateClass(
      "UniqueChecker",
      "d16a58eae60579e79d8a4d4f7a87ec30",
      [
        new __d3ett.D3ETemplateMethodWithReturn(
          "checkTokenUniqueInOneTimePassword",
          [
            new __d3ett.D3ETemplateParam(
              D3ETemplate.typeInt("OneTimePassword"),
              {}
            ),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {}),
          ],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "checkUserEmailUniqueInCompany",
          [
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("Company"), {}),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("User"), {}),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {}),
          ],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
      ]
    ),
    new __d3ett.D3ETemplateClass(
      "FileService",
      "e256e0e9ad423eb20c85f506f4580ce8",
      []
    ),
    new __d3ett.D3ETemplateClass(
      "ExportFileUtils",
      "37b1ce4577e28b34863fd305336b8a7d",
      [
        new __d3ett.D3ETemplateMethodWithReturn(
          "createSampleCSVForLeadData",
          [],
          D3ETemplate.typeInt("DFile"),
          {}
        ),
      ]
    ),
    new __d3ett.D3ETemplateClass(
      "CallService",
      "496a6136208f31be883829ff46f53535",
      [
        new __d3ett.D3ETemplateMethodWithReturn(
          "getAccessToken",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("User"), {})],
          D3ETemplate.typeInt("String"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "getAudioText",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {})],
          D3ETemplate.typeInt("String"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "searchAvailableNumbers",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {})],
          D3ETemplate.typeInt("PhoneNumberDetails"),
          { "returnCollection": true }
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "buyNumber",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {})],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "configureNumberWebhooks",
          [
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("User"), {}),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {}),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {}),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {}),
          ],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "getUnconfiguredNumbers",
          [],
          D3ETemplate.typeInt("PhoneNumberDetails"),
          { "returnCollection": true }
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "releaseNumber",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {})],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "sendSMS",
          [
            new __d3ett.D3ETemplateParam(
              D3ETemplate.typeInt("Interaction"),
              {}
            ),
          ],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "removeCallBacks",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {})],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
      ]
    ),
    new __d3ett.D3ETemplateClass(
      "LeadService",
      "176ffe9e80d22eae41ee4de0e980bfbe",
      [
        new __d3ett.D3ETemplateMethodWithReturn(
          "readEmailsFromLead",
          [
            new __d3ett.D3ETemplateParam(
              D3ETemplate.typeInt("MicroSoftAcc"),
              {}
            ),
            new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("Lead"), {}),
          ],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "createLink",
          [
            new __d3ett.D3ETemplateParam(
              D3ETemplate.typeInt("MicroSoftAcc"),
              {}
            ),
          ],
          D3ETemplate.typeInt("String"),
          {}
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "refreshAccessToken",
          [
            new __d3ett.D3ETemplateParam(
              D3ETemplate.typeInt("MicroSoftAcc"),
              {}
            ),
          ],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
      ]
    ),
    new __d3ett.D3ETemplateClass(
      "ApolloRPCService",
      "2c845ab4e5e9e70af3bba9ffb02e6c49",
      [
        new __d3ett.D3ETemplateMethodWithReturn(
          "searchLeads",
          [
            new __d3ett.D3ETemplateParam(
              D3ETemplate.typeInt("SearchCriteria"),
              {}
            ),
          ],
          D3ETemplate.typeInt("LeadResultItem"),
          { "returnCollection": true }
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "getJobTitleSuggestions",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {})],
          D3ETemplate.typeInt("String"),
          { "returnCollection": true }
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "getLocationSuggestions",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {})],
          D3ETemplate.typeInt("String"),
          { "returnCollection": true }
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "getTechnologySuggestions",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {})],
          D3ETemplate.typeInt("String"),
          { "returnCollection": true }
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "getOrgCompanies",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {})],
          D3ETemplate.typeInt("CompanySearchItem"),
          { "returnCollection": true }
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "getIndustriesSuggestions",
          [new __d3ett.D3ETemplateParam(D3ETemplate.typeInt("String"), {})],
          D3ETemplate.typeInt("IndustryResultItem"),
          { "returnCollection": true }
        ),
        new __d3ett.D3ETemplateMethodWithReturn(
          "saveLeads",
          [
            new __d3ett.D3ETemplateParam(
              D3ETemplate.typeInt("LeadResultItem"),
              { "collection": true }
            ),
          ],
          D3ETemplate.typeInt("Boolean"),
          {}
        ),
      ]
    ),
  ];
}
GlobalFunctions.typeInt = D3ETemplate.typeInt;

GlobalFunctions.types = D3ETemplate.types;
