import User from "./User";
import Lead from "./Lead";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class LeadInteractionsRequest extends DBObject {
  private static readonly _HANDLEDBY: number = 0;
  private static readonly _LEAD: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _lead: Lead = null;
  private _handledBy: User = null;
  public constructor(d3eParams?: Partial<{ handledBy: User; lead: Lead }>) {
    super();

    this.setHandledBy(d3eParams?.handledBy ?? null);

    this.setLead(d3eParams?.lead ?? null);
  }
  public get d3eType(): string {
    return "LeadInteractionsRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get lead(): Lead {
    return this._lead;
  }
  public setLead(val: Lead): void {
    let isValChanged: boolean = this._lead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadInteractionsRequest._LEAD, this._lead);

    this.updateObservable("lead", this._lead, val);

    this._lead = val;

    this.fire("lead", this);
  }
  public get handledBy(): User {
    return this._handledBy;
  }
  public setHandledBy(val: User): void {
    let isValChanged: boolean = this._handledBy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadInteractionsRequest._HANDLEDBY, this._handledBy);

    this.updateObservable("handledBy", this._handledBy, val);

    this._handledBy = val;

    this.fire("handledBy", this);
  }
  public get(field: number): any {
    switch (field) {
      case LeadInteractionsRequest._LEAD: {
        return this._lead;
      }

      case LeadInteractionsRequest._HANDLEDBY: {
        return this._handledBy;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): LeadInteractionsRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: LeadInteractionsRequest = dbObj as LeadInteractionsRequest;

    obj.id = this.id;

    obj.setLead(this._lead);

    obj.setHandledBy(this._handledBy);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case LeadInteractionsRequest._LEAD: {
        this.setLead(value as Lead);
        break;
      }

      case LeadInteractionsRequest._HANDLEDBY: {
        this.setHandledBy(value as User);
        break;
      }
    }
  }
}
