import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class MailBoxRequest extends DBObject {
  private static readonly _OFFSET: number = 0;
  private static readonly _PAGESIZE: number = 1;
  private static readonly _SEARCHMAIL: number = 2;
  public id: number = 0;
  public otherMaster: DBObject;
  private _searchMail: string = "";
  private _pageSize: number = 0;
  private _offset: number = 0;
  public constructor(
    d3eParams?: Partial<{
      offset: number;
      pageSize: number;
      searchMail: string;
    }>
  ) {
    super();

    this.setOffset(d3eParams?.offset ?? 0);

    this.setPageSize(d3eParams?.pageSize ?? 0);

    this.setSearchMail(d3eParams?.searchMail ?? "");
  }
  public get d3eType(): string {
    return "MailBoxRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get searchMail(): string {
    return this._searchMail;
  }
  public setSearchMail(val: string): void {
    let isValChanged: boolean = this._searchMail !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailBoxRequest._SEARCHMAIL, this._searchMail);

    this._searchMail = val;

    this.fire("searchMail", this);
  }
  public get pageSize(): number {
    return this._pageSize;
  }
  public setPageSize(val: number): void {
    let isValChanged: boolean = this._pageSize !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailBoxRequest._PAGESIZE, this._pageSize);

    this._pageSize = val;

    this.fire("pageSize", this);
  }
  public get offset(): number {
    return this._offset;
  }
  public setOffset(val: number): void {
    let isValChanged: boolean = this._offset !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailBoxRequest._OFFSET, this._offset);

    this._offset = val;

    this.fire("offset", this);
  }
  public get(field: number): any {
    switch (field) {
      case MailBoxRequest._SEARCHMAIL: {
        return this._searchMail;
      }

      case MailBoxRequest._PAGESIZE: {
        return this._pageSize;
      }

      case MailBoxRequest._OFFSET: {
        return this._offset;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): MailBoxRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: MailBoxRequest = dbObj as MailBoxRequest;

    obj.id = this.id;

    obj.setSearchMail(this._searchMail);

    obj.setPageSize(this._pageSize);

    obj.setOffset(this._offset);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case MailBoxRequest._SEARCHMAIL: {
        this.setSearchMail(value as string);
        break;
      }

      case MailBoxRequest._PAGESIZE: {
        this.setPageSize(value as number);
        break;
      }

      case MailBoxRequest._OFFSET: {
        this.setOffset(value as number);
        break;
      }
    }
  }
}
