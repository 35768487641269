import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallData from "../classes/CallData";
import LeadStatus from "../classes/LeadStatus";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import SortedHeader from "./SortedHeader";
import DataSet from "../classes/DataSet";
import LeadUtils from "../classes/LeadUtils";
import Doughnut2DChart from "./Doughnut2DChart";
import PaginationView from "./PaginationView";
import Query from "../classes/Query";
import ChartDataHelper from "../classes/ChartDataHelper";
import Button from "./Button";
import DashboardCardWidget from "./DashboardCardWidget";
import ListWrapper from "../utils/ListWrapper";
import ScrollView2 from "./ScrollView2";
import LeadsListRequest from "../models/LeadsListRequest";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import LeadsList from "../classes/LeadsList";
import TextView from "./TextView";
import UserProfileView from "./UserProfileView";
import LabelDropdown from "./LabelDropdown";
import CollectionUtils from "../utils/CollectionUtils";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _LabelDropdownOnChanged = (
  text: string,
  d3eState: TeamOverviewViewRefs
) => void;

export interface TeamOverviewViewProps extends BaseUIProps {
  key?: string;
  user: User;
  client: TwilioClient;
  data: CallData;
}
/// To store state data for TeamOverviewView
class TeamOverviewViewRefs {
  columnScrollController: ui.ScrollController = new ui.ScrollController();
  public forItemState: Map<Lead, _ForItemState> = new Map();
  public forForItem(forItem: Lead): _ForItemState {
    let res = this.forItemState.get(forItem);

    if (res == null) {
      res = new _ForItemState(this, forItem);

      this.forItemState.set(forItem, res);
    }

    return res;
  }
}

interface Button2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ForItemState;
  forItem: Lead;
}

class Button2State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button2WithState extends ObservableComponent<Button2WithStateProps> {
  button2FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button2WithStateProps) {
    super(props);

    this.initState();
  }
  public get button2(): Button2State {
    return this.props.d3eState.button2;
  }
  public get forItem(): Lead {
    return this.props.forItem;
  }
  public get d3eState(): _ForItemState {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button2", null, this.button2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("forItem", this.props.forItem);

    this.on(["button2", "forItem", "forItem.status"], this.rebuild);
  }
  public componentDidUpdate(prevProps: Button2WithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.forItem !== this.props.forItem) {
      this.updateObservable("forItem", prevProps.forItem, this.props.forItem);

      this.fire("forItem", this);
    }
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 120,
      child: Button({
        decoration:
          this.forItem.status.name === "New"
            ? new ui.BoxDecoration({
                color: cStyle.c4,
                borderRadius: ui.BorderRadius.circular(3.0),
              })
            : this.forItem.status.name === "Inprogress"
            ? new ui.BoxDecoration({
                color: cStyle.c5,
                borderRadius: ui.BorderRadius.circular(3.0),
              })
            : this.forItem.status.name === "Closed"
            ? new ui.BoxDecoration({
                color: cStyle.c7,
                borderRadius: ui.BorderRadius.circular(3.0),
              })
            : this.forItem.status.name === "Contacted"
            ? new ui.BoxDecoration({
                color: cStyle.c6,
                borderRadius: ui.BorderRadius.circular(3.0),
              })
            : new ui.BoxDecoration({
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        padding:
          this.forItem.status.name === "New"
            ? ui.EdgeInsets.symmetric({
                horizontal: 20.0,
                vertical: 5.0,
                transitions: new Map(),
              })
            : this.forItem.status.name === "Inprogress"
            ? ui.EdgeInsets.symmetric({
                horizontal: 20.0,
                vertical: 5.0,
                transitions: new Map(),
              })
            : this.forItem.status.name === "Closed"
            ? ui.EdgeInsets.symmetric({
                horizontal: 20.0,
                vertical: 5.0,
                transitions: new Map(),
              })
            : this.forItem.status.name === "Contacted"
            ? ui.EdgeInsets.symmetric({
                horizontal: 20.0,
                vertical: 5.0,
                transitions: new Map(),
              })
            : ui.EdgeInsets.symmetric({
                horizontal: 20.0,
                vertical: 5.0,
                transitions: new Map(),
              }),
        disable: this.button2.disable,
        onFocusChange: (val) => {},
        child: TextView({
          data: this.forItem.status.name,
          style: new ui.TextStyle({ color: cStyle.c14 }),
          className: "x8c6",
        }),
      }),
      className: "x2133 hc",
    });
  }
}
function Button2WithState(props: Button2WithStateProps) {
  return React.createElement(_Button2WithState, props);
}

class _ForItemState {
  parent: TeamOverviewViewRefs;
  forItem: Lead;
  button2: Button2State = new Button2State();
  public constructor(parent, forItem) {
    this.parent = parent;

    this.forItem = forItem;
  }
}

class _TeamOverviewViewState extends ObservableComponent<TeamOverviewViewProps> {
  static defaultProps = { user: null, client: null, data: null };
  d3eState: TeamOverviewViewRefs = new TeamOverviewViewRefs();
  request: LeadsListRequest = null;
  leadsList: LeadsList = null;
  leads: Array<Lead> = ListWrapper.widget(this, "leads");
  searchText: string = "";
  leadFilteredList: Array<Lead> = ListWrapper.widget(this, "leadFilteredList");
  filterByRange: string = "Today";
  doughnut2DChart: Array<DataSet> = ListWrapper.widget(this, "doughnut2DChart");
  leadsCount: string = "";
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: TeamOverviewViewProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "request",
        "request.applyStatus",
        "request.ascending",
        "request.fromDate",
        "request.offset",
        "request.orderBy",
        "request.pageSize",
        "request.status",
        "request.toDate",
        "request.user",
      ],
      this.computeLeadsList
    );

    this.computeLeadsList();

    this.on(["leadsList", "leadsList.items"], this.computeLeads);

    this.computeLeads();

    this.on(
      [
        "filterByRange",
        "leads",
        "leads.createdDate",
        "leads.name",
        "searchText",
      ],
      this.computeLeadFilteredList
    );

    this.computeLeadFilteredList();

    this.on(["leads", "leads.status"], this.computeDoughnut2DChart);

    this.computeDoughnut2DChart();

    this.on(
      ["doughnut2DChart", "doughnut2DChart.data", "doughnut2DChart.data.value"],
      this.computeLeadsCount
    );

    this.computeLeadsCount();

    this.updateSyncProperty("data", this.props.data);

    this.on(
      [
        "doughnut2DChart",
        "filterByRange",
        "leadFilteredList",
        "leadFilteredList.company",
        "leadFilteredList.leadAssignment",
        "leadFilteredList.leadAssignment.assignedBy",
        "leadFilteredList.leadAssignment.assignedBy.firstName",
        "leadFilteredList.leadAssignment.assignedBy.lastName",
        "leadFilteredList.name",
        "leadFilteredList.phone",
        "leads",
        "leads.status",
        "leadsCount",
        "request",
        "request.ascending",
        "request.offset",
        "request.orderBy",
        "request.pageSize",
        "user",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: TeamOverviewViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public setRequest(val: LeadsListRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setLeadsList(val: LeadsList): void {
    let isValChanged: boolean = this.leadsList !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("leadsList", this.leadsList, val);

    this.leadsList = val;

    this.fire("leadsList", this);
  }
  public computeLeadsList = async (): Promise<void> => {
    try {
      this.setLeadsList(
        await Query.get().getLeadsList(
          UsageConstants.QUERY_GETLEADSLIST_TEAMOVERVIEWVIEW_PROPERTIES_LEADSLIST_COMPUTATION,
          this.request
        )
      );
    } catch (exception) {
      console.log(" exception in computeLeadsList : " + exception.toString());

      this.setLeadsList(null);
    }
  };
  public setLeads(val: Array<Lead>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.leads, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("leads", this.leads, val);

    this.leads.clear();

    this.leads.addAll(val);

    this.fire("leads", this);
  }
  public addToLeads(val: Lead, index: number = -1): void {
    if (index === -1) {
      if (!this.leads.contains(val)) this.leads.add(val);
    } else {
      this.leads.remove(this.leads.elementAt(index));

      this.leads.add(val);
    }

    this.fire("leads", this, val, true);

    this.updateObservable("leads", null, val);
  }
  public removeFromLeads(val: Lead): void {
    this.leads.remove(val);

    this.fire("leads", this, val, false);

    this.removeObservable("leads", val);
  }
  public computeLeads = (): void => {
    try {
      this.setLeads(
        Array.from(this.leadsList.items.isNotEmpty ? this.leadsList.items : [])
      );
    } catch (exception) {
      console.log(" exception in computeLeads : " + exception.toString());

      this.setLeads([]);
    }
  };
  public setSearchText(val: string): void {
    let isValChanged: boolean = this.searchText !== val;

    if (!isValChanged) {
      return;
    }

    this.searchText = val;

    this.fire("searchText", this);
  }
  public setLeadFilteredList(val: Array<Lead>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.leadFilteredList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("leadFilteredList", this.leadFilteredList, val);

    this.leadFilteredList.clear();

    this.leadFilteredList.addAll(val);

    this.fire("leadFilteredList", this);
  }
  public addToLeadFilteredList(val: Lead, index: number = -1): void {
    if (index === -1) {
      if (!this.leadFilteredList.contains(val)) this.leadFilteredList.add(val);
    } else {
      this.leadFilteredList.remove(this.leadFilteredList.elementAt(index));

      this.leadFilteredList.add(val);
    }

    this.fire("leadFilteredList", this, val, true);

    this.updateObservable("leadFilteredList", null, val);
  }
  public removeFromLeadFilteredList(val: Lead): void {
    this.leadFilteredList.remove(val);

    this.fire("leadFilteredList", this, val, false);

    this.removeObservable("leadFilteredList", val);
  }
  public computeLeadFilteredList = (): void => {
    try {
      this.setLeadFilteredList(
        Array.from(
          LeadUtils.getFilterLead(this.leads, this.filterByRange)
            .where((c) =>
              c.name.toLowerCase().contains(this.searchText.toLowerCase())
            )
            .toList()
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeLeadFilteredList : " + exception.toString()
      );

      this.setLeadFilteredList([]);
    }
  };
  public setFilterByRange(val: string): void {
    let isValChanged: boolean = this.filterByRange !== val;

    if (!isValChanged) {
      return;
    }

    this.filterByRange = val;

    this.fire("filterByRange", this);
  }
  public setDoughnut2DChart(val: Array<DataSet>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.doughnut2DChart,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("doughnut2DChart", this.doughnut2DChart, val);

    this.doughnut2DChart.clear();

    this.doughnut2DChart.addAll(val);

    this.fire("doughnut2DChart", this);
  }
  public addToDoughnut2DChart(val: DataSet, index: number = -1): void {
    if (index === -1) {
      if (!this.doughnut2DChart.contains(val)) this.doughnut2DChart.add(val);
    } else {
      this.doughnut2DChart.remove(this.doughnut2DChart.elementAt(index));

      this.doughnut2DChart.add(val);
    }

    this.fire("doughnut2DChart", this, val, true);

    this.updateObservable("doughnut2DChart", null, val);
  }
  public removeFromDoughnut2DChart(val: DataSet): void {
    this.doughnut2DChart.remove(val);

    this.fire("doughnut2DChart", this, val, false);

    this.removeObservable("doughnut2DChart", val);
  }
  public computeDoughnut2DChart = async (): Promise<void> => {
    try {
      this.setDoughnut2DChart(
        Array.from(await ChartDataHelper.getStatusBasedChartData(this.leads))
      );
    } catch (exception) {
      console.log(
        " exception in computeDoughnut2DChart : " + exception.toString()
      );

      this.setDoughnut2DChart([]);
    }
  };
  public setLeadsCount(val: string): void {
    let isValChanged: boolean = this.leadsCount !== val;

    if (!isValChanged) {
      return;
    }

    this.leadsCount = val;

    this.fire("leadsCount", this);
  }
  public computeLeadsCount = (): void => {
    try {
      this.setLeadsCount(ChartDataHelper.getCount(this.doughnut2DChart));
    } catch (exception) {
      console.log(" exception in computeLeadsCount : " + exception.toString());

      this.setLeadsCount("");
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ScrollView2({
      child: ui.Column({
        children: [
          UserProfileView({
            user: this.user,
            className: "x2ef hc h",
            key: "0",
          }),
          ui.Row({
            mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
            children: [
              TextView({
                data: "Team Overview",
                style: new ui.TextStyle({
                  fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                  fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                }),
                className: "x749",
                key: "0",
              }),
              ui.Row({
                children: [
                  LabelDropdown<string>({
                    value: this.filterByRange,
                    items: [
                      "Today",
                      "Yesterday",
                      "Current Week",
                      "Current Month",
                      "Last 3 Months",
                      "Last 6 Months",
                      "This Year",
                    ],
                    onChanged: (text) => {
                      this.labelDropdownonChanged(text, this.d3eState);
                    },
                    builder: (context, item) => {
                      return TextView({ data: item });
                    },
                    key: "0",
                  }),
                ],
                key: "1",
              }),
            ],
            className: "x2a1 hc h",
            key: "1",
          }),
          ui.Row({
            crossAxisAlignment: ui.CrossAxisAlignment.start,
            children: [
              ui.Column({
                crossAxisAlignment: ui.CrossAxisAlignment.start,
                children: [
                  ui.Row({
                    children: [
                      ui.Container({
                        expand: true,
                        margin: ui.EdgeInsets.fromLTRB(
                          0.0,
                          0.0,
                          10.0,
                          0.0,
                          new Map()
                        ),
                        child: DashboardCardWidget({
                          bgcolor: cStyle.c4,
                          title: "New",
                          statusCount: this.leads.where(
                            (c) => c.status.name === "New"
                          ).length,
                        }),
                        key: "0",
                        className: "x28cf hc h",
                      }),
                      ui.Container({
                        expand: true,
                        child: DashboardCardWidget({
                          bgcolor: cStyle.c5,
                          title: "Inprogress",
                          statusCount: this.leads.where(
                            (c) => c.status.name === "Inprogress"
                          ).length,
                        }),
                        key: "1",
                        className: "xd2c hc h",
                      }),
                    ],
                    className: "x5ac8 hc h",
                    key: "0",
                  }),
                  ui.Row({
                    children: [
                      ui.Container({
                        expand: true,
                        margin: ui.EdgeInsets.fromLTRB(
                          0.0,
                          0.0,
                          10.0,
                          0.0,
                          new Map()
                        ),
                        child: DashboardCardWidget({
                          bgcolor: cStyle.c6,
                          title: "Contacted",
                          statusCount: this.leads.where(
                            (c) => c.status.name === "Contacted"
                          ).length,
                        }),
                        key: "0",
                        className: "x098 hc h",
                      }),
                      ui.Container({
                        expand: true,
                        margin: ui.EdgeInsets.zero,
                        child: DashboardCardWidget({
                          bgcolor: cStyle.c7,
                          title: "Closed",
                          statusCount: this.leads.where(
                            (c) => c.status.name === "Closed"
                          ).length,
                        }),
                        key: "1",
                        className: "xb58 hc h",
                      }),
                    ],
                    className: "x1e0 hc h",
                    key: "1",
                  }),
                ],
                className: "x79a hc h",
                key: "0",
              }),
              ui.Column({
                crossAxisAlignment: ui.CrossAxisAlignment.center,
                mainAxisAlignment: ui.MainAxisAlignment.center,
                children: [
                  TextView({
                    data: "Status Overview",
                    states: ui.joinStates(
                      { "data-visibility": this.leads.isNotEmpty },
                      {}
                    ),
                    style: new ui.TextStyle({
                      fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                      fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                    }),
                    className: "x6bc hc",
                    key: "0",
                  }),
                  this.leads.isNotEmpty
                    ? ui.Container({
                        alignment: ui.Alignment.center,
                        child: ui.Container({
                          height: 240,
                          width: 500,
                          child: Doughnut2DChart({
                            data: this.doughnut2DChart,
                            key: this.leadsCount,
                          }),
                          className: "x919 hc vc",
                        }),
                        className: "x414 hc vc",
                      })
                    : ui.Container({
                        alignment: ui.Alignment.center,
                        child: ui.Row({
                          mainAxisAlignment: ui.MainAxisAlignment.center,
                          children: [
                            TextView({
                              data: "No Data Available",
                              style: new ui.TextStyle({
                                fontWeight: ui.FontWeight.bold,
                              }),
                              className: "x820 vc",
                              key: "0",
                            }),
                          ],
                          className: "xedd hc vc h",
                        }),
                        className: "x239 hc vc h",
                      }),
                ],
                className: "x7d1 hc h",
                key: "1",
              }),
            ],
            className: "xb029 hc h",
            key: "2",
          }),
          ui.Column({
            children: [
              ui.Table({
                defaultColumnWidth: new ui.FlexColumnWidth(1),
                columnWidths: {
                  0: new ui.FlexColumnWidth(1),
                  1: new ui.FlexColumnWidth(1),
                  2: new ui.FlexColumnWidth(1),
                  3: new ui.FlexColumnWidth(1),
                  4: new ui.FlexColumnWidth(1),
                },
                children: [
                  ui.TableRow({
                    children: [
                      ui.TableCell({
                        child: SortedHeader({
                          name: "Name",
                          assending: this.request.ascending,
                          sorted: this.request.orderBy === "name",
                          onChanged: (value) => {
                            this.onNameSortedHeaderOnChangedHandler(
                              value,
                              this.d3eState
                            );
                          },
                          className: "x4f8 hc h",
                        }),
                        className: "x9d9e hc h",
                        key: "0",
                      }),
                      ui.TableCell({
                        child: TextView({
                          data: "Assigned By",
                          className: "hc",
                        }),
                        className: "xaf2 hc",
                        key: "1",
                      }),
                      ui.TableCell({
                        child: TextView({ data: "Company", className: "hc" }),
                        className: "xda26 hc",
                        key: "2",
                      }),
                      ui.TableCell({
                        child: SortedHeader({
                          name: "Phone",
                          assending: this.request.ascending,
                          sorted: this.request.orderBy === "phone",
                          onChanged: (value) => {
                            this.onPhoneSortedHeaderOnChangedHandler(
                              value,
                              this.d3eState
                            );
                          },
                          className: "x33e hc h",
                        }),
                        className: "x1bb hc h",
                        key: "3",
                      }),
                      ui.TableCell({
                        child: SortedHeader({
                          name: "Status",
                          assending: this.request.ascending,
                          sorted: this.request.orderBy === "status",
                          onChanged: (value) => {
                            this.onStatusSortedHeaderOnChangedHandler(
                              value,
                              this.d3eState
                            );
                          },
                          className: "x71f hc h",
                        }),
                        className: "xbcf hc h",
                        key: "4",
                      }),
                    ],
                    className: "xb089 hc h",
                    key: "0",
                  }),
                  this.leadFilteredList.expand((forItem) => [
                    ui.TableRow({
                      children: [
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: forItem.name,
                            className: "hc",
                          }),
                          className: "hc",
                          key: "0",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data:
                              forItem.leadAssignment.assignedBy.firstName +
                              " " +
                              forItem.leadAssignment.assignedBy.lastName,
                            className: "hc",
                          }),
                          className: "hc",
                          key: "1",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: forItem.company,
                            className: "hc",
                          }),
                          className: "hc",
                          key: "2",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: TextView({
                            data: forItem.phone,
                            className: "hc",
                          }),
                          className: "hc",
                          key: "3",
                        }),
                        ui.TableCell({
                          verticalAlignment:
                            ui.TableCellVerticalAlignment.middle,
                          child: ui.Row({
                            mainAxisAlignment: ui.MainAxisAlignment.start,
                            children: [
                              Button2WithState({
                                d3eState: this.d3eState.forForItem(forItem),
                                forItem: forItem,
                                key: "0",
                              }),
                              ui.Container({ key: "1" }),
                            ],
                            className: "xcfd hc h",
                          }),
                          className: "xa42 hc h",
                          key: "4",
                        }),
                      ],
                      onTap: (e) => {
                        e.stopPropagation();

                        this.onTableRowGestureHandler(
                          this.d3eState.forForItem(forItem)
                        );
                      },
                      className: "xbad hc h",
                      key: forItem?.ident,
                    }),
                  ]),
                ],
                className: "xe060 hc h",
                key: "0",
              }),
              ui.Row({
                mainAxisAlignment: ui.MainAxisAlignment.end,
                children: [
                  PaginationView({
                    pageSize: this.request.pageSize,
                    total:
                      this.leadFilteredList !== null
                        ? this.leadFilteredList.length
                        : 0,
                    offset: this.request.offset,
                    onChange: (pageSize, offset) => {
                      this.onChangePaginationViewHandler(
                        pageSize,
                        offset,
                        this.d3eState
                      );
                    },
                    key: "0",
                  }),
                ],
                className: "xbe4 hc h",
                key: "1",
              }),
            ],
            className: "xe92 hc h",
            key: "3",
          }),
        ],
        className: ui.join(
          this.props.className,
          "TeamOverviewView x5a2 hc vc h v"
        ),
        ...copyBaseUIProps(this.props),
      }),
      scrollDirection: ui.Axis.vertical,
      className: "TeamOverviewView x5a2 hc vc h v",
      controller: this.d3eState.columnScrollController,
    });
  }
  public onInit = (): void => {
    this.setFilterByRange("Current Week");

    this.setRequest(
      new LeadsListRequest({
        pageSize: 10,
        offset: 0,
        orderBy: "",
        ascending: true,
        user: this.user,
      })
    );
  };
  public onChangePaginationViewHandler = (
    pageSize: number,
    offset: number,
    d3eState: TeamOverviewViewRefs
  ): void => {
    this.request.setPageSize(pageSize);

    this.request.setOffset(offset);

    this.setRequest(this.request.deepClone());
  };
  public onNameSortedHeaderOnChangedHandler = (
    value: boolean,
    d3eState: TeamOverviewViewRefs
  ): void => {
    this.request.setOrderBy("name");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onPhoneSortedHeaderOnChangedHandler = (
    value: boolean,
    d3eState: TeamOverviewViewRefs
  ): void => {
    this.request.setOrderBy("phone");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onStatusSortedHeaderOnChangedHandler = (
    value: boolean,
    d3eState: TeamOverviewViewRefs
  ): void => {
    this.request.setOrderBy("status");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onTableRowGestureHandler = (d3eState: _ForItemState): void => {
    this.navigator.pushLeadOverviewPage({
      lead: d3eState.forItem,
      user: this.user,
      client: this.client,
      data: this.data,
      replace: false,
      target: "main",
    });
  };
  public labelDropdownonChanged = (
    val: string,
    d3eState: TeamOverviewViewRefs
  ): void => {
    this.setFilterByRange(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function TeamOverviewView(props: TeamOverviewViewProps) {
  return React.createElement(_TeamOverviewViewState, {
    ..._TeamOverviewViewState.defaultProps,
    ...props,
  });
}
