import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _SearchFilterViewOnSelectItem<T> = (item: T) => void;

type _OnItemOnTap<T> = (d3eState: _ItemState<T>) => void;

type _NoneOptionOnTap<T> = (d3eState: SearchFilterViewRefs<T>) => void;

export interface SearchFilterViewProps<T> extends BaseUIProps {
  key?: string;
  value?: T;
  items?: Array<T>;
  itemColor?: ui.Color;
  _itemsHash?: number;
  onSelectItem?: _SearchFilterViewOnSelectItem<T>;
}
/// To store state data for SearchFilterView
class SearchFilterViewRefs<T> {
  idController: ui.ScrollController = new ui.ScrollController();
  public itemState: Map<any, _ItemState<T>> = new Map();
  public noneOption: NoneOptionState<T> = new NoneOptionState();
  public forItem(item: any): _ItemState<T> {
    let res = this.itemState.get(item);

    if (res == null) {
      res = new _ItemState(this, item);

      this.itemState.set(item, res);
    }

    return res;
  }
}

interface NoneOptionWithStateProps<T> extends BaseUIProps {
  key?: string;
  d3eState: SearchFilterViewRefs<T>;
  _onPressedNone?: _NoneOptionOnTap<T>;
  itemColor: ui.Color;
}

class NoneOptionState<T> extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _NoneOptionWithState<T> extends ObservableComponent<
  NoneOptionWithStateProps<T>
> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: NoneOptionWithStateProps<T>) {
    super(props);

    this.initState();
  }
  public get itemColor(): ui.Color {
    return this.props.itemColor;
  }
  public get noneOption(): NoneOptionState<T> {
    return this.props.d3eState.noneOption;
  }
  public get d3eState(): SearchFilterViewRefs<T> {
    return this.props.d3eState;
  }
  public get _onPressedNone(): _NoneOptionOnTap<T> {
    return this.props._onPressedNone;
  }
  public initState() {
    super.initState();

    this.updateObservable("noneOption", null, this.noneOption);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["itemColor", "noneOption", "noneOption.hover"], this.rebuild);
  }
  public noneOptionOnEnter(event): void {
    return this.noneOption.setHover(true);
  }
  public noneOptionOnExit(event): void {
    return this.noneOption.setHover(false);
  }
  public dispose(): void {
    this.noneOption.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      states: ui.joinStates({ "data-c0": this.noneOption.hover }, {}),
      decoration: this.noneOption.hover
        ? new ui.BoxDecoration({ color: cStyle.c12 })
        : new ui.BoxDecoration({}),
      child: TextView({
        data: " --None-- ",
        style: new ui.TextStyle({ color: this.itemColor }),
        className: "xa1b hc",
      }),
      onEnter: (event) => {
        this.noneOptionOnEnter(event);
      },
      onExit: (event) => {
        this.noneOptionOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onPressedNone(this.d3eState);
      },
      className: "xbf4 hc",
    });
  }
}
function NoneOptionWithState<T>(props: NoneOptionWithStateProps<T>) {
  return React.createElement(_NoneOptionWithState<T>, props);
}

interface OnItemWithStateProps<T> extends BaseUIProps {
  key?: string;
  d3eState: _ItemState<T>;
  _onSelectListTile?: _OnItemOnTap<T>;
  item: T;
  itemColor: ui.Color;
}

class OnItemState<T> extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _OnItemWithState<T> extends ObservableComponent<OnItemWithStateProps<T>> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: OnItemWithStateProps<T>) {
    super(props);

    this.initState();
  }
  public get item(): T {
    return this.props.item;
  }
  public get itemColor(): ui.Color {
    return this.props.itemColor;
  }
  public get onItem(): OnItemState<T> {
    return this.props.d3eState.onItem;
  }
  public get d3eState(): _ItemState<T> {
    return this.props.d3eState;
  }
  public get _onSelectListTile(): _OnItemOnTap<T> {
    return this.props._onSelectListTile;
  }
  public initState() {
    super.initState();

    this.updateObservable("onItem", null, this.onItem);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["item", "itemColor", "onItem", "onItem.hover"], this.rebuild);
  }
  public onItemOnEnter(event): void {
    return this.onItem.setHover(true);
  }
  public onItemOnExit(event): void {
    return this.onItem.setHover(false);
  }
  public dispose(): void {
    this.onItem.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      states: ui.joinStates({ "data-c0": this.onItem.hover }, {}),
      decoration: this.onItem.hover
        ? new ui.BoxDecoration({ color: cStyle.c12 })
        : new ui.BoxDecoration({}),
      child: TextView({
        data: this.item !== null ? this.item.toString() : "None",
        style: new ui.TextStyle({ color: this.itemColor }),
        className: "x772 hc",
      }),
      onEnter: (event) => {
        this.onItemOnEnter(event);
      },
      onExit: (event) => {
        this.onItemOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onSelectListTile(this.d3eState);
      },
      className: "xb1fc hc",
    });
  }
}
function OnItemWithState<T>(props: OnItemWithStateProps<T>) {
  return React.createElement(_OnItemWithState<T>, props);
}

class _ItemState<T> {
  parent: SearchFilterViewRefs<T>;
  item: any;
  onItem: OnItemState<T> = new OnItemState();
  public constructor(parent, item) {
    this.parent = parent;

    this.item = item;
  }
}

class _SearchFilterViewState<T> extends ObservableComponent<
  SearchFilterViewProps<T>
> {
  static defaultProps = {
    value: null,
    itemColor: null,
    items: [],
    onSelectItem: null,
  };
  d3eState: SearchFilterViewRefs<T> = new SearchFilterViewRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchFilterViewProps<T>) {
    super(props);

    this.initState();
  }
  public get value(): T {
    return this.props.value;
  }
  public get items(): Array<T> {
    return this.props.items;
  }
  public get itemColor(): ui.Color {
    return this.props.itemColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.on(["itemColor", "items", "value"], this.rebuild);
  }
  public componentDidUpdate(prevProps: SearchFilterViewProps<T>): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.items !== this.props.items) {
      this.fire("items", this);
    }

    if (prevProps.itemColor !== this.props.itemColor) {
      this.fire("itemColor", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.ListView({
      shrinkWrap: true,
      controller: this.d3eState.idController,
      children: [
        this.items
          .toList()
          .expand((item) => [
            OnItemWithState({
              d3eState: this.d3eState.forItem(item),
              _onSelectListTile: this.onSelectListTile,
              item: item,
              itemColor: this.itemColor,
              key: item?.toString(),
            }),
          ]),
        this.value !== null
          ? NoneOptionWithState({
              d3eState: this.d3eState,
              _onPressedNone: this.onPressedNone,
              itemColor: this.itemColor,
            })
          : [],
        this.items.isEmpty
          ? TextView({ data: " No Results Found ", className: "x54d hc" })
          : [],
      ],
      className: ui.join(this.props.className, "SearchFilterView xa19 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onSelectListTile = (d3eState: _ItemState<T>): void => {
    this.onSelectItem(d3eState.item);
  };
  public onPressedNone = (d3eState: SearchFilterViewRefs<T>): void => {
    this.onSelectItem(null);
  };
  public get onSelectItem(): _SearchFilterViewOnSelectItem<T> {
    return this.props.onSelectItem;
  }
  public get noneOption() {
    return this.d3eState.noneOption;
  }
}
export default function SearchFilterView<T>(props: SearchFilterViewProps<T>) {
  return React.createElement(
    _SearchFilterViewState<T>,
    { ..._SearchFilterViewState.defaultProps, ...props },
    ListWrapper.fromInput<T>(props.items, "items")
  );
}
