import MaritalStatus from "../classes/MaritalStatus";
import ListWrapper from "../utils/ListWrapper";
import D3EDate from "../classes/D3EDate";
import Child from "./Child";
import DBObject from "../utils/DBObject";
import Result from "../classes/Result";
import CloneContext from "../utils/CloneContext";
import CollectionUtils from "../utils/CollectionUtils";

export default class FamilyInfo extends DBObject {
  private static readonly _ANNISVERSARYDATE: number = 0;
  private static readonly _CHILDREN: number = 1;
  private static readonly _FAMILYINCOME: number = 2;
  private static readonly _FAMILYMEMBERS: number = 3;
  private static readonly _HASELDERLYCARERESPONSIBILITY: number = 4;
  private static readonly _MARRITALSTATUS: number = 5;
  private static readonly _SPOUSENAME: number = 6;
  public id: number = 0;
  public otherMaster: DBObject;
  private _spouseName: string = "";
  private _children: Array<Child> = ListWrapper.child(
    this,
    "children",
    FamilyInfo._CHILDREN
  );
  private _marritalStatus: MaritalStatus = MaritalStatus.Single;
  private _familyIncome: number = 0.0;
  private _familyMembers: number = 0;
  private _hasElderlyCareResponsibility: boolean = false;
  private _annisversaryDate: D3EDate = null;
  public childPropertyInMaster: number = 0;
  public constructor(
    d3eParams?: Partial<{
      annisversaryDate: D3EDate;
      children: Array<Child>;
      familyIncome: number;
      familyMembers: number;
      hasElderlyCareResponsibility: boolean;
      marritalStatus: MaritalStatus;
      spouseName: string;
    }>
  ) {
    super();

    this.setAnnisversaryDate(d3eParams?.annisversaryDate ?? null);

    this.setChildren(
      d3eParams?.children ??
        ListWrapper.child(this, "children", FamilyInfo._CHILDREN)
    );

    this.setFamilyIncome(d3eParams?.familyIncome ?? 0.0);

    this.setFamilyMembers(d3eParams?.familyMembers ?? 0);

    this.setHasElderlyCareResponsibility(
      d3eParams?.hasElderlyCareResponsibility ?? false
    );

    this.setMarritalStatus(d3eParams?.marritalStatus ?? MaritalStatus.Single);

    this.setSpouseName(d3eParams?.spouseName ?? "");
  }
  public get d3eType(): string {
    return "FamilyInfo";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get spouseName(): string {
    return this._spouseName;
  }
  public setSpouseName(val: string): void {
    let isValChanged: boolean = this._spouseName !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FamilyInfo._SPOUSENAME, this._spouseName);

    this._spouseName = val;

    this.fire("spouseName", this);
  }
  public get children(): Array<Child> {
    return this._children;
  }
  public setChildren(val: Array<Child>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._children,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (this._children != null) {
      this._children.forEach((one) => (one.otherMaster = null));
    }

    if (val != null) {
      for (let o of val) {
        o.updateMaster(this, FamilyInfo._CHILDREN);
      }
    }

    if (!this.d3eChanges.contains(FamilyInfo._CHILDREN)) {
      let _old: Array<Child> = Array.from(this._children);

      this.updateD3EChanges(FamilyInfo._CHILDREN, _old);
    }

    this.updateObservableColl("children", this._children, val);

    this._children.clear();

    this._children.addAll(val);

    this.fire("children", this);
  }
  public addToChildren(val: Child, index: number = -1): void {
    let _old: Array<Child> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(FamilyInfo._CHILDREN);

    if (_isNewChange) {
      _old = Array.from(this._children);
    }

    val.otherMaster = this;

    val.childPropertyInMaster = FamilyInfo._CHILDREN;

    if (index === -1) {
      if (!this._children.contains(val)) this._children.add(val);
    } else {
      this._children.remove(this._children.elementAt(index));

      this._children.add(val);
    }

    this.fire("children", this, val, true);

    this.updateObservable("children", null, val);

    if (_isNewChange) {
      this.updateD3EChanges(FamilyInfo._CHILDREN, _old);
    }
  }
  public removeFromChildren(val: Child): void {
    let _old: Array<Child> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(FamilyInfo._CHILDREN);

    if (_isNewChange) {
      _old = Array.from(this._children);
    }

    this._children.remove(val);

    val.otherMaster = null;

    this.fire("children", this, val, false);

    this.removeObservable("children", val);

    if (_isNewChange) {
      this.updateD3EChanges(FamilyInfo._CHILDREN, _old);
    }
  }
  public get marritalStatus(): MaritalStatus {
    return this._marritalStatus;
  }
  public setMarritalStatus(val: MaritalStatus): void {
    let isValChanged: boolean = this._marritalStatus !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      FamilyInfo._MARRITALSTATUS,
      this._marritalStatus.index
    );

    this._marritalStatus = val;

    this.fire("marritalStatus", this);
  }
  public get familyIncome(): number {
    return this._familyIncome;
  }
  public setFamilyIncome(val: number): void {
    let isValChanged: boolean = this._familyIncome !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FamilyInfo._FAMILYINCOME, this._familyIncome);

    this._familyIncome = val;

    this.fire("familyIncome", this);
  }
  public get familyMembers(): number {
    return this._familyMembers;
  }
  public setFamilyMembers(val: number): void {
    let isValChanged: boolean = this._familyMembers !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FamilyInfo._FAMILYMEMBERS, this._familyMembers);

    this._familyMembers = val;

    this.fire("familyMembers", this);
  }
  public get hasElderlyCareResponsibility(): boolean {
    return this._hasElderlyCareResponsibility;
  }
  public setHasElderlyCareResponsibility(val: boolean): void {
    let isValChanged: boolean = this._hasElderlyCareResponsibility !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      FamilyInfo._HASELDERLYCARERESPONSIBILITY,
      this._hasElderlyCareResponsibility
    );

    this._hasElderlyCareResponsibility = val;

    this.fire("hasElderlyCareResponsibility", this);
  }
  public get annisversaryDate(): D3EDate {
    return this._annisversaryDate;
  }
  public setAnnisversaryDate(val: D3EDate): void {
    let isValChanged: boolean = this._annisversaryDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(FamilyInfo._ANNISVERSARYDATE, this._annisversaryDate);

    this._annisversaryDate = val;

    this.fire("annisversaryDate", this);
  }
  public get(field: number): any {
    switch (field) {
      case FamilyInfo._SPOUSENAME: {
        return this._spouseName;
      }

      case FamilyInfo._CHILDREN: {
        return this._children;
      }

      case FamilyInfo._MARRITALSTATUS: {
        return this._marritalStatus.index;
      }

      case FamilyInfo._FAMILYINCOME: {
        return this._familyIncome;
      }

      case FamilyInfo._FAMILYMEMBERS: {
        return this._familyMembers;
      }

      case FamilyInfo._HASELDERLYCARERESPONSIBILITY: {
        return this._hasElderlyCareResponsibility;
      }

      case FamilyInfo._ANNISVERSARYDATE: {
        return this._annisversaryDate;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);

    this.otherMaster?.updateChildChanges(this.childPropertyInMaster);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): FamilyInfo {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {
    ctx.collectChilds(this._children);
  }
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: FamilyInfo = dbObj as FamilyInfo;

    obj.id = this.id;

    obj.setSpouseName(this._spouseName);

    ctx.cloneChildList(this._children, (v) => obj.setChildren(v));

    obj.setMarritalStatus(this._marritalStatus);

    obj.setFamilyIncome(this._familyIncome);

    obj.setFamilyMembers(this._familyMembers);

    obj.setHasElderlyCareResponsibility(this._hasElderlyCareResponsibility);

    obj.setAnnisversaryDate(this._annisversaryDate);
  }
  public async save(): Promise<Result<FamilyInfo>> {
    if (this.otherMaster != null) {
      return this.otherMaster.save();
    }

    return new Result();
  }
  public async delete(): Promise<Result<FamilyInfo>> {
    if (this.otherMaster != null) {
      return this.otherMaster.delete();
    }

    return new Result();
  }
  public set(field: number, value: any): void {
    switch (field) {
      case FamilyInfo._SPOUSENAME: {
        this.setSpouseName(value as string);
        break;
      }

      case FamilyInfo._CHILDREN: {
        this.setChildren((value as Array<any>).cast<Child>().toList());
        break;
      }

      case FamilyInfo._MARRITALSTATUS: {
        this.setMarritalStatus(MaritalStatus.values[value as number]);
        break;
      }

      case FamilyInfo._FAMILYINCOME: {
        this.setFamilyIncome(value as number);
        break;
      }

      case FamilyInfo._FAMILYMEMBERS: {
        this.setFamilyMembers(value as number);
        break;
      }

      case FamilyInfo._HASELDERLYCARERESPONSIBILITY: {
        this.setHasElderlyCareResponsibility(value as boolean);
        break;
      }

      case FamilyInfo._ANNISVERSARYDATE: {
        this.setAnnisversaryDate(value as D3EDate);
        break;
      }
    }
  }
}
