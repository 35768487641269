import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import DateField from "./DateField";
import ListWrapper from "../utils/ListWrapper";
import D3EDate from "../classes/D3EDate";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _LabelWithDateFieldOnChanged = (value: D3EDate) => void;

export interface LabelWithDateFieldProps extends BaseUIProps {
  key?: string;
  name: string;
  placeHolder?: string;
  isRequired?: boolean;
  value: D3EDate;
  errors?: Array<string>;
  format?: string;
  disable?: boolean;
  _errorsHash?: number;
  onChanged?: _LabelWithDateFieldOnChanged;
}

class _LabelWithDateFieldState extends ObservableComponent<LabelWithDateFieldProps> {
  static defaultProps = {
    name: "",
    placeHolder: "",
    isRequired: false,
    value: null,
    format: "dd-MM-yyyy",
    disable: false,
    errors: [],
    onChanged: null,
  };
  focusNode: ui.FocusNode = null;
  active: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LabelWithDateFieldProps) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get value(): D3EDate {
    return this.props.value;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get format(): string {
    return this.props.format;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.errors, "errors");

    this.on(
      [
        "disable",
        "errors",
        "format",
        "isRequired",
        "name",
        "placeHolder",
        "value",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LabelWithDateFieldProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.format !== this.props.format) {
      this.fire("format", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this.active !== val;

    if (!isValChanged) {
      return;
    }

    this.active = val;

    this.fire("active", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: cStyle.tColumnFieldStyleCrossAxisAlignmentOn,
      children: [
        this.name !== null && this.name.isNotEmpty
          ? ui.Row({
              children: [
                TextView({
                  data: this.name,
                  style: new ui.TextStyle({
                    color: cStyle.tTextViewLabelTextColorOn,
                    fontSize: cStyle.tTextViewLabelTextFontSizeOn,
                    fontWeight: cStyle.tTextViewLabelTextFontWeightOn,
                  }),
                  className: "x4f72",
                  key: "0",
                }),
                this.isRequired
                  ? TextView({
                      data: "*",
                      style: new ui.TextStyle({
                        color: new ui.Color(0xffc20f2f),
                        fontSize: cStyle.tTextViewLabelTextFontSizeOn,
                        fontWeight: cStyle.tTextViewLabelTextFontWeightOn,
                      }),
                      className: "x4ac",
                    })
                  : [],
              ],
              className: "x968",
            })
          : [],
        DateField({
          value: this.value,
          placeHolder: this.placeHolder,
          format: this.format,
          disable: this.disable,
          padding: ui.EdgeInsets.symmetric({
            horizontal: 10.0,
            vertical: 5.0,
            transitions: new Map(),
          }),
          activeColor: cStyle.c1,
          inActiveColor: this.errors.isNotEmpty
            ? new ui.Color(0xffc20f2f)
            : new ui.Color(0xffd2d2d2),
          cornerRadius: 4,
          selectedColor: cStyle.c1,
          hoverColor: cStyle.c1,
          selectedTextColor: new ui.Color(0xffffffff),
          hoverTextColor: new ui.Color(0xffffffff),
          onChanged: (date) => {
            this.onChangeText(date);
          },
          className: "x059",
          key: "1",
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    textAlign: cStyle.tTextViewErrorTextTextAlignOn,
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorTextColorOn,
                    }),
                    className: "x00c",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "x85",
            })
          : [],
      ],
      className: ui.join(this.props.className, "LabelWithDateField xe1e"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);
  };
  public onChangeFocus = (): void => {
    this.setActive(this.focusNode.hasFocus);
  };
  public onChangeText = (date: D3EDate): void => {
    if (this.onChanged !== null) {
      this.onChanged(date);
    }
  };
  public get onChanged(): _LabelWithDateFieldOnChanged {
    return this.props.onChanged;
  }
}
export default function LabelWithDateField(props: LabelWithDateFieldProps) {
  return React.createElement(
    _LabelWithDateFieldState,
    { ..._LabelWithDateFieldState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
