import { Device, Call } from "@twilio/voice-sdk";
import CallData from "./CallData";
import CallStatus from "./CallStatus";
import TwilioCall from "./TwilioCall";
export default class TwilioClient {
  private device: Device | null = null;
  private activeCall: Call | null = null;
  public data: CallData | null = null;
  public callSid: string | null = null;

  private registrationAttempts: number = 0;
  private maxRegistrationAttempts: number = 5;
  private registrationTimeout: NodeJS.Timeout | null = null;

  constructor(public accessToken: string) {}

  get isMuted(): boolean {
    return this.activeCall ? this.activeCall.isMuted() : false;
  }

  private log(
    level: "info" | "warn" | "error",
    message: string,
    ...args: any[]
  ) {
    const timestamp = new Date().toISOString();
    console[level](`[${timestamp}] ${message}`, ...args);
  }

  async checkMicPermission(): Promise<boolean> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      stream.getTracks().forEach((track) => track.stop());
      return true;
    } catch (error) {
      this.log("error", "Microphone permission denied:", error);
      return false;
    }
  }

  private isValidAccessToken(token: string): boolean {
    return typeof token === "string" && token.trim().length > 0;
  }

  public setAccessToken(token: string) {
    this.accessToken = token;
  }

  private async refreshToken() {
    try {
      // Call your backend to get a new token
      const response = await fetch("/get-new-token");
      const { token } = await response.json();
      this.accessToken = token;
      await this.device?.updateToken(token);
      this.log("info", "Token refreshed successfully");
    } catch (error) {
      this.log("error", "Failed to refresh token:", error);
    }
  }

  async checkRequirements(): Promise<boolean> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      this.log("error", "WebRTC is not supported in this browser");
      return false;
    }

    if (!navigator.onLine) {
      this.log("error", "No internet connection");
      return false;
    }

    return true;
  }

  async initialize({
    onSuccess,
    onFailure,
    onIncomingCall,
  }: {
    onSuccess: (message: string) => void;
    onFailure: (message: string, error: string) => void;
    onIncomingCall: (call: TwilioCall) => void;
  }): Promise<boolean> {
    try {
      if (!this.isValidAccessToken(this.accessToken)) {
        throw new Error("Invalid access token format");
      }
      if (!(await this.checkRequirements())) {
        onFailure("Initialization failed", "Requirements not met");
        return false;
      }
      this.device = new Device(this.accessToken, {
        logLevel: 3, // Warning level
        edge: [
          "ashburn",
          "dublin",
          "singapore",
          "sydney",
          "tokyo",
          "sao-paulo",
          "frankfurt",
        ],
      });

      this.device.on("ready", () => {
        this.log("info", "Device is ready for incoming calls.");
      });

      this.device.on("incoming", (con) => {
        this.log("info", "Incoming call received");
        // Accept the incoming connection
        const parameters = con.parameters;
        const customParams = this.parseCustomParams(parameters.Params);
        const originalCallSid = customParams.OriginalCallSid || "";
        console.log(
          "from : ",
          con.parameters.From,
          " to : ",
          con.parameters.To,
          " callSid : ",
          con.parameters.CallSid,
          "OriginalCallSid : ",
          originalCallSid
        );
        onIncomingCall(
          new TwilioCall(
            con,
            originalCallSid,
            con.parameters.From,
            con.parameters.To
          )
        );
        this.attachCallListeners(con);
      });
      this.device.on("registered", () => {
        this.log("info", "Twilio device registered");
        this.data.setDeviceReady(true);
        onSuccess("Device registered successfully");

        this.registrationAttempts = 0;
        if (this.registrationTimeout) {
          clearTimeout(this.registrationTimeout);
        }
      });

      this.device.on("error", (error) => {
        this.log("error", "Twilio device error:", error);
        this.log("info", "Error code:", error.code);
        this.log("info", "Error message:", error.message);
      });

      this.device.on("disconnect", (connection) => {
        this.log("info", "Call disconnected:", connection);
        if (connection.parameters && connection.parameters.CallSid) {
          this.log("info", "CallSid:", connection.parameters.CallSid);
        }
      });

      this.device.on("hangup", (payload) => {
        if (payload.error && payload.error.code === 31404) {
          this.log(
            "error",
            "Hangup due to Not Found error:",
            payload.error.message
          );
        } else {
          this.log("info", "Call hangup received:", payload);
        }
      });

      this.log("info", "Ready event listener registered.");

      await this.device.register();

      return true;
    } catch (error) {
      this.log("error", "Failed to initialize Twilio device:", error);
      onFailure("Initialization failed", (error as Error).message);
      return false;
    }
  }

  private attachCallListeners(con: any): void {
    con.on("cancel", () => this.handleCallCancelled());
    con.on("reject", () => this.handleCallRejected());
    con.on("disconnect", () => this.handleCallDisconnected());
    con.on("error", (error: Error) => this.handleConnectionError(error));
  }

  private handleCallCancelled(): void {
    this.log(
      "info",
      "Caller hung up before the call was answered. or Other device cancelled the call."
    );
    this.cleanupCall(CallStatus.Cancelled);
  }

  // Handle call reject event (Callee rejects the call)
  private handleCallRejected(): void {
    this.log("info", "Callee rejected the call.");
    this.cleanupCall(CallStatus.Rejected);
  }

  // Handle disconnect event (Call was answered and then disconnected)
  private handleCallDisconnected(): void {
    this.log("info", "Call disconnected.");
    this.cleanupCall(CallStatus.Completed);
  }

  // Handle connection errors
  private handleConnectionError(error: Error): void {
    this.log("error", `Connection error: ${error.message}`);
  }

  // Clean up call state and reset relevant variables
  private cleanupCall(status: CallStatus): void {
    this.data.setCallStatus(status);
    this.data.setCallSid(null);
    this.callSid = null;
    this.activeCall = null;
  }
  private parseCustomParams(paramsString: string): Record<string, string> {
    const params: Record<string, string> = {};
    if (paramsString) {
      paramsString.split("&").forEach((param) => {
        const [key, value] = param.split("=");
        params[key] = value;
      });
    }
    return params;
  }

  private attemptReregistration() {
    if (this.registrationAttempts >= this.maxRegistrationAttempts) {
      this.log(
        "error",
        "Max registration attempts reached. Please check your connection and try again later."
      );
      return;
    }

    this.registrationAttempts++;
    this.log(
      "info",
      `Attempting to re-register device (Attempt ${this.registrationAttempts})`
    );

    this.registrationTimeout = setTimeout(async () => {
      try {
        console.log("Attempting to re-register device...");
        await this.device?.register();
      } catch (error) {
        this.log("error", "Re-registration attempt failed:", error);
        this.attemptReregistration();
      }
    }, 5000 * this.registrationAttempts); // Increasing backoff
  }

  async acceptIncomingCall(
    call: TwilioCall,
    {
      onStatus,
      onFailure,
    }: {
      onStatus: (message: string) => void;
      onFailure: (message: string, error: string) => void;
    }
  ): Promise<boolean> {
    try {
      this.activeCall = call.call;
      await this.activeCall.accept();
      this.setupCallEventListeners(onStatus, onFailure);
      onStatus("Call accepted");
      this.data.setCallStatus(CallStatus.InProgress);
      return true;
    } catch (error) {
      onFailure("Failed to accept incoming call", (error as Error).message);
      return false;
    }
  }

  async rejectIncomingCall(call: TwilioCall): Promise<boolean> {
    try {
      await call.call.reject();
      return true;
    } catch (error) {
      this.log("error", "Failed to reject incoming call:", error);
      return false;
    }
  }

  private monitorCallQuality() {
    if (this.activeCall) {
      this.activeCall.on("sample", (sample) => {
        this.data.setCallStatus(CallStatus.InProgress);
        this.log("info", "Call quality sample:", sample);
        // Analyze sample data and take action if quality degrades
        // For example:
        if (sample.packetLoss > 0.05) {
          this.log("warn", "High packet loss detected");
        }
      });
    }
  }

  async muteCall({
    onSuccess,
    onFailure,
  }: {
    onSuccess: (message: string) => void;
    onFailure: (message: string, error: string) => void;
  }): Promise<boolean> {
    if (!this.activeCall) {
      onFailure("Mute failed", "No active call");
      return false;
    }

    try {
      this.activeCall.mute(true);
      onSuccess("Call muted");
      return true;
    } catch (error) {
      onFailure("Mute failed", (error as Error).message);
      return false;
    }
  }

  async unmuteCall({
    onSuccess,
    onFailure,
  }: {
    onSuccess: (message: string) => void;
    onFailure: (message: string, error: string) => void;
  }): Promise<boolean> {
    if (!this.activeCall) {
      onFailure("Unmute failed", "No active call");
      return false;
    }

    try {
      this.activeCall.mute(false);
      onSuccess("Call unmuted");
      return true;
    } catch (error) {
      onFailure("Unmute failed", (error as Error).message);
      return false;
    }
  }

  async disconnect(): Promise<boolean> {
    const unregisterTimeout = new Promise(
      (_, reject) =>
        setTimeout(() => reject(new Error("Unregister timed out")), 5000) // 5-second timeout
    );

    try {
      if (this.device) {
        this.log("info", "Attempting to unregister device...");
        // Using Promise.race to resolve either unregister or timeout
        await Promise.race([this.device.unregister(), unregisterTimeout]);
        this.log("info", "Device unregistered successfully");
      } else {
        this.log("info", "Device is null or undefined, skipping unregister.");
      }
      this.data.setDeviceReady(false);
      this.data.setCallSid("");

      return true;
    } catch (error) {
      this.log("error", "Failed to disconnect device:", error);
      return false;
    }
  }

  async makeCall(
    from: string, // Twilio phone number
    to: string, // The number or client identifier to call
    {
      onStatus,
      onFailure,
    }: {
      onStatus: (message: string) => void;
      onFailure: (message: string, error: string) => void;
    }
  ): Promise<boolean> {
    if (!this.device || !this.data.deviceReady) {
      onFailure("Connection failed", "Device not ready");
      return false;
    }

    try {
      // Make an outgoing call with both 'from' and 'to'
      this.activeCall = await this.device.connect({
        params: {
          from: from, // Your Twilio number
          to: to, // Phone number or client identifier
        },
      });
      // Event listeners to track the call status
      this.setupCallEventListeners(onStatus, onFailure);
      return true;
    } catch (error) {
      console.error("Connection failed", error);
      onStatus("failed");
      this.data.setCallStatus(CallStatus.Failed);
      onFailure("Connection failed", (error as Error).message);
      this.data.setCallSid(null);
      this.activeCall = null;
      return false;
    }
  }

  private setupCallEventListeners(
    onStatus: (message: string) => void,
    onFailure: (message: string, error: string) => void
  ) {
    if (!this.activeCall) return;

    this.activeCall.on("accept", () => {
      this.data.setCallSid(this.activeCall.parameters.CallSid);
      this.callSid = this.activeCall.parameters.CallSid;
      onStatus("accepted");
      this.data.setCallStatus(CallStatus.InProgress);
    });

    this.activeCall.on("answered", () => {
      this.data.setCallSid(this.activeCall.parameters.CallSid);
      this.callSid = this.activeCall.parameters.CallSid;
      onStatus("accepted");
      this.data.setCallStatus(CallStatus.InProgress);
    });

    this.activeCall.on("ringing", () => {
      this.data.setCallSid(this.activeCall.parameters.CallSid);
      this.callSid = this.activeCall.parameters.CallSid;
      this.data.setCallStatus(CallStatus.Ringing);
      this.log("info", "Ringing");
      console.log("Ringing -> CallId: ", this.activeCall.parameters.CallSid);
      onStatus("ringing");
    });

    this.activeCall.on("open", () => {
      this.log("info", "Call InProgress");
      this.data.setCallStatus(CallStatus.InProgress);
      onStatus("open");
    });

    this.activeCall.on("initiated", () => {
      this.log("info", "Call initiated");
      this.data.setCallStatus(CallStatus.Initiated);
      this.callSid = this.activeCall.parameters.CallSid;
      this.data.setCallSid(this.activeCall.parameters.CallSid);
      onStatus("initiated");
    });

    this.activeCall.on("no-answer", () => {
      this.log("info", "No answer");
      this.data.setCallStatus(CallStatus.Unanswered);
      onStatus("no-answer");
    });

    this.activeCall.on("connecting", () => {
      this.log("info", "Call connecting");
      this.data.setCallStatus(CallStatus.Connecting);
      onStatus("connecting");
    });

    this.activeCall.on("reject", () => {
      this.log("info", "Call rejected (busy)");
      this.data.setCallStatus(CallStatus.Busy);
      onStatus("busy");
      this.data.setCallSid(null);
      this.activeCall = null;
    });

    this.activeCall.on("completed", () => {
      this.log("info", "Call completed");
      this.data.setCallStatus(CallStatus.Completed);
      onStatus("completed");
      this.data.setCallSid(null);
      this.activeCall = null;
    });

    this.activeCall.on("error", (error) => {
      this.log("info", "Call error");
      this.data.setCallStatus(CallStatus.Failed);
      onStatus("failed");
      onFailure("Call error", error.message);
      this.data.setCallSid(null);
      this.activeCall = null;
    });

    this.activeCall.on("warning", (warningName, warningData) => {
      console.warn(`Call warning: ${warningName}`, warningData);
      this.log("warn", `Call warning: ${warningName}`, warningData);
    });

    this.activeCall.on("disconnect", (connection) => {
      onStatus("disconnected");
      this.log("info", "Call disconnected. Reason:", connection.status());
      this.data.setCallSid(null);
      this.data.setCallStatus(CallStatus.Completed);
      this.callSid = null;
      this.activeCall = null;
    });

    this.monitorCallQuality();
  }

  async disconnectCall({
    onFailure,
  }: {
    onFailure: (message: string) => void;
  }): Promise<boolean> {
    if (!this.activeCall) {
      onFailure("No active call");
      return;
    }

    try {
      this.activeCall.disconnect();
      this.data.setCallStatus(CallStatus.Unknown);
      this.data.setCallSid(null);
      return true;
    } catch (error) {
      this.log("error", "Failed to disconnect call:", error);
    }
  }
}
