import LeadAssignment from "./LeadAssignment";
import FamilyInfo from "./FamilyInfo";
import D3EDate from "../classes/D3EDate";
import LeadStatus from "../classes/LeadStatus";
import EmailStatus from "../classes/EmailStatus";
import Interaction from "./Interaction";
import CloneContext from "../utils/CloneContext";
import User from "./User";
import ListWrapper from "../utils/ListWrapper";
import InteractionType from "../classes/InteractionType";
import Education from "./Education";
import DateTime from "../core/DateTime";
import DBObject from "../utils/DBObject";
import CollectionUtils from "../utils/CollectionUtils";

export default class Lead extends DBObject {
  private static readonly _APOLLOID: number = 0;
  private static readonly _BUDGET: number = 1;
  private static readonly _COMPANY: number = 2;
  private static readonly _COMPANYREVENUE: number = 3;
  private static readonly _COMPANYSIZE: number = 4;
  private static readonly _COMPANYWEBSITE: number = 5;
  private static readonly _CREATEDBY: number = 6;
  private static readonly _CREATEDDATE: number = 7;
  private static readonly _DECISIONMAKINGROLE: number = 8;
  private static readonly _DETAILS: number = 9;
  private static readonly _EDUCATIONHISTORY: number = 10;
  private static readonly _EMAIL: number = 11;
  private static readonly _EMAILSTATUS: number = 12;
  private static readonly _EXPRESSEDNEEDS: number = 13;
  private static readonly _FACEBOOKPROFILE: number = 14;
  private static readonly _FAMILYINFO: number = 15;
  private static readonly _HANDLINGUSER: number = 16;
  private static readonly _IDENTIFIEDPAINPOINTS: number = 17;
  private static readonly _INDUSTRY: number = 18;
  private static readonly _INTERACTIONDATE: number = 19;
  private static readonly _INTERACTIONHISTORY: number = 20;
  private static readonly _INTERACTIONTYPE: number = 21;
  private static readonly _INTERESTS: number = 22;
  private static readonly _LANGUAGES: number = 23;
  private static readonly _LASTINTERACTION: number = 24;
  private static readonly _LEADASSIGNMENT: number = 25;
  private static readonly _LINKEDINPROFILE: number = 26;
  private static readonly _NAME: number = 27;
  private static readonly _NEEDS: number = 28;
  private static readonly _PAINS: number = 29;
  private static readonly _PHONE: number = 30;
  private static readonly _PREFERREDCONTACTMETHOD: number = 31;
  private static readonly _PREVIOUSPOSITIONS: number = 32;
  private static readonly _SKILLS: number = 33;
  private static readonly _STATUS: number = 34;
  private static readonly _TIMEZONE: number = 35;
  private static readonly _TWITTERHANDLE: number = 36;
  private static readonly _UPDATEDDATE: number = 37;
  public id: number = 0;
  public otherMaster: DBObject;
  private _name: string = "";
  private _email: string = "";
  private _phone: string = "";
  private _company: string = "";
  private _status: LeadStatus = LeadStatus.New;
  private _industry: string = "";
  private _previousPositions: Array<string> = ListWrapper.primitive(
    this,
    "previousPositions",
    Lead._PREVIOUSPOSITIONS
  );
  private _skills: Array<string> = ListWrapper.primitive(
    this,
    "skills",
    Lead._SKILLS
  );
  private _educationHistory: Array<Education> = ListWrapper.child(
    this,
    "educationHistory",
    Lead._EDUCATIONHISTORY
  );
  private _leadAssignment: LeadAssignment = null;
  private _preferredContactMethod: string = "";
  private _timeZone: string = "";
  private _languages: Array<string> = ListWrapper.primitive(
    this,
    "languages",
    Lead._LANGUAGES
  );
  private _budget: number = 0.0;
  private _expressedNeeds: string = "";
  private _identifiedPainPoints: string = "";
  private _decisionMakingRole: string = "";
  private _interactionHistory: Array<Interaction> = ListWrapper.inverse(
    this,
    "interactionHistory",
    Lead._INTERACTIONHISTORY
  );
  private _linkedInProfile: string = "";
  private _interactionDate: D3EDate = null;
  private _interactionType: InteractionType = InteractionType.Call;
  private _details: string = "";
  private _twitterHandle: string = "";
  private _facebookProfile: string = "";
  private _companySize: string = "";
  private _companyRevenue: string = "";
  private _companyWebsite: string = "";
  private _interests: Array<string> = ListWrapper.primitive(
    this,
    "interests",
    Lead._INTERESTS
  );
  private _familyInfo: FamilyInfo = null;
  private _lastInteraction: Interaction = null;
  private _createdBy: User = null;
  private _emailStatus: EmailStatus = EmailStatus.Unavailable;
  private _apolloId: string = "";
  private _needs: Array<string> = ListWrapper.primitive(
    this,
    "needs",
    Lead._NEEDS
  );
  private _pains: Array<string> = ListWrapper.primitive(
    this,
    "pains",
    Lead._PAINS
  );
  private _handlingUser: User = null;
  private _createdDate: DateTime = null;
  private _updatedDate: DateTime = null;
  public constructor(
    d3eParams?: Partial<{
      apolloId: string;
      budget: number;
      company: string;
      companyRevenue: string;
      companySize: string;
      companyWebsite: string;
      createdBy: User;
      createdDate: DateTime;
      decisionMakingRole: string;
      details: string;
      educationHistory: Array<Education>;
      email: string;
      emailStatus: EmailStatus;
      expressedNeeds: string;
      facebookProfile: string;
      familyInfo: FamilyInfo;
      identifiedPainPoints: string;
      industry: string;
      interactionDate: D3EDate;
      interactionHistory: Array<Interaction>;
      interactionType: InteractionType;
      interests: Array<string>;
      languages: Array<string>;
      lastInteraction: Interaction;
      leadAssignment: LeadAssignment;
      linkedInProfile: string;
      name: string;
      needs: Array<string>;
      pains: Array<string>;
      phone: string;
      preferredContactMethod: string;
      previousPositions: Array<string>;
      skills: Array<string>;
      status: LeadStatus;
      timeZone: string;
      twitterHandle: string;
      updatedDate: DateTime;
    }>
  ) {
    super();

    this.setApolloId(d3eParams?.apolloId ?? "");

    this.setBudget(d3eParams?.budget ?? 0.0);

    this.setCompany(d3eParams?.company ?? "");

    this.setCompanyRevenue(d3eParams?.companyRevenue ?? "");

    this.setCompanySize(d3eParams?.companySize ?? "");

    this.setCompanyWebsite(d3eParams?.companyWebsite ?? "");

    this.setCreatedBy(d3eParams?.createdBy ?? null);

    this.setCreatedDate(d3eParams?.createdDate ?? null);

    this.setDecisionMakingRole(d3eParams?.decisionMakingRole ?? "");

    this.setDetails(d3eParams?.details ?? "");

    this.setEducationHistory(
      d3eParams?.educationHistory ??
        ListWrapper.child(this, "educationHistory", Lead._EDUCATIONHISTORY)
    );

    this.setEmail(d3eParams?.email ?? "");

    this.setEmailStatus(d3eParams?.emailStatus ?? EmailStatus.Unavailable);

    this.setExpressedNeeds(d3eParams?.expressedNeeds ?? "");

    this.setFacebookProfile(d3eParams?.facebookProfile ?? "");

    this.setFamilyInfo(d3eParams?.familyInfo ?? null);

    this.setIdentifiedPainPoints(d3eParams?.identifiedPainPoints ?? "");

    this.setIndustry(d3eParams?.industry ?? "");

    this.setInteractionDate(d3eParams?.interactionDate ?? null);

    this.setInteractionHistory(
      d3eParams?.interactionHistory ??
        ListWrapper.inverse(
          this,
          "interactionHistory",
          Lead._INTERACTIONHISTORY
        )
    );

    this.setInteractionType(d3eParams?.interactionType ?? InteractionType.Call);

    this.setInterests(
      d3eParams?.interests ??
        ListWrapper.primitive(this, "interests", Lead._INTERESTS)
    );

    this.setLanguages(
      d3eParams?.languages ??
        ListWrapper.primitive(this, "languages", Lead._LANGUAGES)
    );

    this.setLastInteraction(d3eParams?.lastInteraction ?? null);

    this.setLeadAssignment(d3eParams?.leadAssignment ?? null);

    this.setLinkedInProfile(d3eParams?.linkedInProfile ?? "");

    this.setName(d3eParams?.name ?? "");

    this.setNeeds(
      d3eParams?.needs ?? ListWrapper.primitive(this, "needs", Lead._NEEDS)
    );

    this.setPains(
      d3eParams?.pains ?? ListWrapper.primitive(this, "pains", Lead._PAINS)
    );

    this.setPhone(d3eParams?.phone ?? "");

    this.setPreferredContactMethod(d3eParams?.preferredContactMethod ?? "");

    this.setPreviousPositions(
      d3eParams?.previousPositions ??
        ListWrapper.primitive(
          this,
          "previousPositions",
          Lead._PREVIOUSPOSITIONS
        )
    );

    this.setSkills(
      d3eParams?.skills ?? ListWrapper.primitive(this, "skills", Lead._SKILLS)
    );

    this.setStatus(d3eParams?.status ?? LeadStatus.New);

    this.setTimeZone(d3eParams?.timeZone ?? "");

    this.setTwitterHandle(d3eParams?.twitterHandle ?? "");

    this.setUpdatedDate(d3eParams?.updatedDate ?? null);
  }
  public get d3eType(): string {
    return "Lead";
  }
  public static updateLeadAssignmentInverse(
    on: any,
    obj: any,
    type: string
  ): void {
    if ("Insert" === type) {
      on.addToLeadAssignment(obj as LeadAssignment);
    }

    if ("Delete" === type) {
      on.removeFromLeadAssignment(obj as LeadAssignment);
    }
  }
  public static updateInteractionHistoryInverse(
    on: any,
    obj: any,
    type: string
  ): void {
    if ("Insert" === type) {
      on.addToInteractionHistory(obj as Interaction);
    }

    if ("Delete" === type) {
      on.removeFromInteractionHistory(obj as Interaction);
    }
  }
  public toString(): string {
    return this.name;
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get email(): string {
    return this._email;
  }
  public setEmail(val: string): void {
    let isValChanged: boolean = this._email !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._EMAIL, this._email);

    this._email = val;

    this.fire("email", this);
  }
  public get phone(): string {
    return this._phone;
  }
  public setPhone(val: string): void {
    let isValChanged: boolean = this._phone !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._PHONE, this._phone);

    this._phone = val;

    this.fire("phone", this);
  }
  public get company(): string {
    return this._company;
  }
  public setCompany(val: string): void {
    let isValChanged: boolean = this._company !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._COMPANY, this._company);

    this._company = val;

    this.fire("company", this);
  }
  public get status(): LeadStatus {
    return this._status;
  }
  public setStatus(val: LeadStatus): void {
    let isValChanged: boolean = this._status !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._STATUS, this._status.index);

    this._status = val;

    this.fire("status", this);
  }
  public get industry(): string {
    return this._industry;
  }
  public setIndustry(val: string): void {
    let isValChanged: boolean = this._industry !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._INDUSTRY, this._industry);

    this._industry = val;

    this.fire("industry", this);
  }
  public get previousPositions(): Array<string> {
    return this._previousPositions;
  }
  public setPreviousPositions(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._previousPositions,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Lead._PREVIOUSPOSITIONS)) {
      let _old: Array<string> = Array.from(this._previousPositions);

      this.updateD3EChanges(Lead._PREVIOUSPOSITIONS, _old);
    }

    this._previousPositions.clear();

    this._previousPositions.addAll(val);

    this.fire("previousPositions", this);
  }
  public addToPreviousPositions(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      Lead._PREVIOUSPOSITIONS
    );

    if (_isNewChange) {
      _old = Array.from(this._previousPositions);
    }

    if (index === -1) {
      if (!this._previousPositions.contains(val))
        this._previousPositions.add(val);
    } else {
      this._previousPositions.remove(this._previousPositions.elementAt(index));

      this._previousPositions.add(val);
    }

    this.fire("previousPositions", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._PREVIOUSPOSITIONS, _old);
    }
  }
  public removeFromPreviousPositions(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      Lead._PREVIOUSPOSITIONS
    );

    if (_isNewChange) {
      _old = Array.from(this._previousPositions);
    }

    this._previousPositions.remove(val);

    this.fire("previousPositions", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._PREVIOUSPOSITIONS, _old);
    }
  }
  public get skills(): Array<string> {
    return this._skills;
  }
  public setSkills(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this._skills, val);

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Lead._SKILLS)) {
      let _old: Array<string> = Array.from(this._skills);

      this.updateD3EChanges(Lead._SKILLS, _old);
    }

    this._skills.clear();

    this._skills.addAll(val);

    this.fire("skills", this);
  }
  public addToSkills(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Lead._SKILLS);

    if (_isNewChange) {
      _old = Array.from(this._skills);
    }

    if (index === -1) {
      if (!this._skills.contains(val)) this._skills.add(val);
    } else {
      this._skills.remove(this._skills.elementAt(index));

      this._skills.add(val);
    }

    this.fire("skills", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._SKILLS, _old);
    }
  }
  public removeFromSkills(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Lead._SKILLS);

    if (_isNewChange) {
      _old = Array.from(this._skills);
    }

    this._skills.remove(val);

    this.fire("skills", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._SKILLS, _old);
    }
  }
  public get educationHistory(): Array<Education> {
    return this._educationHistory;
  }
  public setEducationHistory(val: Array<Education>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._educationHistory,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (this._educationHistory != null) {
      this._educationHistory.forEach((one) => (one.otherMaster = null));
    }

    if (val != null) {
      for (let o of val) {
        o.updateMaster(this, Lead._EDUCATIONHISTORY);
      }
    }

    if (!this.d3eChanges.contains(Lead._EDUCATIONHISTORY)) {
      let _old: Array<Education> = Array.from(this._educationHistory);

      this.updateD3EChanges(Lead._EDUCATIONHISTORY, _old);
    }

    this.updateObservableColl("educationHistory", this._educationHistory, val);

    this._educationHistory.clear();

    this._educationHistory.addAll(val);

    this.fire("educationHistory", this);
  }
  public addToEducationHistory(val: Education, index: number = -1): void {
    let _old: Array<Education> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      Lead._EDUCATIONHISTORY
    );

    if (_isNewChange) {
      _old = Array.from(this._educationHistory);
    }

    val.otherMaster = this;

    val.childPropertyInMaster = Lead._EDUCATIONHISTORY;

    if (index === -1) {
      if (!this._educationHistory.contains(val))
        this._educationHistory.add(val);
    } else {
      this._educationHistory.remove(this._educationHistory.elementAt(index));

      this._educationHistory.add(val);
    }

    this.fire("educationHistory", this, val, true);

    this.updateObservable("educationHistory", null, val);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._EDUCATIONHISTORY, _old);
    }
  }
  public removeFromEducationHistory(val: Education): void {
    let _old: Array<Education> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      Lead._EDUCATIONHISTORY
    );

    if (_isNewChange) {
      _old = Array.from(this._educationHistory);
    }

    this._educationHistory.remove(val);

    val.otherMaster = null;

    this.fire("educationHistory", this, val, false);

    this.removeObservable("educationHistory", val);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._EDUCATIONHISTORY, _old);
    }
  }
  public get leadAssignment(): LeadAssignment {
    return this._leadAssignment;
  }
  public setLeadAssignment(val: LeadAssignment): void {
    let isValChanged: boolean = this._leadAssignment !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._LEADASSIGNMENT, this._leadAssignment);

    this.updateObservable("leadAssignment", this._leadAssignment, val);

    this._leadAssignment = val;

    this.fire("leadAssignment", this);
  }
  public get preferredContactMethod(): string {
    return this._preferredContactMethod;
  }
  public setPreferredContactMethod(val: string): void {
    let isValChanged: boolean = this._preferredContactMethod !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      Lead._PREFERREDCONTACTMETHOD,
      this._preferredContactMethod
    );

    this._preferredContactMethod = val;

    this.fire("preferredContactMethod", this);
  }
  public get timeZone(): string {
    return this._timeZone;
  }
  public setTimeZone(val: string): void {
    let isValChanged: boolean = this._timeZone !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._TIMEZONE, this._timeZone);

    this._timeZone = val;

    this.fire("timeZone", this);
  }
  public get languages(): Array<string> {
    return this._languages;
  }
  public setLanguages(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._languages,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Lead._LANGUAGES)) {
      let _old: Array<string> = Array.from(this._languages);

      this.updateD3EChanges(Lead._LANGUAGES, _old);
    }

    this._languages.clear();

    this._languages.addAll(val);

    this.fire("languages", this);
  }
  public addToLanguages(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Lead._LANGUAGES);

    if (_isNewChange) {
      _old = Array.from(this._languages);
    }

    if (index === -1) {
      if (!this._languages.contains(val)) this._languages.add(val);
    } else {
      this._languages.remove(this._languages.elementAt(index));

      this._languages.add(val);
    }

    this.fire("languages", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._LANGUAGES, _old);
    }
  }
  public removeFromLanguages(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Lead._LANGUAGES);

    if (_isNewChange) {
      _old = Array.from(this._languages);
    }

    this._languages.remove(val);

    this.fire("languages", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._LANGUAGES, _old);
    }
  }
  public get budget(): number {
    return this._budget;
  }
  public setBudget(val: number): void {
    let isValChanged: boolean = this._budget !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._BUDGET, this._budget);

    this._budget = val;

    this.fire("budget", this);
  }
  public get expressedNeeds(): string {
    return this._expressedNeeds;
  }
  public setExpressedNeeds(val: string): void {
    let isValChanged: boolean = this._expressedNeeds !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._EXPRESSEDNEEDS, this._expressedNeeds);

    this._expressedNeeds = val;

    this.fire("expressedNeeds", this);
  }
  public get identifiedPainPoints(): string {
    return this._identifiedPainPoints;
  }
  public setIdentifiedPainPoints(val: string): void {
    let isValChanged: boolean = this._identifiedPainPoints !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      Lead._IDENTIFIEDPAINPOINTS,
      this._identifiedPainPoints
    );

    this._identifiedPainPoints = val;

    this.fire("identifiedPainPoints", this);
  }
  public get decisionMakingRole(): string {
    return this._decisionMakingRole;
  }
  public setDecisionMakingRole(val: string): void {
    let isValChanged: boolean = this._decisionMakingRole !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._DECISIONMAKINGROLE, this._decisionMakingRole);

    this._decisionMakingRole = val;

    this.fire("decisionMakingRole", this);
  }
  public get interactionHistory(): Array<Interaction> {
    return this._interactionHistory;
  }
  public setInteractionHistory(val: Array<Interaction>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._interactionHistory,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Lead._INTERACTIONHISTORY)) {
      let _old: Array<Interaction> = Array.from(this._interactionHistory);

      this.updateD3EChanges(Lead._INTERACTIONHISTORY, _old);
    }

    this.updateObservableColl(
      "interactionHistory",
      this._interactionHistory,
      val
    );

    this._interactionHistory.clear();

    this._interactionHistory.addAll(val);

    this.fire("interactionHistory", this);
  }
  public addToInteractionHistory(val: Interaction, index: number = -1): void {
    let _old: Array<Interaction> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      Lead._INTERACTIONHISTORY
    );

    if (_isNewChange) {
      _old = Array.from(this._interactionHistory);
    }

    if (index === -1) {
      if (!this._interactionHistory.contains(val))
        this._interactionHistory.add(val);
    } else {
      this._interactionHistory.remove(
        this._interactionHistory.elementAt(index)
      );

      this._interactionHistory.add(val);
    }

    this.fire("interactionHistory", this, val, true);

    this.updateObservable("interactionHistory", null, val);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._INTERACTIONHISTORY, _old);
    }
  }
  public removeFromInteractionHistory(val: Interaction): void {
    let _old: Array<Interaction> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(
      Lead._INTERACTIONHISTORY
    );

    if (_isNewChange) {
      _old = Array.from(this._interactionHistory);
    }

    this._interactionHistory.remove(val);

    this.fire("interactionHistory", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._INTERACTIONHISTORY, _old);
    }
  }
  public get linkedInProfile(): string {
    return this._linkedInProfile;
  }
  public setLinkedInProfile(val: string): void {
    let isValChanged: boolean = this._linkedInProfile !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._LINKEDINPROFILE, this._linkedInProfile);

    this._linkedInProfile = val;

    this.fire("linkedInProfile", this);
  }
  public get interactionDate(): D3EDate {
    return this._interactionDate;
  }
  public setInteractionDate(val: D3EDate): void {
    let isValChanged: boolean = this._interactionDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._INTERACTIONDATE, this._interactionDate);

    this._interactionDate = val;

    this.fire("interactionDate", this);
  }
  public get interactionType(): InteractionType {
    return this._interactionType;
  }
  public setInteractionType(val: InteractionType): void {
    let isValChanged: boolean = this._interactionType !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._INTERACTIONTYPE, this._interactionType.index);

    this._interactionType = val;

    this.fire("interactionType", this);
  }
  public get details(): string {
    return this._details;
  }
  public setDetails(val: string): void {
    let isValChanged: boolean = this._details !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._DETAILS, this._details);

    this._details = val;

    this.fire("details", this);
  }
  public get twitterHandle(): string {
    return this._twitterHandle;
  }
  public setTwitterHandle(val: string): void {
    let isValChanged: boolean = this._twitterHandle !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._TWITTERHANDLE, this._twitterHandle);

    this._twitterHandle = val;

    this.fire("twitterHandle", this);
  }
  public get facebookProfile(): string {
    return this._facebookProfile;
  }
  public setFacebookProfile(val: string): void {
    let isValChanged: boolean = this._facebookProfile !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._FACEBOOKPROFILE, this._facebookProfile);

    this._facebookProfile = val;

    this.fire("facebookProfile", this);
  }
  public get companySize(): string {
    return this._companySize;
  }
  public setCompanySize(val: string): void {
    let isValChanged: boolean = this._companySize !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._COMPANYSIZE, this._companySize);

    this._companySize = val;

    this.fire("companySize", this);
  }
  public get companyRevenue(): string {
    return this._companyRevenue;
  }
  public setCompanyRevenue(val: string): void {
    let isValChanged: boolean = this._companyRevenue !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._COMPANYREVENUE, this._companyRevenue);

    this._companyRevenue = val;

    this.fire("companyRevenue", this);
  }
  public get companyWebsite(): string {
    return this._companyWebsite;
  }
  public setCompanyWebsite(val: string): void {
    let isValChanged: boolean = this._companyWebsite !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._COMPANYWEBSITE, this._companyWebsite);

    this._companyWebsite = val;

    this.fire("companyWebsite", this);
  }
  public get interests(): Array<string> {
    return this._interests;
  }
  public setInterests(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._interests,
      val
    );

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Lead._INTERESTS)) {
      let _old: Array<string> = Array.from(this._interests);

      this.updateD3EChanges(Lead._INTERESTS, _old);
    }

    this._interests.clear();

    this._interests.addAll(val);

    this.fire("interests", this);
  }
  public addToInterests(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Lead._INTERESTS);

    if (_isNewChange) {
      _old = Array.from(this._interests);
    }

    if (index === -1) {
      if (!this._interests.contains(val)) this._interests.add(val);
    } else {
      this._interests.remove(this._interests.elementAt(index));

      this._interests.add(val);
    }

    this.fire("interests", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._INTERESTS, _old);
    }
  }
  public removeFromInterests(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Lead._INTERESTS);

    if (_isNewChange) {
      _old = Array.from(this._interests);
    }

    this._interests.remove(val);

    this.fire("interests", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._INTERESTS, _old);
    }
  }
  public get familyInfo(): FamilyInfo {
    return this._familyInfo;
  }
  public setFamilyInfo(val: FamilyInfo): void {
    let isValChanged: boolean = this._familyInfo !== val;

    if (!isValChanged) {
      return;
    }

    if (this._familyInfo != null) {
      this._familyInfo.otherMaster = null;
    }

    if (val != null) {
      val.updateMaster(this, Lead._FAMILYINFO);
    }

    this.updateD3EChanges(Lead._FAMILYINFO, this._familyInfo);

    this.updateObservable("familyInfo", this._familyInfo, val);

    this._familyInfo = val;

    this.fire("familyInfo", this);
  }
  public get lastInteraction(): Interaction {
    return this._lastInteraction;
  }
  public setLastInteraction(val: Interaction): void {
    let isValChanged: boolean = this._lastInteraction !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._LASTINTERACTION, this._lastInteraction);

    this.updateObservable("lastInteraction", this._lastInteraction, val);

    this._lastInteraction = val;

    this.fire("lastInteraction", this);
  }
  public get createdBy(): User {
    return this._createdBy;
  }
  public setCreatedBy(val: User): void {
    let isValChanged: boolean = this._createdBy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._CREATEDBY, this._createdBy);

    this.updateObservable("createdBy", this._createdBy, val);

    this._createdBy = val;

    this.fire("createdBy", this);
  }
  public get emailStatus(): EmailStatus {
    return this._emailStatus;
  }
  public setEmailStatus(val: EmailStatus): void {
    let isValChanged: boolean = this._emailStatus !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._EMAILSTATUS, this._emailStatus.index);

    this._emailStatus = val;

    this.fire("emailStatus", this);
  }
  public get apolloId(): string {
    return this._apolloId;
  }
  public setApolloId(val: string): void {
    let isValChanged: boolean = this._apolloId !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._APOLLOID, this._apolloId);

    this._apolloId = val;

    this.fire("apolloId", this);
  }
  public get needs(): Array<string> {
    return this._needs;
  }
  public setNeeds(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this._needs, val);

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Lead._NEEDS)) {
      let _old: Array<string> = Array.from(this._needs);

      this.updateD3EChanges(Lead._NEEDS, _old);
    }

    this._needs.clear();

    this._needs.addAll(val);

    this.fire("needs", this);
  }
  public addToNeeds(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Lead._NEEDS);

    if (_isNewChange) {
      _old = Array.from(this._needs);
    }

    if (index === -1) {
      if (!this._needs.contains(val)) this._needs.add(val);
    } else {
      this._needs.remove(this._needs.elementAt(index));

      this._needs.add(val);
    }

    this.fire("needs", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._NEEDS, _old);
    }
  }
  public removeFromNeeds(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Lead._NEEDS);

    if (_isNewChange) {
      _old = Array.from(this._needs);
    }

    this._needs.remove(val);

    this.fire("needs", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._NEEDS, _old);
    }
  }
  public get pains(): Array<string> {
    return this._pains;
  }
  public setPains(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this._pains, val);

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Lead._PAINS)) {
      let _old: Array<string> = Array.from(this._pains);

      this.updateD3EChanges(Lead._PAINS, _old);
    }

    this._pains.clear();

    this._pains.addAll(val);

    this.fire("pains", this);
  }
  public addToPains(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Lead._PAINS);

    if (_isNewChange) {
      _old = Array.from(this._pains);
    }

    if (index === -1) {
      if (!this._pains.contains(val)) this._pains.add(val);
    } else {
      this._pains.remove(this._pains.elementAt(index));

      this._pains.add(val);
    }

    this.fire("pains", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._PAINS, _old);
    }
  }
  public removeFromPains(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Lead._PAINS);

    if (_isNewChange) {
      _old = Array.from(this._pains);
    }

    this._pains.remove(val);

    this.fire("pains", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Lead._PAINS, _old);
    }
  }
  public get handlingUser(): User {
    return this._handlingUser;
  }
  public setHandlingUser(val: User): void {
    let isValChanged: boolean = this._handlingUser !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._HANDLINGUSER, this._handlingUser);

    this.updateObservable("handlingUser", this._handlingUser, val);

    this._handlingUser = val;

    this.fire("handlingUser", this);
  }
  public get createdDate(): DateTime {
    return this._createdDate;
  }
  public setCreatedDate(val: DateTime): void {
    let isValChanged: boolean = this._createdDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._CREATEDDATE, this._createdDate);

    this._createdDate = val;

    this.fire("createdDate", this);
  }
  public get updatedDate(): DateTime {
    return this._updatedDate;
  }
  public setUpdatedDate(val: DateTime): void {
    let isValChanged: boolean = this._updatedDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Lead._UPDATEDDATE, this._updatedDate);

    this._updatedDate = val;

    this.fire("updatedDate", this);
  }
  public get(field: number): any {
    switch (field) {
      case Lead._NAME: {
        return this._name;
      }

      case Lead._EMAIL: {
        return this._email;
      }

      case Lead._PHONE: {
        return this._phone;
      }

      case Lead._COMPANY: {
        return this._company;
      }

      case Lead._STATUS: {
        return this._status.index;
      }

      case Lead._INDUSTRY: {
        return this._industry;
      }

      case Lead._PREVIOUSPOSITIONS: {
        return this._previousPositions;
      }

      case Lead._SKILLS: {
        return this._skills;
      }

      case Lead._EDUCATIONHISTORY: {
        return this._educationHistory;
      }

      case Lead._LEADASSIGNMENT: {
        return this._leadAssignment;
      }

      case Lead._PREFERREDCONTACTMETHOD: {
        return this._preferredContactMethod;
      }

      case Lead._TIMEZONE: {
        return this._timeZone;
      }

      case Lead._LANGUAGES: {
        return this._languages;
      }

      case Lead._BUDGET: {
        return this._budget;
      }

      case Lead._EXPRESSEDNEEDS: {
        return this._expressedNeeds;
      }

      case Lead._IDENTIFIEDPAINPOINTS: {
        return this._identifiedPainPoints;
      }

      case Lead._DECISIONMAKINGROLE: {
        return this._decisionMakingRole;
      }

      case Lead._INTERACTIONHISTORY: {
        return this._interactionHistory;
      }

      case Lead._LINKEDINPROFILE: {
        return this._linkedInProfile;
      }

      case Lead._INTERACTIONDATE: {
        return this._interactionDate;
      }

      case Lead._INTERACTIONTYPE: {
        return this._interactionType.index;
      }

      case Lead._DETAILS: {
        return this._details;
      }

      case Lead._TWITTERHANDLE: {
        return this._twitterHandle;
      }

      case Lead._FACEBOOKPROFILE: {
        return this._facebookProfile;
      }

      case Lead._COMPANYSIZE: {
        return this._companySize;
      }

      case Lead._COMPANYREVENUE: {
        return this._companyRevenue;
      }

      case Lead._COMPANYWEBSITE: {
        return this._companyWebsite;
      }

      case Lead._INTERESTS: {
        return this._interests;
      }

      case Lead._FAMILYINFO: {
        return this._familyInfo;
      }

      case Lead._LASTINTERACTION: {
        return this._lastInteraction;
      }

      case Lead._CREATEDBY: {
        return this._createdBy;
      }

      case Lead._EMAILSTATUS: {
        return this._emailStatus.index;
      }

      case Lead._APOLLOID: {
        return this._apolloId;
      }

      case Lead._NEEDS: {
        return this._needs;
      }

      case Lead._PAINS: {
        return this._pains;
      }

      case Lead._HANDLINGUSER: {
        return this._handlingUser;
      }

      case Lead._CREATEDDATE: {
        return this._createdDate;
      }

      case Lead._UPDATEDDATE: {
        return this._updatedDate;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Lead {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {
    ctx.collectChilds(this._educationHistory);

    ctx.collectChild(this._familyInfo);
  }
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: Lead = dbObj as Lead;

    obj.id = this.id;

    obj.setName(this._name);

    obj.setEmail(this._email);

    obj.setPhone(this._phone);

    obj.setCompany(this._company);

    obj.setStatus(this._status);

    obj.setIndustry(this._industry);

    obj.setPreviousPositions(this._previousPositions);

    obj.setSkills(this._skills);

    ctx.cloneChildList(this._educationHistory, (v) =>
      obj.setEducationHistory(v)
    );

    obj.setLeadAssignment(this._leadAssignment);

    obj.setPreferredContactMethod(this._preferredContactMethod);

    obj.setTimeZone(this._timeZone);

    obj.setLanguages(this._languages);

    obj.setBudget(this._budget);

    obj.setExpressedNeeds(this._expressedNeeds);

    obj.setIdentifiedPainPoints(this._identifiedPainPoints);

    obj.setDecisionMakingRole(this._decisionMakingRole);

    obj.setInteractionHistory(this._interactionHistory);

    obj.setLinkedInProfile(this._linkedInProfile);

    obj.setInteractionDate(this._interactionDate);

    obj.setInteractionType(this._interactionType);

    obj.setDetails(this._details);

    obj.setTwitterHandle(this._twitterHandle);

    obj.setFacebookProfile(this._facebookProfile);

    obj.setCompanySize(this._companySize);

    obj.setCompanyRevenue(this._companyRevenue);

    obj.setCompanyWebsite(this._companyWebsite);

    obj.setInterests(this._interests);

    ctx.cloneChild<FamilyInfo>(this._familyInfo, (v) => obj.setFamilyInfo(v));

    obj.setLastInteraction(this._lastInteraction);

    obj.setCreatedBy(this._createdBy);

    obj.setEmailStatus(this._emailStatus);

    obj.setApolloId(this._apolloId);

    obj.setNeeds(this._needs);

    obj.setPains(this._pains);

    obj.setHandlingUser(this._handlingUser);

    obj.setCreatedDate(this._createdDate);

    obj.setUpdatedDate(this._updatedDate);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Lead._NAME: {
        this.setName(value as string);
        break;
      }

      case Lead._EMAIL: {
        this.setEmail(value as string);
        break;
      }

      case Lead._PHONE: {
        this.setPhone(value as string);
        break;
      }

      case Lead._COMPANY: {
        this.setCompany(value as string);
        break;
      }

      case Lead._STATUS: {
        this.setStatus(LeadStatus.values[value as number]);
        break;
      }

      case Lead._INDUSTRY: {
        this.setIndustry(value as string);
        break;
      }

      case Lead._PREVIOUSPOSITIONS: {
        this.setPreviousPositions(
          (value as Array<any>).cast<string>().toList()
        );
        break;
      }

      case Lead._SKILLS: {
        this.setSkills((value as Array<any>).cast<string>().toList());
        break;
      }

      case Lead._EDUCATIONHISTORY: {
        this.setEducationHistory(
          (value as Array<any>).cast<Education>().toList()
        );
        break;
      }

      case Lead._LEADASSIGNMENT: {
        this.setLeadAssignment(value as LeadAssignment);
        break;
      }

      case Lead._PREFERREDCONTACTMETHOD: {
        this.setPreferredContactMethod(value as string);
        break;
      }

      case Lead._TIMEZONE: {
        this.setTimeZone(value as string);
        break;
      }

      case Lead._LANGUAGES: {
        this.setLanguages((value as Array<any>).cast<string>().toList());
        break;
      }

      case Lead._BUDGET: {
        this.setBudget(value as number);
        break;
      }

      case Lead._EXPRESSEDNEEDS: {
        this.setExpressedNeeds(value as string);
        break;
      }

      case Lead._IDENTIFIEDPAINPOINTS: {
        this.setIdentifiedPainPoints(value as string);
        break;
      }

      case Lead._DECISIONMAKINGROLE: {
        this.setDecisionMakingRole(value as string);
        break;
      }

      case Lead._INTERACTIONHISTORY: {
        this.setInteractionHistory(
          (value as Array<any>).cast<Interaction>().toList()
        );
        break;
      }

      case Lead._LINKEDINPROFILE: {
        this.setLinkedInProfile(value as string);
        break;
      }

      case Lead._INTERACTIONDATE: {
        this.setInteractionDate(value as D3EDate);
        break;
      }

      case Lead._INTERACTIONTYPE: {
        this.setInteractionType(InteractionType.values[value as number]);
        break;
      }

      case Lead._DETAILS: {
        this.setDetails(value as string);
        break;
      }

      case Lead._TWITTERHANDLE: {
        this.setTwitterHandle(value as string);
        break;
      }

      case Lead._FACEBOOKPROFILE: {
        this.setFacebookProfile(value as string);
        break;
      }

      case Lead._COMPANYSIZE: {
        this.setCompanySize(value as string);
        break;
      }

      case Lead._COMPANYREVENUE: {
        this.setCompanyRevenue(value as string);
        break;
      }

      case Lead._COMPANYWEBSITE: {
        this.setCompanyWebsite(value as string);
        break;
      }

      case Lead._INTERESTS: {
        this.setInterests((value as Array<any>).cast<string>().toList());
        break;
      }

      case Lead._FAMILYINFO: {
        this.setFamilyInfo(value as FamilyInfo);
        break;
      }

      case Lead._LASTINTERACTION: {
        this.setLastInteraction(value as Interaction);
        break;
      }

      case Lead._CREATEDBY: {
        this.setCreatedBy(value as User);
        break;
      }

      case Lead._EMAILSTATUS: {
        this.setEmailStatus(EmailStatus.values[value as number]);
        break;
      }

      case Lead._APOLLOID: {
        this.setApolloId(value as string);
        break;
      }

      case Lead._NEEDS: {
        this.setNeeds((value as Array<any>).cast<string>().toList());
        break;
      }

      case Lead._PAINS: {
        this.setPains((value as Array<any>).cast<string>().toList());
        break;
      }

      case Lead._HANDLINGUSER: {
        this.setHandlingUser(value as User);
        break;
      }

      case Lead._CREATEDDATE: {
        this.setCreatedDate(value as DateTime);
        break;
      }

      case Lead._UPDATEDDATE: {
        this.setUpdatedDate(value as DateTime);
        break;
      }
    }
  }
}
