import DFile from "../classes/DFile";
import WSReader from "../rocket/WSReader";
import MessageDispatch from "../rocket/MessageDispatch";
import { RPCConstants } from "../rocket/D3ETemplate";

export default class ExportFileUtilsClient {
  public async createSampleCSVForLeadData(): Promise<DFile> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.ExportFileUtils,
      RPCConstants.ExportFileUtilsCreateSampleCSVForLeadData,
      { "args": (w) => {} }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readDFile() as DFile;
  }
}
