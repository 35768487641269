import User from "./User";
import DateTime from "../core/DateTime";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class MicroSoftAcc extends DBObject {
  private static readonly _ACCESSTOKEN: number = 0;
  private static readonly _TOKENEXPERSAT: number = 1;
  private static readonly _USER: number = 2;
  public id: number = 0;
  public otherMaster: DBObject;
  private _user: User = null;
  private _accessToken: string = "";
  private _tokenExpersAt: DateTime = null;
  public constructor(
    d3eParams?: Partial<{
      accessToken: string;
      tokenExpersAt: DateTime;
      user: User;
    }>
  ) {
    super();

    this.setAccessToken(d3eParams?.accessToken ?? "");

    this.setTokenExpersAt(d3eParams?.tokenExpersAt ?? null);

    this.setUser(d3eParams?.user ?? null);
  }
  public get d3eType(): string {
    return "MicroSoftAcc";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get user(): User {
    return this._user;
  }
  public setUser(val: User): void {
    let isValChanged: boolean = this._user !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MicroSoftAcc._USER, this._user);

    this.updateObservable("user", this._user, val);

    this._user = val;

    this.fire("user", this);
  }
  public get accessToken(): string {
    return this._accessToken;
  }
  public setAccessToken(val: string): void {
    let isValChanged: boolean = this._accessToken !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MicroSoftAcc._ACCESSTOKEN, this._accessToken);

    this._accessToken = val;

    this.fire("accessToken", this);
  }
  public get tokenExpersAt(): DateTime {
    return this._tokenExpersAt;
  }
  public setTokenExpersAt(val: DateTime): void {
    let isValChanged: boolean = this._tokenExpersAt !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MicroSoftAcc._TOKENEXPERSAT, this._tokenExpersAt);

    this._tokenExpersAt = val;

    this.fire("tokenExpersAt", this);
  }
  public get(field: number): any {
    switch (field) {
      case MicroSoftAcc._USER: {
        return this._user;
      }

      case MicroSoftAcc._ACCESSTOKEN: {
        return this._accessToken;
      }

      case MicroSoftAcc._TOKENEXPERSAT: {
        return this._tokenExpersAt;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): MicroSoftAcc {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: MicroSoftAcc = dbObj as MicroSoftAcc;

    obj.id = this.id;

    obj.setUser(this._user);

    obj.setAccessToken(this._accessToken);

    obj.setTokenExpersAt(this._tokenExpersAt);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case MicroSoftAcc._USER: {
        this.setUser(value as User);
        break;
      }

      case MicroSoftAcc._ACCESSTOKEN: {
        this.setAccessToken(value as string);
        break;
      }

      case MicroSoftAcc._TOKENEXPERSAT: {
        this.setTokenExpersAt(value as DateTime);
        break;
      }
    }
  }
}
