import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import EventBus from "../utils/EventBus";
import MaterialIcons from "../icons/MaterialIcons";
import Timer from "../utils/Timer";
import IconButton from "./IconButton";
import CallInteraction from "../classes/CallInteraction";
import CallStatus from "../classes/CallStatus";
import Button from "./Button";
import TwilioClient from "../classes/TwilioClient";
import Duration from "../core/Duration";
import DateTime from "../core/DateTime";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _CallingProgressViewOnCall = () => void;

type _ButtonOnPressed = (d3eState: CallingProgressViewRefs) => void;

type _MutecallOnPressed = (d3eState: CallingProgressViewRefs) => void;

export interface CallingProgressViewProps extends BaseUIProps {
  key?: string;
  callInteraction: CallInteraction;
  client: TwilioClient;
  onCall: _CallingProgressViewOnCall;
}
/// To store state data for CallingProgressView
class CallingProgressViewRefs {
  public button: ButtonState = new ButtonState();
}

interface ButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: CallingProgressViewRefs;
  _callIconButtonHandler?: _ButtonOnPressed;
  _onVoiceMutePressed?: _MutecallOnPressed;
  callDuration: string;
  callInteraction: CallInteraction;
  client: TwilioClient;
  status: CallStatus;
}

class ButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ButtonWithState extends ObservableComponent<ButtonWithStateProps> {
  buttonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get button(): ButtonState {
    return this.props.d3eState.button;
  }
  public get callDuration(): string {
    return this.props.callDuration;
  }
  public get callInteraction(): CallInteraction {
    return this.props.callInteraction;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get status(): CallStatus {
    return this.props.status;
  }
  public get d3eState(): CallingProgressViewRefs {
    return this.props.d3eState;
  }
  public get _callIconButtonHandler(): _ButtonOnPressed {
    return this.props._callIconButtonHandler;
  }
  public get _onVoiceMutePressed(): _MutecallOnPressed {
    return this.props._onVoiceMutePressed;
  }
  public initState() {
    super.initState();

    this.updateObservable("button", null, this.button);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("callInteraction", this.props.callInteraction);

    this.on(
      [
        "button",
        "button.",
        "button.hover",
        "callDuration",
        "callInteraction",
        "callInteraction.lead",
        "callInteraction.lead.name",
        "client",
        "status",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.callInteraction !== this.props.callInteraction) {
      this.updateObservable(
        "callInteraction",
        prevProps.callInteraction,
        this.props.callInteraction
      );

      this.fire("callInteraction", this);
    }
  }
  public buttonOnEnter(event): void {
    return this.button.setHover(true);
  }
  public buttonOnExit(event): void {
    return this.button.setHover(false);
  }
  public dispose(): void {
    this.button.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.button.hover
          ? cStyle.tButtonCallPrimaryPaddingOnHover
          : ui.EdgeInsets.symmetric({
              horizontal: 4.0,
              vertical: 1.0,
              transitions: new Map(),
            }),
        decoration: this.button.hover
          ? cStyle.tButtonCallPrimaryDecorationOnHover
          : cStyle.tButtonCallPrimaryDecorationOn,
        disable: this.button.disable,
        onPressed: () => {
          this._callIconButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            IconView({
              icon: MaterialIcons.call,
              color: cStyle.c14,
              size: 25,
              className: "x5fa",
              key: "0",
            }),
            ui.Column({
              children: [
                TextView({
                  data: this.callInteraction.lead.name,
                  textAlign: ui.TextAlign.start,
                  style: new ui.TextStyle({ fontSize: 12 }),
                  className: "x5c9",
                  key: "0",
                }),
                ui.Row({
                  children: [
                    TextView({
                      data: this.callDuration,
                      style: new ui.TextStyle({ fontSize: 12 }),
                      className: "x5f6",
                      key: "0",
                    }),
                    TextView({
                      data: this.status.name,
                      states: ui.joinStates({ "data-visibility": false }, {}),
                      style: new ui.TextStyle({ fontSize: 11 }),
                      className: "x3a2",
                      key: "1",
                    }),
                  ],
                  className: "xea5",
                  key: "1",
                }),
              ],
              className: "x4ea",
              key: "1",
            }),
            IconButton({
              icon: this.client.isMuted
                ? MaterialIcons.mic_off
                : MaterialIcons.mic,
              color: cStyle.c14,
              onPressed: () => {
                this._onVoiceMutePressed(this.d3eState);
              },
              tooltip: this.client.isMuted ? "Unmute" : null,
              key: "2",
            }),
          ],
        }),
        onEnter: (event) => {
          this.buttonOnEnter(event);
        },
        onExit: (event) => {
          this.buttonOnExit(event);
        },
      }),
      className: ui.join(this.props.className, "CallingProgressView xe5"),
      ...copyBaseUIProps(this.props),
    });
  }
}
function ButtonWithState(props: ButtonWithStateProps) {
  return React.createElement(_ButtonWithState, props);
}

class _CallingProgressViewState extends ObservableComponent<CallingProgressViewProps> {
  static defaultProps = { callInteraction: null, client: null, onCall: null };
  d3eState: CallingProgressViewRefs = new CallingProgressViewRefs();
  callDuration: string = "";
  status: CallStatus = CallStatus.Initiated;
  isMute: boolean = false;
  callStatusVal: CallStatus = CallStatus.Initiated;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CallingProgressViewProps) {
    super(props);

    this.initState();
  }
  public get callInteraction(): CallInteraction {
    return this.props.callInteraction;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("callInteraction", this.props.callInteraction);

    this.on(
      [
        "callInteraction",
        "callInteraction.data",
        "callInteraction.data.callStatus",
      ],
      this.computeStatus
    );

    this.computeStatus();

    this.on(
      [
        "callInteraction",
        "callInteraction.interaction",
        "callInteraction.interaction.callStatus",
      ],
      this.computeCallStatusVal
    );

    this.computeCallStatusVal();

    this.on(
      ["callDuration", "callInteraction", "client", "status"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: CallingProgressViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.callInteraction !== this.props.callInteraction) {
      this.updateObservable(
        "callInteraction",
        prevProps.callInteraction,
        this.props.callInteraction
      );

      this.fire("callInteraction", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }
  }
  public setCallDuration(val: string): void {
    let isValChanged: boolean = this.callDuration !== val;

    if (!isValChanged) {
      return;
    }

    this.callDuration = val;

    this.fire("callDuration", this);
  }
  public setStatus(val: CallStatus): void {
    let isValChanged: boolean = this.status !== val;

    if (!isValChanged) {
      return;
    }

    this.status = val;

    this.onCallId();

    this.fire("status", this);
  }
  public computeStatus = (): void => {
    try {
      this.setStatus(this.callInteraction.data.callStatus);
    } catch (exception) {
      console.log(" exception in computeStatus : " + exception.toString());

      this.setStatus(CallStatus.Initiated);
    }
  };
  public setIsMute(val: boolean): void {
    let isValChanged: boolean = this.isMute !== val;

    if (!isValChanged) {
      return;
    }

    this.isMute = val;

    this.fire("isMute", this);
  }
  public setCallStatusVal(val: CallStatus): void {
    let isValChanged: boolean = this.callStatusVal !== val;

    if (!isValChanged) {
      return;
    }

    this.callStatusVal = val;

    this.onCallStatusChanges();

    this.fire("callStatusVal", this);
  }
  public computeCallStatusVal = (): void => {
    try {
      this.setCallStatusVal(this.callInteraction.interaction.callStatus);
    } catch (exception) {
      console.log(
        " exception in computeCallStatusVal : " + exception.toString()
      );

      this.setCallStatusVal(CallStatus.Initiated);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ButtonWithState({
      d3eState: this.d3eState,
      _callIconButtonHandler: this.callIconButtonHandler,
      _onVoiceMutePressed: this.onVoiceMutePressed,
      callDuration: this.callDuration,
      callInteraction: this.callInteraction,
      client: this.client,
      status: this.status,
    });
  }
  public onInit = (): void => {
    this.onChangeCallId();
  };
  public onCallStatusChanges = (): void => {};
  public onChangeCallId = (): void => {
    if (this.callInteraction.interaction.startTime === null) {
      this.callInteraction.interaction.setStartTime(DateTime.now());
    }

    let startTime: DateTime = this.callInteraction.interaction.startTime;

    let tmr: Timer = Timer.periodic(new Duration({ seconds: 1 }), (timer) => {
      //  Calculate the duration

      let duration: Duration = DateTime.now().difference(startTime);

      let totalInSeconds: number = duration.inSeconds.toDouble();

      //  Calculate hours, minutes, and seconds

      let hours: number = totalInSeconds / 3600;

      hours = hours.floorToDouble();

      let minutes: number = totalInSeconds / 60;

      minutes = minutes.floorToDouble();

      let seconds: number = totalInSeconds % 60;

      //  Convert minutes and seconds to strings

      let minutesStr: string = minutes.toStringAsFixed(0);

      let secondsStr: string = seconds.toStringAsFixed(0);

      //  Manually add leading zero if needed

      if (minutesStr.length === 1) {
        minutesStr = "0" + minutesStr;
      }

      if (secondsStr.length === 1) {
        secondsStr = "0" + secondsStr;
      }

      //  Format the call duration string

      if (hours >= 1.0) {
        //  Show hours if the duration is 1 hour or more

        this.setCallDuration(
          hours.toString() + ":" + minutesStr + ":" + secondsStr
        );
      } else {
        //  Show only minutes and seconds

        this.setCallDuration(minutesStr + ":" + secondsStr);
      }
    });
  };
  public onCallId = (): void => {
    if (
      this.callInteraction.data !== null &&
      (this.callInteraction.data.callSid === null ||
        this.callInteraction.data.callSid.isEmpty)
    ) {
      this.onCall();
    }
  };
  public callIconButtonHandler = (d3eState: CallingProgressViewRefs): void => {
    this.onCall();
  };
  public onVoiceMutePressed = async (
    d3eState: CallingProgressViewRefs
  ): Promise<void> => {
    if (this.client.isMuted) {
      let unmute: boolean = await this.client.unmuteCall({
        onSuccess: (e) => {
          this.setIsMute(false);
        },
        onFailure: (e, s) => {
          EventBus.get().fire(new FailureMessage({ message: e }));
        },
      });
    } else {
      let mute: boolean = await this.client.muteCall({
        onSuccess: (e) => {
          this.setIsMute(true);
        },
        onFailure: (e, s) => {
          EventBus.get().fire(new FailureMessage({ message: e }));
        },
      });
    }
  };
  public get onCall(): _CallingProgressViewOnCall {
    return this.props.onCall;
  }
  public get button() {
    return this.d3eState.button;
  }
}
export default function CallingProgressView(props: CallingProgressViewProps) {
  return React.createElement(_CallingProgressViewState, {
    ..._CallingProgressViewState.defaultProps,
    ...props,
  });
}
