import MessageStatus from "../classes/MessageStatus";
import CallStatus from "../classes/CallStatus";
import User from "./User";
import ListWrapper from "../utils/ListWrapper";
import Lead from "./Lead";
import InteractionType from "../classes/InteractionType";
import Duration from "../core/Duration";
import DateTime from "../core/DateTime";
import DFile from "../classes/DFile";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";
import CollectionUtils from "../utils/CollectionUtils";

export default class Interaction extends DBObject {
  private static readonly _BODY: number = 0;
  private static readonly _CALLSTATUS: number = 1;
  private static readonly _CONVERSATION: number = 2;
  private static readonly _CREATEDDATE: number = 3;
  private static readonly _DETAILS: number = 4;
  private static readonly _DURATION: number = 5;
  private static readonly _ENDTIME: number = 6;
  private static readonly _FILE: number = 7;
  private static readonly _FROMNUMBER: number = 8;
  private static readonly _HANDLEDBY: number = 9;
  private static readonly _ISINCOMING: number = 10;
  private static readonly _LEAD: number = 11;
  private static readonly _MESSAGESTATUS: number = 12;
  private static readonly _NOTES: number = 13;
  private static readonly _RECORDINGURL: number = 14;
  private static readonly _SID: number = 15;
  private static readonly _STARTTIME: number = 16;
  private static readonly _TONUMBER: number = 17;
  private static readonly _TYPE: number = 18;
  public id: number = 0;
  public otherMaster: DBObject;
  private _lead: Lead = null;
  private _sid: string = "";
  private _type: InteractionType = InteractionType.Call;
  private _details: string = "";
  private _toNumber: string = "";
  private _fromNumber: string = "";
  private _recordingURL: string = "";
  private _startTime: DateTime = null;
  private _endTime: DateTime = null;
  private _handledBy: User = null;
  private _notes: Array<string> = ListWrapper.primitive(
    this,
    "notes",
    Interaction._NOTES
  );
  private _duration: Duration = null;
  private _callStatus: CallStatus = CallStatus.Initiated;
  private _file: DFile = null;
  private _conversation: string = "";
  private _isIncoming: boolean = false;
  private _body: string = "";
  private _messageStatus: MessageStatus = MessageStatus.Sent;
  private _createdDate: DateTime = null;
  public constructor(
    d3eParams?: Partial<{
      body: string;
      callStatus: CallStatus;
      conversation: string;
      createdDate: DateTime;
      details: string;
      duration: Duration;
      endTime: DateTime;
      file: DFile;
      fromNumber: string;
      handledBy: User;
      isIncoming: boolean;
      lead: Lead;
      messageStatus: MessageStatus;
      notes: Array<string>;
      recordingURL: string;
      sid: string;
      startTime: DateTime;
      toNumber: string;
      type: InteractionType;
    }>
  ) {
    super();

    this.setBody(d3eParams?.body ?? "");

    this.setCallStatus(d3eParams?.callStatus ?? CallStatus.Initiated);

    this.setConversation(d3eParams?.conversation ?? "");

    this.setCreatedDate(d3eParams?.createdDate ?? null);

    this.setDetails(d3eParams?.details ?? "");

    this.setDuration(d3eParams?.duration ?? null);

    this.setEndTime(d3eParams?.endTime ?? null);

    this.setFile(d3eParams?.file ?? null);

    this.setFromNumber(d3eParams?.fromNumber ?? "");

    this.setHandledBy(d3eParams?.handledBy ?? null);

    this.setIsIncoming(d3eParams?.isIncoming ?? false);

    this.setLead(d3eParams?.lead ?? null);

    this.setMessageStatus(d3eParams?.messageStatus ?? MessageStatus.Sent);

    this.setNotes(
      d3eParams?.notes ??
        ListWrapper.primitive(this, "notes", Interaction._NOTES)
    );

    this.setRecordingURL(d3eParams?.recordingURL ?? "");

    this.setSid(d3eParams?.sid ?? "");

    this.setStartTime(d3eParams?.startTime ?? null);

    this.setToNumber(d3eParams?.toNumber ?? "");

    this.setType(d3eParams?.type ?? InteractionType.Call);
  }
  public get d3eType(): string {
    return "Interaction";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get lead(): Lead {
    return this._lead;
  }
  public setLead(val: Lead): void {
    let isValChanged: boolean = this._lead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._LEAD, this._lead);

    this.updateObservable("lead", this._lead, val);

    this._lead = val;

    this.fire("lead", this);
  }
  public get sid(): string {
    return this._sid;
  }
  public setSid(val: string): void {
    let isValChanged: boolean = this._sid !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._SID, this._sid);

    this._sid = val;

    this.fire("sid", this);
  }
  public get type(): InteractionType {
    return this._type;
  }
  public setType(val: InteractionType): void {
    let isValChanged: boolean = this._type !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._TYPE, this._type.index);

    this._type = val;

    this.fire("type", this);
  }
  public get details(): string {
    return this._details;
  }
  public setDetails(val: string): void {
    let isValChanged: boolean = this._details !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._DETAILS, this._details);

    this._details = val;

    this.fire("details", this);
  }
  public get toNumber(): string {
    return this._toNumber;
  }
  public setToNumber(val: string): void {
    let isValChanged: boolean = this._toNumber !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._TONUMBER, this._toNumber);

    this._toNumber = val;

    this.fire("toNumber", this);
  }
  public get fromNumber(): string {
    return this._fromNumber;
  }
  public setFromNumber(val: string): void {
    let isValChanged: boolean = this._fromNumber !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._FROMNUMBER, this._fromNumber);

    this._fromNumber = val;

    this.fire("fromNumber", this);
  }
  public get recordingURL(): string {
    return this._recordingURL;
  }
  public setRecordingURL(val: string): void {
    let isValChanged: boolean = this._recordingURL !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._RECORDINGURL, this._recordingURL);

    this._recordingURL = val;

    this.fire("recordingURL", this);
  }
  public get startTime(): DateTime {
    return this._startTime;
  }
  public setStartTime(val: DateTime): void {
    let isValChanged: boolean = this._startTime !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._STARTTIME, this._startTime);

    this._startTime = val;

    this.fire("startTime", this);
  }
  public get endTime(): DateTime {
    return this._endTime;
  }
  public setEndTime(val: DateTime): void {
    let isValChanged: boolean = this._endTime !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._ENDTIME, this._endTime);

    this._endTime = val;

    this.fire("endTime", this);
  }
  public get handledBy(): User {
    return this._handledBy;
  }
  public setHandledBy(val: User): void {
    let isValChanged: boolean = this._handledBy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._HANDLEDBY, this._handledBy);

    this.updateObservable("handledBy", this._handledBy, val);

    this._handledBy = val;

    this.fire("handledBy", this);
  }
  public get notes(): Array<string> {
    return this._notes;
  }
  public setNotes(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this._notes, val);

    if (!isValChanged) {
      return;
    }

    if (!this.d3eChanges.contains(Interaction._NOTES)) {
      let _old: Array<string> = Array.from(this._notes);

      this.updateD3EChanges(Interaction._NOTES, _old);
    }

    this._notes.clear();

    this._notes.addAll(val);

    this.fire("notes", this);
  }
  public addToNotes(val: string, index: number = -1): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Interaction._NOTES);

    if (_isNewChange) {
      _old = Array.from(this._notes);
    }

    if (index === -1) {
      if (!this._notes.contains(val)) this._notes.add(val);
    } else {
      this._notes.remove(this._notes.elementAt(index));

      this._notes.add(val);
    }

    this.fire("notes", this, val, true);

    if (_isNewChange) {
      this.updateD3EChanges(Interaction._NOTES, _old);
    }
  }
  public removeFromNotes(val: string): void {
    let _old: Array<string> = [];

    let _isNewChange: boolean = !this.d3eChanges.contains(Interaction._NOTES);

    if (_isNewChange) {
      _old = Array.from(this._notes);
    }

    this._notes.remove(val);

    this.fire("notes", this, val, false);

    if (_isNewChange) {
      this.updateD3EChanges(Interaction._NOTES, _old);
    }
  }
  public get duration(): Duration {
    return this._duration;
  }
  public setDuration(val: Duration): void {
    let isValChanged: boolean = this._duration !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._DURATION, this._duration);

    this._duration = val;

    this.fire("duration", this);
  }
  public get callStatus(): CallStatus {
    return this._callStatus;
  }
  public setCallStatus(val: CallStatus): void {
    let isValChanged: boolean = this._callStatus !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._CALLSTATUS, this._callStatus.index);

    this._callStatus = val;

    this.fire("callStatus", this);
  }
  public get file(): DFile {
    return this._file;
  }
  public setFile(val: DFile): void {
    let isValChanged: boolean = this._file !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._FILE, this._file);

    this._file = val;

    this.fire("file", this);
  }
  public get conversation(): string {
    return this._conversation;
  }
  public setConversation(val: string): void {
    let isValChanged: boolean = this._conversation !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._CONVERSATION, this._conversation);

    this._conversation = val;

    this.fire("conversation", this);
  }
  public get isIncoming(): boolean {
    return this._isIncoming;
  }
  public setIsIncoming(val: boolean): void {
    let isValChanged: boolean = this._isIncoming !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._ISINCOMING, this._isIncoming);

    this._isIncoming = val;

    this.fire("isIncoming", this);
  }
  public get body(): string {
    return this._body;
  }
  public setBody(val: string): void {
    let isValChanged: boolean = this._body !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._BODY, this._body);

    this._body = val;

    this.fire("body", this);
  }
  public get messageStatus(): MessageStatus {
    return this._messageStatus;
  }
  public setMessageStatus(val: MessageStatus): void {
    let isValChanged: boolean = this._messageStatus !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      Interaction._MESSAGESTATUS,
      this._messageStatus.index
    );

    this._messageStatus = val;

    this.fire("messageStatus", this);
  }
  public get createdDate(): DateTime {
    return this._createdDate;
  }
  public setCreatedDate(val: DateTime): void {
    let isValChanged: boolean = this._createdDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Interaction._CREATEDDATE, this._createdDate);

    this._createdDate = val;

    this.fire("createdDate", this);
  }
  public get(field: number): any {
    switch (field) {
      case Interaction._LEAD: {
        return this._lead;
      }

      case Interaction._SID: {
        return this._sid;
      }

      case Interaction._TYPE: {
        return this._type.index;
      }

      case Interaction._DETAILS: {
        return this._details;
      }

      case Interaction._TONUMBER: {
        return this._toNumber;
      }

      case Interaction._FROMNUMBER: {
        return this._fromNumber;
      }

      case Interaction._RECORDINGURL: {
        return this._recordingURL;
      }

      case Interaction._STARTTIME: {
        return this._startTime;
      }

      case Interaction._ENDTIME: {
        return this._endTime;
      }

      case Interaction._HANDLEDBY: {
        return this._handledBy;
      }

      case Interaction._NOTES: {
        return this._notes;
      }

      case Interaction._DURATION: {
        return this._duration;
      }

      case Interaction._CALLSTATUS: {
        return this._callStatus.index;
      }

      case Interaction._FILE: {
        return this._file;
      }

      case Interaction._CONVERSATION: {
        return this._conversation;
      }

      case Interaction._ISINCOMING: {
        return this._isIncoming;
      }

      case Interaction._BODY: {
        return this._body;
      }

      case Interaction._MESSAGESTATUS: {
        return this._messageStatus.index;
      }

      case Interaction._CREATEDDATE: {
        return this._createdDate;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Interaction {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: Interaction = dbObj as Interaction;

    obj.id = this.id;

    obj.setLead(this._lead);

    obj.setSid(this._sid);

    obj.setType(this._type);

    obj.setDetails(this._details);

    obj.setToNumber(this._toNumber);

    obj.setFromNumber(this._fromNumber);

    obj.setRecordingURL(this._recordingURL);

    obj.setStartTime(this._startTime);

    obj.setEndTime(this._endTime);

    obj.setHandledBy(this._handledBy);

    obj.setNotes(this._notes);

    obj.setDuration(this._duration);

    obj.setCallStatus(this._callStatus);

    obj.setFile(this._file);

    obj.setConversation(this._conversation);

    obj.setIsIncoming(this._isIncoming);

    obj.setBody(this._body);

    obj.setMessageStatus(this._messageStatus);

    obj.setCreatedDate(this._createdDate);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Interaction._LEAD: {
        this.setLead(value as Lead);
        break;
      }

      case Interaction._SID: {
        this.setSid(value as string);
        break;
      }

      case Interaction._TYPE: {
        this.setType(InteractionType.values[value as number]);
        break;
      }

      case Interaction._DETAILS: {
        this.setDetails(value as string);
        break;
      }

      case Interaction._TONUMBER: {
        this.setToNumber(value as string);
        break;
      }

      case Interaction._FROMNUMBER: {
        this.setFromNumber(value as string);
        break;
      }

      case Interaction._RECORDINGURL: {
        this.setRecordingURL(value as string);
        break;
      }

      case Interaction._STARTTIME: {
        this.setStartTime(value as DateTime);
        break;
      }

      case Interaction._ENDTIME: {
        this.setEndTime(value as DateTime);
        break;
      }

      case Interaction._HANDLEDBY: {
        this.setHandledBy(value as User);
        break;
      }

      case Interaction._NOTES: {
        this.setNotes((value as Array<any>).cast<string>().toList());
        break;
      }

      case Interaction._DURATION: {
        this.setDuration(value as Duration);
        break;
      }

      case Interaction._CALLSTATUS: {
        this.setCallStatus(CallStatus.values[value as number]);
        break;
      }

      case Interaction._FILE: {
        this.setFile(value as DFile);
        break;
      }

      case Interaction._CONVERSATION: {
        this.setConversation(value as string);
        break;
      }

      case Interaction._ISINCOMING: {
        this.setIsIncoming(value as boolean);
        break;
      }

      case Interaction._BODY: {
        this.setBody(value as string);
        break;
      }

      case Interaction._MESSAGESTATUS: {
        this.setMessageStatus(MessageStatus.values[value as number]);
        break;
      }

      case Interaction._CREATEDDATE: {
        this.setCreatedDate(value as DateTime);
        break;
      }
    }
  }
}
