import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import PaginationUtils from "../classes/PaginationUtils";
import RoundedIconButton from "./RoundedIconButton";
import DropDown from "./DropDown";

type _PaginationViewOnChange = (pageSize: number, offset: number) => void;

export interface PaginationViewProps extends BaseUIProps {
  key?: string;
  pageSize: number;
  offset: number;
  total: number;
  onChange?: _PaginationViewOnChange;
}

class _PaginationViewState extends ObservableComponent<PaginationViewProps> {
  static defaultProps = { pageSize: 0, offset: 0, total: 0, onChange: null };
  hRMDropdownFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: PaginationViewProps) {
    super(props);

    this.initState();
  }
  public get pageSize(): number {
    return this.props.pageSize;
  }
  public get offset(): number {
    return this.props.offset;
  }
  public get total(): number {
    return this.props.total;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["offset", "pageSize", "total"], this.rebuild);
  }
  public componentDidUpdate(prevProps: PaginationViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.pageSize !== this.props.pageSize) {
      this.fire("pageSize", this);
    }

    if (prevProps.offset !== this.props.offset) {
      this.fire("offset", this);
    }

    if (prevProps.total !== this.props.total) {
      this.fire("total", this);
    }
  }
  public render(): ReactNode {
    return ui.Container({
      child: ui.Row({
        mainAxisAlignment: ui.MainAxisAlignment.end,
        children: [
          TextView({ data: "Rows per page", className: "x1a66", key: "0" }),
          ui.Container({
            width: 60,
            child: DropDown<number>({
              value: this.pageSize,
              items: [10, 20, 50, 100],
              dropDownDecoration: new ui.BoxDecoration({
                color: new ui.Color(0x0),
              }),
              onChanged: (value) => {
                this.onPressedButtonHandler(value);
              },
              onFocusChange: (val) => {},
              builder: (context, item) => {
                return TextView({
                  data: item.toString(),
                  textAlign: ui.TextAlign.center,
                });
              },
              focusNode: this.hRMDropdownFocusNode,
            }),
            key: "1",
            className: "xc5 hc",
          }),
          TextView({
            data: PaginationUtils.pageNumberPath(
              this.pageSize,
              this.offset,
              this.total
            ),
            className: "x4aa4",
            key: "2",
          }),
          PaginationUtils.hideBackwardArrow(
            this.pageSize,
            this.offset,
            this.total
          )
            ? ui.Container({})
            : ui.Container({
                padding: ui.EdgeInsets.symmetric({
                  horizontal: 5.0,
                  vertical: 0.0,
                  transitions: new Map(),
                }),
                child: RoundedIconButton({
                  size: 16,
                  icon: MaterialIcons.arrow_back_ios,
                  onPressed: () => {
                    this.onPressedButton2Handler();
                  },
                }),
                className: "xba8",
              }),
          PaginationUtils.hideForwardArrow(
            this.pageSize,
            this.offset,
            this.total
          )
            ? ui.Container({})
            : RoundedIconButton({
                icon: MaterialIcons.arrow_forward_ios,
                size: 16,
                onPressed: () => {
                  this.onPressedButtonHandler2();
                },
              }),
        ],
        className: "xe84",
      }),
      className: ui.join(this.props.className, "PaginationView x4aa"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedButtonHandler = (value: number): void => {
    this.onChange(value, 0);
  };
  public onPressedButtonHandler2 = (): void => {
    this.onChange(this.pageSize, this.pageSize + this.offset);
  };
  public onPressedButton2Handler = (): void => {
    this.onChange(this.pageSize, this.offset - this.pageSize);
  };
  public get onChange(): _PaginationViewOnChange {
    return this.props.onChange;
  }
}
export default function PaginationView(props: PaginationViewProps) {
  return React.createElement(_PaginationViewState, {
    ..._PaginationViewState.defaultProps,
    ...props,
  });
}
