import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TabButton from "./TabButton";
import MailStatusView from "./MailStatusView";
import MailPreviewView from "./MailPreviewView";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import SearchComponent from "./SearchComponent";
import MailBox from "../classes/MailBox";
import MailBoxRequest from "../models/MailBoxRequest";
import ListWrapper from "../utils/ListWrapper";
import ScrollView2 from "./ScrollView2";
import TextView from "./TextView";
import CollectionUtils from "../utils/CollectionUtils";
import MailMessage from "../models/MailMessage";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

export interface MailsPageProps extends BaseUIProps {
  key?: string;
}

class _MailsPageState extends ObservableComponent<MailsPageProps> {
  columnScrollController: ui.ScrollController = new ui.ScrollController();
  query: MailBox = null;
  request: MailBoxRequest = null;
  mails: Array<MailMessage> = ListWrapper.widget(this, "mails");
  searchMail: string = "";
  selectedMail: MailMessage = null;
  selectedTab: string = "All";
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: MailsPageProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.on(
      ["request", "request.offset", "request.pageSize", "request.searchMail"],
      this.computeQuery
    );

    this.computeQuery();

    this.on(["query", "query.items"], this.computeMails);

    this.computeMails();

    this.on(
      ["mails", "mails.inbound", "selectedMail", "selectedTab"],
      this.rebuild
    );
  }
  public setQuery(val: MailBox): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    MessageDispatch.get().dispose(this.query);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getMailBox(
          UsageConstants.QUERY_GETMAILBOX_MAILSPAGE_PROPERTIES_QUERY_COMPUTATION,
          this.request,
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setRequest(val: MailBoxRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setMails(val: Array<MailMessage>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.mails, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("mails", this.mails, val);

    this.mails.clear();

    this.mails.addAll(val);

    this.fire("mails", this);
  }
  public addToMails(val: MailMessage, index: number = -1): void {
    if (index === -1) {
      if (!this.mails.contains(val)) this.mails.add(val);
    } else {
      this.mails.remove(this.mails.elementAt(index));

      this.mails.add(val);
    }

    this.fire("mails", this, val, true);

    this.updateObservable("mails", null, val);
  }
  public removeFromMails(val: MailMessage): void {
    this.mails.remove(val);

    this.fire("mails", this, val, false);

    this.removeObservable("mails", val);
  }
  public computeMails = (): void => {
    try {
      this.setMails(Array.from(this.query?.items ?? []));
    } catch (exception) {
      console.log(" exception in computeMails : " + exception.toString());

      this.setMails([]);
    }
  };
  public setSearchMail(val: string): void {
    let isValChanged: boolean = this.searchMail !== val;

    if (!isValChanged) {
      return;
    }

    this.searchMail = val;

    this.fire("searchMail", this);
  }
  public setSelectedMail(val: MailMessage): void {
    let isValChanged: boolean = this.selectedMail !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("selectedMail", this.selectedMail, val);

    this.selectedMail = val;

    this.fire("selectedMail", this);
  }
  public setSelectedTab(val: string): void {
    let isValChanged: boolean = this.selectedTab !== val;

    if (!isValChanged) {
      return;
    }

    this.selectedTab = val;

    this.fire("selectedTab", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          children: [
            ui.Column({
              children: [
                ui.Container({
                  width: 380,
                  child: SearchComponent({
                    placeholder: "Search Mail",
                    onChanged: (value) => {
                      this.onSearchTapHandler(value);
                    },
                  }),
                  key: "0",
                  className: "x07b hc",
                }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  crossAxisAlignment: ui.CrossAxisAlignment.center,
                  children: [
                    ["All", "Inbox", "Sent"].expand((tabItem) => [
                      TabButton({
                        title: tabItem,
                        selectedTab: this.selectedTab,
                        onClick: (selectedTabItem) => {
                          this.onPressedTabHandler(selectedTabItem, tabItem);
                        },
                        key: tabItem?.toString(),
                      }),
                    ]),
                  ],
                  className: "x2e2",
                  key: "1",
                }),
                ScrollView2({
                  child: ui.Column({
                    children: [
                      this.selectedTab === "All"
                        ? this.mails.expand((mail) => [
                            ui.Container({
                              margin: ui.EdgeInsets.fromLTRB(
                                0.0,
                                0.0,
                                0.0,
                                12.0,
                                new Map()
                              ),
                              width: 380,
                              child: MailStatusView({
                                mail: mail,
                                selectedEmail: this.selectedMail,
                                onMailClick: (email) => {
                                  this.onMessageClick(email, mail);
                                },
                              }),
                              key: mail?.ident,
                              className: "x994 hc",
                            }),
                          ])
                        : this.selectedTab === "Inbox"
                        ? this.mails
                            .where((m) => m.inbound)
                            .expand((mail) => [
                              ui.Container({
                                margin: ui.EdgeInsets.fromLTRB(
                                  0.0,
                                  0.0,
                                  0.0,
                                  12.0,
                                  new Map()
                                ),
                                width: 380,
                                child: MailStatusView({
                                  mail: mail,
                                  selectedEmail: this.selectedMail,
                                  onMailClick: (email) => {
                                    this.onInboxMessageClick(email, mail);
                                  },
                                }),
                                key: mail?.ident,
                                className: "x6963 hc",
                              }),
                            ])
                        : this.mails
                            .where((m) => !m.inbound)
                            .expand((mail) => [
                              ui.Container({
                                margin: ui.EdgeInsets.fromLTRB(
                                  0.0,
                                  0.0,
                                  0.0,
                                  12.0,
                                  new Map()
                                ),
                                width: 380,
                                child: MailStatusView({
                                  mail: mail,
                                  selectedEmail: this.selectedMail,
                                  onMailClick: (email) => {
                                    this.onSentMailButton(email, mail);
                                  },
                                }),
                                key: mail?.ident,
                                className: "x21a hc",
                              }),
                            ]),
                    ],
                    key: "2",
                  }),
                  scrollDirection: ui.Axis.vertical,
                  className: "xb01 vc v",
                  controller: this.columnScrollController,
                }),
              ],
              className: "x8db vc v",
              key: "0",
            }),
            ui.Container({
              child:
                this.selectedMail !== null
                  ? MailPreviewView({
                      message: this.selectedMail,
                      className: "x4b9 hc vc h v",
                    })
                  : ui.Column({
                      mainAxisAlignment: ui.MainAxisAlignment.center,
                      crossAxisAlignment: ui.CrossAxisAlignment.center,
                      children: [
                        ui.AssetImage({
                          path: "images/NoMail.png",
                          width: 200,
                          height: 200,
                          fit: ui.BoxFit.fill,
                          className: "xed8 hc vc",
                          key: "0",
                        }),
                        TextView({
                          data: "No Mail Selected",
                          className: "xde7 hc",
                          key: "1",
                        }),
                      ],
                      className: "xdad hc vc v",
                    }),
              className: "xd38 hc vc h v",
              key: "1",
            }),
          ],
          className: "x6a4 hc vc h v",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "MailsPage xa96 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(
      new MailBoxRequest({
        searchMail: this.searchMail,
        pageSize: 50,
        offset: 0,
      })
    );
  };
  public onMessageClick = (email: MailMessage, mail: MailMessage): void => {
    this.setSelectedMail(email);
  };
  public onPressedTabHandler = (
    selectedTabItem: string,
    tabItem: string
  ): void => {
    this.setSelectedTab(tabItem);
  };
  public onInboxMessageClick = (
    email: MailMessage,
    mail: MailMessage
  ): void => {
    this.setSelectedMail(mail);
  };
  public onSentMailButton = (email: MailMessage, mail: MailMessage): void => {
    this.setSelectedMail(mail);
  };
  public onSearchTapHandler = (value: string): void => {
    this.setSearchMail(value);

    this.request.setSearchMail(value);

    this.setRequest(this.request.deepClone());
  };
  public dispose(): void {
    MessageDispatch.get().dispose(this.query);

    super.dispose();
  }
}
export default function MailsPage(props: MailsPageProps) {
  return React.createElement(_MailsPageState, props);
}
