import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import CompanySearchItem from "../classes/CompanySearchItem";
import { BuildContext } from "../classes/BuildContext";

type _SearchCompaniesOnSelectItem = (item: CompanySearchItem) => void;

type _OnItemOnTap = (d3eState: _ItemState) => void;

type _NoneOptionOnTap = (d3eState: SearchCompaniesRefs) => void;

export interface SearchCompaniesProps extends BaseUIProps {
  key?: string;
  value?: CompanySearchItem;
  items?: Array<CompanySearchItem>;
  itemColor?: ui.Color;
  _itemsHash?: number;
  onSelectItem?: _SearchCompaniesOnSelectItem;
}
/// To store state data for SearchCompanies
class SearchCompaniesRefs {
  idController: ui.ScrollController = new ui.ScrollController();
  public itemState: Map<CompanySearchItem, _ItemState> = new Map();
  public noneOption: NoneOptionState = new NoneOptionState();
  public forItem(item: CompanySearchItem): _ItemState {
    let res = this.itemState.get(item);

    if (res == null) {
      res = new _ItemState(this, item);

      this.itemState.set(item, res);
    }

    return res;
  }
}

interface NoneOptionWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: SearchCompaniesRefs;
  _onPressedNone?: _NoneOptionOnTap;
  itemColor: ui.Color;
}

class NoneOptionState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _NoneOptionWithState extends ObservableComponent<NoneOptionWithStateProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: NoneOptionWithStateProps) {
    super(props);

    this.initState();
  }
  public get itemColor(): ui.Color {
    return this.props.itemColor;
  }
  public get noneOption(): NoneOptionState {
    return this.props.d3eState.noneOption;
  }
  public get d3eState(): SearchCompaniesRefs {
    return this.props.d3eState;
  }
  public get _onPressedNone(): _NoneOptionOnTap {
    return this.props._onPressedNone;
  }
  public initState() {
    super.initState();

    this.updateObservable("noneOption", null, this.noneOption);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["itemColor", "noneOption", "noneOption.hover"], this.rebuild);
  }
  public noneOptionOnEnter(event): void {
    return this.noneOption.setHover(true);
  }
  public noneOptionOnExit(event): void {
    return this.noneOption.setHover(false);
  }
  public dispose(): void {
    this.noneOption.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      states: ui.joinStates({ "data-c0": this.noneOption.hover }, {}),
      decoration: this.noneOption.hover
        ? new ui.BoxDecoration({ color: cStyle.c12 })
        : new ui.BoxDecoration({}),
      child: TextView({
        data: " --None-- ",
        style: new ui.TextStyle({ color: this.itemColor }),
        className: "x1ab hc",
      }),
      onEnter: (event) => {
        this.noneOptionOnEnter(event);
      },
      onExit: (event) => {
        this.noneOptionOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onPressedNone(this.d3eState);
      },
      className: "x6c5 hc",
    });
  }
}
function NoneOptionWithState(props: NoneOptionWithStateProps) {
  return React.createElement(_NoneOptionWithState, props);
}

interface OnItemWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ItemState;
  _onSelectListTile?: _OnItemOnTap;
  item: CompanySearchItem;
}

class OnItemState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _OnItemWithState extends ObservableComponent<OnItemWithStateProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: OnItemWithStateProps) {
    super(props);

    this.initState();
  }
  public get item(): CompanySearchItem {
    return this.props.item;
  }
  public get onItem(): OnItemState {
    return this.props.d3eState.onItem;
  }
  public get d3eState(): _ItemState {
    return this.props.d3eState;
  }
  public get _onSelectListTile(): _OnItemOnTap {
    return this.props._onSelectListTile;
  }
  public initState() {
    super.initState();

    this.updateObservable("onItem", null, this.onItem);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("item", this.props.item);

    this.on(
      ["item", "item.logoUrl", "item.name", "onItem", "onItem.hover"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: OnItemWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.item !== this.props.item) {
      this.updateObservable("item", prevProps.item, this.props.item);

      this.fire("item", this);
    }
  }
  public onItemOnEnter(event): void {
    return this.onItem.setHover(true);
  }
  public onItemOnExit(event): void {
    return this.onItem.setHover(false);
  }
  public dispose(): void {
    this.onItem.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      states: ui.joinStates({ "data-c0": this.onItem.hover }, {}),
      decoration: this.onItem.hover
        ? new ui.BoxDecoration({ color: cStyle.c12 })
        : new ui.BoxDecoration({}),
      child: ui.Row({
        children: [
          this.item.logoUrl === null
            ? ui.ClipRRect({
                borderRadius: ui.BorderRadius.circular(10),
                child: ui.AssetImage({
                  path: "images/profile1.png",
                  width: 20,
                  height: 20,
                  fit: ui.BoxFit.fill,
                  className: "x013 hc vc",
                }),
              })
            : ui.ClipRRect({
                borderRadius: ui.BorderRadius.circular(10),
                child: ui.NetworkImage({
                  url: this.item.logoUrl + "?width=20&height=20&inline=true",
                  width: 20,
                  height: 20,
                  fit: ui.BoxFit.fill,
                  className: "xcd7 hc vc",
                }),
              }),
          TextView({ data: this.item.name, className: "x51b", key: "1" }),
        ],
        className: "x52d hc h",
      }),
      onEnter: (event) => {
        this.onItemOnEnter(event);
      },
      onExit: (event) => {
        this.onItemOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onSelectListTile(this.d3eState);
      },
      className: "x6c6b hc h",
    });
  }
}
function OnItemWithState(props: OnItemWithStateProps) {
  return React.createElement(_OnItemWithState, props);
}

class _ItemState {
  parent: SearchCompaniesRefs;
  item: CompanySearchItem;
  onItem: OnItemState = new OnItemState();
  public constructor(parent, item) {
    this.parent = parent;

    this.item = item;
  }
}

class _SearchCompaniesState extends ObservableComponent<SearchCompaniesProps> {
  static defaultProps = {
    value: null,
    itemColor: null,
    items: [],
    onSelectItem: null,
  };
  d3eState: SearchCompaniesRefs = new SearchCompaniesRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchCompaniesProps) {
    super(props);

    this.initState();
  }
  public get value(): CompanySearchItem {
    return this.props.value;
  }
  public get items(): Array<CompanySearchItem> {
    return this.props.items;
  }
  public get itemColor(): ui.Color {
    return this.props.itemColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("value", this.props.value);

    this.subscribeToList(this.items, "items");

    this.updateSyncCollProperty("items", this.props.items);

    this.on(["itemColor", "items", "value"], this.rebuild);
  }
  public componentDidUpdate(prevProps: SearchCompaniesProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.updateObservable("value", prevProps.value, this.props.value);

      this.fire("value", this);
    }

    if (prevProps.items !== this.props.items) {
      this.updateObservableColl("items", prevProps.items, this.props.items);

      this.fire("items", this);
    }

    if (prevProps.itemColor !== this.props.itemColor) {
      this.fire("itemColor", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.ListView({
      shrinkWrap: true,
      controller: this.d3eState.idController,
      children: [
        this.items
          .toList()
          .expand((item) => [
            OnItemWithState({
              d3eState: this.d3eState.forItem(item),
              _onSelectListTile: this.onSelectListTile,
              item: item,
              key: item?.toString(),
            }),
          ]),
        this.value !== null
          ? NoneOptionWithState({
              d3eState: this.d3eState,
              _onPressedNone: this.onPressedNone,
              itemColor: this.itemColor,
            })
          : [],
        this.items.isEmpty
          ? TextView({ data: " No Results Found ", className: "x4f2b hc" })
          : [],
      ],
      className: ui.join(this.props.className, "SearchCompanies xe31 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onSelectListTile = (d3eState: _ItemState): void => {
    this.onSelectItem(d3eState.item);
  };
  public onPressedNone = (d3eState: SearchCompaniesRefs): void => {
    this.onSelectItem(null);
  };
  public get onSelectItem(): _SearchCompaniesOnSelectItem {
    return this.props.onSelectItem;
  }
  public get noneOption() {
    return this.d3eState.noneOption;
  }
}
export default function SearchCompanies(props: SearchCompaniesProps) {
  return React.createElement(
    _SearchCompaniesState,
    { ..._SearchCompaniesState.defaultProps, ...props },
    ListWrapper.fromInput<CompanySearchItem>(props.items, "items")
  );
}
