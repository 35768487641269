export default class ChartTheme {
  public static readonly Fusion = new ChartTheme("Fusion", "Fusion", 0);
  public static readonly Gammel = new ChartTheme("Gammel", "Gammel", 1);
  public static readonly Candy = new ChartTheme("Candy", "Candy", 2);
  public static readonly Ocean = new ChartTheme("Ocean", "Ocean", 3);
  public static readonly Zune = new ChartTheme("Zune", "Zune", 4);
  public static readonly Carbon = new ChartTheme("Carbon", "Carbon", 5);
  public static readonly Umber = new ChartTheme("Umber", "Umber", 6);
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): ChartTheme[] {
    return [
      ChartTheme.Fusion,
      ChartTheme.Gammel,
      ChartTheme.Candy,
      ChartTheme.Ocean,
      ChartTheme.Zune,
      ChartTheme.Carbon,
      ChartTheme.Umber,
    ];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
