import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import RoundedIconButton from "./RoundedIconButton";
import { BuildContext } from "../classes/BuildContext";

type _FailureMessageViewOnClosePressed = () => void;

export interface FailureMessageViewProps extends BaseUIProps {
  key?: string;
  msg?: string;
  onClosePressed?: _FailureMessageViewOnClosePressed;
}

class _FailureMessageViewState extends ObservableComponent<FailureMessageViewProps> {
  static defaultProps = { msg: "", onClosePressed: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: FailureMessageViewProps) {
    super(props);

    this.initState();
  }
  public get msg(): string {
    return this.props.msg;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["msg"], this.rebuild);
  }
  public componentDidUpdate(prevProps: FailureMessageViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.msg !== this.props.msg) {
      this.fire("msg", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.end,
          children: [
            ui.Container({ className: "x0a7 hc h", key: "0" }),
            ui.Row({
              children: [
                TextView({
                  data: this.msg,
                  style: new ui.TextStyle({
                    color: cStyle.c14,
                    fontWeight: ui.FontWeight.bold,
                    fontSize: 14,
                  }),
                  className: "xedb",
                  key: "0",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(0.0, 3.0, 0.0, 0.0, new Map()),
                  child: RoundedIconButton({
                    icon: MaterialIcons.cancel,
                    color: cStyle.c14,
                    onPressed: () => {
                      this.onPressedcloseMessageHandler();
                    },
                  }),
                  key: "1",
                  className: "xb4d",
                }),
              ],
              className: "x64c3",
              key: "1",
            }),
          ],
          className: "x8f6 hc h",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "FailureMessageView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedcloseMessageHandler = (): void => {
    this.onClosePressed();
  };
  public get onClosePressed(): _FailureMessageViewOnClosePressed {
    return this.props.onClosePressed;
  }
}
export default function FailureMessageView(props: FailureMessageViewProps) {
  return React.createElement(_FailureMessageViewState, {
    ..._FailureMessageViewState.defaultProps,
    ...props,
  });
}
