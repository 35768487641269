import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ChartTheme from "../classes/ChartTheme";
import ListWrapper from "../utils/ListWrapper";
import MathExt from "../classes/MathExt";
import DataSet from "../classes/DataSet";
import NumberFormatter from "../classes/NumberFormatter";
import CanvasView from "./CanvasView";
import ChartThemeData from "../classes/ChartThemeData";
import ChartNumberFormat from "../classes/ChartNumberFormat";
import { CanvasViewController } from "./CanvasView";

export interface Doughnut2DChartProps extends BaseUIProps {
  key?: string;
  data: Array<DataSet>;
  numberFormat?: ChartNumberFormat;
  theme?: ChartTheme;
  labelPadding?: ui.EdgeInsets;
  canvasPadding?: ui.EdgeInsets;
  caption?: string;
  _dataHash?: number;
}

class _Doughnut2DChartState extends ObservableComponent<Doughnut2DChartProps> {
  static defaultProps = {
    numberFormat: null,
    theme: ChartTheme.Fusion,
    labelPadding: null,
    canvasPadding: null,
    caption: "",
    data: [],
  };
  canvasViewController: CanvasViewController = new CanvasViewController();
  lastDownAngle: number = 0.0;
  startAngle: number = 0.0;
  public constructor(props: Doughnut2DChartProps) {
    super(props);

    this.initState();
  }
  public get data(): Array<DataSet> {
    return this.props.data;
  }
  public get numberFormat(): ChartNumberFormat {
    return this.props.numberFormat;
  }
  public get theme(): ChartTheme {
    return this.props.theme;
  }
  public get labelPadding(): ui.EdgeInsets {
    return this.props.labelPadding;
  }
  public get canvasPadding(): ui.EdgeInsets {
    return this.props.canvasPadding;
  }
  public get caption(): string {
    return this.props.caption;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.data, "data");

    this.updateSyncCollProperty("data", this.props.data);

    this.updateSyncProperty("numberFormat", this.props.numberFormat);

    this.on(["canvasPadding"], this.rebuild);
  }
  public componentDidUpdate(prevProps: Doughnut2DChartProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.data !== this.props.data) {
      this.updateObservableColl("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }

    if (prevProps.numberFormat !== this.props.numberFormat) {
      this.updateObservable(
        "numberFormat",
        prevProps.numberFormat,
        this.props.numberFormat
      );

      this.fire("numberFormat", this);
    }

    if (prevProps.theme !== this.props.theme) {
      this.fire("theme", this);
    }

    if (prevProps.labelPadding !== this.props.labelPadding) {
      this.fire("labelPadding", this);
    }

    if (prevProps.canvasPadding !== this.props.canvasPadding) {
      this.fire("canvasPadding", this);
    }

    if (prevProps.caption !== this.props.caption) {
      this.fire("caption", this);
    }
  }
  public setLastDownAngle(val: number): void {
    let isValChanged: boolean = this.lastDownAngle !== val;

    if (!isValChanged) {
      return;
    }

    this.lastDownAngle = val;

    this.fire("lastDownAngle", this);
  }
  public setStartAngle(val: number): void {
    let isValChanged: boolean = this.startAngle !== val;

    if (!isValChanged) {
      return;
    }

    this.startAngle = val;

    this.fire("startAngle", this);
  }
  public render(): ReactNode {
    return ui.Container({
      padding: this.canvasPadding,
      child: CanvasView({
        controller: this.canvasViewController,
        onRepaint: () => {
          this.onRepaint();
        },
        d3eRef: ui.LayoutAware((bounds, globalPos) => {
          this.onLayoutChanged(bounds, globalPos);
        }),
        onPointerDown: (event) => {
          this.onPointerDown(event);
        },
        onPointerMove: (event) => {
          this.onPointerMove(event);
        },
      }),
      className: ui.join(this.props.className, "Doughnut2DChart xbc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onRepaint = (): void => {
    this.doRepaint();
  };
  public doRepaint = (): void => {
    let themeData: ChartThemeData = ChartThemeData.getData(this.theme);

    let numFormat: ChartNumberFormat =
      this.numberFormat ?? new ChartNumberFormat();

    let canvas: ui.Canvas = this.canvasViewController.canvas;

    let size: ui.Size = this.canvasViewController.size;

    let defaultTextStyle: ui.TextStyle = this.canvasViewController.textStyle;

    let total: number = this.data.fold(
      0.0,
      (t, ds) => t + ds.data.fold(0.0, (subTotal, dp) => subTotal + dp.value)
    );

    let gapBetweenLabels: number = 1.0;

    let piePadding: number = 20.0;

    let legendWidth: number = 150.0;

    let canvasHeight: number = size.height - piePadding * 2;

    let canvasWidth: number = size.width - piePadding * 2 - legendWidth;

    let circleSize: number = Math.min(canvasHeight, canvasWidth) / 2.0;

    let radius: number = circleSize / 2.0;

    let center: ui.Offset = ui.OffsetExt.getOffset({
      dx: (size.width - legendWidth) / 2.0,
      dy: size.height / 2.0,
    });

    //  Draw caption

    let captionPainter: ui.TextPainter = new ui.TextPainter({
      textDirection: ui.TextDirection.ltr,
      text: new ui.TextSpan({
        text: this.caption,
        style: new ui.TextStyle({
          color: themeData.labelColor,
          fontSize: 16.0,
          fontWeight: ui.FontWeight.bold,
        }),
      }),
    });

    captionPainter.layout(canvas, { minWidth: 0.0, maxWidth: size.width });

    captionPainter.paint(
      canvas,
      ui.OffsetExt.getOffset({
        dx: (size.width - captionPainter.width) / 2,
        dy: 10.0,
      })
    );

    let labelPainters: Array<ui.TextPainter> = [];

    let maxLabelWidth: number = 0.0;

    let maxLabelHeight: number = 0.0;

    for (let i: number = 0; i < this.data.length; i++) {
      let datasetTotal: number = this.data[i].data.fold(
        0.0,
        (subTotal, dp) => subTotal + dp.value
      );

      let percent: number = (datasetTotal * 100.0) / total;

      let formattedPercent: string = NumberFormatter.formatDouble(
        percent,
        numFormat,
        { percent: true }
      );

      let formattedValue: string = NumberFormatter.formatDouble(
        datasetTotal,
        numFormat
      );

      let label: string = formattedValue + " (" + formattedPercent + ")";

      let style: ui.TextStyle = new ui.TextStyle({
        color: themeData.labelColor,
      });

      let textSpan: ui.TextSpan = new ui.TextSpan({
        text: label,
        style: style,
      });

      let textPainter: ui.TextPainter = new ui.TextPainter({
        text: textSpan,
        textDirection: ui.TextDirection.ltr,
      });

      textPainter.layout(canvas, { minWidth: 0.0, maxWidth: size.width / 2 });

      labelPainters.add(textPainter);

      maxLabelWidth = Math.max(maxLabelWidth, textPainter.width);

      maxLabelHeight = Math.max(maxLabelHeight, textPainter.height);
    }

    let connectorWidth: number = 20.0;

    let labelVPadding: number = this.labelPadding?.top ?? 2.0;

    let labelHPadding: number = this.labelPadding?.left ?? 10.0;

    let extraSpaceAfterLabel: number =
      canvasWidth / 2.0 -
      piePadding -
      connectorWidth -
      labelHPadding * 2 -
      maxLabelWidth;

    circleSize += Math.max(extraSpaceAfterLabel, 0.0);

    let adjustedRadius: number = radius + Math.max(extraSpaceAfterLabel, 0.0);

    let connectorPaint: ui.Paint = new ui.Paint();

    connectorPaint.color = themeData.labelColor;

    connectorPaint.style = ui.PaintingStyle.stroke;

    let angle: number = this.startAngle;

    for (let i: number = 0; i < this.data.length; i++) {
      for (let j: number = 0; j < this.data[i].data.length; j++) {
        let share: number = this.data[i].data[j].value / total;

        let sweepAngle: number = share * 2 * MathExt.pi;

        let arcPaint: ui.Paint = new ui.Paint();

        if (this.data[i].color === "" || this.data[i].color === null) {
          arcPaint.color = themeData.dataColor(i);
        } else {
          arcPaint.color = ui.HexColor.fromHexStr(this.data[i].color);

          //  Use the color from DataSet
        }

        arcPaint.style = ui.PaintingStyle.fill;

        let rect: ui.Rect = ui.Rect.fromCircle({
          center: center,
          radius: adjustedRadius,
        });

        let midPointAngle: number = -angle - sweepAngle / 2;

        let x: number = center.dx + adjustedRadius * Math.cos(midPointAngle);

        let y: number = center.dy + adjustedRadius * Math.sin(midPointAngle);

        let x1: number =
          center.dx +
          (adjustedRadius + connectorWidth) * Math.cos(midPointAngle);

        let y1: number =
          center.dy +
          (adjustedRadius + connectorWidth) * Math.sin(midPointAngle);

        let painter: ui.TextPainter = labelPainters[i];

        let midPoint: ui.Offset = ui.OffsetExt.getOffset({ dx: x, dy: y });

        if (midPoint.dx < center.dx) {
          painter.paint(
            canvas,
            ui.OffsetExt.getOffset({
              dx: x1 - painter.width - 10.0,
              dy: y1 - painter.height / 2,
            })
          );

          canvas.drawLine(
            ui.OffsetExt.getOffset({ dx: x1, dy: y1 }),
            ui.OffsetExt.getOffset({ dx: x1 - 5.0, dy: y1 }),
            connectorPaint
          );
        } else {
          painter.paint(
            canvas,
            ui.OffsetExt.getOffset({
              dx: x1 + 10.0,
              dy: y1 - painter.height / 2,
            })
          );

          canvas.drawLine(
            ui.OffsetExt.getOffset({ dx: x1, dy: y1 }),
            ui.OffsetExt.getOffset({ dx: x1 + 5.0, dy: y1 }),
            connectorPaint
          );
        }

        canvas.drawLine(
          ui.OffsetExt.getOffset({ dx: x, dy: y }),
          ui.OffsetExt.getOffset({ dx: x1, dy: y1 }),
          connectorPaint
        );

        canvas.drawLine(
          center,
          ui.OffsetExt.getOffset({ dx: x, dy: y }),
          connectorPaint
        );

        canvas.drawArc(rect, -angle, -sweepAngle, true, arcPaint);

        angle += sweepAngle;
      }
    }

    let innerCirclePaint: ui.Paint = new ui.Paint();

    innerCirclePaint.color = ui.HexColor.fromHexStr("ffffffff");

    innerCirclePaint.style = ui.PaintingStyle.fill;

    canvas.drawCircle(center, adjustedRadius / 2, innerCirclePaint);

    //  Draw legends with 15px left margin

    let legendX: number = size.width - legendWidth + 25;

    //  10px padding from right edge + 15px left margin

    let legendY: number =
      center.dy - (this.data.length * (maxLabelHeight + 5)) / 2;

    for (let i: number = 0; i < this.data.length; i++) {
      let legendPaint: ui.Paint = new ui.Paint();

      if (this.data[i].color === "" || this.data[i].color === null) {
        legendPaint.color = themeData.dataColor(i);
      } else {
        legendPaint.color = ui.HexColor.fromHexStr(this.data[i].color);
      }

      legendPaint.style = ui.PaintingStyle.fill;

      canvas.drawRect(
        ui.Rect.fromLTWH(legendX, legendY, 15.0, 15.0),
        legendPaint
      );

      let legendTextPainter: ui.TextPainter = new ui.TextPainter({
        textDirection: ui.TextDirection.ltr,
        text: new ui.TextSpan({
          text: this.data[i].name,
          style: new ui.TextStyle({
            color: themeData.labelColor,
            fontSize: 12.0,
          }),
        }),
      });

      legendTextPainter.layout(canvas, {
        minWidth: 0.0,
        maxWidth: legendWidth - 30,
      });

      legendTextPainter.paint(
        canvas,
        ui.OffsetExt.getOffset({ dx: legendX + 20, dy: legendY })
      );

      legendY += maxLabelHeight + 5;
    }
  };
  public onLayoutChanged = (bounds: ui.Rect, globalPos: ui.Offset): void => {
    this.doRepaint();
  };
  public onPointerDown = (event: ui.PointerDownEvent): void => {
    let size: ui.Size = this.canvasViewController.size;

    let center: ui.Offset = ui.OffsetExt.getOffset({
      dx: size.width / 2.0,
      dy: size.height / 2.0,
    });

    let location: ui.Offset = event.localPosition;

    this.setLastDownAngle(
      this.startAngle +
        Math.atan2(center.dy - location.dy, center.dx - location.dx)
    );
  };
  public onPointerMove = (event: ui.PointerMoveEvent): void => {
    let size: ui.Size = this.canvasViewController.size;

    let center: ui.Offset = ui.OffsetExt.getOffset({
      dx: size.width / 2.0,
      dy: size.height / 2.0,
    });

    let location: ui.Offset = event.localPosition;

    let newAngle: number = Math.atan2(
      center.dy - location.dy,
      center.dx - location.dx
    );

    this.setStartAngle(this.lastDownAngle - newAngle);

    this.canvasViewController.repaint();
  };
}
export default function Doughnut2DChart(props: Doughnut2DChartProps) {
  return React.createElement(
    _Doughnut2DChartState,
    { ..._Doughnut2DChartState.defaultProps, ...props },
    ListWrapper.fromInput<DataSet>(props.data, "data")
  );
}
