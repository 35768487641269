import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _SubSideMenuButtonOnPressed = () => void;

type _Button11111111OnPressed = (d3eState: SubSideMenuButtonRefs) => void;

export interface SubSideMenuButtonProps extends BaseUIProps {
  key?: string;
  title: string;
  selectedMenu: string;
  onPressed?: _SubSideMenuButtonOnPressed;
}
/// To store state data for SubSideMenuButton
class SubSideMenuButtonRefs {
  public button11111111: Button11111111State = new Button11111111State();
}

interface Button11111111WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: SubSideMenuButtonRefs;
  _onPressedButton?: _Button11111111OnPressed;
  selectedMenu: string;
  title: string;
}

class Button11111111State extends ObjectObservable {
  private _hover: boolean = false;
  private _disable: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button11111111WithState extends ObservableComponent<Button11111111WithStateProps> {
  button11111111FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button11111111WithStateProps) {
    super(props);

    this.initState();
  }
  public get button11111111(): Button11111111State {
    return this.props.d3eState.button11111111;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public get title(): string {
    return this.props.title;
  }
  public get d3eState(): SubSideMenuButtonRefs {
    return this.props.d3eState;
  }
  public get _onPressedButton(): _Button11111111OnPressed {
    return this.props._onPressedButton;
  }
  public initState() {
    super.initState();

    this.updateObservable("button11111111", null, this.button11111111);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["button11111111", "button11111111.hover", "selectedMenu", "title"],
      this.rebuild
    );
  }
  public button11111111OnEnter(event): void {
    return this.button11111111.setHover(true);
  }
  public button11111111OnExit(event): void {
    return this.button11111111.setHover(false);
  }
  public dispose(): void {
    this.button11111111.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: Number.POSITIVE_INFINITY,
      child: Button({
        padding: this.button11111111.hover
          ? ui.EdgeInsets.all(7.0, new Map())
          : this.selectedMenu == this.title
          ? ui.EdgeInsets.all(7.0, new Map())
          : ui.EdgeInsets.symmetric({
              horizontal: 12.0,
              vertical: 7.0,
              transitions: new Map(),
            }),
        decoration: this.button11111111.hover
          ? new ui.BoxDecoration({
              color: new ui.Color(0xff000000),
              borderRadius: ui.BorderRadiusExt.only({
                topLeft: ui.RadiusExt.elliptical({ x: 18, y: 18 }),
                topRight: ui.RadiusExt.elliptical({ x: 0, y: 0 }),
                bottomLeft: ui.RadiusExt.elliptical({ x: 18, y: 18 }),
                bottomRight: ui.RadiusExt.elliptical({ x: 0, y: 0 }),
              }),
            })
          : this.selectedMenu == this.title
          ? new ui.BoxDecoration({
              borderRadius: ui.BorderRadiusExt.only({
                topLeft: ui.RadiusExt.elliptical({ x: 18, y: 18 }),
                topRight: ui.RadiusExt.elliptical({ x: 0, y: 0 }),
                bottomLeft: ui.RadiusExt.elliptical({ x: 18, y: 18 }),
                bottomRight: ui.RadiusExt.elliptical({ x: 0, y: 0 }),
              }),
              color: cStyle.c1,
            })
          : new ui.BoxDecoration({
              borderRadius: ui.BorderRadiusExt.only({
                topLeft: ui.RadiusExt.elliptical({ x: 18, y: 18 }),
                topRight: ui.RadiusExt.elliptical({ x: 0, y: 0 }),
                bottomLeft: ui.RadiusExt.elliptical({ x: 18, y: 18 }),
                bottomRight: ui.RadiusExt.elliptical({ x: 0, y: 0 }),
              }),
              color: new ui.Color(0xff2b2828),
            }),
        disable: this.button11111111.disable,
        onPressed: () => {
          this._onPressedButton(this.d3eState);
        },
        onFocusChange: (val) => {},
        states: ui.joinStates(
          {
            "data-c0": this.button11111111.hover,
            "data-c1": this.selectedMenu == this.title,
          },
          this.props.states
        ),
        child: ui.Row({
          mainAxisSize: ui.MainAxisSize.max,
          children: [
            TextView({
              data: this.title,
              style: new ui.TextStyle({ fontWeight: ui.FontWeight.w500 }),
              className: "xa3ee",
              key: "0",
            }),
          ],
          className: "xc86 hc h",
        }),
        onEnter: (event) => {
          this.button11111111OnEnter(event);
        },
        onExit: (event) => {
          this.button11111111OnExit(event);
        },
      }),
      className: ui.join(this.props.className, "SubSideMenuButton xd70 hc h"),
      states: ui.joinStates(
        {
          "data-c0": this.button11111111.hover,
          "data-c1": this.selectedMenu == this.title,
        },
        this.props.states
      ),
      ...copyBaseUIProps(this.props),
    });
  }
}
function Button11111111WithState(props: Button11111111WithStateProps) {
  return React.createElement(_Button11111111WithState, props);
}

class _SubSideMenuButtonState extends ObservableComponent<SubSideMenuButtonProps> {
  static defaultProps = { title: "", selectedMenu: "", onPressed: null };
  d3eState: SubSideMenuButtonRefs = new SubSideMenuButtonRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SubSideMenuButtonProps) {
    super(props);

    this.initState();
  }
  public get title(): string {
    return this.props.title;
  }
  public get selectedMenu(): string {
    return this.props.selectedMenu;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["selectedMenu", "title"], this.rebuild);
  }
  public componentDidUpdate(prevProps: SubSideMenuButtonProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.title !== this.props.title) {
      this.fire("title", this);
    }

    if (prevProps.selectedMenu !== this.props.selectedMenu) {
      this.fire("selectedMenu", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button11111111WithState({
      d3eState: this.d3eState,
      _onPressedButton: this.onPressedButton,
      selectedMenu: this.selectedMenu,
      title: this.title,
    });
  }
  public onPressedButton = (d3eState: SubSideMenuButtonRefs): void => {
    if (this.onPressed !== null) {
      this.onPressed();

      d3eState.button11111111.setHover(false);
    }
  };
  public get onPressed(): _SubSideMenuButtonOnPressed {
    return this.props.onPressed;
  }
  public get button11111111() {
    return this.d3eState.button11111111;
  }
}
export default function SubSideMenuButton(props: SubSideMenuButtonProps) {
  return React.createElement(_SubSideMenuButtonState, {
    ..._SubSideMenuButtonState.defaultProps,
    ...props,
  });
}
