import ApolloRPCServiceClient from "./ApolloRPCServiceClient";
import FileServiceClient from "./FileServiceClient";
import CallServiceClient from "./CallServiceClient";
import ExportFileUtilsClient from "./ExportFileUtilsClient";
import UniqueCheckerClient from "./UniqueCheckerClient";
import LeadServiceClient from "./LeadServiceClient";

export default class RPCServices {
  private static _uniqueChecker: UniqueCheckerClient;
  private static _fileService: FileServiceClient;
  private static _exportFileUtils: ExportFileUtilsClient;
  private static _callService: CallServiceClient;
  private static _leadService: LeadServiceClient;
  private static _apolloRPCService: ApolloRPCServiceClient;
  public static getUniqueChecker(): UniqueCheckerClient {
    if (RPCServices._uniqueChecker == null) {
      RPCServices._uniqueChecker = new UniqueCheckerClient();
    }

    return RPCServices._uniqueChecker;
  }
  public static getFileService(): FileServiceClient {
    if (RPCServices._fileService == null) {
      RPCServices._fileService = new FileServiceClient();
    }

    return RPCServices._fileService;
  }
  public static getExportFileUtils(): ExportFileUtilsClient {
    if (RPCServices._exportFileUtils == null) {
      RPCServices._exportFileUtils = new ExportFileUtilsClient();
    }

    return RPCServices._exportFileUtils;
  }
  public static getCallService(): CallServiceClient {
    if (RPCServices._callService == null) {
      RPCServices._callService = new CallServiceClient();
    }

    return RPCServices._callService;
  }
  public static getLeadService(): LeadServiceClient {
    if (RPCServices._leadService == null) {
      RPCServices._leadService = new LeadServiceClient();
    }

    return RPCServices._leadService;
  }
  public static getApolloRPCService(): ApolloRPCServiceClient {
    if (RPCServices._apolloRPCService == null) {
      RPCServices._apolloRPCService = new ApolloRPCServiceClient();
    }

    return RPCServices._apolloRPCService;
  }
}
