import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import LeadNeedsAndPainsEditView from "./LeadNeedsAndPainsEditView";
import Lead from "../models/Lead";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import Result from "../classes/Result";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _SaveButtonOnPressed = (d3eState: NeedsCardViewRefs) => void;

type _ModuleNameOnChanged = (text: string, d3eState: NeedsCardViewRefs) => void;

export interface NeedsCardViewProps extends BaseUIProps {
  key?: string;
  lead: Lead;
}
/// To store state data for NeedsCardView
class NeedsCardViewRefs {
  moduleNameController: ui.TextEditingController =
    new ui.TextEditingController();
  moduleNameFocusNode: ui.FocusNode = new ui.FocusNode();
  public saveButton: SaveButtonState = new SaveButtonState();
}

interface SaveButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: NeedsCardViewRefs;
  _saveButtonHandler?: _SaveButtonOnPressed;
}

class SaveButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _SaveButtonWithState extends ObservableComponent<SaveButtonWithStateProps> {
  saveButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SaveButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get saveButton(): SaveButtonState {
    return this.props.d3eState.saveButton;
  }
  public get d3eState(): NeedsCardViewRefs {
    return this.props.d3eState;
  }
  public get _saveButtonHandler(): _SaveButtonOnPressed {
    return this.props._saveButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("saveButton", null, this.saveButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["saveButton", "saveButton.", "saveButton.hover"], this.rebuild);
  }
  public saveButtonOnEnter(event): void {
    return this.saveButton.setHover(true);
  }
  public saveButtonOnExit(event): void {
    return this.saveButton.setHover(false);
  }
  public dispose(): void {
    this.saveButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.saveButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.saveButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.saveButton.disable,
        onPressed: () => {
          this._saveButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Add" }),
        onEnter: (event) => {
          this.saveButtonOnEnter(event);
        },
        onExit: (event) => {
          this.saveButtonOnExit(event);
        },
      }),
      className: "x86ab",
    });
  }
}
function SaveButtonWithState(props: SaveButtonWithStateProps) {
  return React.createElement(_SaveButtonWithState, props);
}

class _NeedsCardViewState extends ObservableComponent<NeedsCardViewProps> {
  static defaultProps = { lead: null };
  d3eState: NeedsCardViewRefs = new NeedsCardViewRefs();
  needsAndPainsItem: string = "";
  isNote: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: NeedsCardViewProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.on(
      ["isNote", "lead", "lead.needs", "needsAndPainsItem"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: NeedsCardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }
  }
  public setNeedsAndPainsItem(val: string): void {
    let isValChanged: boolean = this.needsAndPainsItem !== val;

    if (!isValChanged) {
      return;
    }

    this.needsAndPainsItem = val;

    this.fire("needsAndPainsItem", this);
  }
  public setIsNote(val: boolean): void {
    let isValChanged: boolean = this.isNote !== val;

    if (!isValChanged) {
      return;
    }

    this.isNote = val;

    this.fire("isNote", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      mainAxisAlignment: ui.MainAxisAlignment.start,
      children: [
        ui.Row({
          children: [
            IconView({
              size: 18,
              icon: MaterialIcons.list,
              color: cStyle.c1,
              className: "xd46",
              key: "0",
            }),
            TextView({
              data: "Needs",
              style: new ui.TextStyle({ fontWeight: ui.FontWeight.bold }),
              className: "x7ef",
              key: "1",
            }),
          ],
          className: "xa20",
          key: "0",
        }),
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            this.lead.needs.expand((need) => [
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 5.0,
                  transitions: new Map(),
                }),
                child: LeadNeedsAndPainsEditView({
                  needsAndPainsItem: need,
                  needItem: true,
                  lead: this.lead,
                }),
                key: need?.toString(),
                className: "xfc4 h",
              }),
            ]),
            ui.InputField({
              dense: true,
              inActiveColor: cStyle.c17,
              value: this.needsAndPainsItem,
              activeColor: cStyle.c1,
              padding: ui.EdgeInsets.fromLTRB(5.0, 0.0, 0.0, 0.0, new Map()),
              maxLines: 5,
              minLines: 3,
              keyboardType: ui.TextInputType.multiline,
              placeHolder: "Add Needs here",
              controller: this.d3eState.moduleNameController,
              onChanged: (text) => {
                this.moduleNameonChanged(text, this.d3eState);
              },
              onFocusChange: (val) => {},
              focusNode: this.d3eState.moduleNameFocusNode,
              className: "x0d8b vc h",
              key: "1",
            }),
            this.isNote
              ? TextView({
                  data: "Need is required",
                  style: new ui.TextStyle({ color: cStyle.c10 }),
                  className: "x10f",
                })
              : [],
            ui.Row({
              children: [
                SaveButtonWithState({
                  d3eState: this.d3eState,
                  _saveButtonHandler: this.saveButtonHandler,
                  key: "0",
                }),
              ],
              className: "xd68",
              key: "3",
            }),
          ],
          className: "xb10 vc h v",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "NeedsCardView x319 vc h v"),
      ...copyBaseUIProps(this.props),
    });
  }
  public saveButtonHandler = async (
    d3eState: NeedsCardViewRefs
  ): Promise<void> => {
    if (this.needsAndPainsItem === "") {
      this.setIsNote(true);

      return;
    }

    this.lead.needs.add(this.needsAndPainsItem);

    let result: Result<Lead> = await this.lead.save();

    this.setNeedsAndPainsItem("");

    d3eState.moduleNameController.text = "";
  };
  public moduleNameonChanged = (
    val: string,
    d3eState: NeedsCardViewRefs
  ): void => {
    this.setNeedsAndPainsItem(val);
  };
  public get saveButton() {
    return this.d3eState.saveButton;
  }
}
export default function NeedsCardView(props: NeedsCardViewProps) {
  return React.createElement(_NeedsCardViewState, {
    ..._NeedsCardViewState.defaultProps,
    ...props,
  });
}
