import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import CalenderView from "./CalenderView";
import PopupTargetController from "./PopupTargetController";
import D3EDate from "../classes/D3EDate";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import DateTime from "../core/DateTime";
import CalenderUtils from "../classes/CalenderUtils";
import IconView from "./IconView";
import DateFormat from "../classes/DateFormat";
import { StyleThemeData } from "./ThemeWrapper";

type _DateFieldOnChanged = (date: D3EDate) => void;

type _DateFieldOnFormatError = (error: string) => void;

type _DateButtonOnPressed = (d3eState: DateFieldRefs) => void;

export interface DateFieldProps extends BaseUIProps {
  key?: string;
  value: D3EDate;
  placeHolder?: string;
  format?: string;
  activeColor?: ui.Color;
  cornerRadius?: number;
  inActiveColor?: ui.Color;
  padding?: ui.EdgeInsets;
  disable?: boolean;
  selectedColor?: ui.Color;
  hoverColor?: ui.Color;
  selectedTextColor?: ui.Color;
  hoverTextColor?: ui.Color;
  backgroundColor?: ui.Color;
  onChanged?: _DateFieldOnChanged;
  onFormatError?: _DateFieldOnFormatError;
}
/// To store state data for DateField
class DateFieldRefs {
  public dateButton: DateButtonState = new DateButtonState();
  dateInputController: ui.TextEditingController =
    new ui.TextEditingController();
}

interface DateButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: DateFieldRefs;
  _onIconPressed?: _DateButtonOnPressed;
  dateButtonPopupTargetController: PopupTargetController;
}

class DateButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _DateButtonWithState extends ObservableComponent<DateButtonWithStateProps> {
  dateButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: DateButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get dateButton(): DateButtonState {
    return this.props.d3eState.dateButton;
  }
  public get d3eState(): DateFieldRefs {
    return this.props.d3eState;
  }
  public get _onIconPressed(): _DateButtonOnPressed {
    return this.props._onIconPressed;
  }
  public get dateButtonPopupTargetController(): PopupTargetController {
    return this.props.dateButtonPopupTargetController;
  }
  public initState() {
    super.initState();

    this.updateObservable("dateButton", null, this.dateButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["dateButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    return Button({
      disable: this.dateButton.disable,
      onPressed: () => {
        this._onIconPressed(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        icon: MaterialIcons.edit_calendar,
        size: 18,
        className: "x9a8",
      }),
      d3eRef: this.dateButtonPopupTargetController.handleRef,
    });
  }
}
function DateButtonWithState(props: DateButtonWithStateProps) {
  return React.createElement(_DateButtonWithState, props);
}

class _DateFieldState extends ObservableComponent<DateFieldProps> {
  static defaultProps = {
    value: null,
    placeHolder: "",
    format: "yyyy-MM-dd",
    activeColor: null,
    cornerRadius: 0.0,
    inActiveColor: null,
    padding: null,
    disable: false,
    selectedColor: null,
    hoverColor: null,
    selectedTextColor: null,
    hoverTextColor: null,
    backgroundColor: null,
    onChanged: null,
    onFormatError: null,
  };
  d3eState: DateFieldRefs = new DateFieldRefs();
  focusNode: ui.FocusNode = null;
  focused: boolean = false;
  dateAsString: string = "";
  datePopupPopup: Popup;
  public dateButtonPopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: DateFieldProps) {
    super(props);

    this.initState();
  }
  public get value(): D3EDate {
    return this.props.value;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get format(): string {
    return this.props.format;
  }
  public get activeColor(): ui.Color {
    return this.props.activeColor;
  }
  public get cornerRadius(): number {
    return this.props.cornerRadius;
  }
  public get inActiveColor(): ui.Color {
    return this.props.inActiveColor;
  }
  public get padding(): ui.EdgeInsets {
    return this.props.padding;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get selectedColor(): ui.Color {
    return this.props.selectedColor;
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get selectedTextColor(): ui.Color {
    return this.props.selectedTextColor;
  }
  public get hoverTextColor(): ui.Color {
    return this.props.hoverTextColor;
  }
  public get backgroundColor(): ui.Color {
    return this.props.backgroundColor;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.on(["format", "value"], this.computeDateAsString);

    this.computeDateAsString();

    this.on(
      [
        "activeColor",
        "backgroundColor",
        "cornerRadius",
        "dateAsString",
        "disable",
        "focusNode",
        "focused",
        "inActiveColor",
        "padding",
        "placeHolder",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: DateFieldProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.format !== this.props.format) {
      this.fire("format", this);
    }

    if (prevProps.activeColor !== this.props.activeColor) {
      this.fire("activeColor", this);
    }

    if (prevProps.cornerRadius !== this.props.cornerRadius) {
      this.fire("cornerRadius", this);
    }

    if (prevProps.inActiveColor !== this.props.inActiveColor) {
      this.fire("inActiveColor", this);
    }

    if (prevProps.padding !== this.props.padding) {
      this.fire("padding", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.selectedColor !== this.props.selectedColor) {
      this.fire("selectedColor", this);
    }

    if (prevProps.hoverColor !== this.props.hoverColor) {
      this.fire("hoverColor", this);
    }

    if (prevProps.selectedTextColor !== this.props.selectedTextColor) {
      this.fire("selectedTextColor", this);
    }

    if (prevProps.hoverTextColor !== this.props.hoverTextColor) {
      this.fire("hoverTextColor", this);
    }

    if (prevProps.backgroundColor !== this.props.backgroundColor) {
      this.fire("backgroundColor", this);
    }
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setFocused(val: boolean): void {
    let isValChanged: boolean = this.focused !== val;

    if (!isValChanged) {
      return;
    }

    this.focused = val;

    this.fire("focused", this);
  }
  public setDateAsString(val: string): void {
    let isValChanged: boolean = this.dateAsString !== val;

    if (!isValChanged) {
      return;
    }

    this.dateAsString = val;

    this.fire("dateAsString", this);
  }
  public computeDateAsString = (): void => {
    try {
      this.setDateAsString(
        CalenderUtils.getFormatedDate(this.value, this.format)
      );
    } catch (exception) {
      console.log(
        " exception in computeDateAsString : " + exception.toString()
      );

      this.setDateAsString("");
    }
  };
  public render(): ReactNode {
    return ui.Container({
      decoration: new ui.BoxDecoration({
        border: ui.Border.all({
          color:
            this.activeColor !== null && this.focused
              ? this.activeColor
              : this.inActiveColor !== null && !this.focused
              ? this.inActiveColor
              : this.focused
              ? ui.HexColor.fromHexInt(0xff14acff)
              : ui.HexColor.fromHexInt(0xffbfbfbf),
          width: 1.0,
        }),
        borderRadius: ui.BorderRadiusExt.only({
          topLeft: ui.RadiusExt.elliptical({
            x: this.cornerRadius,
            y: this.cornerRadius,
          }),
          topRight: ui.RadiusExt.elliptical({
            x: this.cornerRadius,
            y: this.cornerRadius,
          }),
          bottomLeft: ui.RadiusExt.elliptical({
            x: this.cornerRadius,
            y: this.cornerRadius,
          }),
          bottomRight: ui.RadiusExt.elliptical({
            x: this.cornerRadius,
            y: this.cornerRadius,
          }),
        }),
        color: this.backgroundColor,
      }),
      child: ui.Container({
        padding: this.padding,
        width: Number.infinity,
        child: ui.Row({
          children: [
            ui.InputField({
              activeColor: new ui.Color(0x0),
              inActiveColor: new ui.Color(0x0),
              value: this.dateAsString,
              placeHolder: this.placeHolder,
              focusNode: this.focusNode,
              disable: this.disable,
              inputFormatter: "^[0-9/-]+$",
              controller: this.d3eState.dateInputController,
              onEditingComplete: () => {
                this.onDateEditingComplete(this.d3eState);
              },
              onFocusChange: (val) => {},
              key: this.dateAsString,
              className: "xe42 hc h",
            }),
            DateButtonWithState({
              d3eState: this.d3eState,
              _onIconPressed: this.onIconPressed,
              dateButtonPopupTargetController:
                this.dateButtonPopupTargetController,
              key: "1",
            }),
          ],
        }),
        className: "hc",
      }),
      className: ui.join(this.props.className, "DateField xf48"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.focusHandler);
  };
  public focusHandler = (): void => {
    this.setFocused(this.focusNode.hasFocus);
  };
  public onIconPressed = (d3eState: DateFieldRefs): void => {
    if (!this.disable) {
      this.showDatePopup({ autoClose: true });
    }
  };
  public onDateSelected = (date: D3EDate): void => {
    this.setDateAsString(CalenderUtils.getFormatedDate(date, this.format));

    this.hideDatePopup();

    this.onChanged(date);
  };
  public onDateEditingComplete = (d3eState: DateFieldRefs): void => {
    if (this.dateAsString !== d3eState.dateInputController.text) {
      let datetime: DateTime;

      try {
        datetime = new DateFormat(this.format).parse(
          d3eState.dateInputController.text
        );
      } catch (e) {
        datetime = null;
      }

      if (datetime !== null && this.onChanged !== null) {
        this.onChanged(D3EDate.of(datetime.year, datetime.month, datetime.day));
      }

      if (datetime === null && this.onFormatError !== null) {
        let expFormat: string = CalenderUtils.getFormatedDate(
          D3EDate.now(),
          this.format
        );

        this.onFormatError(
          "Please enter the date in the format: " +
            this.format +
            " (Ex: " +
            expFormat +
            ") only."
        );
      }
    }
  };
  public get onChanged(): _DateFieldOnChanged {
    return this.props.onChanged;
  }
  public get onFormatError(): _DateFieldOnFormatError {
    return this.props.onFormatError;
  }
  public dispose(): void {
    this.datePopupPopup?.dispose();

    super.dispose();
  }
  public showDatePopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.datePopupPopup?.dispose();

    /*
TODO ThemeWrapper.of(this.context);
*/

    let cStyle = StyleThemeData.current;

    let target: ui.Rect = this.dateButtonPopupTargetController.getTarget(
      this.context
    );

    this.datePopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        decoration: new ui.BoxDecoration({
          border: ui.Border.all({ width: 1.0, color: cStyle.c12 }),
        }),
        height: 280,
        width: 400,
        child: CalenderView({
          input: this.value,
          selectedColor: this.selectedColor,
          hoverColor: this.hoverColor,
          selectedTextColor: this.selectedTextColor,
          hoverTextColor: this.hoverTextColor,
          onDateSelected: (date) => {
            this.onDateSelected(date);
          },
        }),
        className: "x3fe hc vc",
      }),
      target: target,
    });

    this.datePopupPopup.showPopup(this.context);
  }
  public hideDatePopup(): void {
    this.datePopupPopup?.dispose();
  }
  public get dateButton() {
    return this.d3eState.dateButton;
  }
}
export default function DateField(props: DateFieldProps) {
  return React.createElement(_DateFieldState, {
    ..._DateFieldState.defaultProps,
    ...props,
  });
}
