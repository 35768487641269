import DBObject from "../utils/DBObject";

export default class CompanySearchItem extends DBObject {
  private _id: number = DBObject.nextStructId();
  private static readonly _LOGOURL: number = 0;
  private static readonly _NAME: number = 1;
  private static readonly _ORGID: number = 2;
  private _name: string = "";
  private _logoUrl: string = "";
  private _orgID: string = "";
  public constructor(
    d3eParams?: Partial<{ logoUrl: string; name: string; orgID: string }>
  ) {
    super();

    if (d3eParams?.logoUrl) {
      this.setLogoUrl(d3eParams?.logoUrl);
    }

    if (d3eParams?.name) {
      this.setName(d3eParams?.name);
    }

    if (d3eParams?.orgID) {
      this.setOrgID(d3eParams?.orgID);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "CompanySearchItem";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(CompanySearchItem._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get logoUrl(): string {
    return this._logoUrl;
  }
  public setLogoUrl(val: string): void {
    let isValChanged: boolean = this._logoUrl !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(CompanySearchItem._LOGOURL, this._logoUrl);

    this._logoUrl = val;

    this.fire("logoUrl", this);
  }
  public get orgID(): string {
    return this._orgID;
  }
  public setOrgID(val: string): void {
    let isValChanged: boolean = this._orgID !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(CompanySearchItem._ORGID, this._orgID);

    this._orgID = val;

    this.fire("orgID", this);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case CompanySearchItem._NAME: {
        this.setName(value as string);
        break;
      }

      case CompanySearchItem._LOGOURL: {
        this.setLogoUrl(value as string);
        break;
      }

      case CompanySearchItem._ORGID: {
        this.setOrgID(value as string);
        break;
      }
    }
  }
  public get(field: number): any {
    switch (field) {
      case CompanySearchItem._NAME: {
        return this._name;
      }

      case CompanySearchItem._LOGOURL: {
        return this._logoUrl;
      }

      case CompanySearchItem._ORGID: {
        return this._orgID;
      }
      default: {
        return null;
      }
    }
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof CompanySearchItem &&
        this._name === other._name &&
        this._logoUrl === other._logoUrl &&
        this._orgID === other._orgID)
    );
  }
  public get hashCode(): number {
    return (
      (this._name?.hashCode ?? 0) +
      (this._logoUrl?.hashCode ?? 0) +
      (this._orgID?.hashCode ?? 0)
    );
  }
}
