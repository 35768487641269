import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _TabButtonOnClick = (selectedTabItem: string) => void;

type _ButtonOnPressed = (d3eState: TabButtonRefs) => void;

export interface TabButtonProps extends BaseUIProps {
  key?: string;
  title: string;
  selectedTab: string;
  onClick?: _TabButtonOnClick;
}
/// To store state data for TabButton
class TabButtonRefs {
  public button: ButtonState = new ButtonState();
}

interface ButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: TabButtonRefs;
  _onPressedButton?: _ButtonOnPressed;
  selectedTab: string;
  title: string;
}

class ButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ButtonWithState extends ObservableComponent<ButtonWithStateProps> {
  buttonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get button(): ButtonState {
    return this.props.d3eState.button;
  }
  public get selectedTab(): string {
    return this.props.selectedTab;
  }
  public get title(): string {
    return this.props.title;
  }
  public get d3eState(): TabButtonRefs {
    return this.props.d3eState;
  }
  public get _onPressedButton(): _ButtonOnPressed {
    return this.props._onPressedButton;
  }
  public initState() {
    super.initState();

    this.updateObservable("button", null, this.button);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button", "selectedTab", "title"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      constraints: new ui.BoxConstraints({ minHeight: 40 }),
      child: Button({
        padding:
          this.selectedTab == this.title
            ? ui.EdgeInsets.symmetric({
                horizontal: 12.0,
                vertical: 8.0,
                transitions: new Map(),
              })
            : ui.EdgeInsets.symmetric({
                horizontal: 12.0,
                vertical: 8.0,
                transitions: new Map(),
              }),
        disable: this.button.disable,
        onPressed: () => {
          this._onPressedButton(this.d3eState);
        },
        onFocusChange: (val) => {},
        states: ui.joinStates(
          { "data-c0": this.selectedTab == this.title },
          {}
        ),
        child: TextView({ data: this.title }),
      }),
      className: "xdca",
      states: ui.joinStates({ "data-c0": this.selectedTab == this.title }, {}),
    });
  }
}
function ButtonWithState(props: ButtonWithStateProps) {
  return React.createElement(_ButtonWithState, props);
}

class _TabButtonState extends ObservableComponent<TabButtonProps> {
  static defaultProps = { title: "", selectedTab: "", onClick: null };
  d3eState: TabButtonRefs = new TabButtonRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: TabButtonProps) {
    super(props);

    this.initState();
  }
  public get title(): string {
    return this.props.title;
  }
  public get selectedTab(): string {
    return this.props.selectedTab;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["selectedTab", "title"], this.rebuild);
  }
  public componentDidUpdate(prevProps: TabButtonProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.title !== this.props.title) {
      this.fire("title", this);
    }

    if (prevProps.selectedTab !== this.props.selectedTab) {
      this.fire("selectedTab", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ButtonWithState({
          d3eState: this.d3eState,
          _onPressedButton: this.onPressedButton,
          selectedTab: this.selectedTab,
          title: this.title,
          key: "0",
        }),
        this.selectedTab == this.title
          ? ui.Container({ className: "xe662 hc vc" })
          : ui.Container({ className: "xe6625 hc vc" }),
      ],
      className: ui.join(this.props.className, "TabButton"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedButton = (d3eState: TabButtonRefs): void => {
    if (this.onClick !== null) {
      this.onClick(this.selectedTab);
    }
  };
  public get onClick(): _TabButtonOnClick {
    return this.props.onClick;
  }
  public get button() {
    return this.d3eState.button;
  }
}
export default function TabButton(props: TabButtonProps) {
  return React.createElement(_TabButtonState, {
    ..._TabButtonState.defaultProps,
    ...props,
  });
}
