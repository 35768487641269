import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

export interface DashboardCardWidgetProps extends BaseUIProps {
  key?: string;
  bgcolor?: ui.Color;
  title?: string;
  statusCount?: number;
}

class _DashboardCardWidgetState extends ObservableComponent<DashboardCardWidgetProps> {
  static defaultProps = { bgcolor: null, title: "", statusCount: 0 };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DashboardCardWidgetProps) {
    super(props);

    this.initState();
  }
  public get bgcolor(): ui.Color {
    return this.props.bgcolor;
  }
  public get title(): string {
    return this.props.title;
  }
  public get statusCount(): number {
    return this.props.statusCount;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["bgcolor", "statusCount", "title"], this.rebuild);
  }
  public componentDidUpdate(prevProps: DashboardCardWidgetProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.bgcolor !== this.props.bgcolor) {
      this.fire("bgcolor", this);
    }

    if (prevProps.title !== this.props.title) {
      this.fire("title", this);
    }

    if (prevProps.statusCount !== this.props.statusCount) {
      this.fire("statusCount", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Container({
          decoration: new ui.BoxDecoration({
            color: this.bgcolor,
            border: ui.Border.all({
              color: new ui.Color(0xff0000),
              width: 0.0,
            }),
            borderRadius: ui.BorderRadius.circular(5.0),
          }),
          alignment: ui.Alignment.topLeft,
          child: ui.Column({
            crossAxisAlignment: ui.CrossAxisAlignment.start,
            children: [
              TextView({
                data: this.title,
                style: new ui.TextStyle({
                  color: cStyle.c14,
                  fontSize: 16,
                  fontWeight: ui.FontWeight.w500,
                }),
                className: "xa89 hc",
                key: "0",
              }),
              TextView({
                data: this.statusCount.toString() + " Pending",
                style: new ui.TextStyle({
                  color: cStyle.c14,
                  fontSize: 20,
                  fontWeight: ui.FontWeight.w600,
                }),
                className: "xa5f hc",
                key: "1",
              }),
            ],
            className: "hc",
          }),
          className: "xc82 hc",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "DashboardCardWidget hc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function DashboardCardWidget(props: DashboardCardWidgetProps) {
  return React.createElement(_DashboardCardWidgetState, {
    ..._DashboardCardWidgetState.defaultProps,
    ...props,
  });
}
