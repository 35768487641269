import DBObject from "../utils/DBObject";

export default class IndustryResultItem extends DBObject {
  private _id: number = DBObject.nextStructId();
  private static readonly _KEYID: number = 0;
  private static readonly _NAME: number = 1;
  private _name: string = "";
  private _keyID: string = "";
  public constructor(d3eParams?: Partial<{ keyID: string; name: string }>) {
    super();

    if (d3eParams?.keyID) {
      this.setKeyID(d3eParams?.keyID);
    }

    if (d3eParams?.name) {
      this.setName(d3eParams?.name);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "IndustryResultItem";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(IndustryResultItem._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get keyID(): string {
    return this._keyID;
  }
  public setKeyID(val: string): void {
    let isValChanged: boolean = this._keyID !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(IndustryResultItem._KEYID, this._keyID);

    this._keyID = val;

    this.fire("keyID", this);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case IndustryResultItem._NAME: {
        this.setName(value as string);
        break;
      }

      case IndustryResultItem._KEYID: {
        this.setKeyID(value as string);
        break;
      }
    }
  }
  public get(field: number): any {
    switch (field) {
      case IndustryResultItem._NAME: {
        return this._name;
      }

      case IndustryResultItem._KEYID: {
        return this._keyID;
      }
      default: {
        return null;
      }
    }
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof IndustryResultItem &&
        this._name === other._name &&
        this._keyID === other._keyID)
    );
  }
  public get hashCode(): number {
    return (this._name?.hashCode ?? 0) + (this._keyID?.hashCode ?? 0);
  }
}
