import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import SuccessMessage from "../classes/SuccessMessage";
import TextValueField from "./TextValueField";
import EventBus from "../utils/EventBus";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import PhoneNumberDetails from "../classes/PhoneNumberDetails";
import RPCServices from "../rpc/RPCServices";
import PopupWrapperView from "./PopupWrapperView";
import { BuildContext } from "../classes/BuildContext";

type _CancelButtonOnPressed = (d3eState: TwiloWarningViewRefs) => void;

type _UserButtonOnPressed = (d3eState: TwiloWarningViewRefs) => void;

export interface TwiloWarningViewProps extends BaseUIProps {
  key?: string;
  obj: PhoneNumberDetails;
}
/// To store state data for TwiloWarningView
class TwiloWarningViewRefs {
  public cancelButton: CancelButtonState = new CancelButtonState();
  public userButton: UserButtonState = new UserButtonState();
}

interface UserButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: TwiloWarningViewRefs;
  _onAddUserHandler?: _UserButtonOnPressed;
}

class UserButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _UserButtonWithState extends ObservableComponent<UserButtonWithStateProps> {
  userButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: UserButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get userButton(): UserButtonState {
    return this.props.d3eState.userButton;
  }
  public get d3eState(): TwiloWarningViewRefs {
    return this.props.d3eState;
  }
  public get _onAddUserHandler(): _UserButtonOnPressed {
    return this.props._onAddUserHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("userButton", null, this.userButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["userButton", "userButton.", "userButton.hover"], this.rebuild);
  }
  public userButtonOnEnter(event): void {
    return this.userButton.setHover(true);
  }
  public userButtonOnExit(event): void {
    return this.userButton.setHover(false);
  }
  public dispose(): void {
    this.userButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.userButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.userButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.userButton.disable,
        onPressed: () => {
          this._onAddUserHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Confirm" }),
        onEnter: (event) => {
          this.userButtonOnEnter(event);
        },
        onExit: (event) => {
          this.userButtonOnExit(event);
        },
      }),
      className: "x27e",
    });
  }
}
function UserButtonWithState(props: UserButtonWithStateProps) {
  return React.createElement(_UserButtonWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: TwiloWarningViewRefs;
  _cancelButtonHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): TwiloWarningViewRefs {
    return this.props.d3eState;
  }
  public get _cancelButtonHandler(): _CancelButtonOnPressed {
    return this.props._cancelButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._cancelButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Cancel" }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "x093",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

class _TwiloWarningViewState extends ObservableComponent<TwiloWarningViewProps> {
  static defaultProps = { obj: null };
  d3eState: TwiloWarningViewRefs = new TwiloWarningViewRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: TwiloWarningViewProps) {
    super(props);

    this.initState();
  }
  public get obj(): PhoneNumberDetails {
    return this.props.obj;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("obj", this.props.obj);

    this.on(
      [
        "obj",
        "obj.areaCode",
        "obj.country",
        "obj.countryCode",
        "obj.locality",
        "obj.monthlyPrice",
        "obj.phoneNumber",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: TwiloWarningViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.obj !== this.props.obj) {
      this.updateObservable("obj", prevProps.obj, this.props.obj);

      this.fire("obj", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title: "Do you want to buy Twilio number?",
      content: ui.Column({
        children: [
          TextValueField({
            label: "Twilio Number",
            value: this.obj.phoneNumber,
            className: "x6e8 hc h",
            key: "0",
          }),
          TextValueField({
            label: "Price",
            value: "$ " + this.obj.monthlyPrice,
            className: "xc69 hc h",
            key: "1",
          }),
          TextValueField({
            label: "Country",
            value: this.obj.country,
            className: "x9644 hc h",
            key: "2",
          }),
          TextValueField({
            label: "Locality",
            value: this.obj.locality,
            className: "x48d7 hc h",
            key: "3",
          }),
          TextValueField({
            label: "Country Code",
            value: this.obj.countryCode,
            className: "x44b hc h",
            key: "4",
          }),
          TextValueField({
            label: "Area Code",
            value: this.obj.areaCode,
            className: "x5c10 hc h",
            key: "5",
          }),
        ],
        className: "x662 hc h",
      }),
      buttons: [
        CancelButtonWithState({
          d3eState: this.d3eState,
          _cancelButtonHandler: this.cancelButtonHandler,
          key: "0",
        }),
        UserButtonWithState({
          d3eState: this.d3eState,
          _onAddUserHandler: this.onAddUserHandler,
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "TwiloWarningView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public cancelButtonHandler = (d3eState: TwiloWarningViewRefs): void => {
    this.navigator.close();
  };
  public onAddUserHandler = async (
    d3eState: TwiloWarningViewRefs
  ): Promise<void> => {
    let buyNumber: boolean = await RPCServices.getCallService().buyNumber(
      this.obj.phoneNumber
    );

    if (buyNumber) {
      EventBus.get().fire(
        new SuccessMessage({ message: "Number bought successfully" })
      );

      this.navigator.close();
    }
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
  public get userButton() {
    return this.d3eState.userButton;
  }
}
export default function TwiloWarningView(props: TwiloWarningViewProps) {
  return React.createElement(_TwiloWarningViewState, {
    ..._TwiloWarningViewState.defaultProps,
    ...props,
  });
}
