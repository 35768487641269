import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import MaterialIcons from "../icons/MaterialIcons";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";
import ToolTipWrapper from "./ToolTipWrapper";

type _AddNoteButtonWidgetOnAddNote = (isAdded: boolean) => void;

type _AddNoteButtonWidgetOnRemoveNote = (isAdded: boolean) => void;

type _AddNoteButtonOnPressed = (d3eState: AddNoteButtonWidgetRefs) => void;

type _RemoveNoteButtonOnPressed = (d3eState: AddNoteButtonWidgetRefs) => void;

export interface AddNoteButtonWidgetProps extends BaseUIProps {
  key?: string;
  isAdded: boolean;
  onAddNote?: _AddNoteButtonWidgetOnAddNote;
  onRemoveNote?: _AddNoteButtonWidgetOnRemoveNote;
}
/// To store state data for AddNoteButtonWidget
class AddNoteButtonWidgetRefs {
  public addNoteButton: AddNoteButtonState = new AddNoteButtonState();
  public removeNoteButton: RemoveNoteButtonState = new RemoveNoteButtonState();
}

interface RemoveNoteButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AddNoteButtonWidgetRefs;
  _onRemoveNoteButtonPressed?: _RemoveNoteButtonOnPressed;
}

class RemoveNoteButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _RemoveNoteButtonWithState extends ObservableComponent<RemoveNoteButtonWithStateProps> {
  removeNoteButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RemoveNoteButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get removeNoteButton(): RemoveNoteButtonState {
    return this.props.d3eState.removeNoteButton;
  }
  public get d3eState(): AddNoteButtonWidgetRefs {
    return this.props.d3eState;
  }
  public get _onRemoveNoteButtonPressed(): _RemoveNoteButtonOnPressed {
    return this.props._onRemoveNoteButtonPressed;
  }
  public initState() {
    super.initState();

    this.updateObservable("removeNoteButton", null, this.removeNoteButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["removeNoteButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.removeNoteButton.disable,
      onPressed: () => {
        this._onRemoveNoteButtonPressed(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        size: 24,
        icon: MaterialIcons.remove_circle_outline,
        color: cStyle.c1,
        className: "x5f",
      }),
    });
  }
}
function RemoveNoteButtonWithState(props: RemoveNoteButtonWithStateProps) {
  return React.createElement(_RemoveNoteButtonWithState, props);
}

interface AddNoteButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AddNoteButtonWidgetRefs;
  _onAddNoteButtonPressed?: _AddNoteButtonOnPressed;
}

class AddNoteButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _AddNoteButtonWithState extends ObservableComponent<AddNoteButtonWithStateProps> {
  addNoteButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AddNoteButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get addNoteButton(): AddNoteButtonState {
    return this.props.d3eState.addNoteButton;
  }
  public get d3eState(): AddNoteButtonWidgetRefs {
    return this.props.d3eState;
  }
  public get _onAddNoteButtonPressed(): _AddNoteButtonOnPressed {
    return this.props._onAddNoteButtonPressed;
  }
  public initState() {
    super.initState();

    this.updateObservable("addNoteButton", null, this.addNoteButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["addNoteButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.addNoteButton.disable,
      onPressed: () => {
        this._onAddNoteButtonPressed(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        size: 24,
        icon: MaterialIcons.control_point,
        color: cStyle.c1,
        className: "xd0",
      }),
    });
  }
}
function AddNoteButtonWithState(props: AddNoteButtonWithStateProps) {
  return React.createElement(_AddNoteButtonWithState, props);
}

class _AddNoteButtonWidgetState extends ObservableComponent<AddNoteButtonWidgetProps> {
  static defaultProps = { isAdded: false, onAddNote: null, onRemoveNote: null };
  d3eState: AddNoteButtonWidgetRefs = new AddNoteButtonWidgetRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AddNoteButtonWidgetProps) {
    super(props);

    this.initState();
  }
  public get isAdded(): boolean {
    return this.props.isAdded;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["isAdded"], this.rebuild);
  }
  public componentDidUpdate(prevProps: AddNoteButtonWidgetProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.isAdded !== this.props.isAdded) {
      this.fire("isAdded", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          children: [
            ToolTipWrapper({
              message: "Add",
              backgroundColor: cStyle.tooltipBackgroundColor,
              textColor: cStyle.tooltipTextColor,
              child: AddNoteButtonWithState({
                d3eState: this.d3eState,
                _onAddNoteButtonPressed: this.onAddNoteButtonPressed,
                key: "0",
              }),
            }),
            ui.Container({ className: "xb0 hc", key: "1" }),
            this.isAdded
              ? ToolTipWrapper({
                  message: "Remove",
                  backgroundColor: cStyle.tooltipBackgroundColor,
                  textColor: cStyle.tooltipTextColor,
                  child: RemoveNoteButtonWithState({
                    d3eState: this.d3eState,
                    _onRemoveNoteButtonPressed: this.onRemoveNoteButtonPressed,
                  }),
                })
              : [],
          ],
          className: "x9e",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "AddNoteButtonWidget"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onAddNoteButtonPressed = (d3eState: AddNoteButtonWidgetRefs): void => {
    this.onAddNote(this.isAdded);
  };
  public onRemoveNoteButtonPressed = (
    d3eState: AddNoteButtonWidgetRefs
  ): void => {
    this.onRemoveNote(this.isAdded);
  };
  public get onAddNote(): _AddNoteButtonWidgetOnAddNote {
    return this.props.onAddNote;
  }
  public get onRemoveNote(): _AddNoteButtonWidgetOnRemoveNote {
    return this.props.onRemoveNote;
  }
  public get addNoteButton() {
    return this.d3eState.addNoteButton;
  }
  public get removeNoteButton() {
    return this.d3eState.removeNoteButton;
  }
}
export default function AddNoteButtonWidget(props: AddNoteButtonWidgetProps) {
  return React.createElement(_AddNoteButtonWidgetState, {
    ..._AddNoteButtonWidgetState.defaultProps,
    ...props,
  });
}
