import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import EditUserView from "./EditUserView";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import TextView from "./TextView";
import UserRole from "../classes/UserRole";
import IconButton from "./IconButton";
import { BuildContext } from "../classes/BuildContext";

type _TeamProfileWidgetOnClick = () => void;

type _IconButtonOnPressed = (d3eState: TeamProfileWidgetRefs) => void;

type _ColumnRefOnTap = (d3eState: TeamProfileWidgetRefs) => void;

export interface TeamProfileWidgetProps extends BaseUIProps {
  key?: string;
  user: User;
  onClick?: _TeamProfileWidgetOnClick;
}
/// To store state data for TeamProfileWidget
class TeamProfileWidgetRefs {
  public profileItem: ProfileItemState = new ProfileItemState();
}

interface ProfileItemWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: TeamProfileWidgetRefs;
  _onPressedColumnRefHandler?: _ColumnRefOnTap;
  _onTapiconviewBehaviourHandler?: _IconButtonOnPressed;
  user: User;
}

class ProfileItemState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ProfileItemWithState extends ObservableComponent<ProfileItemWithStateProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ProfileItemWithStateProps) {
    super(props);

    this.initState();
  }
  public get profileItem(): ProfileItemState {
    return this.props.d3eState.profileItem;
  }
  public get user(): User {
    return this.props.user;
  }
  public get d3eState(): TeamProfileWidgetRefs {
    return this.props.d3eState;
  }
  public get _onPressedColumnRefHandler(): _ColumnRefOnTap {
    return this.props._onPressedColumnRefHandler;
  }
  public get _onTapiconviewBehaviourHandler(): _IconButtonOnPressed {
    return this.props._onTapiconviewBehaviourHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("profileItem", null, this.profileItem);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "profileItem",
        "profileItem.hover",
        "user",
        "user.email",
        "user.firstName",
        "user.lastName",
        "user.profile",
        "user.role",
        "user.twilioNumber",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ProfileItemWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public profileItemOnEnter(event): void {
    return this.profileItem.setHover(true);
  }
  public profileItemOnExit(event): void {
    return this.profileItem.setHover(false);
  }
  public dispose(): void {
    this.profileItem.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      states: ui.joinStates(
        { "data-c0": this.profileItem.hover },
        this.props.states
      ),
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.end,
          children: [
            this.profileItem.hover
              ? IconButton({
                  icon: MaterialIcons.edit,
                  tooltip: "Edit",
                  size: 18,
                  onPressed: () => {
                    this._onTapiconviewBehaviourHandler(this.d3eState);
                  },
                  className: "vc",
                })
              : [],
          ],
          className: "x5ff3 hc vc h",
          key: "0",
        }),
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            ui.Container({
              alignment: ui.Alignment.center,
              child:
                this.user === null ||
                this.user.profile === null ||
                this.user.profile.downloadUrl === null
                  ? ui.ClipRRect({
                      borderRadius: ui.BorderRadius.circular(50),
                      child: ui.AssetImage({
                        path: "images/profile1.png",
                        width: 100,
                        height: 100,
                        fit: ui.BoxFit.fill,
                        className: "xc46 hc vc",
                      }),
                    })
                  : ui.ClipRRect({
                      borderRadius: ui.BorderRadius.circular(50),
                      child: ui.NetworkImage({
                        url:
                          this.user.profile.downloadUrl +
                          "?width=100&height=100&inline=true",
                        width: 100,
                        height: 100,
                        fit: ui.BoxFit.fill,
                        className: "x42e hc vc",
                      }),
                    }),
              className: "x9f4 hc vc",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.center,
              children: [
                TextView({
                  data: this.user.firstName + " " + this.user.lastName,
                  overflow: ui.TextOverflow.ellipsis,
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineFiveFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineFiveFontWeightOn,
                  }),
                  className: "x682b hc",
                  key: "0",
                }),
                TextView({
                  data: this.user.email,
                  overflow: ui.TextOverflow.ellipsis,
                  style: new ui.TextStyle({ fontSize: 14 }),
                  className: "x5df hc",
                  key: "1",
                }),
                TextView({
                  data:
                    this.user.twilioNumber !== null
                      ? this.user.twilioNumber.toString()
                      : "No Twilio Number",
                  style: new ui.TextStyle({ fontSize: 14 }),
                  className: "x3c2 hc",
                  key: "2",
                }),
                TextView({
                  data: this.user.role.name,
                  style: new ui.TextStyle({ fontSize: 14 }),
                  className: "x7a4 hc",
                  key: "3",
                }),
              ],
              className: "xa5c hc",
              key: "1",
            }),
          ],
          onTap: (e) => {
            e.stopPropagation();

            this._onPressedColumnRefHandler(this.d3eState);
          },
          className: "x6b8 hc",
          key: "1",
        }),
      ],
      onEnter: (event) => {
        this.profileItemOnEnter(event);
      },
      onExit: (event) => {
        this.profileItemOnExit(event);
      },
      className: ui.join(this.props.className, "TeamProfileWidget x570 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
}
function ProfileItemWithState(props: ProfileItemWithStateProps) {
  return React.createElement(_ProfileItemWithState, props);
}

class _TeamProfileWidgetState extends ObservableComponent<TeamProfileWidgetProps> {
  static defaultProps = { user: null, onClick: null };
  d3eState: TeamProfileWidgetRefs = new TeamProfileWidgetRefs();
  editUserViewPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: TeamProfileWidgetProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(["user"], this.rebuild);
  }
  public componentDidUpdate(prevProps: TeamProfileWidgetProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ProfileItemWithState({
      d3eState: this.d3eState,
      _onPressedColumnRefHandler: this.onPressedColumnRefHandler,
      _onTapiconviewBehaviourHandler: this.onTapiconviewBehaviourHandler,
      user: this.user,
      className: this.props.className,
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedColumnRefHandler = (
    d3eState: TeamProfileWidgetRefs
  ): void => {
    if (this.onClick !== null) {
      this.onClick();
    }
  };
  public onTapiconviewBehaviourHandler = (
    d3eState: TeamProfileWidgetRefs
  ): void => {
    this.showEditUserView();
  };
  public get onClick(): _TeamProfileWidgetOnClick {
    return this.props.onClick;
  }
  public dispose(): void {
    this.editUserViewPopup?.dispose();

    super.dispose();
  }
  public showEditUserView(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.editUserViewPopup?.dispose();

    this.editUserViewPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 550,
        child: EditUserView({ user: this.user }),
        className: "xe8eb hc vc",
      }),
    });

    this.editUserViewPopup.showPopup(this.context);
  }
  public hideEditUserView(): void {
    this.editUserViewPopup?.dispose();
  }
  public get profileItem() {
    return this.d3eState.profileItem;
  }
}
export default function TeamProfileWidget(props: TeamProfileWidgetProps) {
  return React.createElement(_TeamProfileWidgetState, {
    ..._TeamProfileWidgetState.defaultProps,
    ...props,
  });
}
