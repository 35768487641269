import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallData from "../classes/CallData";
import AddNoteButtonWidget from "./AddNoteButtonWidget";
import LabelWithInputField from "./LabelWithInputField";
import ResultStatus from "../classes/ResultStatus";
import D3EDate from "../classes/D3EDate";
import LabelWithDateField from "./LabelWithDateField";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import Result from "../classes/Result";
import Button from "./Button";
import MaritalStatus from "../classes/MaritalStatus";
import ListWrapper from "../utils/ListWrapper";
import CheckServerErrors from "./CheckServerErrors";
import Child from "../models/Child";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import TextView from "./TextView";
import CollectionUtils from "../utils/CollectionUtils";
import CancelSaveButtonsWidget from "./CancelSaveButtonsWidget";
import SuccessMessage from "../classes/SuccessMessage";
import EventBus from "../utils/EventBus";
import LabelWithDoubleInputField from "./LabelWithDoubleInputField";
import CheckboxWithText from "./CheckboxWithText";
import FamilyInfo from "../models/FamilyInfo";
import LabelWithIntegerInputField from "./LabelWithIntegerInputField";
import LabelDropdown from "./LabelDropdown";
import IconView from "./IconView";
import Divider from "./Divider";
import { Runnable } from "../classes/core";
import { BuildContext } from "../classes/BuildContext";
import ToolTipWrapper from "./ToolTipWrapper";

type _RemoveButtonOnPressed = (d3eState: _ChildState) => void;

type _LabelDropdown2OnChanged = (
  text: MaritalStatus,
  d3eState: FamilyInformationViewRefs
) => void;

type _LabelWithInputFieldOnChanged = (
  text: string,
  d3eState: FamilyInformationViewRefs
) => void;

type _LabelWithDoubleInputFieldOnChanged = (
  text: number,
  d3eState: FamilyInformationViewRefs
) => void;

type _LabelWithIntegerInputField3OnChanged = (
  text: number,
  d3eState: FamilyInformationViewRefs
) => void;

type _LabelWithDateFieldOnChanged = (
  text: D3EDate,
  d3eState: FamilyInformationViewRefs
) => void;

type _CheckboxWithTextOnChanged = (
  text: boolean,
  d3eState: FamilyInformationViewRefs
) => void;

type _LabelWithInputField8OnChanged = (
  text: string,
  d3eState: _ChildState
) => void;

export interface FamilyInformationViewProps extends BaseUIProps {
  key?: string;
  info: FamilyInfo;
  lead: Lead;
  user: User;
  client: TwilioClient;
  data: CallData;
}
/// To store state data for FamilyInformationView
class FamilyInformationViewRefs {
  public childState: Map<Child, _ChildState> = new Map();
  public forChild(child: Child): _ChildState {
    let res = this.childState.get(child);

    if (res == null) {
      res = new _ChildState(this, child);

      this.childState.set(child, res);
    }

    return res;
  }
}

interface RemoveButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ChildState;
  _onRemoveNote?: _RemoveButtonOnPressed;
}

class RemoveButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _RemoveButtonWithState extends ObservableComponent<RemoveButtonWithStateProps> {
  removeButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RemoveButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get removeButton(): RemoveButtonState {
    return this.props.d3eState.removeButton;
  }
  public get d3eState(): _ChildState {
    return this.props.d3eState;
  }
  public get _onRemoveNote(): _RemoveButtonOnPressed {
    return this.props._onRemoveNote;
  }
  public initState() {
    super.initState();

    this.updateObservable("removeButton", null, this.removeButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["removeButton"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.removeButton.disable,
      onPressed: () => {
        this._onRemoveNote(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        size: 24,
        icon: MaterialIcons.remove_circle_outline,
        color: cStyle.c1,
        className: "xc1",
      }),
    });
  }
}
function RemoveButtonWithState(props: RemoveButtonWithStateProps) {
  return React.createElement(_RemoveButtonWithState, props);
}

class _ChildState {
  parent: FamilyInformationViewRefs;
  child: Child;
  removeButton: RemoveButtonState = new RemoveButtonState();
  public constructor(parent, child) {
    this.parent = parent;

    this.child = child;
  }
}

class _FamilyInformationViewState extends ObservableComponent<FamilyInformationViewProps> {
  static defaultProps = {
    info: null,
    lead: null,
    user: null,
    client: null,
    data: null,
  };
  d3eState: FamilyInformationViewRefs = new FamilyInformationViewRefs();
  errors: Array<string> = ListWrapper.widget(this, "errors");
  isChild: boolean = false;
  isAdded: boolean = false;
  private _editInitListeners: Array<Runnable> = [];
  private _d3eModelErrors: Array<string> = [];
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: FamilyInformationViewProps) {
    super(props);

    this.initState();
  }
  public get info(): FamilyInfo {
    return this.props.info;
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("info", this.props.info);

    this.updateSyncProperty("lead", this.props.lead);

    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("data", this.props.data);

    this.on(
      [
        "errors",
        "info",
        "info.annisversaryDate",
        "info.children",
        "info.children.ageGroup",
        "info.children.name",
        "info.familyIncome",
        "info.familyMembers",
        "info.hasElderlyCareResponsibility",
        "info.marritalStatus",
        "info.spouseName",
        "isChild",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: FamilyInformationViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.info !== this.props.info) {
      this.updateObservable("info", prevProps.info, this.props.info);

      this.fire("info", this);
    }

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public setIsChild(val: boolean): void {
    let isValChanged: boolean = this.isChild !== val;

    if (!isValChanged) {
      return;
    }

    this.isChild = val;

    this.fire("isChild", this);
  }
  public setIsAdded(val: boolean): void {
    let isValChanged: boolean = this.isAdded !== val;

    if (!isValChanged) {
      return;
    }

    this.isAdded = val;

    this.fire("isAdded", this);
  }
  public get d3eModelErrors(): Array<string> {
    return this._d3eModelErrors ?? [];
  }
  public set d3eModelErrors(val: Array<string>) {
    this._d3eModelErrors.clear();

    this._d3eModelErrors.addAll(val);
  }
  public setEditDefaults(): void {}
  public get isEditValid(): boolean {
    return true;
  }
  public validate(): Array<string> {
    let errors: Array<string> = [];

    return errors;
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Column({
          children: [
            ui.Row({
              children: [
                ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 5.0, 0.0, new Map()),
                  child: LabelDropdown<MaritalStatus>({
                    name: "Marrital Status",
                    placeHolder: "-- Select--",
                    items: MaritalStatus.values,
                    value: this.info.marritalStatus,
                    width: 400,
                    onChanged: (text) => {
                      this.labelDropdown2onChanged(text, this.d3eState);
                    },
                    builder: (context, item) => {
                      return TextView({ data: item.name });
                    },
                  }),
                  key: "0",
                  className: "xb56",
                }),
                this.info.marritalStatus !== MaritalStatus.Single &&
                this.info.marritalStatus !== MaritalStatus.PreferNotToSay
                  ? ui.Container({
                      expand: true,
                      child: LabelWithInputField({
                        name: "Spouse Name",
                        placeHolder: "Enter Name",
                        value: this.info.spouseName,
                        onChanged: (text) => {
                          this.labelWithInputFieldonChanged(
                            text,
                            this.d3eState
                          );
                        },
                      }),
                      className: "x99ba hc h",
                    })
                  : [],
                ui.Container({
                  expand: true,
                  child: LabelWithDoubleInputField({
                    name: "Family Income",
                    placeHolder: "0",
                    value: this.info.familyIncome,
                    onChanged: (text) => {
                      this.labelWithDoubleInputFieldonChanged(
                        text,
                        this.d3eState
                      );
                    },
                  }),
                  key: "2",
                  className: "x924 hc h",
                }),
              ],
              className: "x5d3 hc h",
              key: "0",
            }),
            ui.Row({
              children: [
                ui.Container({
                  expand: true,
                  child: LabelWithIntegerInputField({
                    name: "Family Members",
                    placeHolder: "0",
                    value: this.info.familyMembers,
                    onChanged: (text) => {
                      this.labelWithIntegerInputField3onChanged(
                        text,
                        this.d3eState
                      );
                    },
                  }),
                  key: "0",
                  className: "xdc8 hc h",
                }),
                this.info.marritalStatus !== MaritalStatus.Single &&
                this.info.marritalStatus !== MaritalStatus.PreferNotToSay
                  ? ui.Container({
                      expand: true,
                      margin: ui.EdgeInsets.symmetric({
                        horizontal: 15.0,
                        vertical: 0.0,
                        transitions: new Map(),
                      }),
                      child: LabelWithDateField({
                        name: "Anniversary Date",
                        placeHolder: "dd-MM-yyyy",
                        value: this.info.annisversaryDate,
                        onChanged: (text) => {
                          this.labelWithDateFieldonChanged(text, this.d3eState);
                        },
                      }),
                      className: "x7d9 hc h",
                    })
                  : [],
                ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(
                    0.0,
                    30.0,
                    15.0,
                    0.0,
                    new Map()
                  ),
                  child: CheckboxWithText({
                    title: "Has Elderly Care Responsibility",
                    value: this.info.hasElderlyCareResponsibility,
                    activeColor: cStyle.c1,
                    onChanged: (text) => {
                      this.checkboxWithTextonChanged(text, this.d3eState);
                    },
                  }),
                  key: "2",
                  className: "x6c0",
                }),
              ],
              className: "xe2 hc h",
              key: "1",
            }),
            this.info.marritalStatus !== MaritalStatus.Single
              ? ui.Row({
                  children: [
                    TextView({
                      data: "Children",
                      style: new ui.TextStyle({
                        fontSize: cStyle.tTextViewHeadlineFiveFontSizeOn,
                        fontWeight: cStyle.tTextViewHeadlineFiveFontWeightOn,
                      }),
                      className: "x37",
                      key: "0",
                    }),
                    AddNoteButtonWidget({
                      isAdded: false,
                      onAddNote: (isAdded) => {
                        this.onAddNote(isAdded, this.d3eState);
                      },
                      key: "1",
                    }),
                    ui.Container({
                      expand: true,
                      child: Divider({}),
                      key: "2",
                      className: "x21b hc h",
                    }),
                  ],
                  className: "x5a8 hc h",
                })
              : [],
            this.isChild
              ? this.info.children.expand((child) => [
                  ui.Row({
                    children: [
                      ui.Container({
                        expand: true,
                        child: LabelWithInputField({
                          name: "Name",
                          placeHolder: "Enter Name",
                          value: child.name,
                          onChanged: (text) => {
                            this.labelWithInputField8onChanged(
                              text,
                              this.d3eState.forChild(child)
                            );
                          },
                        }),
                        key: "0",
                        className: "xfe24 hc h",
                      }),
                      ui.Container({
                        expand: true,
                        margin: ui.EdgeInsets.fromLTRB(
                          15.0,
                          0.0,
                          0.0,
                          0.0,
                          new Map()
                        ),
                        child: LabelWithInputField({
                          name: "Age ",
                          placeHolder: "0",
                          value: child.ageGroup,
                        }),
                        key: "1",
                        className: "x61c hc h",
                      }),
                      ToolTipWrapper({
                        message: "Remove",
                        backgroundColor: cStyle.tooltipBackgroundColor,
                        textColor: cStyle.tooltipTextColor,
                        child: RemoveButtonWithState({
                          d3eState: this.d3eState.forChild(child),
                          _onRemoveNote: this.onRemoveNote,
                          key: "2",
                        }),
                      }),
                    ],
                    className: "x3b hc h",
                    key: child?.ident,
                  }),
                ])
              : [],
          ],
          className: "x50e hc h",
          key: "0",
        }),
        ui.Container({ className: "x35e hc vc", key: "1" }),
        this.errors.isNotEmpty
          ? CheckServerErrors({ errors: this.errors, className: "x961 hc" })
          : [],
        CancelSaveButtonsWidget({
          title: "Save",
          isNewObj: true,
          onCancel: () => {
            this.onCancelHandler(this.d3eState);
          },
          onSave: () => {
            this.onSaveHandler(this.d3eState);
          },
          className: "xb29 hc h",
          key: "3",
        }),
      ],
      className: ui.join(
        this.props.className,
        "FamilyInformationView x28c hc h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setIsAdded(this.info.children.isNotEmpty);

    this.setIsChild(this.isAdded);
  };
  public onAddNote = (
    isAdded: boolean,
    d3eState: FamilyInformationViewRefs
  ): void => {
    this.setIsAdded(true);

    isAdded = this.isAdded;

    this.info.children.add(new Child());

    this.setIsChild(true);
  };
  public onRemoveNote = (d3eState: _ChildState): void => {
    //  Remove the child from the list

    this.info.children.remove(d3eState.child);
  };
  public onCancelHandler = (d3eState: FamilyInformationViewRefs): void => {
    this.navigator.pushLeadManagementPage({
      user: this.user,
      replace: true,
      client: this.client,
      target: "main",
      data: this.data,
    });
  };
  public onSaveHandler = async (
    d3eState: FamilyInformationViewRefs
  ): Promise<void> => {
    if (this.validate().isEmpty) {
      this.lead.setFamilyInfo(this.info);

      let result: Result<Lead> = await this.lead.save();

      if (result.status === ResultStatus.Success) {
        EventBus.get().fire(
          new SuccessMessage({ message: "Updated Successfully" })
        );

        this.navigator.pushLeadManagementPage({
          user: this.user,
          client: this.client,
          replace: true,
          target: "main",
          data: this.data,
        });
      } else {
        this.setErrors(result.errors);
      }
    }
  };
  public labelDropdown2onChanged = (
    val: MaritalStatus,
    d3eState: FamilyInformationViewRefs
  ): void => {
    this.info.setMarritalStatus(val);
  };
  public labelWithInputFieldonChanged = (
    val: string,
    d3eState: FamilyInformationViewRefs
  ): void => {
    this.info.setSpouseName(val);
  };
  public labelWithDoubleInputFieldonChanged = (
    val: number,
    d3eState: FamilyInformationViewRefs
  ): void => {
    this.info.setFamilyIncome(val);
  };
  public labelWithIntegerInputField3onChanged = (
    val: number,
    d3eState: FamilyInformationViewRefs
  ): void => {
    this.info.setFamilyMembers(val);
  };
  public labelWithDateFieldonChanged = (
    val: D3EDate,
    d3eState: FamilyInformationViewRefs
  ): void => {
    this.info.setAnnisversaryDate(val);
  };
  public checkboxWithTextonChanged = (
    val: boolean,
    d3eState: FamilyInformationViewRefs
  ): void => {
    this.info.setHasElderlyCareResponsibility(val);
  };
  public labelWithInputField8onChanged = (
    val: string,
    d3eState: _ChildState
  ): void => {
    d3eState.child.setName(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function FamilyInformationView(
  props: FamilyInformationViewProps
) {
  return React.createElement(_FamilyInformationViewState, {
    ..._FamilyInformationViewState.defaultProps,
    ...props,
  });
}
