export default class InteractionType {
  public static readonly Call = new InteractionType("Call", "Call", 0);
  public static readonly SMS = new InteractionType("SMS", "SMS", 1);
  public static readonly WhatsApp = new InteractionType(
    "WhatsApp",
    "WhatsApp",
    2
  );
  public static readonly VideoCall = new InteractionType(
    "VideoCall",
    "VideoCall",
    3
  );
  public static readonly Email = new InteractionType("Email", "Email", 4);
  public static readonly Meeting = new InteractionType("Meeting", "Meeting", 5);
  public static readonly Demo = new InteractionType("Demo", "Demo", 6);
  public static readonly Webinar = new InteractionType("Webinar", "Webinar", 7);
  public static readonly Conference = new InteractionType(
    "Conference",
    "Conference",
    8
  );
  public static readonly Other = new InteractionType("Other", "Other", 9);
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): InteractionType[] {
    return [
      InteractionType.Call,
      InteractionType.SMS,
      InteractionType.WhatsApp,
      InteractionType.VideoCall,
      InteractionType.Email,
      InteractionType.Meeting,
      InteractionType.Demo,
      InteractionType.Webinar,
      InteractionType.Conference,
      InteractionType.Other,
    ];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
