import User from "./User";
import Lead from "./Lead";
import D3EDate from "../classes/D3EDate";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class LeadAssignment extends DBObject {
  private static readonly _ASSIGNEDBY: number = 0;
  private static readonly _ASSIGNEDDATE: number = 1;
  private static readonly _ASSIGNEDTO: number = 2;
  private static readonly _LEAD: number = 3;
  public id: number = 0;
  public otherMaster: DBObject;
  private _lead: Lead = null;
  private _assignedTo: User = null;
  private _assignedDate: D3EDate = null;
  private _assignedBy: User = null;
  public constructor(
    d3eParams?: Partial<{
      assignedBy: User;
      assignedDate: D3EDate;
      assignedTo: User;
      lead: Lead;
    }>
  ) {
    super();

    this.setAssignedBy(d3eParams?.assignedBy ?? null);

    this.setAssignedDate(d3eParams?.assignedDate ?? null);

    this.setAssignedTo(d3eParams?.assignedTo ?? null);

    this.setLead(d3eParams?.lead ?? null);
  }
  public get d3eType(): string {
    return "LeadAssignment";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get lead(): Lead {
    return this._lead;
  }
  public setLead(val: Lead): void {
    let isValChanged: boolean = this._lead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadAssignment._LEAD, this._lead);

    this.updateObservable("lead", this._lead, val);

    this._lead = val;

    this.fire("lead", this);
  }
  public get assignedTo(): User {
    return this._assignedTo;
  }
  public setAssignedTo(val: User): void {
    let isValChanged: boolean = this._assignedTo !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadAssignment._ASSIGNEDTO, this._assignedTo);

    this.updateObservable("assignedTo", this._assignedTo, val);

    this._assignedTo = val;

    this.fire("assignedTo", this);
  }
  public get assignedDate(): D3EDate {
    return this._assignedDate;
  }
  public setAssignedDate(val: D3EDate): void {
    let isValChanged: boolean = this._assignedDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadAssignment._ASSIGNEDDATE, this._assignedDate);

    this._assignedDate = val;

    this.fire("assignedDate", this);
  }
  public get assignedBy(): User {
    return this._assignedBy;
  }
  public setAssignedBy(val: User): void {
    let isValChanged: boolean = this._assignedBy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadAssignment._ASSIGNEDBY, this._assignedBy);

    this.updateObservable("assignedBy", this._assignedBy, val);

    this._assignedBy = val;

    this.fire("assignedBy", this);
  }
  public get(field: number): any {
    switch (field) {
      case LeadAssignment._LEAD: {
        return this._lead;
      }

      case LeadAssignment._ASSIGNEDTO: {
        return this._assignedTo;
      }

      case LeadAssignment._ASSIGNEDDATE: {
        return this._assignedDate;
      }

      case LeadAssignment._ASSIGNEDBY: {
        return this._assignedBy;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): LeadAssignment {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: LeadAssignment = dbObj as LeadAssignment;

    obj.id = this.id;

    obj.setLead(this._lead);

    obj.setAssignedTo(this._assignedTo);

    obj.setAssignedDate(this._assignedDate);

    obj.setAssignedBy(this._assignedBy);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case LeadAssignment._LEAD: {
        this.setLead(value as Lead);
        break;
      }

      case LeadAssignment._ASSIGNEDTO: {
        this.setAssignedTo(value as User);
        break;
      }

      case LeadAssignment._ASSIGNEDDATE: {
        this.setAssignedDate(value as D3EDate);
        break;
      }

      case LeadAssignment._ASSIGNEDBY: {
        this.setAssignedBy(value as User);
        break;
      }
    }
  }
}
