import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _CancelSaveButtonsWidgetOnCancel = () => void;

type _CancelSaveButtonsWidgetOnSave = () => void;

type _CancelSaveButtonsWidgetOnSaveAndCancel = () => void;

type _CancelButtonOnPressed = (d3eState: CancelSaveButtonsWidgetRefs) => void;

type _SaveAndCancelButtonOnPressed = (
  d3eState: CancelSaveButtonsWidgetRefs
) => void;

type _SaveButtonOnPressed = (d3eState: CancelSaveButtonsWidgetRefs) => void;

export interface CancelSaveButtonsWidgetProps extends BaseUIProps {
  key?: string;
  title: string;
  isNewObj: boolean;
  onCancel?: _CancelSaveButtonsWidgetOnCancel;
  onSave?: _CancelSaveButtonsWidgetOnSave;
  onSaveAndCancel?: _CancelSaveButtonsWidgetOnSaveAndCancel;
}
/// To store state data for CancelSaveButtonsWidget
class CancelSaveButtonsWidgetRefs {
  public cancelButton: CancelButtonState = new CancelButtonState();
  public saveAndCancelButton: SaveAndCancelButtonState =
    new SaveAndCancelButtonState();
  public saveButton: SaveButtonState = new SaveButtonState();
}

interface SaveButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: CancelSaveButtonsWidgetRefs;
  _onSaveHandler?: _SaveButtonOnPressed;
  title: string;
}

class SaveButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _SaveButtonWithState extends ObservableComponent<SaveButtonWithStateProps> {
  saveButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SaveButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get saveButton(): SaveButtonState {
    return this.props.d3eState.saveButton;
  }
  public get title(): string {
    return this.props.title;
  }
  public get d3eState(): CancelSaveButtonsWidgetRefs {
    return this.props.d3eState;
  }
  public get _onSaveHandler(): _SaveButtonOnPressed {
    return this.props._onSaveHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("saveButton", null, this.saveButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["saveButton", "saveButton.", "saveButton.hover", "title"],
      this.rebuild
    );
  }
  public saveButtonOnEnter(event): void {
    return this.saveButton.setHover(true);
  }
  public saveButtonOnExit(event): void {
    return this.saveButton.setHover(false);
  }
  public dispose(): void {
    this.saveButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(10.0, 0.0, 0.0, 0.0, new Map()),
      width: 110,
      child: Button({
        padding: this.saveButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.saveButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.saveButton.disable,
        onPressed: () => {
          this._onSaveHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: this.title,
          style: new ui.TextStyle({ color: cStyle.c14 }),
          className: "x617",
        }),
        onEnter: (event) => {
          this.saveButtonOnEnter(event);
        },
        onExit: (event) => {
          this.saveButtonOnExit(event);
        },
      }),
      className: "x92b hc",
    });
  }
}
function SaveButtonWithState(props: SaveButtonWithStateProps) {
  return React.createElement(_SaveButtonWithState, props);
}

interface SaveAndCancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: CancelSaveButtonsWidgetRefs;
  _onSaveAndCancelHandler?: _SaveAndCancelButtonOnPressed;
}

class SaveAndCancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _SaveAndCancelButtonWithState extends ObservableComponent<SaveAndCancelButtonWithStateProps> {
  saveAndCancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SaveAndCancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get saveAndCancelButton(): SaveAndCancelButtonState {
    return this.props.d3eState.saveAndCancelButton;
  }
  public get d3eState(): CancelSaveButtonsWidgetRefs {
    return this.props.d3eState;
  }
  public get _onSaveAndCancelHandler(): _SaveAndCancelButtonOnPressed {
    return this.props._onSaveAndCancelHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable(
      "saveAndCancelButton",
      null,
      this.saveAndCancelButton
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "saveAndCancelButton",
        "saveAndCancelButton.",
        "saveAndCancelButton.hover",
      ],
      this.rebuild
    );
  }
  public saveAndCancelButtonOnEnter(event): void {
    return this.saveAndCancelButton.setHover(true);
  }
  public saveAndCancelButtonOnExit(event): void {
    return this.saveAndCancelButton.setHover(false);
  }
  public dispose(): void {
    this.saveAndCancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.saveAndCancelButton.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.saveAndCancelButton.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.saveAndCancelButton.disable,
        onPressed: () => {
          this._onSaveAndCancelHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: "Save and Close",
          style: new ui.TextStyle({ color: cStyle.c1 }),
          className: "x36",
        }),
        onEnter: (event) => {
          this.saveAndCancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.saveAndCancelButtonOnExit(event);
        },
      }),
      className: "x08",
    });
  }
}
function SaveAndCancelButtonWithState(
  props: SaveAndCancelButtonWithStateProps
) {
  return React.createElement(_SaveAndCancelButtonWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: CancelSaveButtonsWidgetRefs;
  _onCancelHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): CancelSaveButtonsWidgetRefs {
    return this.props.d3eState;
  }
  public get _onCancelHandler(): _CancelButtonOnPressed {
    return this.props._onCancelHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 100,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._onCancelHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: "Cancel",
          style: new ui.TextStyle({ color: cStyle.c1 }),
          className: "x20",
        }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "xe3b hc",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

class _CancelSaveButtonsWidgetState extends ObservableComponent<CancelSaveButtonsWidgetProps> {
  static defaultProps = {
    title: "",
    isNewObj: false,
    onCancel: null,
    onSave: null,
    onSaveAndCancel: null,
  };
  d3eState: CancelSaveButtonsWidgetRefs = new CancelSaveButtonsWidgetRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelSaveButtonsWidgetProps) {
    super(props);

    this.initState();
  }
  public get title(): string {
    return this.props.title;
  }
  public get isNewObj(): boolean {
    return this.props.isNewObj;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["isNewObj", "title"], this.rebuild);
  }
  public componentDidUpdate(prevProps: CancelSaveButtonsWidgetProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.title !== this.props.title) {
      this.fire("title", this);
    }

    if (prevProps.isNewObj !== this.props.isNewObj) {
      this.fire("isNewObj", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.end,
      mainAxisAlignment: ui.MainAxisAlignment.end,
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.end,
          crossAxisAlignment: ui.CrossAxisAlignment.end,
          children: [
            this.isNewObj
              ? CancelButtonWithState({
                  d3eState: this.d3eState,
                  _onCancelHandler: this.onCancelHandler,
                })
              : SaveAndCancelButtonWithState({
                  d3eState: this.d3eState,
                  _onSaveAndCancelHandler: this.onSaveAndCancelHandler,
                }),
            SaveButtonWithState({
              d3eState: this.d3eState,
              _onSaveHandler: this.onSaveHandler,
              title: this.title,
              key: "1",
            }),
          ],
          className: "x9c hc h",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "CancelSaveButtonsWidget x54f hc h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onCancelHandler = (d3eState: CancelSaveButtonsWidgetRefs): void => {
    if (this.onCancel !== null) {
      this.onCancel();
    }
  };
  public onSaveHandler = (d3eState: CancelSaveButtonsWidgetRefs): void => {
    if (this.onSave !== null) {
      this.onSave();
    }
  };
  public onSaveAndCancelHandler = (
    d3eState: CancelSaveButtonsWidgetRefs
  ): void => {
    if (this.onSaveAndCancel !== null) {
      this.onSaveAndCancel();
    }
  };
  public get onCancel(): _CancelSaveButtonsWidgetOnCancel {
    return this.props.onCancel;
  }
  public get onSave(): _CancelSaveButtonsWidgetOnSave {
    return this.props.onSave;
  }
  public get onSaveAndCancel(): _CancelSaveButtonsWidgetOnSaveAndCancel {
    return this.props.onSaveAndCancel;
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
  public get saveAndCancelButton() {
    return this.d3eState.saveAndCancelButton;
  }
  public get saveButton() {
    return this.d3eState.saveButton;
  }
}
export default function CancelSaveButtonsWidget(
  props: CancelSaveButtonsWidgetProps
) {
  return React.createElement(_CancelSaveButtonsWidgetState, {
    ..._CancelSaveButtonsWidgetState.defaultProps,
    ...props,
  });
}
