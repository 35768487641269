import { ReactNode } from "react";
import DBObject from "./DBObject";
import * as __d3ett from "../rocket/D3ETemplateTypes";
import Result from "../classes/Result";
import { LeadOverviewPageProps } from "../components/LeadOverviewPage";
import { LeadInformationPageProps } from "../components/LeadInformationPage";
import { NewTeamPageProps } from "../components/NewTeamPage";
import { ApolloLeadsPageProps } from "../components/ApolloLeadsPage";
import { LeadManagementPageProps } from "../components/LeadManagementPage";
import { ChangePassWordPageProps } from "../components/ChangePassWordPage";
import { ForgotPasswordPageProps } from "../components/ForgotPasswordPage";
import { LandingPageProps } from "../components/LandingPage";
import { LeadMailsPageProps } from "../components/LeadMailsPage";
import { DashboardPageProps } from "../components/DashboardPage";
import { MailsPageProps } from "../components/MailsPage";
import { ConnectionsPageProps } from "../components/ConnectionsPage";
import { RoutePageProps } from "../components/RoutePage";
import { SalesDashboardPageProps } from "../components/SalesDashboardPage";
import { TeamOverviewPageProps } from "../components/TeamOverviewPage";
import { LoginPageProps } from "../components/LoginPage";
import { SettingsPageProps } from "../components/SettingsPage";

export default class GlobalFunctions {
  public static save: (obj: DBObject) => Promise<Result<any>>;
  public static delete: (obj: DBObject) => Promise<Result<any>>;
  public static objectSave: (obj: DBObject, create: boolean) => void;
  public static fieldChanged: (
    obj: DBObject,
    field: number,
    oldValue: any
  ) => void;
  public static objectDelete: (obj: DBObject) => void;
  public static typeInt: (obj: string) => number;
  public static types: Array<__d3ett.D3ETemplateType>;
  public static ApolloLeadsPage: (props: ApolloLeadsPageProps) => ReactNode;
  public static ChangePassWordPage: (
    props: ChangePassWordPageProps
  ) => ReactNode;
  public static ConnectionsPage: (props: ConnectionsPageProps) => ReactNode;
  public static DashboardPage: (props: DashboardPageProps) => ReactNode;
  public static ForgotPasswordPage: (
    props: ForgotPasswordPageProps
  ) => ReactNode;
  public static LandingPage: (props: LandingPageProps) => ReactNode;
  public static LeadInformationPage: (
    props: LeadInformationPageProps
  ) => ReactNode;
  public static LeadMailsPage: (props: LeadMailsPageProps) => ReactNode;
  public static LeadManagementPage: (
    props: LeadManagementPageProps
  ) => ReactNode;
  public static LeadOverviewPage: (props: LeadOverviewPageProps) => ReactNode;
  public static LoginPage: (props: LoginPageProps) => ReactNode;
  public static MailsPage: (props: MailsPageProps) => ReactNode;
  public static NewTeamPage: (props: NewTeamPageProps) => ReactNode;
  public static RoutePage: (props: RoutePageProps) => ReactNode;
  public static SalesDashboardPage: (
    props: SalesDashboardPageProps
  ) => ReactNode;
  public static SettingsPage: (props: SettingsPageProps) => ReactNode;
  public static TeamOverviewPage: (props: TeamOverviewPageProps) => ReactNode;
}
