import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import PopupTargetController from "./PopupTargetController";
import D3EDisposable from "../rocket/D3EDisposable";
import LeadStatus from "../classes/LeadStatus";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import IconButton from "./IconButton";
import LeadUtils from "../classes/LeadUtils";
import MessageDispatch from "../rocket/MessageDispatch";
import TimeZoneUtil from "../classes/TimeZoneUtil";
import InteractionType from "../classes/InteractionType";
import RecentItemDeleteView from "./RecentItemDeleteView";
import Lead from "../models/Lead";
import TextView from "./TextView";
import Interaction from "../models/Interaction";
import IconView from "./IconView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _RecentConnectionItemProfileViewOnLeadClick = (lead: Lead) => void;

type _RecentLeadButtonOnTap = (
  d3eState: RecentConnectionItemProfileViewRefs
) => void;

type _IconButtonOnPressed = (
  d3eState: RecentConnectionItemProfileViewRefs
) => void;

export interface RecentConnectionItemProfileViewProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  selectedLead: Lead;
  onLeadClick: _RecentConnectionItemProfileViewOnLeadClick;
}
/// To store state data for RecentConnectionItemProfileView
class RecentConnectionItemProfileViewRefs {
  public recentLeadButton: RecentLeadButtonState = new RecentLeadButtonState();
}

interface RecentLeadButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RecentConnectionItemProfileViewRefs;
  _onHover?: _IconButtonOnPressed;
  _onRecentLeadButtonPressed?: _RecentLeadButtonOnTap;
  iconButtonPopupTargetController: PopupTargetController;
  lastInteraction: Interaction;
  lead: Lead;
  selectedLead: Lead;
}

class RecentLeadButtonState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _RecentLeadButtonWithState extends ObservableComponent<RecentLeadButtonWithStateProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RecentLeadButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get lastInteraction(): Interaction {
    return this.props.lastInteraction;
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get recentLeadButton(): RecentLeadButtonState {
    return this.props.d3eState.recentLeadButton;
  }
  public get selectedLead(): Lead {
    return this.props.selectedLead;
  }
  public get d3eState(): RecentConnectionItemProfileViewRefs {
    return this.props.d3eState;
  }
  public get _onHover(): _IconButtonOnPressed {
    return this.props._onHover;
  }
  public get _onRecentLeadButtonPressed(): _RecentLeadButtonOnTap {
    return this.props._onRecentLeadButtonPressed;
  }
  public get iconButtonPopupTargetController(): PopupTargetController {
    return this.props.iconButtonPopupTargetController;
  }
  public initState() {
    super.initState();

    this.updateObservable("recentLeadButton", null, this.recentLeadButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lastInteraction", this.props.lastInteraction);

    this.updateSyncProperty("selectedLead", this.props.selectedLead);

    this.updateSyncProperty("lead", this.props.lead);

    this.on(
      [
        "lastInteraction",
        "lastInteraction.body",
        "lastInteraction.createdDate",
        "lastInteraction.isIncoming",
        "lastInteraction.type",
        "lead",
        "lead.name",
        "lead.status",
        "recentLeadButton",
        "recentLeadButton.hover",
        "selectedLead",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RecentLeadButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lastInteraction !== this.props.lastInteraction) {
      this.updateObservable(
        "lastInteraction",
        prevProps.lastInteraction,
        this.props.lastInteraction
      );

      this.fire("lastInteraction", this);
    }

    if (prevProps.selectedLead !== this.props.selectedLead) {
      this.updateObservable(
        "selectedLead",
        prevProps.selectedLead,
        this.props.selectedLead
      );

      this.fire("selectedLead", this);
    }

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }
  }
  public recentLeadButtonOnEnter(event): void {
    return this.recentLeadButton.setHover(true);
  }
  public recentLeadButtonOnExit(event): void {
    return this.recentLeadButton.setHover(false);
  }
  public dispose(): void {
    this.recentLeadButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
      crossAxisAlignment: ui.CrossAxisAlignment.center,
      states: ui.joinStates(
        {
          "data-c0": this.recentLeadButton.hover,
          "data-c1":
            this.selectedLead !== null && this.lead === this.selectedLead,
        },
        this.props.states
      ),
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.start,
          crossAxisAlignment: ui.CrossAxisAlignment.center,
          children: [
            ui.Container({
              decoration: new ui.BoxDecoration({
                color: ui.HexColor.fromHexStr(
                  LeadUtils.getColorForFirstLetter(
                    this.lead.name !== null ? this.lead.name : "Unknown"
                  )
                ),
                borderRadius: ui.BorderRadius.circular(20.0),
              }),
              alignment: ui.Alignment.center,
              child: TextView({
                data:
                  this.lead.name.isEmpty || this.lead.name === ""
                    ? ""
                    : this.lead.name[0].toUpperCase(),
                textAlign: ui.TextAlign.center,
                style: new ui.TextStyle({
                  color: cStyle.c14,
                  fontWeight: ui.FontWeight.bold,
                }),
                className: "x742 hc vc",
              }),
              className: "xebe hc vc",
              key: "0",
            }),
            ui.Column({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    TextView({ data: this.lead.name, key: "0" }),
                    TextView({
                      data:
                        this.lastInteraction !== null &&
                        this.lastInteraction.createdDate !== null
                          ? TimeZoneUtil.chatMessageTimestamp(
                              this.lastInteraction.createdDate
                            ).toString()
                          : "",
                      key: "1",
                    }),
                  ],
                  className: "x582 hc",
                  key: "0",
                }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    this.lastInteraction !== null &&
                    this.lastInteraction.type === InteractionType.SMS
                      ? TextView({
                          data: !this.lastInteraction.isIncoming
                            ? this.lastInteraction.body.length > 30
                              ? this.lastInteraction.body.substring(0, 30) +
                                "..."
                              : "You: " + (this.lastInteraction?.body ?? "")
                            : this.lastInteraction.body.length > 30
                            ? this.lastInteraction.body.substring(0, 30) + "..."
                            : "" + (this.lastInteraction?.body ?? ""),
                          overflow: ui.TextOverflow.ellipsis,
                        })
                      : [],
                    this.lastInteraction !== null &&
                    this.lastInteraction.type === InteractionType.Call
                      ? ui.Row({
                          children: [
                            IconView({
                              icon: MaterialIcons.call,
                              size: 16,
                              className: "x527",
                              key: "0",
                            }),
                            TextView({
                              data: !this.lastInteraction.isIncoming
                                ? "Outgoing Call"
                                : "Incoming Call",
                              className: "x3b4",
                              key: "1",
                            }),
                          ],
                        })
                      : ui.Container({}),
                    this.recentLeadButton.hover &&
                    this.lead.status !== LeadStatus.Closed
                      ? ui.Container({
                          padding: ui.EdgeInsets.symmetric({
                            horizontal: 4.0,
                            vertical: 0.0,
                            transitions: new Map(),
                          }),
                          child: IconButton({
                            icon: MaterialIcons.more_horiz,
                            size: 14,
                            onPressed: () => {
                              this._onHover(this.d3eState);
                            },
                            d3eRef:
                              this.iconButtonPopupTargetController.handleRef,
                          }),
                          className: "x089",
                        })
                      : ui.Container({ className: "x70c5 vc" }),
                  ],
                  className: "xcd9 hc",
                  key: "1",
                }),
              ],
              key: "1",
            }),
          ],
          key: "0",
        }),
      ],
      onEnter: (event) => {
        this.recentLeadButtonOnEnter(event);
      },
      onExit: (event) => {
        this.recentLeadButtonOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onRecentLeadButtonPressed(this.d3eState);
      },
      className: ui.join(
        this.props.className,
        "RecentConnectionItemProfileView x29b"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
}
function RecentLeadButtonWithState(props: RecentLeadButtonWithStateProps) {
  return React.createElement(_RecentLeadButtonWithState, props);
}

class _RecentConnectionItemProfileViewState extends ObservableComponent<RecentConnectionItemProfileViewProps> {
  static defaultProps = { lead: null, selectedLead: null, onLeadClick: null };
  d3eState: RecentConnectionItemProfileViewRefs =
    new RecentConnectionItemProfileViewRefs();
  lastInteraction: Interaction = null;
  recentItemDeleteViewPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public iconButtonPopupTargetController: PopupTargetController =
    new PopupTargetController();
  public leadDisposable: D3EDisposable;
  public constructor(props: RecentConnectionItemProfileViewProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get selectedLead(): Lead {
    return this.props.selectedLead;
  }
  public initState() {
    super.initState();

    this.leadDisposable = MessageDispatch.get().syncObject(
      this.props.lead,
      UsageConstants.SUBSCRIPTION_ONLEADCHANGE_RECENTCONNECTIONITEMPROFILEVIEW_LEAD_SYNCHRONISE
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.on(["lead", "lead.lastInteraction"], this.computeLastInteraction);

    this.computeLastInteraction();

    this.updateSyncProperty("selectedLead", this.props.selectedLead);

    this.on(["lastInteraction", "lead", "selectedLead"], this.rebuild);
  }
  public componentDidUpdate(
    prevProps: RecentConnectionItemProfileViewProps
  ): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.selectedLead !== this.props.selectedLead) {
      this.updateObservable(
        "selectedLead",
        prevProps.selectedLead,
        this.props.selectedLead
      );

      this.fire("selectedLead", this);
    }
  }
  public setLastInteraction(val: Interaction): void {
    let isValChanged: boolean = this.lastInteraction !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("lastInteraction", this.lastInteraction, val);

    this.lastInteraction = val;

    this.fire("lastInteraction", this);
  }
  public computeLastInteraction = (): void => {
    try {
      this.setLastInteraction(this.lead.lastInteraction);
    } catch (exception) {
      console.log(
        " exception in computeLastInteraction : " + exception.toString()
      );

      this.setLastInteraction(null);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return RecentLeadButtonWithState({
      d3eState: this.d3eState,
      _onHover: this.onHover,
      _onRecentLeadButtonPressed: this.onRecentLeadButtonPressed,
      lastInteraction: this.lastInteraction,
      lead: this.lead,
      selectedLead: this.selectedLead,
      iconButtonPopupTargetController: this.iconButtonPopupTargetController,
      className: this.props.className,
      ...copyBaseUIProps(this.props),
    });
  }
  public onRecentLeadButtonPressed = (
    d3eState: RecentConnectionItemProfileViewRefs
  ): void => {
    this.onLeadClick(this.lead);
  };
  public onHover = (d3eState: RecentConnectionItemProfileViewRefs): void => {
    this.showRecentItemDeleteView({ autoClose: true });
  };
  public get onLeadClick(): _RecentConnectionItemProfileViewOnLeadClick {
    return this.props.onLeadClick;
  }
  public dispose(): void {
    this.leadDisposable?.dispose();

    this.recentItemDeleteViewPopup?.dispose();

    super.dispose();
  }
  public showRecentItemDeleteView(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.recentItemDeleteViewPopup?.dispose();

    let target: ui.Rect = this.iconButtonPopupTargetController.getTarget(
      this.context
    );

    this.recentItemDeleteViewPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        height: 50,
        child: RecentItemDeleteView({ lead: this.lead }),
        className: "x7a0 hc vc",
      }),
      target: target,
    });

    this.recentItemDeleteViewPopup.showPopup(this.context);
  }
  public hideRecentItemDeleteView(): void {
    this.recentItemDeleteViewPopup?.dispose();
  }
  public get recentLeadButton() {
    return this.d3eState.recentLeadButton;
  }
}
export default function RecentConnectionItemProfileView(
  props: RecentConnectionItemProfileViewProps
) {
  return React.createElement(_RecentConnectionItemProfileViewState, {
    ..._RecentConnectionItemProfileViewState.defaultProps,
    ...props,
  });
}
