import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class UsersListRequest extends DBObject {
  private static readonly _SEARCHTEXT: number = 0;
  public id: number = 0;
  public otherMaster: DBObject;
  private _searchText: string = "";
  public constructor(d3eParams?: Partial<{ searchText: string }>) {
    super();

    this.setSearchText(d3eParams?.searchText ?? "");
  }
  public get d3eType(): string {
    return "UsersListRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get searchText(): string {
    return this._searchText;
  }
  public setSearchText(val: string): void {
    let isValChanged: boolean = this._searchText !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(UsersListRequest._SEARCHTEXT, this._searchText);

    this._searchText = val;

    this.fire("searchText", this);
  }
  public get(field: number): any {
    switch (field) {
      case UsersListRequest._SEARCHTEXT: {
        return this._searchText;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): UsersListRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: UsersListRequest = dbObj as UsersListRequest;

    obj.id = this.id;

    obj.setSearchText(this._searchText);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case UsersListRequest._SEARCHTEXT: {
        this.setSearchText(value as string);
        break;
      }
    }
  }
}
