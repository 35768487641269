import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallData from "../classes/CallData";
import ResultStatus from "../classes/ResultStatus";
import LabelWithInputField from "./LabelWithInputField";
import ChangePasswordRequest from "../models/ChangePasswordRequest";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import Result from "../classes/Result";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import TwilioClient from "../classes/TwilioClient";
import TextView from "./TextView";
import CollectionUtils from "../utils/CollectionUtils";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _DoneRBOnPressed = (d3eState: ChangePassWordPageRefs) => void;

type _NewPasswordFieldWithLabelOnChanged = (
  text: string,
  d3eState: ChangePassWordPageRefs
) => void;

type _TextFieldOnChanged = (
  text: string,
  d3eState: ChangePassWordPageRefs
) => void;

export interface ChangePassWordPageProps extends BaseUIProps {
  key?: string;
  user: User;
}
/// To store state data for ChangePassWordPage
class ChangePassWordPageRefs {
  public doneRB: DoneRBState = new DoneRBState();
}

interface DoneRBWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: ChangePassWordPageRefs;
  _onPasswordSetHandler?: _DoneRBOnPressed;
}

class DoneRBState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _DoneRBWithState extends ObservableComponent<DoneRBWithStateProps> {
  doneRBFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DoneRBWithStateProps) {
    super(props);

    this.initState();
  }
  public get doneRB(): DoneRBState {
    return this.props.d3eState.doneRB;
  }
  public get d3eState(): ChangePassWordPageRefs {
    return this.props.d3eState;
  }
  public get _onPasswordSetHandler(): _DoneRBOnPressed {
    return this.props._onPasswordSetHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("doneRB", null, this.doneRB);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["doneRB", "doneRB.", "doneRB.hover"], this.rebuild);
  }
  public doneRBOnEnter(event): void {
    return this.doneRB.setHover(true);
  }
  public doneRBOnExit(event): void {
    return this.doneRB.setHover(false);
  }
  public dispose(): void {
    this.doneRB.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 0.0, 0.0, new Map()),
      child: Button({
        padding: this.doneRB.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.doneRB.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.doneRB.disable,
        onPressed: () => {
          this._onPasswordSetHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [TextView({ data: "Submit", className: "xc8c", key: "0" })],
          className: "xb9 hc h",
        }),
        onEnter: (event) => {
          this.doneRBOnEnter(event);
        },
        onExit: (event) => {
          this.doneRBOnExit(event);
        },
      }),
      className: "xb66 hc h",
    });
  }
}
function DoneRBWithState(props: DoneRBWithStateProps) {
  return React.createElement(_DoneRBWithState, props);
}

class _ChangePassWordPageState extends ObservableComponent<ChangePassWordPageProps> {
  static defaultProps = { user: null };
  d3eState: ChangePassWordPageRefs = new ChangePassWordPageRefs();
  newPassword: string = "";
  confirmPassword: string = "";
  isUpperCaseMissing: boolean = false;
  isLowerCaseMissing: boolean = false;
  isContainsSpace: boolean = false;
  isSpecialCharacterMissing: boolean = false;
  isNumberMissing: boolean = false;
  isNotMatchedPassword: boolean = false;
  isNotMinimumLengthPwd: boolean = false;
  errorMessages: Array<string> = ListWrapper.widget(this, "errorMessages");
  showErrors: boolean = false;
  showSuccess: boolean = false;
  showValidationError: boolean = false;
  showPasswordError: boolean = false;
  showConfirmPasswordError: boolean = false;
  showNewPasswordError: boolean = false;
  client: TwilioClient = null;
  data: CallData = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ChangePassWordPageProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(["newPassword"], this.computeIsUpperCaseMissing);

    this.computeIsUpperCaseMissing();

    this.on(["newPassword"], this.computeIsLowerCaseMissing);

    this.computeIsLowerCaseMissing();

    this.on(["newPassword"], this.computeIsContainsSpace);

    this.computeIsContainsSpace();

    this.on(["newPassword"], this.computeIsSpecialCharacterMissing);

    this.computeIsSpecialCharacterMissing();

    this.on(["newPassword"], this.computeIsNumberMissing);

    this.computeIsNumberMissing();

    this.on(["newPassword"], this.computeIsNotMinimumLengthPwd);

    this.computeIsNotMinimumLengthPwd();

    this.on(
      [
        "confirmPassword",
        "errorMessages",
        "isContainsSpace",
        "isLowerCaseMissing",
        "isNotMatchedPassword",
        "isNotMinimumLengthPwd",
        "isNumberMissing",
        "isSpecialCharacterMissing",
        "isUpperCaseMissing",
        "newPassword",
        "showConfirmPasswordError",
        "showErrors",
        "showNewPasswordError",
        "showPasswordError",
        "showSuccess",
        "showValidationError",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ChangePassWordPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public setNewPassword(val: string): void {
    let isValChanged: boolean = this.newPassword !== val;

    if (!isValChanged) {
      return;
    }

    this.newPassword = val;

    this.fire("newPassword", this);
  }
  public setConfirmPassword(val: string): void {
    let isValChanged: boolean = this.confirmPassword !== val;

    if (!isValChanged) {
      return;
    }

    this.confirmPassword = val;

    this.fire("confirmPassword", this);
  }
  public setIsUpperCaseMissing(val: boolean): void {
    let isValChanged: boolean = this.isUpperCaseMissing !== val;

    if (!isValChanged) {
      return;
    }

    this.isUpperCaseMissing = val;

    this.fire("isUpperCaseMissing", this);
  }
  public computeIsUpperCaseMissing = (): void => {
    try {
      this.setIsUpperCaseMissing(
        !new RegExp(".*[A-Z].*").hasMatch(this.newPassword)
      );
    } catch (exception) {
      console.log(
        " exception in computeIsUpperCaseMissing : " + exception.toString()
      );

      this.setIsUpperCaseMissing(false);
    }
  };
  public setIsLowerCaseMissing(val: boolean): void {
    let isValChanged: boolean = this.isLowerCaseMissing !== val;

    if (!isValChanged) {
      return;
    }

    this.isLowerCaseMissing = val;

    this.fire("isLowerCaseMissing", this);
  }
  public computeIsLowerCaseMissing = (): void => {
    try {
      this.setIsLowerCaseMissing(
        !new RegExp(".*[a-z].*").hasMatch(this.newPassword)
      );
    } catch (exception) {
      console.log(
        " exception in computeIsLowerCaseMissing : " + exception.toString()
      );

      this.setIsLowerCaseMissing(false);
    }
  };
  public setIsContainsSpace(val: boolean): void {
    let isValChanged: boolean = this.isContainsSpace !== val;

    if (!isValChanged) {
      return;
    }

    this.isContainsSpace = val;

    this.fire("isContainsSpace", this);
  }
  public computeIsContainsSpace = (): void => {
    try {
      this.setIsContainsSpace(this.newPassword.contains(" "));
    } catch (exception) {
      console.log(
        " exception in computeIsContainsSpace : " + exception.toString()
      );

      this.setIsContainsSpace(false);
    }
  };
  public setIsSpecialCharacterMissing(val: boolean): void {
    let isValChanged: boolean = this.isSpecialCharacterMissing !== val;

    if (!isValChanged) {
      return;
    }

    this.isSpecialCharacterMissing = val;

    this.fire("isSpecialCharacterMissing", this);
  }
  public computeIsSpecialCharacterMissing = (): void => {
    try {
      this.setIsSpecialCharacterMissing(
        !new RegExp(".*[!@#$%&*].*").hasMatch(this.newPassword)
      );
    } catch (exception) {
      console.log(
        " exception in computeIsSpecialCharacterMissing : " +
          exception.toString()
      );

      this.setIsSpecialCharacterMissing(false);
    }
  };
  public setIsNumberMissing(val: boolean): void {
    let isValChanged: boolean = this.isNumberMissing !== val;

    if (!isValChanged) {
      return;
    }

    this.isNumberMissing = val;

    this.fire("isNumberMissing", this);
  }
  public computeIsNumberMissing = (): void => {
    try {
      this.setIsNumberMissing(
        !new RegExp(".*[0-9].*").hasMatch(this.newPassword)
      );
    } catch (exception) {
      console.log(
        " exception in computeIsNumberMissing : " + exception.toString()
      );

      this.setIsNumberMissing(false);
    }
  };
  public setIsNotMatchedPassword(val: boolean): void {
    let isValChanged: boolean = this.isNotMatchedPassword !== val;

    if (!isValChanged) {
      return;
    }

    this.isNotMatchedPassword = val;

    this.fire("isNotMatchedPassword", this);
  }
  public setIsNotMinimumLengthPwd(val: boolean): void {
    let isValChanged: boolean = this.isNotMinimumLengthPwd !== val;

    if (!isValChanged) {
      return;
    }

    this.isNotMinimumLengthPwd = val;

    this.fire("isNotMinimumLengthPwd", this);
  }
  public computeIsNotMinimumLengthPwd = (): void => {
    try {
      this.setIsNotMinimumLengthPwd(!(this.newPassword.length >= 8));
    } catch (exception) {
      console.log(
        " exception in computeIsNotMinimumLengthPwd : " + exception.toString()
      );

      this.setIsNotMinimumLengthPwd(false);
    }
  };
  public setErrorMessages(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.errorMessages,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.errorMessages.clear();

    this.errorMessages.addAll(val);

    this.fire("errorMessages", this);
  }
  public addToErrorMessages(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errorMessages.contains(val)) this.errorMessages.add(val);
    } else {
      this.errorMessages.remove(this.errorMessages.elementAt(index));

      this.errorMessages.add(val);
    }

    this.fire("errorMessages", this, val, true);
  }
  public removeFromErrorMessages(val: string): void {
    this.errorMessages.remove(val);

    this.fire("errorMessages", this, val, false);
  }
  public setShowErrors(val: boolean): void {
    let isValChanged: boolean = this.showErrors !== val;

    if (!isValChanged) {
      return;
    }

    this.showErrors = val;

    this.fire("showErrors", this);
  }
  public setShowSuccess(val: boolean): void {
    let isValChanged: boolean = this.showSuccess !== val;

    if (!isValChanged) {
      return;
    }

    this.showSuccess = val;

    this.fire("showSuccess", this);
  }
  public setShowValidationError(val: boolean): void {
    let isValChanged: boolean = this.showValidationError !== val;

    if (!isValChanged) {
      return;
    }

    this.showValidationError = val;

    this.fire("showValidationError", this);
  }
  public setShowPasswordError(val: boolean): void {
    let isValChanged: boolean = this.showPasswordError !== val;

    if (!isValChanged) {
      return;
    }

    this.showPasswordError = val;

    this.fire("showPasswordError", this);
  }
  public setShowConfirmPasswordError(val: boolean): void {
    let isValChanged: boolean = this.showConfirmPasswordError !== val;

    if (!isValChanged) {
      return;
    }

    this.showConfirmPasswordError = val;

    this.fire("showConfirmPasswordError", this);
  }
  public setShowNewPasswordError(val: boolean): void {
    let isValChanged: boolean = this.showNewPasswordError !== val;

    if (!isValChanged) {
      return;
    }

    this.showNewPasswordError = val;

    this.fire("showNewPasswordError", this);
  }
  public setClient(val: TwilioClient): void {
    let isValChanged: boolean = this.client !== val;

    if (!isValChanged) {
      return;
    }

    this.client = val;

    this.fire("client", this);
  }
  public setData(val: CallData): void {
    let isValChanged: boolean = this.data !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("data", this.data, val);

    this.data = val;

    this.fire("data", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Center({
      child: ui.Column({
        crossAxisAlignment: ui.CrossAxisAlignment.center,
        mainAxisAlignment: ui.MainAxisAlignment.center,
        children: [
          ui.Column({
            mainAxisAlignment: ui.MainAxisAlignment.center,
            crossAxisAlignment: ui.CrossAxisAlignment.start,
            mainAxisSize: ui.MainAxisSize.min,
            children: [
              TextView({
                data: "Change Password",
                style: new ui.TextStyle({
                  fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                  fontWeight: cStyle.tTextViewHeadlineThreeFontWeightOn,
                }),
                className: "xd2 hc",
                key: "0",
              }),
              LabelWithInputField({
                name: "New Password",
                value: this.newPassword,
                placeHolder: "Enter New Password",
                obscureText: true,
                onTap: () => {
                  this.passwordErrorHandler(this.d3eState);
                },
                onChanged: (text) => {
                  this.newPasswordFieldWithLabelonChanged(text, this.d3eState);

                  this.newPasswordSetHandler(text, this.d3eState);
                },
                className: "x0a6 hc h",
                key: "1",
              }),
              ui.Column({
                crossAxisAlignment: ui.CrossAxisAlignment.start,
                children: [
                  TextView({
                    data: "Password Rules:",
                    style: new ui.TextStyle({
                      fontSize: cStyle.tTextViewHeadlineFiveFontSizeOn,
                      fontWeight: cStyle.tTextViewHeadlineFiveFontWeightOn,
                    }),
                    className: "x28 hc",
                    key: "0",
                  }),
                  ui.Row({
                    crossAxisAlignment: ui.CrossAxisAlignment.center,
                    children: [
                      !this.isNotMinimumLengthPwd
                        ? IconView({
                            icon: MaterialIcons.done,
                            size: 15,
                            color: cStyle.c1,
                            className: "xe8",
                          })
                        : [],
                      TextView({
                        data: "Password must be atleast 8 characters long.",
                        className: "xdbc",
                        key: "1",
                      }),
                    ],
                    className: "xadb hc h",
                    key: "1",
                  }),
                  ui.Row({
                    crossAxisAlignment: ui.CrossAxisAlignment.center,
                    children: [
                      !this.isUpperCaseMissing && !this.isLowerCaseMissing
                        ? IconView({
                            icon: MaterialIcons.done,
                            size: 15,
                            color: cStyle.c1,
                            className: "xe64",
                          })
                        : [],
                      TextView({
                        data: "Password must include 1 Uppercase and 1 Lowercase letter.",
                        className: "x59c",
                        key: "1",
                      }),
                    ],
                    className: "x30 hc h",
                    key: "2",
                  }),
                  ui.Row({
                    crossAxisAlignment: ui.CrossAxisAlignment.center,
                    children: [
                      !this.isNumberMissing
                        ? IconView({
                            icon: MaterialIcons.done,
                            size: 15,
                            color: cStyle.c1,
                            className: "x0af",
                          })
                        : [],
                      TextView({
                        data: "Password must contain 1 numeric character.",
                        className: "xd17",
                        key: "1",
                      }),
                    ],
                    className: "x165 hc h",
                    key: "3",
                  }),
                  ui.Row({
                    crossAxisAlignment: ui.CrossAxisAlignment.center,
                    children: [
                      !this.isSpecialCharacterMissing
                        ? IconView({
                            icon: MaterialIcons.done,
                            size: 15,
                            color: cStyle.c1,
                            className: "x0d6c",
                          })
                        : [],
                      TextView({
                        data: "Password must contain 1 special character.",
                        className: "x7fc",
                        key: "1",
                      }),
                    ],
                    className: "x56f hc h",
                    key: "4",
                  }),
                  ui.Row({
                    crossAxisAlignment: ui.CrossAxisAlignment.center,
                    children: [
                      !this.isContainsSpace && this.newPassword !== ""
                        ? IconView({
                            icon: MaterialIcons.done,
                            size: 15,
                            color: cStyle.c1,
                            className: "x08a",
                          })
                        : [],
                      TextView({
                        data: "Password should not contain any space.",
                        className: "x42",
                        key: "1",
                      }),
                    ],
                    className: "xea7 hc h",
                    key: "5",
                  }),
                ],
                className: "xef hc",
                key: "2",
              }),
              LabelWithInputField({
                name: "Confirm Password",
                value: this.confirmPassword,
                placeHolder: "Enter Confirm Password",
                obscureText: true,
                onTap: () => {
                  this.confirmPasswordErrorHandler(this.d3eState);
                },
                onChanged: (text) => {
                  this.textFieldonChanged(text, this.d3eState);

                  this.confirmPasswordSetHandler(text, this.d3eState);
                },
                className: "x992 hc h",
                key: "3",
              }),
              this.showErrors
                ? this.errorMessages.expand((error) => [
                    TextView({
                      data: error,
                      style: new ui.TextStyle({
                        color: cStyle.tTextViewErrorLabelColorOn,
                        fontSize: cStyle.tTextViewErrorLabelFontSizeOn,
                      }),
                      className: "xeac hc",
                      key: error?.toString(),
                    }),
                  ])
                : [],
              this.isNotMatchedPassword
                ? TextView({
                    data: "Password and Confirm Password must match",
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorLabelColorOn,
                      fontSize: cStyle.tTextViewErrorLabelFontSizeOn,
                    }),
                    className: "x811 hc",
                  })
                : [],
              this.showValidationError
                ? TextView({
                    data: "Password does not meet pasword rules",
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorLabelColorOn,
                      fontSize: cStyle.tTextViewErrorLabelFontSizeOn,
                    }),
                    className: "xa13 hc",
                  })
                : [],
              this.showPasswordError
                ? TextView({
                    data: "Please enter new password and confirm password",
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorLabelColorOn,
                      fontSize: cStyle.tTextViewErrorLabelFontSizeOn,
                    }),
                    className: "x26 hc",
                  })
                : [],
              this.showNewPasswordError
                ? TextView({
                    data: "Please enter new password",
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorLabelColorOn,
                      fontSize: cStyle.tTextViewErrorLabelFontSizeOn,
                    }),
                    className: "xa8 hc",
                  })
                : [],
              this.showConfirmPasswordError
                ? TextView({
                    data: "Please enter  confirm password",
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorLabelColorOn,
                      fontSize: cStyle.tTextViewErrorLabelFontSizeOn,
                    }),
                    className: "x228 hc",
                  })
                : [],
              DoneRBWithState({
                d3eState: this.d3eState,
                _onPasswordSetHandler: this.onPasswordSetHandler,
                key: "10",
              }),
              this.showSuccess
                ? ui.Row({
                    children: [
                      TextView({
                        data: "Password updated successFully !",
                        className: "xf9",
                        key: "0",
                      }),
                    ],
                    className: "x7e2 hc",
                  })
                : [],
            ],
            className: "x953 hc h",
            key: "0",
          }),
        ],
        className: "x4fa hc vc v",
      }),
      className: ui.join(this.props.className, "ChangePassWordPage hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setClient(new TwilioClient(""));

    this.client.data = new CallData({ deviceReady: false });

    this.setData(this.client.data);
  };
  public onPasswordSetHandler = (d3eState: ChangePassWordPageRefs): void => {
    this.onChangeValidation();
  };
  public newPasswordSetHandler = (
    value: string,
    d3eState: ChangePassWordPageRefs
  ): void => {
    this.setIsNotMinimumLengthPwd(false);

    this.setNewPassword(value);
  };
  public confirmPasswordSetHandler = (
    value: string,
    d3eState: ChangePassWordPageRefs
  ): void => {
    this.setIsNotMatchedPassword(false);

    this.setConfirmPassword(value);
  };
  public onChangePassword = async (): Promise<void> => {
    this.setShowErrors(false);

    this.setShowPasswordError(false);

    this.setShowConfirmPasswordError(false);

    this.setShowSuccess(false);

    let result: Result<ChangePasswordRequest> = await new ChangePasswordRequest(
      { newPassword: this.newPassword }
    ).save();

    if (result.status === ResultStatus.Success) {
      this.setShowSuccess(true);

      this.navigator.pushRoutePage({ user: this.user });
    } else {
      this.setErrorMessages(result.errors);

      this.setShowErrors(true);

      // this.showValidationError = true;
    }
  };
  public onChangeValidation = (): void => {
    if (this.newPassword.contains(" ")) {
      this.setIsContainsSpace(true);
    } else if (
      this.newPassword.isNotEmpty &&
      this.confirmPassword.isNotEmpty &&
      this.newPassword !== this.confirmPassword
    ) {
      this.setIsNotMatchedPassword(true);
    } else if (
      this.newPassword.isNotEmpty &&
      this.confirmPassword.length <= 0
    ) {
      this.setShowConfirmPasswordError(true);
    } else if (
      this.confirmPassword.isNotEmpty &&
      this.newPassword.length <= 0
    ) {
      this.setShowNewPasswordError(true);
    } else if (
      this.newPassword.length <= 0 &&
      this.confirmPassword.length <= 0
    ) {
      this.setShowPasswordError(true);
    } else if (
      !(
        this.isNumberMissing ||
        this.isSpecialCharacterMissing ||
        this.isUpperCaseMissing ||
        this.isLowerCaseMissing ||
        this.isContainsSpace ||
        this.isNotMinimumLengthPwd
      )
    ) {
      this.onChangePassword();
    } else {
      this.setShowValidationError(true);
    }
  };
  public confirmPasswordErrorHandler = (
    d3eState: ChangePassWordPageRefs
  ): void => {
    this.setShowSuccess(false);

    this.setShowValidationError(false);

    this.setIsNotMatchedPassword(false);

    this.setShowPasswordError(false);

    this.setShowConfirmPasswordError(false);

    this.setShowNewPasswordError(false);
  };
  public passwordErrorHandler = (d3eState: ChangePassWordPageRefs): void => {
    this.setShowSuccess(false);

    this.setShowValidationError(false);

    this.setShowPasswordError(false);

    this.setShowConfirmPasswordError(false);

    this.setShowNewPasswordError(false);
  };
  public newPasswordFieldWithLabelonChanged = (
    val: string,
    d3eState: ChangePassWordPageRefs
  ): void => {
    this.setNewPassword(val);
  };
  public textFieldonChanged = (
    val: string,
    d3eState: ChangePassWordPageRefs
  ): void => {
    this.setConfirmPassword(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get doneRB() {
    return this.d3eState.doneRB;
  }
}
export default function ChangePassWordPage(props: ChangePassWordPageProps) {
  return React.createElement(_ChangePassWordPageState, {
    ..._ChangePassWordPageState.defaultProps,
    ...props,
  });
}
