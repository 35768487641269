import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import LabelDropdownPopup from "./LabelDropdownPopup";
import PopupTargetController from "./PopupTargetController";
import ListWrapper from "../utils/ListWrapper";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _LabelDropdownOnChanged<T> = (value: T) => void;

type _LabelDropdownBuilder<T> = (context: any, item: T) => ReactNode;

type _Id2OnTap<T> = (d3eState: LabelDropdownRefs<T>) => void;

type _Id2OnKey<T> = (
  focusNode: ui.FocusNode,
  event: ui.RawKeyEvent,
  d3eState: LabelDropdownRefs<T>
) => ui.KeyEventResult;

export interface LabelDropdownProps<T> extends BaseUIProps {
  key?: string;
  name?: string;
  isRequired?: boolean;
  value?: T;
  items: Array<T>;
  placeHolder?: string;
  errors?: Array<string>;
  disable?: boolean;
  width?: number;
  _itemsHash?: number;
  _errorsHash?: number;
  onChanged?: _LabelDropdownOnChanged<T>;
  builder?: _LabelDropdownBuilder<T>;
}
/// To store state data for LabelDropdown
class LabelDropdownRefs<T> {
  public id2: Id2State<T> = new Id2State();
}

interface Id2WithStateProps<T> extends BaseUIProps {
  key?: string;
  d3eState: LabelDropdownRefs<T>;
  builder: _LabelDropdownBuilder<T>;
  _onKey?: _Id2OnKey<T>;
  _onShowDropDown?: _Id2OnTap<T>;
  errors: Array<string>;
  isExists: boolean;
  placeHolder: string;
  popUpWidth: number;
  value: T;
}

class Id2State<T> extends ObjectObservable {
  private _focus: boolean = false;
  public get focus(): boolean {
    return this._focus;
  }
  public setFocus(val: boolean) {
    let isValChanged: boolean = this._focus !== val;

    if (!isValChanged) {
      return;
    }

    this._focus = val;

    this.fire("focus", this);
  }
}

class _Id2WithState<T> extends ObservableComponent<Id2WithStateProps<T>> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Id2WithStateProps<T>) {
    super(props);

    this.initState();
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get id2(): Id2State<T> {
    return this.props.d3eState.id2;
  }
  public get isExists(): boolean {
    return this.props.isExists;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get popUpWidth(): number {
    return this.props.popUpWidth;
  }
  public get value(): T {
    return this.props.value;
  }
  public get d3eState(): LabelDropdownRefs<T> {
    return this.props.d3eState;
  }
  public get builder(): _LabelDropdownBuilder<T> {
    return this.props.builder;
  }
  public get _onKey(): _Id2OnKey<T> {
    return this.props._onKey;
  }
  public get _onShowDropDown(): _Id2OnTap<T> {
    return this.props._onShowDropDown;
  }
  public initState() {
    super.initState();

    this.updateObservable("id2", null, this.id2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "errors",
        "id2",
        "id2.focus",
        "isExists",
        "placeHolder",
        "popUpWidth",
        "value",
      ],
      this.rebuild
    );
  }
  public id2OnFocusChange(val): void {
    return this.id2.setFocus(val);
  }
  public dispose(): void {
    this.id2.setFocus(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Focus({
      focusNode: new ui.FocusNode(),
      child: ui.Container({
        width: this.popUpWidth,
        states: ui.joinStates({ "data-c0": this.errors.isNotEmpty }, {}),
        decoration: this.errors.isNotEmpty
          ? new ui.BoxDecoration({
              border: ui.Border.all({
                color: new ui.Color(0xffc20f2f),
                width: 1.0,
              }),
            })
          : new ui.BoxDecoration({}),
        key: this.placeHolder,
        child: ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            this.isExists
              ? ui.Container({
                  child: this.props.builder(this.context, this.value),
                  className: "x28e hc vc h",
                })
              : TextView({
                  data:
                    this.placeHolder !== null
                      ? this.placeHolder
                      : "---Select---",
                  overflow: ui.TextOverflow.ellipsis,
                  style: new ui.TextStyle({ fontSize: 14 }),
                  className: "x7dd hc vc h",
                }),
            IconView({
              icon: MaterialIcons.arrow_drop_down,
              className: "vc",
              key: "1",
            }),
          ],
          className: "x495 hc vc h",
        }),
        onFocusKey: (focusNode, event) => {
          return this._onKey(focusNode, event, this.d3eState);
        },
        onTap: (e) => {
          e.stopPropagation();

          this._onShowDropDown(this.d3eState);
        },
        className: "xc62 hc vc",
      }),
      onFocusKey: (focusNode, event) => {
        return this._onKey(focusNode, event, this.d3eState);
      },
      onFocusChange: (val) => {
        this.id2OnFocusChange(val);
      },
    });
  }
}
function Id2WithState<T>(props: Id2WithStateProps<T>) {
  return React.createElement(_Id2WithState<T>, props);
}

class _LabelDropdownState<T> extends ObservableComponent<
  LabelDropdownProps<T>
> {
  static defaultProps = {
    name: "",
    isRequired: false,
    value: null,
    placeHolder: "",
    disable: false,
    width: 200.0,
    items: [],
    errors: [],
    onChanged: null,
  };
  d3eState: LabelDropdownRefs<T> = new LabelDropdownRefs();
  popUpWidth: number = 150.0;
  isExists: boolean = false;
  show: boolean = false;
  itemsPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public id3PopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: LabelDropdownProps<T>) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get value(): T {
    return this.props.value;
  }
  public get items(): Array<T> {
    return this.props.items;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get width(): number {
    return this.props.width;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.subscribeToList(this.errors, "errors");

    this.on(["items", "value"], this.computeIsExists);

    this.computeIsExists();

    this.on(
      [
        "errors",
        "isExists",
        "isRequired",
        "name",
        "placeHolder",
        "popUpWidth",
        "value",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LabelDropdownProps<T>): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.items !== this.props.items) {
      this.fire("items", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.width !== this.props.width) {
      this.fire("width", this);
    }
  }
  public setPopUpWidth(val: number): void {
    let isValChanged: boolean = this.popUpWidth !== val;

    if (!isValChanged) {
      return;
    }

    this.popUpWidth = val;

    this.fire("popUpWidth", this);
  }
  public setIsExists(val: boolean): void {
    let isValChanged: boolean = this.isExists !== val;

    if (!isValChanged) {
      return;
    }

    this.isExists = val;

    this.fire("isExists", this);
  }
  public computeIsExists = (): void => {
    try {
      this.setIsExists(this.items.any((element) => element === this.value));
    } catch (exception) {
      console.log(" exception in computeIsExists : " + exception.toString());

      this.setIsExists(false);
    }
  };
  public setShow(val: boolean): void {
    let isValChanged: boolean = this.show !== val;

    if (!isValChanged) {
      return;
    }

    this.show = val;

    this.fire("show", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      mainAxisAlignment: ui.MainAxisAlignment.start,
      crossAxisAlignment: cStyle.tColumnFieldStyleCrossAxisAlignmentOn,
      children: [
        this.name !== null && this.name.isNotEmpty
          ? ui.Row({
              children: [
                TextView({
                  data: this.name,
                  style: new ui.TextStyle({
                    color: cStyle.tTextViewLabelTextColorOn,
                    fontSize: cStyle.tTextViewLabelTextFontSizeOn,
                    fontWeight: cStyle.tTextViewLabelTextFontWeightOn,
                  }),
                  className: "x934",
                  key: "0",
                }),
                this.isRequired
                  ? TextView({
                      data: "*",
                      style: new ui.TextStyle({
                        color: new ui.Color(0xffc20f2f),
                        fontSize: cStyle.tTextViewLabelTextFontSizeOn,
                        fontWeight: cStyle.tTextViewLabelTextFontWeightOn,
                      }),
                      className: "x265",
                    })
                  : [],
              ],
              className: "xc48",
            })
          : [],
        ui.Container({
          alignment: ui.Alignment.centerLeft,
          child: Id2WithState({
            d3eState: this.d3eState,
            builder: this.props.builder,
            _onKey: this.onKey,
            _onShowDropDown: this.onShowDropDown,
            errors: this.errors,
            isExists: this.isExists,
            placeHolder: this.placeHolder,
            popUpWidth: this.popUpWidth,
            value: this.value,
          }),
          d3eRef: ui.LayoutAware((bounds, globalPos) => {
            this.onDropDownBoundsChanges(bounds, globalPos, this.d3eState);
          }, this.id3PopupTargetController.handleRef),
          className: "xe72",
          key: "1",
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    textAlign: cStyle.tTextViewErrorTextTextAlignOn,
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorTextColorOn,
                    }),
                    className: "x2f1",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "x904",
            })
          : [],
      ],
      className: ui.join(this.props.className, "LabelDropdown x033"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setPopUpWidth(this.width);
  };
  public onShowDropDown = (d3eState: LabelDropdownRefs<T>): void => {
    if (this.show) {
      this.hideItemsPopup();

      this.setShow(false);

      return;
    }

    if (this.items.isNotEmpty && !this.disable) {
      this.showItemsPopup({ autoClose: true });

      this.setShow(true);
    }
  };
  public onDropDownBoundsChanges = (
    bounds: ui.Rect,
    globalPos: ui.Offset,
    d3eState: LabelDropdownRefs<T>
  ): void => {
    // this.popUpWidth = bounds.width;
  };
  public onKey = (
    focusNode: ui.FocusNode,
    event: ui.RawKeyEvent,
    d3eState: LabelDropdownRefs<T>
  ): ui.KeyEventResult => {
    if (event instanceof ui.RawKeyDownEvent && !this.disable) {
      if (
        event.logicalKey === ui.LogicalKeyboardKey.enter ||
        event.logicalKey === ui.LogicalKeyboardKey.space ||
        event.logicalKey === ui.LogicalKeyboardKey.numpadEnter
      ) {
        if (this.items.isNotEmpty && !this.disable) {
          this.showItemsPopup({ autoClose: true });

          this.setShow(true);
        }

        return ui.KeyEventResult.handled;
      }

      if (event instanceof ui.RawKeyDownEvent) {
        if (event.logicalKey === ui.LogicalKeyboardKey.arrowDown) {
          this.onDownHandler();
        } else if (event.logicalKey === ui.LogicalKeyboardKey.arrowUp) {
          this.onUpHandler();
        }
      }
    }

    return ui.KeyEventResult.ignored;
  };
  public onDownHandler = (): void => {
    if (this.items.isNotEmpty && !this.disable) {
      let value: number = this.items.indexOf(
        this.value !== null ? this.value : this.items.first
      );

      let nextValue: number = value + 1;

      if (nextValue >= this.items.length) {
        return;
      }

      this.onChanged(this.items.elementAt(nextValue));
    }
  };
  public onUpHandler = (): void => {
    if (this.items.isNotEmpty && !this.disable) {
      let value: number = this.items.indexOf(
        this.value !== null ? this.value : this.items.first
      );

      let nextValue: number = value - 1;

      if (nextValue < 0) {
        return;
      }

      this.onChanged(this.items.elementAt(nextValue));
    }
  };
  public onChangeditemsPopupHandler = (value: any): void => {
    this.hideItemsPopup();

    this.setShow(false);

    this.onChanged(value);
  };
  public get onChanged(): _LabelDropdownOnChanged<T> {
    return this.props.onChanged;
  }
  public dispose(): void {
    this.itemsPopupPopup?.dispose();

    super.dispose();
  }
  public showItemsPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.itemsPopupPopup?.dispose();

    let target: ui.Rect = this.id3PopupTargetController.getTarget(this.context);

    this.itemsPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        constraints: new ui.BoxConstraints({ maxHeight: 230 }),
        width: this.popUpWidth,
        child: LabelDropdownPopup({
          items: this.items,
          currentItem: this.value,
          hoverColor: new ui.Color(0xffdcdcdc),
          onChanged: (value) => {
            this.onChangeditemsPopupHandler(value);
          },
          builder: this.props.builder,
        }),
        className: "x3f0 hc vc",
      }),
      target: target,
    });

    this.itemsPopupPopup.showPopup(this.context);
  }
  public hideItemsPopup(): void {
    this.itemsPopupPopup?.dispose();
  }
  public get id2() {
    return this.d3eState.id2;
  }
}
export default function LabelDropdown<T>(props: LabelDropdownProps<T>) {
  return React.createElement(
    _LabelDropdownState<T>,
    { ..._LabelDropdownState.defaultProps, ...props },
    ListWrapper.fromInput<T>(props.items, "items"),
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
