import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import AddNoteButtonWidget from "./AddNoteButtonWidget";
import ResultStatus from "../classes/ResultStatus";
import LabelWithInputField from "./LabelWithInputField";
import LeadStatus from "../classes/LeadStatus";
import LabelWithDoubleInputField from "./LabelWithDoubleInputField";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import Result from "../classes/Result";
import ListWrapper from "../utils/ListWrapper";
import CheckServerErrors from "./CheckServerErrors";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import Chip from "./Chip";
import CollectionUtils from "../utils/CollectionUtils";
import CancelSaveButtonsWidget from "./CancelSaveButtonsWidget";
import { Runnable } from "../classes/core";
import { BuildContext } from "../classes/BuildContext";

type _CompanyInformationViewOnSubmit = () => void;

type _LabelWithInputFieldOnChanged = (text: string) => void;

type _LabelWithInputField2OnChanged = (text: string) => void;

type _LabelWithInputField3OnChanged = (text: string) => void;

type _LabelWithInputField4OnChanged = (text: string) => void;

type _LabelWithInputField5OnChanged = (text: string) => void;

type _LabelWithInputField8OnChanged = (text: string) => void;

type _LabelWithInputField6OnChanged = (text: string) => void;

type _LabelWithDoubleInputFieldOnChanged = (text: number) => void;

type _DecisionLabelWithInputField8OnChanged = (text: string) => void;

type _LabelWithInputField9OnChanged = (text: string) => void;

export interface CompanyInformationViewProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  client: TwilioClient;
  data: CallData;
  isNewObj: boolean;
  onSubmit?: _CompanyInformationViewOnSubmit;
}

class _CompanyInformationViewState extends ObservableComponent<CompanyInformationViewProps> {
  static defaultProps = {
    lead: null,
    client: null,
    data: null,
    isNewObj: false,
    onSubmit: null,
  };
  errors: Array<string> = ListWrapper.widget(this, "errors");
  user: User = null;
  position: string = "";
  skill: string = "";
  hackValue: boolean = false;
  private _editInitListeners: Array<Runnable> = [];
  private _d3eModelErrors: Array<string> = [];
  private _nameErrors: Array<string> = [];
  private _statusErrors: Array<string> = [];
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CompanyInformationViewProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public get isNewObj(): boolean {
    return this.props.isNewObj;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.updateSyncProperty("data", this.props.data);

    this.on(
      ["lead.leadAssignment", "lead.leadAssignment.assignedTo", "lead"],
      this.computeHandlingUser
    );

    this.computeHandlingUser();

    this.on(
      [
        "errors",
        "hackValue",
        "isNewObj",
        "lead",
        "lead.budget",
        "lead.company",
        "lead.companyRevenue",
        "lead.companySize",
        "lead.companyWebsite",
        "lead.decisionMakingRole",
        "lead.industry",
        "lead.preferredContactMethod",
        "lead.previousPositions",
        "lead.skills",
        "position",
        "skill",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: CompanyInformationViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }

    if (prevProps.isNewObj !== this.props.isNewObj) {
      this.fire("isNewObj", this);
    }
  }
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public setUser(val: User): void {
    let isValChanged: boolean = this.user !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("user", this.user, val);

    this.user = val;

    this.fire("user", this);
  }
  public setPosition(val: string): void {
    let isValChanged: boolean = this.position !== val;

    if (!isValChanged) {
      return;
    }

    this.position = val;

    this.fire("position", this);
  }
  public setSkill(val: string): void {
    let isValChanged: boolean = this.skill !== val;

    if (!isValChanged) {
      return;
    }

    this.skill = val;

    this.fire("skill", this);
  }
  public setHackValue(val: boolean): void {
    let isValChanged: boolean = this.hackValue !== val;

    if (!isValChanged) {
      return;
    }

    this.hackValue = val;

    this.fire("hackValue", this);
  }
  public get d3eModelErrors(): Array<string> {
    return this._d3eModelErrors ?? [];
  }
  public set d3eModelErrors(val: Array<string>) {
    this._d3eModelErrors.clear();

    this._d3eModelErrors.addAll(val);
  }
  public get nameErrors(): Array<string> {
    return this._nameErrors ?? [];
  }
  public set nameErrors(val: Array<string>) {
    this._nameErrors.clear();

    this._nameErrors.addAll(val);
  }
  public async computeNameErrors(): Promise<void> {
    this.nameErrors = [];

    let it: string = this.lead.name;

    if (it == null || it === "") {
      this.nameErrors.add("Name cannot be empty.");
    }

    this.fire("nameErrors", this);
  }
  public get statusErrors(): Array<string> {
    return this._statusErrors ?? [];
  }
  public set statusErrors(val: Array<string>) {
    this._statusErrors.clear();

    this._statusErrors.addAll(val);
  }
  public async computeStatusErrors(): Promise<void> {
    this.statusErrors = [];

    let it: LeadStatus = this.lead.status;

    this.fire("statusErrors", this);
  }
  public computeHandlingUser = (): void => {
    try {
      this.lead.setHandlingUser(
        this.lead.leadAssignment !== null
          ? this.lead.leadAssignment.assignedTo
          : null
      );
    } catch (exception) {
      console.log(
        " exception in computeHandlingUser : " + exception.toString()
      );

      this.lead.setHandlingUser(null);
    }
  };
  public setEditDefaults(): void {}
  public get isEditValid(): boolean {
    if (this.nameErrors.isNotEmpty) {
      return false;
    }

    if (this.statusErrors.isNotEmpty) {
      return false;
    }

    return true;
  }
  public validate(): Array<string> {
    let errors: Array<string> = [];

    this.computeNameErrors();

    errors.addAll(this.nameErrors);

    this.computeStatusErrors();

    errors.addAll(this.statusErrors);

    return errors;
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Column({
          children: [
            ui.Row({
              children: [
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "Company Name",
                    placeHolder: "Enter Name",
                    value: this.lead.company,
                    onChanged: (text) => {
                      this.labelWithInputFieldonChanged(text);
                    },
                  }),
                  key: "0",
                  className: "xaf7 hc h",
                }),
                ui.Container({
                  expand: true,
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 15.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: LabelWithInputField({
                    name: "Company Size",
                    placeHolder: "Enter Size",
                    value: this.lead.companySize,
                    onChanged: (text) => {
                      this.labelWithInputField2onChanged(text);
                    },
                  }),
                  key: "1",
                  className: "xa27 hc h",
                }),
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "Industry Name",
                    placeHolder: "Enter Industry Name",
                    value: this.lead.industry,
                    onChanged: (text) => {
                      this.labelWithInputField3onChanged(text);
                    },
                  }),
                  key: "2",
                  className: "x46 hc h",
                }),
              ],
              className: "x256 hc h",
              key: "0",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "Company Revenue",
                    placeHolder: "Enter Company Revenue",
                    value: this.lead.companyRevenue,
                    onChanged: (text) => {
                      this.labelWithInputField4onChanged(text);
                    },
                  }),
                  key: "0",
                  className: "x30b hc h",
                }),
                ui.Container({
                  expand: true,
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 15.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: LabelWithInputField({
                    name: "\u00A0Company Website",
                    placeHolder: "Enter Company Website",
                    value: this.lead.companyWebsite,
                    onChanged: (text) => {
                      this.labelWithInputField5onChanged(text);
                    },
                  }),
                  key: "1",
                  className: "x18a hc h",
                }),
                ui.Column({
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        ui.Column({
                          mainAxisAlignment: ui.MainAxisAlignment.start,
                          crossAxisAlignment: ui.CrossAxisAlignment.start,
                          children: [
                            LabelWithInputField({
                              name: "Skills",
                              placeHolder: "Add Skills",
                              value: this.skill,
                              onChanged: (text) => {
                                this.labelWithInputField8onChanged(text);
                              },
                              key: this.hackValue ? "0" : "",
                              className: "x07f hc h",
                            }),
                            ui.Wrap({
                              spacing: 5,
                              runSpacing: 5,
                              children: [
                                this.lead.skills.expand((skill) => [
                                  Chip({
                                    title: skill,
                                    onCancel: () => {
                                      this.onDeletePressed(this.skill);
                                    },
                                    className: "hc",
                                    key: skill?.toString(),
                                  }),
                                ]),
                              ],
                              className: "xf99 hc",
                              key: "1",
                            }),
                          ],
                          className: "x43 hc h",
                          key: "0",
                        }),
                        ui.Container({
                          margin: ui.EdgeInsets.fromLTRB(
                            0.0,
                            40.0,
                            0.0,
                            0.0,
                            new Map()
                          ),
                          child: AddNoteButtonWidget({
                            isAdded: false,
                            onAddNote: (isAdded) => {
                              this.addSkillButtonHandler(isAdded);
                            },
                            onRemoveNote: (isAdded) => {
                              this.removeSkillButtonHandler(isAdded);
                            },
                          }),
                          key: "1",
                          className: "x055",
                        }),
                      ],
                      className: "xe4a hc h",
                      key: "0",
                    }),
                  ],
                  className: "xbf hc h",
                  key: "2",
                }),
                ui.Column({
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Row({
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        ui.Column({
                          mainAxisAlignment: ui.MainAxisAlignment.start,
                          crossAxisAlignment: ui.CrossAxisAlignment.start,
                          children: [
                            LabelWithInputField({
                              name: "Previous Position ",
                              placeHolder: "Enter Previous Position ",
                              value: this.position,
                              onChanged: (text) => {
                                this.labelWithInputField6onChanged(text);
                              },
                              key: this.hackValue ? "0" : "",
                              className: "x491 hc h",
                            }),
                            ui.Wrap({
                              spacing: 5,
                              runSpacing: 5,
                              children: [
                                this.lead.previousPositions.expand(
                                  (position) => [
                                    Chip({
                                      title: position,
                                      onCancel: () => {
                                        this.onDeletePositionPressed(
                                          this.position
                                        );
                                      },
                                      className: "hc",
                                      key: position?.toString(),
                                    }),
                                  ]
                                ),
                              ],
                              className: "x421 hc",
                              key: "1",
                            }),
                          ],
                          className: "xe3c hc h",
                          key: "0",
                        }),
                        ui.Container({
                          margin: ui.EdgeInsets.fromLTRB(
                            0.0,
                            40.0,
                            0.0,
                            0.0,
                            new Map()
                          ),
                          child: AddNoteButtonWidget({
                            isAdded: false,
                            onAddNote: (isAdded) => {
                              this.addPositionButtonHandler(isAdded);
                            },
                          }),
                          key: "1",
                          className: "x42d",
                        }),
                      ],
                      className: "xe95 hc h",
                      key: "0",
                    }),
                  ],
                  className: "x0fa hc h",
                  key: "3",
                }),
              ],
              className: "xbb9 hc h",
              key: "1",
            }),
            ui.Row({
              children: [
                ui.Container({
                  expand: true,
                  child: LabelWithDoubleInputField({
                    name: "Budget",
                    placeHolder: "0",
                    value: this.lead.budget,
                    onChanged: (text) => {
                      this.labelWithDoubleInputFieldonChanged(text);
                    },
                  }),
                  key: "0",
                  className: "x058 hc h",
                }),
                ui.Container({
                  expand: true,
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 15.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: LabelWithInputField({
                    name: "Decision Making Role",
                    placeHolder: "Enter Role",
                    value: this.lead.decisionMakingRole,
                    onChanged: (text) => {
                      this.decisionLabelWithInputField8onChanged(text);
                    },
                  }),
                  key: "1",
                  className: "xaba hc h",
                }),
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "Preferred Contact Method",
                    placeHolder: "Enter Method",
                    value: this.lead.preferredContactMethod,
                    onChanged: (text) => {
                      this.labelWithInputField9onChanged(text);
                    },
                  }),
                  key: "2",
                  className: "x039 hc h",
                }),
              ],
              className: "xd1d hc h",
              key: "2",
            }),
          ],
          className: "xc20 hc h",
          key: "0",
        }),
        ui.Container({ className: "x229 hc vc", key: "1" }),
        this.errors.isNotEmpty
          ? CheckServerErrors({ errors: this.errors, className: "xb9b hc" })
          : [],
        CancelSaveButtonsWidget({
          title: "Next",
          isNewObj: this.isNewObj,
          onCancel: () => {
            this.onCancelHandler();
          },
          onSave: () => {
            this.onSaveHandler();
          },
          onSaveAndCancel: () => {
            this.saveAndCancelHandler();
          },
          className: "xccb hc h",
          key: "3",
        }),
      ],
      className: ui.join(
        this.props.className,
        "CompanyInformationView x4d4 hc h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onCancelHandler = (): void => {
    this.navigator.pushLeadManagementPage({
      user: this.user,
      replace: true,
      client: this.client,
      target: "main",
      data: this.data,
    });
  };
  public saveAndCancelHandler = async (): Promise<void> => {
    if (this.validate().isEmpty) {
      let result: Result<Lead> = await this.lead.save();

      if (result.status === ResultStatus.Success) {
      } else {
        this.setErrors(result.errors);
      }
    }

    this.navigator.pushLeadManagementPage({
      user: this.user,
      replace: true,
      client: this.client,
      target: "main",
      data: this.data,
    });
  };
  public addPositionButtonHandler = (isAdded: boolean): void => {
    this.setHackValue(!this.hackValue);

    if (this.position !== "") {
      this.lead.previousPositions.add(this.position);

      this.setPosition("");
    }
  };
  public onDeletePressed = (skill: string): void => {
    this.lead.skills.removeAt(this.lead.skills.length - 1);
  };
  public onDeletePositionPressed = (position: string): void => {
    this.lead.previousPositions.removeAt(
      this.lead.previousPositions.length - 1
    );
  };
  public addSkillButtonHandler = (isAdded: boolean): void => {
    this.setHackValue(!this.hackValue);

    if (this.skill !== "") {
      this.lead.skills.add(this.skill);

      this.setSkill("");
    }
  };
  public removeSkillButtonHandler = (isAdded: boolean): void => {
    this.lead.skills.removeAt(this.lead.skills.length - 1);
  };
  public onSaveHandler = async (): Promise<void> => {
    if (this.validate().isEmpty) {
      let result: Result<Lead> = await this.lead.save();

      if (result.status === ResultStatus.Success) {
      } else {
        this.setErrors(result.errors);
      }
    }

    if (this.onSubmit !== null) {
      this.onSubmit();
    }
  };
  public get onSubmit(): _CompanyInformationViewOnSubmit {
    return this.props.onSubmit;
  }
  public labelWithInputFieldonChanged = (val: string): void => {
    this.lead.setCompany(val);
  };
  public labelWithInputField2onChanged = (val: string): void => {
    this.lead.setCompanySize(val);
  };
  public labelWithInputField3onChanged = (val: string): void => {
    this.lead.setIndustry(val);
  };
  public labelWithInputField4onChanged = (val: string): void => {
    this.lead.setCompanyRevenue(val);
  };
  public labelWithInputField5onChanged = (val: string): void => {
    this.lead.setCompanyWebsite(val);
  };
  public labelWithInputField8onChanged = (val: string): void => {
    this.setSkill(val);
  };
  public labelWithInputField6onChanged = (val: string): void => {
    this.setPosition(val);
  };
  public labelWithDoubleInputFieldonChanged = (val: number): void => {
    this.lead.setBudget(val);
  };
  public decisionLabelWithInputField8onChanged = (val: string): void => {
    this.lead.setDecisionMakingRole(val);
  };
  public labelWithInputField9onChanged = (val: string): void => {
    this.lead.setPreferredContactMethod(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function CompanyInformationView(
  props: CompanyInformationViewProps
) {
  return React.createElement(_CompanyInformationViewState, {
    ..._CompanyInformationViewState.defaultProps,
    ...props,
  });
}
