import D3EDate from "../classes/D3EDate";
import DBObject from "../utils/DBObject";
import Result from "../classes/Result";
import CloneContext from "../utils/CloneContext";

export default class Education extends DBObject {
  private static readonly _DEGREE: number = 0;
  private static readonly _ENDDATE: number = 1;
  private static readonly _FIELDOFSTUDY: number = 2;
  private static readonly _INSTITUTION: number = 3;
  private static readonly _STARTDATE: number = 4;
  public id: number = 0;
  public otherMaster: DBObject;
  private _institution: string = "";
  private _degree: string = "";
  private _fieldOfStudy: string = "";
  private _startDate: D3EDate = null;
  private _endDate: D3EDate = null;
  public childPropertyInMaster: number = 0;
  public constructor(
    d3eParams?: Partial<{
      degree: string;
      endDate: D3EDate;
      fieldOfStudy: string;
      institution: string;
      startDate: D3EDate;
    }>
  ) {
    super();

    this.setDegree(d3eParams?.degree ?? "");

    this.setEndDate(d3eParams?.endDate ?? null);

    this.setFieldOfStudy(d3eParams?.fieldOfStudy ?? "");

    this.setInstitution(d3eParams?.institution ?? "");

    this.setStartDate(d3eParams?.startDate ?? null);
  }
  public get d3eType(): string {
    return "Education";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get institution(): string {
    return this._institution;
  }
  public setInstitution(val: string): void {
    let isValChanged: boolean = this._institution !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Education._INSTITUTION, this._institution);

    this._institution = val;

    this.fire("institution", this);
  }
  public get degree(): string {
    return this._degree;
  }
  public setDegree(val: string): void {
    let isValChanged: boolean = this._degree !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Education._DEGREE, this._degree);

    this._degree = val;

    this.fire("degree", this);
  }
  public get fieldOfStudy(): string {
    return this._fieldOfStudy;
  }
  public setFieldOfStudy(val: string): void {
    let isValChanged: boolean = this._fieldOfStudy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Education._FIELDOFSTUDY, this._fieldOfStudy);

    this._fieldOfStudy = val;

    this.fire("fieldOfStudy", this);
  }
  public get startDate(): D3EDate {
    return this._startDate;
  }
  public setStartDate(val: D3EDate): void {
    let isValChanged: boolean = this._startDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Education._STARTDATE, this._startDate);

    this._startDate = val;

    this.fire("startDate", this);
  }
  public get endDate(): D3EDate {
    return this._endDate;
  }
  public setEndDate(val: D3EDate): void {
    let isValChanged: boolean = this._endDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Education._ENDDATE, this._endDate);

    this._endDate = val;

    this.fire("endDate", this);
  }
  public get(field: number): any {
    switch (field) {
      case Education._INSTITUTION: {
        return this._institution;
      }

      case Education._DEGREE: {
        return this._degree;
      }

      case Education._FIELDOFSTUDY: {
        return this._fieldOfStudy;
      }

      case Education._STARTDATE: {
        return this._startDate;
      }

      case Education._ENDDATE: {
        return this._endDate;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);

    this.otherMaster?.updateChildChanges(this.childPropertyInMaster);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Education {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: Education = dbObj as Education;

    obj.id = this.id;

    obj.setInstitution(this._institution);

    obj.setDegree(this._degree);

    obj.setFieldOfStudy(this._fieldOfStudy);

    obj.setStartDate(this._startDate);

    obj.setEndDate(this._endDate);
  }
  public async save(): Promise<Result<Education>> {
    if (this.otherMaster != null) {
      return this.otherMaster.save();
    }

    return new Result();
  }
  public async delete(): Promise<Result<Education>> {
    if (this.otherMaster != null) {
      return this.otherMaster.delete();
    }

    return new Result();
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Education._INSTITUTION: {
        this.setInstitution(value as string);
        break;
      }

      case Education._DEGREE: {
        this.setDegree(value as string);
        break;
      }

      case Education._FIELDOFSTUDY: {
        this.setFieldOfStudy(value as string);
        break;
      }

      case Education._STARTDATE: {
        this.setStartDate(value as D3EDate);
        break;
      }

      case Education._ENDDATE: {
        this.setEndDate(value as D3EDate);
        break;
      }
    }
  }
}
