export default class InputFieldType {
  public static readonly Rectangle = new InputFieldType(
    "Rectangle",
    "Rectangle",
    0
  );
  public static readonly Circle = new InputFieldType("Circle", "Circle", 1);
  public static readonly Underline = new InputFieldType(
    "Underline",
    "Underline",
    2
  );
  public static readonly None = new InputFieldType("None", "None", 3);
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): InputFieldType[] {
    return [
      InputFieldType.Rectangle,
      InputFieldType.Circle,
      InputFieldType.Underline,
      InputFieldType.None,
    ];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
