import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallData from "../classes/CallData";
import FileToUpload from "../classes/FileToUpload";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import IconButton from "./IconButton";
import SortedHeader from "./SortedHeader";
import Result from "../classes/Result";
import PaginationView from "./PaginationView";
import Query from "../classes/Query";
import LeadsAssignedList from "../classes/LeadsAssignedList";
import SearchComponent from "./SearchComponent";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import LeadImportFile from "../models/LeadImportFile";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import DFile from "../classes/DFile";
import CollectionUtils from "../utils/CollectionUtils";
import FileUploadResult from "../classes/FileUploadResult";
import LeadsAssignedListRequest from "../models/LeadsAssignedListRequest";
import SuccessMessage from "../classes/SuccessMessage";
import LeadStatus from "../classes/LeadStatus";
import EventBus from "../utils/EventBus";
import CheckboxWithText from "./CheckboxWithText";
import PlatformClient from "../classes/PlatformClient";
import Browser from "../classes/Browser";
import ScrollView2 from "./ScrollView2";
import RPCServices from "../rpc/RPCServices";
import IconView from "./IconView";
import Divider from "./Divider";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _ImportButtonOnPressed = (d3eState: LeadManagementViewRefs) => void;

type _AddLeadsButtonOnPressed = (d3eState: LeadManagementViewRefs) => void;

export interface LeadManagementViewProps extends BaseUIProps {
  key?: string;
  user: User;
  client: TwilioClient;
  data: CallData;
}
/// To store state data for LeadManagementView
class LeadManagementViewRefs {
  public addLeadsButton: AddLeadsButtonState = new AddLeadsButtonState();
  columnScrollController: ui.ScrollController = new ui.ScrollController();
  public importButton: ImportButtonState = new ImportButtonState();
}

interface AddLeadsButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: LeadManagementViewRefs;
  _onAddLeadsButtonHandler?: _AddLeadsButtonOnPressed;
}

class AddLeadsButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _AddLeadsButtonWithState extends ObservableComponent<AddLeadsButtonWithStateProps> {
  addLeadsButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AddLeadsButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get addLeadsButton(): AddLeadsButtonState {
    return this.props.d3eState.addLeadsButton;
  }
  public get d3eState(): LeadManagementViewRefs {
    return this.props.d3eState;
  }
  public get _onAddLeadsButtonHandler(): _AddLeadsButtonOnPressed {
    return this.props._onAddLeadsButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("addLeadsButton", null, this.addLeadsButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["addLeadsButton", "addLeadsButton.", "addLeadsButton.hover"],
      this.rebuild
    );
  }
  public addLeadsButtonOnEnter(event): void {
    return this.addLeadsButton.setHover(true);
  }
  public addLeadsButtonOnExit(event): void {
    return this.addLeadsButton.setHover(false);
  }
  public dispose(): void {
    this.addLeadsButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.addLeadsButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.addLeadsButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.addLeadsButton.disable,
        onPressed: () => {
          this._onAddLeadsButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Add Leads" }),
        onEnter: (event) => {
          this.addLeadsButtonOnEnter(event);
        },
        onExit: (event) => {
          this.addLeadsButtonOnExit(event);
        },
      }),
      className: "x28c5",
    });
  }
}
function AddLeadsButtonWithState(props: AddLeadsButtonWithStateProps) {
  return React.createElement(_AddLeadsButtonWithState, props);
}

interface ImportButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: LeadManagementViewRefs;
  _onImportButtonHandler?: _ImportButtonOnPressed;
}

class ImportButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ImportButtonWithState extends ObservableComponent<ImportButtonWithStateProps> {
  importButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ImportButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get importButton(): ImportButtonState {
    return this.props.d3eState.importButton;
  }
  public get d3eState(): LeadManagementViewRefs {
    return this.props.d3eState;
  }
  public get _onImportButtonHandler(): _ImportButtonOnPressed {
    return this.props._onImportButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("importButton", null, this.importButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["importButton", "importButton.", "importButton.hover"],
      this.rebuild
    );
  }
  public importButtonOnEnter(event): void {
    return this.importButton.setHover(true);
  }
  public importButtonOnExit(event): void {
    return this.importButton.setHover(false);
  }
  public dispose(): void {
    this.importButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.importButton.hover
          ? cStyle.tButtonLightPaddingOnHover
          : cStyle.tButtonLightPaddingOn,
        decoration: this.importButton.hover
          ? cStyle.tButtonLightDecorationOnHover
          : cStyle.tButtonLightDecorationOn,
        disable: this.importButton.disable,
        onPressed: () => {
          this._onImportButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          children: [
            IconView({
              size: 18,
              icon: MaterialIcons.insert_drive_file,
              className: "x8c3",
              key: "0",
            }),
            TextView({ data: "Import", className: "x1ad3", key: "1" }),
          ],
        }),
        onEnter: (event) => {
          this.importButtonOnEnter(event);
        },
        onExit: (event) => {
          this.importButtonOnExit(event);
        },
      }),
      className: "x73b",
    });
  }
}
function ImportButtonWithState(props: ImportButtonWithStateProps) {
  return React.createElement(_ImportButtonWithState, props);
}

class _LeadManagementViewState extends ObservableComponent<LeadManagementViewProps> {
  static defaultProps = { user: null, client: null, data: null };
  d3eState: LeadManagementViewRefs = new LeadManagementViewRefs();
  request: LeadsAssignedListRequest = null;
  leadsList: LeadsAssignedList = null;
  leads: Array<Lead> = ListWrapper.widget(this, "leads");
  searchText: string = "";
  lead: Lead = null;
  uploaded: boolean = false;
  mousePointerOn: boolean = false;
  isAssigned: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LeadManagementViewProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.on(
      [
        "request",
        "request.applyStatus",
        "request.ascending",
        "request.offset",
        "request.orderBy",
        "request.pageSize",
        "request.searchValue",
        "request.status",
        "request.user",
      ],
      this.computeLeadsList
    );

    this.computeLeadsList();

    this.on(["leadsList", "leadsList.items"], this.computeLeads);

    this.computeLeads();

    this.updateSyncProperty("user", this.props.user);

    this.on(["leads"], this.computeUploaded);

    this.computeUploaded();

    this.updateSyncProperty("data", this.props.data);

    this.on(
      [
        "isAssigned",
        "leads",
        "leads.company",
        "leads.email",
        "leads.leadAssignment",
        "leads.leadAssignment.assignedTo",
        "leads.leadAssignment.assignedTo.firstName",
        "leads.leadAssignment.assignedTo.lastName",
        "leads.name",
        "leads.phone",
        "leads.status",
        "leadsList",
        "leadsList.count",
        "request",
        "request.ascending",
        "request.offset",
        "request.orderBy",
        "request.pageSize",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LeadManagementViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public setRequest(val: LeadsAssignedListRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setLeadsList(val: LeadsAssignedList): void {
    let isValChanged: boolean = this.leadsList !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("leadsList", this.leadsList, val);

    this.leadsList = val;

    this.fire("leadsList", this);
  }
  public computeLeadsList = async (): Promise<void> => {
    try {
      this.setLeadsList(
        await Query.get().getLeadsAssignedList(
          UsageConstants.QUERY_GETLEADSASSIGNEDLIST_LEADMANAGEMENTVIEW_PROPERTIES_LEADSLIST_COMPUTATION,
          this.request
        )
      );
    } catch (exception) {
      console.log(" exception in computeLeadsList : " + exception.toString());

      this.setLeadsList(null);
    }
  };
  public setLeads(val: Array<Lead>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.leads, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("leads", this.leads, val);

    this.leads.clear();

    this.leads.addAll(val);

    this.fire("leads", this);
  }
  public addToLeads(val: Lead, index: number = -1): void {
    if (index === -1) {
      if (!this.leads.contains(val)) this.leads.add(val);
    } else {
      this.leads.remove(this.leads.elementAt(index));

      this.leads.add(val);
    }

    this.fire("leads", this, val, true);

    this.updateObservable("leads", null, val);
  }
  public removeFromLeads(val: Lead): void {
    this.leads.remove(val);

    this.fire("leads", this, val, false);

    this.removeObservable("leads", val);
  }
  public computeLeads = (): void => {
    try {
      this.setLeads(
        Array.from(this.leadsList.items.isNotEmpty ? this.leadsList.items : [])
      );
    } catch (exception) {
      console.log(" exception in computeLeads : " + exception.toString());

      this.setLeads([]);
    }
  };
  public setSearchText(val: string): void {
    let isValChanged: boolean = this.searchText !== val;

    if (!isValChanged) {
      return;
    }

    this.searchText = val;

    this.fire("searchText", this);
  }
  public setLead(val: Lead): void {
    let isValChanged: boolean = this.lead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("lead", this.lead, val);

    this.lead = val;

    this.fire("lead", this);
  }
  public setUploaded(val: boolean): void {
    let isValChanged: boolean = this.uploaded !== val;

    if (!isValChanged) {
      return;
    }

    this.uploaded = val;

    this.fire("uploaded", this);
  }
  public computeUploaded = (): void => {
    try {
      this.setUploaded(this.leads.isNotEmpty);
    } catch (exception) {
      console.log(" exception in computeUploaded : " + exception.toString());

      this.setUploaded(false);
    }
  };
  public setMousePointerOn(val: boolean): void {
    let isValChanged: boolean = this.mousePointerOn !== val;

    if (!isValChanged) {
      return;
    }

    this.mousePointerOn = val;

    this.fire("mousePointerOn", this);
  }
  public setIsAssigned(val: boolean): void {
    let isValChanged: boolean = this.isAssigned !== val;

    if (!isValChanged) {
      return;
    }

    this.isAssigned = val;

    this.fire("isAssigned", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Column({
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              children: [
                ui.Container({
                  width: 300,
                  child: SearchComponent({
                    placeholder: "Search",
                    onChanged: (value) => {
                      this.onSearchTapHandler(value, this.d3eState);
                    },
                  }),
                  key: "0",
                  className: "x939 hc",
                }),
                ui.Row({
                  children: [
                    ui.Container({
                      margin: ui.EdgeInsets.fromLTRB(
                        0.0,
                        0.0,
                        10.0,
                        0.0,
                        new Map()
                      ),
                      child: CheckboxWithText({
                        value: this.isAssigned,
                        title: "Get unassigned leads",
                        activeColor: cStyle.c1,
                        onChanged: (value) => {
                          this.onCheckboxWithTextOnChangedHandler(
                            value,
                            this.d3eState
                          );
                        },
                        states: ui.joinStates({ "data-visibility": false }, {}),
                        key: this.isAssigned.toString(),
                      }),
                      key: "0",
                      className: "x2dfe",
                      states: ui.joinStates({ "data-visibility": false }, {}),
                    }),
                    ImportButtonWithState({
                      d3eState: this.d3eState,
                      _onImportButtonHandler: this.onImportButtonHandler,
                      key: "1",
                    }),
                    ui.Container({
                      margin: ui.EdgeInsets.symmetric({
                        horizontal: 10.0,
                        vertical: 0.0,
                        transitions: new Map(),
                      }),
                      child: IconButton({
                        icon: MaterialIcons.info,
                        tooltip: "Download Sample CSV",
                        size: 18,
                        onPressed: () => {
                          this.onSampleCSVRefButtonHandler(this.d3eState);
                        },
                      }),
                      key: "2",
                      className: "x8cc",
                    }),
                    AddLeadsButtonWithState({
                      d3eState: this.d3eState,
                      _onAddLeadsButtonHandler: this.onAddLeadsButtonHandler,
                      key: "3",
                    }),
                  ],
                  key: "1",
                }),
              ],
              className: "xdb04 hc h",
              key: "0",
            }),
            ui.Container({
              height: 1,
              margin: ui.EdgeInsets.symmetric({
                horizontal: 0.0,
                vertical: 20.0,
                transitions: new Map(),
              }),
              child: Divider({}),
              key: "1",
              className: "xe07 hc vc h",
            }),
            ScrollView2({
              child: ui.Column({
                children: [
                  ui.Table({
                    defaultColumnWidth: new ui.FlexColumnWidth(1),
                    columnWidths: {
                      0: new ui.FlexColumnWidth(1),
                      1: new ui.FlexColumnWidth(1),
                      2: new ui.FlexColumnWidth(1),
                      3: new ui.FlexColumnWidth(1),
                      4: new ui.FlexColumnWidth(1),
                      5: new ui.FlexColumnWidth(1),
                      6: new ui.FixedColumnWidth(70),
                    },
                    children: [
                      ui.TableRow({
                        children: [
                          ui.TableCell({
                            child: SortedHeader({
                              name: "Name",
                              assending: this.request.ascending,
                              sorted: this.request.orderBy === "name",
                              onChanged: (value) => {
                                this.onNameSortedHeaderOnChangedHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                              className: "xaea hc h",
                            }),
                            className: "x0f2 hc h",
                            key: "0",
                          }),
                          ui.TableCell({
                            child: TextView({
                              data: "Company",
                              className: "hc",
                            }),
                            className: "x1e7 hc",
                            key: "1",
                          }),
                          ui.TableCell({
                            child: SortedHeader({
                              name: "Phone",
                              assending: this.request.ascending,
                              sorted: this.request.orderBy === "phone",
                              onChanged: (value) => {
                                this.onPhoneSortedHeaderOnChangedHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                              className: "x5be hc h",
                            }),
                            className: "x77b6 hc h",
                            key: "2",
                          }),
                          ui.TableCell({
                            child: SortedHeader({
                              name: "Email",
                              assending: this.request.ascending,
                              sorted: this.request.orderBy === "email",
                              onChanged: (value) => {
                                this.onEmailSortedHeaderOnChangedHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                              className: "x718 hc h",
                            }),
                            className: "x1268 hc h",
                            key: "3",
                          }),
                          ui.TableCell({
                            child: TextView({
                              data: "Assigned To",
                              className: "hc",
                            }),
                            className: "xd74 hc",
                            key: "4",
                          }),
                          ui.TableCell({
                            child: SortedHeader({
                              name: "Status",
                              assending: this.request.ascending,
                              sorted: this.request.orderBy === "status",
                              onChanged: (value) => {
                                this.onStatusSortedHeaderOnChangedHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                              className: "x869 hc h",
                            }),
                            className: "x257 hc h",
                            key: "5",
                          }),
                          ui.TableCell({
                            child: TextView({ data: "", className: "hc" }),
                            className: "xc42 hc",
                            key: "6",
                          }),
                        ],
                        className: "x873 hc h",
                        key: "0",
                      }),
                      this.leads.expand((forItem) => [
                        ui.TableRow({
                          children: [
                            ui.TableCell({
                              verticalAlignment:
                                ui.TableCellVerticalAlignment.middle,
                              child: TextView({
                                data: forItem.name,
                                className: "hc",
                              }),
                              className: "hc",
                              key: "0",
                            }),
                            ui.TableCell({
                              verticalAlignment:
                                ui.TableCellVerticalAlignment.middle,
                              child: TextView({
                                data: forItem.company,
                                className: "hc",
                              }),
                              className: "hc",
                              key: "1",
                            }),
                            ui.TableCell({
                              verticalAlignment:
                                ui.TableCellVerticalAlignment.middle,
                              child: TextView({
                                data: forItem.phone,
                                className: "hc",
                              }),
                              className: "hc",
                              key: "2",
                            }),
                            ui.TableCell({
                              verticalAlignment:
                                ui.TableCellVerticalAlignment.middle,
                              child: TextView({
                                data: forItem.email,
                                className: "hc",
                              }),
                              className: "hc",
                              key: "3",
                            }),
                            ui.TableCell({
                              verticalAlignment:
                                ui.TableCellVerticalAlignment.middle,
                              child: TextView({
                                data:
                                  (forItem.leadAssignment?.assignedTo
                                    ?.firstName ?? "") +
                                  " " +
                                  (forItem.leadAssignment?.assignedTo
                                    ?.lastName ?? ""),
                                className: "hc",
                              }),
                              className: "hc",
                              key: "4",
                            }),
                            ui.TableCell({
                              verticalAlignment:
                                ui.TableCellVerticalAlignment.middle,
                              child: ui.Row({
                                mainAxisAlignment: ui.MainAxisAlignment.start,
                                children: [
                                  ui.Container({
                                    alignment: ui.Alignment.center,
                                    states: ui.joinStates(
                                      {
                                        "data-c0":
                                          forItem.status.name === "New",
                                        "data-c1":
                                          forItem.status.name === "Inprogress",
                                        "data-c2":
                                          forItem.status.name === "Closed",
                                        "data-c3":
                                          forItem.status.name === "Contacted",
                                      },
                                      {}
                                    ),
                                    padding:
                                      forItem.status.name === "New"
                                        ? ui.EdgeInsets.symmetric({
                                            horizontal: 20.0,
                                            vertical: 5.0,
                                            transitions: new Map(),
                                          })
                                        : forItem.status.name === "Inprogress"
                                        ? ui.EdgeInsets.symmetric({
                                            horizontal: 20.0,
                                            vertical: 5.0,
                                            transitions: new Map(),
                                          })
                                        : forItem.status.name === "Closed"
                                        ? ui.EdgeInsets.symmetric({
                                            horizontal: 20.0,
                                            vertical: 5.0,
                                            transitions: new Map(),
                                          })
                                        : forItem.status.name === "Contacted"
                                        ? ui.EdgeInsets.symmetric({
                                            horizontal: 20.0,
                                            vertical: 5.0,
                                            transitions: new Map(),
                                          })
                                        : null,
                                    decoration:
                                      forItem.status.name === "New"
                                        ? new ui.BoxDecoration({
                                            color: cStyle.c4,
                                            borderRadius:
                                              ui.BorderRadius.circular(3.0),
                                          })
                                        : forItem.status.name === "Inprogress"
                                        ? new ui.BoxDecoration({
                                            color: cStyle.c5,
                                            borderRadius:
                                              ui.BorderRadius.circular(3.0),
                                          })
                                        : forItem.status.name === "Closed"
                                        ? new ui.BoxDecoration({
                                            color: cStyle.c7,
                                            borderRadius:
                                              ui.BorderRadius.circular(3.0),
                                          })
                                        : forItem.status.name === "Contacted"
                                        ? new ui.BoxDecoration({
                                            color: cStyle.c6,
                                            borderRadius:
                                              ui.BorderRadius.circular(3.0),
                                          })
                                        : new ui.BoxDecoration({}),
                                    child: TextView({
                                      data: forItem.status.name,
                                      style: new ui.TextStyle({
                                        color: cStyle.c14,
                                      }),
                                      className: "x8eb hc",
                                    }),
                                    className: "xb64 hc",
                                    key: "0",
                                  }),
                                  ui.Container({ key: "1" }),
                                ],
                                className: "xe08 hc h",
                              }),
                              className: "x9c7 hc h",
                              key: "5",
                            }),
                            ui.TableCell({
                              child: IconButton({
                                icon: MaterialIcons.edit,
                                tooltip: "Edit",
                                onPressed: () => {
                                  this.onLeadEditHandler(
                                    forItem,
                                    this.d3eState
                                  );
                                },
                                className: "hc",
                              }),
                              className: "hc",
                              key: "6",
                            }),
                          ],
                          onEnter: (event) => {
                            this.onTableRowGestureHandler2(
                              event,
                              forItem,
                              this.d3eState
                            );
                          },
                          onTap: (e) => {
                            e.stopPropagation();

                            this.onTableRowGestureHandler(
                              forItem,
                              this.d3eState
                            );
                          },
                          className: "x8c2 hc h",
                          key: forItem?.ident,
                        }),
                      ]),
                    ],
                    className: "x08a6 hc h",
                    key: "0",
                  }),
                ],
                key: "2",
              }),
              scrollDirection: ui.Axis.vertical,
              className: "x575 hc vc h v",
              controller: this.d3eState.columnScrollController,
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.end,
              children: [
                PaginationView({
                  pageSize: this.request.pageSize,
                  total: this.leadsList !== null ? this.leadsList.count : 0,
                  offset: this.request.offset,
                  onChange: (pageSize, offset) => {
                    this.onChangePaginationViewHandler(
                      pageSize,
                      offset,
                      this.d3eState
                    );
                  },
                  key: "0",
                }),
              ],
              className: "xb02 hc h",
              key: "3",
            }),
          ],
          className: "xfc5 hc vc h v",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "LeadManagementView xb9a hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(
      new LeadsAssignedListRequest({
        pageSize: 50,
        offset: 0,
        orderBy: "",
        ascending: true,
        user: this.user,
      })
    );

    this.setRequest(this.request.deepClone());
  };
  public onSearchTapHandler = (
    value: string,
    d3eState: LeadManagementViewRefs
  ): void => {
    this.setSearchText(value);

    this.request.setSearchValue(value);

    this.setRequest(this.request.deepClone());
  };
  public onChangePaginationViewHandler = (
    pageSize: number,
    offset: number,
    d3eState: LeadManagementViewRefs
  ): void => {
    this.request.setPageSize(pageSize);

    this.request.setOffset(offset);

    this.setRequest(this.request.deepClone());
  };
  public onAddLeadsButtonHandler = (d3eState: LeadManagementViewRefs): void => {
    this.setLead(new Lead());

    this.navigator.pushLeadInformationPage({
      user: this.user,
      lead: this.lead,
      replace: false,
      client: this.client,
      target: "main",
      data: this.data,
      isNewObj: true,
    });
  };
  public onTableRowGestureHandler = (
    forItem: Lead,
    d3eState: LeadManagementViewRefs
  ): void => {
    this.navigator.pushLeadOverviewPage({
      lead: forItem,
      user: this.user,
      client: this.client,
      data: this.data,
      replace: false,
      target: "main",
    });
  };
  public onLeadEditHandler = (
    forItem: Lead,
    d3eState: LeadManagementViewRefs
  ): void => {
    this.setLead(forItem);

    this.navigator.pushLeadInformationPage({
      user: this.user,
      lead: this.lead,
      replace: false,
      client: this.client,
      target: "main",
      data: this.data,
      isNewObj: false,
    });
  };
  public onTableRowGestureHandler2 = (
    event: ui.PointerEnterEvent,
    forItem: Lead,
    d3eState: LeadManagementViewRefs
  ): void => {
    this.setMousePointerOn(true);
  };
  public onSampleCSVRefButtonHandler = async (
    d3eState: LeadManagementViewRefs
  ): Promise<void> => {
    let file: DFile =
      await RPCServices.getExportFileUtils().createSampleCSVForLeadData();

    if (file !== null) {
      Browser.download(file);

      EventBus.get().fire(
        new SuccessMessage({ message: "Downloaded successfully." })
      );
    }
  };
  public onImportButtonHandler = async (
    d3eState: LeadManagementViewRefs
  ): Promise<void> => {
    let fileToUpload: FileToUpload = await Browser.selectFile(
      ["csv"],
      () => {}
    );

    if (fileToUpload !== null) {
      let res: FileUploadResult = await PlatformClient.upload(fileToUpload);

      if (res.success) {
        let file: LeadImportFile = new LeadImportFile({ file: res.file });

        let result: Result<LeadImportFile> = await file.save();

        EventBus.get().fire(
          new SuccessMessage({ message: "File uploaded successfully." })
        );

        this.setUploaded(true);
      } else {
        EventBus.get().fire(
          new FailureMessage({ message: "File upload Failed." })
        );
      }
    }
  };
  public onNameSortedHeaderOnChangedHandler = (
    value: boolean,
    d3eState: LeadManagementViewRefs
  ): void => {
    this.request.setOrderBy("name");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onPhoneSortedHeaderOnChangedHandler = (
    value: boolean,
    d3eState: LeadManagementViewRefs
  ): void => {
    this.request.setOrderBy("phone");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onEmailSortedHeaderOnChangedHandler = (
    value: boolean,
    d3eState: LeadManagementViewRefs
  ): void => {
    this.request.setOrderBy("email");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onStatusSortedHeaderOnChangedHandler = (
    value: boolean,
    d3eState: LeadManagementViewRefs
  ): void => {
    this.request.setOrderBy("status");

    this.request.setAscending(value);

    this.setRequest(this.request.deepClone());
  };
  public onCheckboxWithTextOnChangedHandler = (
    value: boolean,
    d3eState: LeadManagementViewRefs
  ): void => {
    this.setIsAssigned(value);

    this.request.setUser(this.isAssigned ? null : this.user);

    this.setRequest(this.request.deepClone());
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get addLeadsButton() {
    return this.d3eState.addLeadsButton;
  }
  public get importButton() {
    return this.d3eState.importButton;
  }
}
export default function LeadManagementView(props: LeadManagementViewProps) {
  return React.createElement(_LeadManagementViewState, {
    ..._LeadManagementViewState.defaultProps,
    ...props,
  });
}
