export default class CallStatus {
  public static readonly Initiated = new CallStatus(
    "Initiated",
    "Initiated",
    0
  );
  public static readonly InProgress = new CallStatus(
    "InProgress",
    "InProgress",
    1
  );
  public static readonly Ringing = new CallStatus("Ringing", "Ringing", 2);
  public static readonly Answered = new CallStatus("Answered", "Answered", 3);
  public static readonly Completed = new CallStatus(
    "Completed",
    "Completed",
    4
  );
  public static readonly Failed = new CallStatus("Failed", "Failed", 5);
  public static readonly Busy = new CallStatus("Busy", "Busy", 6);
  public static readonly Unanswered = new CallStatus(
    "Unanswered",
    "Unanswered",
    7
  );
  public static readonly Unknown = new CallStatus("Unknown", "Unknown", 8);
  public static readonly Connecting = new CallStatus(
    "Connecting",
    "Connecting",
    9
  );
  public static readonly Cancelled = new CallStatus(
    "Cancelled",
    "Cancelled",
    10
  );
  public static readonly Rejected = new CallStatus("Rejected", "Rejected", 11);
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): CallStatus[] {
    return [
      CallStatus.Initiated,
      CallStatus.InProgress,
      CallStatus.Ringing,
      CallStatus.Answered,
      CallStatus.Completed,
      CallStatus.Failed,
      CallStatus.Busy,
      CallStatus.Unanswered,
      CallStatus.Unknown,
      CallStatus.Connecting,
      CallStatus.Cancelled,
      CallStatus.Rejected,
    ];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
