import ListWrapper from "../utils/ListWrapper";
import DBObject from "../utils/DBObject";
import CollectionUtils from "../utils/CollectionUtils";

export default class ChartNumberFormat extends DBObject {
  private _id: number = DBObject.nextStructId();
  private static readonly _DECIMALSEPARATOR: number = 0;
  private static readonly _DECIMALS: number = 1;
  private static readonly _DEFAULTNUMBERSCALE: number = 2;
  private static readonly _FORCEDECIMALS: number = 3;
  private static readonly _FORCENUMBERSCALE: number = 4;
  private static readonly _FORMATNUMBER: number = 5;
  private static readonly _FORMATNUMBERSCALE: number = 6;
  private static readonly _MAXSCALERECURSION: number = 7;
  private static readonly _NUMBERPREFIX: number = 8;
  private static readonly _NUMBERSCALEUNITS: number = 9;
  private static readonly _NUMBERSCALEVALUE: number = 10;
  private static readonly _NUMBERSUFFIX: number = 11;
  private static readonly _SCALERECURSIVELY: number = 12;
  private static readonly _SCALESEPARATOR: number = 13;
  private static readonly _THOUSANDSEPARATOR: number = 14;
  private static readonly _THOUSANDSEPARATORPOSITION: number = 15;
  private _formatNumber: boolean = true;
  private _formatNumberScale: boolean = true;
  private _defaultNumberScale: string = "";
  private _numberScaleUnits: Array<string> = ListWrapper.primitive(
    this,
    "numberScaleUnits",
    ChartNumberFormat._NUMBERSCALEUNITS
  );
  private _numberScaleUnitsDefaultValue: Array<string> = ListWrapper.primitive(
    this,
    "numberScaleUnits",
    ChartNumberFormat._NUMBERSCALEUNITS
  );
  private _numberScaleValue: Array<number> = ListWrapper.primitive(
    this,
    "numberScaleValue",
    ChartNumberFormat._NUMBERSCALEVALUE
  );
  private _numberScaleValueDefaultValue: Array<number> = ListWrapper.primitive(
    this,
    "numberScaleValue",
    ChartNumberFormat._NUMBERSCALEVALUE
  );
  private _forceNumberScale: boolean = false;
  private _scaleRecursively: boolean = false;
  private _maxScaleRecursion: number = 0;
  private _scaleSeparator: string = " ";
  private _numberPrefix: string = "";
  private _numberSuffix: string = "";
  private _decimalSeparator: string = ".";
  private _thousandSeparator: string = ",";
  private _thousandSeparatorPosition: number = 3;
  private _decimals: number = 3;
  private _forceDecimals: boolean = false;
  public constructor(
    d3eParams?: Partial<{
      decimalSeparator: string;
      decimals: number;
      defaultNumberScale: string;
      forceDecimals: boolean;
      forceNumberScale: boolean;
      formatNumber: boolean;
      formatNumberScale: boolean;
      maxScaleRecursion: number;
      numberPrefix: string;
      numberScaleUnits: Array<string>;
      numberScaleValue: Array<number>;
      numberSuffix: string;
      scaleRecursively: boolean;
      scaleSeparator: string;
      thousandSeparator: string;
      thousandSeparatorPosition: number;
    }>
  ) {
    super();

    if (d3eParams?.decimalSeparator) {
      this.setDecimalSeparator(d3eParams?.decimalSeparator);
    }

    if (d3eParams?.decimals) {
      this.setDecimals(d3eParams?.decimals);
    }

    if (d3eParams?.defaultNumberScale) {
      this.setDefaultNumberScale(d3eParams?.defaultNumberScale);
    }

    if (d3eParams?.forceDecimals) {
      this.setForceDecimals(d3eParams?.forceDecimals);
    }

    if (d3eParams?.forceNumberScale) {
      this.setForceNumberScale(d3eParams?.forceNumberScale);
    }

    if (d3eParams?.formatNumber) {
      this.setFormatNumber(d3eParams?.formatNumber);
    }

    if (d3eParams?.formatNumberScale) {
      this.setFormatNumberScale(d3eParams?.formatNumberScale);
    }

    if (d3eParams?.maxScaleRecursion) {
      this.setMaxScaleRecursion(d3eParams?.maxScaleRecursion);
    }

    if (d3eParams?.numberPrefix) {
      this.setNumberPrefix(d3eParams?.numberPrefix);
    }

    if (d3eParams?.numberScaleUnits) {
      this.setNumberScaleUnits(d3eParams?.numberScaleUnits);
    }

    if (d3eParams?.numberScaleValue) {
      this.setNumberScaleValue(d3eParams?.numberScaleValue);
    }

    if (d3eParams?.numberSuffix) {
      this.setNumberSuffix(d3eParams?.numberSuffix);
    }

    if (d3eParams?.scaleRecursively) {
      this.setScaleRecursively(d3eParams?.scaleRecursively);
    }

    if (d3eParams?.scaleSeparator) {
      this.setScaleSeparator(d3eParams?.scaleSeparator);
    }

    if (d3eParams?.thousandSeparator) {
      this.setThousandSeparator(d3eParams?.thousandSeparator);
    }

    if (d3eParams?.thousandSeparatorPosition) {
      this.setThousandSeparatorPosition(d3eParams?.thousandSeparatorPosition);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "ChartNumberFormat";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();

    this.setDefaults();
  }
  public setDefaults(): void {
    this.setNumberScaleUnitsDefaultValue();

    this.setNumberScaleValueDefaultValue();
  }
  public get formatNumber(): boolean {
    return this._formatNumber;
  }
  public setFormatNumber(val: boolean): void {
    let isValChanged: boolean = this._formatNumber !== val;

    if (!isValChanged) {
      return;
    }

    this._formatNumber = val;

    this.fire("formatNumber", this);
  }
  public get formatNumberScale(): boolean {
    return this._formatNumberScale;
  }
  public setFormatNumberScale(val: boolean): void {
    let isValChanged: boolean = this._formatNumberScale !== val;

    if (!isValChanged) {
      return;
    }

    this._formatNumberScale = val;

    this.fire("formatNumberScale", this);
  }
  public get defaultNumberScale(): string {
    return this._defaultNumberScale;
  }
  public setDefaultNumberScale(val: string): void {
    let isValChanged: boolean = this._defaultNumberScale !== val;

    if (!isValChanged) {
      return;
    }

    this._defaultNumberScale = val;

    this.fire("defaultNumberScale", this);
  }
  public get numberScaleUnits(): Array<string> {
    return this._numberScaleUnits;
  }
  public setNumberScaleUnits(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._numberScaleUnits,
      val
    );

    if (!isValChanged) {
      return;
    }

    this._numberScaleUnits.clear();

    this._numberScaleUnits.addAll(val);

    this.fire("numberScaleUnits", this);
  }
  public addToNumberScaleUnits(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this._numberScaleUnits.contains(val))
        this._numberScaleUnits.add(val);
    } else {
      this._numberScaleUnits.remove(this._numberScaleUnits.elementAt(index));

      this._numberScaleUnits.add(val);
    }

    this.fire("numberScaleUnits", this, val, true);
  }
  public removeFromNumberScaleUnits(val: string): void {
    this._numberScaleUnits.remove(val);

    this.fire("numberScaleUnits", this, val, false);
  }
  public setNumberScaleUnitsDefaultValue = (force?: boolean): void => {
    try {
      let oldDefaultValue: Array<string> = this._numberScaleUnitsDefaultValue;

      this._numberScaleUnitsDefaultValue = ["K", "M", "B"];

      if (
        force ||
        CollectionUtils.isEquals(oldDefaultValue, this.numberScaleUnits)
      )
        this.setNumberScaleUnits(this._numberScaleUnitsDefaultValue);
    } catch (e) {
      this.setNumberScaleUnits([]);
    }
  };
  public get numberScaleValue(): Array<number> {
    return this._numberScaleValue;
  }
  public setNumberScaleValue(val: Array<number>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this._numberScaleValue,
      val
    );

    if (!isValChanged) {
      return;
    }

    this._numberScaleValue.clear();

    this._numberScaleValue.addAll(val);

    this.fire("numberScaleValue", this);
  }
  public addToNumberScaleValue(val: number, index: number = -1): void {
    if (index === -1) {
      if (!this._numberScaleValue.contains(val))
        this._numberScaleValue.add(val);
    } else {
      this._numberScaleValue.remove(this._numberScaleValue.elementAt(index));

      this._numberScaleValue.add(val);
    }

    this.fire("numberScaleValue", this, val, true);
  }
  public removeFromNumberScaleValue(val: number): void {
    this._numberScaleValue.remove(val);

    this.fire("numberScaleValue", this, val, false);
  }
  public setNumberScaleValueDefaultValue = (force?: boolean): void => {
    try {
      let oldDefaultValue: Array<number> = this._numberScaleValueDefaultValue;

      this._numberScaleValueDefaultValue = [1000.0, 1000.0, 1000.0];

      if (
        force ||
        CollectionUtils.isEquals(oldDefaultValue, this.numberScaleValue)
      )
        this.setNumberScaleValue(this._numberScaleValueDefaultValue);
    } catch (e) {
      this.setNumberScaleValue([]);
    }
  };
  public get forceNumberScale(): boolean {
    return this._forceNumberScale;
  }
  public setForceNumberScale(val: boolean): void {
    let isValChanged: boolean = this._forceNumberScale !== val;

    if (!isValChanged) {
      return;
    }

    this._forceNumberScale = val;

    this.fire("forceNumberScale", this);
  }
  public get scaleRecursively(): boolean {
    return this._scaleRecursively;
  }
  public setScaleRecursively(val: boolean): void {
    let isValChanged: boolean = this._scaleRecursively !== val;

    if (!isValChanged) {
      return;
    }

    this._scaleRecursively = val;

    this.fire("scaleRecursively", this);
  }
  public get maxScaleRecursion(): number {
    return this._maxScaleRecursion;
  }
  public setMaxScaleRecursion(val: number): void {
    let isValChanged: boolean = this._maxScaleRecursion !== val;

    if (!isValChanged) {
      return;
    }

    this._maxScaleRecursion = val;

    this.fire("maxScaleRecursion", this);
  }
  public get scaleSeparator(): string {
    return this._scaleSeparator;
  }
  public setScaleSeparator(val: string): void {
    let isValChanged: boolean = this._scaleSeparator !== val;

    if (!isValChanged) {
      return;
    }

    this._scaleSeparator = val;

    this.fire("scaleSeparator", this);
  }
  public get numberPrefix(): string {
    return this._numberPrefix;
  }
  public setNumberPrefix(val: string): void {
    let isValChanged: boolean = this._numberPrefix !== val;

    if (!isValChanged) {
      return;
    }

    this._numberPrefix = val;

    this.fire("numberPrefix", this);
  }
  public get numberSuffix(): string {
    return this._numberSuffix;
  }
  public setNumberSuffix(val: string): void {
    let isValChanged: boolean = this._numberSuffix !== val;

    if (!isValChanged) {
      return;
    }

    this._numberSuffix = val;

    this.fire("numberSuffix", this);
  }
  public get decimalSeparator(): string {
    return this._decimalSeparator;
  }
  public setDecimalSeparator(val: string): void {
    let isValChanged: boolean = this._decimalSeparator !== val;

    if (!isValChanged) {
      return;
    }

    this._decimalSeparator = val;

    this.fire("decimalSeparator", this);
  }
  public get thousandSeparator(): string {
    return this._thousandSeparator;
  }
  public setThousandSeparator(val: string): void {
    let isValChanged: boolean = this._thousandSeparator !== val;

    if (!isValChanged) {
      return;
    }

    this._thousandSeparator = val;

    this.fire("thousandSeparator", this);
  }
  public get thousandSeparatorPosition(): number {
    return this._thousandSeparatorPosition;
  }
  public setThousandSeparatorPosition(val: number): void {
    let isValChanged: boolean = this._thousandSeparatorPosition !== val;

    if (!isValChanged) {
      return;
    }

    this._thousandSeparatorPosition = val;

    this.fire("thousandSeparatorPosition", this);
  }
  public get decimals(): number {
    return this._decimals;
  }
  public setDecimals(val: number): void {
    let isValChanged: boolean = this._decimals !== val;

    if (!isValChanged) {
      return;
    }

    this._decimals = val;

    this.fire("decimals", this);
  }
  public get forceDecimals(): boolean {
    return this._forceDecimals;
  }
  public setForceDecimals(val: boolean): void {
    let isValChanged: boolean = this._forceDecimals !== val;

    if (!isValChanged) {
      return;
    }

    this._forceDecimals = val;

    this.fire("forceDecimals", this);
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof ChartNumberFormat &&
        this._formatNumber === other._formatNumber &&
        this._formatNumberScale === other._formatNumberScale &&
        this._defaultNumberScale === other._defaultNumberScale &&
        this._numberScaleUnits === other._numberScaleUnits &&
        this._numberScaleValue === other._numberScaleValue &&
        this._forceNumberScale === other._forceNumberScale &&
        this._scaleRecursively === other._scaleRecursively &&
        this._maxScaleRecursion === other._maxScaleRecursion &&
        this._scaleSeparator === other._scaleSeparator &&
        this._numberPrefix === other._numberPrefix &&
        this._numberSuffix === other._numberSuffix &&
        this._decimalSeparator === other._decimalSeparator &&
        this._thousandSeparator === other._thousandSeparator &&
        this._thousandSeparatorPosition === other._thousandSeparatorPosition &&
        this._decimals === other._decimals &&
        this._forceDecimals === other._forceDecimals)
    );
  }
  public get hashCode(): number {
    return (
      (this._formatNumber?.hashCode ?? 0) +
      (this._formatNumberScale?.hashCode ?? 0) +
      (this._defaultNumberScale?.hashCode ?? 0) +
      (this._numberScaleUnits?.hashCode ?? 0) +
      (this._numberScaleValue?.hashCode ?? 0) +
      (this._forceNumberScale?.hashCode ?? 0) +
      (this._scaleRecursively?.hashCode ?? 0) +
      (this._maxScaleRecursion?.hashCode ?? 0) +
      (this._scaleSeparator?.hashCode ?? 0) +
      (this._numberPrefix?.hashCode ?? 0) +
      (this._numberSuffix?.hashCode ?? 0) +
      (this._decimalSeparator?.hashCode ?? 0) +
      (this._thousandSeparator?.hashCode ?? 0) +
      (this._thousandSeparatorPosition?.hashCode ?? 0) +
      (this._decimals?.hashCode ?? 0) +
      (this._forceDecimals?.hashCode ?? 0)
    );
  }
}
