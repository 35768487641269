import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import TextView from "./TextView";
import LeadUtils from "../classes/LeadUtils";
import MailMessage from "../models/MailMessage";
import { BuildContext } from "../classes/BuildContext";

type _MailStatusViewOnMailClick = (email: MailMessage) => void;

type _EmailRowOnTap = (d3eState: MailStatusViewRefs) => void;

export interface MailStatusViewProps extends BaseUIProps {
  key?: string;
  mail: MailMessage;
  selectedEmail: MailMessage;
  onMailClick: _MailStatusViewOnMailClick;
}
/// To store state data for MailStatusView
class MailStatusViewRefs {
  public emailRow: EmailRowState = new EmailRowState();
}

interface EmailRowWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: MailStatusViewRefs;
  _onEmailRowHandler?: _EmailRowOnTap;
  mail: MailMessage;
  selectedEmail: MailMessage;
}

class EmailRowState extends ObjectObservable {
  private _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _EmailRowWithState extends ObservableComponent<EmailRowWithStateProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: EmailRowWithStateProps) {
    super(props);

    this.initState();
  }
  public get emailRow(): EmailRowState {
    return this.props.d3eState.emailRow;
  }
  public get mail(): MailMessage {
    return this.props.mail;
  }
  public get selectedEmail(): MailMessage {
    return this.props.selectedEmail;
  }
  public get d3eState(): MailStatusViewRefs {
    return this.props.d3eState;
  }
  public get _onEmailRowHandler(): _EmailRowOnTap {
    return this.props._onEmailRowHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("emailRow", null, this.emailRow);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("mail", this.props.mail);

    this.updateSyncProperty("selectedEmail", this.props.selectedEmail);

    this.on(
      [
        "emailRow",
        "emailRow.hover",
        "mail",
        "mail.body",
        "mail.receivedDateTime",
        "mail.recipientName",
        "mail.senderName",
        "mail.sentDateTime",
        "mail.subject",
        "selectedEmail",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: EmailRowWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.mail !== this.props.mail) {
      this.updateObservable("mail", prevProps.mail, this.props.mail);

      this.fire("mail", this);
    }

    if (prevProps.selectedEmail !== this.props.selectedEmail) {
      this.updateObservable(
        "selectedEmail",
        prevProps.selectedEmail,
        this.props.selectedEmail
      );

      this.fire("selectedEmail", this);
    }
  }
  public emailRowOnEnter(event): void {
    return this.emailRow.setHover(true);
  }
  public emailRowOnExit(event): void {
    return this.emailRow.setHover(false);
  }
  public dispose(): void {
    this.emailRow.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.start,
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      states: ui.joinStates(
        {
          "data-c0": this.emailRow.hover,
          "data-c1":
            this.selectedEmail !== null && this.mail === this.selectedEmail,
        },
        this.props.states
      ),
      children: [
        ui.Container({
          decoration: new ui.BoxDecoration({
            color: ui.HexColor.fromHexStr(
              LeadUtils.getColorForFirstLetter(
                this.mail.senderName !== null
                  ? this.mail.senderName
                  : this.mail.recipientName
              )
            ),
            borderRadius: ui.BorderRadius.circular(20.0),
          }),
          alignment: ui.Alignment.center,
          child: TextView({
            data:
              this.mail.senderName === null
                ? this.mail.recipientName.substring(0, 1)
                : this.mail.senderName.substring(0, 1),
            textAlign: ui.TextAlign.center,
            style: new ui.TextStyle({
              color: cStyle.c14,
              fontWeight: ui.FontWeight.bold,
            }),
            className: "x8b71 hc vc",
          }),
          className: "xcd25 hc vc",
          key: "0",
        }),
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            TextView({
              data:
                this.mail.senderName === null
                  ? this.mail.recipientName
                  : this.mail.senderName,
              className: "x4fee",
              key: "0",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                TextView({
                  data: this.mail.subject,
                  overflow: ui.TextOverflow.ellipsis,
                  className: "xe09b hc",
                  key: "0",
                }),
                TextView({
                  data: LeadUtils.formatDateTime(
                    this.mail.sentDateTime,
                    this.mail.receivedDateTime
                  ),
                  key: "1",
                }),
              ],
              className: "x653",
              key: "1",
            }),
            TextView({
              data: this.mail.body,
              overflow: ui.TextOverflow.ellipsis,
              style: new ui.TextStyle({ color: new ui.Color(0xff616161) }),
              className: "x38a hc",
              key: "2",
            }),
          ],
          key: "1",
        }),
      ],
      onEnter: (event) => {
        this.emailRowOnEnter(event);
      },
      onExit: (event) => {
        this.emailRowOnExit(event);
      },
      onTap: (e) => {
        e.stopPropagation();

        this._onEmailRowHandler(this.d3eState);
      },
      className: ui.join(this.props.className, "MailStatusView x6c7"),
      ...copyBaseUIProps(this.props),
    });
  }
}
function EmailRowWithState(props: EmailRowWithStateProps) {
  return React.createElement(_EmailRowWithState, props);
}

class _MailStatusViewState extends ObservableComponent<MailStatusViewProps> {
  static defaultProps = { mail: null, selectedEmail: null, onMailClick: null };
  d3eState: MailStatusViewRefs = new MailStatusViewRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: MailStatusViewProps) {
    super(props);

    this.initState();
  }
  public get mail(): MailMessage {
    return this.props.mail;
  }
  public get selectedEmail(): MailMessage {
    return this.props.selectedEmail;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("mail", this.props.mail);

    this.updateSyncProperty("selectedEmail", this.props.selectedEmail);

    this.on(["mail", "selectedEmail"], this.rebuild);
  }
  public componentDidUpdate(prevProps: MailStatusViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.mail !== this.props.mail) {
      this.updateObservable("mail", prevProps.mail, this.props.mail);

      this.fire("mail", this);
    }

    if (prevProps.selectedEmail !== this.props.selectedEmail) {
      this.updateObservable(
        "selectedEmail",
        prevProps.selectedEmail,
        this.props.selectedEmail
      );

      this.fire("selectedEmail", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return EmailRowWithState({
      d3eState: this.d3eState,
      _onEmailRowHandler: this.onEmailRowHandler,
      mail: this.mail,
      selectedEmail: this.selectedEmail,
      className: this.props.className,
      ...copyBaseUIProps(this.props),
    });
  }
  public onEmailRowHandler = (d3eState: MailStatusViewRefs): void => {
    this.onMailClick(this.mail);
  };
  public get onMailClick(): _MailStatusViewOnMailClick {
    return this.props.onMailClick;
  }
  public get emailRow() {
    return this.d3eState.emailRow;
  }
}
export default function MailStatusView(props: MailStatusViewProps) {
  return React.createElement(_MailStatusViewState, {
    ..._MailStatusViewState.defaultProps,
    ...props,
  });
}
