import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";
import Checkbox from "./Checkbox";

type _CheckboxWithTextOnChanged = (value: boolean) => void;

export interface CheckboxWithTextProps extends BaseUIProps {
  key?: string;
  value?: boolean;
  title: string;
  activeColor?: ui.Color;
  disable?: boolean;
  onChanged?: _CheckboxWithTextOnChanged;
}

class _CheckboxWithTextState extends ObservableComponent<CheckboxWithTextProps> {
  static defaultProps = {
    value: false,
    title: "",
    activeColor: null,
    disable: false,
    onChanged: null,
  };
  public constructor(props: CheckboxWithTextProps) {
    super(props);

    this.initState();
  }
  public get value(): boolean {
    return this.props.value;
  }
  public get title(): string {
    return this.props.title;
  }
  public get activeColor(): ui.Color {
    return this.props.activeColor;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["activeColor", "disable", "title", "value"], this.rebuild);
  }
  public componentDidUpdate(prevProps: CheckboxWithTextProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.title !== this.props.title) {
      this.fire("title", this);
    }

    if (prevProps.activeColor !== this.props.activeColor) {
      this.fire("activeColor", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }
  }
  public render(): ReactNode {
    return ui.Row({
      children: [
        Checkbox({
          value: this.value,
          disable: this.disable,
          activeColor: this.activeColor,
          onChanged: (val) => {
            this.onChangedLeftCheckBoxHandler(val);
          },
          key: "0",
        }),
        TextView({
          data: this.title,
          className: "x8b7",
          key: "1",
          onTap: (e) => {
            e.stopPropagation();

            this.onTap();
          },
        }),
      ],
      className: ui.join(this.props.className, "CheckboxWithText"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onChangedLeftCheckBoxHandler = (val: boolean): void => {
    if (this.onChanged !== null && !this.disable) {
      this.onChanged(val);
    }
  };
  public onTap = (): void => {
    if (this.onChanged !== null && !this.disable) {
      this.onChanged(!this.value);
    }
  };
  public get onChanged(): _CheckboxWithTextOnChanged {
    return this.props.onChanged;
  }
}
export default function CheckboxWithText(props: CheckboxWithTextProps) {
  return React.createElement(_CheckboxWithTextState, {
    ..._CheckboxWithTextState.defaultProps,
    ...props,
  });
}
