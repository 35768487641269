import React, { ReactElement, ReactNode } from "react";
import * as ui from "../native";
import { ContextData } from "./BuildContext";
import CallData from "./CallData";
import Company from "../models/Company";
import ThemeWrapper from "../components/ThemeWrapper";
import Lead from "../models/Lead";
import User from "../models/User";
import GlobalFunctions from "../utils/GlobalFunctions";
import TwilioClient from "./TwilioClient";
import { StyleThemeData } from "../components/ThemeWrapper";

export type RouterController = (node: ReactNode) => void;

const pageRouters: Map<string, RouterController> = new Map<
  string,
  RouterController
>();

export default class PageNavigator {
  public static defaultOverlay: ui.OverlayController;
  public static themeUpdateListener = () => {};
  public kIsWeb = true;
  public ctx: ContextData;
  public constructor(ctx: ContextData) {
    this.ctx = ctx;
  }
  public static addListener(target: string, listener: RouterController) {
    pageRouters.set(target, listener);
  }
  public static removeListener(target: string) {
    pageRouters.remove(target);
  }
  public static of(ctx: ContextData): PageNavigator {
    return new PageNavigator(ctx);
  }
  public wrapTheme(widget: ReactNode): ReactNode {
    let mediaQuery = ui.MediaQuery.of(this.ctx);

    return ThemeWrapper({ child: widget });
  }
  public push(
    replace: boolean,
    widget: ReactNode,
    title: string,
    path: string,
    target: string
  ): void {
    if (this.kIsWeb) {
      if (replace) {
        window.history.replaceState(null, title, path);
      } else {
        window.history.pushState(null, title, path);
      }
    }

    widget = this.wrapTheme(widget);

    if (!target) {
      target = "";
    }

    let listener = pageRouters.get(target);

    if (listener) {
      listener(widget);
    }
  }
  public pop(): void {
    this.close();
  }
  public close(): boolean {
    if (this.ctx.popup) {
      this.ctx.popup.hidePopup({});

      return true;
    } else {
      return false;
    }
  }
  public pushApolloLeadsPage(
    d3eParams?: Partial<{ replace: boolean; target: string; user: User }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    this.push(
      replace,
      GlobalFunctions.ApolloLeadsPage({ user: user }),
      "",
      "#apollo-leads-page",
      target
    );
  }
  public pushChangePassWordPage(
    d3eParams?: Partial<{ replace: boolean; target: string; user: User }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    this.push(
      replace,
      GlobalFunctions.ChangePassWordPage({ user: user }),
      "AdminChangePassWord",
      "#change-pass-word-page",
      target
    );
  }
  public pushConnectionsPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      user: User;
      client: TwilioClient;
      data: CallData;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    let client = d3eParams?.client;

    let data = d3eParams?.data;

    this.push(
      replace,
      GlobalFunctions.ConnectionsPage({
        user: user,
        client: client,
        data: data,
      }),
      "",
      "#connections-page",
      target
    );
  }
  public pushDashboardPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      user: User;
      client: TwilioClient;
      data: CallData;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    let client = d3eParams?.client;

    let data = d3eParams?.data;

    this.push(
      replace,
      GlobalFunctions.DashboardPage({ user: user, client: client, data: data }),
      "",
      "#dashboard page",
      target
    );
  }
  public pushForgotPasswordPage(
    d3eParams?: Partial<{ replace: boolean; target: string }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    this.push(
      replace,
      GlobalFunctions.ForgotPasswordPage({}),
      "",
      "#forgot password page",
      target
    );
  }
  public pushLandingPage(
    d3eParams?: Partial<{ replace: boolean; target: string }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    this.push(
      replace,
      GlobalFunctions.LandingPage({}),
      "",
      "#landing-page",
      target
    );
  }
  public pushLeadInformationPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      lead: Lead;
      user: User;
      client: TwilioClient;
      data: CallData;
      isNewObj: boolean;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let lead = d3eParams?.lead;

    let user = d3eParams?.user;

    let client = d3eParams?.client;

    let data = d3eParams?.data;

    let isNewObj = d3eParams?.isNewObj;

    this.push(
      replace,
      GlobalFunctions.LeadInformationPage({
        lead: lead,
        user: user,
        client: client,
        data: data,
        isNewObj: isNewObj,
      }),
      "",
      "#lead information page",
      target
    );
  }
  public pushLeadMailsPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      lead: Lead;
      user: User;
      client: TwilioClient;
      data: CallData;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let lead = d3eParams?.lead;

    let user = d3eParams?.user;

    let client = d3eParams?.client;

    let data = d3eParams?.data;

    this.push(
      replace,
      GlobalFunctions.LeadMailsPage({
        lead: lead,
        user: user,
        client: client,
        data: data,
      }),
      "",
      "#lead-mails-page",
      target
    );
  }
  public pushLeadManagementPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      user: User;
      client: TwilioClient;
      data: CallData;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    let client = d3eParams?.client;

    let data = d3eParams?.data;

    this.push(
      replace,
      GlobalFunctions.LeadManagementPage({
        user: user,
        client: client,
        data: data,
      }),
      "",
      "#lead management page",
      target
    );
  }
  public pushLeadOverviewPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      lead: Lead;
      user: User;
      client: TwilioClient;
      data: CallData;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let lead = d3eParams?.lead;

    let user = d3eParams?.user;

    let client = d3eParams?.client;

    let data = d3eParams?.data;

    this.push(
      replace,
      GlobalFunctions.LeadOverviewPage({
        lead: lead,
        user: user,
        client: client,
        data: data,
      }),
      "",
      "#lead-overview page",
      target
    );
  }
  public pushLoginPage(
    d3eParams?: Partial<{ replace: boolean; target: string }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    this.push(
      replace,
      GlobalFunctions.LoginPage({}),
      "",
      "#login page",
      target
    );
  }
  public pushMailsPage(
    d3eParams?: Partial<{ replace: boolean; target: string }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    this.push(
      replace,
      GlobalFunctions.MailsPage({}),
      "",
      "#mails-page",
      target
    );
  }
  public pushNewTeamPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      user: User;
      client: TwilioClient;
      data: CallData;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    let client = d3eParams?.client;

    let data = d3eParams?.data;

    this.push(
      replace,
      GlobalFunctions.NewTeamPage({ user: user, client: client, data: data }),
      "",
      "#new team page",
      target
    );
  }
  public pushRoutePage(
    d3eParams?: Partial<{ replace: boolean; target: string; user: User }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    this.push(
      replace,
      GlobalFunctions.RoutePage({ user: user }),
      "",
      "#route-page",
      target
    );
  }
  public pushSalesDashboardPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      user: User;
      client: TwilioClient;
      data: CallData;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    let client = d3eParams?.client;

    let data = d3eParams?.data;

    this.push(
      replace,
      GlobalFunctions.SalesDashboardPage({
        user: user,
        client: client,
        data: data,
      }),
      "",
      "#sales-dashboard-page",
      target
    );
  }
  public pushSettingsPage(
    d3eParams?: Partial<{ replace: boolean; target: string; company: Company }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let company = d3eParams?.company;

    this.push(
      replace,
      GlobalFunctions.SettingsPage({ company: company }),
      "",
      "#settings-page",
      target
    );
  }
  public pushTeamOverviewPage(
    d3eParams?: Partial<{
      replace: boolean;
      target: string;
      user: User;
      client: TwilioClient;
      data: CallData;
    }>
  ): void {
    let replace = d3eParams?.replace ?? true;

    let target = d3eParams?.target;

    let user = d3eParams?.user;

    let client = d3eParams?.client;

    let data = d3eParams?.data;

    this.push(
      replace,
      GlobalFunctions.TeamOverviewPage({
        user: user,
        client: client,
        data: data,
      }),
      "",
      "#team overview page",
      target
    );
  }
  public applyTheme(d3eParams?: Partial<{ theme: string }>): void {
    let theme = d3eParams?.theme;

    /*
TODO
*/

    switch (theme) {
      case "LeadMgtTheme": {
        StyleThemeData.current = StyleThemeData.createLeadMgtTheme();

        PageNavigator.themeUpdateListener();

        /*
TODO ThemeBuilder.of(this.ctx).rebuild();
*/
        break;
      }
      default: {
        StyleThemeData.current = StyleThemeData.createLeadMgtTheme();

        PageNavigator.themeUpdateListener();

        /*
TODO ThemeBuilder.of(this.ctx).rebuild();
*/
      }
    }
  }
  public getProjectThemeNames(): string[] {
    return ["LeadMgtTheme"];
  }
  public getCurrentThemeName(): string {
    return StyleThemeData.current.themeName;
  }
}
