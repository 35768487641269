import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _TextValueWithButtonOnOpen = () => void;

type _LinkButtonOnPressed = (d3eState: TextValueWithButtonRefs) => void;

export interface TextValueWithButtonProps extends BaseUIProps {
  key?: string;
  label: string;
  value: string;
  onOpen: _TextValueWithButtonOnOpen;
}
/// To store state data for TextValueWithButton
class TextValueWithButtonRefs {
  public linkButton: LinkButtonState = new LinkButtonState();
}

interface LinkButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: TextValueWithButtonRefs;
  _onLinkButtonHandle?: _LinkButtonOnPressed;
  value: string;
}

class LinkButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _LinkButtonWithState extends ObservableComponent<LinkButtonWithStateProps> {
  linkButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LinkButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get linkButton(): LinkButtonState {
    return this.props.d3eState.linkButton;
  }
  public get value(): string {
    return this.props.value;
  }
  public get d3eState(): TextValueWithButtonRefs {
    return this.props.d3eState;
  }
  public get _onLinkButtonHandle(): _LinkButtonOnPressed {
    return this.props._onLinkButtonHandle;
  }
  public initState() {
    super.initState();

    this.updateObservable("linkButton", null, this.linkButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["linkButton", "linkButton.", "linkButton.hover", "value"],
      this.rebuild
    );
  }
  public linkButtonOnEnter(event): void {
    return this.linkButton.setHover(true);
  }
  public linkButtonOnExit(event): void {
    return this.linkButton.setHover(false);
  }
  public dispose(): void {
    this.linkButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: ui.EdgeInsets.zero,
        disable: this.linkButton.disable,
        onPressed: () => {
          this._onLinkButtonHandle(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: this.value,
          softWrap: true,
          style: new ui.TextStyle({
            fontSize: 14,
            fontWeight: ui.FontWeight.w400,
          }),
          className: "xf75 hc",
        }),
        onEnter: (event) => {
          this.linkButtonOnEnter(event);
        },
        onExit: (event) => {
          this.linkButtonOnExit(event);
        },
      }),
      className: "x21bf hc h",
    });
  }
}
function LinkButtonWithState(props: LinkButtonWithStateProps) {
  return React.createElement(_LinkButtonWithState, props);
}

class _TextValueWithButtonState extends ObservableComponent<TextValueWithButtonProps> {
  static defaultProps = { label: "", value: "", onOpen: null };
  d3eState: TextValueWithButtonRefs = new TextValueWithButtonRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: TextValueWithButtonProps) {
    super(props);

    this.initState();
  }
  public get label(): string {
    return this.props.label;
  }
  public get value(): string {
    return this.props.value;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["label", "value"], this.rebuild);
  }
  public componentDidUpdate(prevProps: TextValueWithButtonProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.label !== this.props.label) {
      this.fire("label", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.start,
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        TextView({ data: this.label, className: "x59a hc", key: "0" }),
        TextView({ data: ":", className: "x6b5", key: "1" }),
        LinkButtonWithState({
          d3eState: this.d3eState,
          _onLinkButtonHandle: this.onLinkButtonHandle,
          value: this.value,
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "TextValueWithButton x1a1"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onLinkButtonHandle = (d3eState: TextValueWithButtonRefs): void => {
    this.onOpen();
  };
  public get onOpen(): _TextValueWithButtonOnOpen {
    return this.props.onOpen;
  }
  public get linkButton() {
    return this.d3eState.linkButton;
  }
}
export default function TextValueWithButton(props: TextValueWithButtonProps) {
  return React.createElement(_TextValueWithButtonState, {
    ..._TextValueWithButtonState.defaultProps,
    ...props,
  });
}
