import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import DateCell from "./DateCell";
import Range from "../classes/Range";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import D3EDate from "../classes/D3EDate";
import MaterialIcons from "../icons/MaterialIcons";
import MonthOrYearCell from "./MonthOrYearCell";
import TextView from "./TextView";
import CalenderUtils from "../classes/CalenderUtils";
import CollectionUtils from "../utils/CollectionUtils";
import IconView from "./IconView";

type _CalenderViewOnDateSelected = (date: D3EDate) => void;

type _LeftYearIconOnPressed = (d3eState: CalenderViewRefs) => void;

type _RightYearIconOnPressed = (d3eState: CalenderViewRefs) => void;

export interface CalenderViewProps extends BaseUIProps {
  key?: string;
  input?: D3EDate;
  selectedColor?: ui.Color;
  hoverColor?: ui.Color;
  selectedTextColor?: ui.Color;
  hoverTextColor?: ui.Color;
  enableDate?: D3EDate;
  onDateSelected?: _CalenderViewOnDateSelected;
}
/// To store state data for CalenderView
class CalenderViewRefs {
  public leftYearIcon: LeftYearIconState = new LeftYearIconState();
  public rightYearIcon: RightYearIconState = new RightYearIconState();
}

interface RightYearIconWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: CalenderViewRefs;
  _onTapRightIconHandler?: _RightYearIconOnPressed;
  enableDate: D3EDate;
  selectedDate: D3EDate;
}

class RightYearIconState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _RightYearIconWithState extends ObservableComponent<RightYearIconWithStateProps> {
  rightYearIconFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: RightYearIconWithStateProps) {
    super(props);

    this.initState();
  }
  public get enableDate(): D3EDate {
    return this.props.enableDate;
  }
  public get rightYearIcon(): RightYearIconState {
    return this.props.d3eState.rightYearIcon;
  }
  public get selectedDate(): D3EDate {
    return this.props.selectedDate;
  }
  public get d3eState(): CalenderViewRefs {
    return this.props.d3eState;
  }
  public get _onTapRightIconHandler(): _RightYearIconOnPressed {
    return this.props._onTapRightIconHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("rightYearIcon", null, this.rightYearIcon);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["enableDate", "rightYearIcon", "selectedDate"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    return Button({
      disable: this.rightYearIcon.disable,
      onPressed: () => {
        this._onTapRightIconHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        icon: MaterialIcons.keyboard_arrow_right,
        states: ui.joinStates(
          {
            "data-c0":
              this.enableDate !== null &&
              this.selectedDate.month === this.enableDate.month &&
              this.selectedDate.year === this.enableDate.year,
          },
          {}
        ),
        color:
          this.enableDate !== null &&
          this.selectedDate.month === this.enableDate.month &&
          this.selectedDate.year === this.enableDate.year
            ? new ui.Color(0x61dddddd)
            : null,
        className: "xffe",
      }),
    });
  }
}
function RightYearIconWithState(props: RightYearIconWithStateProps) {
  return React.createElement(_RightYearIconWithState, props);
}

interface LeftYearIconWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: CalenderViewRefs;
  _onTapLeftIconHandler?: _LeftYearIconOnPressed;
  enableDate: D3EDate;
  selectedDate: D3EDate;
}

class LeftYearIconState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _LeftYearIconWithState extends ObservableComponent<LeftYearIconWithStateProps> {
  leftYearIconFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: LeftYearIconWithStateProps) {
    super(props);

    this.initState();
  }
  public get enableDate(): D3EDate {
    return this.props.enableDate;
  }
  public get leftYearIcon(): LeftYearIconState {
    return this.props.d3eState.leftYearIcon;
  }
  public get selectedDate(): D3EDate {
    return this.props.selectedDate;
  }
  public get d3eState(): CalenderViewRefs {
    return this.props.d3eState;
  }
  public get _onTapLeftIconHandler(): _LeftYearIconOnPressed {
    return this.props._onTapLeftIconHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("leftYearIcon", null, this.leftYearIcon);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["enableDate", "leftYearIcon", "selectedDate"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    return Button({
      disable: this.leftYearIcon.disable,
      onPressed: () => {
        this._onTapLeftIconHandler(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: IconView({
        icon: MaterialIcons.keyboard_arrow_left,
        states: ui.joinStates(
          {
            "data-c0":
              this.enableDate !== null &&
              this.selectedDate.month === this.enableDate.month &&
              this.selectedDate.year === this.enableDate.year,
          },
          {}
        ),
        color:
          this.enableDate !== null &&
          this.selectedDate.month === this.enableDate.month &&
          this.selectedDate.year === this.enableDate.year
            ? new ui.Color(0x61dddddd)
            : null,
        className: "x50",
      }),
    });
  }
}
function LeftYearIconWithState(props: LeftYearIconWithStateProps) {
  return React.createElement(_LeftYearIconWithState, props);
}

class _CalenderViewState extends ObservableComponent<CalenderViewProps> {
  static defaultProps = {
    input: null,
    selectedColor: null,
    hoverColor: null,
    selectedTextColor: null,
    hoverTextColor: null,
    enableDate: null,
    onDateSelected: null,
  };
  d3eState: CalenderViewRefs = new CalenderViewRefs();
  selectedDate: D3EDate = null;
  listOfDates: Array<D3EDate> = ListWrapper.widget(this, "listOfDates");
  weekNames: Array<string> = ListWrapper.widget(this, "weekNames");
  monthList: Array<string> = ListWrapper.widget(this, "monthList");
  monthsView: boolean = false;
  yearsView: boolean = false;
  yearsList: Array<number> = ListWrapper.widget(this, "yearsList");
  public constructor(props: CalenderViewProps) {
    super(props);

    this.initState();
  }
  public get input(): D3EDate {
    return this.props.input;
  }
  public get selectedColor(): ui.Color {
    return this.props.selectedColor;
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get selectedTextColor(): ui.Color {
    return this.props.selectedTextColor;
  }
  public get hoverTextColor(): ui.Color {
    return this.props.hoverTextColor;
  }
  public get enableDate(): D3EDate {
    return this.props.enableDate;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.on(["input"], this.computeSelectedDate);

    this.computeSelectedDate();

    this.on(["selectedDate"], this.computeListOfDates);

    this.computeListOfDates();

    this.computeWeekNames();

    this.computeMonthList();

    this.on(
      [
        "enableDate",
        "hoverColor",
        "hoverTextColor",
        "input",
        "listOfDates",
        "monthList",
        "monthsView",
        "selectedColor",
        "selectedDate",
        "selectedTextColor",
        "weekNames",
        "yearsList",
        "yearsView",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: CalenderViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.input !== this.props.input) {
      this.fire("input", this);
    }

    if (prevProps.selectedColor !== this.props.selectedColor) {
      this.fire("selectedColor", this);
    }

    if (prevProps.hoverColor !== this.props.hoverColor) {
      this.fire("hoverColor", this);
    }

    if (prevProps.selectedTextColor !== this.props.selectedTextColor) {
      this.fire("selectedTextColor", this);
    }

    if (prevProps.hoverTextColor !== this.props.hoverTextColor) {
      this.fire("hoverTextColor", this);
    }

    if (prevProps.enableDate !== this.props.enableDate) {
      this.fire("enableDate", this);
    }
  }
  public setSelectedDate(val: D3EDate): void {
    let isValChanged: boolean = this.selectedDate !== val;

    if (!isValChanged) {
      return;
    }

    this.selectedDate = val;

    this.fire("selectedDate", this);
  }
  public computeSelectedDate = (): void => {
    try {
      this.setSelectedDate(this.input !== null ? this.input : D3EDate.now());
    } catch (exception) {
      console.log(
        " exception in computeSelectedDate : " + exception.toString()
      );

      this.setSelectedDate(null);
    }
  };
  public setListOfDates(val: Array<D3EDate>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.listOfDates,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.listOfDates.clear();

    this.listOfDates.addAll(val);

    this.fire("listOfDates", this);
  }
  public addToListOfDates(val: D3EDate, index: number = -1): void {
    if (index === -1) {
      if (!this.listOfDates.contains(val)) this.listOfDates.add(val);
    } else {
      this.listOfDates.remove(this.listOfDates.elementAt(index));

      this.listOfDates.add(val);
    }

    this.fire("listOfDates", this, val, true);
  }
  public removeFromListOfDates(val: D3EDate): void {
    this.listOfDates.remove(val);

    this.fire("listOfDates", this, val, false);
  }
  public computeListOfDates = (): void => {
    try {
      this.setListOfDates(
        Array.from(CalenderUtils.prepareCalenderData(this.selectedDate))
      );
    } catch (exception) {
      console.log(" exception in computeListOfDates : " + exception.toString());

      this.setListOfDates([]);
    }
  };
  public setWeekNames(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.weekNames,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.weekNames.clear();

    this.weekNames.addAll(val);

    this.fire("weekNames", this);
  }
  public addToWeekNames(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.weekNames.contains(val)) this.weekNames.add(val);
    } else {
      this.weekNames.remove(this.weekNames.elementAt(index));

      this.weekNames.add(val);
    }

    this.fire("weekNames", this, val, true);
  }
  public removeFromWeekNames(val: string): void {
    this.weekNames.remove(val);

    this.fire("weekNames", this, val, false);
  }
  public computeWeekNames = (): void => {
    try {
      this.setWeekNames(
        Array.from(["SUN", "MON", "TUE", "WED", "THR", "FRI", "SAT"])
      );
    } catch (exception) {
      console.log(" exception in computeWeekNames : " + exception.toString());

      this.setWeekNames([]);
    }
  };
  public setMonthList(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.monthList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.monthList.clear();

    this.monthList.addAll(val);

    this.fire("monthList", this);
  }
  public addToMonthList(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.monthList.contains(val)) this.monthList.add(val);
    } else {
      this.monthList.remove(this.monthList.elementAt(index));

      this.monthList.add(val);
    }

    this.fire("monthList", this, val, true);
  }
  public removeFromMonthList(val: string): void {
    this.monthList.remove(val);

    this.fire("monthList", this, val, false);
  }
  public computeMonthList = (): void => {
    try {
      this.setMonthList(
        Array.from([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ])
      );
    } catch (exception) {
      console.log(" exception in computeMonthList : " + exception.toString());

      this.setMonthList([]);
    }
  };
  public setMonthsView(val: boolean): void {
    let isValChanged: boolean = this.monthsView !== val;

    if (!isValChanged) {
      return;
    }

    this.monthsView = val;

    this.fire("monthsView", this);
  }
  public setYearsView(val: boolean): void {
    let isValChanged: boolean = this.yearsView !== val;

    if (!isValChanged) {
      return;
    }

    this.yearsView = val;

    this.fire("yearsView", this);
  }
  public setYearsList(val: Array<number>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.yearsList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.yearsList.clear();

    this.yearsList.addAll(val);

    this.fire("yearsList", this);
  }
  public addToYearsList(val: number, index: number = -1): void {
    if (index === -1) {
      if (!this.yearsList.contains(val)) this.yearsList.add(val);
    } else {
      this.yearsList.remove(this.yearsList.elementAt(index));

      this.yearsList.add(val);
    }

    this.fire("yearsList", this, val, true);
  }
  public removeFromYearsList(val: number): void {
    this.yearsList.remove(val);

    this.fire("yearsList", this, val, false);
  }
  public render(): ReactNode {
    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      mainAxisSize: ui.MainAxisSize.min,
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            LeftYearIconWithState({
              d3eState: this.d3eState,
              _onTapLeftIconHandler: this.onTapLeftIconHandler,
              enableDate: this.enableDate,
              selectedDate: this.selectedDate,
              key: "0",
            }),
            this.monthsView && !this.yearsView
              ? ui.Container({
                  alignment: ui.Alignment.center,
                  child: TextView({
                    data: this.selectedDate.year.toString(),
                    style: new ui.TextStyle({
                      fontSize: 15,
                      fontFamily: "Roboto",
                      fontWeight: ui.FontWeight.bold,
                      color: new ui.Color(0xff000000),
                    }),
                    className: "xb3",
                  }),
                  onTap: (e) => {
                    e.stopPropagation();

                    this.onTapcontainer1BehaviourHandler(this.d3eState);
                  },
                  className: "xce",
                })
              : !this.monthsView && !this.yearsView
              ? ui.Wrap({
                  spacing: 40,
                  crossAxisAlignment: ui.WrapCrossAlignment.center,
                  children: [
                    ui.Container({
                      alignment: ui.Alignment.center,
                      child: TextView({
                        data: CalenderUtils.getMonthName(
                          this.selectedDate.month
                        ),
                        style: new ui.TextStyle({
                          fontSize: 15,
                          fontFamily: "Roboto",
                          color: new ui.Color(0xff000000),
                          fontWeight: ui.FontWeight.bold,
                        }),
                        className: "x44",
                      }),
                      onTap: (e) => {
                        e.stopPropagation();

                        this.onTapcontainer2BehaviourHandler(this.d3eState);
                      },
                      className: "xfc0",
                      key: "0",
                    }),
                    ui.Container({
                      alignment: ui.Alignment.center,
                      child: TextView({
                        data: this.selectedDate.year.toString(),
                        style: new ui.TextStyle({
                          fontSize: 15,
                          fontFamily: "Roboto",
                          color: new ui.Color(0xff000000),
                          fontWeight: ui.FontWeight.bold,
                        }),
                        className: "x59",
                      }),
                      onTap: (e) => {
                        e.stopPropagation();

                        this.onTapcontainer3BehaviourHandler(this.d3eState);
                      },
                      className: "xafa",
                      key: "1",
                    }),
                  ],
                })
              : ui.Container({
                  alignment: ui.Alignment.center,
                  child: TextView({
                    data:
                      this.yearsList.first.toString() +
                      " - " +
                      this.yearsList.last.toString(),
                    style: new ui.TextStyle({
                      fontSize: 15,
                      fontFamily: "Roboto",
                      fontWeight: ui.FontWeight.bold,
                      color: new ui.Color(0xff000000),
                    }),
                    className: "x396 hc",
                  }),
                  className: "xc2b hc h",
                }),
            RightYearIconWithState({
              d3eState: this.d3eState,
              _onTapRightIconHandler: this.onTapRightIconHandler,
              enableDate: this.enableDate,
              selectedDate: this.selectedDate,
              key: "2",
            }),
          ],
          className: "x548 hc h",
          key: "0",
        }),
        this.yearsView && !this.monthsView
          ? ui.Column({
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    this.yearsList.sublist(0, 3).expand((year1) => [
                      MonthOrYearCell({
                        monthOrYear: year1.toString(),
                        selectedColor: this.selectedColor,
                        hoverColor: this.hoverColor,
                        selectedTextColor: this.selectedTextColor,
                        hoverTextColor: this.hoverTextColor,
                        disable:
                          this.enableDate !== null
                            ? this.enableDate.year < year1
                            : false,
                        onTapMonthOrYear: (monthOrYear) => {
                          this.onTapMonthOrYearmonthoryearcell4Handler(
                            monthOrYear,
                            year1,
                            this.d3eState
                          );
                        },
                        key: year1?.toString(),
                      }),
                    ]),
                  ],
                  className: "x402 hc h",
                  key: "0",
                }),
                ui.Container({ className: "xb86 hc vc", key: "1" }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    this.yearsList.sublist(3, 6).expand((year2) => [
                      MonthOrYearCell({
                        monthOrYear: year2.toString(),
                        selectedColor: this.selectedColor,
                        hoverColor: this.hoverColor,
                        selectedTextColor: this.selectedTextColor,
                        hoverTextColor: this.hoverTextColor,
                        disable:
                          this.enableDate !== null
                            ? this.enableDate.year < year2
                            : false,
                        onTapMonthOrYear: (monthOrYear) => {
                          this.onTapMonthOrYearmonthoryearcell5Handler(
                            monthOrYear,
                            year2,
                            this.d3eState
                          );
                        },
                        key: year2?.toString(),
                      }),
                    ]),
                  ],
                  className: "xb36 hc h",
                  key: "2",
                }),
                ui.Container({ className: "xbe hc vc", key: "3" }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    this.yearsList.sublist(6, 9).expand((year3) => [
                      MonthOrYearCell({
                        monthOrYear: year3.toString(),
                        selectedColor: this.selectedColor,
                        hoverColor: this.hoverColor,
                        selectedTextColor: this.selectedTextColor,
                        hoverTextColor: this.hoverTextColor,
                        disable:
                          this.enableDate !== null
                            ? this.enableDate.year < year3
                            : false,
                        onTapMonthOrYear: (monthOrYear) => {
                          this.onTapMonthOrYearmonthoryearcell6Handler(
                            monthOrYear,
                            year3,
                            this.d3eState
                          );
                        },
                        key: year3?.toString(),
                      }),
                    ]),
                  ],
                  className: "xaf1 hc h",
                  key: "4",
                }),
                ui.Container({ className: "x69db hc vc", key: "5" }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    this.yearsList.sublist(9, 12).expand((year3) => [
                      MonthOrYearCell({
                        monthOrYear: year3.toString(),
                        selectedColor: this.selectedColor,
                        hoverColor: this.hoverColor,
                        selectedTextColor: this.selectedTextColor,
                        hoverTextColor: this.hoverTextColor,
                        disable:
                          this.enableDate !== null
                            ? this.enableDate.year < year3
                            : false,
                        onTapMonthOrYear: (monthOrYear) => {
                          this.onTapMonthOrYearmonthoryearcell7Handler(
                            monthOrYear,
                            year3,
                            this.d3eState
                          );
                        },
                        key: year3?.toString(),
                      }),
                    ]),
                  ],
                  className: "xb2 hc h",
                  key: "6",
                }),
              ],
              className: "x3f hc h",
            })
          : !this.yearsView && this.monthsView
          ? ui.Column({
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    this.monthList.sublist(0, 3).expand((forItem8) => [
                      MonthOrYearCell({
                        monthOrYear: forItem8,
                        selectedColor: this.selectedColor,
                        hoverColor: this.hoverColor,
                        selectedTextColor: this.selectedTextColor,
                        hoverTextColor: this.hoverTextColor,
                        disable:
                          this.enableDate !== null
                            ? this.enableDate.month <
                              CalenderUtils.getMonthNumber(forItem8)
                            : false,
                        onTapMonthOrYear: (monthOrYear) => {
                          this.onTapMonthOrYearmonthOrYearCell1Handler(
                            monthOrYear,
                            forItem8,
                            this.d3eState
                          );
                        },
                        key: forItem8?.toString(),
                      }),
                    ]),
                  ],
                  className: "x23 hc h",
                  key: "0",
                }),
                ui.Container({ className: "x7a3 hc vc", key: "1" }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    this.monthList.sublist(3, 6).expand((forItem9) => [
                      MonthOrYearCell({
                        monthOrYear: forItem9,
                        selectedColor: this.selectedColor,
                        hoverColor: this.hoverColor,
                        selectedTextColor: this.selectedTextColor,
                        hoverTextColor: this.hoverTextColor,
                        disable:
                          this.enableDate !== null
                            ? this.enableDate.month <
                              CalenderUtils.getMonthNumber(forItem9)
                            : false,
                        onTapMonthOrYear: (monthOrYear) => {
                          this.onTapMonthOrYearmonthOrYearCell2Handler(
                            monthOrYear,
                            forItem9,
                            this.d3eState
                          );
                        },
                        key: forItem9?.toString(),
                      }),
                    ]),
                  ],
                  className: "xb7e hc h",
                  key: "2",
                }),
                ui.Container({ className: "xe6 hc vc", key: "3" }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    this.monthList.sublist(6, 9).expand((forItem10) => [
                      MonthOrYearCell({
                        monthOrYear: forItem10,
                        selectedColor: this.selectedColor,
                        hoverColor: this.hoverColor,
                        selectedTextColor: this.selectedTextColor,
                        hoverTextColor: this.hoverTextColor,
                        disable:
                          this.enableDate !== null
                            ? this.enableDate.month <
                              CalenderUtils.getMonthNumber(forItem10)
                            : false,
                        onTapMonthOrYear: (monthOrYear) => {
                          this.onTapMonthOrYearmonthOrYearCell3Handler(
                            monthOrYear,
                            forItem10,
                            this.d3eState
                          );
                        },
                        key: forItem10?.toString(),
                      }),
                    ]),
                  ],
                  className: "x06 hc h",
                  key: "4",
                }),
                ui.Container({ className: "xcd2 hc vc", key: "5" }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    this.monthList.sublist(9, 12).expand((forItem8) => [
                      MonthOrYearCell({
                        monthOrYear: forItem8,
                        selectedColor: this.selectedColor,
                        hoverColor: this.hoverColor,
                        selectedTextColor: this.selectedTextColor,
                        hoverTextColor: this.hoverTextColor,
                        disable:
                          this.enableDate !== null
                            ? this.enableDate.month <
                              CalenderUtils.getMonthNumber(forItem8)
                            : false,
                        onTapMonthOrYear: (monthOrYear) => {
                          this.onTapMonthOrYearmonthOrYearCell8Handler(
                            monthOrYear,
                            forItem8,
                            this.d3eState
                          );
                        },
                        key: forItem8?.toString(),
                      }),
                    ]),
                  ],
                  className: "x483 hc h",
                  key: "6",
                }),
              ],
              className: "x76 hc h",
            })
          : ui.Column({
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceEvenly,
                  children: [
                    this.weekNames.expand((forItem) => [
                      TextView({
                        data: forItem,
                        style: new ui.TextStyle({
                          fontSize: 11,
                          color: new ui.Color(0xff000000),
                          fontWeight: ui.FontWeight.w600,
                        }),
                        className: "x4e",
                        key: forItem?.toString(),
                      }),
                    ]),
                  ],
                  className: "xfaa hc h",
                  key: "0",
                }),
                Range.to(6).expand((eachWeek) => [
                  ui.Row({
                    mainAxisAlignment: ui.MainAxisAlignment.spaceEvenly,
                    children: [
                      this.listOfDates
                        .sublist(eachWeek * 7, eachWeek * 7 + 7)
                        .expand((forItem2) => [
                          DateCell({
                            date: forItem2,
                            selected: D3EDate.equals(forItem2, this.input),
                            month: this.selectedDate.month,
                            selectedColor: this.selectedColor,
                            hoverColor: this.hoverColor,
                            selectedTextColor: this.selectedTextColor,
                            hoverTextColor: this.hoverTextColor,
                            disable:
                              this.enableDate !== null
                                ? this.selectedDate.month ===
                                    this.enableDate.month &&
                                  this.selectedDate.year ===
                                    this.enableDate.year &&
                                  this.enableDate.day < forItem2.day
                                : false,
                            onTapDate: (date) => {
                              this.onTapDatedateCell1Handler(
                                date,
                                forItem2,
                                eachWeek,
                                this.d3eState
                              );
                            },
                            className: "x22",
                            key: forItem2?.toString(),
                          }),
                        ]),
                    ],
                    className: "xb0a hc h",
                    key: eachWeek?.toString(),
                  }),
                ]),
              ],
              className: "xd1b hc h",
            }),
      ],
      className: ui.join(this.props.className, "CalenderView x4f7 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setYearsList(
      CalenderUtils.getYearsList(this.selectedDate.year, false)
    );
  };
  public onTapLeftIconHandler = (d3eState: CalenderViewRefs): void => {
    if (this.yearsView && !this.monthsView) {
      this.setYearsList(
        CalenderUtils.getYearsList(this.yearsList.first - 1, false)
      );
    } else if (!this.yearsView && this.monthsView) {
      this.setSelectedDate(this.selectedDate.plusYears(-1));
    } else {
      this.setSelectedDate(this.selectedDate.plusMonths(-1));
    }

    // Your code here.
  };
  public onTapRightIconHandler = (d3eState: CalenderViewRefs): void => {
    if (this.yearsView && !this.monthsView) {
      this.setYearsList(
        CalenderUtils.getYearsList(this.yearsList.last + 1, true)
      );
    } else if (!this.yearsView && this.monthsView) {
      this.setSelectedDate(this.selectedDate.plusYears(1));
    } else {
      this.setSelectedDate(this.selectedDate.plusMonths(1));
    }

    // Your code here.
  };
  public onTapMonthOrYearmonthoryearcell4Handler = (
    monthOrYear: string,
    year1: number,
    d3eState: CalenderViewRefs
  ): void => {
    let year: number = Number.parseInt(monthOrYear);

    this.setSelectedDate(D3EDate.of(year, 1, 1));

    this.setYearsView(false);

    this.setMonthsView(true);

    // Your code here.
  };
  public onTapMonthOrYearmonthoryearcell5Handler = (
    monthOrYear: string,
    year2: number,
    d3eState: CalenderViewRefs
  ): void => {
    let year: number = Number.parseInt(monthOrYear);

    this.setSelectedDate(D3EDate.of(year, 1, 1));

    this.setYearsView(false);

    this.setMonthsView(true);

    // Your code here.
  };
  public onTapMonthOrYearmonthoryearcell6Handler = (
    monthOrYear: string,
    year3: number,
    d3eState: CalenderViewRefs
  ): void => {
    let year: number = Number.parseInt(monthOrYear);

    this.setSelectedDate(D3EDate.of(year, 1, 1));

    this.setYearsView(false);

    this.setMonthsView(true);

    // Your code here.
  };
  public onTapMonthOrYearmonthoryearcell7Handler = (
    monthOrYear: string,
    year3: number,
    d3eState: CalenderViewRefs
  ): void => {
    let year: number = Number.parseInt(monthOrYear);

    this.setSelectedDate(D3EDate.of(year, 1, 1));

    this.setYearsView(false);

    this.setMonthsView(true);

    // Your code here.
  };
  public onTapMonthOrYearmonthOrYearCell1Handler = (
    monthOrYear: string,
    forItem8: string,
    d3eState: CalenderViewRefs
  ): void => {
    let month: number = CalenderUtils.getMonthNumber(monthOrYear);

    this.setSelectedDate(D3EDate.of(this.selectedDate.year, month, 1));

    this.setMonthsView(false);

    // Your code here.
  };
  public onTapMonthOrYearmonthOrYearCell8Handler = (
    monthOrYear: string,
    forItem8: string,
    d3eState: CalenderViewRefs
  ): void => {
    let month: number = CalenderUtils.getMonthNumber(monthOrYear);

    this.setSelectedDate(D3EDate.of(this.selectedDate.year, month, 1));

    this.setMonthsView(false);

    // Your code here.
  };
  public onTapMonthOrYearmonthOrYearCell2Handler = (
    monthOrYear: string,
    forItem9: string,
    d3eState: CalenderViewRefs
  ): void => {
    let month: number = CalenderUtils.getMonthNumber(monthOrYear);

    this.setSelectedDate(D3EDate.of(this.selectedDate.year, month, 1));

    this.setMonthsView(false);

    // Your code here.
  };
  public onTapMonthOrYearmonthOrYearCell3Handler = (
    monthOrYear: string,
    forItem10: string,
    d3eState: CalenderViewRefs
  ): void => {
    let month: number = CalenderUtils.getMonthNumber(monthOrYear);

    this.setSelectedDate(D3EDate.of(this.selectedDate.year, month, 1));

    this.setMonthsView(false);

    // Your code here.
  };
  public onTapDatedateCell1Handler = (
    date: D3EDate,
    forItem2: D3EDate,
    eachWeek: number,
    d3eState: CalenderViewRefs
  ): void => {
    if (
      date.month === this.selectedDate.month &&
      this.onDateSelected !== null
    ) {
      this.onDateSelected(date);
    } else {
      this.setSelectedDate(date);
    }

    // Your code here.
  };
  public onTapcontainer1BehaviourHandler = (
    d3eState: CalenderViewRefs
  ): void => {
    this.setYearsView(true);

    this.setMonthsView(false);

    // Your code here.
  };
  public onTapcontainer2BehaviourHandler = (
    d3eState: CalenderViewRefs
  ): void => {
    this.setMonthsView(true);

    // Your code here.
  };
  public onTapcontainer3BehaviourHandler = (
    d3eState: CalenderViewRefs
  ): void => {
    this.setYearsView(true);

    this.setMonthsView(false);

    // Your code here.
  };
  public get onDateSelected(): _CalenderViewOnDateSelected {
    return this.props.onDateSelected;
  }
  public get leftYearIcon() {
    return this.d3eState.leftYearIcon;
  }
  public get rightYearIcon() {
    return this.d3eState.rightYearIcon;
  }
}
export default function CalenderView(props: CalenderViewProps) {
  return React.createElement(_CalenderViewState, {
    ..._CalenderViewState.defaultProps,
    ...props,
  });
}
