import DataPoint from "./DataPoint";
import ListWrapper from "../utils/ListWrapper";
import DBObject from "../utils/DBObject";
import CollectionUtils from "../utils/CollectionUtils";

export default class DataSet extends DBObject {
  private _id: number = DBObject.nextStructId();
  private static readonly _COLOR: number = 0;
  private static readonly _DATA: number = 1;
  private static readonly _NAME: number = 2;
  private _name: string = "";
  private _data: Array<DataPoint> = ListWrapper.vanilla(
    this,
    "data",
    DataSet._DATA
  );
  private _color: string = "";
  public constructor(
    d3eParams?: Partial<{ color: string; data: Array<DataPoint>; name: string }>
  ) {
    super();

    if (d3eParams?.color) {
      this.setColor(d3eParams?.color);
    }

    if (d3eParams?.data) {
      this.setData(d3eParams?.data);
    }

    if (d3eParams?.name) {
      this.setName(d3eParams?.name);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "DataSet";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this._name = val;

    this.fire("name", this);
  }
  public get data(): Array<DataPoint> {
    return this._data;
  }
  public setData(val: Array<DataPoint>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this._data, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("data", this._data, val);

    this._data.clear();

    this._data.addAll(val);

    this.fire("data", this);
  }
  public addToData(val: DataPoint, index: number = -1): void {
    if (index === -1) {
      if (!this._data.contains(val)) this._data.add(val);
    } else {
      this._data.remove(this._data.elementAt(index));

      this._data.add(val);
    }

    this.fire("data", this, val, true);

    this.updateObservable("data", null, val);
  }
  public removeFromData(val: DataPoint): void {
    this._data.remove(val);

    this.fire("data", this, val, false);

    this.removeObservable("data", val);
  }
  public get color(): string {
    return this._color;
  }
  public setColor(val: string): void {
    let isValChanged: boolean = this._color !== val;

    if (!isValChanged) {
      return;
    }

    this._color = val;

    this.fire("color", this);
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof DataSet &&
        this._name === other._name &&
        this._data.equals(other._data) &&
        this._color === other._color)
    );
  }
  public get hashCode(): number {
    return (
      (this._name?.hashCode ?? 0) +
      (this._data?.hashCode ?? 0) +
      (this._color?.hashCode ?? 0)
    );
  }
}
