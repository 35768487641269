import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextValueField from "./TextValueField";
import Browser from "../classes/Browser";
import ScrollView2 from "./ScrollView2";
import LeadStatus from "../classes/LeadStatus";
import TextValueCollectionView from "./TextValueCollectionView";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import LeadResultItem from "../classes/LeadResultItem";
import IconButton from "./IconButton";
import TextValueWithButton from "./TextValueWithButton";
import HorizontalLine from "./HorizontalLine";
import { BuildContext } from "../classes/BuildContext";

type _ApolloLeadDetailsViewOnClose = () => void;

export interface ApolloLeadDetailsViewProps extends BaseUIProps {
  key?: string;
  lead: LeadResultItem;
  onClose?: _ApolloLeadDetailsViewOnClose;
}

class _ApolloLeadDetailsViewState extends ObservableComponent<ApolloLeadDetailsViewProps> {
  static defaultProps = { lead: null, onClose: null };
  id4ScrollController: ui.ScrollController = new ui.ScrollController();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ApolloLeadDetailsViewProps) {
    super(props);

    this.initState();
  }
  public get lead(): LeadResultItem {
    return this.props.lead;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.on(
      [
        "lead",
        "lead.birthDate",
        "lead.company",
        "lead.companyRevenue",
        "lead.companySize",
        "lead.companyWebsite",
        "lead.currentPosition",
        "lead.decisionMakingRole",
        "lead.email",
        "lead.emailStatus",
        "lead.facebookProfile",
        "lead.industry",
        "lead.interactionDate",
        "lead.languages",
        "lead.linkedInProfile",
        "lead.location",
        "lead.name",
        "lead.phone",
        "lead.previousPositions",
        "lead.skills",
        "lead.source",
        "lead.status",
        "lead.timeZone",
        "lead.twitterHandle",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ApolloLeadDetailsViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.center,
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            TextView({
              data: "Lead Details",
              style: new ui.TextStyle({
                fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
              }),
              className: "xde",
              key: "0",
            }),
            IconButton({
              icon: MaterialIcons.close,
              onPressed: () => {
                this.onCloseHandler();
              },
              key: "1",
            }),
          ],
          className: "x5d hc h",
          key: "0",
        }),
        HorizontalLine({
          margin: ui.EdgeInsets.symmetric({
            horizontal: 0.0,
            vertical: 10.0,
            transitions: new Map(),
          }),
          className: "x87 hc h",
          key: "1",
        }),
        ScrollView2({
          child: ui.Column({
            children: [
              TextValueField({
                value: this.lead.name.isEmpty ? " --- " : this.lead.name,
                label: "Name",
                className: "x96 hc h",
                key: "0",
              }),
              TextValueField({
                value: this.lead.email.isEmpty ? " --- " : this.lead.email,
                label: "Email",
                className: "x17 hc h",
                key: "1",
              }),
              TextValueField({
                value: this.lead.phone.isEmpty ? "---" : this.lead.phone,
                label: "Phone",
                className: "xb8 hc h",
                key: "2",
              }),
              TextValueField({
                label: "Status",
                value: this.lead?.status?.name ?? "",
                className: "xba hc h",
                key: "3",
              }),
              TextValueField({
                label: "Birth Date",
                value:
                  this.lead?.birthDate !== null
                    ? this.lead.birthDate.toString()
                    : "",
                className: "x3c hc h",
                key: "4",
              }),
              TextValueField({
                label: "Location",
                value: this.lead?.location ?? "",
                className: "xae hc h",
                key: "5",
              }),
              TextValueField({
                value: this.lead.company === "" ? " --- " : this.lead.company,
                label: "Company",
                className: "x0b hc h",
                key: "6",
              }),
              TextValueField({
                label: "Industry Name",
                value: this.lead?.industry ?? "",
                className: "xde8 hc h",
                key: "7",
              }),
              TextValueField({
                label: "Company Size",
                value: this.lead?.companySize ?? "",
                className: "x730 hc h",
                key: "8",
              }),
              TextValueField({
                label: "Revenue",
                value: this.lead?.companyRevenue ?? "",
                className: "xdf hc h",
                key: "9",
              }),
              TextValueWithButton({
                label: "Website",
                value: this.lead?.companyWebsite ?? "",
                onOpen: () => {
                  this.onWebsiteLabelButtonHandler();
                },
                className: "x0e hc h",
                key: "10",
              }),
              TextValueCollectionView({
                label: "Previous Positions",
                items: this.lead?.previousPositions ?? [],
                className: "x16 hc h",
                key: "11",
              }),
              TextValueField({
                label: "Current Position",
                value: this.lead?.currentPosition ?? "",
                className: "xa0 hc h",
                key: "12",
              }),
              TextValueField({
                label: "Desicion Making Role",
                value: this.lead?.decisionMakingRole ?? "",
                className: "x8a hc h",
                key: "13",
              }),
              TextValueField({
                label: "Source",
                value: this.lead?.source ?? "",
                className: "xca hc h",
                key: "14",
              }),
              TextValueWithButton({
                label: "Linkedin Profile",
                value: this.lead?.linkedInProfile ?? "",
                onOpen: () => {
                  this.onLinkedinLabelButtonHandler();
                },
                className: "xf8 hc h",
                key: "15",
              }),
              TextValueWithButton({
                label: "Twitter Profile",
                value: this.lead?.twitterHandle ?? "",
                onOpen: () => {
                  this.onTwitterLabelHandler();
                },
                className: "x5fc hc h",
                key: "16",
              }),
              TextValueWithButton({
                label: "Facebook Profile",
                value: this.lead?.facebookProfile ?? "",
                onOpen: () => {
                  this.onFacebookLabelHandler();
                },
                className: "xff hc h",
                key: "17",
              }),
              TextValueCollectionView({
                label: "Languages",
                items: this.lead?.languages ?? [],
                className: "x27 hc h",
                key: "18",
              }),
              TextValueCollectionView({
                label: "Skills",
                items: this.lead.skills,
                className: "x5e0 hc h",
                key: "19",
              }),
              TextValueField({
                label: "Time Zone",
                value: this.lead?.timeZone ?? "",
                className: "x69 hc h",
                key: "20",
              }),
              TextValueField({
                label: "Interaction Date",
                value:
                  this.lead?.interactionDate !== null
                    ? this.lead.interactionDate.toString()
                    : "",
                className: "x53 hc h",
                key: "21",
              }),
              TextValueField({
                label: "Email Status",
                value: this.lead?.emailStatus ?? "",
                className: "x1a hc h",
                key: "22",
              }),
            ],
            key: "2",
          }),
          scrollDirection: ui.Axis.vertical,
          className: "x8e hc h",
          controller: this.id4ScrollController,
        }),
      ],
      className: ui.join(
        this.props.className,
        "ApolloLeadDetailsView x72 hc vc"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onWebsiteLabelButtonHandler = (): void => {
    Browser.open(this.lead.companyWebsite);
  };
  public onLinkedinLabelButtonHandler = (): void => {
    Browser.open(this.lead.linkedInProfile);
  };
  public onTwitterLabelHandler = (): void => {
    Browser.open(this.lead.twitterHandle);
  };
  public onFacebookLabelHandler = (): void => {
    Browser.open(this.lead.facebookProfile);
  };
  public onCloseHandler = (): void => {
    if (this.onClose !== null) {
      this.onClose();
    }
  };
  public get onClose(): _ApolloLeadDetailsViewOnClose {
    return this.props.onClose;
  }
}
export default function ApolloLeadDetailsView(
  props: ApolloLeadDetailsViewProps
) {
  return React.createElement(_ApolloLeadDetailsViewState, {
    ..._ApolloLeadDetailsViewState.defaultProps,
    ...props,
  });
}
