import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import MultilineEditableField from "./MultilineEditableField";
import LeadInteractionsRequest from "../models/LeadInteractionsRequest";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import Timer from "../utils/Timer";
import IconButton from "./IconButton";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import LeadInteractions from "../classes/LeadInteractions";
import ListWrapper from "../utils/ListWrapper";
import ScrollView2 from "./ScrollView2";
import Company from "../models/Company";
import Lead from "../models/Lead";
import Duration from "../core/Duration";
import TextView from "./TextView";
import Interaction from "../models/Interaction";
import CollectionUtils from "../utils/CollectionUtils";
import ConversationNoteWidget from "./ConversationNoteWidget";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _InteractionHistoriesViewComputeNewMessage = (body: string) => void;

type _MultiLineOnChanged = (text: string) => void;

export interface InteractionHistoriesViewProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  handledBy: User;
  data: CallData;
  company: Company;
  computeNewMessage: _InteractionHistoriesViewComputeNewMessage;
}

class _InteractionHistoriesViewState extends ObservableComponent<InteractionHistoriesViewProps> {
  static defaultProps = {
    lead: null,
    handledBy: null,
    data: null,
    company: null,
    computeNewMessage: null,
  };
  multiLineController: ui.TextEditingController =
    new ui.TextEditingController();
  chatScrollController: ui.ScrollController = new ui.ScrollController();
  multiLineFocusNode: ui.FocusNode = new ui.FocusNode();
  query: LeadInteractions = null;
  interactions: Array<Interaction> = ListWrapper.widget(this, "interactions");
  message: string = "";
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: InteractionHistoriesViewProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get handledBy(): User {
    return this.props.handledBy;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public get company(): Company {
    return this.props.company;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.updateSyncProperty("handledBy", this.props.handledBy);

    this.on(["handledBy", "lead"], this.computeQuery);

    this.computeQuery();

    this.on(["query", "query.items"], this.computeInteractions);

    this.computeInteractions();

    this.updateSyncProperty("data", this.props.data);

    this.updateSyncProperty("company", this.props.company);

    this.on(
      [
        "company",
        "company.enableMessaging",
        "data",
        "data.deviceReady",
        "interactions",
        "message",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: InteractionHistoriesViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.handledBy !== this.props.handledBy) {
      this.updateObservable(
        "handledBy",
        prevProps.handledBy,
        this.props.handledBy
      );

      this.fire("handledBy", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }

    if (prevProps.company !== this.props.company) {
      this.updateObservable("company", prevProps.company, this.props.company);

      this.fire("company", this);
    }
  }
  public setQuery(val: LeadInteractions): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    MessageDispatch.get().dispose(this.query);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getLeadInteractions(
          UsageConstants.QUERY_GETLEADINTERACTIONS_INTERACTIONHISTORIESVIEW_PROPERTIES_QUERY_COMPUTATION,
          new LeadInteractionsRequest({
            lead: this.lead,
            handledBy: this.handledBy,
          }),
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setInteractions(val: Array<Interaction>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.interactions,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("interactions", this.interactions, val);

    this.interactions.clear();

    this.interactions.addAll(val);

    this.fire("interactions", this);
  }
  public addToInteractions(val: Interaction, index: number = -1): void {
    if (index === -1) {
      if (!this.interactions.contains(val)) this.interactions.add(val);
    } else {
      this.interactions.remove(this.interactions.elementAt(index));

      this.interactions.add(val);
    }

    this.fire("interactions", this, val, true);

    this.updateObservable("interactions", null, val);
  }
  public removeFromInteractions(val: Interaction): void {
    this.interactions.remove(val);

    this.fire("interactions", this, val, false);

    this.removeObservable("interactions", val);
  }
  public computeInteractions = (): void => {
    try {
      this.setInteractions(Array.from(this.query?.items ?? []));
    } catch (exception) {
      console.log(
        " exception in computeInteractions : " + exception.toString()
      );

      this.setInteractions([]);
    }
  };
  public setMessage(val: string): void {
    let isValChanged: boolean = this.message !== val;

    if (!isValChanged) {
      return;
    }

    this.message = val;

    this.fire("message", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ScrollView2({
          child: ui.Column({
            children: [
              this.interactions.expand((interaction) => [
                ConversationNoteWidget({
                  interaction: interaction,
                  className: "xa7 hc h",
                  key: interaction?.ident,
                }),
              ]),
            ],
            key: "0",
          }),
          scrollDirection: ui.Axis.vertical,
          className: "x62e hc vc h v",
          controller: this.chatScrollController,
        }),
        this.company.enableMessaging
          ? ui.Column({
              mainAxisAlignment: ui.MainAxisAlignment.end,
              children: [
                ui.Container({
                  alignment: ui.Alignment.bottomCenter,
                  child: ui.Row({
                    children: [
                      MultilineEditableField({
                        value: this.message,
                        inActiveColor: cStyle.c14,
                        activeColor: cStyle.c14,
                        placeHolder: "Type a message",
                        disable: this.data !== null && !this.data.deviceReady,
                        controller: this.multiLineController,
                        onSubmitted: (text) => {
                          this.onSubmiitedTextHandler(text);
                        },
                        onChanged: (text) => {
                          this.multiLineonChanged(text);
                        },
                        onFocusChange: (val) => {},
                        focusNode: this.multiLineFocusNode,
                        className: "x621 hc h",
                        key: "0",
                      }),
                      IconButton({
                        icon: MaterialIcons.send,
                        tooltip:
                          this.data === null || !this.data.deviceReady
                            ? "Your in offline mode"
                            : "Send",
                        disabledColor: new ui.Color(0xffbdbdbd),
                        disableMode:
                          this.data === null || !this.data.deviceReady
                            ? true
                            : false,
                        onPressed: () => {
                          this.sendNewMessage();
                        },
                        key: "1",
                      }),
                    ],
                    className: "xc6c hc h",
                  }),
                  className: "xea8 hc h",
                  key: "0",
                }),
                this.data !== null && !this.data.deviceReady
                  ? TextView({
                      data: "You are in offline mode, Your message will not be sent",
                      textAlign: ui.TextAlign.left,
                      style: new ui.TextStyle({
                        color: new ui.Color(0xff343434),
                      }),
                      className: "x12a hc",
                    })
                  : [],
              ],
              className: "x6dc hc h",
            })
          : [],
      ],
      className: ui.join(
        this.props.className,
        "InteractionHistoriesView x1d1 hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setMessage("");

    new Timer(new Duration({ milliseconds: 300 }), () => {
      this.chatScrollController.animateToBottom();
    });
  };
  public sendNewMessage = (): void => {
    //  check message contains only white spaces

    if (
      this.computeNewMessage !== null &&
      this.message !== null &&
      this.message.trim().isNotEmpty
    ) {
      this.computeNewMessage(this.message);

      this.setMessage("");

      this.multiLineController.text = "";
    }
  };
  public onSubmiitedTextHandler = (text: string): void => {
    if (
      this.computeNewMessage !== null &&
      this.message !== null &&
      this.message.trim().isNotEmpty
    ) {
      this.computeNewMessage(this.message);

      this.setMessage("");

      this.multiLineController.text = "";
    }
  };
  public get computeNewMessage(): _InteractionHistoriesViewComputeNewMessage {
    return this.props.computeNewMessage;
  }
  public dispose(): void {
    MessageDispatch.get().dispose(this.query);

    super.dispose();
  }
  public multiLineonChanged = (val: string): void => {
    this.setMessage(val);
  };
}
export default function InteractionHistoriesView(
  props: InteractionHistoriesViewProps
) {
  return React.createElement(_InteractionHistoriesViewState, {
    ..._InteractionHistoriesViewState.defaultProps,
    ...props,
  });
}
