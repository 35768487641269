import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import Loader from "./Loader";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import Duration from "../core/Duration";
import Timer from "../utils/Timer";
import BaseUser from "../models/BaseUser";
import Query from "../classes/Query";
import { BuildContext } from "../classes/BuildContext";

export interface LandingPageProps extends BaseUIProps {
  key?: string;
}

class _LandingPageState extends ObservableComponent<LandingPageProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LandingPageProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {}
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Center({
      child: Loader({
        backgroundColor: cStyle.c14,
        valueColor: cStyle.c1,
        className: "hc vc",
      }),
      className: ui.join(this.props.className, "LandingPage hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = async (): Promise<void> => {
    let baseUser: BaseUser = await Query.get().currentUser();

    let user: User;

    if (baseUser !== null) {
      if (baseUser instanceof User) {
        user = baseUser as User;

        new Timer(new Duration({ milliseconds: 15 }), () => {
          if (user !== null && user.isRememberMe) {
            this.navigator.pushRoutePage({ user: user });
          } else {
            this.navigator.pushLoginPage();
          }
        });
      }
    } else {
      new Timer(new Duration({ milliseconds: 20 }), () => {
        this.navigator.pushLoginPage();
      });
    }
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function LandingPage(props: LandingPageProps) {
  return React.createElement(_LandingPageState, props);
}
