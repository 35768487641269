import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import D3EDate from "../classes/D3EDate";
import CollectionTechnologieView from "./CollectionTechnologieView";
import LabelWithDateField from "./LabelWithDateField";
import MaterialIcons from "../icons/MaterialIcons";
import CollectionView from "./CollectionView";
import SearchCriteria from "../classes/SearchCriteria";
import RemoveFilterView from "./RemoveFilterView";
import SearchComponent from "./SearchComponent";
import SideMenuUtils from "../classes/SideMenuUtils";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import CollectionUtils from "../utils/CollectionUtils";
import SearchFilterButtonView from "./SearchFilterButtonView";
import IndustryResultItem from "../classes/IndustryResultItem";
import CollectionLocationView from "./CollectionLocationView";
import CollectionCompanyView from "./CollectionCompanyView";
import CompanySearchItem from "../classes/CompanySearchItem";
import ApolloServicesUtils from "../classes/ApolloServicesUtils";
import CollectionIndustriesView from "./CollectionIndustriesView";
import ScrollView2 from "./ScrollView2";
import InputFieldType from "../classes/InputFieldType";
import LabelWithIntegerInputField from "./LabelWithIntegerInputField";
import CollectionSearchView from "./CollectionSearchView";
import LabelDropdown from "./LabelDropdown";
import Divider from "./Divider";
import { BuildContext } from "../classes/BuildContext";

type _ApolloSearchFilterViewOnSearchName = (value: SearchCriteria) => void;

type _SubmitButtonOnPressed = (d3eState: ApolloSearchFilterViewRefs) => void;

type _SearchWithNameOnChanged = (
  text: string,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _PersonLocationsViewOnChanged = (
  text: Array<string>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _CompanyViewOnChanged = (
  text: Array<CompanySearchItem>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _TimeZonesViewOnChanged = (
  text: Array<string>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _AccountLocationViewOnChanged = (
  text: Array<string>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _JobPostingHiringForViewOnChanged = (
  text: Array<string>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _JobPostingLocationViewOnChanged = (
  text: Array<string>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _JobTitlesViewOnChanged = (
  text: Array<string>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _KeywordsViewOnChanged = (
  text: Array<IndustryResultItem>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _TechnologiesViewOnChanged = (
  text: Array<string>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _LanguagesViewOnChanged = (
  text: Array<string>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _SICCodesViewOnChanged = (
  text: Array<string>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

type _SICCodesView1OnChanged = (
  text: Array<string>,
  d3eState: ApolloSearchFilterViewRefs
) => void;

export interface ApolloSearchFilterViewProps extends BaseUIProps {
  key?: string;
  onSearchName: _ApolloSearchFilterViewOnSearchName;
}
/// To store state data for ApolloSearchFilterView
class ApolloSearchFilterViewRefs {
  personalInfoViewScrollController: ui.ScrollController =
    new ui.ScrollController();
  public submitButton: SubmitButtonState = new SubmitButtonState();
}

interface SubmitButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: ApolloSearchFilterViewRefs;
  _onSubmitHandler?: _SubmitButtonOnPressed;
}

class SubmitButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _SubmitButtonWithState extends ObservableComponent<SubmitButtonWithStateProps> {
  submitButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SubmitButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get submitButton(): SubmitButtonState {
    return this.props.d3eState.submitButton;
  }
  public get d3eState(): ApolloSearchFilterViewRefs {
    return this.props.d3eState;
  }
  public get _onSubmitHandler(): _SubmitButtonOnPressed {
    return this.props._onSubmitHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("submitButton", null, this.submitButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["submitButton", "submitButton.", "submitButton.hover"],
      this.rebuild
    );
  }
  public submitButtonOnEnter(event): void {
    return this.submitButton.setHover(true);
  }
  public submitButtonOnExit(event): void {
    return this.submitButton.setHover(false);
  }
  public dispose(): void {
    this.submitButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.submitButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.submitButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.submitButton.disable,
        onPressed: () => {
          this._onSubmitHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            TextView({ data: "Apply Filters", className: "xae1", key: "0" }),
          ],
        }),
        onEnter: (event) => {
          this.submitButtonOnEnter(event);
        },
        onExit: (event) => {
          this.submitButtonOnExit(event);
        },
      }),
      className: "xdb",
    });
  }
}
function SubmitButtonWithState(props: SubmitButtonWithStateProps) {
  return React.createElement(_SubmitButtonWithState, props);
}

class _ApolloSearchFilterViewState extends ObservableComponent<ApolloSearchFilterViewProps> {
  d3eState: ApolloSearchFilterViewRefs = new ApolloSearchFilterViewRefs();
  criteria: SearchCriteria = null;
  timeZones: Array<string> = ListWrapper.widget(this, "timeZones");
  searchJobTiltes: Array<string> = ListWrapper.widget(this, "searchJobTiltes");
  companyTagIDs: Array<CompanySearchItem> = ListWrapper.widget(
    this,
    "companyTagIDs"
  );
  minYearsOfExperience: number = 0;
  maxYearsOfExperience: number = 0;
  minYearTimeCurrentRole: number = 0;
  maxYearTimeCurrentRole: number = 0;
  minMonthTimeCurrentRole: number = 0;
  maxMonthTimeCurrentRole: number = 0;
  industryKeyIDs: Array<IndustryResultItem> = ListWrapper.widget(
    this,
    "industryKeyIDs"
  );
  minGrowPercentage: number = 0;
  maxGrowPercentage: number = 0;
  fundingFromDate: D3EDate = null;
  fundingToDate: D3EDate = null;
  minFundingAmount: number = 0;
  maxFundingAmount: number = 0;
  minFundingYear: number = 0;
  maxFundingYear: number = 0;
  minRetailLocation: number = 0;
  maxRetailLocation: number = 0;
  minJobPostings: number = 0;
  maxJobPostings: number = 0;
  jobPostedFromDate: D3EDate = null;
  jobPostedToDate: D3EDate = null;
  minEmployeeRange: number = 0;
  maxEmployeeRange: number = 0;
  filtersCount: number = 0;
  hackValue: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ApolloSearchFilterViewProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.on(
      [
        "companyTagIDs",
        "criteria",
        "criteria.accountLocation",
        "criteria.department",
        "criteria.fullName",
        "criteria.jobPostingHiringFor",
        "criteria.jobPostingLocation",
        "criteria.jobTitles",
        "criteria.languages",
        "criteria.personLocations",
        "criteria.technologies",
        "criteria.timeFrame",
        "filtersCount",
        "fundingFromDate",
        "fundingToDate",
        "hackValue",
        "industryKeyIDs",
        "jobPostedFromDate",
        "jobPostedToDate",
        "maxEmployeeRange",
        "maxFundingAmount",
        "maxFundingYear",
        "maxGrowPercentage",
        "maxJobPostings",
        "maxMonthTimeCurrentRole",
        "maxRetailLocation",
        "maxYearTimeCurrentRole",
        "maxYearsOfExperience",
        "minEmployeeRange",
        "minFundingAmount",
        "minFundingYear",
        "minGrowPercentage",
        "minJobPostings",
        "minMonthTimeCurrentRole",
        "minRetailLocation",
        "minYearTimeCurrentRole",
        "minYearsOfExperience",
        "searchJobTiltes",
        "timeZones",
      ],
      this.rebuild
    );
  }
  public setCriteria(val: SearchCriteria): void {
    let isValChanged: boolean = this.criteria !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("criteria", this.criteria, val);

    this.criteria = val;

    this.fire("criteria", this);
  }
  public setTimeZones(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.timeZones,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.timeZones.clear();

    this.timeZones.addAll(val);

    this.fire("timeZones", this);
  }
  public addToTimeZones(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.timeZones.contains(val)) this.timeZones.add(val);
    } else {
      this.timeZones.remove(this.timeZones.elementAt(index));

      this.timeZones.add(val);
    }

    this.fire("timeZones", this, val, true);
  }
  public removeFromTimeZones(val: string): void {
    this.timeZones.remove(val);

    this.fire("timeZones", this, val, false);
  }
  public setSearchJobTiltes(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.searchJobTiltes,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.searchJobTiltes.clear();

    this.searchJobTiltes.addAll(val);

    this.fire("searchJobTiltes", this);
  }
  public addToSearchJobTiltes(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.searchJobTiltes.contains(val)) this.searchJobTiltes.add(val);
    } else {
      this.searchJobTiltes.remove(this.searchJobTiltes.elementAt(index));

      this.searchJobTiltes.add(val);
    }

    this.fire("searchJobTiltes", this, val, true);
  }
  public removeFromSearchJobTiltes(val: string): void {
    this.searchJobTiltes.remove(val);

    this.fire("searchJobTiltes", this, val, false);
  }
  public setCompanyTagIDs(val: Array<CompanySearchItem>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.companyTagIDs,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("companyTagIDs", this.companyTagIDs, val);

    this.companyTagIDs.clear();

    this.companyTagIDs.addAll(val);

    this.fire("companyTagIDs", this);
  }
  public addToCompanyTagIDs(val: CompanySearchItem, index: number = -1): void {
    if (index === -1) {
      if (!this.companyTagIDs.contains(val)) this.companyTagIDs.add(val);
    } else {
      this.companyTagIDs.remove(this.companyTagIDs.elementAt(index));

      this.companyTagIDs.add(val);
    }

    this.fire("companyTagIDs", this, val, true);

    this.updateObservable("companyTagIDs", null, val);
  }
  public removeFromCompanyTagIDs(val: CompanySearchItem): void {
    this.companyTagIDs.remove(val);

    this.fire("companyTagIDs", this, val, false);

    this.removeObservable("companyTagIDs", val);
  }
  public setMinYearsOfExperience(val: number): void {
    let isValChanged: boolean = this.minYearsOfExperience !== val;

    if (!isValChanged) {
      return;
    }

    this.minYearsOfExperience = val;

    this.fire("minYearsOfExperience", this);
  }
  public setMaxYearsOfExperience(val: number): void {
    let isValChanged: boolean = this.maxYearsOfExperience !== val;

    if (!isValChanged) {
      return;
    }

    this.maxYearsOfExperience = val;

    this.fire("maxYearsOfExperience", this);
  }
  public setMinYearTimeCurrentRole(val: number): void {
    let isValChanged: boolean = this.minYearTimeCurrentRole !== val;

    if (!isValChanged) {
      return;
    }

    this.minYearTimeCurrentRole = val;

    this.fire("minYearTimeCurrentRole", this);
  }
  public setMaxYearTimeCurrentRole(val: number): void {
    let isValChanged: boolean = this.maxYearTimeCurrentRole !== val;

    if (!isValChanged) {
      return;
    }

    this.maxYearTimeCurrentRole = val;

    this.fire("maxYearTimeCurrentRole", this);
  }
  public setMinMonthTimeCurrentRole(val: number): void {
    let isValChanged: boolean = this.minMonthTimeCurrentRole !== val;

    if (!isValChanged) {
      return;
    }

    this.minMonthTimeCurrentRole = val;

    this.fire("minMonthTimeCurrentRole", this);
  }
  public setMaxMonthTimeCurrentRole(val: number): void {
    let isValChanged: boolean = this.maxMonthTimeCurrentRole !== val;

    if (!isValChanged) {
      return;
    }

    this.maxMonthTimeCurrentRole = val;

    this.fire("maxMonthTimeCurrentRole", this);
  }
  public setIndustryKeyIDs(val: Array<IndustryResultItem>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.industryKeyIDs,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("industryKeyIDs", this.industryKeyIDs, val);

    this.industryKeyIDs.clear();

    this.industryKeyIDs.addAll(val);

    this.fire("industryKeyIDs", this);
  }
  public addToIndustryKeyIDs(
    val: IndustryResultItem,
    index: number = -1
  ): void {
    if (index === -1) {
      if (!this.industryKeyIDs.contains(val)) this.industryKeyIDs.add(val);
    } else {
      this.industryKeyIDs.remove(this.industryKeyIDs.elementAt(index));

      this.industryKeyIDs.add(val);
    }

    this.fire("industryKeyIDs", this, val, true);

    this.updateObservable("industryKeyIDs", null, val);
  }
  public removeFromIndustryKeyIDs(val: IndustryResultItem): void {
    this.industryKeyIDs.remove(val);

    this.fire("industryKeyIDs", this, val, false);

    this.removeObservable("industryKeyIDs", val);
  }
  public setMinGrowPercentage(val: number): void {
    let isValChanged: boolean = this.minGrowPercentage !== val;

    if (!isValChanged) {
      return;
    }

    this.minGrowPercentage = val;

    this.fire("minGrowPercentage", this);
  }
  public setMaxGrowPercentage(val: number): void {
    let isValChanged: boolean = this.maxGrowPercentage !== val;

    if (!isValChanged) {
      return;
    }

    this.maxGrowPercentage = val;

    this.fire("maxGrowPercentage", this);
  }
  public setFundingFromDate(val: D3EDate): void {
    let isValChanged: boolean = this.fundingFromDate !== val;

    if (!isValChanged) {
      return;
    }

    this.fundingFromDate = val;

    this.fire("fundingFromDate", this);
  }
  public setFundingToDate(val: D3EDate): void {
    let isValChanged: boolean = this.fundingToDate !== val;

    if (!isValChanged) {
      return;
    }

    this.fundingToDate = val;

    this.fire("fundingToDate", this);
  }
  public setMinFundingAmount(val: number): void {
    let isValChanged: boolean = this.minFundingAmount !== val;

    if (!isValChanged) {
      return;
    }

    this.minFundingAmount = val;

    this.fire("minFundingAmount", this);
  }
  public setMaxFundingAmount(val: number): void {
    let isValChanged: boolean = this.maxFundingAmount !== val;

    if (!isValChanged) {
      return;
    }

    this.maxFundingAmount = val;

    this.fire("maxFundingAmount", this);
  }
  public setMinFundingYear(val: number): void {
    let isValChanged: boolean = this.minFundingYear !== val;

    if (!isValChanged) {
      return;
    }

    this.minFundingYear = val;

    this.fire("minFundingYear", this);
  }
  public setMaxFundingYear(val: number): void {
    let isValChanged: boolean = this.maxFundingYear !== val;

    if (!isValChanged) {
      return;
    }

    this.maxFundingYear = val;

    this.fire("maxFundingYear", this);
  }
  public setMinRetailLocation(val: number): void {
    let isValChanged: boolean = this.minRetailLocation !== val;

    if (!isValChanged) {
      return;
    }

    this.minRetailLocation = val;

    this.fire("minRetailLocation", this);
  }
  public setMaxRetailLocation(val: number): void {
    let isValChanged: boolean = this.maxRetailLocation !== val;

    if (!isValChanged) {
      return;
    }

    this.maxRetailLocation = val;

    this.fire("maxRetailLocation", this);
  }
  public setMinJobPostings(val: number): void {
    let isValChanged: boolean = this.minJobPostings !== val;

    if (!isValChanged) {
      return;
    }

    this.minJobPostings = val;

    this.fire("minJobPostings", this);
  }
  public setMaxJobPostings(val: number): void {
    let isValChanged: boolean = this.maxJobPostings !== val;

    if (!isValChanged) {
      return;
    }

    this.maxJobPostings = val;

    this.fire("maxJobPostings", this);
  }
  public setJobPostedFromDate(val: D3EDate): void {
    let isValChanged: boolean = this.jobPostedFromDate !== val;

    if (!isValChanged) {
      return;
    }

    this.jobPostedFromDate = val;

    this.fire("jobPostedFromDate", this);
  }
  public setJobPostedToDate(val: D3EDate): void {
    let isValChanged: boolean = this.jobPostedToDate !== val;

    if (!isValChanged) {
      return;
    }

    this.jobPostedToDate = val;

    this.fire("jobPostedToDate", this);
  }
  public setMinEmployeeRange(val: number): void {
    let isValChanged: boolean = this.minEmployeeRange !== val;

    if (!isValChanged) {
      return;
    }

    this.minEmployeeRange = val;

    this.fire("minEmployeeRange", this);
  }
  public setMaxEmployeeRange(val: number): void {
    let isValChanged: boolean = this.maxEmployeeRange !== val;

    if (!isValChanged) {
      return;
    }

    this.maxEmployeeRange = val;

    this.fire("maxEmployeeRange", this);
  }
  public setFiltersCount(val: number): void {
    let isValChanged: boolean = this.filtersCount !== val;

    if (!isValChanged) {
      return;
    }

    this.filtersCount = val;

    this.fire("filtersCount", this);
  }
  public setHackValue(val: boolean): void {
    let isValChanged: boolean = this.hackValue !== val;

    if (!isValChanged) {
      return;
    }

    this.hackValue = val;

    this.fire("hackValue", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            TextView({
              data: "Filters",
              style: new ui.TextStyle({
                fontSize: 16,
                fontWeight: ui.FontWeight.bold,
              }),
              className: "x0a",
              key: "0",
            }),
            this.filtersCount > 0
              ? ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(5.0, 0.0, 0.0, 0.0, new Map()),
                  child: RemoveFilterView({
                    filtersCount: this.filtersCount,
                    onCancel: () => {
                      this.removeFilterHandler(this.d3eState);
                    },
                  }),
                  className: "xfc",
                })
              : [],
            SubmitButtonWithState({
              d3eState: this.d3eState,
              _onSubmitHandler: this.onSubmitHandler,
              key: "2",
            }),
          ],
          className: "x66c",
          key: "0",
        }),
        ui.Container({
          height: 1,
          margin: ui.EdgeInsets.symmetric({
            horizontal: 0.0,
            vertical: 5.0,
            transitions: new Map(),
          }),
          child: Divider({}),
          key: "1",
          className: "x39 vc h",
        }),
        ScrollView2({
          child: ui.Column({
            crossAxisAlignment: ui.CrossAxisAlignment.start,
            children: [
              TextView({
                data: "Person Info",
                style: new ui.TextStyle({
                  fontSize: 16,
                  fontWeight: ui.FontWeight.bold,
                }),
                className: "x04",
                key: "0",
              }),
              SearchFilterButtonView({
                filterName: "Name",
                icon: MaterialIcons.person_outline,
                color: cStyle.c8,
                content: ui.Column({
                  children: [
                    SearchComponent({
                      value: this.criteria.fullName,
                      placeholder: "Search for a name",
                      onCancel: () => {
                        this.onCancelSearchWithNameHandler(this.d3eState);
                      },
                      onChanged: (text) => {
                        this.searchWithNameonChanged(text, this.d3eState);
                      },
                      key: this.hackValue ? "1" : "",
                      className: "x10 h",
                    }),
                  ],
                  className: "xa9 h",
                }),
                className: "x9a h",
                key: "1",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "Locations",
                  icon: MaterialIcons.location_on,
                  color: cStyle.c8,
                  content: ui.Column({
                    children: [
                      CollectionLocationView({
                        name: "City/State/Country",
                        value: this.criteria.personLocations,
                        placeHolder: "Search for a location",
                        items: ApolloServicesUtils.allCountries(),
                        fieldType: InputFieldType.Rectangle,
                        onCancel: () => {
                          this.onCancelPersonLocationsHandler(this.d3eState);
                        },
                        onChanged: (text) => {
                          this.personLocationsViewonChanged(
                            text,
                            this.d3eState
                          );
                        },
                        className: "xf7 h",
                        key: "0",
                      }),
                    ],
                    className: "x5ac h",
                  }),
                }),
                key: "2",
                className: "x6c h",
              }),
              SearchFilterButtonView({
                filterName: "Total Years of Experience",
                icon: MaterialIcons.work,
                color: cStyle.c8,
                content: ui.Column({
                  children: [
                    ui.Row({
                      children: [
                        ui.Container({
                          margin: ui.EdgeInsets.fromLTRB(
                            0.0,
                            0.0,
                            10.0,
                            0.0,
                            new Map()
                          ),
                          width: 130,
                          child: LabelWithIntegerInputField({
                            placeHolder: "Min Year",
                            value: this.minYearsOfExperience,
                            onChanged: (value) => {
                              this.onChangedMinYearsOfExperienceHandler(
                                value,
                                this.d3eState
                              );
                            },
                            onSubmitted: (value) => {
                              this.onMinYearsOfExperience(value, this.d3eState);
                            },
                          }),
                          key: "0",
                          className: "x7a7 hc",
                        }),
                        ui.Container({
                          width: 130,
                          child: LabelWithIntegerInputField({
                            placeHolder: "Max Year",
                            value: this.maxYearsOfExperience,
                            onChanged: (value) => {
                              this.onChangedMaxYearsOfExperienceHandler(
                                value,
                                this.d3eState
                              );
                            },
                            onSubmitted: (value) => {
                              this.onMaxYearsOfExperience(value, this.d3eState);
                            },
                          }),
                          key: "1",
                          className: "x315 hc",
                        }),
                      ],
                      className: "x87a",
                      key: "0",
                    }),
                  ],
                }),
                className: "x94",
                key: "3",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "Companies",
                  icon: MaterialIcons.location_on,
                  color: cStyle.c8,
                  content: ui.Column({
                    children: [
                      CollectionCompanyView({
                        name: "Is any of",
                        value: this.companyTagIDs,
                        placeHolder: "Search for a company",
                        items: [],
                        fieldType: InputFieldType.Rectangle,
                        onCancel: () => {
                          this.onCancelCompanyViewHandler(this.d3eState);
                        },
                        onChanged: (text) => {
                          this.companyViewonChanged(text, this.d3eState);
                        },
                        key: this.hackValue ? "0" : "",
                        className: "xc8 h",
                      }),
                    ],
                    className: "xfe2 h",
                  }),
                }),
                key: "4",
                className: "xda h",
              }),
              SearchFilterButtonView({
                filterName: "TimeZones",
                icon: MaterialIcons.language,
                color: cStyle.c8,
                content: ui.Column({
                  children: [
                    CollectionView<string>({
                      name: "Is any of",
                      value: this.timeZones,
                      placeHolder: "Time Zones",
                      items: ApolloServicesUtils.allTimeZones(),
                      fieldType: InputFieldType.Rectangle,
                      onCancel: () => {
                        this.onCancelTimeZonesViewHandler(this.d3eState);
                      },
                      onChanged: (text) => {
                        this.timeZonesViewonChanged(text, this.d3eState);
                      },
                      className: "x00 h",
                      key: "0",
                    }),
                  ],
                  className: "xb6 h",
                }),
                className: "x80 h",
                key: "5",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "Account Location",
                  icon: MaterialIcons.place,
                  color: cStyle.c8,
                  content: ui.Column({
                    children: [
                      CollectionLocationView({
                        name: "Is any of",
                        value: this.criteria.accountLocation,
                        placeHolder: "Search for a location",
                        items: ApolloServicesUtils.allCountries(),
                        fieldType: InputFieldType.Rectangle,
                        onCancel: () => {
                          this.onCancelAccountLocationViewHandler(
                            this.d3eState
                          );
                        },
                        onChanged: (text) => {
                          this.accountLocationViewonChanged(
                            text,
                            this.d3eState
                          );
                        },
                        className: "x8d2 h",
                        key: "0",
                      }),
                    ],
                    className: "xf7e h",
                  }),
                }),
                key: "6",
                className: "xd1 h",
              }),
              SearchFilterButtonView({
                filterName: "Job Posting Hiring For",
                icon: MaterialIcons.work,
                color: cStyle.c8,
                content: ui.Column({
                  children: [
                    CollectionSearchView({
                      name: "Currently hiring for",
                      value: this.criteria.jobPostingHiringFor,
                      placeHolder: "Search for a job",
                      items: [
                        ...ApolloServicesUtils.allJobTitles(),
                        ...this.searchJobTiltes,
                      ],
                      fieldType: InputFieldType.Rectangle,
                      onCancel: () => {
                        this.onCancelJobPostingHiringForViewHandler(
                          this.d3eState
                        );
                      },
                      onChanged: (text) => {
                        this.jobPostingHiringForViewonChanged(
                          text,
                          this.d3eState
                        );
                      },
                      className: "x4a h",
                      key: "0",
                    }),
                  ],
                  className: "x8b h",
                }),
                className: "xc9 h",
                key: "7",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "Job Posting Location",
                  icon: MaterialIcons.location_on,
                  color: cStyle.c8,
                  content: ui.Column({
                    children: [
                      CollectionLocationView({
                        name: "Job located at",
                        value: this.criteria.jobPostingLocation,
                        placeHolder: "Search for a location",
                        items: ApolloServicesUtils.allCountries(),
                        fieldType: InputFieldType.Rectangle,
                        onCancel: () => {
                          this.onCancelJobPostingLocationViewHandler(
                            this.d3eState
                          );
                        },
                        onChanged: (text) => {
                          this.jobPostingLocationViewonChanged(
                            text,
                            this.d3eState
                          );
                        },
                        className: "x6e h",
                        key: "0",
                      }),
                    ],
                    className: "x3e8 h",
                  }),
                }),
                key: "8",
                className: "xb17 h",
              }),
              SearchFilterButtonView({
                filterName: "Job Titles",
                icon: MaterialIcons.work_outline,
                color: cStyle.c8,
                content: ui.Column({
                  children: [
                    CollectionSearchView({
                      name: "Is any of",
                      value: this.criteria.jobTitles,
                      placeHolder: "Search for a job",
                      items: ApolloServicesUtils.allJobTitles(),
                      fieldType: InputFieldType.Rectangle,
                      onCancel: () => {
                        this.onCancelJobTitlesViewHandler(this.d3eState);
                      },
                      onChanged: (text) => {
                        this.jobTitlesViewonChanged(text, this.d3eState);
                      },
                      className: "x88 h",
                      key: "0",
                    }),
                  ],
                  className: "xe1 h",
                }),
                className: "xfec h",
                key: "9",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "Time in Current Role",
                  icon: MaterialIcons.calendar_today,
                  color: cStyle.c8,
                  content: ui.Row({
                    children: [
                      ui.Column({
                        crossAxisAlignment: ui.CrossAxisAlignment.start,
                        children: [
                          ui.Container({
                            margin: ui.EdgeInsets.fromLTRB(
                              0.0,
                              0.0,
                              10.0,
                              0.0,
                              new Map()
                            ),
                            width: 140,
                            child: LabelWithIntegerInputField({
                              name: "Years",
                              placeHolder: "Min Year",
                              value: this.minYearTimeCurrentRole,
                              onChanged: (value) => {
                                this.onChangedMinYearTimeCurrentRoleHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                              onSubmitted: (value) => {
                                this.onMinYearTimeCurrentRole(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "0",
                            className: "x07 hc",
                          }),
                          ui.Container({
                            margin: ui.EdgeInsets.fromLTRB(
                              0.0,
                              0.0,
                              10.0,
                              0.0,
                              new Map()
                            ),
                            width: 140,
                            child: LabelWithIntegerInputField({
                              placeHolder: "Max Year",
                              value: this.maxYearTimeCurrentRole,
                              onChanged: (value) => {
                                this.onChangedMaxYearTimeCurrentRoleHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                              onSubmitted: (value) => {
                                this.onMaxYearTimeCurrentRole(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "1",
                            className: "xb77 hc",
                          }),
                        ],
                        key: "0",
                      }),
                      ui.Column({
                        children: [
                          ui.Container({
                            width: 140,
                            child: LabelWithIntegerInputField({
                              name: "Months",
                              placeHolder: "Min Month",
                              value: this.minMonthTimeCurrentRole,
                              onChanged: (value) => {
                                this.onChangedMinMonthTimeCurrentRoleHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                              onSubmitted: (value) => {
                                this.onMinMonthTimeCurrentRole(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "0",
                            className: "x70 hc",
                          }),
                          ui.Container({
                            width: 140,
                            child: LabelWithIntegerInputField({
                              placeHolder: "Max Month",
                              value: this.maxMonthTimeCurrentRole,
                              onChanged: (value) => {
                                this.onChangedMaxMonthTimeCurrentRoleHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                              onSubmitted: (value) => {
                                this.onMaxMonthTimeCurrentRole(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "1",
                            className: "x100 hc",
                          }),
                        ],
                        key: "1",
                      }),
                    ],
                  }),
                }),
                key: "10",
                className: "x93",
              }),
              TextView({
                data: "Company Info",
                style: new ui.TextStyle({
                  fontSize: 16,
                  fontWeight: ui.FontWeight.bold,
                }),
                className: "xe7",
                key: "11",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "Industry & Keywords",
                  icon: MaterialIcons.construction,
                  color: cStyle.c8,
                  content: ui.Column({
                    children: [
                      CollectionIndustriesView({
                        name: "Is any of",
                        value: this.industryKeyIDs,
                        placeHolder: "Search for a keyword",
                        items: [],
                        fieldType: InputFieldType.Rectangle,
                        onCancel: () => {
                          this.onCancelKeywordsViewHandler(this.d3eState);
                        },
                        onChanged: (text) => {
                          this.keywordsViewonChanged(text, this.d3eState);
                        },
                        className: "x562 h",
                        key: "0",
                      }),
                    ],
                    className: "xacf h",
                  }),
                }),
                key: "12",
                className: "xcd h",
              }),
              SearchFilterButtonView({
                filterName: "Technologies",
                icon: MaterialIcons.biotech,
                color: cStyle.c8,
                content: ui.Column({
                  children: [
                    CollectionTechnologieView({
                      name: "Is any of",
                      value: this.criteria.technologies,
                      placeHolder: "Search for a technology",
                      items: [],
                      fieldType: InputFieldType.Rectangle,
                      onCancel: () => {
                        this.onCancelTechnologiesViewHandler(this.d3eState);
                      },
                      onChanged: (text) => {
                        this.technologiesViewonChanged(text, this.d3eState);
                      },
                      className: "x485 h",
                      key: "0",
                    }),
                  ],
                  className: "x5c h",
                }),
                className: "xd3b h",
                key: "13",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "Headcount Growth",
                  icon: MaterialIcons.trending_up,
                  color: cStyle.c8,
                  content: ui.Column({
                    crossAxisAlignment: ui.CrossAxisAlignment.start,
                    children: [
                      TextView({
                        data: "Growth Range",
                        className: "xa1",
                        key: "0",
                      }),
                      ui.Row({
                        children: [
                          ui.Container({
                            margin: ui.EdgeInsets.fromLTRB(
                              0.0,
                              0.0,
                              10.0,
                              0.0,
                              new Map()
                            ),
                            width: 130,
                            child: LabelWithIntegerInputField({
                              placeHolder: "Min(%)",
                              value: this.minGrowPercentage,
                              onChanged: (value) => {
                                this.onChangedMinGrowPercentageHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "0",
                            className: "xd11 hc",
                          }),
                          ui.Container({
                            width: 130,
                            child: LabelWithIntegerInputField({
                              placeHolder: "Max(%)",
                              value: this.maxGrowPercentage,
                              onChanged: (value) => {
                                this.onChangedMaxGrowPercentageHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "1",
                            className: "x9b7 hc",
                          }),
                        ],
                        className: "x49",
                        key: "1",
                      }),
                      TextView({
                        data: "Timeframe",
                        className: "x1ad",
                        key: "2",
                      }),
                      ui.Row({
                        mainAxisAlignment: ui.MainAxisAlignment.start,
                        crossAxisAlignment: ui.CrossAxisAlignment.center,
                        children: [
                          TextView({
                            data: "Past",
                            className: "x99",
                            key: "0",
                          }),
                          ui.Container({
                            margin: ui.EdgeInsets.symmetric({
                              horizontal: 5.0,
                              vertical: 0.0,
                              transitions: new Map(),
                            }),
                            child: LabelDropdown<string>({
                              value: this.criteria.timeFrame,
                              items: ["6", "12", "24"],
                              width: 70,
                              onChanged: (value) => {
                                this.onCancelLabelDropdownHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                              builder: (context, item) => {
                                return TextView({ data: item });
                              },
                            }),
                            key: "1",
                            className: "x05",
                          }),
                          TextView({
                            data: "Months",
                            className: "xda1",
                            key: "2",
                          }),
                        ],
                        className: "x038",
                        key: "3",
                      }),
                      LabelDropdown<string>({
                        name: "Department",
                        value: this.criteria.department,
                        placeHolder: "Select Department",
                        items: [
                          "C-Suite",
                          "Product",
                          "Engineering & Technical",
                          "Design",
                          "Education",
                          "Finance",
                          "Human Resources",
                          "Information Technology",
                          "Legal",
                          "Marketing",
                          "Medical & Health",
                          "Sales",
                          "Operations",
                          "Consulting",
                        ],
                        onChanged: (value) => {
                          this.onCancelDepartmentDropdownHandler(
                            value,
                            this.d3eState
                          );
                        },
                        builder: (context, item) => {
                          return TextView({ data: item });
                        },
                        className: "x668",
                        key: "4",
                      }),
                    ],
                  }),
                }),
                key: "14",
                className: "x4d",
              }),
              SearchFilterButtonView({
                filterName: "Funding",
                icon: MaterialIcons.local_atm,
                color: cStyle.c8,
                content: ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    TextView({
                      data: "Last funding round date",
                      className: "x9a7",
                      key: "0",
                    }),
                    ui.Row({
                      children: [
                        LabelWithDateField({
                          name: "",
                          placeHolder: "From",
                          value: this.fundingFromDate,
                          format: "yyyy-MM-dd",
                          onChanged: (value) => {
                            this.onChangedLabelWithDateField5Handler(
                              value,
                              this.d3eState
                            );
                          },
                          key: "0",
                        }),
                        LabelWithDateField({
                          name: "",
                          placeHolder: "To",
                          value: this.fundingToDate,
                          format: "yyyy-MM-dd",
                          onChanged: (value) => {
                            this.onChangedLabelWithDateField6Handler(
                              value,
                              this.d3eState
                            );
                          },
                          key: "1",
                        }),
                      ],
                      className: "xfa",
                      key: "1",
                    }),
                    TextView({
                      data: "Last funding round amount ($)",
                      className: "xa4",
                      key: "2",
                    }),
                    ui.Row({
                      children: [
                        ui.Container({
                          margin: ui.EdgeInsets.fromLTRB(
                            0.0,
                            0.0,
                            10.0,
                            0.0,
                            new Map()
                          ),
                          width: 130,
                          child: LabelWithIntegerInputField({
                            placeHolder: "Min",
                            value: this.minFundingAmount,
                            onChanged: (value) => {
                              this.onChangedLabelWithIntegerInputField8Handler(
                                value,
                                this.d3eState
                              );
                            },
                          }),
                          key: "0",
                          className: "x97e hc",
                        }),
                        ui.Container({
                          width: 130,
                          child: LabelWithIntegerInputField({
                            placeHolder: "Max",
                            value: this.maxFundingAmount,
                            onChanged: (value) => {
                              this.onChangedLabelWithIntegerInputField9Handler(
                                value,
                                this.d3eState
                              );
                            },
                          }),
                          key: "1",
                          className: "xb6c hc",
                        }),
                      ],
                      className: "xcdc",
                      key: "3",
                    }),
                  ],
                }),
                className: "x6d",
                key: "15",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "Founded Year",
                  icon: MaterialIcons.calendar_today,
                  color: cStyle.c8,
                  content: ui.Column({
                    children: [
                      ui.Row({
                        children: [
                          ui.Container({
                            margin: ui.EdgeInsets.fromLTRB(
                              0.0,
                              0.0,
                              10.0,
                              0.0,
                              new Map()
                            ),
                            width: 130,
                            child: LabelWithIntegerInputField({
                              placeHolder: "Min Year",
                              value: this.minFundingYear,
                              onChanged: (value) => {
                                this.onChangedLabelWithIntegerInputField10Handler(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "0",
                            className: "xd8 hc",
                          }),
                          ui.Container({
                            width: 130,
                            child: LabelWithIntegerInputField({
                              placeHolder: "Max Year",
                              value: this.maxFundingYear,
                              onChanged: (value) => {
                                this.onChangedLabelWithIntegerInputField11Handler(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "1",
                            className: "x9bb hc",
                          }),
                        ],
                        className: "x568",
                        key: "0",
                      }),
                    ],
                  }),
                }),
                key: "16",
                className: "xb05",
              }),
              SearchFilterButtonView({
                filterName: "Languages",
                icon: MaterialIcons.translate,
                color: cStyle.c8,
                content: ui.Column({
                  children: [
                    CollectionView<string>({
                      name: "Is any of",
                      value: this.criteria.languages,
                      placeHolder: "Search for a language",
                      items: ApolloServicesUtils.allLanguages(),
                      fieldType: InputFieldType.Rectangle,
                      onCancel: () => {
                        this.onCancelLanguagesViewHandler(this.d3eState);
                      },
                      onChanged: (text) => {
                        this.languagesViewonChanged(text, this.d3eState);
                      },
                      className: "x77b h",
                      key: "0",
                    }),
                  ],
                  className: "x52 h",
                }),
                className: "x32e h",
                key: "17",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "Retail Locations",
                  icon: MaterialIcons.location_on,
                  color: cStyle.c8,
                  content: ui.Column({
                    children: [
                      ui.Row({
                        children: [
                          ui.Container({
                            margin: ui.EdgeInsets.fromLTRB(
                              0.0,
                              0.0,
                              10.0,
                              0.0,
                              new Map()
                            ),
                            width: 100,
                            child: LabelWithIntegerInputField({
                              placeHolder: "Min",
                              value: this.minRetailLocation,
                              onChanged: (value) => {
                                this.onChangedMinRetailLocationHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "0",
                            className: "xfb hc",
                          }),
                          ui.Container({
                            width: 100,
                            child: LabelWithIntegerInputField({
                              placeHolder: "Max",
                              value: this.maxRetailLocation,
                              onChanged: (value) => {
                                this.onChangedMaxRetailLocationHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "1",
                            className: "x2c hc",
                          }),
                        ],
                        className: "x6ed",
                        key: "0",
                      }),
                    ],
                  }),
                }),
                key: "18",
                className: "x2e",
              }),
              SearchFilterButtonView({
                filterName: "Job Posting date& range",
                icon: MaterialIcons.work,
                color: cStyle.c8,
                content: ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    TextView({
                      data: "Active job postings",
                      className: "x15",
                      key: "0",
                    }),
                    ui.Row({
                      children: [
                        ui.Container({
                          margin: ui.EdgeInsets.fromLTRB(
                            0.0,
                            0.0,
                            10.0,
                            0.0,
                            new Map()
                          ),
                          width: 130,
                          child: LabelWithIntegerInputField({
                            placeHolder: "Min",
                            value: this.minJobPostings,
                            onChanged: (value) => {
                              this.onChangedMinJobPostingsHandler(
                                value,
                                this.d3eState
                              );
                            },
                          }),
                          key: "0",
                          className: "x2b hc",
                        }),
                        ui.Container({
                          width: 130,
                          child: LabelWithIntegerInputField({
                            placeHolder: "Max",
                            value: this.maxJobPostings,
                            onChanged: (value) => {
                              this.onChangedMaxJobPostingsHandler(
                                value,
                                this.d3eState
                              );
                            },
                          }),
                          key: "1",
                          className: "x615 hc",
                        }),
                      ],
                      className: "x691",
                      key: "1",
                    }),
                    TextView({
                      data: "Job Posted At",
                      className: "x271",
                      key: "2",
                    }),
                    ui.Row({
                      children: [
                        LabelWithDateField({
                          name: "",
                          placeHolder: "From",
                          value: this.jobPostedFromDate,
                          format: "yyyy-MM-dd",
                          onChanged: (value) => {
                            this.onChangedJobPostedFromDateHandler(
                              value,
                              this.d3eState
                            );
                          },
                          key: "0",
                        }),
                        LabelWithDateField({
                          name: "",
                          placeHolder: "To",
                          value: this.jobPostedToDate,
                          format: "yyyy-MM-dd",
                          onChanged: (value) => {
                            this.onChangedJobPostedToDateHandler(
                              value,
                              this.d3eState
                            );
                          },
                          key: "1",
                        }),
                      ],
                      className: "xf64",
                      key: "3",
                    }),
                  ],
                }),
                className: "x804",
                key: "19",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "Employee Range",
                  icon: MaterialIcons.people,
                  color: cStyle.c8,
                  content: ui.Column({
                    children: [
                      ui.Row({
                        children: [
                          ui.Container({
                            margin: ui.EdgeInsets.fromLTRB(
                              0.0,
                              0.0,
                              10.0,
                              0.0,
                              new Map()
                            ),
                            width: 130,
                            child: LabelWithIntegerInputField({
                              placeHolder: "Min",
                              value: this.minEmployeeRange,
                              onChanged: (value) => {
                                this.onChangedMinEmployeeRangeHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "0",
                            className: "x7e hc",
                          }),
                          ui.Container({
                            width: 130,
                            child: LabelWithIntegerInputField({
                              placeHolder: "Max",
                              value: this.maxEmployeeRange,
                              onChanged: (value) => {
                                this.onChangedMaxEmployeeRangeHandler(
                                  value,
                                  this.d3eState
                                );
                              },
                            }),
                            key: "1",
                            className: "xb6d hc",
                          }),
                        ],
                        className: "x052",
                        key: "0",
                      }),
                    ],
                  }),
                }),
                key: "20",
                className: "xdd",
              }),
              ui.Container({
                margin: ui.EdgeInsets.symmetric({
                  horizontal: 0.0,
                  vertical: 10.0,
                  transitions: new Map(),
                }),
                child: SearchFilterButtonView({
                  filterName: "SIC Codes",
                  icon: MaterialIcons.code,
                  color: cStyle.c8,
                  states: ui.joinStates({ "data-visibility": false }, {}),
                  content: ui.Column({
                    children: [
                      CollectionView<string>({
                        name: "Is any of",
                        value: this.criteria.personLocations,
                        placeHolder: "Search SIC Codes",
                        items: [],
                        fieldType: InputFieldType.Rectangle,
                        onCancel: () => {
                          this.onCancelSICCodesViewHandler(this.d3eState);
                        },
                        onChanged: (text) => {
                          this.sICCodesViewonChanged(text, this.d3eState);
                        },
                        className: "xa02 h",
                        key: "0",
                      }),
                      CollectionView<string>({
                        name: "Is not any of",
                        value: this.criteria.personLocations,
                        placeHolder: "Search SIC Codes to exclude",
                        items: [],
                        fieldType: InputFieldType.Rectangle,
                        onCancel: () => {
                          this.onCancelSICCodesView1Handler(this.d3eState);
                        },
                        onChanged: (text) => {
                          this.sICCodesView1onChanged(text, this.d3eState);
                        },
                        className: "x0d h",
                        key: "1",
                      }),
                    ],
                    className: "x7d8 h",
                  }),
                }),
                key: "21",
                className: "x25 h",
                states: ui.joinStates({ "data-visibility": false }, {}),
              }),
              SearchFilterButtonView({
                filterName: "News",
                icon: MaterialIcons.people,
                color: cStyle.c8,
                states: ui.joinStates({ "data-visibility": false }, {}),
                content: ui.Column({}),
                className: "x967",
                key: "22",
              }),
            ],
            key: "2",
          }),
          scrollDirection: ui.Axis.vertical,
          className: "xa2 vc h v",
          controller: this.d3eState.personalInfoViewScrollController,
        }),
      ],
      className: ui.join(
        this.props.className,
        "ApolloSearchFilterView x3e vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setCriteria(new SearchCriteria());
  };
  public onCancelSearchWithNameHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setFullName("");

    //  the filters count should be 0

    if (this.filtersCount > 0) {
      this.filtersCount--;
    }
  };
  public onCancelPersonLocationsHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.criteria.personLocations.isEmpty) {
      this.filtersCount--;
    }
  };
  public onChangedMinYearsOfExperienceHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinYearsOfExperience(value);

    if (
      this.filtersCount > 0 &&
      this.minYearsOfExperience === 0 &&
      this.maxYearsOfExperience === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedMaxYearsOfExperienceHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxYearsOfExperience(value);

    if (
      this.filtersCount > 0 &&
      this.minYearsOfExperience === 0 &&
      this.maxYearsOfExperience === 0
    ) {
      this.filtersCount--;
    }
  };
  public onCancelCompanyViewHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.companyTagIDs.isEmpty) {
      this.filtersCount--;
    }
  };
  public onCancelTimeZonesViewHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.timeZones.isEmpty) {
      this.filtersCount--;
    }
  };
  public onCancelAccountLocationViewHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.criteria.accountLocation.isEmpty) {
      this.filtersCount--;
    }
  };
  public onCancelJobPostingHiringForViewHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.criteria.jobPostingHiringFor.isEmpty) {
      this.filtersCount--;
    }
  };
  public onCancelJobPostingLocationViewHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.criteria.jobPostingLocation.isEmpty) {
      this.filtersCount--;
    }
  };
  public onCancelJobTitlesViewHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.criteria.jobTitles.isEmpty) {
      this.filtersCount--;
    }
  };
  public onChangedMinYearTimeCurrentRoleHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinYearTimeCurrentRole(value);

    if (
      this.filtersCount > 0 &&
      this.minYearTimeCurrentRole === 0 &&
      this.maxYearTimeCurrentRole === 0 &&
      this.minMonthTimeCurrentRole === 0 &&
      this.maxMonthTimeCurrentRole === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedMaxYearTimeCurrentRoleHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxYearTimeCurrentRole(value);

    if (
      this.filtersCount > 0 &&
      this.minYearTimeCurrentRole === 0 &&
      this.maxYearTimeCurrentRole === 0 &&
      this.minMonthTimeCurrentRole === 0 &&
      this.maxMonthTimeCurrentRole === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedMinMonthTimeCurrentRoleHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinMonthTimeCurrentRole(value);

    if (
      this.filtersCount > 0 &&
      this.minYearTimeCurrentRole === 0 &&
      this.maxYearTimeCurrentRole === 0 &&
      this.minMonthTimeCurrentRole === 0 &&
      this.maxMonthTimeCurrentRole === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedMaxMonthTimeCurrentRoleHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxMonthTimeCurrentRole(value);

    if (
      this.filtersCount > 0 &&
      this.minYearTimeCurrentRole === 0 &&
      this.maxYearTimeCurrentRole === 0 &&
      this.minMonthTimeCurrentRole === 0 &&
      this.maxMonthTimeCurrentRole === 0
    ) {
      this.filtersCount--;
    }
  };
  public onCancelKeywordsViewHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.industryKeyIDs.isEmpty) {
      this.filtersCount--;
    }
  };
  public onCancelTechnologiesViewHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.criteria.technologies.isEmpty) {
      this.filtersCount--;
    }
  };
  public onChangedMinGrowPercentageHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinGrowPercentage(value);

    if (
      this.filtersCount > 0 &&
      this.minGrowPercentage === 0 &&
      this.maxGrowPercentage === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedMaxGrowPercentageHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxGrowPercentage(value);

    if (
      this.filtersCount > 0 &&
      this.minGrowPercentage === 0 &&
      this.maxGrowPercentage === 0
    ) {
      this.filtersCount--;
    }
  };
  public onCancelLabelDropdownHandler = (
    value: string,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setTimeFrame(value);

    if (this.filtersCount > 0 && this.criteria.timeFrame.isEmpty) {
      this.filtersCount--;
    }
  };
  public onCancelDepartmentDropdownHandler = (
    value: string,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setDepartment(value);

    if (this.filtersCount > 0 && this.criteria.department.isEmpty) {
      this.filtersCount--;
    }
  };
  public onChangedLabelWithDateField5Handler = (
    value: D3EDate,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setFundingFromDate(value);

    if (
      this.filtersCount > 0 &&
      D3EDate.equals(this.fundingFromDate, null) &&
      D3EDate.equals(this.fundingToDate, null) &&
      this.minFundingAmount === 0 &&
      this.maxFundingAmount === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedLabelWithDateField6Handler = (
    value: D3EDate,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setFundingToDate(value);

    if (
      this.filtersCount > 0 &&
      D3EDate.equals(this.fundingFromDate, null) &&
      D3EDate.equals(this.fundingToDate, null) &&
      this.minFundingAmount === 0 &&
      this.maxFundingAmount === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedLabelWithIntegerInputField8Handler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinFundingAmount(value);

    if (
      this.filtersCount > 0 &&
      D3EDate.equals(this.fundingFromDate, null) &&
      D3EDate.equals(this.fundingToDate, null) &&
      this.minFundingAmount === 0 &&
      this.maxFundingAmount === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedLabelWithIntegerInputField9Handler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxFundingAmount(value);

    if (
      this.filtersCount > 0 &&
      D3EDate.equals(this.fundingFromDate, null) &&
      D3EDate.equals(this.fundingToDate, null) &&
      this.minFundingAmount === 0 &&
      this.maxFundingAmount === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedLabelWithIntegerInputField10Handler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinFundingYear(value);

    if (
      this.filtersCount > 0 &&
      this.minFundingYear === 0 &&
      this.maxFundingYear === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedLabelWithIntegerInputField11Handler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxFundingYear(value);

    if (
      this.filtersCount > 0 &&
      this.minFundingYear === 0 &&
      this.maxFundingYear === 0
    ) {
      this.filtersCount--;
    }
  };
  public onCancelLanguagesViewHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.criteria.languages.isEmpty) {
      this.filtersCount--;
    }
  };
  public onChangedMinRetailLocationHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinRetailLocation(value);

    if (
      this.filtersCount > 0 &&
      this.minRetailLocation === 0 &&
      this.maxRetailLocation === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedMaxRetailLocationHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxRetailLocation(value);

    if (
      this.filtersCount > 0 &&
      this.minRetailLocation === 0 &&
      this.maxRetailLocation === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedMinJobPostingsHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinJobPostings(value);

    if (
      this.filtersCount > 0 &&
      this.minJobPostings === 0 &&
      this.maxJobPostings === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedMaxJobPostingsHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxJobPostings(value);

    if (
      this.filtersCount > 0 &&
      this.minJobPostings === 0 &&
      this.maxJobPostings === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedJobPostedFromDateHandler = (
    value: D3EDate,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setJobPostedFromDate(value);

    if (
      this.filtersCount > 0 &&
      D3EDate.equals(this.jobPostedFromDate, null) &&
      D3EDate.equals(this.jobPostedToDate, null)
    ) {
      this.filtersCount--;
    }
  };
  public onChangedJobPostedToDateHandler = (
    value: D3EDate,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setJobPostedToDate(value);

    if (
      this.filtersCount > 0 &&
      D3EDate.equals(this.jobPostedFromDate, null) &&
      D3EDate.equals(this.jobPostedToDate, null)
    ) {
      this.filtersCount--;
    }
  };
  public onChangedMinEmployeeRangeHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinEmployeeRange(value);

    if (
      this.filtersCount > 0 &&
      this.minEmployeeRange === 0 &&
      this.maxEmployeeRange === 0
    ) {
      this.filtersCount--;
    }
  };
  public onChangedMaxEmployeeRangeHandler = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxEmployeeRange(value);

    if (
      this.filtersCount > 0 &&
      this.minEmployeeRange === 0 &&
      this.maxEmployeeRange === 0
    ) {
      this.filtersCount--;
    }
  };
  public onCancelSICCodesViewHandler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.criteria.personLocations.isEmpty) {
      this.filtersCount--;
    }
  };
  public onCancelSICCodesView1Handler = (
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    if (this.filtersCount > 0 && this.criteria.personLocations.isEmpty) {
      this.filtersCount--;
    }
  };
  public onSubmitHandler = (d3eState: ApolloSearchFilterViewRefs): void => {
    this.criteria.setTimeZones(
      ApolloServicesUtils.getApolloEquivalentValues(this.timeZones)
    );

    if (this.industryKeyIDs.isNotEmpty) {
      this.criteria.setKeywords(
        this.industryKeyIDs.map((item) => item.keyID).toList()
      );
    }

    if (this.companyTagIDs.isNotEmpty) {
      this.criteria.setCompanyTagIDs(
        this.companyTagIDs.map((item) => item.orgID).toList()
      );
    }

    if (this.minYearsOfExperience > 0) {
      this.criteria.setMinYearExp(this.minYearsOfExperience.toString());
    }

    if (this.maxYearsOfExperience > 0) {
      this.criteria.setMaxYearExp(this.maxYearsOfExperience.toString());
    }

    if (this.minYearTimeCurrentRole > 0) {
      this.criteria.setMinYearCurrentRole(
        (this.minYearTimeCurrentRole * 365).toString()
      );
    }

    if (this.maxYearTimeCurrentRole > 0) {
      this.criteria.setMaxYearCurrentRole(
        (this.maxYearTimeCurrentRole * 365).toString()
      );
    }

    if (this.minMonthTimeCurrentRole > 0) {
      this.criteria.setMinMonthCurrentRole(
        (this.minMonthTimeCurrentRole * 30).toString()
      );
    }

    if (this.maxMonthTimeCurrentRole > 0) {
      this.criteria.setMaxMonthCurrentRole(
        (this.maxMonthTimeCurrentRole * 30).toString()
      );
    }

    if (this.minGrowPercentage > 0) {
      this.criteria.setMinGrowPercentage(this.minGrowPercentage.toString());
    }

    if (this.maxGrowPercentage > 0) {
      this.criteria.setMaxGrowPercentage(this.maxGrowPercentage.toString());
    }

    if (this.fundingFromDate !== null) {
      this.criteria.setFundingFromDate(this.fundingFromDate.toString());
    }

    if (this.fundingToDate !== null) {
      this.criteria.setFundingToDate(this.fundingToDate.toString());
    }

    if (this.minFundingAmount > 0) {
      this.criteria.setMinFundingAmount(this.minFundingAmount.toString());
    }

    if (this.maxFundingAmount > 0) {
      this.criteria.setMaxFundingAmount(this.maxFundingAmount.toString());
    }

    if (this.minFundingYear > 0) {
      this.criteria.setMinFoundedYear(this.minFundingYear.toString());
    }

    if (this.maxFundingYear > 0) {
      this.criteria.setMaxFoundedYear(this.maxFundingYear.toString());
    }

    if (this.minRetailLocation > 0) {
      this.criteria.setMinRetailLocation(this.minRetailLocation.toString());
    }

    if (this.maxRetailLocation > 0) {
      this.criteria.setMaxRetailLocation(this.maxRetailLocation.toString());
    }

    if (this.minJobPostings > 0) {
      this.criteria.setMinJobPosting(this.minJobPostings.toString());
    }

    if (this.maxJobPostings > 0) {
      this.criteria.setMaxJobPosting(this.maxJobPostings.toString());
    }

    if (this.jobPostedFromDate !== null) {
      this.criteria.setJobPostedFromDate(this.jobPostedFromDate.toString());
    }

    if (this.jobPostedToDate !== null) {
      this.criteria.setJobPostedToDate(this.jobPostedToDate.toString());
    }

    if (this.minEmployeeRange > 0) {
      this.criteria.setMinEmployeeRange(this.minEmployeeRange.toString());
    }

    if (this.maxEmployeeRange > 0) {
      this.criteria.setMaxEmployeeRange(this.maxEmployeeRange.toString());
    }

    this.setFiltersCount(SideMenuUtils.getFilterCount(this.criteria));

    this.onSearchName(this.criteria);
  };
  public removeFilterHandler = (d3eState: ApolloSearchFilterViewRefs): void => {
    if (this.filtersCount > 0) {
      this.setHackValue(!this.hackValue);

      this.setCriteria(new SearchCriteria({ fullName: "" }));

      this.setFiltersCount(0);

      this.setMinYearsOfExperience(0);

      this.setMaxYearsOfExperience(0);

      this.setCompanyTagIDs([]);

      this.setTimeZones([]);

      this.setMinYearTimeCurrentRole(0);

      this.setMaxYearTimeCurrentRole(0);

      this.setIndustryKeyIDs([]);

      this.setMinGrowPercentage(0);

      this.setMaxGrowPercentage(0);

      this.setFundingFromDate(null);

      this.setFundingToDate(null);

      this.setMinFundingAmount(0);

      this.setMaxFundingAmount(0);

      this.setMinFundingYear(0);

      this.setMaxFundingYear(0);

      this.setMinRetailLocation(0);

      this.setMaxRetailLocation(0);

      this.setMinJobPostings(0);

      this.setMaxJobPostings(0);

      this.setJobPostedFromDate(null);

      this.setJobPostedToDate(null);

      this.setMinEmployeeRange(0);

      this.setMaxEmployeeRange(0);

      this.onSearchName(this.criteria);
    }
  };
  public onMinYearsOfExperience = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinYearsOfExperience(value);
  };
  public onMaxYearsOfExperience = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxYearsOfExperience(value);
  };
  public onMinYearTimeCurrentRole = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinYearTimeCurrentRole(value);
  };
  public onMaxYearTimeCurrentRole = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxYearTimeCurrentRole(value);
  };
  public onMinMonthTimeCurrentRole = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMinMonthTimeCurrentRole(value);
  };
  public onMaxMonthTimeCurrentRole = (
    value: number,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setMaxMonthTimeCurrentRole(value);
  };
  public get onSearchName(): _ApolloSearchFilterViewOnSearchName {
    return this.props.onSearchName;
  }
  public searchWithNameonChanged = (
    val: string,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setFullName(val);
  };
  public personLocationsViewonChanged = (
    val: Array<string>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setPersonLocations(val.map((x) => x).toList());
  };
  public companyViewonChanged = (
    val: Array<CompanySearchItem>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setCompanyTagIDs(val);
  };
  public timeZonesViewonChanged = (
    val: Array<string>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setTimeZones(val.map((x) => x).toList());
  };
  public accountLocationViewonChanged = (
    val: Array<string>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setAccountLocation(val.map((x) => x).toList());
  };
  public jobPostingHiringForViewonChanged = (
    val: Array<string>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setJobPostingHiringFor(val.map((x) => x).toList());
  };
  public jobPostingLocationViewonChanged = (
    val: Array<string>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setJobPostingLocation(val.map((x) => x).toList());
  };
  public jobTitlesViewonChanged = (
    val: Array<string>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setJobTitles(val.map((x) => x).toList());
  };
  public keywordsViewonChanged = (
    val: Array<IndustryResultItem>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.setIndustryKeyIDs(val);
  };
  public technologiesViewonChanged = (
    val: Array<string>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setTechnologies(val.map((x) => x).toList());
  };
  public languagesViewonChanged = (
    val: Array<string>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setLanguages(val.map((x) => x).toList());
  };
  public sICCodesViewonChanged = (
    val: Array<string>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setPersonLocations(val.map((x) => x).toList());
  };
  public sICCodesView1onChanged = (
    val: Array<string>,
    d3eState: ApolloSearchFilterViewRefs
  ): void => {
    this.criteria.setPersonLocations(val.map((x) => x).toList());
  };
  public get submitButton() {
    return this.d3eState.submitButton;
  }
}
export default function ApolloSearchFilterView(
  props: ApolloSearchFilterViewProps
) {
  return React.createElement(_ApolloSearchFilterViewState, props);
}
