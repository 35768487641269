import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallStatus from "../classes/CallStatus";
import CallData from "../classes/CallData";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import CallInteraction from "../classes/CallInteraction";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";
import ToolTipWrapper from "./ToolTipWrapper";

type _IncomingCallViewOnAccept = () => void;

type _IncomingCallViewOnReject = () => void;

type _IncomingCallViewOnCloseView = () => void;

export interface IncomingCallViewProps extends BaseUIProps {
  key?: string;
  callInteraction: CallInteraction;
  from: string;
  onAccept: _IncomingCallViewOnAccept;
  onReject: _IncomingCallViewOnReject;
  onCloseView: _IncomingCallViewOnCloseView;
}

class _IncomingCallViewState extends ObservableComponent<IncomingCallViewProps> {
  static defaultProps = {
    callInteraction: null,
    from: "",
    onAccept: null,
    onReject: null,
    onCloseView: null,
  };
  data: CallData = null;
  status: CallStatus = CallStatus.Initiated;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: IncomingCallViewProps) {
    super(props);

    this.initState();
  }
  public get callInteraction(): CallInteraction {
    return this.props.callInteraction;
  }
  public get from(): string {
    return this.props.from;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("callInteraction", this.props.callInteraction);

    this.on(["callInteraction", "callInteraction.data"], this.computeData);

    this.computeData();

    this.on(
      [
        "callInteraction",
        "callInteraction.data",
        "callInteraction.data.callStatus",
      ],
      this.computeStatus
    );

    this.computeStatus();

    this.on(
      [
        "callInteraction",
        "callInteraction.lead",
        "callInteraction.lead.name",
        "from",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: IncomingCallViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.callInteraction !== this.props.callInteraction) {
      this.updateObservable(
        "callInteraction",
        prevProps.callInteraction,
        this.props.callInteraction
      );

      this.fire("callInteraction", this);
    }

    if (prevProps.from !== this.props.from) {
      this.fire("from", this);
    }
  }
  public setData(val: CallData): void {
    let isValChanged: boolean = this.data !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("data", this.data, val);

    this.data = val;

    this.fire("data", this);
  }
  public computeData = (): void => {
    try {
      this.setData(this.callInteraction.data);
    } catch (exception) {
      console.log(" exception in computeData : " + exception.toString());

      this.setData(null);
    }
  };
  public setStatus(val: CallStatus): void {
    let isValChanged: boolean = this.status !== val;

    if (!isValChanged) {
      return;
    }

    this.status = val;

    this.onChangeCallId();

    this.fire("status", this);
  }
  public computeStatus = (): void => {
    try {
      this.setStatus(this.callInteraction.data.callStatus);
    } catch (exception) {
      console.log(" exception in computeStatus : " + exception.toString());

      this.setStatus(CallStatus.Initiated);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            ui.Row({
              children: [
                TextView({
                  data:
                    this.callInteraction !== null &&
                    this.callInteraction.lead !== null
                      ? this.callInteraction.lead.name
                      : "Unknown Caller",
                  style: new ui.TextStyle({
                    fontSize: 16,
                    color: cStyle.c14,
                    fontWeight: ui.FontWeight.bold,
                  }),
                  className: "x95b",
                  key: "0",
                }),
              ],
              className: "x7f0",
              key: "0",
            }),
          ],
          className: "xf83 hc h",
          key: "0",
        }),
        ui.Row({
          children: [ui.Container({ className: "x991 hc vc h", key: "0" })],
          className: "x7601 hc h",
          key: "1",
        }),
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            ui.Container({
              alignment: ui.Alignment.center,
              child: ui.Container({
                alignment: ui.Alignment.center,
                child: ui.Center({
                  child: TextView({
                    data:
                      this.callInteraction === null ||
                      this.callInteraction.lead === null
                        ? "UN"
                        : this.callInteraction.lead.name[0] +
                          this.callInteraction.lead.name[1],
                    textAlign: ui.TextAlign.center,
                    style: new ui.TextStyle({
                      color: new ui.Color(0xff4caca4),
                      fontWeight: ui.FontWeight.bold,
                      fontSize: 35,
                    }),
                    className: "xc8b hc vc",
                  }),
                  className: "hc vc",
                }),
                className: "x24b hc vc",
              }),
              className: "x65 hc vc",
              key: "0",
            }),
            TextView({
              data: this.from,
              style: new ui.TextStyle({
                color: cStyle.c14,
                fontSize: 16,
                fontWeight: ui.FontWeight.bold,
              }),
              className: "xde2 hc",
              key: "1",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.Container({
                  alignment: ui.Alignment.center,
                  child: ToolTipWrapper({
                    message: "Accept",
                    backgroundColor: cStyle.tooltipBackgroundColor,
                    textColor: cStyle.tooltipTextColor,
                    child: IconView({
                      icon: MaterialIcons.call,
                      color: cStyle.c14,
                      className: "x0f6 hc vc",
                    }),
                  }),
                  onTap: (e) => {
                    e.stopPropagation();

                    this.onVoiceMutePressed();
                  },
                  className: "x681 hc vc",
                  key: "0",
                }),
                ui.Container({ className: "xa0a hc", key: "1" }),
                ui.Container({
                  alignment: ui.Alignment.center,
                  child: ToolTipWrapper({
                    message: "Reject",
                    backgroundColor: cStyle.tooltipBackgroundColor,
                    textColor: cStyle.tooltipTextColor,
                    child: IconView({
                      icon: MaterialIcons.call_end,
                      color: cStyle.c14,
                      className: "x7c4f hc vc",
                    }),
                  }),
                  onTap: (e) => {
                    e.stopPropagation();

                    this.onDisconnectPressed();
                  },
                  className: "xe3a hc vc",
                  key: "2",
                }),
              ],
              className: "xa39 hc h",
              key: "2",
            }),
          ],
          className: "x41c hc vc h",
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "IncomingCallView x0ab1 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onVoiceMutePressed = (): void => {
    this.onAccept();
  };
  public onDisconnectPressed = (): void => {
    this.onReject();
  };
  public onChangeCallId = (): void => {
    if (
      this.data !== null &&
      (this.data.callSid === null || this.data.callSid.isEmpty)
    ) {
      this.onCloseView();
    }
  };
  public get onAccept(): _IncomingCallViewOnAccept {
    return this.props.onAccept;
  }
  public get onReject(): _IncomingCallViewOnReject {
    return this.props.onReject;
  }
  public get onCloseView(): _IncomingCallViewOnCloseView {
    return this.props.onCloseView;
  }
}
export default function IncomingCallView(props: IncomingCallViewProps) {
  return React.createElement(_IncomingCallViewState, {
    ..._IncomingCallViewState.defaultProps,
    ...props,
  });
}
