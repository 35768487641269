import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallData from "../classes/CallData";
import ResultStatus from "../classes/ResultStatus";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import CallInteraction from "../classes/CallInteraction";
import TextValueWithButton from "./TextValueWithButton";
import Result from "../classes/Result";
import MessageDispatch from "../rocket/MessageDispatch";
import CallStatus from "../classes/CallStatus";
import Button from "./Button";
import InteractionType from "../classes/InteractionType";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import D3EDisposable from "../rocket/D3EDisposable";
import TextValueField from "./TextValueField";
import SuccessMessage from "../classes/SuccessMessage";
import LeadStatus from "../classes/LeadStatus";
import EventBus from "../utils/EventBus";
import Browser from "../classes/Browser";
import DateTime from "../core/DateTime";
import Interaction from "../models/Interaction";
import LabelDropdown from "./LabelDropdown";
import IconView from "./IconView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _CallButtonOnPressed = (d3eState: ProfileViewWidgetRefs) => void;

export interface ProfileViewWidgetProps extends BaseUIProps {
  key?: string;
  user: User;
  lead: Lead;
  client: TwilioClient;
  data: CallData;
}
/// To store state data for ProfileViewWidget
class ProfileViewWidgetRefs {
  public callButton: CallButtonState = new CallButtonState();
}

interface CallButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: ProfileViewWidgetRefs;
  _callButtonHandler?: _CallButtonOnPressed;
  data: CallData;
}

class CallButtonState extends ObjectObservable {
  public _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CallButtonWithState extends ObservableComponent<CallButtonWithStateProps> {
  callButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CallButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get callButton(): CallButtonState {
    return this.props.d3eState.callButton;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public get d3eState(): ProfileViewWidgetRefs {
    return this.props.d3eState;
  }
  public get _callButtonHandler(): _CallButtonOnPressed {
    return this.props._callButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("callButton", null, this.callButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("data", this.props.data);

    this.on(
      [
        "callButton",
        "callButton.",
        "callButton.hover",
        "data",
        "data.callStatus",
        "data.deviceReady",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: CallButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public callButtonOnEnter(event): void {
    return this.callButton.setHover(true);
  }
  public callButtonOnExit(event): void {
    return this.callButton.setHover(false);
  }
  public dispose(): void {
    this.callButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 0.0, 0.0, new Map()),
      width: 100,
      child: Button({
        disable:
          this.data === null ||
          this.data.deviceReady === false ||
          this.data.callStatus === CallStatus.InProgress,
        padding: this.callButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.callButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        onPressed: () => {
          this._callButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            IconView({
              icon: MaterialIcons.call,
              size: 20,
              color:
                this.data === null ||
                this.data.deviceReady === false ||
                this.data.callStatus === CallStatus.InProgress
                  ? new ui.Color(0xff616161)
                  : new ui.Color(0xffffffff),
              states: ui.joinStates(
                {
                  "data-c0":
                    this.data === null ||
                    this.data.deviceReady === false ||
                    this.data.callStatus === CallStatus.InProgress,
                },
                {}
              ),
              className: "x966",
              key: "0",
            }),
            TextView({
              data: "Call",
              states: ui.joinStates(
                {
                  "data-c0":
                    this.data === null ||
                    this.data.deviceReady === false ||
                    this.data.callStatus === CallStatus.InProgress,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.data === null ||
                  this.data.deviceReady === false ||
                  this.data.callStatus === CallStatus.InProgress
                    ? new ui.Color(0xff616161)
                    : new ui.Color(0xffffffff),
              }),
              className: "x0a0",
              key: "1",
            }),
          ],
        }),
        onEnter: (event) => {
          this.callButtonOnEnter(event);
        },
        onExit: (event) => {
          this.callButtonOnExit(event);
        },
      }),
      className: "x4eb hc",
    });
  }
}
function CallButtonWithState(props: CallButtonWithStateProps) {
  return React.createElement(_CallButtonWithState, props);
}

class _ProfileViewWidgetState extends ObservableComponent<ProfileViewWidgetProps> {
  static defaultProps = { user: null, lead: null, client: null, data: null };
  d3eState: ProfileViewWidgetRefs = new ProfileViewWidgetRefs();
  lastInteraction: Interaction = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public lastInteractionDisposable: D3EDisposable;
  public constructor(props: ProfileViewWidgetProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.lastInteractionDisposable = MessageDispatch.get().syncObject(
      this.lastInteraction,
      UsageConstants.SUBSCRIPTION_ONINTERACTIONCHANGE_PROFILEVIEWWIDGET_LASTINTERACTION_SYNCHRONISE
    );

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("lead", this.props.lead);

    this.on(["lead", "lead.lastInteraction"], this.computeLastInteraction);

    this.computeLastInteraction();

    this.updateSyncProperty("data", this.props.data);

    this.on(
      ["data", "lead", "lead.email", "lead.name", "lead.phone", "lead.status"],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ProfileViewWidgetProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public setLastInteraction(val: Interaction): void {
    let isValChanged: boolean = this.lastInteraction !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("lastInteraction", this.lastInteraction, val);

    this.lastInteractionDisposable?.dispose();

    this.lastInteraction = val;

    this.lastInteractionDisposable = MessageDispatch.get().syncObject(
      this.lastInteraction,
      UsageConstants.SUBSCRIPTION_ONINTERACTIONCHANGE_PROFILEVIEWWIDGET_LASTINTERACTION_SYNCHRONISE
    );

    this.fire("lastInteraction", this);
  }
  public computeLastInteraction = (): void => {
    try {
      this.setLastInteraction(this.lead.lastInteraction);
    } catch (exception) {
      console.log(
        " exception in computeLastInteraction : " + exception.toString()
      );

      this.setLastInteraction(null);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
      children: [
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          mainAxisAlignment: ui.MainAxisAlignment.start,
          children: [
            TextValueField({
              value: this.lead.name.isEmpty ? " --- " : this.lead.name,
              label: "Name",
              className: "x996b hc h",
              key: "0",
            }),
            TextValueWithButton({
              value: this.lead.email.isEmpty ? " --- " : this.lead.email,
              label: "Email",
              onOpen: () => {
                this.onEmailButtonHandler(this.d3eState);
              },
              className: "x4c3 hc h",
              key: "1",
            }),
            TextValueField({
              value: this.lead.phone.isEmpty ? "---" : this.lead.phone,
              label: "Phone",
              className: "xc89 hc h",
              key: "2",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              crossAxisAlignment: ui.CrossAxisAlignment.center,
              children: [
                TextView({ data: "Status", className: "x001 hc", key: "0" }),
                TextView({ data: ":", className: "xeae", key: "1" }),
                ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(
                    10.0,
                    0.0,
                    0.0,
                    0.0,
                    new Map()
                  ),
                  child: LabelDropdown<LeadStatus>({
                    value: this.lead.status,
                    items: LeadStatus.values,
                    width: 120,
                    onChanged: (value) => {
                      this.statusDropdownHandler(value, this.d3eState);
                    },
                    builder: (context, item) => {
                      return TextView({ data: item.name });
                    },
                  }),
                  key: "2",
                  className: "x0ef",
                }),
              ],
              className: "x173 hc h",
              key: "3",
            }),
          ],
          className: "x3edc hc h",
          key: "0",
        }),
        ui.Row({
          children: [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.end,
              children: [
                CallButtonWithState({
                  d3eState: this.d3eState,
                  _callButtonHandler: this.callButtonHandler,
                  data: this.data,
                  key: "0",
                }),
              ],
              key: "0",
            }),
          ],
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "ProfileViewWidget xdd2 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {};
  public callButtonHandler = async (
    d3eState: ProfileViewWidgetRefs
  ): Promise<void> => {
    this.setLastInteraction(null);

    if (this.client.data.deviceReady) {
      if (this.user.twilioNumber === null || this.user.twilioNumber.isEmpty) {
        EventBus.get().fire(
          new FailureMessage({
            message:
              "You do not have a Twilio Number. Please contact your administrator.",
          })
        );

        return;
      }

      if (this.lead.phone === null || this.lead.phone.isEmpty) {
        EventBus.get().fire(
          new FailureMessage({
            message: "Lead does not have valid phone number",
          })
        );

        return;
      }

      let hasMicPermission: boolean = await this.client.checkMicPermission();

      if (!hasMicPermission) {
        EventBus.get().fire(
          new FailureMessage({
            message: "Please allow microphone permission to make calls.",
          })
        );

        return;
      }

      let connectCall: boolean = await this.client.makeCall(
        this.user.twilioNumber,
        this.lead.phone,
        {
          onStatus: (s) => {
            this.onStatusChange(s);
          },
          onFailure: (m, e) => {
            this.onConnectionFailure(m, e);
          },
        }
      );
    }
  };
  public onStatusChange = async (status: string): Promise<void> => {
    if (this.lastInteraction === null) {
      this.setLastInteraction(
        new Interaction({
          lead: this.lead,
          fromNumber: this.user.twilioNumber,
          toNumber: this.lead.phone,
          callStatus: CallStatus.Initiated,
          type: InteractionType.Call,
          sid: this.client.callSid,
          isIncoming: false,
          handledBy: this.user,
        })
      );

      EventBus.get().fire(
        new CallInteraction({
          interaction: this.lastInteraction,
          data: this.data,
          lead: this.lead,
        })
      );

      let result: Result<Interaction> = await this.lastInteraction.save();

      if (result.status === ResultStatus.Success) {
      }
    } else if (this.lastInteraction !== null) {
      let st: CallStatus = CallStatus.Initiated;

      switch (status) {
        case "ringing": {
          st = CallStatus.Ringing;
          break;
        }

        case "completed": {
          st = CallStatus.Completed;
          break;
        }

        case "accepted": {
          {
            st = CallStatus.InProgress;

            this.lastInteraction.setStartTime(DateTime.now());
          }
          break;
        }

        case "failed": {
          st = CallStatus.Failed;
          break;
        }

        case "busy": {
          st = CallStatus.Busy;
          break;
        }

        case "open": {
          st = CallStatus.InProgress;
          break;
        }

        case "connecting": {
          st = CallStatus.Connecting;
          break;
        }

        case "initiated": {
          st = CallStatus.Initiated;
          break;
        }
        default: {
        }
      }

      this.lastInteraction.setCallStatus(st);
    }
  };
  public onConnectionFailure = (message: string, error: string): void => {
    EventBus.get().fire(new FailureMessage({ message: message }));
  };
  public onCallDisconnectError = (error: string): void => {
    EventBus.get().fire(new FailureMessage({ message: error }));
  };
  public onEmailButtonHandler = (d3eState: ProfileViewWidgetRefs): void => {
    let subject: string = "Hi " + this.lead.name;

    let body: string = "Hi " + this.lead.name + ",\n\n";

    Browser.sendMailTo(this.lead.email, { subject: subject, body: body });
  };
  public statusDropdownHandler = async (
    value: LeadStatus,
    d3eState: ProfileViewWidgetRefs
  ): Promise<void> => {
    this.lead.setStatus(value);

    let result: Result<Lead> = await this.lead.save();

    if (result.status === ResultStatus.Success) {
      EventBus.get().fire(
        new SuccessMessage({ message: "Lead Status Updated" })
      );
    } else {
      EventBus.get().fire(
        new FailureMessage({ message: "Failed to update Lead Status" })
      );
    }
  };
  public dispose(): void {
    this.lastInteractionDisposable?.dispose();

    super.dispose();
  }
  public get callButton() {
    return this.d3eState.callButton;
  }
}
export default function ProfileViewWidget(props: ProfileViewWidgetProps) {
  return React.createElement(_ProfileViewWidgetState, {
    ..._ProfileViewWidgetState.defaultProps,
    ...props,
  });
}
