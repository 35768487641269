import DBObject from "../utils/DBObject";

export default class PhoneNumberDetails extends DBObject {
  private _id: number = DBObject.nextStructId();
  private static readonly _AREACODE: number = 0;
  private static readonly _COUNTRY: number = 1;
  private static readonly _COUNTRYCODE: number = 2;
  private static readonly _LOCALITY: number = 3;
  private static readonly _MONTHLYPRICE: number = 4;
  private static readonly _PHONENUMBER: number = 5;
  private static readonly _SID: number = 6;
  private static readonly _STATUS: number = 7;
  private _phoneNumber: string = "";
  private _country: string = "";
  private _countryCode: string = "";
  private _areaCode: string = "";
  private _monthlyPrice: string = "";
  private _status: boolean = false;
  private _sid: string = "";
  private _locality: string = "";
  public constructor(
    d3eParams?: Partial<{
      areaCode: string;
      country: string;
      countryCode: string;
      locality: string;
      monthlyPrice: string;
      phoneNumber: string;
      sid: string;
      status: boolean;
    }>
  ) {
    super();

    if (d3eParams?.areaCode) {
      this.setAreaCode(d3eParams?.areaCode);
    }

    if (d3eParams?.country) {
      this.setCountry(d3eParams?.country);
    }

    if (d3eParams?.countryCode) {
      this.setCountryCode(d3eParams?.countryCode);
    }

    if (d3eParams?.locality) {
      this.setLocality(d3eParams?.locality);
    }

    if (d3eParams?.monthlyPrice) {
      this.setMonthlyPrice(d3eParams?.monthlyPrice);
    }

    if (d3eParams?.phoneNumber) {
      this.setPhoneNumber(d3eParams?.phoneNumber);
    }

    if (d3eParams?.sid) {
      this.setSid(d3eParams?.sid);
    }

    if (d3eParams?.status) {
      this.setStatus(d3eParams?.status);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "PhoneNumberDetails";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get phoneNumber(): string {
    return this._phoneNumber;
  }
  public setPhoneNumber(val: string): void {
    let isValChanged: boolean = this._phoneNumber !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PhoneNumberDetails._PHONENUMBER, this._phoneNumber);

    this._phoneNumber = val;

    this.fire("phoneNumber", this);
  }
  public get country(): string {
    return this._country;
  }
  public setCountry(val: string): void {
    let isValChanged: boolean = this._country !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PhoneNumberDetails._COUNTRY, this._country);

    this._country = val;

    this.fire("country", this);
  }
  public get countryCode(): string {
    return this._countryCode;
  }
  public setCountryCode(val: string): void {
    let isValChanged: boolean = this._countryCode !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PhoneNumberDetails._COUNTRYCODE, this._countryCode);

    this._countryCode = val;

    this.fire("countryCode", this);
  }
  public get areaCode(): string {
    return this._areaCode;
  }
  public setAreaCode(val: string): void {
    let isValChanged: boolean = this._areaCode !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PhoneNumberDetails._AREACODE, this._areaCode);

    this._areaCode = val;

    this.fire("areaCode", this);
  }
  public get monthlyPrice(): string {
    return this._monthlyPrice;
  }
  public setMonthlyPrice(val: string): void {
    let isValChanged: boolean = this._monthlyPrice !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PhoneNumberDetails._MONTHLYPRICE, this._monthlyPrice);

    this._monthlyPrice = val;

    this.fire("monthlyPrice", this);
  }
  public get status(): boolean {
    return this._status;
  }
  public setStatus(val: boolean): void {
    let isValChanged: boolean = this._status !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PhoneNumberDetails._STATUS, this._status);

    this._status = val;

    this.fire("status", this);
  }
  public get sid(): string {
    return this._sid;
  }
  public setSid(val: string): void {
    let isValChanged: boolean = this._sid !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PhoneNumberDetails._SID, this._sid);

    this._sid = val;

    this.fire("sid", this);
  }
  public get locality(): string {
    return this._locality;
  }
  public setLocality(val: string): void {
    let isValChanged: boolean = this._locality !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(PhoneNumberDetails._LOCALITY, this._locality);

    this._locality = val;

    this.fire("locality", this);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case PhoneNumberDetails._PHONENUMBER: {
        this.setPhoneNumber(value as string);
        break;
      }

      case PhoneNumberDetails._COUNTRY: {
        this.setCountry(value as string);
        break;
      }

      case PhoneNumberDetails._COUNTRYCODE: {
        this.setCountryCode(value as string);
        break;
      }

      case PhoneNumberDetails._AREACODE: {
        this.setAreaCode(value as string);
        break;
      }

      case PhoneNumberDetails._MONTHLYPRICE: {
        this.setMonthlyPrice(value as string);
        break;
      }

      case PhoneNumberDetails._STATUS: {
        this.setStatus(value as boolean);
        break;
      }

      case PhoneNumberDetails._SID: {
        this.setSid(value as string);
        break;
      }

      case PhoneNumberDetails._LOCALITY: {
        this.setLocality(value as string);
        break;
      }
    }
  }
  public get(field: number): any {
    switch (field) {
      case PhoneNumberDetails._PHONENUMBER: {
        return this._phoneNumber;
      }

      case PhoneNumberDetails._COUNTRY: {
        return this._country;
      }

      case PhoneNumberDetails._COUNTRYCODE: {
        return this._countryCode;
      }

      case PhoneNumberDetails._AREACODE: {
        return this._areaCode;
      }

      case PhoneNumberDetails._MONTHLYPRICE: {
        return this._monthlyPrice;
      }

      case PhoneNumberDetails._STATUS: {
        return this._status;
      }

      case PhoneNumberDetails._SID: {
        return this._sid;
      }

      case PhoneNumberDetails._LOCALITY: {
        return this._locality;
      }
      default: {
        return null;
      }
    }
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof PhoneNumberDetails &&
        this._phoneNumber === other._phoneNumber &&
        this._country === other._country &&
        this._countryCode === other._countryCode &&
        this._areaCode === other._areaCode &&
        this._monthlyPrice === other._monthlyPrice &&
        this._status === other._status &&
        this._sid === other._sid &&
        this._locality === other._locality)
    );
  }
  public get hashCode(): number {
    return (
      (this._phoneNumber?.hashCode ?? 0) +
      (this._country?.hashCode ?? 0) +
      (this._countryCode?.hashCode ?? 0) +
      (this._areaCode?.hashCode ?? 0) +
      (this._monthlyPrice?.hashCode ?? 0) +
      (this._status?.hashCode ?? 0) +
      (this._sid?.hashCode ?? 0) +
      (this._locality?.hashCode ?? 0)
    );
  }
}
