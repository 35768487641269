import ChartNumberFormat from "./ChartNumberFormat";

export default class NumberFormatter {
  public constructor() {}
  public static formatDouble(
    doubleValue: number,
    format: ChartNumberFormat,
    d3eParams?: Partial<{ percent: boolean }>
  ): string {
    let percent = d3eParams?.percent;

    if (!format.formatNumber) {
      return doubleValue.toString();
    }

    let res: string = percent ? "" : format.numberPrefix ?? "";

    let unit: string = "";

    for (let i: number = 0; i < format.numberScaleValue.length; i++) {
      let val: number = format.numberScaleValue[i];

      if (doubleValue / val < 1.0) {
        break;
      }

      unit = format.numberScaleUnits[i];

      doubleValue /= val;
    }

    res += NumberFormatter.dp(doubleValue, 2).toString();

    res += unit;

    if (percent) {
      res += "%";
    } else {
      res += format.numberSuffix ?? "";
    }

    return res;
  }
  public static dp(val: number, places: number): number {
    let mod: number = Math.pow(10.0, places.toDouble());

    return (val * mod).round().toDouble() / mod;
  }
}
