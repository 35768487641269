import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import SearchableView from "./SearchableView";
import ResultStatus from "../classes/ResultStatus";
import SuccessMessage from "../classes/SuccessMessage";
import EventBus from "../utils/EventBus";
import PageNavigator from "../classes/PageNavigator";
import Result from "../classes/Result";
import Query from "../classes/Query";
import PopupWrapperView from "./PopupWrapperView";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import LeadsListRequest from "../models/LeadsListRequest";
import Lead from "../models/Lead";
import LeadsList from "../classes/LeadsList";
import TextView from "./TextView";
import Interaction from "../models/Interaction";
import CollectionUtils from "../utils/CollectionUtils";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _CancelButtonOnPressed = (d3eState: LeadAssignPopupViewRefs) => void;

type _AssignButtonOnPressed = (d3eState: LeadAssignPopupViewRefs) => void;

type _LabelDropdownOnChanged = (
  text: Lead,
  d3eState: LeadAssignPopupViewRefs
) => void;

export interface LeadAssignPopupViewProps extends BaseUIProps {
  key?: string;
  interaction: Interaction;
}
/// To store state data for LeadAssignPopupView
class LeadAssignPopupViewRefs {
  public assignButton: AssignButtonState = new AssignButtonState();
  public cancelButton: CancelButtonState = new CancelButtonState();
}

interface AssignButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: LeadAssignPopupViewRefs;
  _onAssignHandler?: _AssignButtonOnPressed;
}

class AssignButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _AssignButtonWithState extends ObservableComponent<AssignButtonWithStateProps> {
  assignButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AssignButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get assignButton(): AssignButtonState {
    return this.props.d3eState.assignButton;
  }
  public get d3eState(): LeadAssignPopupViewRefs {
    return this.props.d3eState;
  }
  public get _onAssignHandler(): _AssignButtonOnPressed {
    return this.props._onAssignHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("assignButton", null, this.assignButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["assignButton", "assignButton.", "assignButton.hover"],
      this.rebuild
    );
  }
  public assignButtonOnEnter(event): void {
    return this.assignButton.setHover(true);
  }
  public assignButtonOnExit(event): void {
    return this.assignButton.setHover(false);
  }
  public dispose(): void {
    this.assignButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.assignButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.assignButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.assignButton.disable,
        onPressed: () => {
          this._onAssignHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Assign" }),
        onEnter: (event) => {
          this.assignButtonOnEnter(event);
        },
        onExit: (event) => {
          this.assignButtonOnExit(event);
        },
      }),
      className: "x58a",
    });
  }
}
function AssignButtonWithState(props: AssignButtonWithStateProps) {
  return React.createElement(_AssignButtonWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: LeadAssignPopupViewRefs;
  _onCancelHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): LeadAssignPopupViewRefs {
    return this.props.d3eState;
  }
  public get _onCancelHandler(): _CancelButtonOnPressed {
    return this.props._onCancelHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._onCancelHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Cancel" }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "x45c",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

class _LeadAssignPopupViewState extends ObservableComponent<LeadAssignPopupViewProps> {
  static defaultProps = { interaction: null };
  d3eState: LeadAssignPopupViewRefs = new LeadAssignPopupViewRefs();
  request: LeadsListRequest = null;
  leadsList: LeadsList = null;
  leads: Array<Lead> = ListWrapper.widget(this, "leads");
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LeadAssignPopupViewProps) {
    super(props);

    this.initState();
  }
  public get interaction(): Interaction {
    return this.props.interaction;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("interaction", this.props.interaction);

    this.on(
      [
        "request",
        "request.applyStatus",
        "request.ascending",
        "request.fromDate",
        "request.offset",
        "request.orderBy",
        "request.pageSize",
        "request.status",
        "request.toDate",
        "request.user",
      ],
      this.computeLeadsList
    );

    this.computeLeadsList();

    this.on(["leadsList", "leadsList.items"], this.computeLeads);

    this.computeLeads();

    this.on(["interaction", "interaction.lead", "leads"], this.rebuild);
  }
  public componentDidUpdate(prevProps: LeadAssignPopupViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.interaction !== this.props.interaction) {
      this.updateObservable(
        "interaction",
        prevProps.interaction,
        this.props.interaction
      );

      this.fire("interaction", this);
    }
  }
  public setRequest(val: LeadsListRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setLeadsList(val: LeadsList): void {
    let isValChanged: boolean = this.leadsList !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("leadsList", this.leadsList, val);

    this.leadsList = val;

    this.fire("leadsList", this);
  }
  public computeLeadsList = async (): Promise<void> => {
    try {
      this.setLeadsList(
        await Query.get().getLeadsList(
          UsageConstants.QUERY_GETLEADSLIST_LEADASSIGNPOPUPVIEW_PROPERTIES_LEADSLIST_COMPUTATION,
          this.request
        )
      );
    } catch (exception) {
      console.log(" exception in computeLeadsList : " + exception.toString());

      this.setLeadsList(null);
    }
  };
  public setLeads(val: Array<Lead>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.leads, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("leads", this.leads, val);

    this.leads.clear();

    this.leads.addAll(val);

    this.fire("leads", this);
  }
  public addToLeads(val: Lead, index: number = -1): void {
    if (index === -1) {
      if (!this.leads.contains(val)) this.leads.add(val);
    } else {
      this.leads.remove(this.leads.elementAt(index));

      this.leads.add(val);
    }

    this.fire("leads", this, val, true);

    this.updateObservable("leads", null, val);
  }
  public removeFromLeads(val: Lead): void {
    this.leads.remove(val);

    this.fire("leads", this, val, false);

    this.removeObservable("leads", val);
  }
  public computeLeads = (): void => {
    try {
      this.setLeads(
        Array.from(this.leadsList.items.isNotEmpty ? this.leadsList.items : [])
      );
    } catch (exception) {
      console.log(" exception in computeLeads : " + exception.toString());

      this.setLeads([]);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title: "Assign Lead",
      content: ui.Column({
        children: [
          ui.Container({
            width: 500,
            child: SearchableView<Lead>({
              name: "Lead",
              placeHolder: "Select Lead",
              value: this.interaction.lead,
              items: this.leads,
              onChanged: (text) => {
                this.labelDropdownonChanged(text, this.d3eState);
              },
              key: this.leads.length.toString(),
            }),
            key: "0",
            className: "x0d4 hc",
          }),
        ],
        className: "hc",
      }),
      buttons: [
        CancelButtonWithState({
          d3eState: this.d3eState,
          _onCancelHandler: this.onCancelHandler,
          key: "0",
        }),
        AssignButtonWithState({
          d3eState: this.d3eState,
          _onAssignHandler: this.onAssignHandler,
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "LeadAssignPopupView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(
      new LeadsListRequest({
        pageSize: 100,
        offset: 0,
        orderBy: "",
        ascending: true,
      })
    );
  };
  public onAssignHandler = async (
    d3eState: LeadAssignPopupViewRefs
  ): Promise<void> => {
    if (this.interaction.lead === null) {
      return;
    }

    let result: Result<Interaction> = await this.interaction.save();

    if (result.status === ResultStatus.Success) {
      EventBus.get().fire(
        new SuccessMessage({ message: "Lead Assigned Successfully" })
      );

      this.navigator.pop();
    }
  };
  public onCancelHandler = (d3eState: LeadAssignPopupViewRefs): void => {
    this.navigator.pop();
  };
  public labelDropdownonChanged = (
    val: Lead,
    d3eState: LeadAssignPopupViewRefs
  ): void => {
    this.interaction.setLead(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get assignButton() {
    return this.d3eState.assignButton;
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
}
export default function LeadAssignPopupView(props: LeadAssignPopupViewProps) {
  return React.createElement(_LeadAssignPopupViewState, {
    ..._LeadAssignPopupViewState.defaultProps,
    ...props,
  });
}
