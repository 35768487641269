import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import RoundedIconButton from "./RoundedIconButton";
import { BuildContext } from "../classes/BuildContext";

type _RemoveFilterViewOnCancel = () => void;

export interface RemoveFilterViewProps extends BaseUIProps {
  key?: string;
  filtersCount?: number;
  onCancel: _RemoveFilterViewOnCancel;
}

class _RemoveFilterViewState extends ObservableComponent<RemoveFilterViewProps> {
  static defaultProps = { filtersCount: 0, onCancel: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RemoveFilterViewProps) {
    super(props);

    this.initState();
  }
  public get filtersCount(): number {
    return this.props.filtersCount;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["filtersCount"], this.rebuild);
  }
  public componentDidUpdate(prevProps: RemoveFilterViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.filtersCount !== this.props.filtersCount) {
      this.fire("filtersCount", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          mainAxisSize: ui.MainAxisSize.min,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            RoundedIconButton({
              icon: MaterialIcons.close,
              size: 16,
              onPressed: () => {
                this.onTapiconviewBehaviourHandler();
              },
              key: "0",
            }),
            TextView({
              data: this.filtersCount.toString(),
              className: "x36c",
              key: "1",
            }),
          ],
          className: "x275",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "RemoveFilterView"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onTapiconviewBehaviourHandler = (): void => {
    if (this.onCancel !== null) {
      this.onCancel();
    }
  };
  public get onCancel(): _RemoveFilterViewOnCancel {
    return this.props.onCancel;
  }
}
export default function RemoveFilterView(props: RemoveFilterViewProps) {
  return React.createElement(_RemoveFilterViewState, {
    ..._RemoveFilterViewState.defaultProps,
    ...props,
  });
}
