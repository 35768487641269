import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import HRMInputFieldType from "../classes/HRMInputFieldType";
import PopupTargetController from "./PopupTargetController";
import ListWrapper from "../utils/ListWrapper";
import SearchFilterView from "./SearchFilterView";
import Popup from "./Popup";
import TextView from "./TextView";
import CollectionUtils from "../utils/CollectionUtils";
import { BuildContext } from "../classes/BuildContext";

type _SearchableViewOnChanged<T> = (item: T) => void;

export interface SearchableViewProps<T> extends BaseUIProps {
  key?: string;
  value?: T;
  isRequired?: boolean;
  name?: string;
  items?: Array<T>;
  placeHolder?: string;
  errors?: Array<string>;
  fieldType?: HRMInputFieldType;
  disable?: boolean;
  _itemsHash?: number;
  _errorsHash?: number;
  onChanged?: _SearchableViewOnChanged<T>;
}

class _SearchableViewState<T> extends ObservableComponent<
  SearchableViewProps<T>
> {
  static defaultProps = {
    value: null,
    isRequired: false,
    name: "",
    placeHolder: "Search",
    fieldType: HRMInputFieldType.Rectangle,
    disable: false,
    items: [],
    errors: [],
    onChanged: null,
  };
  searchableEmpltyController: ui.TextEditingController =
    new ui.TextEditingController();
  itemsInternal: Array<T> = ListWrapper.widget(this, "itemsInternal");
  data: Array<T> = ListWrapper.widget(this, "data");
  searchValue: string = "";
  popUpWidth: number = 150.0;
  focusNode: ui.FocusNode = null;
  active: boolean = false;
  dummyVar: T = null;
  resultsPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public emptyPopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: SearchableViewProps<T>) {
    super(props);

    this.initState();
  }
  public get value(): T {
    return this.props.value;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get name(): string {
    return this.props.name;
  }
  public get items(): Array<T> {
    return this.props.items;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get fieldType(): HRMInputFieldType {
    return this.props.fieldType;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.on(["items"], this.computeItemsInternal);

    this.computeItemsInternal();

    this.subscribeToList(this.errors, "errors");

    this.on(["value"], this.computeDummyVar);

    this.computeDummyVar();

    this.on(
      [
        "active",
        "disable",
        "errors",
        "fieldType",
        "focusNode",
        "isRequired",
        "name",
        "placeHolder",
        "searchValue",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: SearchableViewProps<T>): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.items !== this.props.items) {
      this.fire("items", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.fieldType !== this.props.fieldType) {
      this.fire("fieldType", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }
  }
  public setItemsInternal(val: Array<T>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.itemsInternal,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.itemsInternal.clear();

    this.itemsInternal.addAll(val);

    this.fire("itemsInternal", this);
  }
  public addToItemsInternal(val: T, index: number = -1): void {
    if (index === -1) {
      if (!this.itemsInternal.contains(val)) this.itemsInternal.add(val);
    } else {
      this.itemsInternal.remove(this.itemsInternal.elementAt(index));

      this.itemsInternal.add(val);
    }

    this.fire("itemsInternal", this, val, true);
  }
  public removeFromItemsInternal(val: T): void {
    this.itemsInternal.remove(val);

    this.fire("itemsInternal", this, val, false);
  }
  public computeItemsInternal = (): void => {
    try {
      this.setItemsInternal(Array.from([...this.items]));
    } catch (exception) {
      console.log(
        " exception in computeItemsInternal : " + exception.toString()
      );

      this.setItemsInternal([]);
    }
  };
  public setData(val: Array<T>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.data, val);

    if (!isValChanged) {
      return;
    }

    this.data.clear();

    this.data.addAll(val);

    this.fire("data", this);
  }
  public addToData(val: T, index: number = -1): void {
    if (index === -1) {
      if (!this.data.contains(val)) this.data.add(val);
    } else {
      this.data.remove(this.data.elementAt(index));

      this.data.add(val);
    }

    this.fire("data", this, val, true);
  }
  public removeFromData(val: T): void {
    this.data.remove(val);

    this.fire("data", this, val, false);
  }
  public setSearchValue(val: string): void {
    let isValChanged: boolean = this.searchValue !== val;

    if (!isValChanged) {
      return;
    }

    this.searchValue = val;

    this.fire("searchValue", this);
  }
  public setPopUpWidth(val: number): void {
    let isValChanged: boolean = this.popUpWidth !== val;

    if (!isValChanged) {
      return;
    }

    this.popUpWidth = val;

    this.fire("popUpWidth", this);
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this.active !== val;

    if (!isValChanged) {
      return;
    }

    this.active = val;

    this.fire("active", this);
  }
  public setDummyVar(val: T): void {
    let isValChanged: boolean = this.dummyVar !== val;

    if (!isValChanged) {
      return;
    }

    this.dummyVar = val;

    this.onChangeValue();

    this.fire("dummyVar", this);
  }
  public computeDummyVar = (): void => {
    try {
      this.setDummyVar(this.value);
    } catch (exception) {
      console.log(" exception in computeDummyVar : " + exception.toString());

      this.setDummyVar(null);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        this.name !== null && this.name.isNotEmpty
          ? ui.Row({
              children: [
                TextView({
                  data: this.name,
                  style: new ui.TextStyle({
                    color: new ui.Color(0xff4096d2),
                    fontWeight: ui.FontWeight.w500,
                  }),
                  className: "x069",
                  key: "0",
                }),
                this.isRequired
                  ? TextView({
                      data: "*",
                      style: new ui.TextStyle({
                        color: new ui.Color(0xff4096d2),
                        fontWeight: ui.FontWeight.w500,
                      }),
                      className: "x196",
                    })
                  : [],
              ],
              className: "x48c hc h",
            })
          : [],
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          states: ui.joinStates(
            {
              "data-c0":
                this.fieldType === HRMInputFieldType.Circle && this.active,
              "data-c1":
                this.fieldType === HRMInputFieldType.Circle && !this.active,
              "data-c2":
                this.fieldType === HRMInputFieldType.Underline && !this.active,
              "data-c3":
                this.fieldType === HRMInputFieldType.Underline && this.active,
              "data-c4":
                this.fieldType === HRMInputFieldType.Rectangle && this.active,
              "data-c5":
                this.fieldType === HRMInputFieldType.Rectangle && !this.active,
            },
            {}
          ),
          children: [
            ui.InputField({
              activeColor: new ui.Color(0xfff1f1f5),
              inActiveColor: new ui.Color(0x0),
              focusNode: this.focusNode,
              padding: ui.EdgeInsets.symmetric({
                horizontal: 5.0,
                vertical: 6.0,
                transitions: new Map(),
              }),
              disable: this.disable,
              placeHolder:
                this.searchableEmpltyController.text === null ||
                this.searchableEmpltyController.text.isEmpty
                  ? this.placeHolder
                  : null,
              value: this.searchValue,
              controller: this.searchableEmpltyController,
              onChanged: (text) => {
                this.onChangeText(text);
              },
              onTap: () => {
                this.onEmptyClick();
              },
              onFocusChange: (val) => {},
              className: "x666 hc h",
              key: "0",
            }),
          ],
          d3eRef: ui.LayoutAware((bounds, globalPos) => {
            this.onDropDownBoundsChanges(bounds, globalPos);
          }, this.emptyPopupTargetController.handleRef),
          className: "x59b hc h",
          key: "1",
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    textAlign: cStyle.tTextViewErrorTextTextAlignOn,
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorTextColorOn,
                    }),
                    className: "x2f10 hc",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "xee30 hc",
            })
          : [],
      ],
      className: ui.join(this.props.className, "SearchableView xc85 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onEmptyClick = (): void => {
    if (!this.disable) {
      this.showResults({ autoClose: true, takeFocus: false });

      this.setSearchValue("");

      this.setActive(true);
    }
  };
  public onSelectListTile = (item: any): void => {
    this.onChanged(item);

    if (item !== null) {
      this.searchableEmpltyController.text = item.toString();

      this.setSearchValue(item.toString());

      this.setData(this.items);
    } else {
      this.searchableEmpltyController.text = "";

      this.setSearchValue("");
    }

    this.hideResults();

    this.setActive(false);
  };
  public onChangeValue = (): void => {
    if (this.value === null) {
      this.searchableEmpltyController.text = "";

      this.setSearchValue("");
    }
  };
  public onDropDownBoundsChanges = (
    bounds: ui.Rect,
    globalPos: ui.Offset
  ): void => {
    this.setPopUpWidth(bounds.width - 12);
  };
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);

    this.setSearchValue(this.value !== null ? this.value.toString() : "");

    this.setData(this.items);
  };
  public onChangeFocus = (): void => {
    this.setActive(this.focusNode.hasFocus);
  };
  public onClosePopUp = (): void => {
    if (this.searchValue.isEmpty) {
      this.setSearchValue(this.value !== null ? this.value.toString() : "");
    }
  };
  public onChangeText = (text: string): void => {
    let val: string = text !== null ? text : "";

    this.setSearchValue(val);

    let l: Array<T> = this.items
      .where((i) => i.toString().toLowerCase().startsWith(val.toLowerCase()))
      .toList();

    this.setItemsInternal(
      this.itemsInternal.where((x) => !l.contains(x)).toList()
    );

    l.addAll(
      this.itemsInternal
        .where((i) => i.toString().toLowerCase().contains(val.toLowerCase()))
        .toList()
    );

    if (val.isEmpty) {
      this.setData(this.items);
    } else {
      this.setData(l);
    }

    this.showResults({ autoClose: true, takeFocus: false });
  };
  public get onChanged(): _SearchableViewOnChanged<T> {
    return this.props.onChanged;
  }
  public dispose(): void {
    this.resultsPopup?.dispose();

    super.dispose();
  }
  public showResults(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.resultsPopup?.dispose();

    let target: ui.Rect = this.emptyPopupTargetController.getTarget(
      this.context
    );

    this.resultsPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        constraints: new ui.BoxConstraints({ maxHeight: 250 }),
        decoration: new ui.BoxDecoration({
          color: new ui.Color(0xffffffff),
          borderRadius: ui.BorderRadius.zero,
          boxShadow: [
            new ui.BoxShadow({
              color: new ui.Color(0xffe6f6fb),
              blurRadius: 1,
              spreadRadius: 2,
            }),
          ],
        }),
        margin: ui.EdgeInsets.fromLTRB(1.0, 2.0, 1.0, 0.0, new Map()),
        width: this.popUpWidth + 2,
        child: SearchFilterView({
          value: this.value,
          items: this.data,
          onSelectItem: (item) => {
            this.onSelectListTile(item);
          },
        }),
        className: "x896 hc vc",
      }),
      target: target,
      onClose: this.onClosePopUp,
    });

    this.resultsPopup.showPopup(this.context);
  }
  public hideResults(): void {
    this.resultsPopup?.dispose();
  }
}
export default function SearchableView<T>(props: SearchableViewProps<T>) {
  return React.createElement(
    _SearchableViewState<T>,
    { ..._SearchableViewState.defaultProps, ...props },
    ListWrapper.fromInput<T>(props.items, "items"),
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
