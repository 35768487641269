import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CellTitle from "./CellTitle";
import MaterialIcons from "../icons/MaterialIcons";
import IconView from "./IconView";

type _SortedHeaderOnChanged = (value: boolean) => void;

export interface SortedHeaderProps extends BaseUIProps {
  key?: string;
  name?: string;
  assending: boolean;
  sorted?: boolean;
  onChanged?: _SortedHeaderOnChanged;
}

class _SortedHeaderState extends ObservableComponent<SortedHeaderProps> {
  static defaultProps = {
    name: "",
    assending: false,
    sorted: false,
    onChanged: null,
  };
  public constructor(props: SortedHeaderProps) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get assending(): boolean {
    return this.props.assending;
  }
  public get sorted(): boolean {
    return this.props.sorted;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["assending", "name", "sorted"], this.rebuild);
  }
  public componentDidUpdate(prevProps: SortedHeaderProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.assending !== this.props.assending) {
      this.fire("assending", this);
    }

    if (prevProps.sorted !== this.props.sorted) {
      this.fire("sorted", this);
    }
  }
  public render(): ReactNode {
    return ui.Container({
      child: ui.Row({
        mainAxisAlignment: ui.MainAxisAlignment.start,
        children: [
          ui.Container({
            margin: ui.EdgeInsets.fromLTRB(5.0, 0.0, 0.0, 0.0, new Map()),
            child: CellTitle({ data: this.name }),
            key: "0",
            className: "xdb1",
          }),
          this.sorted
            ? IconView({
                icon: this.assending
                  ? MaterialIcons.arrow_upward
                  : MaterialIcons.arrow_downward,
                size: 16,
                className: "x7afc",
              })
            : [],
        ],
        className: "xe2e6 hc h",
      }),
      onTap: (e) => {
        e.stopPropagation();

        this.onPressedUpWardButtonHandler();
      },
      className: ui.join(this.props.className, "SortedHeader xa50e hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedUpWardButtonHandler = (): void => {
    if (this.onChanged !== null) {
      this.onChanged(!this.assending);
    }
  };
  public get onChanged(): _SortedHeaderOnChanged {
    return this.props.onChanged;
  }
}
export default function SortedHeader(props: SortedHeaderProps) {
  return React.createElement(_SortedHeaderState, {
    ..._SortedHeaderState.defaultProps,
    ...props,
  });
}
