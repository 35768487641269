import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import RoundedIconButton from "./RoundedIconButton";
import { BuildContext } from "../classes/BuildContext";

type _ChipOnCancel = () => void;

export interface ChipProps extends BaseUIProps {
  key?: string;
  title?: string;
  onCancel?: _ChipOnCancel;
}

class _ChipState extends ObservableComponent<ChipProps> {
  static defaultProps = { title: "", onCancel: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ChipProps) {
    super(props);

    this.initState();
  }
  public get title(): string {
    return this.props.title;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["title"], this.rebuild);
  }
  public componentDidUpdate(prevProps: ChipProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.title !== this.props.title) {
      this.fire("title", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          mainAxisSize: ui.MainAxisSize.min,
          children: [
            TextView({ data: this.title, className: "xeab", key: "0" }),
            RoundedIconButton({
              icon: MaterialIcons.close,
              size: 16,
              onPressed: () => {
                this.onTapiconviewBehaviourHandler();
              },
              key: "1",
            }),
          ],
          className: "x73f",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "Chip"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onTapiconviewBehaviourHandler = (): void => {
    if (this.onCancel !== null) {
      this.onCancel();
    }
  };
  public get onCancel(): _ChipOnCancel {
    return this.props.onCancel;
  }
}
export default function Chip(props: ChipProps) {
  return React.createElement(_ChipState, {
    ..._ChipState.defaultProps,
    ...props,
  });
}
