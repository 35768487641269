import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import PopupWrapperView from "./PopupWrapperView";
import { BuildContext } from "../classes/BuildContext";

type _TwiloReleaseWarningViewAssignToAnotherUser = () => void;

type _TwiloReleaseWarningViewReleaseTwilioNumber = () => void;

type _CancelButtonOnPressed = (d3eState: TwiloReleaseWarningViewRefs) => void;

type _AssignToAnotherOnPressed = (
  d3eState: TwiloReleaseWarningViewRefs
) => void;

type _ReleaseNumberOnPressed = (d3eState: TwiloReleaseWarningViewRefs) => void;

export interface TwiloReleaseWarningViewProps extends BaseUIProps {
  key?: string;
  assignToAnotherUser: _TwiloReleaseWarningViewAssignToAnotherUser;
  releaseTwilioNumber: _TwiloReleaseWarningViewReleaseTwilioNumber;
}
/// To store state data for TwiloReleaseWarningView
class TwiloReleaseWarningViewRefs {
  public assignToAnother: AssignToAnotherState = new AssignToAnotherState();
  public cancelButton: CancelButtonState = new CancelButtonState();
  public releaseNumber: ReleaseNumberState = new ReleaseNumberState();
}

interface ReleaseNumberWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: TwiloReleaseWarningViewRefs;
  _onReleaseTwilioNumber?: _ReleaseNumberOnPressed;
}

class ReleaseNumberState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ReleaseNumberWithState extends ObservableComponent<ReleaseNumberWithStateProps> {
  releaseNumberFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ReleaseNumberWithStateProps) {
    super(props);

    this.initState();
  }
  public get releaseNumber(): ReleaseNumberState {
    return this.props.d3eState.releaseNumber;
  }
  public get d3eState(): TwiloReleaseWarningViewRefs {
    return this.props.d3eState;
  }
  public get _onReleaseTwilioNumber(): _ReleaseNumberOnPressed {
    return this.props._onReleaseTwilioNumber;
  }
  public initState() {
    super.initState();

    this.updateObservable("releaseNumber", null, this.releaseNumber);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["releaseNumber", "releaseNumber.", "releaseNumber.hover"],
      this.rebuild
    );
  }
  public releaseNumberOnEnter(event): void {
    return this.releaseNumber.setHover(true);
  }
  public releaseNumberOnExit(event): void {
    return this.releaseNumber.setHover(false);
  }
  public dispose(): void {
    this.releaseNumber.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.releaseNumber.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.releaseNumber.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.releaseNumber.disable,
        onPressed: () => {
          this._onReleaseTwilioNumber(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Release" }),
        onEnter: (event) => {
          this.releaseNumberOnEnter(event);
        },
        onExit: (event) => {
          this.releaseNumberOnExit(event);
        },
      }),
      className: "x693",
    });
  }
}
function ReleaseNumberWithState(props: ReleaseNumberWithStateProps) {
  return React.createElement(_ReleaseNumberWithState, props);
}

interface AssignToAnotherWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: TwiloReleaseWarningViewRefs;
  _onAddUserHandler?: _AssignToAnotherOnPressed;
}

class AssignToAnotherState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _AssignToAnotherWithState extends ObservableComponent<AssignToAnotherWithStateProps> {
  assignToAnotherFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AssignToAnotherWithStateProps) {
    super(props);

    this.initState();
  }
  public get assignToAnother(): AssignToAnotherState {
    return this.props.d3eState.assignToAnother;
  }
  public get d3eState(): TwiloReleaseWarningViewRefs {
    return this.props.d3eState;
  }
  public get _onAddUserHandler(): _AssignToAnotherOnPressed {
    return this.props._onAddUserHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("assignToAnother", null, this.assignToAnother);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["assignToAnother", "assignToAnother.", "assignToAnother.hover"],
      this.rebuild
    );
  }
  public assignToAnotherOnEnter(event): void {
    return this.assignToAnother.setHover(true);
  }
  public assignToAnotherOnExit(event): void {
    return this.assignToAnother.setHover(false);
  }
  public dispose(): void {
    this.assignToAnother.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.assignToAnother.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.assignToAnother.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.assignToAnother.disable,
        onPressed: () => {
          this._onAddUserHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Assign to another" }),
        onEnter: (event) => {
          this.assignToAnotherOnEnter(event);
        },
        onExit: (event) => {
          this.assignToAnotherOnExit(event);
        },
      }),
      className: "x7c4b",
    });
  }
}
function AssignToAnotherWithState(props: AssignToAnotherWithStateProps) {
  return React.createElement(_AssignToAnotherWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: TwiloReleaseWarningViewRefs;
  _cancelButtonHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): TwiloReleaseWarningViewRefs {
    return this.props.d3eState;
  }
  public get _cancelButtonHandler(): _CancelButtonOnPressed {
    return this.props._cancelButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._cancelButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Cancel" }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "x625",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

class _TwiloReleaseWarningViewState extends ObservableComponent<TwiloReleaseWarningViewProps> {
  d3eState: TwiloReleaseWarningViewRefs = new TwiloReleaseWarningViewRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: TwiloReleaseWarningViewProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {}
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title: "Do you want to release Twilio number? or Assign to another user?",
      content: ui.Column({
        children: [
          TextView({
            data: "Releasing Twilio number will remove it from your account and you will not be able to use it again.",
            softWrap: true,
            className: "x849 hc",
            key: "0",
          }),
          ui.Container({ className: "x5e4 hc vc", key: "1" }),
          TextView({
            data: "Release Twilio number to current user and assign to another user? You will not be able to use it again.",
            softWrap: true,
            className: "x805 hc",
            key: "2",
          }),
        ],
        className: "hc",
      }),
      buttons: [
        CancelButtonWithState({
          d3eState: this.d3eState,
          _cancelButtonHandler: this.cancelButtonHandler,
          key: "0",
        }),
        AssignToAnotherWithState({
          d3eState: this.d3eState,
          _onAddUserHandler: this.onAddUserHandler,
          key: "1",
        }),
        ReleaseNumberWithState({
          d3eState: this.d3eState,
          _onReleaseTwilioNumber: this.onReleaseTwilioNumber,
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "TwiloReleaseWarningView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public cancelButtonHandler = (
    d3eState: TwiloReleaseWarningViewRefs
  ): void => {
    this.navigator.close();
  };
  public onAddUserHandler = (d3eState: TwiloReleaseWarningViewRefs): void => {
    this.assignToAnotherUser();
  };
  public onReleaseTwilioNumber = (
    d3eState: TwiloReleaseWarningViewRefs
  ): void => {
    this.releaseTwilioNumber();
  };
  public get assignToAnotherUser(): _TwiloReleaseWarningViewAssignToAnotherUser {
    return this.props.assignToAnotherUser;
  }
  public get releaseTwilioNumber(): _TwiloReleaseWarningViewReleaseTwilioNumber {
    return this.props.releaseTwilioNumber;
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get assignToAnother() {
    return this.d3eState.assignToAnother;
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
  public get releaseNumber() {
    return this.d3eState.releaseNumber;
  }
}
export default function TwiloReleaseWarningView(
  props: TwiloReleaseWarningViewProps
) {
  return React.createElement(_TwiloReleaseWarningViewState, props);
}
