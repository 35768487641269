import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _SearchFilterButtonViewOnPressed = () => void;

export interface SearchFilterButtonViewProps extends BaseUIProps {
  key?: string;
  filterName?: string;
  icon?: ui.IconData;
  color?: ui.Color;
  onPressed?: _SearchFilterButtonViewOnPressed;
  content?: ReactNode;
  button?: ReactNode;
}

class _SearchFilterButtonViewState extends ObservableComponent<SearchFilterButtonViewProps> {
  static defaultProps = {
    filterName: "",
    icon: null,
    color: null,
    onPressed: null,
  };
  collapse: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SearchFilterButtonViewProps) {
    super(props);

    this.initState();
  }
  public get filterName(): string {
    return this.props.filterName;
  }
  public get icon(): ui.IconData {
    return this.props.icon;
  }
  public get color(): ui.Color {
    return this.props.color;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["collapse", "color", "filterName", "icon"], this.rebuild);
  }
  public componentDidUpdate(prevProps: SearchFilterButtonViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.filterName !== this.props.filterName) {
      this.fire("filterName", this);
    }

    if (prevProps.icon !== this.props.icon) {
      this.fire("icon", this);
    }

    if (prevProps.color !== this.props.color) {
      this.fire("color", this);
    }
  }
  public setCollapse(val: boolean): void {
    let isValChanged: boolean = this.collapse !== val;

    if (!isValChanged) {
      return;
    }

    this.collapse = val;

    this.fire("collapse", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      decoration: new ui.BoxDecoration({
        border: ui.Border.all({ color: this.color, width: 1.0 }),
        borderRadius: ui.BorderRadius.circular(5.0),
      }),
      child: ui.Column({
        children: [
          ui.Row({
            mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
            children: [
              ui.Row({
                children: [
                  IconView({
                    icon: this.icon,
                    size: 20,
                    className: "xe32",
                    key: "0",
                  }),
                  TextView({
                    data: this.filterName,
                    className: "xdd68",
                    key: "1",
                  }),
                ],
                key: "0",
              }),
              IconView({
                icon: this.collapse
                  ? MaterialIcons.arrow_drop_down
                  : MaterialIcons.arrow_right,
                className: "x5c8",
                key: "1",
              }),
            ],
            onTap: (e) => {
              e.stopPropagation();

              this.onRowPressedHandler();
            },
            className: "x459",
            key: "0",
          }),
          this.collapse
            ? ui.Container({ child: this.props.content, className: "x283" })
            : [],
        ],
      }),
      className: ui.join(this.props.className, "SearchFilterButtonView x920"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onRowPressedHandler = (): void => {
    this.setCollapse(!this.collapse);

    if (this.onPressed !== null) {
      this.onPressed();
    }
  };
  public get onPressed(): _SearchFilterButtonViewOnPressed {
    return this.props.onPressed;
  }
  public get contentPresent(): boolean {
    return this.props.content != null;
  }
  public get content(): ReactNode {
    return this.props.content;
  }
  public get buttonPresent(): boolean {
    return this.props.button != null;
  }
  public get button(): ReactNode {
    return this.props.button;
  }
}
export default function SearchFilterButtonView(
  props: SearchFilterButtonViewProps
) {
  return React.createElement(_SearchFilterButtonViewState, {
    ..._SearchFilterButtonViewState.defaultProps,
    ...props,
  });
}
