import React from "react";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";

interface HtmlProps extends BaseUIProps {
  data?: string;
}

class _Html extends ObservableComponent<HtmlProps> {
  public constructor(props: any) {
    super(props);
    this.initState();
  }

  public get data(): string {
    return this.props.data;
  }

  initState(): void {
    this.render();
  }

  render() {
    return React.createElement("div", {
      dangerouslySetInnerHTML: { __html: this.props.data },
    });
  }
}

export default function HtmlCodeToText(props: HtmlProps) {
  return React.createElement(_Html, props);
}
