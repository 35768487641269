import OneTimePassword from "../models/OneTimePassword";
import Company from "../models/Company";
import User from "../models/User";
import WSReader from "../rocket/WSReader";
import MessageDispatch from "../rocket/MessageDispatch";
import { RPCConstants } from "../rocket/D3ETemplate";

export default class UniqueCheckerClient {
  public async checkTokenUniqueInOneTimePassword(
    oneTimePassword: OneTimePassword,
    token: string
  ): Promise<boolean> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.UniqueChecker,
      RPCConstants.UniqueCheckerCheckTokenUniqueInOneTimePassword,
      {
        "args": (w) => {
          w.writeObjFull(oneTimePassword);

          w.writeString(token);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readBoolean() as boolean;
  }
  public async checkUserEmailUniqueInCompany(
    company: Company,
    user: User,
    email: string
  ): Promise<boolean> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.UniqueChecker,
      RPCConstants.UniqueCheckerCheckUserEmailUniqueInCompany,
      {
        "args": (w) => {
          w.writeObjFull(company);

          w.writeObjFull(user);

          w.writeString(email);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readBoolean() as boolean;
  }
}
