import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import TwilioClient from "../classes/TwilioClient";
import User from "../models/User";
import TeamView from "./TeamView";

export interface NewTeamPageProps extends BaseUIProps {
  key?: string;
  user: User;
  client: TwilioClient;
  data: CallData;
}

class _NewTeamPageState extends ObservableComponent<NewTeamPageProps> {
  static defaultProps = { user: null, client: null, data: null };
  public constructor(props: NewTeamPageProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("data", this.props.data);

    this.on(["client", "data", "user", "user.company"], this.rebuild);
  }
  public componentDidUpdate(prevProps: NewTeamPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public render(): ReactNode {
    return ui.Column({
      children: [
        ui.Container({
          expand: true,
          child: TeamView({
            company: this.user.company,
            client: this.client,
            data: this.data,
          }),
          key: "0",
          className: "x8ef hc vc h v",
        }),
      ],
      className: ui.join(this.props.className, "NewTeamPage hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function NewTeamPage(props: NewTeamPageProps) {
  return React.createElement(_NewTeamPageState, {
    ..._NewTeamPageState.defaultProps,
    ...props,
  });
}
