import User from "./User";
import LeadStatus from "../classes/LeadStatus";
import DateTime from "../core/DateTime";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class LeadsListRequest extends DBObject {
  private static readonly _APPLYSTATUS: number = 0;
  private static readonly _ASCENDING: number = 1;
  private static readonly _FROMDATE: number = 2;
  private static readonly _OFFSET: number = 3;
  private static readonly _ORDERBY: number = 4;
  private static readonly _PAGESIZE: number = 5;
  private static readonly _STATUS: number = 6;
  private static readonly _TODATE: number = 7;
  private static readonly _USER: number = 8;
  public id: number = 0;
  public otherMaster: DBObject;
  private _status: LeadStatus = LeadStatus.New;
  private _applyStatus: boolean = false;
  private _user: User = null;
  private _pageSize: number = 0;
  private _offset: number = 0;
  private _orderBy: string = "";
  private _ascending: boolean = false;
  private _toDate: DateTime = null;
  private _fromDate: DateTime = null;
  public constructor(
    d3eParams?: Partial<{
      applyStatus: boolean;
      ascending: boolean;
      fromDate: DateTime;
      offset: number;
      orderBy: string;
      pageSize: number;
      status: LeadStatus;
      toDate: DateTime;
      user: User;
    }>
  ) {
    super();

    this.setApplyStatus(d3eParams?.applyStatus ?? false);

    this.setAscending(d3eParams?.ascending ?? false);

    this.setFromDate(d3eParams?.fromDate ?? null);

    this.setOffset(d3eParams?.offset ?? 0);

    this.setOrderBy(d3eParams?.orderBy ?? "");

    this.setPageSize(d3eParams?.pageSize ?? 0);

    this.setStatus(d3eParams?.status ?? LeadStatus.New);

    this.setToDate(d3eParams?.toDate ?? null);

    this.setUser(d3eParams?.user ?? null);
  }
  public get d3eType(): string {
    return "LeadsListRequest";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isTransientModel(): boolean {
    return;
  }
  public get status(): LeadStatus {
    return this._status;
  }
  public setStatus(val: LeadStatus): void {
    let isValChanged: boolean = this._status !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadsListRequest._STATUS, this._status.index);

    this._status = val;

    this.fire("status", this);
  }
  public get applyStatus(): boolean {
    return this._applyStatus;
  }
  public setApplyStatus(val: boolean): void {
    let isValChanged: boolean = this._applyStatus !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadsListRequest._APPLYSTATUS, this._applyStatus);

    this._applyStatus = val;

    this.fire("applyStatus", this);
  }
  public get user(): User {
    return this._user;
  }
  public setUser(val: User): void {
    let isValChanged: boolean = this._user !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadsListRequest._USER, this._user);

    this.updateObservable("user", this._user, val);

    this._user = val;

    this.fire("user", this);
  }
  public get pageSize(): number {
    return this._pageSize;
  }
  public setPageSize(val: number): void {
    let isValChanged: boolean = this._pageSize !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadsListRequest._PAGESIZE, this._pageSize);

    this._pageSize = val;

    this.fire("pageSize", this);
  }
  public get offset(): number {
    return this._offset;
  }
  public setOffset(val: number): void {
    let isValChanged: boolean = this._offset !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadsListRequest._OFFSET, this._offset);

    this._offset = val;

    this.fire("offset", this);
  }
  public get orderBy(): string {
    return this._orderBy;
  }
  public setOrderBy(val: string): void {
    let isValChanged: boolean = this._orderBy !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadsListRequest._ORDERBY, this._orderBy);

    this._orderBy = val;

    this.fire("orderBy", this);
  }
  public get ascending(): boolean {
    return this._ascending;
  }
  public setAscending(val: boolean): void {
    let isValChanged: boolean = this._ascending !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadsListRequest._ASCENDING, this._ascending);

    this._ascending = val;

    this.fire("ascending", this);
  }
  public get toDate(): DateTime {
    return this._toDate;
  }
  public setToDate(val: DateTime): void {
    let isValChanged: boolean = this._toDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadsListRequest._TODATE, this._toDate);

    this._toDate = val;

    this.fire("toDate", this);
  }
  public get fromDate(): DateTime {
    return this._fromDate;
  }
  public setFromDate(val: DateTime): void {
    let isValChanged: boolean = this._fromDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(LeadsListRequest._FROMDATE, this._fromDate);

    this._fromDate = val;

    this.fire("fromDate", this);
  }
  public get(field: number): any {
    switch (field) {
      case LeadsListRequest._STATUS: {
        return this._status.index;
      }

      case LeadsListRequest._APPLYSTATUS: {
        return this._applyStatus;
      }

      case LeadsListRequest._USER: {
        return this._user;
      }

      case LeadsListRequest._PAGESIZE: {
        return this._pageSize;
      }

      case LeadsListRequest._OFFSET: {
        return this._offset;
      }

      case LeadsListRequest._ORDERBY: {
        return this._orderBy;
      }

      case LeadsListRequest._ASCENDING: {
        return this._ascending;
      }

      case LeadsListRequest._TODATE: {
        return this._toDate;
      }

      case LeadsListRequest._FROMDATE: {
        return this._fromDate;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): LeadsListRequest {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: LeadsListRequest = dbObj as LeadsListRequest;

    obj.id = this.id;

    obj.setStatus(this._status);

    obj.setApplyStatus(this._applyStatus);

    obj.setUser(this._user);

    obj.setPageSize(this._pageSize);

    obj.setOffset(this._offset);

    obj.setOrderBy(this._orderBy);

    obj.setAscending(this._ascending);

    obj.setToDate(this._toDate);

    obj.setFromDate(this._fromDate);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case LeadsListRequest._STATUS: {
        this.setStatus(LeadStatus.values[value as number]);
        break;
      }

      case LeadsListRequest._APPLYSTATUS: {
        this.setApplyStatus(value as boolean);
        break;
      }

      case LeadsListRequest._USER: {
        this.setUser(value as User);
        break;
      }

      case LeadsListRequest._PAGESIZE: {
        this.setPageSize(value as number);
        break;
      }

      case LeadsListRequest._OFFSET: {
        this.setOffset(value as number);
        break;
      }

      case LeadsListRequest._ORDERBY: {
        this.setOrderBy(value as string);
        break;
      }

      case LeadsListRequest._ASCENDING: {
        this.setAscending(value as boolean);
        break;
      }

      case LeadsListRequest._TODATE: {
        this.setToDate(value as DateTime);
        break;
      }

      case LeadsListRequest._FROMDATE: {
        this.setFromDate(value as DateTime);
        break;
      }
    }
  }
}
