import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import { BuildContext } from "../classes/BuildContext";

type _ToggleViewOnChanged = (value: boolean) => void;

export interface ToggleViewProps extends BaseUIProps {
  key?: string;
  value: boolean;
  onChanged?: _ToggleViewOnChanged;
}

class _ToggleViewState extends ObservableComponent<ToggleViewProps> {
  static defaultProps = { value: false, onChanged: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ToggleViewProps) {
    super(props);

    this.initState();
  }
  public get value(): boolean {
    return this.props.value;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["value"], this.rebuild);
  }
  public componentDidUpdate(prevProps: ToggleViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      alignment: ui.Alignment.center,
      child: ui.Stack({
        alignment: ui.AlignmentDirectional.centerStart,
        children: [
          ui.Container({
            states: ui.joinStates({ "data-c0": this.value }, {}),
            decoration: this.value
              ? new ui.BoxDecoration({
                  color: new ui.Color(0xff1bd51f),
                  borderRadius: ui.BorderRadius.circular(15.0),
                })
              : new ui.BoxDecoration({}),
            className: "x7ca hc vc",
            key: "0",
          }),
          this.value
            ? ui.Positioned({
                child: ui.Container({
                  child: ui.Container({ className: "xbab hc vc" }),
                  className: "x19c",
                }),
                right: 0,
              })
            : ui.Positioned({
                child: ui.Container({
                  child: ui.Container({ className: "xbabc hc vc" }),
                  className: "x19c7",
                }),
                left: 0,
              }),
        ],
        onTap: (e) => {
          e.stopPropagation();

          this.onChange();
        },
        className: "x8bd",
      }),
      className: ui.join(this.props.className, "ToggleView x020"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onChange = (): void => {
    if (this.onChanged !== null) {
      this.onChanged(!this.value);
    }
  };
  public get onChanged(): _ToggleViewOnChanged {
    return this.props.onChanged;
  }
}
export default function ToggleView(props: ToggleViewProps) {
  return React.createElement(_ToggleViewState, {
    ..._ToggleViewState.defaultProps,
    ...props,
  });
}
