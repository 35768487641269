import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import Browser from "../classes/Browser";
import User from "../models/User";
import TextView from "./TextView";
import UserRole from "../classes/UserRole";
import { BuildContext } from "../classes/BuildContext";

type _EmailButtonOnPressed = (d3eState: UserProfileViewRefs) => void;

export interface UserProfileViewProps extends BaseUIProps {
  key?: string;
  user: User;
}
/// To store state data for UserProfileView
class UserProfileViewRefs {
  public emailButton: EmailButtonState = new EmailButtonState();
}

interface EmailButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: UserProfileViewRefs;
  _onEmailButtonHandler?: _EmailButtonOnPressed;
  user: User;
}

class EmailButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _EmailButtonWithState extends ObservableComponent<EmailButtonWithStateProps> {
  emailButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: EmailButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get emailButton(): EmailButtonState {
    return this.props.d3eState.emailButton;
  }
  public get user(): User {
    return this.props.user;
  }
  public get d3eState(): UserProfileViewRefs {
    return this.props.d3eState;
  }
  public get _onEmailButtonHandler(): _EmailButtonOnPressed {
    return this.props._onEmailButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("emailButton", null, this.emailButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "emailButton",
        "emailButton.",
        "emailButton.hover",
        "user",
        "user.email",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: EmailButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public emailButtonOnEnter(event): void {
    return this.emailButton.setHover(true);
  }
  public emailButtonOnExit(event): void {
    return this.emailButton.setHover(false);
  }
  public dispose(): void {
    this.emailButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.zero,
      child: Button({
        disable: this.emailButton.disable,
        onPressed: () => {
          this._onEmailButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: this.user.email,
          style: new ui.TextStyle({
            fontSize: 14,
            fontWeight: ui.FontWeight.w500,
          }),
          className: "x0d5f",
        }),
        onEnter: (event) => {
          this.emailButtonOnEnter(event);
        },
        onExit: (event) => {
          this.emailButtonOnExit(event);
        },
      }),
      className: "x0b5",
    });
  }
}
function EmailButtonWithState(props: EmailButtonWithStateProps) {
  return React.createElement(_EmailButtonWithState, props);
}

class _UserProfileViewState extends ObservableComponent<UserProfileViewProps> {
  static defaultProps = { user: null };
  d3eState: UserProfileViewRefs = new UserProfileViewRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: UserProfileViewProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "user",
        "user.firstName",
        "user.lastName",
        "user.profile",
        "user.role",
        "user.twilioNumber",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: UserProfileViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              children: [
                ui.Container({
                  alignment: ui.Alignment.center,
                  child:
                    this.user.profile === null ||
                    this.user.profile.downloadUrl === null
                      ? ui.ClipRRect({
                          borderRadius: ui.BorderRadius.circular(50),
                          child: ui.AssetImage({
                            path: "images/profile1.png",
                            width: 100,
                            height: 100,
                            fit: ui.BoxFit.fill,
                            className: "x2a2 hc vc",
                          }),
                        })
                      : ui.ClipRRect({
                          borderRadius: ui.BorderRadius.circular(50),
                          child: ui.NetworkImage({
                            url:
                              this.user.profile.downloadUrl +
                              "?width=100&height=100&inline=true",
                            width: 100,
                            height: 100,
                            fit: ui.BoxFit.fill,
                            className: "xff37 hc vc",
                          }),
                        }),
                  className: "x3d2 hc vc",
                  key: "0",
                }),
                ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  children: [
                    TextView({
                      data: this.user.firstName + " " + this.user.lastName,
                      style: new ui.TextStyle({
                        fontSize: cStyle.tTextViewHeadlineFiveFontSizeOn,
                        fontWeight: cStyle.tTextViewHeadlineFiveFontWeightOn,
                      }),
                      className: "x13a",
                      key: "0",
                    }),
                    this.user.twilioNumber !== null &&
                    this.user.twilioNumber !== ""
                      ? TextView({
                          data: this.user.twilioNumber,
                          style: new ui.TextStyle({ fontSize: 14 }),
                          className: "x9393",
                        })
                      : [],
                    EmailButtonWithState({
                      d3eState: this.d3eState,
                      _onEmailButtonHandler: this.onEmailButtonHandler,
                      user: this.user,
                      key: "2",
                    }),
                    TextView({
                      data: this.user.role.name,
                      style: new ui.TextStyle({ fontSize: 14 }),
                      className: "x20a",
                      key: "3",
                    }),
                  ],
                  className: "xaaa0",
                  key: "1",
                }),
              ],
              key: "0",
            }),
          ],
          className: "xc0f hc h",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "UserProfileView x915 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onEmailButtonHandler = (d3eState: UserProfileViewRefs): void => {
    let subject: string =
      "Hi " + this.user.firstName + " " + this.user.lastName;

    let body: string =
      "Hi " + this.user.firstName + " " + this.user.lastName + ",\n\n";

    Browser.sendMailTo(this.user.email, { subject: subject, body: body });
  };
  public get emailButton() {
    return this.d3eState.emailButton;
  }
}
export default function UserProfileView(props: UserProfileViewProps) {
  return React.createElement(_UserProfileViewState, {
    ..._UserProfileViewState.defaultProps,
    ...props,
  });
}
