export default class LeadStatus {
  public static readonly New = new LeadStatus("New", "New", 0);
  public static readonly Contacted = new LeadStatus(
    "Contacted",
    "Contacted",
    1
  );
  public static readonly Inprogress = new LeadStatus(
    "Inprogress",
    "Inprogress",
    2
  );
  public static readonly Closed = new LeadStatus("Closed", "Closed", 3);
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): LeadStatus[] {
    return [
      LeadStatus.New,
      LeadStatus.Contacted,
      LeadStatus.Inprogress,
      LeadStatus.Closed,
    ];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
