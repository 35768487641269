import MicroSoftAcc from "../models/MicroSoftAcc";
import Lead from "../models/Lead";
import WSReader from "../rocket/WSReader";
import MessageDispatch from "../rocket/MessageDispatch";
import { RPCConstants } from "../rocket/D3ETemplate";

export default class LeadServiceClient {
  public async readEmailsFromLead(
    acc: MicroSoftAcc,
    lead: Lead
  ): Promise<boolean> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.LeadService,
      RPCConstants.LeadServiceReadEmailsFromLead,
      {
        "args": (w) => {
          w.writeObjFull(acc);

          w.writeObjFull(lead);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readBoolean() as boolean;
  }
  public async createLink(acc: MicroSoftAcc): Promise<string> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.LeadService,
      RPCConstants.LeadServiceCreateLink,
      {
        "args": (w) => {
          w.writeObjFull(acc);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readString() as string;
  }
  public async refreshAccessToken(acc: MicroSoftAcc): Promise<boolean> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.LeadService,
      RPCConstants.LeadServiceRefreshAccessToken,
      {
        "args": (w) => {
          w.writeObjFull(acc);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readBoolean() as boolean;
  }
}
