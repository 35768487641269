import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _LabelWithInputFieldOnChanged = (value: string) => void;

type _LabelWithInputFieldOnTap = () => void;

type _LabelWithInputFieldOnSubmitted = (value: string) => void;

export interface LabelWithInputFieldProps extends BaseUIProps {
  key?: string;
  name?: string;
  placeHolder?: string;
  isRequired?: boolean;
  value?: string;
  errors?: Array<string>;
  obscureText?: boolean;
  disable?: boolean;
  fieldColor?: ui.Color;
  maxLines?: number;
  cornerRadius?: number;
  keyboardType?: ui.TextInputType;
  dense?: boolean;
  inputFormatter?: RegExp;
  _errorsHash?: number;
  onChanged?: _LabelWithInputFieldOnChanged;
  onTap?: _LabelWithInputFieldOnTap;
  onSubmitted?: _LabelWithInputFieldOnSubmitted;
}

class _LabelWithInputFieldState extends ObservableComponent<LabelWithInputFieldProps> {
  static defaultProps = {
    name: "",
    placeHolder: "",
    isRequired: false,
    value: "",
    obscureText: false,
    disable: false,
    fieldColor: null,
    maxLines: 1,
    cornerRadius: 4.0,
    keyboardType: ui.TextInputType.text,
    dense: false,
    inputFormatter: null,
    errors: [],
    onChanged: null,
    onTap: null,
    onSubmitted: null,
  };
  fieldController: ui.TextEditingController = new ui.TextEditingController();
  focusNode: ui.FocusNode = null;
  active: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LabelWithInputFieldProps) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get value(): string {
    return this.props.value;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get obscureText(): boolean {
    return this.props.obscureText;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get fieldColor(): ui.Color {
    return this.props.fieldColor;
  }
  public get maxLines(): number {
    return this.props.maxLines;
  }
  public get cornerRadius(): number {
    return this.props.cornerRadius;
  }
  public get keyboardType(): ui.TextInputType {
    return this.props.keyboardType;
  }
  public get dense(): boolean {
    return this.props.dense;
  }
  public get inputFormatter(): RegExp {
    return this.props.inputFormatter;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.errors, "errors");

    this.on(
      [
        "cornerRadius",
        "dense",
        "disable",
        "errors",
        "fieldColor",
        "focusNode",
        "isRequired",
        "keyboardType",
        "maxLines",
        "name",
        "obscureText",
        "placeHolder",
        "value",
      ],
      this.rebuild
    );

    this.on(["value"], () => {
      this.fieldController.text = this.value;
    });
  }
  public componentDidUpdate(prevProps: LabelWithInputFieldProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.obscureText !== this.props.obscureText) {
      this.fire("obscureText", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.fieldColor !== this.props.fieldColor) {
      this.fire("fieldColor", this);
    }

    if (prevProps.maxLines !== this.props.maxLines) {
      this.fire("maxLines", this);
    }

    if (prevProps.cornerRadius !== this.props.cornerRadius) {
      this.fire("cornerRadius", this);
    }

    if (prevProps.keyboardType !== this.props.keyboardType) {
      this.fire("keyboardType", this);
    }

    if (prevProps.dense !== this.props.dense) {
      this.fire("dense", this);
    }

    if (prevProps.inputFormatter !== this.props.inputFormatter) {
      this.fire("inputFormatter", this);
    }
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this.active !== val;

    if (!isValChanged) {
      return;
    }

    this.active = val;

    this.fire("active", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: cStyle.tColumnFieldStyleCrossAxisAlignmentOn,
      children: [
        this.name !== null && this.name.isNotEmpty
          ? ui.Row({
              children: [
                TextView({
                  data: this.name,
                  style: new ui.TextStyle({
                    color: cStyle.tTextViewLabelTextColorOn,
                    fontSize: cStyle.tTextViewLabelTextFontSizeOn,
                    fontWeight: cStyle.tTextViewLabelTextFontWeightOn,
                  }),
                  className: "x5ed",
                  key: "0",
                }),
                this.isRequired
                  ? TextView({
                      data: "*",
                      style: new ui.TextStyle({
                        color: new ui.Color(0xffc20f2f),
                        fontSize: cStyle.tTextViewLabelTextFontSizeOn,
                        fontWeight: cStyle.tTextViewLabelTextFontWeightOn,
                      }),
                      className: "xb0c",
                    })
                  : [],
              ],
              className: "x0ed hc h",
            })
          : [],
        ui.InputField({
          value: this.value,
          focusNode: this.focusNode,
          obscureText: this.obscureText,
          placeHolder: this.placeHolder,
          disable: this.disable,
          maxLines: this.maxLines,
          dense: this.dense,
          keyboardType: this.keyboardType,
          cornerRadius: this.cornerRadius,
          padding: cStyle.tInputFieldDefaultInputFieldPaddingOn,
          backgroundColor: this.disable
            ? new ui.Color(0xffb4b4b4)
            : this.fieldColor === null
            ? new ui.Color(0xffffffff)
            : cStyle.tInputFieldDefaultInputFieldBackgroundColorOn,
          activeColor: cStyle.tInputFieldDefaultInputFieldActiveColorOn,
          style: cStyle.tInputFieldDefaultInputFieldStyleOn,
          placeHolderColor:
            cStyle.tInputFieldDefaultInputFieldPlaceHolderColorOn,
          inActiveColor: this.errors.isNotEmpty
            ? new ui.Color(0xffc20f2f)
            : cStyle.tInputFieldDefaultInputFieldInActiveColorOn,
          controller: this.fieldController,
          onChanged: (text) => {
            this.onChangeText(text);
          },
          onSubmitted: (text) => {
            this.onSubmiitedTextHandler(text);
          },
          onTap: () => {
            this.onTapHandker();
          },
          onFocusChange: (val) => {},
          states: ui.joinStates({ "data-c2": this.errors.isNotEmpty }, {}),
          className: "x07c hc vc h",
          key: "1",
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    textAlign: cStyle.tTextViewErrorTextTextAlignOn,
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorTextColorOn,
                    }),
                    className: "xd6d1 hc",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "x890 hc",
            })
          : [],
      ],
      className: ui.join(this.props.className, "LabelWithInputField x83f hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);
  };
  public onChangeFocus = (): void => {
    this.setActive(this.focusNode.hasFocus);

    if (this.active && this.onTap !== null) {
      this.onTap();
    }
  };
  public onChangeText = (text: string): void => {
    if (this.onChanged !== null) {
      this.onChanged(text);
    }
  };
  public onTapHandker = (): void => {
    this.errors.clear();

    if (this.onTap !== null) {
      this.onTap();
    }
  };
  public onSubmiitedTextHandler = (text: string): void => {
    if (this.onSubmitted !== null) {
      this.onSubmitted(text);
    }
  };
  public get onChanged(): _LabelWithInputFieldOnChanged {
    return this.props.onChanged;
  }
  public get onTap(): _LabelWithInputFieldOnTap {
    return this.props.onTap;
  }
  public get onSubmitted(): _LabelWithInputFieldOnSubmitted {
    return this.props.onSubmitted;
  }
}
export default function LabelWithInputField(props: LabelWithInputFieldProps) {
  return React.createElement(
    _LabelWithInputFieldState,
    { ..._LabelWithInputFieldState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
