import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";

export interface TextValueCollectionViewProps extends BaseUIProps {
  key?: string;
  label: string;
  items: Array<string>;
  _itemsHash?: number;
}

class _TextValueCollectionViewState extends ObservableComponent<TextValueCollectionViewProps> {
  static defaultProps = { label: "", items: [] };
  public constructor(props: TextValueCollectionViewProps) {
    super(props);

    this.initState();
  }
  public get label(): string {
    return this.props.label;
  }
  public get items(): Array<string> {
    return this.props.items;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.items, "items");

    this.on(["items", "label"], this.rebuild);
  }
  public componentDidUpdate(prevProps: TextValueCollectionViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.label !== this.props.label) {
      this.fire("label", this);
    }

    if (prevProps.items !== this.props.items) {
      this.fire("items", this);
    }
  }
  public render(): ReactNode {
    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.start,
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        TextView({ data: this.label, className: "xd0a hc", key: "0" }),
        TextView({ data: ":", className: "x8b79", key: "1" }),
        TextView({
          data: this.items.where((item) => item !== null).join(", "),
          softWrap: true,
          className: "xfd0 hc h",
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "TextValueCollectionView xfe5"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function TextValueCollectionView(
  props: TextValueCollectionViewProps
) {
  return React.createElement(
    _TextValueCollectionViewState,
    { ..._TextValueCollectionViewState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.items, "items")
  );
}
