import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import TabButton from "./TabButton";
import User from "../models/User";
import CompanyInformationView from "./CompanyInformationView";
import Education from "../models/Education";
import BasicView from "./BasicView";
import InteractionAndPreferencesView from "./InteractionAndPreferencesView";
import SideMenuUtils from "../classes/SideMenuUtils";
import LeadAssignment from "../models/LeadAssignment";
import FamilyInfo from "../models/FamilyInfo";
import ScrollView2 from "./ScrollView2";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import FamilyInformationView from "./FamilyInformationView";
import Interaction from "../models/Interaction";
import { BuildContext } from "../classes/BuildContext";

export interface LeadInformationPageProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  user: User;
  client: TwilioClient;
  data: CallData;
  isNewObj: boolean;
}

class _LeadInformationPageState extends ObservableComponent<LeadInformationPageProps> {
  static defaultProps = {
    lead: null,
    user: null,
    client: null,
    data: null,
    isNewObj: false,
  };
  column1ScrollController: ui.ScrollController = new ui.ScrollController();
  column2ScrollController: ui.ScrollController = new ui.ScrollController();
  selectedTab: string = "Basic";
  educationHistory: Education = null;
  interaction: Interaction = null;
  familyInfo: FamilyInfo = null;
  leadAssignment: LeadAssignment = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LeadInformationPageProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public get isNewObj(): boolean {
    return this.props.isNewObj;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("data", this.props.data);

    this.on(
      [
        "client",
        "data",
        "familyInfo",
        "isNewObj",
        "lead",
        "leadAssignment",
        "selectedTab",
        "user",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LeadInformationPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }

    if (prevProps.isNewObj !== this.props.isNewObj) {
      this.fire("isNewObj", this);
    }
  }
  public setSelectedTab(val: string): void {
    let isValChanged: boolean = this.selectedTab !== val;

    if (!isValChanged) {
      return;
    }

    this.selectedTab = val;

    this.fire("selectedTab", this);
  }
  public setEducationHistory(val: Education): void {
    let isValChanged: boolean = this.educationHistory !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("educationHistory", this.educationHistory, val);

    this.educationHistory = val;

    this.fire("educationHistory", this);
  }
  public setInteraction(val: Interaction): void {
    let isValChanged: boolean = this.interaction !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("interaction", this.interaction, val);

    this.interaction = val;

    this.fire("interaction", this);
  }
  public setFamilyInfo(val: FamilyInfo): void {
    let isValChanged: boolean = this.familyInfo !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("familyInfo", this.familyInfo, val);

    this.familyInfo = val;

    this.fire("familyInfo", this);
  }
  public setLeadAssignment(val: LeadAssignment): void {
    let isValChanged: boolean = this.leadAssignment !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("leadAssignment", this.leadAssignment, val);

    this.leadAssignment = val;

    this.fire("leadAssignment", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ScrollView2({
          child: ui.Column({
            crossAxisAlignment: ui.CrossAxisAlignment.start,
            children: [
              ui.Row({
                children: [
                  ui.Container({ className: "x91 hc vc h", key: "0" }),
                ],
                className: "xf0a hc h",
                key: "0",
              }),
              ui.Column({
                crossAxisAlignment: ui.CrossAxisAlignment.start,
                children: [
                  ui.Row({
                    children: [
                      SideMenuUtils.getTabItemNames("Manager").expand(
                        (tabItem) => [
                          ui.Container({
                            padding: ui.EdgeInsets.fromLTRB(
                              0.0,
                              0.0,
                              50.0,
                              0.0,
                              new Map()
                            ),
                            child: TabButton({
                              title: tabItem,
                              selectedTab: this.selectedTab,
                              onClick: (selectedTabItem) => {
                                this.onPressedTabHandler(
                                  selectedTabItem,
                                  tabItem
                                );
                              },
                            }),
                            key: tabItem?.toString(),
                            className: "x6f4",
                          }),
                        ]
                      ),
                    ],
                    className: "x63 hc h",
                    key: "0",
                  }),
                  ScrollView2({
                    child: ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        this.selectedTab === "Basic"
                          ? BasicView({
                              lead: this.lead,
                              leadAssignment: this.leadAssignment,
                              user: this.user,
                              client: this.client,
                              data: this.data,
                              isNewObj: this.isNewObj,
                              onSubmit: () => {
                                this.onBasicViewSaveHandler();
                              },
                              className: "x950 hc h",
                            })
                          : [],
                        this.selectedTab === "Company Information"
                          ? CompanyInformationView({
                              lead: this.lead,
                              client: this.client,
                              data: this.data,
                              isNewObj: this.isNewObj,
                              onSubmit: () => {
                                this.onCompanyInformationViewSaveHandler();
                              },
                              className: "x6c2 hc h",
                            })
                          : [],
                        this.selectedTab === "Interaction & Preferences"
                          ? InteractionAndPreferencesView({
                              interaction: this.lead,
                              client: this.client,
                              data: this.data,
                              isNewObj: this.isNewObj,
                              onSubmit: () => {
                                this.onInteractionAndPreferencesViewSaveHandler();
                              },
                              className: "x5ff hc h",
                            })
                          : [],
                        this.selectedTab === "Family Information"
                          ? FamilyInformationView({
                              info: this.familyInfo,
                              lead: this.lead,
                              user: this.user,
                              client: this.client,
                              data: this.data,
                              className: "xeed hc h",
                            })
                          : [],
                      ],
                      key: "1",
                    }),
                    scrollDirection: ui.Axis.vertical,
                    className: "x778 hc h",
                    controller: this.column2ScrollController,
                  }),
                ],
                className: "x6b2 hc h",
                key: "1",
              }),
            ],
            key: "0",
          }),
          scrollDirection: ui.Axis.vertical,
          className: "xf66 hc vc h v",
          controller: this.column1ScrollController,
        }),
      ],
      className: ui.join(this.props.className, "LeadInformationPage hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setSelectedTab("Basic");

    this.setEducationHistory(new Education());

    this.setInteraction(new Interaction({ lead: this.lead }));

    if (this.lead.familyInfo === null) {
      this.setFamilyInfo(new FamilyInfo());
    } else {
      this.setFamilyInfo(this.lead.familyInfo);
    }

    if (this.lead.leadAssignment === null) {
      this.setLeadAssignment(new LeadAssignment({ lead: this.lead }));
    } else {
      this.setLeadAssignment(this.lead.leadAssignment);
    }
  };
  public onPressedTabHandler = (
    selectedTabItem: string,
    tabItem: string
  ): void => {
    this.setSelectedTab(tabItem);
  };
  public onBasicViewSaveHandler = (): void => {
    this.setSelectedTab("Company Information");
  };
  public onCompanyInformationViewSaveHandler = (): void => {
    this.setSelectedTab("Interaction & Preferences");
  };
  public onInteractionAndPreferencesViewSaveHandler = (): void => {
    this.setSelectedTab("Family Information");
  };
}
export default function LeadInformationPage(props: LeadInformationPageProps) {
  return React.createElement(_LeadInformationPageState, {
    ..._LeadInformationPageState.defaultProps,
    ...props,
  });
}
