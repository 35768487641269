import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ToolTipWrapper from "./ToolTipWrapper";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _IconButtonOnPressed = () => void;

export interface IconButtonProps extends BaseUIProps {
  key?: string;
  icon: ui.IconData;
  tooltip?: string;
  disableMode?: boolean;
  color?: ui.Color;
  hoverColor?: ui.Color;
  disabledColor?: ui.Color;
  size?: number;
  onPressed?: _IconButtonOnPressed;
}

class _IconButtonState extends ObservableComponent<IconButtonProps> {
  static defaultProps = {
    icon: null,
    tooltip: "",
    disableMode: false,
    color: null,
    hoverColor: null,
    disabledColor: null,
    size: 0.0,
    onPressed: null,
  };
  mousePointerOn: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: IconButtonProps) {
    super(props);

    this.initState();
  }
  public get icon(): ui.IconData {
    return this.props.icon;
  }
  public get tooltip(): string {
    return this.props.tooltip;
  }
  public get disableMode(): boolean {
    return this.props.disableMode;
  }
  public get color(): ui.Color {
    return this.props.color;
  }
  public get hoverColor(): ui.Color {
    return this.props.hoverColor;
  }
  public get disabledColor(): ui.Color {
    return this.props.disabledColor;
  }
  public get size(): number {
    return this.props.size;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      [
        "color",
        "disableMode",
        "disabledColor",
        "hoverColor",
        "icon",
        "mousePointerOn",
        "size",
        "tooltip",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: IconButtonProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.icon !== this.props.icon) {
      this.fire("icon", this);
    }

    if (prevProps.tooltip !== this.props.tooltip) {
      this.fire("tooltip", this);
    }

    if (prevProps.disableMode !== this.props.disableMode) {
      this.fire("disableMode", this);
    }

    if (prevProps.color !== this.props.color) {
      this.fire("color", this);
    }

    if (prevProps.hoverColor !== this.props.hoverColor) {
      this.fire("hoverColor", this);
    }

    if (prevProps.disabledColor !== this.props.disabledColor) {
      this.fire("disabledColor", this);
    }

    if (prevProps.size !== this.props.size) {
      this.fire("size", this);
    }
  }
  public setMousePointerOn(val: boolean): void {
    let isValChanged: boolean = this.mousePointerOn !== val;

    if (!isValChanged) {
      return;
    }

    this.mousePointerOn = val;

    this.fire("mousePointerOn", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ToolTipWrapper({
      message: this.tooltip,
      child: ui.Container({
        child: IconView({
          icon: this.icon,
          color:
            this.disableMode && this.disabledColor !== null
              ? this.disabledColor
              : this.mousePointerOn && this.hoverColor !== null
              ? this.hoverColor
              : this.color,
          size: this.size !== 0.0 ? this.size : 24.0,
          states: ui.joinStates(
            {
              "data-c0":
                !this.mousePointerOn &&
                this.hoverColor === null &&
                this.color === null,
              "data-c1": this.disableMode && this.disabledColor === null,
            },
            {}
          ),
          onEnter: (event) => {
            this.onEnterHandler(event);
          },
          onExit: (event) => {
            this.onExitHandler(event);
          },
          onTap: (e) => {
            e.stopPropagation();

            this.onPressedHandler();
          },
        }),
        onFocusKey: (focusNode, event) => {
          return this.onKey(focusNode, event);
        },
        className: "x088",
      }),
      className: ui.join(this.props.className, "IconButton x5d7"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onEnterHandler = (event: ui.PointerEnterEvent): void => {
    this.setMousePointerOn(true);
  };
  public onExitHandler = (event: ui.PointerExitEvent): void => {
    this.setMousePointerOn(false);
  };
  public onPressedHandler = (): void => {
    if (!this.disableMode && this.onPressed !== null) {
      this.onPressed();
    }
  };
  public onKey = (
    focusNode: ui.FocusNode,
    event: ui.RawKeyEvent
  ): ui.KeyEventResult => {
    if (event instanceof ui.RawKeyDownEvent && !this.disableMode) {
      if (
        event.logicalKey === ui.LogicalKeyboardKey.enter ||
        event.logicalKey === ui.LogicalKeyboardKey.space ||
        event.logicalKey === ui.LogicalKeyboardKey.numpadEnter
      ) {
        if (this.onPressed !== null) {
          this.onPressed();
        }

        return ui.KeyEventResult.handled;
      }
    }

    return ui.KeyEventResult.ignored;
  };
  public get onPressed(): _IconButtonOnPressed {
    return this.props.onPressed;
  }
}
export default function IconButton(props: IconButtonProps) {
  return React.createElement(_IconButtonState, {
    ..._IconButtonState.defaultProps,
    ...props,
  });
}
