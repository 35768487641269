import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import PopupTargetController from "./PopupTargetController";
import ListWrapper from "../utils/ListWrapper";
import IndustryResultItem from "../classes/IndustryResultItem";
import InputFieldType from "../classes/InputFieldType";
import Popup from "./Popup";
import TextView from "./TextView";
import SearchIndustries from "./SearchIndustries";
import Chip from "./Chip";
import RPCServices from "../rpc/RPCServices";
import CollectionUtils from "../utils/CollectionUtils";
import { BuildContext } from "../classes/BuildContext";

type _CollectionIndustriesViewOnChanged = (
  value: Array<IndustryResultItem>
) => void;

type _CollectionIndustriesViewOnCancel = () => void;

export interface CollectionIndustriesViewProps extends BaseUIProps {
  key?: string;
  name: string;
  isRequired?: boolean;
  value: Array<IndustryResultItem>;
  items: Array<IndustryResultItem>;
  placeHolder: string;
  errors?: Array<string>;
  fieldType?: InputFieldType;
  disable?: boolean;
  _valueHash?: number;
  _itemsHash?: number;
  _errorsHash?: number;
  onChanged?: _CollectionIndustriesViewOnChanged;
  onCancel?: _CollectionIndustriesViewOnCancel;
}

class _CollectionIndustriesViewState extends ObservableComponent<CollectionIndustriesViewProps> {
  static defaultProps = {
    name: "",
    isRequired: false,
    placeHolder: "",
    fieldType: InputFieldType.Rectangle,
    disable: false,
    value: [],
    items: [],
    errors: [],
    onChanged: null,
    onCancel: null,
  };
  emptyController: ui.TextEditingController = new ui.TextEditingController();
  internalValue: Array<IndustryResultItem> = ListWrapper.widget(
    this,
    "internalValue"
  );
  popUpWidth: number = 150.0;
  focused: boolean = false;
  focusNode: ui.FocusNode = null;
  searchValue: string = "";
  data: Array<IndustryResultItem> = ListWrapper.widget(this, "data");
  filterValues: Array<IndustryResultItem> = ListWrapper.widget(
    this,
    "filterValues"
  );
  resultsPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public rowEmptyPopupTargetController: PopupTargetController =
    new PopupTargetController();
  public constructor(props: CollectionIndustriesViewProps) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get value(): Array<IndustryResultItem> {
    return this.props.value;
  }
  public get items(): Array<IndustryResultItem> {
    return this.props.items;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get fieldType(): InputFieldType {
    return this.props.fieldType;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.value, "value");

    this.updateSyncCollProperty("value", this.props.value);

    this.on(["value"], this.computeInternalValue);

    this.computeInternalValue();

    this.subscribeToList(this.items, "items");

    this.updateSyncCollProperty("items", this.props.items);

    this.subscribeToList(this.errors, "errors");

    this.on(
      [
        "disable",
        "errors",
        "fieldType",
        "focusNode",
        "focused",
        "internalValue",
        "internalValue.name",
        "isRequired",
        "name",
        "placeHolder",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: CollectionIndustriesViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.value !== this.props.value) {
      this.updateObservableColl("value", prevProps.value, this.props.value);

      this.fire("value", this);
    }

    if (prevProps.items !== this.props.items) {
      this.updateObservableColl("items", prevProps.items, this.props.items);

      this.fire("items", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.fieldType !== this.props.fieldType) {
      this.fire("fieldType", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }
  }
  public setInternalValue(val: Array<IndustryResultItem>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.internalValue,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("internalValue", this.internalValue, val);

    this.internalValue.clear();

    this.internalValue.addAll(val);

    this.fire("internalValue", this);
  }
  public addToInternalValue(val: IndustryResultItem, index: number = -1): void {
    if (index === -1) {
      if (!this.internalValue.contains(val)) this.internalValue.add(val);
    } else {
      this.internalValue.remove(this.internalValue.elementAt(index));

      this.internalValue.add(val);
    }

    this.fire("internalValue", this, val, true);

    this.updateObservable("internalValue", null, val);
  }
  public removeFromInternalValue(val: IndustryResultItem): void {
    this.internalValue.remove(val);

    this.fire("internalValue", this, val, false);

    this.removeObservable("internalValue", val);
  }
  public computeInternalValue = (): void => {
    try {
      this.setInternalValue(Array.from([...this.value]));
    } catch (exception) {
      console.log(
        " exception in computeInternalValue : " + exception.toString()
      );

      this.setInternalValue([]);
    }
  };
  public setPopUpWidth(val: number): void {
    let isValChanged: boolean = this.popUpWidth !== val;

    if (!isValChanged) {
      return;
    }

    this.popUpWidth = val;

    this.fire("popUpWidth", this);
  }
  public setFocused(val: boolean): void {
    let isValChanged: boolean = this.focused !== val;

    if (!isValChanged) {
      return;
    }

    this.focused = val;

    this.fire("focused", this);
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setSearchValue(val: string): void {
    let isValChanged: boolean = this.searchValue !== val;

    if (!isValChanged) {
      return;
    }

    this.searchValue = val;

    this.fire("searchValue", this);
  }
  public setData(val: Array<IndustryResultItem>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.data, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("data", this.data, val);

    this.data.clear();

    this.data.addAll(val);

    this.fire("data", this);
  }
  public addToData(val: IndustryResultItem, index: number = -1): void {
    if (index === -1) {
      if (!this.data.contains(val)) this.data.add(val);
    } else {
      this.data.remove(this.data.elementAt(index));

      this.data.add(val);
    }

    this.fire("data", this, val, true);

    this.updateObservable("data", null, val);
  }
  public removeFromData(val: IndustryResultItem): void {
    this.data.remove(val);

    this.fire("data", this, val, false);

    this.removeObservable("data", val);
  }
  public setFilterValues(val: Array<IndustryResultItem>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.filterValues,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("filterValues", this.filterValues, val);

    this.filterValues.clear();

    this.filterValues.addAll(val);

    this.fire("filterValues", this);
  }
  public addToFilterValues(val: IndustryResultItem, index: number = -1): void {
    if (index === -1) {
      if (!this.filterValues.contains(val)) this.filterValues.add(val);
    } else {
      this.filterValues.remove(this.filterValues.elementAt(index));

      this.filterValues.add(val);
    }

    this.fire("filterValues", this, val, true);

    this.updateObservable("filterValues", null, val);
  }
  public removeFromFilterValues(val: IndustryResultItem): void {
    this.filterValues.remove(val);

    this.fire("filterValues", this, val, false);

    this.removeObservable("filterValues", val);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          children: [
            TextView({ data: this.name, className: "x6d9", key: "0" }),
            this.isRequired ? TextView({ data: "*", className: "xf38" }) : [],
          ],
          className: "x7c1",
          key: "0",
        }),
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          states: ui.joinStates(
            {
              "data-c0":
                this.fieldType === InputFieldType.Circle && this.focused,
              "data-c1":
                this.fieldType === InputFieldType.Circle && !this.focused,
              "data-c2":
                this.fieldType === InputFieldType.Underline && !this.focused,
              "data-c3":
                this.fieldType === InputFieldType.Underline && this.focused,
              "data-c4":
                this.fieldType === InputFieldType.Rectangle && this.focused,
              "data-c5":
                this.fieldType === InputFieldType.Rectangle && !this.focused,
            },
            {}
          ),
          children: [
            ui.InputField({
              activeColor: cStyle.c1,
              inActiveColor: new ui.Color(0xffd9d9d9),
              focusNode: this.focusNode,
              padding: ui.EdgeInsets.all(4.0, new Map()),
              disable: this.disable,
              placeHolder: this.placeHolder,
              cornerRadius: 4.0,
              controller: this.emptyController,
              onChanged: (text) => {
                this.onChangeText(text);
              },
              onTap: () => {
                this.onEmptyClick();
              },
              onFocusChange: (val) => {},
              className: "xe4 hc vc h",
              key: "0",
            }),
          ],
          d3eRef: ui.LayoutAware((bounds, globalPos) => {
            this.onDropDownBoundsChanges(bounds, globalPos);
          }, this.rowEmptyPopupTargetController.handleRef),
          className: "x8ec h",
          key: "1",
        }),
        ui.Column({
          children: [
            ui.Wrap({
              spacing: 5,
              runSpacing: 5,
              children: [
                this.internalValue.expand((val) => [
                  Chip({
                    title: val.name,
                    onCancel: () => {
                      this.onDeletePressed(val);
                    },
                    key: val?.toString(),
                  }),
                ]),
              ],
              className: "x168",
              key: "0",
            }),
          ],
          className: "xd4a",
          key: "2",
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    textAlign: cStyle.tTextViewErrorTextTextAlignOn,
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorTextColorOn,
                    }),
                    className: "x769",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "x17f9",
            })
          : [],
      ],
      className: ui.join(
        this.props.className,
        "CollectionIndustriesView xa6 h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);

    this.setSearchValue("");
  };
  public onEmptyClick = async (): Promise<void> => {
    if (!this.disable) {
      this.setData(
        await RPCServices.getApolloRPCService().getIndustriesSuggestions(null)
      );

      this.data.sort((a, b) => a.name.compareTo(b.name));

      this.setFilterValues(this.data);

      this.showResults({ autoClose: true, takeFocus: false });

      this.setSearchValue("");

      this.setFocused(true);
    }
  };
  public onSelectListTile = (item: IndustryResultItem): void => {
    if (this.onChanged !== null) {
      if (!this.internalValue.contains(item)) {
        this.internalValue.add(item);
      }

      this.onChanged(this.internalValue);

      this.emptyController.text = "";
    }

    this.hideResults();

    this.setFocused(false);

    this.setSearchValue("");
  };
  public onDropDownBoundsChanges = (
    bounds: ui.Rect,
    globalPos: ui.Offset
  ): void => {
    this.setPopUpWidth(bounds.width - 12);
  };
  public onDeletePressed = (val: IndustryResultItem): void => {
    if (this.onChanged !== null) {
      this.internalValue.remove(val);

      this.onChanged(this.internalValue);
    }

    if (this.onCancel !== null) {
      this.onCancel();
    }
  };
  public onChangeFocus = (): void => {
    this.setFocused(this.focusNode.hasFocus);
  };
  public onChangeText = (text: string): void => {
    let val: string = text !== null ? text : "";

    this.setFilterValues(
      this.data
        .where((element) =>
          element.name.toLowerCase().contains(val.toLowerCase())
        )
        .toList()
    );

    if (val !== "" || val.isNotEmpty) {
      this.showResults({ autoClose: true, takeFocus: false });
    }
  };
  public get onChanged(): _CollectionIndustriesViewOnChanged {
    return this.props.onChanged;
  }
  public get onCancel(): _CollectionIndustriesViewOnCancel {
    return this.props.onCancel;
  }
  public dispose(): void {
    this.resultsPopup?.dispose();

    super.dispose();
  }
  public showResults(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.resultsPopup?.dispose();

    let target: ui.Rect = this.rowEmptyPopupTargetController.getTarget(
      this.context
    );

    this.resultsPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Bottom,
      child: ui.Container({
        height: 200,
        width: this.popUpWidth,
        child: SearchIndustries({
          items: this.filterValues,
          onSelectItem: (item) => {
            this.onSelectListTile(item);
          },
        }),
        className: "xfe0 hc vc",
      }),
      target: target,
    });

    this.resultsPopup.showPopup(this.context);
  }
  public hideResults(): void {
    this.resultsPopup?.dispose();
  }
}
export default function CollectionIndustriesView(
  props: CollectionIndustriesViewProps
) {
  return React.createElement(
    _CollectionIndustriesViewState,
    { ..._CollectionIndustriesViewState.defaultProps, ...props },
    ListWrapper.fromInput<IndustryResultItem>(props.value, "value"),
    ListWrapper.fromInput<IndustryResultItem>(props.items, "items"),
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
