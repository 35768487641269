import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import AddNoteButtonWidget from "./AddNoteButtonWidget";
import ResultStatus from "../classes/ResultStatus";
import LabelWithInputField from "./LabelWithInputField";
import D3EDate from "../classes/D3EDate";
import LeadStatus from "../classes/LeadStatus";
import LabelWithDateField from "./LabelWithDateField";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import Result from "../classes/Result";
import ListWrapper from "../utils/ListWrapper";
import InteractionType from "../classes/InteractionType";
import CheckServerErrors from "./CheckServerErrors";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import TextView from "./TextView";
import Chip from "./Chip";
import LabelDropdown from "./LabelDropdown";
import CollectionUtils from "../utils/CollectionUtils";
import CancelSaveButtonsWidget from "./CancelSaveButtonsWidget";
import Divider from "./Divider";
import { Runnable } from "../classes/core";
import { BuildContext } from "../classes/BuildContext";

type _InteractionAndPreferencesViewOnSubmit = () => void;

type _LabelWithDateFieldOnChanged = (text: D3EDate) => void;

type _LabelDropdown2OnChanged = (text: InteractionType) => void;

type _LabelWithInputField3OnChanged = (text: string) => void;

type _LabelWithInputField4OnChanged = (text: string) => void;

type _LabelWithInputField5OnChanged = (text: string) => void;

type _LabelWithInputField7OnChanged = (text: string) => void;

type _LabelWithInputField8OnChanged = (text: string) => void;

type _LabelWithInputField9OnChanged = (text: string) => void;

type _LabelWithInputField10OnChanged = (text: string) => void;

type _LabelWithInputField11OnChanged = (text: string) => void;

export interface InteractionAndPreferencesViewProps extends BaseUIProps {
  key?: string;
  interaction: Lead;
  client: TwilioClient;
  data: CallData;
  isNewObj: boolean;
  onSubmit?: _InteractionAndPreferencesViewOnSubmit;
}

class _InteractionAndPreferencesViewState extends ObservableComponent<InteractionAndPreferencesViewProps> {
  static defaultProps = {
    interaction: null,
    client: null,
    data: null,
    isNewObj: false,
    onSubmit: null,
  };
  errors: Array<string> = ListWrapper.widget(this, "errors");
  user: User = null;
  language: string = "";
  interest: string = "";
  hackValue: boolean = false;
  private _editInitListeners: Array<Runnable> = [];
  private _d3eModelErrors: Array<string> = [];
  private _nameErrors: Array<string> = [];
  private _statusErrors: Array<string> = [];
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: InteractionAndPreferencesViewProps) {
    super(props);

    this.initState();
  }
  public get interaction(): Lead {
    return this.props.interaction;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public get isNewObj(): boolean {
    return this.props.isNewObj;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("interaction", this.props.interaction);

    this.updateSyncProperty("data", this.props.data);

    this.on(
      [
        "interaction.leadAssignment",
        "interaction.leadAssignment.assignedTo",
        "interaction",
      ],
      this.computeHandlingUser
    );

    this.computeHandlingUser();

    this.on(
      [
        "errors",
        "hackValue",
        "interaction",
        "interaction.details",
        "interaction.expressedNeeds",
        "interaction.facebookProfile",
        "interaction.identifiedPainPoints",
        "interaction.interactionDate",
        "interaction.interactionType",
        "interaction.interests",
        "interaction.languages",
        "interaction.linkedInProfile",
        "interaction.twitterHandle",
        "interest",
        "isNewObj",
        "language",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(
    prevProps: InteractionAndPreferencesViewProps
  ): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.interaction !== this.props.interaction) {
      this.updateObservable(
        "interaction",
        prevProps.interaction,
        this.props.interaction
      );

      this.fire("interaction", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }

    if (prevProps.isNewObj !== this.props.isNewObj) {
      this.fire("isNewObj", this);
    }
  }
  public setErrors(val: Array<string>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.errors, val);

    if (!isValChanged) {
      return;
    }

    this.errors.clear();

    this.errors.addAll(val);

    this.fire("errors", this);
  }
  public addToErrors(val: string, index: number = -1): void {
    if (index === -1) {
      if (!this.errors.contains(val)) this.errors.add(val);
    } else {
      this.errors.remove(this.errors.elementAt(index));

      this.errors.add(val);
    }

    this.fire("errors", this, val, true);
  }
  public removeFromErrors(val: string): void {
    this.errors.remove(val);

    this.fire("errors", this, val, false);
  }
  public setUser(val: User): void {
    let isValChanged: boolean = this.user !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("user", this.user, val);

    this.user = val;

    this.fire("user", this);
  }
  public setLanguage(val: string): void {
    let isValChanged: boolean = this.language !== val;

    if (!isValChanged) {
      return;
    }

    this.language = val;

    this.fire("language", this);
  }
  public setInterest(val: string): void {
    let isValChanged: boolean = this.interest !== val;

    if (!isValChanged) {
      return;
    }

    this.interest = val;

    this.fire("interest", this);
  }
  public setHackValue(val: boolean): void {
    let isValChanged: boolean = this.hackValue !== val;

    if (!isValChanged) {
      return;
    }

    this.hackValue = val;

    this.fire("hackValue", this);
  }
  public get d3eModelErrors(): Array<string> {
    return this._d3eModelErrors ?? [];
  }
  public set d3eModelErrors(val: Array<string>) {
    this._d3eModelErrors.clear();

    this._d3eModelErrors.addAll(val);
  }
  public get nameErrors(): Array<string> {
    return this._nameErrors ?? [];
  }
  public set nameErrors(val: Array<string>) {
    this._nameErrors.clear();

    this._nameErrors.addAll(val);
  }
  public async computeNameErrors(): Promise<void> {
    this.nameErrors = [];

    let it: string = this.interaction.name;

    if (it == null || it === "") {
      this.nameErrors.add("Name cannot be empty.");
    }

    this.fire("nameErrors", this);
  }
  public get statusErrors(): Array<string> {
    return this._statusErrors ?? [];
  }
  public set statusErrors(val: Array<string>) {
    this._statusErrors.clear();

    this._statusErrors.addAll(val);
  }
  public async computeStatusErrors(): Promise<void> {
    this.statusErrors = [];

    let it: LeadStatus = this.interaction.status;

    this.fire("statusErrors", this);
  }
  public computeHandlingUser = (): void => {
    try {
      this.interaction.setHandlingUser(
        this.interaction.leadAssignment !== null
          ? this.interaction.leadAssignment.assignedTo
          : null
      );
    } catch (exception) {
      console.log(
        " exception in computeHandlingUser : " + exception.toString()
      );

      this.interaction.setHandlingUser(null);
    }
  };
  public setEditDefaults(): void {}
  public get isEditValid(): boolean {
    if (this.nameErrors.isNotEmpty) {
      return false;
    }

    if (this.statusErrors.isNotEmpty) {
      return false;
    }

    return true;
  }
  public validate(): Array<string> {
    let errors: Array<string> = [];

    this.computeNameErrors();

    errors.addAll(this.nameErrors);

    this.computeStatusErrors();

    errors.addAll(this.statusErrors);

    return errors;
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Column({
          children: [
            ui.Row({
              children: [
                ui.Container({
                  expand: true,
                  child: LabelWithDateField({
                    name: "Date",
                    placeHolder: "-- Select Date --",
                    value: this.interaction.interactionDate,
                    onChanged: (text) => {
                      this.labelWithDateFieldonChanged(text);
                    },
                  }),
                  key: "0",
                  className: "x004 hc h",
                }),
                ui.Container({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 15.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: LabelDropdown<InteractionType>({
                    name: "Interaction Type",
                    placeHolder: "-- Select Interaction Type --",
                    value: this.interaction.interactionType,
                    items: InteractionType.values,
                    width: 400,
                    onChanged: (text) => {
                      this.labelDropdown2onChanged(text);
                    },
                    builder: (context, item) => {
                      return TextView({ data: item.name });
                    },
                  }),
                  key: "1",
                  className: "x34d",
                }),
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "Details",
                    placeHolder: "Enter Details",
                    value: this.interaction.details,
                    onChanged: (text) => {
                      this.labelWithInputField3onChanged(text);
                    },
                  }),
                  key: "2",
                  className: "xbcc hc h",
                }),
              ],
              className: "xde63 hc h",
              key: "0",
            }),
            ui.Row({
              children: [
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "Expressed Needs",
                    placeHolder: "Add Express Needs",
                    value: this.interaction.expressedNeeds,
                    onChanged: (text) => {
                      this.labelWithInputField4onChanged(text);
                    },
                  }),
                  key: "0",
                  className: "x34c hc h",
                }),
                ui.Container({
                  expand: true,
                  margin: ui.EdgeInsets.fromLTRB(
                    15.0,
                    0.0,
                    0.0,
                    0.0,
                    new Map()
                  ),
                  child: LabelWithInputField({
                    name: "Identified Pain Points",
                    placeHolder: "Add Identified Pain Points",
                    value: this.interaction.identifiedPainPoints,
                    onChanged: (text) => {
                      this.labelWithInputField5onChanged(text);
                    },
                  }),
                  key: "1",
                  className: "x45 hc h",
                }),
              ],
              className: "x533 hc h",
              key: "1",
            }),
            ui.Row({
              children: [
                TextView({
                  data: "Preferences",
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineFiveFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineFiveFontWeightOn,
                  }),
                  className: "xde2c",
                  key: "0",
                }),
                ui.Container({
                  expand: true,
                  child: Divider({}),
                  key: "1",
                  className: "x731 hc h",
                }),
              ],
              className: "xaf6 hc h",
              key: "2",
            }),
            ui.Row({
              children: [
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "LinkedIn Profile",
                    placeHolder: "Enter LinkedIn URL",
                    value: this.interaction.linkedInProfile,
                    onChanged: (text) => {
                      this.labelWithInputField7onChanged(text);
                    },
                  }),
                  key: "0",
                  className: "x9d9 hc h",
                }),
                ui.Container({
                  expand: true,
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 15.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: LabelWithInputField({
                    name: "Twitter Handle",
                    placeHolder: "Enter Twitter URL",
                    value: this.interaction.twitterHandle,
                    onChanged: (text) => {
                      this.labelWithInputField8onChanged(text);
                    },
                  }),
                  key: "1",
                  className: "xbf2 hc h",
                }),
                ui.Container({
                  expand: true,
                  child: LabelWithInputField({
                    name: "Facebook Profile",
                    placeHolder: "Enter Facebook URL",
                    value: this.interaction.facebookProfile,
                    onChanged: (text) => {
                      this.labelWithInputField9onChanged(text);
                    },
                  }),
                  key: "2",
                  className: "xde25 hc h",
                }),
              ],
              className: "xa82 hc h",
              key: "3",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Column({
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Row({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      children: [
                        ui.Column({
                          mainAxisAlignment: ui.MainAxisAlignment.start,
                          crossAxisAlignment: ui.CrossAxisAlignment.start,
                          children: [
                            LabelWithInputField({
                              name: "Languages",
                              placeHolder: "Add languages",
                              value: this.language,
                              onChanged: (text) => {
                                this.labelWithInputField10onChanged(text);
                              },
                              key: this.hackValue ? "0" : "",
                              className: "x072 hc h",
                            }),
                            ui.Wrap({
                              spacing: 5,
                              runSpacing: 5,
                              children: [
                                this.interaction.languages.expand((item) => [
                                  Chip({
                                    title: item,
                                    onCancel: () => {
                                      this.removeLanguagesButtonHandler(item);
                                    },
                                    className: "hc",
                                    key: item?.toString(),
                                  }),
                                ]),
                              ],
                              className: "x5fa9 hc",
                              key: "1",
                            }),
                          ],
                          className: "x53a hc h",
                          key: "0",
                        }),
                        ui.Container({
                          margin: ui.EdgeInsets.fromLTRB(
                            0.0,
                            40.0,
                            0.0,
                            0.0,
                            new Map()
                          ),
                          child: AddNoteButtonWidget({
                            isAdded: false,
                            onAddNote: (isAdded) => {
                              this.onAddNoteHandler(isAdded);
                            },
                          }),
                          key: "1",
                          className: "x1f1",
                        }),
                      ],
                      className: "x1eb hc h",
                      key: "0",
                    }),
                  ],
                  className: "x733 hc h",
                  key: "0",
                }),
                ui.Column({
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Row({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      children: [
                        ui.Column({
                          mainAxisAlignment: ui.MainAxisAlignment.start,
                          crossAxisAlignment: ui.CrossAxisAlignment.start,
                          children: [
                            ui.Container({
                              margin: ui.EdgeInsets.fromLTRB(
                                15.0,
                                0.0,
                                0.0,
                                0.0,
                                new Map()
                              ),
                              child: LabelWithInputField({
                                name: "Interests",
                                placeHolder: "Add Interests",
                                value: this.interest,
                                onChanged: (text) => {
                                  this.labelWithInputField11onChanged(text);
                                },
                                key: this.hackValue ? "0" : "",
                              }),
                              key: "0",
                              className: "x1fb hc h",
                            }),
                            ui.Wrap({
                              spacing: 5,
                              runSpacing: 5,
                              children: [
                                this.interaction.interests.expand((item) => [
                                  Chip({
                                    title: item,
                                    onCancel: () => {
                                      this.removeInterestButtonHandler(item);
                                    },
                                    className: "hc",
                                    key: item?.toString(),
                                  }),
                                ]),
                              ],
                              className: "x945 hc",
                              key: "1",
                            }),
                          ],
                          className: "xfcb8 hc h",
                          key: "0",
                        }),
                        ui.Container({
                          margin: ui.EdgeInsets.fromLTRB(
                            0.0,
                            40.0,
                            0.0,
                            0.0,
                            new Map()
                          ),
                          child: AddNoteButtonWidget({
                            isAdded: false,
                            onAddNote: (isAdded) => {
                              this.onAddInterestHandler(isAdded);
                            },
                          }),
                          key: "1",
                          className: "x796",
                        }),
                      ],
                      className: "x4a5 hc h",
                      key: "0",
                    }),
                  ],
                  className: "xa50 hc h",
                  key: "1",
                }),
              ],
              className: "xe52 hc h",
              key: "4",
            }),
          ],
          className: "x70c hc h",
          key: "0",
        }),
        ui.Container({ className: "xee hc vc", key: "1" }),
        this.errors.isNotEmpty
          ? CheckServerErrors({ errors: this.errors, className: "xdf1 hc" })
          : [],
        CancelSaveButtonsWidget({
          title: "Next",
          isNewObj: this.isNewObj,
          onCancel: () => {
            this.onCancelHandler();
          },
          onSave: () => {
            this.onSaveHandler();
          },
          onSaveAndCancel: () => {
            this.onSaveAndCancelHandler();
          },
          className: "x31d hc h",
          key: "3",
        }),
      ],
      className: ui.join(
        this.props.className,
        "InteractionAndPreferencesView x79d hc h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onCancelHandler = (): void => {
    this.navigator.pushLeadManagementPage({
      user: this.user,
      replace: true,
      client: this.client,
      target: "main",
      data: this.data,
    });
  };
  public onSaveHandler = async (): Promise<void> => {
    if (this.validate().isEmpty) {
      let result: Result<Lead> = await this.interaction.save();

      if (result.status === ResultStatus.Success) {
      } else {
        this.setErrors(result.errors);
      }
    }

    if (this.onSubmit !== null) {
      this.onSubmit();
    }
  };
  public onSaveAndCancelHandler = async (): Promise<void> => {
    if (this.validate().isEmpty) {
      let result: Result<Lead> = await this.interaction.save();

      if (result.status === ResultStatus.Success) {
      } else {
        this.setErrors(result.errors);
      }
    }

    this.navigator.pushLeadManagementPage({
      user: this.user,
      replace: true,
      client: this.client,
      target: "main",
      data: this.data,
    });
  };
  public onAddNoteHandler = (isAdded: boolean): void => {
    this.setHackValue(!this.hackValue);

    if (this.language !== "") {
      this.interaction.languages.add(this.language);

      this.setLanguage("");
    }
  };
  public onAddInterestHandler = (isAdded: boolean): void => {
    this.setHackValue(!this.hackValue);

    if (this.interest !== "") {
      this.interaction.interests.add(this.interest);

      this.setInterest("");
    }
  };
  public removeLanguagesButtonHandler = (item: string): void => {
    this.interaction.languages.removeAt(
      this.interaction.languages.indexOf(item)
    );
  };
  public removeInterestButtonHandler = (item: string): void => {
    this.interaction.interests.removeAt(
      this.interaction.interests.indexOf(item)
    );
  };
  public get onSubmit(): _InteractionAndPreferencesViewOnSubmit {
    return this.props.onSubmit;
  }
  public labelWithDateFieldonChanged = (val: D3EDate): void => {
    this.interaction.setInteractionDate(val);
  };
  public labelDropdown2onChanged = (val: InteractionType): void => {
    this.interaction.setInteractionType(val);
  };
  public labelWithInputField3onChanged = (val: string): void => {
    this.interaction.setDetails(val);
  };
  public labelWithInputField4onChanged = (val: string): void => {
    this.interaction.setExpressedNeeds(val);
  };
  public labelWithInputField5onChanged = (val: string): void => {
    this.interaction.setIdentifiedPainPoints(val);
  };
  public labelWithInputField7onChanged = (val: string): void => {
    this.interaction.setLinkedInProfile(val);
  };
  public labelWithInputField8onChanged = (val: string): void => {
    this.interaction.setTwitterHandle(val);
  };
  public labelWithInputField9onChanged = (val: string): void => {
    this.interaction.setFacebookProfile(val);
  };
  public labelWithInputField10onChanged = (val: string): void => {
    this.setLanguage(val);
  };
  public labelWithInputField11onChanged = (val: string): void => {
    this.setInterest(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function InteractionAndPreferencesView(
  props: InteractionAndPreferencesViewProps
) {
  return React.createElement(_InteractionAndPreferencesViewState, {
    ..._InteractionAndPreferencesViewState.defaultProps,
    ...props,
  });
}
