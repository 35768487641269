import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallData from "../classes/CallData";
import LeadStatus from "../classes/LeadStatus";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import DataSet from "../classes/DataSet";
import LeadUtils from "../classes/LeadUtils";
import Doughnut2DChart from "./Doughnut2DChart";
import Query from "../classes/Query";
import Button from "./Button";
import DashboardCardWidget from "./DashboardCardWidget";
import ListWrapper from "../utils/ListWrapper";
import LeadAssignment from "../models/LeadAssignment";
import LeadAssignedUsers from "../classes/LeadAssignedUsers";
import ScrollView2 from "./ScrollView2";
import TwilioClient from "../classes/TwilioClient";
import TextView from "./TextView";
import ChartDataUtils from "../classes/ChartDataUtils";
import LabelDropdown from "./LabelDropdown";
import CollectionUtils from "../utils/CollectionUtils";
import LeadAssignedUsersRequest from "../models/LeadAssignedUsersRequest";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _LabelDropdown1OnChanged = (
  text: string,
  d3eState: SalesDashboardViewRefs
) => void;

type _LabelDropdownOnChanged = (
  text: string,
  d3eState: SalesDashboardViewRefs
) => void;

export interface SalesDashboardViewProps extends BaseUIProps {
  key?: string;
  user: User;
  client: TwilioClient;
  data: CallData;
}
/// To store state data for SalesDashboardView
class SalesDashboardViewRefs {
  column3ScrollController: ui.ScrollController = new ui.ScrollController();
  public itemState: Map<LeadAssignment, _ItemState> = new Map();
  public forItem(item: LeadAssignment): _ItemState {
    let res = this.itemState.get(item);

    if (res == null) {
      res = new _ItemState(this, item);

      this.itemState.set(item, res);
    }

    return res;
  }
}

interface Button2WithStateProps extends BaseUIProps {
  key?: string;
  d3eState: _ItemState;
  item: LeadAssignment;
}

class Button2State extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _Button2WithState extends ObservableComponent<Button2WithStateProps> {
  button2FocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: Button2WithStateProps) {
    super(props);

    this.initState();
  }
  public get button2(): Button2State {
    return this.props.d3eState.button2;
  }
  public get item(): LeadAssignment {
    return this.props.item;
  }
  public get d3eState(): _ItemState {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button2", null, this.button2);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("item", this.props.item);

    this.on(["button2", "item", "item.lead", "item.lead.status"], this.rebuild);
  }
  public componentDidUpdate(prevProps: Button2WithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.item !== this.props.item) {
      this.updateObservable("item", prevProps.item, this.props.item);

      this.fire("item", this);
    }
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 120,
      child: Button({
        decoration:
          this.item.lead.status.name === "New"
            ? new ui.BoxDecoration({
                color: cStyle.c4,
                borderRadius: ui.BorderRadius.circular(3.0),
              })
            : this.item.lead.status.name === "Inprogress"
            ? new ui.BoxDecoration({
                color: cStyle.c5,
                borderRadius: ui.BorderRadius.circular(3.0),
              })
            : this.item.lead.status.name === "Closed"
            ? new ui.BoxDecoration({
                color: cStyle.c7,
                borderRadius: ui.BorderRadius.circular(3.0),
              })
            : this.item.lead.status.name === "Contacted"
            ? new ui.BoxDecoration({
                color: cStyle.c6,
                borderRadius: ui.BorderRadius.circular(3.0),
              })
            : new ui.BoxDecoration({
                borderRadius: ui.BorderRadius.circular(3.0),
              }),
        padding:
          this.item.lead.status.name === "New"
            ? ui.EdgeInsets.symmetric({
                horizontal: 20.0,
                vertical: 5.0,
                transitions: new Map(),
              })
            : this.item.lead.status.name === "Inprogress"
            ? ui.EdgeInsets.symmetric({
                horizontal: 20.0,
                vertical: 5.0,
                transitions: new Map(),
              })
            : this.item.lead.status.name === "Closed"
            ? ui.EdgeInsets.symmetric({
                horizontal: 20.0,
                vertical: 5.0,
                transitions: new Map(),
              })
            : this.item.lead.status.name === "Contacted"
            ? ui.EdgeInsets.symmetric({
                horizontal: 20.0,
                vertical: 5.0,
                transitions: new Map(),
              })
            : ui.EdgeInsets.symmetric({
                horizontal: 7.0,
                vertical: 5.0,
                transitions: new Map(),
              }),
        disable: this.button2.disable,
        onFocusChange: (val) => {},
        child: TextView({
          data: this.item.lead.status.name,
          style: new ui.TextStyle({ color: cStyle.c14 }),
          className: "x14c",
        }),
      }),
      className: "xa40 hc",
    });
  }
}
function Button2WithState(props: Button2WithStateProps) {
  return React.createElement(_Button2WithState, props);
}

class _ItemState {
  parent: SalesDashboardViewRefs;
  item: LeadAssignment;
  button2: Button2State = new Button2State();
  public constructor(parent, item) {
    this.parent = parent;

    this.item = item;
  }
}

class _SalesDashboardViewState extends ObservableComponent<SalesDashboardViewProps> {
  static defaultProps = { user: null, client: null, data: null };
  d3eState: SalesDashboardViewRefs = new SalesDashboardViewRefs();
  request: LeadAssignedUsersRequest = null;
  query: LeadAssignedUsers = null;
  leadAssignments: Array<LeadAssignment> = ListWrapper.widget(
    this,
    "leadAssignments"
  );
  leadFilteredList: Array<LeadAssignment> = ListWrapper.widget(
    this,
    "leadFilteredList"
  );
  filterLeadsList: Array<LeadAssignment> = ListWrapper.widget(
    this,
    "filterLeadsList"
  );
  inProgressLeadAssignments: Array<LeadAssignment> = ListWrapper.widget(
    this,
    "inProgressLeadAssignments"
  );
  filterByRange: string = "Today";
  doughnut2DChart: Array<DataSet> = ListWrapper.widget(this, "doughnut2DChart");
  leadsCount: string = "";
  filterByStatus: string = "All";
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SalesDashboardViewProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(["request", "request.user"], this.computeQuery);

    this.computeQuery();

    this.on(["query", "query.items"], this.computeLeadAssignments);

    this.computeLeadAssignments();

    this.on(
      [
        "filterByRange",
        "leadAssignments",
        "leadAssignments.lead",
        "leadAssignments.lead.createdDate",
      ],
      this.computeLeadFilteredList
    );

    this.computeLeadFilteredList();

    this.on(
      [
        "filterByStatus",
        "leadFilteredList",
        "leadFilteredList.lead",
        "leadFilteredList.lead.status",
      ],
      this.computeFilterLeadsList
    );

    this.computeFilterLeadsList();

    this.on(
      [
        "leadAssignments",
        "leadAssignments.lead",
        "leadAssignments.lead.status",
      ],
      this.computeInProgressLeadAssignments
    );

    this.computeInProgressLeadAssignments();

    this.on(
      [
        "leadAssignments",
        "leadAssignments.lead",
        "leadAssignments.lead.status",
      ],
      this.computeDoughnut2DChart
    );

    this.computeDoughnut2DChart();

    this.on(
      ["doughnut2DChart", "doughnut2DChart.data", "doughnut2DChart.data.value"],
      this.computeLeadsCount
    );

    this.computeLeadsCount();

    this.updateSyncProperty("data", this.props.data);

    this.on(
      [
        "doughnut2DChart",
        "filterByRange",
        "filterByStatus",
        "filterLeadsList",
        "filterLeadsList.assignedBy",
        "filterLeadsList.assignedBy.firstName",
        "filterLeadsList.assignedBy.lastName",
        "filterLeadsList.lead",
        "filterLeadsList.lead.company",
        "filterLeadsList.lead.name",
        "filterLeadsList.lead.phone",
        "filterLeadsList.lead.status",
        "inProgressLeadAssignments",
        "inProgressLeadAssignments.assignedBy",
        "inProgressLeadAssignments.assignedBy.firstName",
        "inProgressLeadAssignments.assignedBy.lastName",
        "inProgressLeadAssignments.lead",
        "inProgressLeadAssignments.lead.name",
        "inProgressLeadAssignments.lead.status",
        "leadAssignments",
        "leadsCount",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: SalesDashboardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public setRequest(val: LeadAssignedUsersRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setQuery(val: LeadAssignedUsers): void {
    let isValChanged: boolean = this.query !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("query", this.query, val);

    this.query = val;

    this.fire("query", this);
  }
  public computeQuery = async (): Promise<void> => {
    try {
      this.setQuery(
        await Query.get().getLeadAssignedUsers(
          UsageConstants.QUERY_GETLEADASSIGNEDUSERS_SALESDASHBOARDVIEW_PROPERTIES_QUERY_COMPUTATION,
          this.request
        )
      );
    } catch (exception) {
      console.log(" exception in computeQuery : " + exception.toString());

      this.setQuery(null);
    }
  };
  public setLeadAssignments(val: Array<LeadAssignment>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.leadAssignments,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("leadAssignments", this.leadAssignments, val);

    this.leadAssignments.clear();

    this.leadAssignments.addAll(val);

    this.fire("leadAssignments", this);
  }
  public addToLeadAssignments(val: LeadAssignment, index: number = -1): void {
    if (index === -1) {
      if (!this.leadAssignments.contains(val)) this.leadAssignments.add(val);
    } else {
      this.leadAssignments.remove(this.leadAssignments.elementAt(index));

      this.leadAssignments.add(val);
    }

    this.fire("leadAssignments", this, val, true);

    this.updateObservable("leadAssignments", null, val);
  }
  public removeFromLeadAssignments(val: LeadAssignment): void {
    this.leadAssignments.remove(val);

    this.fire("leadAssignments", this, val, false);

    this.removeObservable("leadAssignments", val);
  }
  public computeLeadAssignments = (): void => {
    try {
      this.setLeadAssignments(
        Array.from(this.query.items.isNotEmpty ? this.query.items : [])
      );
    } catch (exception) {
      console.log(
        " exception in computeLeadAssignments : " + exception.toString()
      );

      this.setLeadAssignments([]);
    }
  };
  public setLeadFilteredList(val: Array<LeadAssignment>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.leadFilteredList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("leadFilteredList", this.leadFilteredList, val);

    this.leadFilteredList.clear();

    this.leadFilteredList.addAll(val);

    this.fire("leadFilteredList", this);
  }
  public addToLeadFilteredList(val: LeadAssignment, index: number = -1): void {
    if (index === -1) {
      if (!this.leadFilteredList.contains(val)) this.leadFilteredList.add(val);
    } else {
      this.leadFilteredList.remove(this.leadFilteredList.elementAt(index));

      this.leadFilteredList.add(val);
    }

    this.fire("leadFilteredList", this, val, true);

    this.updateObservable("leadFilteredList", null, val);
  }
  public removeFromLeadFilteredList(val: LeadAssignment): void {
    this.leadFilteredList.remove(val);

    this.fire("leadFilteredList", this, val, false);

    this.removeObservable("leadFilteredList", val);
  }
  public computeLeadFilteredList = (): void => {
    try {
      this.setLeadFilteredList(
        Array.from(
          LeadUtils.getLeadAssignedFilter(
            this.leadAssignments,
            this.filterByRange
          ).toList()
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeLeadFilteredList : " + exception.toString()
      );

      this.setLeadFilteredList([]);
    }
  };
  public setFilterLeadsList(val: Array<LeadAssignment>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.filterLeadsList,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("filterLeadsList", this.filterLeadsList, val);

    this.filterLeadsList.clear();

    this.filterLeadsList.addAll(val);

    this.fire("filterLeadsList", this);
  }
  public addToFilterLeadsList(val: LeadAssignment, index: number = -1): void {
    if (index === -1) {
      if (!this.filterLeadsList.contains(val)) this.filterLeadsList.add(val);
    } else {
      this.filterLeadsList.remove(this.filterLeadsList.elementAt(index));

      this.filterLeadsList.add(val);
    }

    this.fire("filterLeadsList", this, val, true);

    this.updateObservable("filterLeadsList", null, val);
  }
  public removeFromFilterLeadsList(val: LeadAssignment): void {
    this.filterLeadsList.remove(val);

    this.fire("filterLeadsList", this, val, false);

    this.removeObservable("filterLeadsList", val);
  }
  public computeFilterLeadsList = (): void => {
    try {
      this.setFilterLeadsList(
        Array.from(
          LeadUtils.getLeadAssignedFilterByStatus(
            this.leadFilteredList,
            this.filterByStatus
          ).toList()
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeFilterLeadsList : " + exception.toString()
      );

      this.setFilterLeadsList([]);
    }
  };
  public setInProgressLeadAssignments(val: Array<LeadAssignment>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.inProgressLeadAssignments,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl(
      "inProgressLeadAssignments",
      this.inProgressLeadAssignments,
      val
    );

    this.inProgressLeadAssignments.clear();

    this.inProgressLeadAssignments.addAll(val);

    this.fire("inProgressLeadAssignments", this);
  }
  public addToInProgressLeadAssignments(
    val: LeadAssignment,
    index: number = -1
  ): void {
    if (index === -1) {
      if (!this.inProgressLeadAssignments.contains(val))
        this.inProgressLeadAssignments.add(val);
    } else {
      this.inProgressLeadAssignments.remove(
        this.inProgressLeadAssignments.elementAt(index)
      );

      this.inProgressLeadAssignments.add(val);
    }

    this.fire("inProgressLeadAssignments", this, val, true);

    this.updateObservable("inProgressLeadAssignments", null, val);
  }
  public removeFromInProgressLeadAssignments(val: LeadAssignment): void {
    this.inProgressLeadAssignments.remove(val);

    this.fire("inProgressLeadAssignments", this, val, false);

    this.removeObservable("inProgressLeadAssignments", val);
  }
  public computeInProgressLeadAssignments = (): void => {
    try {
      this.setInProgressLeadAssignments(
        Array.from(
          this.leadAssignments
            .where(
              (c) =>
                c.lead.status === LeadStatus.Inprogress ||
                c.lead.status === LeadStatus.Contacted
            )
            .toList()
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeInProgressLeadAssignments : " +
          exception.toString()
      );

      this.setInProgressLeadAssignments([]);
    }
  };
  public setFilterByRange(val: string): void {
    let isValChanged: boolean = this.filterByRange !== val;

    if (!isValChanged) {
      return;
    }

    this.filterByRange = val;

    this.fire("filterByRange", this);
  }
  public setDoughnut2DChart(val: Array<DataSet>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(
      this.doughnut2DChart,
      val
    );

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("doughnut2DChart", this.doughnut2DChart, val);

    this.doughnut2DChart.clear();

    this.doughnut2DChart.addAll(val);

    this.fire("doughnut2DChart", this);
  }
  public addToDoughnut2DChart(val: DataSet, index: number = -1): void {
    if (index === -1) {
      if (!this.doughnut2DChart.contains(val)) this.doughnut2DChart.add(val);
    } else {
      this.doughnut2DChart.remove(this.doughnut2DChart.elementAt(index));

      this.doughnut2DChart.add(val);
    }

    this.fire("doughnut2DChart", this, val, true);

    this.updateObservable("doughnut2DChart", null, val);
  }
  public removeFromDoughnut2DChart(val: DataSet): void {
    this.doughnut2DChart.remove(val);

    this.fire("doughnut2DChart", this, val, false);

    this.removeObservable("doughnut2DChart", val);
  }
  public computeDoughnut2DChart = async (): Promise<void> => {
    try {
      this.setDoughnut2DChart(
        Array.from(
          await ChartDataUtils.getStatusBasedChartData(this.leadAssignments)
        )
      );
    } catch (exception) {
      console.log(
        " exception in computeDoughnut2DChart : " + exception.toString()
      );

      this.setDoughnut2DChart([]);
    }
  };
  public setLeadsCount(val: string): void {
    let isValChanged: boolean = this.leadsCount !== val;

    if (!isValChanged) {
      return;
    }

    this.leadsCount = val;

    this.fire("leadsCount", this);
  }
  public computeLeadsCount = (): void => {
    try {
      this.setLeadsCount(ChartDataUtils.getCount(this.doughnut2DChart));
    } catch (exception) {
      console.log(" exception in computeLeadsCount : " + exception.toString());

      this.setLeadsCount("");
    }
  };
  public setFilterByStatus(val: string): void {
    let isValChanged: boolean = this.filterByStatus !== val;

    if (!isValChanged) {
      return;
    }

    this.filterByStatus = val;

    this.fire("filterByStatus", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            TextView({
              data: "Overview",
              softWrap: true,
              style: new ui.TextStyle({
                fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
              }),
              className: "x007",
              key: "0",
            }),
            ui.Row({
              children: [
                ui.Container({
                  margin: ui.EdgeInsets.fromLTRB(
                    0.0,
                    0.0,
                    10.0,
                    0.0,
                    new Map()
                  ),
                  child: LabelDropdown<string>({
                    value: this.filterByStatus,
                    items: ["All", "New", "Inprogress", "Contacted", "Closed"],
                    onChanged: (text) => {
                      this.labelDropdown1onChanged(text, this.d3eState);
                    },
                    builder: (context, item) => {
                      return TextView({ data: item });
                    },
                  }),
                  key: "0",
                  className: "x9fb",
                }),
                LabelDropdown<string>({
                  value: this.filterByRange,
                  items: [
                    "Today",
                    "Yesterday",
                    "Current Week",
                    "Current Month",
                    "Last 3 Months",
                    "Last 6 Months",
                    "This Year",
                  ],
                  onChanged: (text) => {
                    this.labelDropdownonChanged(text, this.d3eState);
                  },
                  builder: (context, item) => {
                    return TextView({ data: item });
                  },
                  key: "1",
                }),
              ],
              key: "1",
            }),
          ],
          className: "x103 hc h",
          key: "0",
        }),
        ui.Row({
          children: [
            ui.Container({
              expand: true,
              margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 10.0, 0.0, new Map()),
              child: DashboardCardWidget({
                bgcolor: cStyle.c4,
                title: "New",
                statusCount: this.filterLeadsList.where(
                  (c) => c.lead.status === LeadStatus.New
                ).length,
              }),
              key: "0",
              className: "x9a9d hc h",
            }),
            ui.Container({
              expand: true,
              padding: ui.EdgeInsets.fromLTRB(0.0, 0.0, 10.0, 0.0, new Map()),
              child: DashboardCardWidget({
                bgcolor: cStyle.c5,
                title: "InProgress",
                statusCount: this.filterLeadsList.where(
                  (c) => c.lead.status === LeadStatus.Inprogress
                ).length,
              }),
              key: "1",
              className: "x7a2 hc h",
            }),
            ui.Container({
              expand: true,
              margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 10.0, 0.0, new Map()),
              child: DashboardCardWidget({
                bgcolor: cStyle.c6,
                title: "Accepted",
                statusCount: this.filterLeadsList.where(
                  (c) => c.lead.status === LeadStatus.Contacted
                ).length,
              }),
              key: "2",
              className: "x4c8 hc h",
            }),
            ui.Container({
              expand: true,
              margin: ui.EdgeInsets.zero,
              child: DashboardCardWidget({
                bgcolor: cStyle.c7,
                title: "Completed",
                statusCount: this.filterLeadsList.where(
                  (c) => c.lead.status === LeadStatus.Closed
                ).length,
              }),
              key: "3",
              className: "x8c1 hc h",
            }),
          ],
          className: "xf48a hc h",
          key: "1",
        }),
        ui.Row({
          children: [
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                TextView({
                  data: "Status Overview",
                  states: ui.joinStates(
                    { "data-visibility": this.leadAssignments.isNotEmpty },
                    {}
                  ),
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                  }),
                  className: "x8f8 hc",
                  key: "0",
                }),
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.center,
                  children: [
                    ui.Container({
                      alignment: ui.Alignment.center,
                      child: this.leadAssignments.isNotEmpty
                        ? ui.Container({
                            height: 255,
                            width: 500,
                            child: Doughnut2DChart({
                              data: this.doughnut2DChart,
                              key: this.leadsCount,
                            }),
                            className: "x1c6 hc vc",
                          })
                        : TextView({
                            data: "No Data Available",
                            style: new ui.TextStyle({
                              fontWeight: ui.FontWeight.bold,
                            }),
                            className: "xecf vc",
                          }),
                      className: "xa88 vc",
                      key: "0",
                    }),
                  ],
                  className: "x767 hc h",
                  key: "1",
                }),
              ],
              className: "x77c hc vc h v",
              key: "0",
            }),
            ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    TextView({
                      data: "On Going Tasks",
                      style: new ui.TextStyle({
                        fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                        fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                      }),
                      className: "x26d",
                      key: "0",
                    }),
                  ],
                  className: "x181 hc h",
                  key: "0",
                }),
                ScrollView2({
                  child: ui.Column({
                    children: [
                      ui.Table({
                        defaultColumnWidth: new ui.FlexColumnWidth(1),
                        columnWidths: {
                          0: new ui.FlexColumnWidth(1),
                          1: new ui.FlexColumnWidth(1),
                          2: new ui.FlexColumnWidth(1),
                        },
                        children: [
                          ui.TableRow({
                            children: [
                              ui.TableCell({
                                child: TextView({
                                  data: "Name",
                                  className: "hc",
                                }),
                                className: "x53c hc",
                                key: "0",
                              }),
                              ui.TableCell({
                                child: TextView({
                                  data: "Assigned By",
                                  className: "hc",
                                }),
                                className: "xcb3 hc",
                                key: "1",
                              }),
                              ui.TableCell({
                                child: TextView({
                                  data: "Status",
                                  className: "hc",
                                }),
                                className: "xa483 hc",
                                key: "2",
                              }),
                            ],
                            className: "x81ca hc",
                            key: "0",
                          }),
                          this.inProgressLeadAssignments.expand((forItem) => [
                            ui.TableRow({
                              children: [
                                ui.TableCell({
                                  child: TextView({
                                    data: forItem.lead.name,
                                    className: "hc",
                                  }),
                                  className: "hc",
                                  key: "0",
                                }),
                                ui.TableCell({
                                  child: TextView({
                                    data:
                                      forItem.assignedBy.firstName +
                                      " " +
                                      forItem.assignedBy.lastName,
                                    className: "hc",
                                  }),
                                  className: "hc",
                                  key: "1",
                                }),
                                ui.TableCell({
                                  verticalAlignment:
                                    ui.TableCellVerticalAlignment.middle,
                                  child: ui.Row({
                                    mainAxisAlignment:
                                      ui.MainAxisAlignment.start,
                                    children: [
                                      ui.Container({
                                        alignment: ui.Alignment.center,
                                        states: ui.joinStates(
                                          {
                                            "data-c0":
                                              forItem.lead.status.name ===
                                              "New",
                                            "data-c1":
                                              forItem.lead.status.name ===
                                              "Inprogress",
                                            "data-c2":
                                              forItem.lead.status.name ===
                                              "Closed",
                                            "data-c3":
                                              forItem.lead.status.name ===
                                              "Contacted",
                                          },
                                          {}
                                        ),
                                        padding:
                                          forItem.lead.status.name === "New"
                                            ? ui.EdgeInsets.symmetric({
                                                horizontal: 20.0,
                                                vertical: 5.0,
                                                transitions: new Map(),
                                              })
                                            : forItem.lead.status.name ===
                                              "Inprogress"
                                            ? ui.EdgeInsets.symmetric({
                                                horizontal: 20.0,
                                                vertical: 5.0,
                                                transitions: new Map(),
                                              })
                                            : forItem.lead.status.name ===
                                              "Closed"
                                            ? ui.EdgeInsets.symmetric({
                                                horizontal: 20.0,
                                                vertical: 5.0,
                                                transitions: new Map(),
                                              })
                                            : forItem.lead.status.name ===
                                              "Contacted"
                                            ? ui.EdgeInsets.symmetric({
                                                horizontal: 20.0,
                                                vertical: 5.0,
                                                transitions: new Map(),
                                              })
                                            : null,
                                        decoration:
                                          forItem.lead.status.name === "New"
                                            ? new ui.BoxDecoration({
                                                color: cStyle.c4,
                                                borderRadius:
                                                  ui.BorderRadius.circular(3.0),
                                              })
                                            : forItem.lead.status.name ===
                                              "Inprogress"
                                            ? new ui.BoxDecoration({
                                                color: cStyle.c5,
                                                borderRadius:
                                                  ui.BorderRadius.circular(3.0),
                                              })
                                            : forItem.lead.status.name ===
                                              "Closed"
                                            ? new ui.BoxDecoration({
                                                color: cStyle.c7,
                                                borderRadius:
                                                  ui.BorderRadius.circular(3.0),
                                              })
                                            : forItem.lead.status.name ===
                                              "Contacted"
                                            ? new ui.BoxDecoration({
                                                color: cStyle.c6,
                                                borderRadius:
                                                  ui.BorderRadius.circular(3.0),
                                              })
                                            : new ui.BoxDecoration({}),
                                        child: TextView({
                                          data: forItem.lead.status.name,
                                          style: new ui.TextStyle({
                                            color: cStyle.c14,
                                          }),
                                          className: "xfeb",
                                        }),
                                        className: "xaffd",
                                        key: "0",
                                      }),
                                      ui.Container({ key: "1" }),
                                    ],
                                    className: "x2717 hc h",
                                  }),
                                  className: "xcbb hc h",
                                  key: "2",
                                }),
                              ],
                              onTap: (e) => {
                                e.stopPropagation();

                                this.onInProgressButtonHandler(
                                  forItem,
                                  this.d3eState
                                );
                              },
                              className: "x10b hc h",
                              key: forItem?.ident,
                            }),
                          ]),
                        ],
                        className: "xae6 hc h",
                        key: "0",
                      }),
                    ],
                    key: "1",
                  }),
                  scrollDirection: ui.Axis.vertical,
                  className: "xc5b hc h",
                  controller: this.d3eState.column3ScrollController,
                }),
              ],
              className: "x035 hc vc h v",
              key: "1",
            }),
          ],
          className: "xa5a hc vc h v",
          key: "2",
        }),
        ui.Column({
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            TextView({
              data: "Assigned Leads",
              softWrap: true,
              style: new ui.TextStyle({
                fontSize: cStyle.tTextViewHeadlineThreeFontSizeOn,
                fontWeight: cStyle.tTextViewHeadlineThreeFontWeightOn,
              }),
              className: "xb08 hc",
              key: "0",
            }),
            ui.Table({
              defaultColumnWidth: new ui.FlexColumnWidth(1),
              columnWidths: {
                0: new ui.FlexColumnWidth(1),
                1: new ui.FlexColumnWidth(1),
                2: new ui.FlexColumnWidth(1),
                3: new ui.FlexColumnWidth(1),
                4: new ui.FlexColumnWidth(1),
              },
              children: [
                ui.TableRow({
                  children: [
                    ui.TableCell({
                      child: TextView({ data: "Name", className: "hc" }),
                      className: "xa7ef hc",
                      key: "0",
                    }),
                    ui.TableCell({
                      child: TextView({ data: "Assigned By", className: "hc" }),
                      className: "x03b hc",
                      key: "1",
                    }),
                    ui.TableCell({
                      child: TextView({ data: "Company", className: "hc" }),
                      className: "x61f hc",
                      key: "2",
                    }),
                    ui.TableCell({
                      child: TextView({
                        data: "Phone Number",
                        className: "hc",
                      }),
                      className: "x8be hc",
                      key: "3",
                    }),
                    ui.TableCell({
                      child: TextView({ data: "Status", className: "hc" }),
                      className: "x53b hc",
                      key: "4",
                    }),
                  ],
                  className: "x358 hc",
                  key: "0",
                }),
                this.filterLeadsList.expand((item) => [
                  ui.TableRow({
                    children: [
                      ui.TableCell({
                        child: TextView({
                          data: item.lead.name,
                          className: "hc",
                        }),
                        className: "hc",
                        key: "0",
                      }),
                      ui.TableCell({
                        child: TextView({
                          data:
                            item.assignedBy.firstName +
                            " " +
                            item.assignedBy.lastName,
                          className: "hc",
                        }),
                        className: "hc",
                        key: "1",
                      }),
                      ui.TableCell({
                        child: TextView({
                          data: item.lead.company,
                          className: "hc",
                        }),
                        className: "hc",
                        key: "2",
                      }),
                      ui.TableCell({
                        child: TextView({
                          data: item.lead.phone,
                          className: "hc",
                        }),
                        className: "hc",
                        key: "3",
                      }),
                      ui.TableCell({
                        verticalAlignment: ui.TableCellVerticalAlignment.middle,
                        child: ui.Row({
                          mainAxisAlignment: ui.MainAxisAlignment.start,
                          children: [
                            Button2WithState({
                              d3eState: this.d3eState.forItem(item),
                              item: item,
                              key: "0",
                            }),
                            ui.Container({ key: "1" }),
                          ],
                          className: "x306 hc h",
                        }),
                        className: "x682 hc h",
                        key: "4",
                      }),
                    ],
                    onTap: (e) => {
                      e.stopPropagation();

                      this.onTableRowGestureHandler(
                        this.d3eState.forItem(item)
                      );
                    },
                    className: "x1c8 hc h",
                    key: item?.ident,
                  }),
                ]),
              ],
              className: "x0db hc h",
              key: "1",
            }),
          ],
          className: "xe4bf hc h",
          key: "3",
        }),
      ],
      className: ui.join(
        this.props.className,
        "SalesDashboardView x198 hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(new LeadAssignedUsersRequest({ user: this.user }));
  };
  public onTableRowGestureHandler = (d3eState: _ItemState): void => {
    this.navigator.pushLeadOverviewPage({
      lead: d3eState.item.lead,
      user: this.user,
      client: this.client,
      data: this.data,
      replace: false,
      target: "main",
    });
  };
  public onInProgressButtonHandler = (
    forItem: LeadAssignment,
    d3eState: SalesDashboardViewRefs
  ): void => {
    this.navigator.pushLeadOverviewPage({
      lead: forItem.lead,
      user: this.user,
      client: this.client,
      data: this.data,
      replace: false,
      target: "main",
    });
  };
  public labelDropdown1onChanged = (
    val: string,
    d3eState: SalesDashboardViewRefs
  ): void => {
    this.setFilterByStatus(val);
  };
  public labelDropdownonChanged = (
    val: string,
    d3eState: SalesDashboardViewRefs
  ): void => {
    this.setFilterByRange(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function SalesDashboardView(props: SalesDashboardViewProps) {
  return React.createElement(_SalesDashboardViewState, {
    ..._SalesDashboardViewState.defaultProps,
    ...props,
  });
}
