export default class MaritalStatus {
  public static readonly Single = new MaritalStatus("Single", "Single", 0);
  public static readonly Married = new MaritalStatus("Married", "Married", 1);
  public static readonly Divorced = new MaritalStatus(
    "Divorced",
    "Divorced",
    2
  );
  public static readonly Widowed = new MaritalStatus("Widowed", "Widowed", 3);
  public static readonly PreferNotToSay = new MaritalStatus(
    "PreferNotToSay",
    "PreferNotToSay",
    4
  );
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): MaritalStatus[] {
    return [
      MaritalStatus.Single,
      MaritalStatus.Married,
      MaritalStatus.Divorced,
      MaritalStatus.Widowed,
      MaritalStatus.PreferNotToSay,
    ];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
