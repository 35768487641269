import LeadAssignment from "../models/LeadAssignment";
import LeadStatus from "./LeadStatus";
import Lead from "../models/Lead";
import Duration from "../core/Duration";
import DateTime from "../core/DateTime";
import DateFormat from "./DateFormat";

export default class LeadUtils {
  public static colorPalette: Array<string> = [
    "FFFF5733",
    "FF33FF57",
    "FF5733FF",
    "FFFF33A8",
    "FF33FFF5",
    "FFF5FF33",
    "FFA833FF",
    "FFFFC107",
    "FFFF5722",
    "FFFF9800",
    "FF673AB7",
    "FF3F51B5",
    "FF00BCD4",
    "FF009688",
    "FF8BC34A",
    "FFCDDC39",
    "FF795548",
    "FF9E9E9E",
    "FF607D8B",
    "FF000000",
  ];
  public constructor() {}
  public static getFilterLead(
    leads: Array<Lead>,
    filterByRange: string
  ): Array<Lead> {
    let now: DateTime = DateTime.now();

    return leads
      .where((lead) => {
        switch (filterByRange) {
          case "Today": {
            return (
              lead.createdDate.day === now.day &&
              lead.createdDate.month === now.month &&
              lead.createdDate.year === now.year
            );
          }

          case "Yesterday": {
            return (
              lead.createdDate.day ===
                now.subtract(new Duration({ days: 1 })).day &&
              lead.createdDate.month ===
                now.subtract(new Duration({ days: 1 })).month &&
              lead.createdDate.year ===
                now.subtract(new Duration({ days: 1 })).year
            );
          }

          case "Current Week": {
            return (
              lead.createdDate.isAfter(
                now.subtract(new Duration({ days: now.weekday }))
              ) &&
              lead.createdDate.isBefore(
                now.add(
                  new Duration({ days: DateTime.daysPerWeek - now.weekday })
                )
              )
            );
          }

          case "Current Month": {
            return (
              lead.createdDate.month === now.month &&
              lead.createdDate.year === now.year
            );
          }

          case "Last Month": {
            return (
              lead.createdDate.month ===
                now.subtract(new Duration({ days: now.day })).month &&
              lead.createdDate.year ===
                now.subtract(new Duration({ days: now.day })).year
            );
          }

          case "Last 3 Months": {
            return lead.createdDate.isAfter(
              now.subtract(new Duration({ days: 90 }))
            );
          }

          case "Last 6 Months": {
            return lead.createdDate.isAfter(
              now.subtract(new Duration({ days: 180 }))
            );
          }

          case "Last Year": {
            return lead.createdDate.year === now.year - 1;
          }

          case "This Year": {
            return lead.createdDate.year === now.year;
          }
          default: {
            return false;
          }
        }
      })
      .toList();
  }
  public static getLeadAssignedFilter(
    leadAssignments: Array<LeadAssignment>,
    filterByRange: string
  ): Array<LeadAssignment> {
    let now: DateTime = DateTime.now();

    return leadAssignments
      .where((leadAssignment) => {
        switch (filterByRange) {
          case "Today": {
            return (
              leadAssignment.lead.createdDate.day === now.day &&
              leadAssignment.lead.createdDate.month === now.month &&
              leadAssignment.lead.createdDate.year === now.year
            );
          }

          case "Yesterday": {
            return (
              leadAssignment.lead.createdDate.day ===
                now.subtract(new Duration({ days: 1 })).day &&
              leadAssignment.lead.createdDate.month ===
                now.subtract(new Duration({ days: 1 })).month &&
              leadAssignment.lead.createdDate.year ===
                now.subtract(new Duration({ days: 1 })).year
            );
          }

          case "Current Week": {
            return (
              leadAssignment.lead.createdDate.isAfter(
                now.subtract(new Duration({ days: now.weekday }))
              ) &&
              leadAssignment.lead.createdDate.isBefore(
                now.add(
                  new Duration({ days: DateTime.daysPerWeek - now.weekday })
                )
              )
            );
          }

          case "Current Month": {
            return (
              leadAssignment.lead.createdDate.month === now.month &&
              leadAssignment.lead.createdDate.year === now.year
            );
          }

          case "Last Month": {
            return (
              leadAssignment.lead.createdDate.month ===
                now.subtract(new Duration({ days: now.day })).month &&
              leadAssignment.lead.createdDate.year ===
                now.subtract(new Duration({ days: now.day })).year
            );
          }

          case "Last 3 Months": {
            return leadAssignment.lead.createdDate.isAfter(
              now.subtract(new Duration({ days: 90 }))
            );
          }

          case "Last 6 Months": {
            return leadAssignment.lead.createdDate.isAfter(
              now.subtract(new Duration({ days: 180 }))
            );
          }

          case "Last Year": {
            return leadAssignment.lead.createdDate.year === now.year - 1;
          }

          case "This Year": {
            return leadAssignment.lead.createdDate.year === now.year;
          }
          default: {
            return false;
          }
        }
      })
      .toList();
  }
  public static toGMTFromDateTime(
    dateTime: DateTime,
    d3eParams?: Partial<{ format: string }>
  ): string {
    let format = d3eParams?.format;

    let dateFormat: DateFormat = new DateFormat(format);

    return dateFormat.format(dateTime).toString();
  }
  public static getLeadAssignedFilterByStatus(
    leadAssignments: Array<LeadAssignment>,
    status: string
  ): Array<LeadAssignment> {
    let leadStatus: LeadStatus = LeadStatus.values.firstWhere(
      (e) => e.toString().split(".").last === status,
      { orElse: () => null }
    );

    if (status === "All") {
      return leadAssignments;
    }

    return leadAssignments
      .where((leadAssignment) => leadAssignment.lead.status === leadStatus)
      .toList();
  }
  public static getToDateFilter(filterByRange: string): DateTime {
    let now: DateTime = DateTime.now();

    switch (filterByRange) {
      case "Today": {
        return new DateTime(now.year, now.month, now.day, 23, 59, 59);
      }

      case "Yesterday": {
        return new DateTime(
          now.subtract(new Duration({ days: 1 })).year,
          now.subtract(new Duration({ days: 1 })).month,
          now.subtract(new Duration({ days: 1 })).day,
          23,
          59,
          59
        );
      }

      case "Current Week": {
        return new DateTime(now.year, now.month, now.day, 23, 59, 59);
      }

      case "Current Month": {
        return new DateTime(now.year, now.month, now.day, 23, 59, 59);
      }

      case "Last Month": {
        return new DateTime(
          now.subtract(new Duration({ days: now.day })).year,
          now.subtract(new Duration({ days: now.day })).month,
          now.subtract(new Duration({ days: now.day })).day,
          23,
          59,
          59
        );
      }

      case "Last 3 Months": {
        return new DateTime(now.year, now.month, now.day, 23, 59, 59);
      }

      case "Last 6 Months": {
        return new DateTime(now.year, now.month, now.day, 23, 59, 59);
      }

      case "Last Year": {
        return new DateTime(now.year - 1, 12, 31, 23, 59, 59);
      }

      case "This Year": {
        return new DateTime(now.year, 12, 31, 23, 59, 59);
      }
      default: {
        return DateTime.now();
      }
    }
  }
  public static getFromDateFilter(filterByRange: string): DateTime {
    let now: DateTime = DateTime.now();

    switch (filterByRange) {
      case "Today": {
        return new DateTime(now.year, now.month, now.day, 0, 0, 0);
      }

      case "Yesterday": {
        return new DateTime(
          now.subtract(new Duration({ days: 1 })).year,
          now.subtract(new Duration({ days: 1 })).month,
          now.subtract(new Duration({ days: 1 })).day,
          0,
          0,
          0
        );
      }

      case "Current Week": {
        return new DateTime(
          now.subtract(new Duration({ days: now.weekday })).year,
          now.subtract(new Duration({ days: now.weekday })).month,
          now.subtract(new Duration({ days: now.weekday })).day,
          0,
          0,
          0
        );
      }

      case "Current Month": {
        return new DateTime(now.year, now.month, 1, 0, 0, 0);
      }

      case "Last Month": {
        return new DateTime(
          now.subtract(new Duration({ days: now.day })).year,
          now.subtract(new Duration({ days: now.day })).month,
          1,
          0,
          0,
          0
        );
      }

      case "Last 3 Months": {
        return new DateTime(
          now.subtract(new Duration({ days: 90 })).year,
          now.subtract(new Duration({ days: 90 })).month,
          now.subtract(new Duration({ days: 90 })).day,
          0,
          0,
          0
        );
      }

      case "Last 6 Months": {
        return new DateTime(
          now.subtract(new Duration({ days: 180 })).year,
          now.subtract(new Duration({ days: 180 })).month,
          now.subtract(new Duration({ days: 180 })).day,
          0,
          0,
          0
        );
      }

      case "Last Year": {
        return new DateTime(now.year - 1, 1, 1, 0, 0, 0);
      }

      case "This Year": {
        return new DateTime(now.year, 1, 1, 0, 0, 0);
      }
      default: {
        return DateTime.now();
      }
    }
  }
  public static getMobileCodeWithCountry(): Array<string> {
    return [
      "+1 United States - US",
      "+44 United Kingdom - GB",
      "+1 Canada - CA",
      "+61 Australia - AU",
      "+49 Germany - DE",
      "+91 India - IN",
      "+33 France - FR",
      "+52 Mexico - MX",
      "+55 Brazil - BR",
      "+81 Japan - JP",
      "+39 Italy - IT",
      "+34 Spain - ES",
      "+31 Netherlands - NL",
      "+41 Switzerland - CH",
      "+82 SouthKorea - KR",
      "+47 Norway - NO",
      "+46 Sweden",
      "+48 Poland - PL",
      "+351 Portugal - PT",
      "+43 Austria - AT",
    ];
  }
  public static formatDateTime(
    sentDateTime: DateTime,
    receivedDateTime: DateTime
  ): string {
    let effectiveDateTime: DateTime =
      sentDateTime !== null ? sentDateTime : receivedDateTime;

    if (effectiveDateTime === null) {
      return "";
    }

    let now: DateTime = DateTime.now();

    //  Calculate start of the week (Monday at 00:00)

    let startOfWeek: DateTime = now.subtract(
      new Duration({ days: now.weekday - 1 })
    );

    startOfWeek = new DateTime(
      startOfWeek.year,
      startOfWeek.month,
      startOfWeek.day
    );

    //  If the date is today, show the time

    if (
      effectiveDateTime.day === now.day &&
      effectiveDateTime.month === now.month &&
      effectiveDateTime.year === now.year
    ) {
      return new DateFormat("HH:mm").format(effectiveDateTime);
    }

    if (effectiveDateTime.isAfter(startOfWeek)) {
      return new DateFormat("EEE, HH:mm").format(effectiveDateTime);
    }

    return new DateFormat("EEE, dd MMM").format(effectiveDateTime);
  }
  public static getColorForFirstLetter(name: string): string {
    if (name === null || name.isEmpty) {
      return "ff000000";
    }

    //  Get the first letter of the name

    let firstLetter: string = name.substring(0, 1).toUpperCase();

    //  Calculate index based on the ASCII value of the first letter

    let index: number =
      firstLetter.codeUnitAt(0) % LeadUtils.colorPalette.length;

    return LeadUtils.colorPalette[index];
  }
}
