import React, { ReactNode } from "react";
import ObservableComponent from "./ObservableComponent";
import * as ui from "../native/index";
import BaseUIProps, { processBaseUIProps } from "../native/ui/BaseUIProps";

interface ScrollView2Props extends BaseUIProps {
  child: ReactNode;
  scrollDirection: ui.Axis;
  controller?: ui.ScrollController;
}

export class ScrollData extends ui.ChangeNotifier {
  dx: number = 0;
  dy: number = 0;
  private _parent: ScrollData | null = null;

  set parent(p: ScrollData | null) {
    if (this._parent !== p) {
      if (this._parent) {
        this._parent.removeListener(this.onNotify);
      }
      this._parent = p;
      if (this._parent) {
        this._parent.addListener(this.onNotify);
      }
    }
  }

  get parent(): ScrollData | null {
    return this._parent;
  }

  onNotify = () => {
    console.log("Scrolling");
    this.notifyListeners();
  };

  update(x: number, y: number) {
    this.dx = x ?? 0;
    this.dy = y ?? 0;
    this.notifyListeners();
  }

  dispose() {
    if (this._parent) {
      this._parent.removeListener(this.onNotify);
      this._parent = null;
    }
    super.dispose();
  }
}

class _ScrollView2 extends ObservableComponent<ScrollView2Props> {
  data: ScrollData = new ScrollData();
  kIsWeb = true;
  scrollRef: React.RefObject<HTMLElement> = React.createRef();
  controller: ui.ScrollController;

  constructor(props: ScrollView2Props) {
    super(props);
    this.controller = props.controller ?? new ui.ScrollController();
    this.data.addListener(this.handleScroll);
  }

  componentDidMount() {
    console.log("componentDidMount called");

    // Delay to ensure DOM is fully updated
    setTimeout(() => {
      const scrollableElement = this.scrollRef.current;
      console.log("Scrollable element:", scrollableElement);
      if (scrollableElement && this.props.controller) {
        console.log("Scrollable element and controller found");
        this.props.controller.setScrollableElement(scrollableElement);
        // Set the initial scroll position (if needed)
        scrollableElement.scrollTop = this.props.controller.initialScrollOffset;
        // Add the scroll event listener
        scrollableElement.addEventListener("scroll", this.handleScroll);
      } else {
        console.log("Either scrollable element or controller is missing.");
      }
    }, 0);
  }

  componentWillUnmount() {
    console.log("componentWillUnmount called");
    const scrollableElement = this.scrollRef.current;
    if (scrollableElement) {
      scrollableElement.removeEventListener("scroll", this.handleScroll);
    }

    if (this.data) {
      this.data.dispose();
    }

    if (this.props.controller) {
      this.props.controller.dispose();
    }
  }

  handleScroll = () => {
    console.log("Scrolling");
    const scrollableElement = this.scrollRef.current;
    if (scrollableElement && this.props.controller) {
      const offset = scrollableElement.scrollTop;
      this.props.controller.setScrollPosition(offset);
    }
  };

  render(): ReactNode {
    const classNames = [];
    const element = React.createElement(
      "ui-scroll-view",
      {
        class:
          classNames.join(" ") +
          (this.props.className ? " " + this.props.className : ""),
        ...processBaseUIProps(this.props),
        onScroll: (event: Event) => {
          const target = event.target as HTMLElement;
          this.controller.setScrollableElement(target);
        },
        ref: (e: HTMLElement) => {
          this.controller.setScrollableElement(e);
        },
      },
      this.props.child
    );
    return element;
  }
}
export default function ScrollView2(props: ScrollView2Props): ReactNode {
  return React.createElement(_ScrollView2, props);
}
