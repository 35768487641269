import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class Company extends DBObject {
  private static readonly _ENABLEMESSAGING: number = 0;
  private static readonly _ISLEADSERVICE: number = 1;
  private static readonly _MESSAGESERVICEID: number = 2;
  public id: number = 0;
  public otherMaster: DBObject;
  private _isLeadService: boolean = false;
  private _enableMessaging: boolean = false;
  private _messageServiceId: string = "";
  public constructor(
    d3eParams?: Partial<{
      enableMessaging: boolean;
      isLeadService: boolean;
      messageServiceId: string;
    }>
  ) {
    super();

    this.setEnableMessaging(d3eParams?.enableMessaging ?? false);

    this.setIsLeadService(d3eParams?.isLeadService ?? false);

    this.setMessageServiceId(d3eParams?.messageServiceId ?? "");
  }
  public get d3eType(): string {
    return "Company";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get isLeadService(): boolean {
    return this._isLeadService;
  }
  public setIsLeadService(val: boolean): void {
    let isValChanged: boolean = this._isLeadService !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Company._ISLEADSERVICE, this._isLeadService);

    this._isLeadService = val;

    this.fire("isLeadService", this);
  }
  public get enableMessaging(): boolean {
    return this._enableMessaging;
  }
  public setEnableMessaging(val: boolean): void {
    let isValChanged: boolean = this._enableMessaging !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Company._ENABLEMESSAGING, this._enableMessaging);

    this._enableMessaging = val;

    this.fire("enableMessaging", this);
  }
  public get messageServiceId(): string {
    return this._messageServiceId;
  }
  public setMessageServiceId(val: string): void {
    let isValChanged: boolean = this._messageServiceId !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Company._MESSAGESERVICEID, this._messageServiceId);

    this._messageServiceId = val;

    this.fire("messageServiceId", this);
  }
  public get(field: number): any {
    switch (field) {
      case Company._ISLEADSERVICE: {
        return this._isLeadService;
      }

      case Company._ENABLEMESSAGING: {
        return this._enableMessaging;
      }

      case Company._MESSAGESERVICEID: {
        return this._messageServiceId;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Company {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: Company = dbObj as Company;

    obj.id = this.id;

    obj.setIsLeadService(this._isLeadService);

    obj.setEnableMessaging(this._enableMessaging);

    obj.setMessageServiceId(this._messageServiceId);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Company._ISLEADSERVICE: {
        this.setIsLeadService(value as boolean);
        break;
      }

      case Company._ENABLEMESSAGING: {
        this.setEnableMessaging(value as boolean);
        break;
      }

      case Company._MESSAGESERVICEID: {
        this.setMessageServiceId(value as string);
        break;
      }
    }
  }
}
