import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ToggleView from "./ToggleView";
import TextView from "./TextView";

type _TextWithToggleOnChanged = (val: boolean) => void;

export interface TextWithToggleProps extends BaseUIProps {
  key?: string;
  value?: boolean;
  name: string;
  onChanged?: _TextWithToggleOnChanged;
}

class _TextWithToggleState extends ObservableComponent<TextWithToggleProps> {
  static defaultProps = { value: false, name: "", onChanged: null };
  public constructor(props: TextWithToggleProps) {
    super(props);

    this.initState();
  }
  public get value(): boolean {
    return this.props.value;
  }
  public get name(): string {
    return this.props.name;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["name", "value"], this.rebuild);
  }
  public componentDidUpdate(prevProps: TextWithToggleProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }
  }
  public render(): ReactNode {
    return ui.Row({
      children: [
        TextView({
          data: this.name,
          style: new ui.TextStyle({ fontSize: 15 }),
          className: "x93a",
          key: "0",
        }),
        ui.Container({ className: "xd627 hc", key: "1" }),
        ToggleView({
          value: this.value,
          onChanged: (value) => {
            this.onChangeValue(value);
          },
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "TextWithToggle x3a7"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onChangeValue = (value: boolean): void => {
    if (this.onChanged !== null) {
      this.onChanged(!this.value);
    }
  };
  public get onChanged(): _TextWithToggleOnChanged {
    return this.props.onChanged;
  }
}
export default function TextWithToggle(props: TextWithToggleProps) {
  return React.createElement(_TextWithToggleState, {
    ..._TextWithToggleState.defaultProps,
    ...props,
  });
}
