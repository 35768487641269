import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MessageStatus from "../classes/MessageStatus";
import InteractionType from "../classes/InteractionType";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import VideoPlayWidget from "./VideoPlayWidget";
import Interaction from "../models/Interaction";
import ProfileWidget from "./ProfileWidget";
import IconView from "./IconView";
import DateFormat from "../classes/DateFormat";
import { BuildContext } from "../classes/BuildContext";

export interface ConversationNoteWidgetProps extends BaseUIProps {
  key?: string;
  interaction: Interaction;
}

class _ConversationNoteWidgetState extends ObservableComponent<ConversationNoteWidgetProps> {
  static defaultProps = { interaction: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ConversationNoteWidgetProps) {
    super(props);

    this.initState();
  }
  public get interaction(): Interaction {
    return this.props.interaction;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("interaction", this.props.interaction);

    this.on(
      [
        "interaction",
        "interaction.body",
        "interaction.conversation",
        "interaction.createdDate",
        "interaction.file",
        "interaction.isIncoming",
        "interaction.messageStatus",
        "interaction.type",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ConversationNoteWidgetProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.interaction !== this.props.interaction) {
      this.updateObservable(
        "interaction",
        prevProps.interaction,
        this.props.interaction
      );

      this.fire("interaction", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: this.interaction.isIncoming
        ? ui.CrossAxisAlignment.start
        : ui.CrossAxisAlignment.end,
      children: [
        ui.Wrap({
          crossAxisAlignment: this.interaction.isIncoming
            ? ui.WrapCrossAlignment.start
            : ui.WrapCrossAlignment.end,
          children: [
            ui.Column({
              crossAxisAlignment: this.interaction.isIncoming
                ? ui.CrossAxisAlignment.start
                : ui.CrossAxisAlignment.end,
              children: [
                ui.Column({
                  crossAxisAlignment: this.interaction.isIncoming
                    ? ui.CrossAxisAlignment.start
                    : ui.CrossAxisAlignment.end,
                  children: [
                    this.interaction !== null &&
                    this.interaction.file !== null &&
                    this.interaction.type === InteractionType.Call
                      ? ui.Row({
                          mainAxisAlignment: !this.interaction.isIncoming
                            ? ui.MainAxisAlignment.end
                            : ui.MainAxisAlignment.start,
                          children: [
                            this.interaction !== null &&
                            this.interaction.file !== null &&
                            this.interaction.type === InteractionType.Call
                              ? ui.Column({
                                  crossAxisAlignment: !this.interaction
                                    .isIncoming
                                    ? ui.CrossAxisAlignment.end
                                    : ui.CrossAxisAlignment.start,
                                  children: [
                                    TextView({
                                      data: this.interaction.conversation,
                                      softWrap: true,
                                      style: new ui.TextStyle({
                                        fontWeight: ui.FontWeight.w500,
                                      }),
                                      className: "x536",
                                      key: "0",
                                    }),
                                  ],
                                  className: "xd5",
                                })
                              : [],
                            ui.Container({
                              width: 300,
                              child: VideoPlayWidget({
                                interaction: this.interaction,
                              }),
                              key: "1",
                              className: "xf2 hc",
                            }),
                          ],
                          className: "x938 hc h",
                        })
                      : this.interaction !== null &&
                        this.interaction.type === InteractionType.Call &&
                        this.interaction.file === null
                      ? TextView({
                          data: "Call Hung Up",
                          style: new ui.TextStyle({
                            fontWeight: ui.FontWeight.w500,
                          }),
                          className: "x7da hc",
                        })
                      : this.interaction !== null &&
                        this.interaction.type === InteractionType.SMS &&
                        this.interaction.isIncoming
                      ? ui.Row({
                          mainAxisAlignment: !this.interaction.isIncoming
                            ? ui.MainAxisAlignment.end
                            : ui.MainAxisAlignment.start,
                          children: [
                            ui.Column({
                              crossAxisAlignment: ui.CrossAxisAlignment.start,
                              children: [
                                ui.Container({
                                  child: TextView({
                                    data: this.interaction.body,
                                  }),
                                  className: "x19d",
                                  key: "0",
                                }),
                                TextView({
                                  data:
                                    this.interaction !== null &&
                                    this.interaction.createdDate !== null
                                      ? new DateFormat(
                                          "EEE, MMM y HH:mm"
                                        ).format(this.interaction.createdDate)
                                      : "",
                                  style: new ui.TextStyle({
                                    fontWeight: ui.FontWeight.w400,
                                    fontSize: 9,
                                    color: cStyle.c19,
                                  }),
                                  className: "x99b",
                                  key: "1",
                                }),
                              ],
                              key: "0",
                            }),
                          ],
                          className: "xb2d hc h",
                        })
                      : ui.Row({
                          mainAxisAlignment: this.interaction.isIncoming
                            ? ui.MainAxisAlignment.start
                            : ui.MainAxisAlignment.end,
                          children: [
                            ui.Column({
                              crossAxisAlignment: this.interaction.isIncoming
                                ? ui.CrossAxisAlignment.start
                                : ui.CrossAxisAlignment.end,
                              children: [
                                ui.Container({
                                  child: TextView({
                                    data: this.interaction.body,
                                  }),
                                  className: "x19df",
                                  key: "0",
                                }),
                                ui.Row({
                                  mainAxisAlignment: ui.MainAxisAlignment.end,
                                  children: [
                                    TextView({
                                      data:
                                        this.interaction !== null &&
                                        this.interaction.createdDate !== null
                                          ? new DateFormat(
                                              "EEE, MMM y HH:mm"
                                            ).format(
                                              this.interaction.createdDate
                                            )
                                          : "",
                                      style: new ui.TextStyle({
                                        fontWeight: ui.FontWeight.w400,
                                        color: cStyle.c19,
                                        fontSize: 9,
                                      }),
                                      className: "x5ab",
                                      key: "0",
                                    }),
                                    IconView({
                                      icon:
                                        this.interaction.messageStatus ===
                                        MessageStatus.Sent
                                          ? MaterialIcons.done
                                          : this.interaction.messageStatus ===
                                            MessageStatus.Failed
                                          ? MaterialIcons.error_outline
                                          : MaterialIcons.done_all,
                                      size: 17,
                                      color:
                                        this.interaction.messageStatus ===
                                        MessageStatus.Sent
                                          ? new ui.Color(0xffbfbfbf)
                                          : this.interaction.messageStatus ===
                                            MessageStatus.Delivered
                                          ? new ui.Color(0xff1bd51f)
                                          : this.interaction.messageStatus ===
                                            MessageStatus.Failed
                                          ? cStyle.c10
                                          : this.interaction.messageStatus ===
                                            MessageStatus.Unknown
                                          ? cStyle.c10
                                          : cStyle.c1,
                                      states: ui.joinStates(
                                        {
                                          "data-c0":
                                            this.interaction.messageStatus ===
                                            MessageStatus.Sent,
                                          "data-c1":
                                            this.interaction.messageStatus ===
                                            MessageStatus.Delivered,
                                          "data-c2":
                                            this.interaction.messageStatus ===
                                            MessageStatus.Failed,
                                          "data-c3":
                                            this.interaction.messageStatus ===
                                            MessageStatus.Unknown,
                                        },
                                        {}
                                      ),
                                      className: "x25b",
                                      key: "1",
                                    }),
                                  ],
                                  className: "x1a6",
                                  key: "1",
                                }),
                              ],
                              key: "0",
                            }),
                          ],
                          className: "xb2d3 hc h",
                        }),
                  ],
                  className: "x206 hc h",
                  key: "0",
                }),
                ui.Row({
                  mainAxisAlignment: this.interaction.isIncoming
                    ? ui.MainAxisAlignment.start
                    : ui.MainAxisAlignment.end,
                  children: [
                    ui.Container({
                      margin: ui.EdgeInsets.fromLTRB(
                        0.0,
                        0.0,
                        0.0,
                        10.0,
                        new Map()
                      ),
                      child: ProfileWidget({
                        interaction: this.interaction,
                        states: ui.joinStates(
                          {
                            "data-visibility":
                              this.interaction.type === InteractionType.Call,
                          },
                          {}
                        ),
                      }),
                      key: "0",
                      className: "xf08",
                      states: ui.joinStates(
                        {
                          "data-visibility":
                            this.interaction.type === InteractionType.Call,
                        },
                        {}
                      ),
                    }),
                  ],
                  className: "x4b hc h",
                  key: "1",
                }),
              ],
              className: "xf41 hc h",
              key: "0",
            }),
          ],
          className: "x996 hc h",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "ConversationNoteWidget x397 hc h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function ConversationNoteWidget(
  props: ConversationNoteWidgetProps
) {
  return React.createElement(_ConversationNoteWidgetState, {
    ..._ConversationNoteWidgetState.defaultProps,
    ...props,
  });
}
