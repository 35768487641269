import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallData from "../classes/CallData";
import ResultStatus from "../classes/ResultStatus";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import CallInteraction from "../classes/CallInteraction";
import IconButton from "./IconButton";
import LeadUtils from "../classes/LeadUtils";
import Result from "../classes/Result";
import MessageDispatch from "../rocket/MessageDispatch";
import CallStatus from "../classes/CallStatus";
import Button from "./Button";
import InteractionType from "../classes/InteractionType";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import D3EDisposable from "../rocket/D3EDisposable";
import LeadStatus from "../classes/LeadStatus";
import EventBus from "../utils/EventBus";
import AddNewConnectionView from "./AddNewConnectionView";
import NeedsAndPainsCardView from "./NeedsAndPainsCardView";
import InteractionHistoriesView from "./InteractionHistoriesView";
import DateTime from "../core/DateTime";
import Interaction from "../models/Interaction";
import RPCServices from "../rpc/RPCServices";
import IconView from "./IconView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _CallButtonOnPressed = (d3eState: RecentInteractionViewRefs) => void;

export interface RecentInteractionViewProps extends BaseUIProps {
  key?: string;
  user: User;
  lead: Lead;
  client: TwilioClient;
  data: CallData;
}
/// To store state data for RecentInteractionView
class RecentInteractionViewRefs {
  public callButton: CallButtonState = new CallButtonState();
}

interface CallButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RecentInteractionViewRefs;
  _onCallButtonPressed?: _CallButtonOnPressed;
  data: CallData;
}

class CallButtonState extends ObjectObservable {
  public _hover: boolean = false;
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CallButtonWithState extends ObservableComponent<CallButtonWithStateProps> {
  callButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CallButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get callButton(): CallButtonState {
    return this.props.d3eState.callButton;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public get d3eState(): RecentInteractionViewRefs {
    return this.props.d3eState;
  }
  public get _onCallButtonPressed(): _CallButtonOnPressed {
    return this.props._onCallButtonPressed;
  }
  public initState() {
    super.initState();

    this.updateObservable("callButton", null, this.callButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("data", this.props.data);

    this.on(
      [
        "callButton",
        "callButton.",
        "callButton.hover",
        "data",
        "data.callStatus",
        "data.deviceReady",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: CallButtonWithStateProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public callButtonOnEnter(event): void {
    return this.callButton.setHover(true);
  }
  public callButtonOnExit(event): void {
    return this.callButton.setHover(false);
  }
  public dispose(): void {
    this.callButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      width: 70,
      child: Button({
        disable:
          this.data === null ||
          this.data.deviceReady === false ||
          this.data.callStatus === CallStatus.InProgress,
        padding: this.callButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.callButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        onPressed: () => {
          this._onCallButtonPressed(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            IconView({
              icon: MaterialIcons.call,
              size: 20,
              color:
                this.data === null ||
                this.data.deviceReady === false ||
                this.data.callStatus === CallStatus.InProgress
                  ? new ui.Color(0xff616161)
                  : new ui.Color(0xffffffff),
              states: ui.joinStates(
                {
                  "data-c0":
                    this.data === null ||
                    this.data.deviceReady === false ||
                    this.data.callStatus === CallStatus.InProgress,
                },
                {}
              ),
              className: "xc70",
              key: "0",
            }),
            TextView({
              data: "Call",
              states: ui.joinStates(
                {
                  "data-c0":
                    this.data === null ||
                    this.data.deviceReady === false ||
                    this.data.callStatus === CallStatus.InProgress,
                },
                {}
              ),
              style: new ui.TextStyle({
                color:
                  this.data === null ||
                  this.data.deviceReady === false ||
                  this.data.callStatus === CallStatus.InProgress
                    ? new ui.Color(0xff616161)
                    : new ui.Color(0xffffffff),
              }),
              className: "x29b0",
              key: "1",
            }),
          ],
        }),
        onEnter: (event) => {
          this.callButtonOnEnter(event);
        },
        onExit: (event) => {
          this.callButtonOnExit(event);
        },
      }),
      className: "xe6c hc",
    });
  }
}
function CallButtonWithState(props: CallButtonWithStateProps) {
  return React.createElement(_CallButtonWithState, props);
}

class _RecentInteractionViewState extends ObservableComponent<RecentInteractionViewProps> {
  static defaultProps = { user: null, lead: null, client: null, data: null };
  d3eState: RecentInteractionViewRefs = new RecentInteractionViewRefs();
  lastInteraction: Interaction = null;
  isExpanded: boolean = false;
  editConnectionPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public lastInteractionDisposable: D3EDisposable;
  public constructor(props: RecentInteractionViewProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.lastInteractionDisposable = MessageDispatch.get().syncObject(
      this.lastInteraction,
      UsageConstants.SUBSCRIPTION_ONINTERACTIONCHANGE_RECENTINTERACTIONVIEW_LASTINTERACTION_SYNCHRONISE
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("lead", this.props.lead);

    this.on(["lead", "lead.lastInteraction"], this.computeLastInteraction);

    this.computeLastInteraction();

    this.updateSyncProperty("data", this.props.data);

    this.on(
      [
        "data",
        "isExpanded",
        "lead",
        "lead.name",
        "lead.status",
        "user",
        "user.company",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: RecentInteractionViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public setLastInteraction(val: Interaction): void {
    let isValChanged: boolean = this.lastInteraction !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("lastInteraction", this.lastInteraction, val);

    this.lastInteractionDisposable?.dispose();

    this.lastInteraction = val;

    this.lastInteractionDisposable = MessageDispatch.get().syncObject(
      this.lastInteraction,
      UsageConstants.SUBSCRIPTION_ONINTERACTIONCHANGE_RECENTINTERACTIONVIEW_LASTINTERACTION_SYNCHRONISE
    );

    this.fire("lastInteraction", this);
  }
  public computeLastInteraction = (): void => {
    try {
      this.setLastInteraction(this.lead.lastInteraction);
    } catch (exception) {
      console.log(
        " exception in computeLastInteraction : " + exception.toString()
      );

      this.setLastInteraction(null);
    }
  };
  public setIsExpanded(val: boolean): void {
    let isValChanged: boolean = this.isExpanded !== val;

    if (!isValChanged) {
      return;
    }

    this.isExpanded = val;

    this.fire("isExpanded", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Column({
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              children: [
                ui.Row({
                  children: [
                    ui.Container({
                      child: ui.Container({
                        decoration: new ui.BoxDecoration({
                          color: ui.HexColor.fromHexStr(
                            LeadUtils.getColorForFirstLetter(
                              this.lead.name !== null
                                ? this.lead.name
                                : "Unknown"
                            )
                          ),
                          borderRadius: ui.BorderRadius.circular(20.0),
                        }),
                        alignment: ui.Alignment.center,
                        child: TextView({
                          data:
                            this.lead.name.isEmpty || this.lead.name === ""
                              ? ""
                              : this.lead.name[0].toUpperCase(),
                          textAlign: ui.TextAlign.center,
                          style: new ui.TextStyle({
                            color: cStyle.c14,
                            fontWeight: ui.FontWeight.bold,
                          }),
                          className: "xa28 hc vc",
                        }),
                        className: "x219 hc vc",
                      }),
                      onTap: (e) => {
                        e.stopPropagation();

                        this.onProfileButtonHandler(this.d3eState);
                      },
                      className: "x48d",
                      key: "0",
                    }),
                    ui.Column({
                      children: [
                        TextView({
                          data: this.lead.name,
                          style: new ui.TextStyle({
                            fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                            fontWeight:
                              cStyle.tTextViewHeadlineFourFontWeightOn,
                          }),
                          className: "xaa8",
                          key: "0",
                        }),
                        this.lead.status === LeadStatus.Closed
                          ? TextView({
                              data: "Closed",
                              style: new ui.TextStyle({ color: cStyle.c10 }),
                              className: "x063",
                            })
                          : [],
                      ],
                      key: "1",
                    }),
                  ],
                  key: "0",
                }),
                ui.Row({
                  children: [
                    ui.Container({
                      margin: ui.EdgeInsets.fromLTRB(
                        0.0,
                        0.0,
                        20.0,
                        0.0,
                        new Map()
                      ),
                      child: IconButton({
                        icon: MaterialIcons.email,
                        size: 24,
                        tooltip: "Mails",
                        color: cStyle.c1,
                        onPressed: () => {
                          this.onEmailButtonHandler(this.d3eState);
                        },
                      }),
                      key: "0",
                      className: "xa5fb",
                    }),
                    CallButtonWithState({
                      d3eState: this.d3eState,
                      _onCallButtonPressed: this.onCallButtonPressed,
                      data: this.data,
                      key: "1",
                    }),
                    IconButton({
                      icon: this.isExpanded
                        ? MaterialIcons.keyboard_arrow_right
                        : MaterialIcons.keyboard_arrow_left,
                      size: 20,
                      tooltip: "Needs & Pains",
                      onPressed: () => {
                        this.onExpandButtonPressed(this.d3eState);
                      },
                      key: "2",
                    }),
                  ],
                  key: "1",
                }),
              ],
              className: "x983 hc h",
              key: "0",
            }),
            ui.Container({ className: "xbfe hc vc", key: "1" }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              children: [
                ui.Container({
                  expand: true,
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 8.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: InteractionHistoriesView({
                    lead: this.lead,
                    handledBy: this.user,
                    data: this.data,
                    company: this.user.company,
                    computeNewMessage: (body) => {
                      this.onNewMessageButtonPressed(body, this.d3eState);
                    },
                    key: this.lead.ident,
                  }),
                  key: "0",
                  className: "x1fc hc vc h v",
                }),
                this.isExpanded
                  ? ui.Container({
                      decoration: new ui.BoxDecoration({
                        border: new ui.Border({
                          left: new ui.BorderSide({
                            width: 1.5,
                            style: ui.BorderStyle.solid,
                            color: cStyle.c16,
                          }),
                        }),
                      }),
                      width: 400,
                      child: NeedsAndPainsCardView({ lead: this.lead }),
                      className: "x04c2 hc vc v",
                    })
                  : [],
              ],
              className: "x34b hc vc h v",
              key: "2",
            }),
          ],
          className: "x7b7 hc vc h v",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "RecentInteractionView xe66 hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onEmailButtonHandler = (d3eState: RecentInteractionViewRefs): void => {
    //  String subject = 'Hi ' + lead.name;

    //  String body = 'Hi ' + lead.name + ',\n\n';

    //  Browser.sendMailTo(lead.email, subject: subject, body: body);

    this.navigator.pushLeadMailsPage({
      lead: this.lead,
      user: this.user,
      target: "main",
      replace: false,
      data: this.data,
      client: this.client,
    });
  };
  public onNewMessageButtonPressed = async (
    body: string,
    d3eState: RecentInteractionViewRefs
  ): Promise<void> => {
    let interaction: Interaction = new Interaction({
      lead: this.lead,
      fromNumber: this.user.twilioNumber,
      toNumber: this.lead.phone,
      type: InteractionType.SMS,
      body: body,
      isIncoming: false,
      handledBy: this.user,
      createdDate: DateTime.now(),
    });

    let status: boolean = await RPCServices.getCallService().sendSMS(
      interaction
    );

    if (status) {
      this.lead.interactionHistory.add(interaction);
    }
  };
  public onCallButtonPressed = async (
    d3eState: RecentInteractionViewRefs
  ): Promise<void> => {
    this.setLastInteraction(null);

    if (this.client.data.deviceReady) {
      if (this.user.twilioNumber === null || this.user.twilioNumber.isEmpty) {
        EventBus.get().fire(
          new FailureMessage({
            message:
              "You do not have a Twilio Number. Please contact your administrator.",
          })
        );

        return;
      }

      if (this.lead.phone === null || this.lead.phone.isEmpty) {
        EventBus.get().fire(
          new FailureMessage({
            message: "Lead does not have valid phone number",
          })
        );

        return;
      }

      let hasMicPermission: boolean = await this.client.checkMicPermission();

      if (!hasMicPermission) {
        EventBus.get().fire(
          new FailureMessage({
            message: "Please allow microphone permission to make calls.",
          })
        );

        return;
      }

      let connectCall: boolean = await this.client.makeCall(
        this.user.twilioNumber,
        this.lead.phone,
        {
          onStatus: (s) => {
            this.onStatusChange(s);
          },
          onFailure: (m, e) => {
            this.onConnectionFailure(m, e);
          },
        }
      );
    }
  };
  public onStatusChange = async (status: string): Promise<void> => {
    if (this.lastInteraction === null) {
      this.setLastInteraction(
        new Interaction({
          lead: this.lead,
          fromNumber: this.user.twilioNumber,
          toNumber: this.lead.phone,
          callStatus: CallStatus.Initiated,
          type: InteractionType.Call,
          sid: this.client.callSid,
          isIncoming: false,
          handledBy: this.user,
        })
      );

      EventBus.get().fire(
        new CallInteraction({
          interaction: this.lastInteraction,
          data: this.data,
          lead: this.lead,
        })
      );

      let result: Result<Interaction> = await this.lastInteraction.save();

      if (result.status === ResultStatus.Success) {
      }
    } else if (this.lastInteraction !== null) {
      let st: CallStatus = CallStatus.Initiated;

      switch (status) {
        case "ringing": {
          st = CallStatus.Ringing;
          break;
        }

        case "completed": {
          st = CallStatus.Completed;
          break;
        }

        case "accepted": {
          {
            st = CallStatus.InProgress;

            this.lastInteraction.setStartTime(DateTime.now());
          }
          break;
        }

        case "failed": {
          st = CallStatus.Failed;
          break;
        }

        case "busy": {
          st = CallStatus.Busy;
          break;
        }

        case "open": {
          st = CallStatus.InProgress;
          break;
        }

        case "connecting": {
          st = CallStatus.Connecting;
          break;
        }

        case "initiated": {
          st = CallStatus.Initiated;
          break;
        }
        default: {
        }
      }

      this.lastInteraction.setCallStatus(st);
    }
  };
  public onConnectionFailure = (message: string, error: string): void => {
    EventBus.get().fire(new FailureMessage({ message: message }));
  };
  public onProfileButtonHandler = (
    d3eState: RecentInteractionViewRefs
  ): void => {
    this.showEditConnectionPopup();
  };
  public onExpandButtonPressed = (
    d3eState: RecentInteractionViewRefs
  ): void => {
    this.setIsExpanded(!this.isExpanded);
  };
  public dispose(): void {
    this.lastInteractionDisposable?.dispose();

    this.editConnectionPopupPopup?.dispose();

    super.dispose();
  }
  public showEditConnectionPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.editConnectionPopupPopup?.dispose();

    this.editConnectionPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 550,
        child: AddNewConnectionView({ lead: this.lead, user: this.user }),
        className: "xde5 hc vc",
      }),
    });

    this.editConnectionPopupPopup.showPopup(this.context);
  }
  public hideEditConnectionPopup(): void {
    this.editConnectionPopupPopup?.dispose();
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get callButton() {
    return this.d3eState.callButton;
  }
}
export default function RecentInteractionView(
  props: RecentInteractionViewProps
) {
  return React.createElement(_RecentInteractionViewState, {
    ..._RecentInteractionViewState.defaultProps,
    ...props,
  });
}
