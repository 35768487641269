import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import Range from "../classes/Range";
import ScrollView2 from "./ScrollView2";
import TextView from "./TextView";
import HorizontalLine from "./HorizontalLine";
import { BuildContext } from "../classes/BuildContext";

export interface PopupWrapperViewProps extends BaseUIProps {
  key?: string;
  title?: string;
  content?: ReactNode;
  buttons?: Array<ReactNode>;
}

class _PopupWrapperViewState extends ObservableComponent<PopupWrapperViewProps> {
  static defaultProps = { title: "" };
  id4ScrollController: ui.ScrollController = new ui.ScrollController();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PopupWrapperViewProps) {
    super(props);

    this.initState();
  }
  public get title(): string {
    return this.props.title;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["buttonsCount", "contentPresent", "title"], this.rebuild);
  }
  public componentDidUpdate(prevProps: PopupWrapperViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.title !== this.props.title) {
      this.fire("title", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      mainAxisSize: ui.MainAxisSize.min,
      crossAxisAlignment: ui.CrossAxisAlignment.center,
      children: [
        this.title !== null && this.title.isNotEmpty
          ? ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              children: [
                TextView({
                  data: this.title,
                  style: new ui.TextStyle({
                    fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                    fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                  }),
                  className: "xcf7",
                  key: "0",
                }),
              ],
              className: "x49e hc h",
            })
          : [],
        HorizontalLine({
          margin: ui.EdgeInsets.symmetric({
            horizontal: 0.0,
            vertical: 10.0,
            transitions: new Map(),
          }),
          states: ui.joinStates(
            { "data-visibility": this.title !== null && this.title.isNotEmpty },
            {}
          ),
          className: "xa46 hc h",
          key: "1",
        }),
        this.contentPresent
          ? ScrollView2({
              child: ui.Container({ child: this.props.content }),
              scrollDirection: ui.Axis.vertical,
              className: "xb18",
              controller: this.id4ScrollController,
            })
          : [],
        this.buttonsCount > 0
          ? HorizontalLine({
              margin: ui.EdgeInsets.symmetric({
                horizontal: 0.0,
                vertical: 10.0,
                transitions: new Map(),
              }),
              className: "x16c hc h",
            })
          : ui.Container({ className: "xafa9 hc vc" }),
        this.buttonsCount > 0
          ? ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.start,
              children: [
                ui.Wrap({
                  alignment: ui.WrapAlignment.start,
                  spacing: 10,
                  crossAxisAlignment: ui.WrapCrossAlignment.center,
                  children: [
                    Range.to(this.buttonsCount).expand((index) => [
                      this.props.buttons[index],
                    ]),
                  ],
                  key: "0",
                }),
              ],
              className: "x652 hc h",
            })
          : [],
      ],
      className: ui.join(this.props.className, "PopupWrapperView xe87 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public get contentPresent(): boolean {
    return this.props.content != null;
  }
  public get content(): ReactNode {
    return this.props.content;
  }
  public get buttonsCount(): number {
    return this.props.buttons == null ? 0 : this.props.buttons.length;
  }
  public get buttons(): Array<ReactNode> {
    return this.props.buttons;
  }
}
export default function PopupWrapperView(props: PopupWrapperViewProps) {
  return React.createElement(
    _PopupWrapperViewState,
    { ..._PopupWrapperViewState.defaultProps, ...props },
    ...React.Children.toArray(props.buttons)
  );
}
