import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import ResultStatus from "../classes/ResultStatus";
import SuccessMessage from "../classes/SuccessMessage";
import LeadStatus from "../classes/LeadStatus";
import Lead from "../models/Lead";
import EventBus from "../utils/EventBus";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import FailureMessage from "../classes/FailureMessage";
import Result from "../classes/Result";
import { BuildContext } from "../classes/BuildContext";

type _RecentItemDeleteViewOnClosePressed = () => void;

type _ButtonOnPressed = (d3eState: RecentItemDeleteViewRefs) => void;

export interface RecentItemDeleteViewProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  onClosePressed?: _RecentItemDeleteViewOnClosePressed;
}
/// To store state data for RecentItemDeleteView
class RecentItemDeleteViewRefs {
  public button: ButtonState = new ButtonState();
}

interface ButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: RecentItemDeleteViewRefs;
  _onButtonPressed?: _ButtonOnPressed;
}

class ButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
}

class _ButtonWithState extends ObservableComponent<ButtonWithStateProps> {
  buttonFocusNode: ui.FocusNode = new ui.FocusNode();
  public constructor(props: ButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get button(): ButtonState {
    return this.props.d3eState.button;
  }
  public get d3eState(): RecentItemDeleteViewRefs {
    return this.props.d3eState;
  }
  public get _onButtonPressed(): _ButtonOnPressed {
    return this.props._onButtonPressed;
  }
  public initState() {
    super.initState();

    this.updateObservable("button", null, this.button);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button"], this.rebuild);
  }
  public dispose(): void {
    super.dispose();
  }
  public render(): ReactNode {
    return Button({
      disable: this.button.disable,
      onPressed: () => {
        this._onButtonPressed(this.d3eState);
      },
      onFocusChange: (val) => {},
      child: TextView({ data: "InActive", className: "vc" }),
      className: "vc",
    });
  }
}
function ButtonWithState(props: ButtonWithStateProps) {
  return React.createElement(_ButtonWithState, props);
}

class _RecentItemDeleteViewState extends ObservableComponent<RecentItemDeleteViewProps> {
  static defaultProps = { lead: null, onClosePressed: null };
  d3eState: RecentItemDeleteViewRefs = new RecentItemDeleteViewRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RecentItemDeleteViewProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);
  }
  public componentDidUpdate(prevProps: RecentItemDeleteViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.end,
      children: [
        ui.Container({ className: "x6c6 hc vc h", key: "0" }),
        ui.Row({
          children: [
            ButtonWithState({
              d3eState: this.d3eState,
              _onButtonPressed: this.onButtonPressed,
              key: "0",
            }),
          ],
          className: "x321 vc",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "RecentItemDeleteView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onButtonPressed = async (
    d3eState: RecentItemDeleteViewRefs
  ): Promise<void> => {
    this.lead.setStatus(LeadStatus.Closed);

    let result: Result<Lead> = await this.lead.save();

    if (result.status === ResultStatus.Success) {
      EventBus.get().fire(
        new SuccessMessage({ message: "Lead Closed Successfully" })
      );

      this.navigator.close();
    } else {
      EventBus.get().fire(
        new FailureMessage({ message: "Failed to close lead" })
      );

      this.navigator.close();
    }
  };
  public get onClosePressed(): _RecentItemDeleteViewOnClosePressed {
    return this.props.onClosePressed;
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get button() {
    return this.d3eState.button;
  }
}
export default function RecentItemDeleteView(props: RecentItemDeleteViewProps) {
  return React.createElement(_RecentItemDeleteViewState, {
    ..._RecentItemDeleteViewState.defaultProps,
    ...props,
  });
}
