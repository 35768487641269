import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import LeadInformationWidget from "./LeadInformationWidget";
import D3EDisposable from "../rocket/D3EDisposable";
import User from "../models/User";
import NeedsAndPainsCardView from "./NeedsAndPainsCardView";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import InteractionHistoriesView from "./InteractionHistoriesView";
import ProfileViewWidget from "./ProfileViewWidget";
import CallStatus from "../classes/CallStatus";
import InteractionType from "../classes/InteractionType";
import ScrollView2 from "./ScrollView2";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import Duration from "../core/Duration";
import DateTime from "../core/DateTime";
import TextView from "./TextView";
import Interaction from "../models/Interaction";
import RPCServices from "../rpc/RPCServices";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

export interface LeadOverviewWidgetProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  user: User;
  client: TwilioClient;
  data: CallData;
}

class _LeadOverviewWidgetState extends ObservableComponent<LeadOverviewWidgetProps> {
  static defaultProps = { lead: null, user: null, client: null, data: null };
  viewScrollController: ui.ScrollController = new ui.ScrollController();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public leadDisposable: D3EDisposable;
  public constructor(props: LeadOverviewWidgetProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.leadDisposable = MessageDispatch.get().syncObject(
      this.props.lead,
      UsageConstants.SUBSCRIPTION_ONLEADCHANGE_LEADOVERVIEWWIDGET_LEAD_SYNCHRONISE
    );

    this.runFetchDataQueryForLead();

    this.leadDisposable = MessageDispatch.get().syncObject(
      this.props.lead,
      UsageConstants.QUERY_GETLEADBYID_LEADOVERVIEWWIDGET_LEAD_FETCHDATA
    );

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("data", this.props.data);

    this.on(
      [
        "client",
        "data",
        "lead",
        "lead.familyInfo",
        "lead.interactionHistory",
        "user",
        "user.company",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LeadOverviewWidgetProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.runFetchDataQueryForLead();

      this.fire("lead", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public runFetchDataQueryForLead = (): void => {
    if (this.lead == null) {
      return;
    }

    Query.get().getLeadById(
      UsageConstants.QUERY_GETLEADBYID_LEADOVERVIEWWIDGET_LEAD_FETCHDATA,
      this.lead.id
    );
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Column({
          children: [
            ProfileViewWidget({
              lead: this.lead,
              user: this.user,
              client: this.client,
              data: this.data,
              className: "x4f6 hc h",
              key: "0",
            }),
            ui.Row({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Container({
                  decoration: new ui.BoxDecoration({
                    border: new ui.Border({
                      right: new ui.BorderSide({
                        width: 1.5,
                        style: ui.BorderStyle.solid,
                        color: cStyle.c16,
                      }),
                    }),
                  }),
                  width: 380,
                  child: LeadInformationWidget({
                    lead: this.lead,
                    info: this.lead.familyInfo,
                  }),
                  key: "0",
                  className: "x2e6 hc vc v",
                }),
                this.lead.interactionHistory.isNotEmpty
                  ? ui.Column({
                      children: [
                        ui.Row({
                          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                          crossAxisAlignment: ui.CrossAxisAlignment.center,
                          children: [
                            TextView({
                              data: "Call Recordings and Notes",
                              style: new ui.TextStyle({
                                fontSize:
                                  cStyle.tTextViewHeadlineFourFontSizeOn,
                                fontWeight:
                                  cStyle.tTextViewHeadlineFourFontWeightOn,
                              }),
                              className: "xe1c",
                              key: "0",
                            }),
                          ],
                          className: "x58e hc h",
                          key: "0",
                        }),
                        ui.Row({
                          children: [
                            ui.Container({
                              className: "x86e hc vc h",
                              key: "0",
                            }),
                          ],
                          className: "x698 hc h",
                          key: "1",
                        }),
                        ui.Container({
                          expand: true,
                          margin: ui.EdgeInsets.symmetric({
                            horizontal: 8.0,
                            vertical: 0.0,
                            transitions: new Map(),
                          }),
                          child: ScrollView2({
                            child: InteractionHistoriesView({
                              lead: this.lead,
                              handledBy: this.user,
                              data: this.data,
                              company: this.user.company,
                              computeNewMessage: (body) => {
                                this.onNewMessageButtonPressed(body);
                              },
                              key: this.lead.ident,
                            }),
                            scrollDirection: ui.Axis.vertical,
                            className: "x037 hc vc h v",
                            controller: this.viewScrollController,
                          }),
                          key: "2",
                          className: "x037 hc vc h v",
                        }),
                      ],
                      className: "xd06 hc vc h v",
                    })
                  : ui.Column({
                      children: [
                        TextView({
                          textAlign: ui.TextAlign.center,
                          data: "No Call Recordings and Notes",
                          className: "x2d3 hc",
                          key: "0",
                        }),
                      ],
                      className: "xd067 hc vc h v",
                    }),
                ui.Container({
                  decoration: new ui.BoxDecoration({
                    border: new ui.Border({
                      left: new ui.BorderSide({
                        width: 1.5,
                        style: ui.BorderStyle.solid,
                        color: cStyle.c16,
                      }),
                    }),
                  }),
                  width: 400,
                  child: NeedsAndPainsCardView({ lead: this.lead }),
                  key: "2",
                  className: "x9e6 hc vc v",
                }),
              ],
              className: "x529 hc vc h v",
              key: "1",
            }),
          ],
          className: "x8f9 hc vc h v",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "LeadOverviewWidget xaca hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    if (false) {
      let i: Interaction = this.lead.interactionHistory.first;

      i.setSid("123");

      i.setType(InteractionType.Call);

      i.setDetails("Call Details");

      i.setToNumber("1234567890");

      i.setFromNumber("1234567890");

      i.setDuration(new Duration({ minutes: 5 }));

      i.setRecordingURL("http://recording.com");

      i.setStartTime(DateTime.now());

      i.setEndTime(DateTime.now());

      i.setHandledBy(this.user);

      i.setCallStatus(CallStatus.Completed);

      i.setNotes(["Note 1", "Note 2"]);
    }
  };
  public onNewMessageButtonPressed = async (body: string): Promise<void> => {
    let interaction: Interaction = new Interaction({
      lead: this.lead,
      fromNumber: this.user.twilioNumber,
      toNumber: this.lead.phone,
      type: InteractionType.SMS,
      body: body,
      isIncoming: false,
      handledBy: this.user,
      createdDate: DateTime.now(),
    });

    let status: boolean = await RPCServices.getCallService().sendSMS(
      interaction
    );

    if (status) {
      this.lead.interactionHistory.add(interaction);
    }
  };
  public dispose(): void {
    this.leadDisposable?.dispose();

    super.dispose();
  }
}
export default function LeadOverviewWidget(props: LeadOverviewWidgetProps) {
  return React.createElement(_LeadOverviewWidgetState, {
    ..._LeadOverviewWidgetState.defaultProps,
    ...props,
  });
}
