import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import Loader from "./Loader";
import Device from "../utils/Device";
import InfoMessage from "../classes/InfoMessage";
import Popup from "./Popup";
import EventBus from "../utils/EventBus";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import PageNavigator from "../classes/PageNavigator";
import UserRole from "../classes/UserRole";
import IconButton from "./IconButton";
import CallInteraction from "../classes/CallInteraction";
import CallingProgressView from "./CallingProgressView";
import Query from "../classes/Query";
import EditProfileView from "./EditProfileView";
import TwilioClient from "../classes/TwilioClient";
import TextView from "./TextView";
import IconView from "./IconView";
import TextWithToggle from "./TextWithToggle";
import { BuildContext } from "../classes/BuildContext";
import ToolTipWrapper from "./ToolTipWrapper";

type _PageHeaderViewOnConnect = () => void;

type _PageHeaderViewOnMaximizeCall = () => void;

export interface PageHeaderViewProps extends BaseUIProps {
  key?: string;
  title?: string;
  data: CallData;
  callInteraction: CallInteraction;
  enableCallView: boolean;
  client: TwilioClient;
  user: User;
  onConnect: _PageHeaderViewOnConnect;
  onMaximizeCall: _PageHeaderViewOnMaximizeCall;
}

class _PageHeaderViewState extends ObservableComponent<PageHeaderViewProps> {
  static defaultProps = {
    title: "",
    data: null,
    callInteraction: null,
    enableCallView: false,
    client: null,
    user: null,
    onConnect: null,
    onMaximizeCall: null,
  };
  editProfilePopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: PageHeaderViewProps) {
    super(props);

    this.initState();
  }
  public get title(): string {
    return this.props.title;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public get callInteraction(): CallInteraction {
    return this.props.callInteraction;
  }
  public get enableCallView(): boolean {
    return this.props.enableCallView;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("data", this.props.data);

    this.updateSyncProperty("callInteraction", this.props.callInteraction);

    this.updateSyncProperty("user", this.props.user);

    this.on(
      [
        "callInteraction",
        "client",
        "data",
        "data.connecting",
        "data.deviceReady",
        "enableCallView",
        "title",
        "user",
        "user.profile",
        "user.role",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: PageHeaderViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.title !== this.props.title) {
      this.fire("title", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }

    if (prevProps.callInteraction !== this.props.callInteraction) {
      this.updateObservable(
        "callInteraction",
        prevProps.callInteraction,
        this.props.callInteraction
      );

      this.fire("callInteraction", this);
    }

    if (prevProps.enableCallView !== this.props.enableCallView) {
      this.fire("enableCallView", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
      children: [
        TextView({
          data:
            this.user.role === UserRole.SalesPerson
              ? "Lead Management"
              : this.title,
          style: new ui.TextStyle({
            fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
            fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
          }),
          className: "x13",
          key: "0",
        }),
        ui.Container({ className: "xc8f hc h", key: "1" }),
        this.enableCallView
          ? ui.Container({
              margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 10.0, 0.0, new Map()),
              child: CallingProgressView({
                callInteraction: this.callInteraction,
                client: this.client,
                onCall: () => {
                  this.onCallingInteractionHanlde();
                },
              }),
              className: "x2ca",
            })
          : [],
        this.data.connecting
          ? Loader({ backgroundColor: cStyle.c14, valueColor: cStyle.c1 })
          : ui.Container({
              margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 10.0, 0.0, new Map()),
              child: TextWithToggle({
                value: this.data.deviceReady,
                name:
                  this.data !== null && this.data.deviceReady
                    ? "Online"
                    : "Offline",
                onChanged: (val) => {
                  this.connectButtonPressed(val);
                },
              }),
              className: "x4a1b",
            }),
        ui.Row({
          children: [
            this.user.role === UserRole.SalesPerson
              ? ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
                  children: [
                    this.user.profile !== null &&
                    this.user.profile.downloadUrl !== null
                      ? ui.ClipRRect({
                          borderRadius: ui.BorderRadius.circular(16),
                          child: ui.NetworkImage({
                            url:
                              this.user.profile.downloadUrl +
                              "?width=25&height=25&inline=true",
                            width: 30,
                            height: 30,
                            fit: ui.BoxFit.fill,
                            className: "xcc2 hc vc",
                          }),
                        })
                      : ToolTipWrapper({
                          message: "Profile",
                          backgroundColor: cStyle.tooltipBackgroundColor,
                          textColor: cStyle.tooltipTextColor,
                          child: ui.ClipRRect({
                            borderRadius: ui.BorderRadius.circular(16),
                            child: ui.AssetImage({
                              path: "images/profile1.png",
                              width: 30,
                              height: 30,
                              fit: ui.BoxFit.fill,
                              className: "x4f79 hc vc",
                            }),
                          }),
                        }),
                  ],
                  onTap: (e) => {
                    e.stopPropagation();

                    this.onProfileButtonHandler();
                  },
                  className: "x7e6",
                })
              : [],
            this.user.role === UserRole.SalesPerson
              ? ui.Container({
                  margin: ui.EdgeInsets.symmetric({
                    horizontal: 10.0,
                    vertical: 0.0,
                    transitions: new Map(),
                  }),
                  child: IconButton({
                    size: 24,
                    tooltip: "Logout",
                    icon: MaterialIcons.exit_to_app,
                    onPressed: () => {
                      this.onLogoutButtonHandler();
                    },
                  }),
                  className: "xed2",
                })
              : [],
          ],
          key: "4",
        }),
        ui.Stack({
          states: ui.joinStates({ "data-visibility": false }, {}),
          children: [
            ToolTipWrapper({
              message: "Notif",
              backgroundColor: cStyle.tooltipBackgroundColor,
              textColor: cStyle.tooltipTextColor,
              child: IconView({
                size: 24,
                icon: MaterialIcons.notifications,
                className: "x16bb",
                key: "0",
              }),
            }),
            ui.Positioned({
              child: ui.Container({ className: "x06b hc vc", key: "1" }),
              width: 6,
              height: 6,
              left: 15,
            }),
          ],
          onTap: (e) => {
            e.stopPropagation();

            this.notificationPressed();
          },
          className: "x1fba",
          key: "5",
        }),
      ],
      className: ui.join(this.props.className, "PageHeaderView xdc7 hc h"),
      ...copyBaseUIProps(this.props),
    });
  }
  public connectButtonPressed = (val: boolean): void => {
    this.onConnect();
  };
  public notificationPressed = (): void => {
    EventBus.get().fire(new InfoMessage({ message: "No new notifications" }));
  };
  public onCallingInteractionHanlde = (): void => {
    this.onMaximizeCall();
  };
  public onLogoutButtonHandler = async (): Promise<void> => {
    Device.put("email", null);

    Device.put("password", null);

    let result: boolean = await Query.get().logout();

    this.navigator.pushLoginPage();
  };
  public onProfileButtonHandler = (): void => {
    this.showEditProfilePopup();
  };
  public get onConnect(): _PageHeaderViewOnConnect {
    return this.props.onConnect;
  }
  public get onMaximizeCall(): _PageHeaderViewOnMaximizeCall {
    return this.props.onMaximizeCall;
  }
  public dispose(): void {
    this.editProfilePopupPopup?.dispose();

    super.dispose();
  }
  public showEditProfilePopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.editProfilePopupPopup?.dispose();

    this.editProfilePopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 500,
        child: EditProfileView({ user: this.user }),
        className: "x3028 hc vc",
      }),
    });

    this.editProfilePopupPopup.showPopup(this.context);
  }
  public hideEditProfilePopup(): void {
    this.editProfilePopupPopup?.dispose();
  }
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
}
export default function PageHeaderView(props: PageHeaderViewProps) {
  return React.createElement(_PageHeaderViewState, {
    ..._PageHeaderViewState.defaultProps,
    ...props,
  });
}
