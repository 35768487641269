import DataPoint from "./DataPoint";
import LeadAssignment from "../models/LeadAssignment";
import DataSet from "./DataSet";
import LeadStatus from "./LeadStatus";

export default class ChartDataUtils {
  public constructor() {}
  public static async getStatusBasedChartData(
    leadAssignments: Array<LeadAssignment>
  ): Promise<Array<DataSet>> {
    let newCount: number = leadAssignments
      .where((c) => c.lead.status === LeadStatus.New)
      .length.toDouble();

    let inProgressCount: number = leadAssignments
      .where((c) => c.lead.status === LeadStatus.Inprogress)
      .length.toDouble();

    let contactedCount: number = leadAssignments
      .where((c) => c.lead.status === LeadStatus.Contacted)
      .length.toDouble();

    let closedCount: number = leadAssignments
      .where((c) => c.lead.status === LeadStatus.Closed)
      .length.toDouble();

    let data: Array<DataSet> = [];

    //  Add data sets for each country

    data.add(
      new DataSet({
        name: "New",
        data: [new DataPoint({ value: newCount })],
        color: "ffe5a000",
      })
    );

    data.add(
      new DataSet({
        name: "In Progress",
        data: [new DataPoint({ value: inProgressCount })],
        color: "ffd732a8",
      })
    );

    data.add(
      new DataSet({
        name: "Contacted",
        data: [new DataPoint({ value: contactedCount })],
        color: "ff6c73ff",
      })
    );

    data.add(
      new DataSet({
        name: "Closed",
        data: [new DataPoint({ value: closedCount })],
        color: "ff14ae5c",
      })
    );

    return data;
  }
  public static getCount(dataSets: Array<DataSet>): string {
    let count: string = "";

    for (let dataSet of dataSets.where((c) => c.data.isNotEmpty)) {
      count = count + dataSet.data[0].value.toString() + ", ";
    }

    return count.toString();
  }
}
