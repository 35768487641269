export default class UserRole {
  public static readonly Manager = new UserRole("Manager", "Manager", 0);
  public static readonly SalesPerson = new UserRole(
    "SalesPerson",
    "SalesPerson",
    1
  );
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): UserRole[] {
    return [UserRole.Manager, UserRole.SalesPerson];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
