import SearchCriteria from "./SearchCriteria";

export default class SideMenuUtils {
  public constructor() {}
  public static getTabItemNames(user: string): Array<string> {
    let tabItemNames: Array<string> = [];

    if (user === "Manager") {
      tabItemNames = [
        "Basic",
        "Company Information",
        "Interaction & Preferences",
        "Family Information",
      ];
    }

    return tabItemNames;
  }
  public static getFilterCount(criteria: SearchCriteria): number {
    let count: number = 0;

    if (criteria !== null) {
      if (criteria.fullName !== null && criteria.fullName !== "") {
        count++;
      }

      if (
        criteria.personLocations !== null &&
        criteria.personLocations.isNotEmpty
      ) {
        count++;
      }

      if (criteria.timeZones !== null && criteria.timeZones.isNotEmpty) {
        count++;
      }

      if (
        criteria.accountLocation !== null &&
        criteria.accountLocation.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.jobPostingHiringFor !== null &&
        criteria.jobPostingHiringFor.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.jobPostingLocation !== null &&
        criteria.jobPostingLocation.isNotEmpty
      ) {
        count++;
      }

      if (criteria.jobTitles !== null && criteria.jobTitles.isNotEmpty) {
        count++;
      }

      if (criteria.languages !== null && criteria.languages.isNotEmpty) {
        count++;
      }

      if (criteria.keywords !== null && criteria.keywords.isNotEmpty) {
        count++;
      }

      if (
        criteria.companyTagIDs !== null &&
        criteria.companyTagIDs.isNotEmpty
      ) {
        count++;
      }

      if (criteria.technologies !== null && criteria.technologies.isNotEmpty) {
        count++;
      }

      if (criteria.minYearExp !== null && criteria.minYearExp.isNotEmpty) {
        count++;
      }

      if (criteria.maxYearExp !== null && criteria.maxYearExp.isNotEmpty) {
        count++;
      }

      if (
        criteria.minYearCurrentRole !== null &&
        criteria.minYearCurrentRole.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.maxYearCurrentRole !== null &&
        criteria.maxYearCurrentRole.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.minMonthCurrentRole !== null &&
        criteria.minMonthCurrentRole.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.maxMonthCurrentRole !== null &&
        criteria.maxMonthCurrentRole.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.minGrowPercentage !== null &&
        criteria.minGrowPercentage.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.maxGrowPercentage !== null &&
        criteria.maxGrowPercentage.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.fundingFromDate !== null &&
        criteria.fundingFromDate.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.fundingToDate !== null &&
        criteria.fundingToDate.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.minFundingAmount !== null &&
        criteria.minFundingAmount.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.maxFundingAmount !== null &&
        criteria.maxFundingAmount.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.minFoundedYear !== null &&
        criteria.minFoundedYear.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.maxFoundedYear !== null &&
        criteria.maxFoundedYear.isNotEmpty
      ) {
        count++;
      }

      if (
        criteria.minRetailLocation !== null &&
        !criteria.minRetailLocation.isEmpty
      ) {
        count++;
      }

      if (
        criteria.maxRetailLocation !== null &&
        !criteria.maxRetailLocation.isEmpty
      ) {
        count++;
      }

      if (criteria.minJobPosting !== null && !criteria.minJobPosting.isEmpty) {
        count++;
      }

      if (criteria.maxJobPosting !== null && !criteria.maxJobPosting.isEmpty) {
        count++;
      }

      if (
        criteria.jobPostedFromDate !== null &&
        !criteria.jobPostedFromDate.isEmpty
      ) {
        count++;
      }

      if (
        criteria.jobPostedToDate !== null &&
        !criteria.jobPostedToDate.isEmpty
      ) {
        count++;
      }

      if (
        criteria.minEmployeeRange !== null &&
        !criteria.minEmployeeRange.isEmpty
      ) {
        count++;
      }

      if (
        criteria.maxEmployeeRange !== null &&
        !criteria.maxEmployeeRange.isEmpty
      ) {
        count++;
      }

      if (criteria.timeFrame !== null && !criteria.timeFrame.isEmpty) {
        count++;
      }

      if (criteria.department !== null && !criteria.department.isEmpty) {
        count++;
      }
    } else {
      count = 0;
    }

    return count;
  }
}
