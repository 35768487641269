import DateTime from "../core/DateTime";
import UserRole from "../classes/UserRole";
import DFile from "../classes/DFile";
import BaseUser from "./BaseUser";
import DBObject from "../utils/DBObject";
import MicroSoftAcc from "./MicroSoftAcc";
import CloneContext from "../utils/CloneContext";
import Company from "./Company";

export default class User extends BaseUser {
  private static readonly _COMPANY: number = 0;
  private static readonly _CREATEDDATE: number = 1;
  private static readonly _EMAIL: number = 2;
  private static readonly _FIRSTNAME: number = 3;
  private static readonly _ISREMEMBERME: number = 4;
  private static readonly _LASTNAME: number = 5;
  private static readonly _MACCOUNT: number = 6;
  private static readonly _PASSWORD: number = 7;
  private static readonly _PHONENUMBER: number = 8;
  private static readonly _PROFILE: number = 9;
  private static readonly _ROLE: number = 10;
  private static readonly _TWILIONUMBER: number = 11;
  private _firstName: string = "";
  private _lastName: string = "";
  private _email: string = "";
  private _password: string = "";
  private _phoneNumber: string = "";
  private _role: UserRole = UserRole.Manager;
  private _profile: DFile = null;
  private _isRememberMe: boolean = false;
  private _twilioNumber: string = "";
  private _mAccount: MicroSoftAcc = null;
  private _company: Company = null;
  private _createdDate: DateTime = null;
  public constructor(
    d3eParams?: Partial<{
      company: Company;
      createdDate: DateTime;
      email: string;
      firstName: string;
      isRememberMe: boolean;
      lastName: string;
      mAccount: MicroSoftAcc;
      password: string;
      phoneNumber: string;
      profile: DFile;
      role: UserRole;
      twilioNumber: string;
    }>
  ) {
    super();

    this.setCompany(d3eParams?.company ?? null);

    this.setCreatedDate(d3eParams?.createdDate ?? null);

    this.setEmail(d3eParams?.email ?? "");

    this.setFirstName(d3eParams?.firstName ?? "");

    this.setIsRememberMe(d3eParams?.isRememberMe ?? false);

    this.setLastName(d3eParams?.lastName ?? "");

    this.setMAccount(d3eParams?.mAccount ?? null);

    this.setPassword(d3eParams?.password ?? "");

    this.setPhoneNumber(d3eParams?.phoneNumber ?? "");

    this.setProfile(d3eParams?.profile ?? null);

    this.setRole(d3eParams?.role ?? UserRole.Manager);

    this.setTwilioNumber(d3eParams?.twilioNumber ?? "");
  }
  public get d3eType(): string {
    return "User";
  }
  public static updateMAccountInverse(on: any, obj: any, type: string): void {
    if ("Insert" === type) {
      on.addToMAccount(obj as MicroSoftAcc);
    }

    if ("Delete" === type) {
      on.removeFromMAccount(obj as MicroSoftAcc);
    }
  }
  public get firstName(): string {
    return this._firstName;
  }
  public setFirstName(val: string): void {
    let isValChanged: boolean = this._firstName !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._FIRSTNAME, this._firstName);

    this._firstName = val;

    this.fire("firstName", this);
  }
  public get lastName(): string {
    return this._lastName;
  }
  public setLastName(val: string): void {
    let isValChanged: boolean = this._lastName !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._LASTNAME, this._lastName);

    this._lastName = val;

    this.fire("lastName", this);
  }
  public get email(): string {
    return this._email;
  }
  public setEmail(val: string): void {
    let isValChanged: boolean = this._email !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._EMAIL, this._email);

    this._email = val;

    this.fire("email", this);
  }
  public get password(): string {
    return this._password;
  }
  public setPassword(val: string): void {
    let isValChanged: boolean = this._password !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._PASSWORD, this._password);

    this._password = val;

    this.fire("password", this);
  }
  public get phoneNumber(): string {
    return this._phoneNumber;
  }
  public setPhoneNumber(val: string): void {
    let isValChanged: boolean = this._phoneNumber !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._PHONENUMBER, this._phoneNumber);

    this._phoneNumber = val;

    this.fire("phoneNumber", this);
  }
  public get role(): UserRole {
    return this._role;
  }
  public setRole(val: UserRole): void {
    let isValChanged: boolean = this._role !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._ROLE, this._role.index);

    this._role = val;

    this.fire("role", this);
  }
  public get profile(): DFile {
    return this._profile;
  }
  public setProfile(val: DFile): void {
    let isValChanged: boolean = this._profile !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._PROFILE, this._profile);

    this._profile = val;

    this.fire("profile", this);
  }
  public get isRememberMe(): boolean {
    return this._isRememberMe;
  }
  public setIsRememberMe(val: boolean): void {
    let isValChanged: boolean = this._isRememberMe !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._ISREMEMBERME, this._isRememberMe);

    this._isRememberMe = val;

    this.fire("isRememberMe", this);
  }
  public get twilioNumber(): string {
    return this._twilioNumber;
  }
  public setTwilioNumber(val: string): void {
    let isValChanged: boolean = this._twilioNumber !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._TWILIONUMBER, this._twilioNumber);

    this._twilioNumber = val;

    this.fire("twilioNumber", this);
  }
  public get mAccount(): MicroSoftAcc {
    return this._mAccount;
  }
  public setMAccount(val: MicroSoftAcc): void {
    let isValChanged: boolean = this._mAccount !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._MACCOUNT, this._mAccount);

    this.updateObservable("mAccount", this._mAccount, val);

    this._mAccount = val;

    this.fire("mAccount", this);
  }
  public get company(): Company {
    return this._company;
  }
  public setCompany(val: Company): void {
    let isValChanged: boolean = this._company !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._COMPANY, this._company);

    this.updateObservable("company", this._company, val);

    this._company = val;

    this.fire("company", this);
  }
  public get createdDate(): DateTime {
    return this._createdDate;
  }
  public setCreatedDate(val: DateTime): void {
    let isValChanged: boolean = this._createdDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(User._CREATEDDATE, this._createdDate);

    this._createdDate = val;

    this.fire("createdDate", this);
  }
  public get(field: number): any {
    switch (field) {
      case User._FIRSTNAME: {
        return this._firstName;
      }

      case User._LASTNAME: {
        return this._lastName;
      }

      case User._EMAIL: {
        return this._email;
      }

      case User._PASSWORD: {
        return this._password;
      }

      case User._PHONENUMBER: {
        return this._phoneNumber;
      }

      case User._ROLE: {
        return this._role.index;
      }

      case User._PROFILE: {
        return this._profile;
      }

      case User._ISREMEMBERME: {
        return this._isRememberMe;
      }

      case User._TWILIONUMBER: {
        return this._twilioNumber;
      }

      case User._MACCOUNT: {
        return this._mAccount;
      }

      case User._COMPANY: {
        return this._company;
      }

      case User._CREATEDDATE: {
        return this._createdDate;
      }
      default: {
        return super.get(field);
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): User {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {
    super.collectChildValues(ctx);
  }
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    super.deepCloneIntoObj(dbObj, ctx);

    let obj: User = dbObj as User;

    obj.setFirstName(this._firstName);

    obj.setLastName(this._lastName);

    obj.setEmail(this._email);

    obj.setPassword(this._password);

    obj.setPhoneNumber(this._phoneNumber);

    obj.setRole(this._role);

    obj.setProfile(this._profile);

    obj.setIsRememberMe(this._isRememberMe);

    obj.setTwilioNumber(this._twilioNumber);

    obj.setMAccount(this._mAccount);

    obj.setCompany(this._company);

    obj.setCreatedDate(this._createdDate);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case User._FIRSTNAME: {
        this.setFirstName(value as string);
        break;
      }

      case User._LASTNAME: {
        this.setLastName(value as string);
        break;
      }

      case User._EMAIL: {
        this.setEmail(value as string);
        break;
      }

      case User._PASSWORD: {
        this.setPassword(value as string);
        break;
      }

      case User._PHONENUMBER: {
        this.setPhoneNumber(value as string);
        break;
      }

      case User._ROLE: {
        this.setRole(UserRole.values[value as number]);
        break;
      }

      case User._PROFILE: {
        this.setProfile(value as DFile);
        break;
      }

      case User._ISREMEMBERME: {
        this.setIsRememberMe(value as boolean);
        break;
      }

      case User._TWILIONUMBER: {
        this.setTwilioNumber(value as string);
        break;
      }

      case User._MACCOUNT: {
        this.setMAccount(value as MicroSoftAcc);
        break;
      }

      case User._COMPANY: {
        this.setCompany(value as Company);
        break;
      }

      case User._CREATEDDATE: {
        this.setCreatedDate(value as DateTime);
        break;
      }
      default: {
        super.set(field, value);
      }
    }
  }
}
