import IndustryResultItem from "../classes/IndustryResultItem";
import LeadResultItem from "../classes/LeadResultItem";
import CompanySearchItem from "../classes/CompanySearchItem";
import WSReader from "../rocket/WSReader";
import SearchCriteria from "../classes/SearchCriteria";
import MessageDispatch from "../rocket/MessageDispatch";
import { RPCConstants } from "../rocket/D3ETemplate";

export default class ApolloRPCServiceClient {
  public async searchLeads(
    criteria: SearchCriteria
  ): Promise<Array<LeadResultItem>> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.ApolloRPCService,
      RPCConstants.ApolloRPCServiceSearchLeads,
      {
        "args": (w) => {
          w.writeObjFull(criteria);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readRefList() as Array<LeadResultItem>;
  }
  public async getJobTitleSuggestions(
    jobTitle: string
  ): Promise<Array<string>> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.ApolloRPCService,
      RPCConstants.ApolloRPCServiceGetJobTitleSuggestions,
      {
        "args": (w) => {
          w.writeString(jobTitle);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readStringList() as Array<string>;
  }
  public async getLocationSuggestions(
    location: string
  ): Promise<Array<string>> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.ApolloRPCService,
      RPCConstants.ApolloRPCServiceGetLocationSuggestions,
      {
        "args": (w) => {
          w.writeString(location);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readStringList() as Array<string>;
  }
  public async getTechnologySuggestions(
    technology: string
  ): Promise<Array<string>> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.ApolloRPCService,
      RPCConstants.ApolloRPCServiceGetTechnologySuggestions,
      {
        "args": (w) => {
          w.writeString(technology);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readStringList() as Array<string>;
  }
  public async getOrgCompanies(
    query: string
  ): Promise<Array<CompanySearchItem>> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.ApolloRPCService,
      RPCConstants.ApolloRPCServiceGetOrgCompanies,
      {
        "args": (w) => {
          w.writeString(query);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readRefList() as Array<CompanySearchItem>;
  }
  public async getIndustriesSuggestions(
    query: string
  ): Promise<Array<IndustryResultItem>> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.ApolloRPCService,
      RPCConstants.ApolloRPCServiceGetIndustriesSuggestions,
      {
        "args": (w) => {
          w.writeString(query);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readRefList() as Array<IndustryResultItem>;
  }
  public async saveLeads(leads: Array<LeadResultItem>): Promise<boolean> {
    let r: WSReader = await MessageDispatch.get().rpcMessage(
      RPCConstants.ApolloRPCService,
      RPCConstants.ApolloRPCServiceSaveLeads,
      {
        "args": (w) => {
          w.writeObjFullList(leads);
        },
      }
    );

    let code: number = r.readByte();

    if (code === 1) {
      let error: string = r.readString();

      return Promise.error(error);
    }

    return r.readBoolean() as boolean;
  }
}
