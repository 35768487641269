export default class MessageStatus {
  public static readonly Sent = new MessageStatus("Sent", "Sent", 0);
  public static readonly Delivered = new MessageStatus(
    "Delivered",
    "Delivered",
    1
  );
  public static readonly Failed = new MessageStatus("Failed", "Failed", 2);
  public static readonly Unknown = new MessageStatus("Unknown", "Unknown", 3);
  private _ident: string;
  private _name: string;
  private _index: number;
  public constructor(_ident: string, _name: string, _index: number) {
    this._ident = _ident;

    this._name = _name;

    this._index = _index;
  }
  public static get values(): MessageStatus[] {
    return [
      MessageStatus.Sent,
      MessageStatus.Delivered,
      MessageStatus.Failed,
      MessageStatus.Unknown,
    ];
  }
  public get ident(): string {
    return this._ident;
  }
  public get name(): string {
    return this._name;
  }
  public get index(): number {
    return this._index;
  }
  public toString(): string {
    return this._name;
  }
}
