import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";

export interface TextValueFieldProps extends BaseUIProps {
  key?: string;
  label: string;
  value: string;
}

class _TextValueFieldState extends ObservableComponent<TextValueFieldProps> {
  static defaultProps = { label: "", value: "" };
  public constructor(props: TextValueFieldProps) {
    super(props);

    this.initState();
  }
  public get label(): string {
    return this.props.label;
  }
  public get value(): string {
    return this.props.value;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["label", "value"], this.rebuild);
  }
  public componentDidUpdate(prevProps: TextValueFieldProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.label !== this.props.label) {
      this.fire("label", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }
  }
  public render(): ReactNode {
    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.start,
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        TextView({ data: this.label, className: "x9af hc", key: "0" }),
        TextView({ data: ":", className: "xa12", key: "1" }),
        TextView({
          data: this.value,
          softWrap: true,
          style: new ui.TextStyle({ fontWeight: ui.FontWeight.w400 }),
          className: "x43a1 hc h",
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "TextValueField xe75"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function TextValueField(props: TextValueFieldProps) {
  return React.createElement(_TextValueFieldState, {
    ..._TextValueFieldState.defaultProps,
    ...props,
  });
}
