import Lead from "./Lead";
import DateTime from "../core/DateTime";
import DBObject from "../utils/DBObject";
import CloneContext from "../utils/CloneContext";

export default class MailMessage extends DBObject {
  private static readonly _BODY: number = 0;
  private static readonly _CONTENT: number = 1;
  private static readonly _CREATEDDATE: number = 2;
  private static readonly _INBOUND: number = 3;
  private static readonly _LEAD: number = 4;
  private static readonly _RECEIVEDDATETIME: number = 5;
  private static readonly _RECIPIENTEMAIL: number = 6;
  private static readonly _RECIPIENTNAME: number = 7;
  private static readonly _SENDEREMAIL: number = 8;
  private static readonly _SENDERNAME: number = 9;
  private static readonly _SENTDATETIME: number = 10;
  private static readonly _SUBJECT: number = 11;
  public id: number = 0;
  public otherMaster: DBObject;
  private _sentDateTime: DateTime = null;
  private _receivedDateTime: DateTime = null;
  private _lead: Lead = null;
  private _senderEmail: string = "";
  private _recipientEmail: string = "";
  private _senderName: string = "";
  private _recipientName: string = "";
  private _subject: string = "";
  private _body: string = "";
  private _inbound: boolean = false;
  private _createdDate: DateTime = null;
  private _content: string = "";
  public constructor(
    d3eParams?: Partial<{
      body: string;
      content: string;
      createdDate: DateTime;
      inbound: boolean;
      lead: Lead;
      receivedDateTime: DateTime;
      recipientEmail: string;
      recipientName: string;
      senderEmail: string;
      senderName: string;
      sentDateTime: DateTime;
      subject: string;
    }>
  ) {
    super();

    this.setBody(d3eParams?.body ?? "");

    this.setContent(d3eParams?.content ?? "");

    this.setCreatedDate(d3eParams?.createdDate ?? null);

    this.setInbound(d3eParams?.inbound ?? false);

    this.setLead(d3eParams?.lead ?? null);

    this.setReceivedDateTime(d3eParams?.receivedDateTime ?? null);

    this.setRecipientEmail(d3eParams?.recipientEmail ?? "");

    this.setRecipientName(d3eParams?.recipientName ?? "");

    this.setSenderEmail(d3eParams?.senderEmail ?? "");

    this.setSenderName(d3eParams?.senderName ?? "");

    this.setSentDateTime(d3eParams?.sentDateTime ?? null);

    this.setSubject(d3eParams?.subject ?? "");
  }
  public get d3eType(): string {
    return "MailMessage";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get sentDateTime(): DateTime {
    return this._sentDateTime;
  }
  public setSentDateTime(val: DateTime): void {
    let isValChanged: boolean = this._sentDateTime !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._SENTDATETIME, this._sentDateTime);

    this._sentDateTime = val;

    this.fire("sentDateTime", this);
  }
  public get receivedDateTime(): DateTime {
    return this._receivedDateTime;
  }
  public setReceivedDateTime(val: DateTime): void {
    let isValChanged: boolean = this._receivedDateTime !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(
      MailMessage._RECEIVEDDATETIME,
      this._receivedDateTime
    );

    this._receivedDateTime = val;

    this.fire("receivedDateTime", this);
  }
  public get lead(): Lead {
    return this._lead;
  }
  public setLead(val: Lead): void {
    let isValChanged: boolean = this._lead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._LEAD, this._lead);

    this.updateObservable("lead", this._lead, val);

    this._lead = val;

    this.fire("lead", this);
  }
  public get senderEmail(): string {
    return this._senderEmail;
  }
  public setSenderEmail(val: string): void {
    let isValChanged: boolean = this._senderEmail !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._SENDEREMAIL, this._senderEmail);

    this._senderEmail = val;

    this.fire("senderEmail", this);
  }
  public get recipientEmail(): string {
    return this._recipientEmail;
  }
  public setRecipientEmail(val: string): void {
    let isValChanged: boolean = this._recipientEmail !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._RECIPIENTEMAIL, this._recipientEmail);

    this._recipientEmail = val;

    this.fire("recipientEmail", this);
  }
  public get senderName(): string {
    return this._senderName;
  }
  public setSenderName(val: string): void {
    let isValChanged: boolean = this._senderName !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._SENDERNAME, this._senderName);

    this._senderName = val;

    this.fire("senderName", this);
  }
  public get recipientName(): string {
    return this._recipientName;
  }
  public setRecipientName(val: string): void {
    let isValChanged: boolean = this._recipientName !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._RECIPIENTNAME, this._recipientName);

    this._recipientName = val;

    this.fire("recipientName", this);
  }
  public get subject(): string {
    return this._subject;
  }
  public setSubject(val: string): void {
    let isValChanged: boolean = this._subject !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._SUBJECT, this._subject);

    this._subject = val;

    this.fire("subject", this);
  }
  public get body(): string {
    return this._body;
  }
  public setBody(val: string): void {
    let isValChanged: boolean = this._body !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._BODY, this._body);

    this._body = val;

    this.fire("body", this);
  }
  public get inbound(): boolean {
    return this._inbound;
  }
  public setInbound(val: boolean): void {
    let isValChanged: boolean = this._inbound !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._INBOUND, this._inbound);

    this._inbound = val;

    this.fire("inbound", this);
  }
  public get createdDate(): DateTime {
    return this._createdDate;
  }
  public setCreatedDate(val: DateTime): void {
    let isValChanged: boolean = this._createdDate !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._CREATEDDATE, this._createdDate);

    this._createdDate = val;

    this.fire("createdDate", this);
  }
  public get content(): string {
    return this._content;
  }
  public setContent(val: string): void {
    let isValChanged: boolean = this._content !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(MailMessage._CONTENT, this._content);

    this._content = val;

    this.fire("content", this);
  }
  public get(field: number): any {
    switch (field) {
      case MailMessage._SENTDATETIME: {
        return this._sentDateTime;
      }

      case MailMessage._RECEIVEDDATETIME: {
        return this._receivedDateTime;
      }

      case MailMessage._LEAD: {
        return this._lead;
      }

      case MailMessage._SENDEREMAIL: {
        return this._senderEmail;
      }

      case MailMessage._RECIPIENTEMAIL: {
        return this._recipientEmail;
      }

      case MailMessage._SENDERNAME: {
        return this._senderName;
      }

      case MailMessage._RECIPIENTNAME: {
        return this._recipientName;
      }

      case MailMessage._SUBJECT: {
        return this._subject;
      }

      case MailMessage._BODY: {
        return this._body;
      }

      case MailMessage._INBOUND: {
        return this._inbound;
      }

      case MailMessage._CREATEDDATE: {
        return this._createdDate;
      }

      case MailMessage._CONTENT: {
        return this._content;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): MailMessage {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: MailMessage = dbObj as MailMessage;

    obj.id = this.id;

    obj.setSentDateTime(this._sentDateTime);

    obj.setReceivedDateTime(this._receivedDateTime);

    obj.setLead(this._lead);

    obj.setSenderEmail(this._senderEmail);

    obj.setRecipientEmail(this._recipientEmail);

    obj.setSenderName(this._senderName);

    obj.setRecipientName(this._recipientName);

    obj.setSubject(this._subject);

    obj.setBody(this._body);

    obj.setInbound(this._inbound);

    obj.setCreatedDate(this._createdDate);

    obj.setContent(this._content);
  }
  public set(field: number, value: any): void {
    switch (field) {
      case MailMessage._SENTDATETIME: {
        this.setSentDateTime(value as DateTime);
        break;
      }

      case MailMessage._RECEIVEDDATETIME: {
        this.setReceivedDateTime(value as DateTime);
        break;
      }

      case MailMessage._LEAD: {
        this.setLead(value as Lead);
        break;
      }

      case MailMessage._SENDEREMAIL: {
        this.setSenderEmail(value as string);
        break;
      }

      case MailMessage._RECIPIENTEMAIL: {
        this.setRecipientEmail(value as string);
        break;
      }

      case MailMessage._SENDERNAME: {
        this.setSenderName(value as string);
        break;
      }

      case MailMessage._RECIPIENTNAME: {
        this.setRecipientName(value as string);
        break;
      }

      case MailMessage._SUBJECT: {
        this.setSubject(value as string);
        break;
      }

      case MailMessage._BODY: {
        this.setBody(value as string);
        break;
      }

      case MailMessage._INBOUND: {
        this.setInbound(value as boolean);
        break;
      }

      case MailMessage._CREATEDDATE: {
        this.setCreatedDate(value as DateTime);
        break;
      }

      case MailMessage._CONTENT: {
        this.setContent(value as string);
        break;
      }
    }
  }
}
