import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import DashboardView from "./DashboardView";
import CallData from "../classes/CallData";
import TwilioClient from "../classes/TwilioClient";
import User from "../models/User";

export interface DashboardPageProps extends BaseUIProps {
  key?: string;
  user: User;
  client: TwilioClient;
  data: CallData;
}

class _DashboardPageState extends ObservableComponent<DashboardPageProps> {
  static defaultProps = { user: null, client: null, data: null };
  public constructor(props: DashboardPageProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("data", this.props.data);

    this.on(["client", "data", "user"], this.rebuild);
  }
  public componentDidUpdate(prevProps: DashboardPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public render(): ReactNode {
    return ui.Column({
      children: [
        ui.Container({
          expand: true,
          child: DashboardView({
            client: this.client,
            data: this.data,
            user: this.user,
          }),
          key: "0",
          className: "x7ec hc vc h v",
        }),
      ],
      className: ui.join(this.props.className, "DashboardPage hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function DashboardPage(props: DashboardPageProps) {
  return React.createElement(_DashboardPageState, {
    ..._DashboardPageState.defaultProps,
    ...props,
  });
}
