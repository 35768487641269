import DBObject from "../utils/DBObject";
import Result from "../classes/Result";
import CloneContext from "../utils/CloneContext";

export default class Child extends DBObject {
  private static readonly _AGEGROUP: number = 0;
  private static readonly _NAME: number = 1;
  public id: number = 0;
  public otherMaster: DBObject;
  private _ageGroup: string = "";
  private _name: string = "";
  public childPropertyInMaster: number = 0;
  public constructor(d3eParams?: Partial<{ ageGroup: string; name: string }>) {
    super();

    this.setAgeGroup(d3eParams?.ageGroup ?? "");

    this.setName(d3eParams?.name ?? "");
  }
  public get d3eType(): string {
    return "Child";
  }
  public clear(): void {
    this.d3eChanges.clear();
  }
  public get ageGroup(): string {
    return this._ageGroup;
  }
  public setAgeGroup(val: string): void {
    let isValChanged: boolean = this._ageGroup !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Child._AGEGROUP, this._ageGroup);

    this._ageGroup = val;

    this.fire("ageGroup", this);
  }
  public get name(): string {
    return this._name;
  }
  public setName(val: string): void {
    let isValChanged: boolean = this._name !== val;

    if (!isValChanged) {
      return;
    }

    this.updateD3EChanges(Child._NAME, this._name);

    this._name = val;

    this.fire("name", this);
  }
  public get(field: number): any {
    switch (field) {
      case Child._AGEGROUP: {
        return this._ageGroup;
      }

      case Child._NAME: {
        return this._name;
      }
      default: {
        return null;
      }
    }
  }
  public updateD3EChanges(index: number, value: any): void {
    if (this.lockedChanges()) {
      return;
    }

    super.updateD3EChanges(index, value);

    this.otherMaster?.updateChildChanges(this.childPropertyInMaster);
  }
  public restore(): void {
    /*
TODO: Might be removed
*/

    this.d3eChanges.restore(this);
  }
  public deepClone(clearId = true): Child {
    let ctx: CloneContext = new CloneContext({ "clearId": clearId });

    return ctx.startClone(this);
  }
  public collectChildValues(ctx: CloneContext): void {}
  public deepCloneIntoObj(dbObj: DBObject, ctx: CloneContext): void {
    let obj: Child = dbObj as Child;

    obj.id = this.id;

    obj.setAgeGroup(this._ageGroup);

    obj.setName(this._name);
  }
  public async save(): Promise<Result<Child>> {
    if (this.otherMaster != null) {
      return this.otherMaster.save();
    }

    return new Result();
  }
  public async delete(): Promise<Result<Child>> {
    if (this.otherMaster != null) {
      return this.otherMaster.delete();
    }

    return new Result();
  }
  public set(field: number, value: any): void {
    switch (field) {
      case Child._AGEGROUP: {
        this.setAgeGroup(value as string);
        break;
      }

      case Child._NAME: {
        this.setName(value as string);
        break;
      }
    }
  }
}
