import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ListWrapper from "../utils/ListWrapper";
import TextView from "./TextView";
import { BuildContext } from "../classes/BuildContext";

type _LabelWithIntegerInputFieldOnChanged = (value: number) => void;

type _LabelWithIntegerInputFieldOnTap = () => void;

type _LabelWithIntegerInputFieldOnSubmitted = (value: number) => void;

export interface LabelWithIntegerInputFieldProps extends BaseUIProps {
  key?: string;
  name?: string;
  placeHolder?: string;
  isRequired?: boolean;
  value?: number;
  errors?: Array<string>;
  disable?: boolean;
  fieldColor?: ui.Color;
  cornerRadius?: number;
  dense?: boolean;
  _errorsHash?: number;
  onChanged?: _LabelWithIntegerInputFieldOnChanged;
  onTap?: _LabelWithIntegerInputFieldOnTap;
  onSubmitted?: _LabelWithIntegerInputFieldOnSubmitted;
}

class _LabelWithIntegerInputFieldState extends ObservableComponent<LabelWithIntegerInputFieldProps> {
  static defaultProps = {
    name: "",
    placeHolder: "",
    isRequired: false,
    value: 0,
    disable: false,
    fieldColor: null,
    cornerRadius: 4.0,
    dense: false,
    errors: [],
    onChanged: null,
    onTap: null,
    onSubmitted: null,
  };
  fieldController: ui.TextEditingController = new ui.TextEditingController();
  focusNode: ui.FocusNode = null;
  active: boolean = false;
  nullValue: number = 0;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LabelWithIntegerInputFieldProps) {
    super(props);

    this.initState();
  }
  public get name(): string {
    return this.props.name;
  }
  public get placeHolder(): string {
    return this.props.placeHolder;
  }
  public get isRequired(): boolean {
    return this.props.isRequired;
  }
  public get value(): number {
    return this.props.value;
  }
  public get errors(): Array<string> {
    return this.props.errors;
  }
  public get disable(): boolean {
    return this.props.disable;
  }
  public get fieldColor(): ui.Color {
    return this.props.fieldColor;
  }
  public get cornerRadius(): number {
    return this.props.cornerRadius;
  }
  public get dense(): boolean {
    return this.props.dense;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.subscribeToList(this.errors, "errors");

    this.on(
      [
        "cornerRadius",
        "dense",
        "disable",
        "errors",
        "fieldColor",
        "focusNode",
        "isRequired",
        "name",
        "nullValue",
        "placeHolder",
        "value",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LabelWithIntegerInputFieldProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.name !== this.props.name) {
      this.fire("name", this);
    }

    if (prevProps.placeHolder !== this.props.placeHolder) {
      this.fire("placeHolder", this);
    }

    if (prevProps.isRequired !== this.props.isRequired) {
      this.fire("isRequired", this);
    }

    if (prevProps.value !== this.props.value) {
      this.fire("value", this);
    }

    if (prevProps.errors !== this.props.errors) {
      this.fire("errors", this);
    }

    if (prevProps.disable !== this.props.disable) {
      this.fire("disable", this);
    }

    if (prevProps.fieldColor !== this.props.fieldColor) {
      this.fire("fieldColor", this);
    }

    if (prevProps.cornerRadius !== this.props.cornerRadius) {
      this.fire("cornerRadius", this);
    }

    if (prevProps.dense !== this.props.dense) {
      this.fire("dense", this);
    }
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setActive(val: boolean): void {
    let isValChanged: boolean = this.active !== val;

    if (!isValChanged) {
      return;
    }

    this.active = val;

    this.fire("active", this);
  }
  public setNullValue(val: number): void {
    let isValChanged: boolean = this.nullValue !== val;

    if (!isValChanged) {
      return;
    }

    this.nullValue = val;

    this.fire("nullValue", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: cStyle.tColumnFieldStyleCrossAxisAlignmentOn,
      children: [
        this.name !== null && this.name.isNotEmpty
          ? ui.Row({
              children: [
                TextView({
                  data: this.name,
                  style: new ui.TextStyle({
                    color: cStyle.tTextViewLabelTextColorOn,
                    fontSize: cStyle.tTextViewLabelTextFontSizeOn,
                    fontWeight: cStyle.tTextViewLabelTextFontWeightOn,
                  }),
                  className: "xe3c1",
                  key: "0",
                }),
                this.isRequired
                  ? TextView({
                      data: "*",
                      style: new ui.TextStyle({
                        color: new ui.Color(0xffc20f2f),
                        fontSize: cStyle.tTextViewLabelTextFontSizeOn,
                        fontWeight: cStyle.tTextViewLabelTextFontWeightOn,
                      }),
                      className: "x278",
                    })
                  : [],
              ],
              className: "x4dd",
            })
          : [],
        ui.InputField({
          value: this.value !== this.nullValue ? this.value.toString() : "",
          focusNode: this.focusNode,
          placeHolder: this.placeHolder,
          disable: this.disable,
          inputFormatter: "[0-9]*",
          dense: this.dense,
          padding: ui.EdgeInsets.symmetric({
            horizontal: 10.0,
            vertical: 0.0,
            transitions: new Map(),
          }),
          keyboardType: ui.TextInputType.number,
          cornerRadius: this.cornerRadius,
          backgroundColor: this.disable
            ? new ui.Color(0xffb4b4b4)
            : this.fieldColor === null
            ? new ui.Color(0xffffffff)
            : cStyle.tInputFieldDefaultInputFieldBackgroundColorOn,
          activeColor: cStyle.tInputFieldDefaultInputFieldActiveColorOn,
          style: cStyle.tInputFieldDefaultInputFieldStyleOn,
          placeHolderColor:
            cStyle.tInputFieldDefaultInputFieldPlaceHolderColorOn,
          inActiveColor: this.errors.isNotEmpty
            ? new ui.Color(0xffd2d2d2)
            : cStyle.tInputFieldDefaultInputFieldInActiveColorOn,
          controller: this.fieldController,
          onChanged: (text) => {
            this.onChangeText(text);
          },
          onSubmitted: (text) => {
            this.onSubmiitedTextHandler(text);
          },
          onTap: () => {
            this.onTapHandker();
          },
          onFocusChange: (val) => {},
          states: ui.joinStates({ "data-c2": this.errors.isNotEmpty }, {}),
          className: "xd13",
          key: "1",
        }),
        this.errors.isNotEmpty
          ? ui.Column({
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                this.errors.expand((item) => [
                  TextView({
                    data: item,
                    textAlign: cStyle.tTextViewErrorTextTextAlignOn,
                    style: new ui.TextStyle({
                      color: cStyle.tTextViewErrorTextColorOn,
                    }),
                    className: "xd16",
                    key: item?.toString(),
                  }),
                ]),
              ],
              className: "x97a",
            })
          : [],
      ],
      className: ui.join(
        this.props.className,
        "LabelWithIntegerInputField xd4b"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setNullValue(null);

    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.onChangeFocus);
  };
  public onChangeFocus = (): void => {
    this.setActive(this.focusNode.hasFocus);

    if (this.active && this.onTap !== null) {
      this.onTap();
    }
  };
  public onChangeText = (text: string): void => {
    if (this.onChanged !== null) {
      this.onChanged(Number.tryParseInt(text));
    }
  };
  public onTapHandker = (): void => {
    this.errors.clear();

    if (this.onTap !== null) {
      this.onTap();
    }
  };
  public onSubmiitedTextHandler = (text: string): void => {
    if (this.onSubmitted !== null) {
      this.onSubmitted(Number.tryParseInt(text));
    }
  };
  public get onChanged(): _LabelWithIntegerInputFieldOnChanged {
    return this.props.onChanged;
  }
  public get onTap(): _LabelWithIntegerInputFieldOnTap {
    return this.props.onTap;
  }
  public get onSubmitted(): _LabelWithIntegerInputFieldOnSubmitted {
    return this.props.onSubmitted;
  }
}
export default function LabelWithIntegerInputField(
  props: LabelWithIntegerInputFieldProps
) {
  return React.createElement(
    _LabelWithIntegerInputFieldState,
    { ..._LabelWithIntegerInputFieldState.defaultProps, ...props },
    ListWrapper.fromInput<string>(props.errors, "errors")
  );
}
