import SettingsPage from "../components/SettingsPage";
import LoginPage from "../components/LoginPage";
import LeadInformationPage from "../components/LeadInformationPage";
import LeadOverviewPage from "../components/LeadOverviewPage";
import GlobalFunctions from "./GlobalFunctions";
import DashboardPage from "../components/DashboardPage";
import RoutePage from "../components/RoutePage";
import UndoManager from "./UndoManager";
import ConnectionsPage from "../components/ConnectionsPage";
import MailsPage from "../components/MailsPage";
import NewTeamPage from "../components/NewTeamPage";
import LeadMailsPage from "../components/LeadMailsPage";
import ChangePassWordPage from "../components/ChangePassWordPage";
import SalesDashboardPage from "../components/SalesDashboardPage";
import TeamOverviewPage from "../components/TeamOverviewPage";
import ForgotPasswordPage from "../components/ForgotPasswordPage";
import ApolloLeadsPage from "../components/ApolloLeadsPage";
import LeadManagementPage from "../components/LeadManagementPage";
import LandingPage from "../components/LandingPage";

export default class AllPagesUtil {
  public static load(): void {
    GlobalFunctions.ApolloLeadsPage = ApolloLeadsPage;

    GlobalFunctions.ChangePassWordPage = ChangePassWordPage;

    GlobalFunctions.ConnectionsPage = ConnectionsPage;

    GlobalFunctions.DashboardPage = DashboardPage;

    GlobalFunctions.ForgotPasswordPage = ForgotPasswordPage;

    GlobalFunctions.LandingPage = LandingPage;

    GlobalFunctions.LeadInformationPage = LeadInformationPage;

    GlobalFunctions.LeadMailsPage = LeadMailsPage;

    GlobalFunctions.LeadManagementPage = LeadManagementPage;

    GlobalFunctions.LeadOverviewPage = LeadOverviewPage;

    GlobalFunctions.LoginPage = LoginPage;

    GlobalFunctions.MailsPage = MailsPage;

    GlobalFunctions.NewTeamPage = NewTeamPage;

    GlobalFunctions.RoutePage = RoutePage;

    GlobalFunctions.SalesDashboardPage = SalesDashboardPage;

    GlobalFunctions.SettingsPage = SettingsPage;

    GlobalFunctions.TeamOverviewPage = TeamOverviewPage;

    UndoManager.load();
  }
}
