import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallData from "../classes/CallData";
import D3EDisposable from "../rocket/D3EDisposable";
import Popup from "./Popup";
import EventBus from "../utils/EventBus";
import MaterialIcons from "../icons/MaterialIcons";
import Timer from "../utils/Timer";
import IconButton from "./IconButton";
import CallInteraction from "../classes/CallInteraction";
import LeadAssignPopupView from "./LeadAssignPopupView";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import CallStatus from "../classes/CallStatus";
import Button from "./Button";
import TwilioClient from "../classes/TwilioClient";
import Duration from "../core/Duration";
import DateTime from "../core/DateTime";
import TextView from "./TextView";
import FailureMessage from "../classes/FailureMessage";
import Interaction from "../models/Interaction";
import IconView from "./IconView";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";
import ToolTipWrapper from "./ToolTipWrapper";

type _CallProgressViewOnCloseView = () => void;

type _CallProgressViewOnMinimizeView = () => void;

type _AssignToLeadButtonOnPressed = (d3eState: CallProgressViewRefs) => void;

export interface CallProgressViewProps extends BaseUIProps {
  key?: string;
  client?: TwilioClient;
  callInteraction?: CallInteraction;
  interaction: Interaction;
  onCloseView: _CallProgressViewOnCloseView;
  onMinimizeView?: _CallProgressViewOnMinimizeView;
}
/// To store state data for CallProgressView
class CallProgressViewRefs {
  public assignToLeadButton: AssignToLeadButtonState =
    new AssignToLeadButtonState();
}

interface AssignToLeadButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: CallProgressViewRefs;
  _onAssignToLeadPressed?: _AssignToLeadButtonOnPressed;
}

class AssignToLeadButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _AssignToLeadButtonWithState extends ObservableComponent<AssignToLeadButtonWithStateProps> {
  assignToLeadButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AssignToLeadButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get assignToLeadButton(): AssignToLeadButtonState {
    return this.props.d3eState.assignToLeadButton;
  }
  public get d3eState(): CallProgressViewRefs {
    return this.props.d3eState;
  }
  public get _onAssignToLeadPressed(): _AssignToLeadButtonOnPressed {
    return this.props._onAssignToLeadPressed;
  }
  public initState() {
    super.initState();

    this.updateObservable("assignToLeadButton", null, this.assignToLeadButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["assignToLeadButton", "assignToLeadButton.", "assignToLeadButton.hover"],
      this.rebuild
    );
  }
  public assignToLeadButtonOnEnter(event): void {
    return this.assignToLeadButton.setHover(true);
  }
  public assignToLeadButtonOnExit(event): void {
    return this.assignToLeadButton.setHover(false);
  }
  public dispose(): void {
    this.assignToLeadButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      margin: ui.EdgeInsets.fromLTRB(5.0, 0.0, 0.0, 0.0, new Map()),
      child: Button({
        disable: this.assignToLeadButton.disable,
        onPressed: () => {
          this._onAssignToLeadPressed(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({
          data: "Assign to Lead",
          style: new ui.TextStyle({ color: cStyle.c1 }),
          className: "x81",
        }),
        onEnter: (event) => {
          this.assignToLeadButtonOnEnter(event);
        },
        onExit: (event) => {
          this.assignToLeadButtonOnExit(event);
        },
      }),
      className: "x0d5",
    });
  }
}
function AssignToLeadButtonWithState(props: AssignToLeadButtonWithStateProps) {
  return React.createElement(_AssignToLeadButtonWithState, props);
}

class _CallProgressViewState extends ObservableComponent<CallProgressViewProps> {
  static defaultProps = {
    client: null,
    callInteraction: null,
    interaction: null,
    onCloseView: null,
    onMinimizeView: null,
  };
  d3eState: CallProgressViewRefs = new CallProgressViewRefs();
  isMute: boolean = false;
  callDuration: string = "";
  data: CallData = null;
  status: CallStatus = CallStatus.Initiated;
  durationStart: boolean = false;
  leadAssignPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public interactionDisposable: D3EDisposable;
  public constructor(props: CallProgressViewProps) {
    super(props);

    this.initState();
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get callInteraction(): CallInteraction {
    return this.props.callInteraction;
  }
  public get interaction(): Interaction {
    return this.props.interaction;
  }
  public initState() {
    super.initState();

    this.interactionDisposable = MessageDispatch.get().syncObject(
      this.props.interaction,
      UsageConstants.SUBSCRIPTION_ONINTERACTIONCHANGE_CALLPROGRESSVIEW_INTERACTION_SYNCHRONISE
    );

    this.runFetchDataQueryForInteraction();

    this.interactionDisposable = MessageDispatch.get().syncObject(
      this.props.interaction,
      UsageConstants.QUERY_GETINTERACTIONBYID_CALLPROGRESSVIEW_INTERACTION_FETCHDATA
    );

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("callInteraction", this.props.callInteraction);

    this.updateSyncProperty("interaction", this.props.interaction);

    this.on(["callInteraction", "callInteraction.data"], this.computeData);

    this.computeData();

    this.on(
      [
        "callInteraction",
        "callInteraction.data",
        "callInteraction.data.callStatus",
      ],
      this.computeStatus
    );

    this.computeStatus();

    this.on(
      [
        "callDuration",
        "callInteraction",
        "callInteraction.lead",
        "callInteraction.lead.name",
        "client",
        "interaction",
        "interaction.callStatus",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: CallProgressViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.callInteraction !== this.props.callInteraction) {
      this.updateObservable(
        "callInteraction",
        prevProps.callInteraction,
        this.props.callInteraction
      );

      this.fire("callInteraction", this);
    }

    if (prevProps.interaction !== this.props.interaction) {
      this.updateObservable(
        "interaction",
        prevProps.interaction,
        this.props.interaction
      );

      this.runFetchDataQueryForInteraction();

      this.fire("interaction", this);
    }
  }
  public runFetchDataQueryForInteraction = (): void => {
    if (this.interaction == null) {
      return;
    }

    Query.get().getInteractionById(
      UsageConstants.QUERY_GETINTERACTIONBYID_CALLPROGRESSVIEW_INTERACTION_FETCHDATA,
      this.interaction.id
    );
  };
  public setIsMute(val: boolean): void {
    let isValChanged: boolean = this.isMute !== val;

    if (!isValChanged) {
      return;
    }

    this.isMute = val;

    this.fire("isMute", this);
  }
  public setCallDuration(val: string): void {
    let isValChanged: boolean = this.callDuration !== val;

    if (!isValChanged) {
      return;
    }

    this.callDuration = val;

    this.fire("callDuration", this);
  }
  public setData(val: CallData): void {
    let isValChanged: boolean = this.data !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("data", this.data, val);

    this.data = val;

    this.fire("data", this);
  }
  public computeData = (): void => {
    try {
      this.setData(this.callInteraction.data);
    } catch (exception) {
      console.log(" exception in computeData : " + exception.toString());

      this.setData(null);
    }
  };
  public setStatus(val: CallStatus): void {
    let isValChanged: boolean = this.status !== val;

    if (!isValChanged) {
      return;
    }

    this.status = val;

    this.onChangeCallId();

    this.fire("status", this);
  }
  public computeStatus = (): void => {
    try {
      this.setStatus(this.callInteraction.data.callStatus);
    } catch (exception) {
      console.log(" exception in computeStatus : " + exception.toString());

      this.setStatus(CallStatus.Initiated);
    }
  };
  public setDurationStart(val: boolean): void {
    let isValChanged: boolean = this.durationStart !== val;

    if (!isValChanged) {
      return;
    }

    this.durationStart = val;

    this.fire("durationStart", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            ui.Row({
              children: [
                TextView({
                  data:
                    this.callInteraction.lead === null
                      ? "Unknown Caller"
                      : this.callInteraction.lead.name,
                  style: new ui.TextStyle({
                    fontSize: 16,
                    color: cStyle.c14,
                    fontWeight: ui.FontWeight.bold,
                  }),
                  className: "x0a3",
                  key: "0",
                }),
                TextView({
                  data: this.callDuration,
                  style: new ui.TextStyle({
                    fontWeight: ui.FontWeight.w400,
                    color: cStyle.c14,
                  }),
                  className: "xc02",
                  key: "1",
                }),
                this.callInteraction.lead === null
                  ? AssignToLeadButtonWithState({
                      d3eState: this.d3eState,
                      _onAssignToLeadPressed: this.onAssignToLeadPressed,
                    })
                  : [],
              ],
              className: "xab",
              key: "0",
            }),
            IconButton({
              icon: MaterialIcons.minimize,
              color: cStyle.c14,
              onPressed: () => {
                this.onMinimizePressed(this.d3eState);
              },
              key: "1",
            }),
          ],
          className: "x7e8 hc h",
          key: "0",
        }),
        ui.Row({
          children: [ui.Container({ className: "x8ce hc vc h", key: "0" })],
          className: "x0d6 hc h",
          key: "1",
        }),
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.center,
          children: [
            ui.Container({
              alignment: ui.Alignment.center,
              child: ui.Container({
                alignment: ui.Alignment.center,
                child: ui.Center({
                  child: TextView({
                    data:
                      this.callInteraction.lead === null ||
                      this.callInteraction.lead.name === null ||
                      this.callInteraction.lead.name.trim().length < 1
                        ? "UN"
                        : this.callInteraction.lead.name[0] +
                          this.callInteraction.lead.name[1],
                    textAlign: ui.TextAlign.center,
                    style: new ui.TextStyle({
                      color: new ui.Color(0xff4caca4),
                      fontWeight: ui.FontWeight.bold,
                      fontSize: 35,
                    }),
                    className: "xf8d hc vc",
                  }),
                  className: "hc vc",
                }),
                className: "x1f hc vc",
              }),
              className: "x18 hc vc",
              key: "0",
            }),
            TextView({
              data:
                this.interaction === null
                  ? "Initiating..."
                  : this.interaction.callStatus.name,
              states: ui.joinStates(
                { "data-c0": this.interaction === null },
                {}
              ),
              style: new ui.TextStyle({
                color: this.interaction === null ? cStyle.c1 : cStyle.c14,
              }),
              className: "xb26 hc",
              key: "1",
            }),
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.center,
              children: [
                ui.Container({
                  alignment: ui.Alignment.center,
                  child: ToolTipWrapper({
                    message: "Mute",
                    backgroundColor: cStyle.tooltipBackgroundColor,
                    textColor: cStyle.tooltipTextColor,
                    child: IconView({
                      icon: this.client.isMuted
                        ? MaterialIcons.mic_off
                        : MaterialIcons.mic,
                      color: cStyle.c14,
                      className: "x64c hc vc",
                    }),
                  }),
                  onTap: (e) => {
                    e.stopPropagation();

                    this.onVoiceMutePressed(this.d3eState);
                  },
                  className: "xd62 hc vc",
                  key: "0",
                }),
                ui.Container({ className: "xdea hc", key: "1" }),
                ui.Container({
                  alignment: ui.Alignment.center,
                  child: ToolTipWrapper({
                    message: "Hangup",
                    backgroundColor: cStyle.tooltipBackgroundColor,
                    textColor: cStyle.tooltipTextColor,
                    child: IconView({
                      icon: MaterialIcons.call_end,
                      color: cStyle.c14,
                      className: "xa59 hc vc",
                    }),
                  }),
                  onTap: (e) => {
                    e.stopPropagation();

                    this.onDisconnectPressed(this.d3eState);
                  },
                  className: "xce8 hc vc",
                  key: "2",
                }),
              ],
              className: "xb23 hc h",
              key: "2",
            }),
          ],
          className: "x17f hc vc h",
          key: "2",
        }),
      ],
      className: ui.join(this.props.className, "CallProgressView xff6 hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setCallDuration("");

    this.setDurationStart(false);
  };
  public onVoiceMutePressed = async (
    d3eState: CallProgressViewRefs
  ): Promise<void> => {
    if (this.client.isMuted) {
      let unmute: boolean = await this.client.unmuteCall({
        onSuccess: (e) => {
          this.setIsMute(false);
        },
        onFailure: (e, s) => {
          EventBus.get().fire(new FailureMessage({ message: e }));
        },
      });
    } else {
      let mute: boolean = await this.client.muteCall({
        onSuccess: (e) => {
          this.setIsMute(true);
        },
        onFailure: (e, s) => {
          EventBus.get().fire(new FailureMessage({ message: e }));
        },
      });
    }
  };
  public onDisconnectPressed = async (
    d3eState: CallProgressViewRefs
  ): Promise<void> => {
    let disconnect: boolean = await this.client.disconnectCall({
      onFailure: (e) => {
        this.onCallDisconnectError(e);
      },
    });

    this.onCloseView();
  };
  public onChangeCallId = (): void => {
    if (
      this.data !== null &&
      (this.data.callSid === null || this.data.callSid.isEmpty)
    ) {
      this.onCloseView();
    }

    if (
      this.data !== null &&
      this.data.callStatus === CallStatus.InProgress &&
      !this.durationStart
    ) {
      this.setDurationStart(true);

      if (this.callInteraction.interaction.startTime === null) {
        this.callInteraction.interaction.setStartTime(DateTime.now());
      }

      let startTime: DateTime = this.callInteraction.interaction.startTime;

      let tmr: Timer = Timer.periodic(new Duration({ seconds: 1 }), (timer) => {
        //  Calculate the duration

        let duration: Duration = DateTime.now().difference(startTime);

        let totalInSeconds: number = duration.inSeconds.toDouble();

        //  Calculate hours, minutes, and seconds

        let hours: number = totalInSeconds / 3600;

        hours = hours.floorToDouble();

        let minutes: number = totalInSeconds / 60;

        minutes = minutes.floorToDouble();

        let seconds: number = totalInSeconds % 60;

        //  Convert minutes and seconds to strings

        let minutesStr: string = minutes.toStringAsFixed(0);

        let secondsStr: string = seconds.toStringAsFixed(0);

        //  Manually add leading zero if needed

        if (minutesStr.length === 1) {
          minutesStr = "0" + minutesStr;
        }

        if (secondsStr.length === 1) {
          secondsStr = "0" + secondsStr;
        }

        //  Format the call duration string

        if (hours >= 1.0) {
          //  Show hours if the duration is 1 hour or more

          this.setCallDuration(
            hours.toString() + ":" + minutesStr + ":" + secondsStr
          );
        } else {
          //  Show only minutes and seconds

          this.setCallDuration(minutesStr + ":" + secondsStr);
        }
      });
    }
  };
  public onCallDisconnectError = (error: string): void => {
    EventBus.get().fire(new FailureMessage({ message: error }));
  };
  public onMinimizePressed = (d3eState: CallProgressViewRefs): void => {
    this.onMinimizeView();
  };
  public onAssignToLeadPressed = (d3eState: CallProgressViewRefs): void => {
    this.showLeadAssignPopup();
  };
  public get onCloseView(): _CallProgressViewOnCloseView {
    return this.props.onCloseView;
  }
  public get onMinimizeView(): _CallProgressViewOnMinimizeView {
    return this.props.onMinimizeView;
  }
  public dispose(): void {
    this.interactionDisposable?.dispose();

    MessageDispatch.get().dispose(this.callInteraction);

    this.leadAssignPopupPopup?.dispose();

    super.dispose();
  }
  public showLeadAssignPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.leadAssignPopupPopup?.dispose();

    this.leadAssignPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: LeadAssignPopupView({
        interaction: this.callInteraction.interaction,
        className: "hc vc",
      }),
    });

    this.leadAssignPopupPopup.showPopup(this.context);
  }
  public hideLeadAssignPopup(): void {
    this.leadAssignPopupPopup?.dispose();
  }
  public get assignToLeadButton() {
    return this.d3eState.assignToLeadButton;
  }
}
export default function CallProgressView(props: CallProgressViewProps) {
  return React.createElement(_CallProgressViewState, {
    ..._CallProgressViewState.defaultProps,
    ...props,
  });
}
