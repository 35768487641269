import ChartTheme from "./ChartTheme";
import * as ui from "../native";

export default class ChartThemeData {
  public static fusion: ChartThemeData = new ChartThemeData({
    dataColors: [
      ui.HexColor.fromHexInt(0xff5d62b5),
      ui.HexColor.fromHexInt(0xff29c3be),
      ui.HexColor.fromHexInt(0xfff2726f),
      ui.HexColor.fromHexInt(0xffffda7e),
      ui.HexColor.fromHexInt(0xff62b58f),
      ui.HexColor.fromHexInt(0xffbc95df),
      ui.HexColor.fromHexInt(0xff67cdf2),
    ],
    stepColor: ui.HexColor.fromHexInt(0xffd9d5d4),
    labelColor: ui.HexColor.fromHexInt(0xff5a5a5a),
  });
  public readonly dataColors: Array<ui.Color>;
  public readonly stepColor: ui.Color;
  public readonly labelColor: ui.Color;
  public constructor(
    d3eParams?: Partial<{
      dataColors: Array<ui.Color>;
      stepColor: ui.Color;
      labelColor: ui.Color;
    }>
  ) {
    this.dataColors = d3eParams?.dataColors;

    this.stepColor = d3eParams?.stepColor;

    this.labelColor = d3eParams?.labelColor;
  }
  public static getData(theme: ChartTheme): ChartThemeData {
    switch (theme) {
      case ChartTheme.Fusion: {
        return ChartThemeData.fusion;
      }
      default: {
        return ChartThemeData.fusion;
      }
    }
  }
  public dataColor(index: number = 1): ui.Color {
    index = index % this.dataColors.length;

    return this.dataColors[index];
  }
}
