import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";
import Html from "./Html";
import LeadUtils from "../classes/LeadUtils";
import MailMessage from "../models/MailMessage";
import DateFormat from "../classes/DateFormat";
import { BuildContext } from "../classes/BuildContext";

export interface MailPreviewViewProps extends BaseUIProps {
  key?: string;
  message: MailMessage;
}

class _MailPreviewViewState extends ObservableComponent<MailPreviewViewProps> {
  static defaultProps = { message: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: MailPreviewViewProps) {
    super(props);

    this.initState();
  }
  public get message(): MailMessage {
    return this.props.message;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("message", this.props.message);

    this.on(
      [
        "message",
        "message.content",
        "message.inbound",
        "message.receivedDateTime",
        "message.recipientEmail",
        "message.recipientName",
        "message.senderName",
        "message.sentDateTime",
        "message.subject",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: MailPreviewViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.message !== this.props.message) {
      this.updateObservable("message", prevProps.message, this.props.message);

      this.fire("message", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      mainAxisAlignment: ui.MainAxisAlignment.start,
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        TextView({
          data: this.message.subject,
          style: new ui.TextStyle({ fontWeight: ui.FontWeight.bold }),
          className: "x8c4 hc",
          key: "0",
        }),
        ui.Column({
          mainAxisAlignment: ui.MainAxisAlignment.start,
          crossAxisAlignment: ui.CrossAxisAlignment.start,
          children: [
            ui.Row({
              mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
              crossAxisAlignment: ui.CrossAxisAlignment.start,
              children: [
                ui.Row({
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  children: [
                    ui.Container({
                      decoration: new ui.BoxDecoration({
                        color: ui.HexColor.fromHexStr(
                          LeadUtils.getColorForFirstLetter(
                            this.message.senderName !== null
                              ? this.message.senderName
                              : this.message.recipientName
                          )
                        ),
                        borderRadius: ui.BorderRadius.circular(20.0),
                      }),
                      alignment: ui.Alignment.center,
                      child: TextView({
                        data:
                          this.message.senderName === null
                            ? this.message.recipientName.substring(0, 1)
                            : this.message.senderName.substring(0, 1),
                        textAlign: ui.TextAlign.center,
                        style: new ui.TextStyle({
                          color: cStyle.c14,
                          fontWeight: ui.FontWeight.bold,
                        }),
                        className: "x7af hc vc",
                      }),
                      className: "x0439 hc vc",
                      key: "0",
                    }),
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      mainAxisAlignment: ui.MainAxisAlignment.start,
                      children: [
                        TextView({
                          data: this.message.senderName,
                          className: "x768",
                          key: "0",
                        }),
                        TextView({
                          data: this.message.inbound
                            ? "To : " + this.message.recipientEmail
                            : "To : " + this.message.recipientEmail,
                          className: "xe09",
                          key: "1",
                        }),
                      ],
                      key: "1",
                    }),
                  ],
                  key: "0",
                }),
                ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  children: [
                    TextView({
                      data:
                        this.message.sentDateTime === null
                          ? new DateFormat("EEE, dd MMM y HH:mm").format(
                              this.message.receivedDateTime
                            )
                          : new DateFormat("EEE, dd MMM y HH:mm").format(
                              this.message.sentDateTime
                            ),
                      className: "x3fc",
                      key: "0",
                    }),
                  ],
                  key: "1",
                }),
              ],
              className: "x3d9 hc h",
              key: "0",
            }),
            ui.Container({
              child: Html({ data: this.message.content, className: "hc" }),
              className: "xe50 hc",
              key: "1",
            }),
          ],
          className: "x169 hc vc h v",
          key: "1",
        }),
      ],
      className: ui.join(
        this.props.className,
        "MailPreviewView x96d hc vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function MailPreviewView(props: MailPreviewViewProps) {
  return React.createElement(_MailPreviewViewState, {
    ..._MailPreviewViewState.defaultProps,
    ...props,
  });
}
