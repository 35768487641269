import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import TextView from "./TextView";

export interface CellTitleProps extends BaseUIProps {
  key?: string;
  data: string;
  textAlign?: ui.TextAlign;
}

class _CellTitleState extends ObservableComponent<CellTitleProps> {
  static defaultProps = { data: "", textAlign: ui.TextAlign.left };
  public constructor(props: CellTitleProps) {
    super(props);

    this.initState();
  }
  public get data(): string {
    return this.props.data;
  }
  public get textAlign(): ui.TextAlign {
    return this.props.textAlign;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["data", "textAlign"], this.rebuild);
  }
  public componentDidUpdate(prevProps: CellTitleProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.data !== this.props.data) {
      this.fire("data", this);
    }

    if (prevProps.textAlign !== this.props.textAlign) {
      this.fire("textAlign", this);
    }
  }
  public render(): ReactNode {
    return TextView({
      data: this.data,
      textAlign: this.textAlign === null ? ui.TextAlign.center : this.textAlign,
      softWrap: true,
      style: new ui.TextStyle({ fontWeight: ui.FontWeight.w600 }),
      className: ui.join(this.props.className, "CellTitle xcdd"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function CellTitle(props: CellTitleProps) {
  return React.createElement(_CellTitleState, {
    ..._CellTitleState.defaultProps,
    ...props,
  });
}
