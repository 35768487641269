import DateTime from "../core/DateTime";
import DateFormat from "./DateFormat";
import D3EDate from "./D3EDate";

export default class CalenderUtils {
  public constructor() {}
  public static getMonthNumber(month: string): number {
    switch (month) {
      case "January": {
        return 1;
      }

      case "February": {
        return 2;
      }

      case "March": {
        return 3;
      }

      case "April": {
        return 4;
      }

      case "May": {
        return 5;
      }

      case "June": {
        return 6;
      }

      case "July": {
        return 7;
      }

      case "August": {
        return 8;
      }

      case "September": {
        return 9;
      }

      case "October": {
        return 10;
      }

      case "November": {
        return 11;
      }

      case "December": {
        return 12;
      }
      default: {
      }
    }

    return 0;
  }
  public static getMonthName(month: number): string {
    switch (month) {
      case 1: {
        return "January";
      }

      case 2: {
        return "February";
      }

      case 3: {
        return "March";
      }

      case 4: {
        return "April";
      }

      case 5: {
        return "May";
      }

      case 6: {
        return "June";
      }

      case 7: {
        return "July";
      }

      case 8: {
        return "August";
      }

      case 9: {
        return "September";
      }

      case 10: {
        return "October";
      }

      case 11: {
        return "November";
      }

      case 12: {
        return "December";
      }
      default: {
      }
    }

    return "";
  }
  public static prepareCalenderData(date: D3EDate): Array<D3EDate> {
    let firstDay: DateTime = new DateTime(date.year, date.month, 1);

    let weekDay: number = firstDay.weekday % 7;

    let firstDayDate: D3EDate = D3EDate.of(
      firstDay.year,
      firstDay.month,
      firstDay.day
    );

    return CalenderUtils.getListOfDates(firstDayDate, -weekDay, 42 - weekDay);
  }
  public static getListOfDates(
    firstDay: D3EDate,
    statsWith: number,
    endsWith: number
  ): Array<D3EDate> {
    let list_of_dates: Array<D3EDate> = [];

    for (let i: number = statsWith; i < endsWith; i++) {
      let day: D3EDate = firstDay.plusDays(i);

      list_of_dates.add(day);
    }

    return list_of_dates;
  }
  public static getYearsList(year: number, forward: boolean): Array<number> {
    let list_of_years: Array<number> = [];

    list_of_years.add(year);

    if (forward) {
      for (let i: number = 1; i < 12; i++) {
        list_of_years.add(year + i);
      }
    } else {
      for (let i: number = 1; i < 12; i++) {
        list_of_years.insert(0, year - i);
      }
    }

    return list_of_years;
  }
  public static getFormatedDate(date: D3EDate, format: string): string {
    if (D3EDate.equals(date, null)) {
      return "";
    }

    let date1: D3EDate = date !== null ? date : D3EDate.now();

    if (format !== null && format.isNotEmpty) {
      let formatter: DateFormat = new DateFormat(format);

      let formatedDate: string = formatter.format(date1.toDateTime());

      if (formatedDate !== null) {
        return formatedDate;
      } else {
        return date1.toString();
      }
    }

    return date1.toString();
  }
}
