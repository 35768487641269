import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ResultStatus from "../classes/ResultStatus";
import Lead from "../models/Lead";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import Result from "../classes/Result";
import IconView from "./IconView";
import { BuildContext } from "../classes/BuildContext";

type _ModuleNameOnChanged = (text: string) => void;

export interface LeadNeedsAndPainsEditViewProps extends BaseUIProps {
  key?: string;
  needsAndPainsItem: string;
  needItem: boolean;
  lead: Lead;
}

class _LeadNeedsAndPainsEditViewState extends ObservableComponent<LeadNeedsAndPainsEditViewProps> {
  static defaultProps = { needsAndPainsItem: "", needItem: false, lead: null };
  moduleNameController: ui.TextEditingController =
    new ui.TextEditingController();
  focusNode: ui.FocusNode = null;
  newName: string = "";
  savedName: string = "";
  isNeedOrPainEditing: boolean = false;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LeadNeedsAndPainsEditViewProps) {
    super(props);

    this.initState();
  }
  public get needsAndPainsItem(): string {
    return this.props.needsAndPainsItem;
  }
  public get needItem(): boolean {
    return this.props.needItem;
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.on(["focusNode", "isNeedOrPainEditing", "newName"], this.rebuild);
  }
  public componentDidUpdate(prevProps: LeadNeedsAndPainsEditViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.needsAndPainsItem !== this.props.needsAndPainsItem) {
      this.fire("needsAndPainsItem", this);
    }

    if (prevProps.needItem !== this.props.needItem) {
      this.fire("needItem", this);
    }

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }
  }
  public setFocusNode(val: ui.FocusNode): void {
    let isValChanged: boolean = this.focusNode !== val;

    if (!isValChanged) {
      return;
    }

    this.focusNode = val;

    this.fire("focusNode", this);
  }
  public setNewName(val: string): void {
    let isValChanged: boolean = this.newName !== val;

    if (!isValChanged) {
      return;
    }

    this.newName = val;

    this.fire("newName", this);
  }
  public setSavedName(val: string): void {
    let isValChanged: boolean = this.savedName !== val;

    if (!isValChanged) {
      return;
    }

    this.savedName = val;

    this.fire("savedName", this);
  }
  public setIsNeedOrPainEditing(val: boolean): void {
    let isValChanged: boolean = this.isNeedOrPainEditing !== val;

    if (!isValChanged) {
      return;
    }

    this.isNeedOrPainEditing = val;

    this.fire("isNeedOrPainEditing", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      mainAxisAlignment: ui.MainAxisAlignment.start,
      mainAxisSize: ui.MainAxisSize.min,
      children: [
        ui.Row({
          children: [
            IconView({
              size: 8,
              icon: MaterialIcons.fiber_manual_record,
              className: "x23a",
              key: "0",
            }),
            this.isNeedOrPainEditing
              ? ui.InputField({
                  focusNode: this.focusNode,
                  dense: true,
                  inActiveColor: new ui.Color(0xff0000),
                  value: this.newName,
                  activeColor: cStyle.c1,
                  maxLines: 5,
                  minLines: 3,
                  keyboardType: ui.TextInputType.multiline,
                  padding: ui.EdgeInsets.fromLTRB(
                    5.0,
                    0.0,
                    0.0,
                    0.0,
                    new Map()
                  ),
                  controller: this.moduleNameController,
                  onEditingComplete: () => {
                    this.onNameEditingComplete();
                  },
                  onChanged: (text) => {
                    this.moduleNameonChanged(text);
                  },
                  onFocusChange: (val) => {},
                  className: "xc96 hc h",
                })
              : TextView({
                  data: this.newName,
                  className: "x84",
                  onTap: (e) => {
                    e.stopPropagation();

                    this.onPressedEditNeedOrPain();
                  },
                }),
          ],
          className: "x0c6 h",
          key: "0",
        }),
      ],
      className: ui.join(
        this.props.className,
        "LeadNeedsAndPainsEditView xaff h"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setFocusNode(new ui.FocusNode());

    this.focusNode.addListener(this.focusHandler);

    this.setNewName(this.needsAndPainsItem);
  };
  public focusHandler = (): void => {
    if (!this.focusNode.hasFocus) {
      if (this.needsAndPainsItem !== this.newName) {
        if (
          this.newName.isNotEmpty &&
          this.newName.replaceAll(" ", "").length !== 0 &&
          this.savedName !== this.newName
        ) {
          this.onSessionChange(this.lead);
        } else {
          this.moduleNameController.text = this.needsAndPainsItem;
        }
      }
    }
  };
  public onNameEditingComplete = (): void => {
    if (this.newName !== null && this.newName.isNotEmpty) {
      let style: ui.TextStyle = new ui.TextStyle({ fontSize: 13.0 });

      let textPainter: ui.TextPainter = new ui.TextPainter({
        text: new ui.TextSpan({ text: this.newName, style: style }),
        textDirection: ui.TextDirection.ltr,
      });

      if (this.needItem) {
        let index: number = this.lead.needs.indexOf(this.needsAndPainsItem);

        if (index !== -1) {
          this.lead.needs.removeAt(index);

          this.lead.needs.insert(index, this.newName);
        }
      } else {
        let index: number = this.lead.pains.indexOf(this.needsAndPainsItem);

        if (index !== -1) {
          this.lead.pains.removeAt(index);

          this.lead.pains.insert(index, this.newName);
        }
      }

      this.onSessionChange(this.lead);
    }
  };
  public onSessionChange = async (obj: Lead): Promise<void> => {
    let result: Result<Lead> = await obj.save();

    if (result.status === ResultStatus.Success) {
      this.setSavedName(this.newName);

      this.setIsNeedOrPainEditing(false);
    }
  };
  public onPressedEditNeedOrPain = (): void => {
    this.setIsNeedOrPainEditing(true);
  };
  public moduleNameonChanged = (val: string): void => {
    this.setNewName(val);
  };
}
export default function LeadNeedsAndPainsEditView(
  props: LeadNeedsAndPainsEditViewProps
) {
  return React.createElement(_LeadNeedsAndPainsEditViewState, {
    ..._LeadNeedsAndPainsEditViewState.defaultProps,
    ...props,
  });
}
