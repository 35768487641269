import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import CallData from "../classes/CallData";
import TwilioClient from "../classes/TwilioClient";
import Lead from "../models/Lead";
import User from "../models/User";
import LeadOverviewWidget from "./LeadOverviewWidget";

export interface LeadOverviewPageProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  user: User;
  client: TwilioClient;
  data: CallData;
}

class _LeadOverviewPageState extends ObservableComponent<LeadOverviewPageProps> {
  static defaultProps = { lead: null, user: null, client: null, data: null };
  public constructor(props: LeadOverviewPageProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get user(): User {
    return this.props.user;
  }
  public get client(): TwilioClient {
    return this.props.client;
  }
  public get data(): CallData {
    return this.props.data;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.updateSyncProperty("user", this.props.user);

    this.updateSyncProperty("data", this.props.data);

    this.on(["client", "data", "lead", "user"], this.rebuild);
  }
  public componentDidUpdate(prevProps: LeadOverviewPageProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }

    if (prevProps.client !== this.props.client) {
      this.fire("client", this);
    }

    if (prevProps.data !== this.props.data) {
      this.updateObservable("data", prevProps.data, this.props.data);

      this.fire("data", this);
    }
  }
  public render(): ReactNode {
    return ui.Column({
      children: [
        ui.Row({
          children: [
            ui.Container({
              expand: true,
              child: LeadOverviewWidget({
                lead: this.lead,
                user: this.user,
                client: this.client,
                data: this.data,
              }),
              key: "0",
              className: "xef2d hc vc h v",
            }),
          ],
          className: "x8df hc vc h v",
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "LeadOverviewPage hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function LeadOverviewPage(props: LeadOverviewPageProps) {
  return React.createElement(_LeadOverviewPageState, {
    ..._LeadOverviewPageState.defaultProps,
    ...props,
  });
}
