import Duration from "../core/Duration";
import DateTime from "../core/DateTime";
import DateFormat from "./DateFormat";

export default class TimeZoneUtil {
  public constructor() {}
  public static allTimeZones(): Array<string> {
    let inputs: Array<string> = [
      "UTC(GMT+00:00) Default",
      "Etc/GMT+12(GMT-12:00) International Date Line West",
      "Etc/GMT+11(GMT-11:00) Coordinated Universal Time-11",
      "Pacific/Samoa(GMT-11:00)",
      "Pacific/Honolulu(GMT-10:00)",
      "Pacific/Marquesas(GMT-09:30)",
      "America/Anchorage(GMT-09:00)",
      "America/Tijuana(GMT-08:00)",
      "America/Los_Angeles(GMT-08:00)",
      "America/Chihuahua(GMT-07:00)",
      "America/Phoenix(GMT-07:00)",
      "America/Denver(GMT-07:00)",
      "America/Chicago(GMT-06:00)",
      "America/Guatemala(GMT-06:00)",
      "America/Mexico_City(GMT-06:00)",
      "America/Regina(GMT-06:00)",
      "America/Bogota(GMT-05:00)",
      "America/New_York(GMT-05:00)",
      "America/Indianapolis(GMT-05:00)",
      "America/Caracas(GMT-04:30)",
      "America/Halifax(GMT-04:00)",
      "America/Asuncion(GMT-04:00)",
      "America/Cuiaba(GMT-04:00)",
      "America/Santiago(GMT-04:00)",
      "America/La_Paz(GMT-04:00)",
      "America/St_Johns(GMT-03:30)",
      "America/Buenos_Aires(GMT-03:00)",
      "America/Sao_Paul(GMT-03:00)",
      "America/Cayenne(GMT-03:00)",
      "America/Montevideo(GMT-03:00)",
      "America/Godthab(GMT-03:00)",
      "Etc/GMT+2(GMT-02:00)",
      "Etc/GMT+2(GMT-02:00)",
      "Atlantic/Azores(GMT-01:00)",
      "Atlantic/Cape_Verde(GMT-01:00)",
      "Africa/Casablanca(GMT+00:00)",
      "Atlantic/Reykjavik(GMT+00:00)",
      "Europe/London(GMT+00:00)",
      "Europe/Warsaw(GMT+01:00)",
      "Africa/Lagos(GMT+01:00)",
      "Europe/Budapest(GMT+01:00)",
      "Europe/Paris(GMT+01:00)",
      "Europe/Berlin(GMT+01:00)",
      "Africa/Johannesburg(GMT+02:00)",
      "Asia/Damascus(GMT+02:00)",
      "Europe/Kiev(GMT+02:00)",
      "Africa/Windhoek(GMT+02:00)",
      "Europe/Minsk(GMT+02:00)",
      "Europe/Istanbu(GMT+02:00)",
      "Asia/Amman(GMT+02:00)",
      "Asia/Beirut(GMT+02:00)",
      "Asia/Jerusalem(GMT+02:00)",
      "Africa/Cairo(GMT+02:00)",
      "Asia/Riyadh(GMT+03:00)",
      "Europe/Moscow(GMT+03:00)",
      "Asia/Baghdad(GMT+03:00)",
      "Africa/Nairobi(GMT+03:00)",
      "Asia/Tehran(GMT+03:30)",
      "Indian/Mauritius(GMT+04:00)",
      "Asia/Tbilisi(GMT+04:00)",
      "Asia/Baku(GMT+04:00)",
      "Asia/Yerevan(GMT+04:00)",
      "Asia/Dubai(GMT+04:00)",
      "Asia/Kabul(GMT+04:30)",
      "Asia/Yekaterinburg(GMT+05:00)",
      "Asia/Karachi(GMT+05:00)",
      "Asia/Tashkent(GMT+05:00)",
      "Asia/Calcutta(GMT+05:30)",
      "Asia/Colombo(GMT+05:30)",
      "Asia/Katmandu(GMT+05:45)",
      "Asia/Dhaka(GMT+06:00)",
      "Asia/Novosibirsk(GMT+06:00)",
      "Asia/Almaty(GMT+06:00)",
      "Asia/Rangoon(GMT+06:30)",
      "Asia/Krasnoyarsk(GMT+07:00)",
      "Asia/Bangkok(GMT+07:00)",
      "Asia/Ulaanbaatar(GMT+08:00)",
      "Australia/Perth(GMT+08:00)",
      "Asia/Taipei(GMT+08:00)",
      "Asia/Singapore(GMT+08:00)",
      "Asia/Shanghai(GMT+08:00)",
      "Asia/Irkutsk(GMT+08:00)",
      "Asia/Pyongyang(GMT+08:30)",
      "Australia/Eucla(GMT+08:30)",
      "Asia/Seoul(GMT+09:00)",
      "Asia/Tokyo(GMT+09:00)",
      "Asia/Yakutsk(GMT+09:00)",
      "Australia/Darwin(GMT+09:30)",
      "Australia/Adelaide(GMT+09:30)",
      "Australia/Hobart(GMT+10:00)",
      "Asia/Vladivostok(GMT+10:00)",
      "Pacific/Port_Moresby(GMT+10:00)",
      "Australia/Brisbane(GMT+10:00)",
      "Australia/Sydney(GMT+10:00)",
      "Australia/LHI(GMT+10:30)",
      "Asia/Magadan(GMT+11:00)",
      "Pacific/Fiji(GMT+12:00)",
      "Asia/Kamchatka(GMT+12:00)",
      "Pacific/Auckland(GMT+12:00)",
      "Etc/GMT-12(GMT+12:00)",
      "Pacific/Chatham(GMT+12:45)",
      "Pacific/Enderbury(GMT+13:00)",
      "Pacific/Kiritimati(GMT+14:00)",
    ];

    return inputs;
  }
  public static chatMessageTimestamp(last: DateTime): string {
    let currentDate: DateTime = DateTime.now();

    let lastUsedDate: DateTime = last.toLocal();

    let diff: Duration = currentDate.difference(lastUsedDate);

    let message: string = "";

    if (diff.inDays >= 1) {
      if (diff.inDays > 365) {
        message = new DateFormat("dd MMM y").format(lastUsedDate);
      } else if (diff.inDays >= 30) {
        let month: number = (diff.inDays / 30).toInt();

        if (month === 1) {
          message = "lm";
        } else {
          message = month.round().toString() + "m";
        }
      } else {
        if (diff.inDays >= 7) {
          let week: number = (diff.inDays / 7).toInt();

          if (week === 1) {
            message = "lw";
          } else {
            message = "Staying from " + week.round().toString() + "w ago";
          }
        } else {
          if (diff.inDays.toInt() === 1) {
            message = "Yday";
          } else {
            message = diff.inDays.round().toString() + "d ago";
          }
        }
      }
    } else if (diff.inHours >= 1) {
      if (diff.inHours === 1) {
        message = "1h ago";
      } else {
        message = diff.inHours.round().toString() + "h ago";
      }
    } else if (diff.inMinutes >= 0) {
      if (diff.inMinutes === 1) {
        message = "1m ago";
      } else {
        message = diff.inMinutes.round().toString() + "m ago";
      }
    } else {
      message = diff.inSeconds.round().toString() + "s ago";
    }

    return message;
  }
}
