export default class ApolloServicesUtils {
  public constructor() {}
  public static allCountries(): Array<string> {
    let inputs: Array<string> = [
      "United States",
      "Americas",
      "North America",
      "Europe",
      "Germany",
      "California, US",
      "India",
      "United Kingdom",
      "Russia",
      "Greater New York City Area",
    ];

    return inputs;
  }
  public static allJobTitles(): Array<string> {
    let inputs: Array<string> = [
      "Manager",
      "Project Manager",
      "Teacher",
      "Owner",
      "Student",
      "Director",
      "Software Engineer",
      "Consultant",
      "Account Manager",
      "Engineer",
      "Sales Manager",
      "Professor",
      "Sales",
      "Partner",
      "Associate",
      "President",
      "Administartive assistant",
      "Supervisor",
      "General Manager",
      "Realtor",
    ];

    return inputs;
  }
  public static allTimeZones(): Array<string> {
    let inputs: Array<string> = [
      "GMT -12:00",
      "GMT -11:00",
      "GMT -10:00",
      "GMT -09:00",
      "GMT -08:00",
      "GMT -07:00",
      "GMT -06:00",
      "GMT -05:00",
      "GMT -04:00",
      "GMT -03:00",
      "GMT -02:00",
      "GMT -01:00",
      "GMT 00:00",
      "GMT +01:00",
      "GMT +02:00",
      "GMT +03:00",
      "GMT +04:00",
      "GMT +05:00",
      "GMT +05:30",
      "GMT +05:45",
      "GMT +06:00",
      "GMT +07:00",
      "GMT +08:00",
      "GMT +09:00",
      "GMT +10:00",
      "GMT +11:00",
      "GMT +12:00",
    ];

    return inputs;
  }
  public static getApolloEquivalentValues(
    timeZones: Array<string>
  ): Array<string> {
    let apolloAPIValues: Array<string> = [];

    for (let timeZone of timeZones) {
      if (timeZone === "GMT -12:00") {
        apolloAPIValues.add("-720");
      }

      if (timeZone === "GMT -11:00") {
        apolloAPIValues.add("-660");
      }

      if (timeZone === "GMT -10:00") {
        apolloAPIValues.add("-600");
      }

      if (timeZone === "GMT -09:00") {
        apolloAPIValues.add("-540");
      }

      if (timeZone === "GMT -08:00") {
        apolloAPIValues.add("-480");
      }

      if (timeZone === "GMT -07:00") {
        apolloAPIValues.add("-420");
      }

      if (timeZone === "GMT -06:00") {
        apolloAPIValues.add("-360");
      }

      if (timeZone === "GMT -05:00") {
        apolloAPIValues.add("-300");
      }

      if (timeZone === "GMT -04:00") {
        apolloAPIValues.add("-240");
      }

      if (timeZone === "GMT -03:00") {
        apolloAPIValues.add("-180");
      }

      if (timeZone === "GMT -02:00") {
        apolloAPIValues.add("-120");
      }

      if (timeZone === "GMT -01:00") {
        apolloAPIValues.add("-60");
      }

      if (timeZone === "GMT 00:00") {
        apolloAPIValues.add("0");
      }

      if (timeZone === "GMT +01:00") {
        apolloAPIValues.add("60");
      }

      if (timeZone === "GMT +02:00") {
        apolloAPIValues.add("120");
      }

      if (timeZone === "GMT +03:00") {
        apolloAPIValues.add("180");
      }

      if (timeZone === "GMT +04:00") {
        apolloAPIValues.add("240");
      }

      if (timeZone === "GMT +05:00") {
        apolloAPIValues.add("300");
      }

      if (timeZone === "GMT +05:30") {
        apolloAPIValues.add("330");
      }

      if (timeZone === "GMT +05:45") {
        apolloAPIValues.add("345");
      }

      if (timeZone === "GMT +06:00") {
        apolloAPIValues.add("360");
      }

      if (timeZone === "GMT +07:00") {
        apolloAPIValues.add("420");
      }

      if (timeZone === "GMT +08:00") {
        apolloAPIValues.add("480");
      }

      if (timeZone === "GMT +09:00") {
        apolloAPIValues.add("540");
      }

      if (timeZone === "GMT +10:00") {
        apolloAPIValues.add("600");
      }

      if (timeZone === "GMT +11:00") {
        apolloAPIValues.add("660");
      }

      if (timeZone === "GMT +12:00") {
        apolloAPIValues.add("720");
      }
    }

    return apolloAPIValues;
  }
  public static allLanguages(): Array<string> {
    let inputs: Array<string> = [
      "English",
      "German",
      "Spanish",
      "French",
      "Italian",
      "Portuguese",
      "Chinese",
      "Japanese",
      "Russian",
    ];

    return inputs;
  }
}
