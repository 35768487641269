import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaritalStatus from "../classes/MaritalStatus";
import Child from "../models/Child";
import FamilyInfo from "../models/FamilyInfo";
import TextValueField from "./TextValueField";
import Browser from "../classes/Browser";
import ScrollView2 from "./ScrollView2";
import Lead from "../models/Lead";
import TextValueCollectionView from "./TextValueCollectionView";
import TextView from "./TextView";
import TextValueWithButton from "./TextValueWithButton";
import Education from "../models/Education";
import Divider from "./Divider";
import { BuildContext } from "../classes/BuildContext";

export interface LeadInformationWidgetProps extends BaseUIProps {
  key?: string;
  lead: Lead;
  info: FamilyInfo;
}

class _LeadInformationWidgetState extends ObservableComponent<LeadInformationWidgetProps> {
  static defaultProps = { lead: null, info: null };
  columnScrollController: ui.ScrollController = new ui.ScrollController();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: LeadInformationWidgetProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public get info(): FamilyInfo {
    return this.props.info;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.updateSyncProperty("info", this.props.info);

    this.on(
      [
        "info",
        "info.annisversaryDate",
        "info.children",
        "info.children.ageGroup",
        "info.children.name",
        "info.familyIncome",
        "info.familyMembers",
        "info.marritalStatus",
        "info.spouseName",
        "lead",
        "lead.budget",
        "lead.company",
        "lead.companyRevenue",
        "lead.companySize",
        "lead.companyWebsite",
        "lead.createdDate",
        "lead.decisionMakingRole",
        "lead.educationHistory",
        "lead.educationHistory.degree",
        "lead.educationHistory.endDate",
        "lead.educationHistory.fieldOfStudy",
        "lead.educationHistory.institution",
        "lead.educationHistory.startDate",
        "lead.facebookProfile",
        "lead.industry",
        "lead.interests",
        "lead.languages",
        "lead.linkedInProfile",
        "lead.preferredContactMethod",
        "lead.previousPositions",
        "lead.skills",
        "lead.twitterHandle",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: LeadInformationWidgetProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }

    if (prevProps.info !== this.props.info) {
      this.updateObservable("info", prevProps.info, this.props.info);

      this.fire("info", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      mainAxisAlignment: ui.MainAxisAlignment.start,
      children: [
        ScrollView2({
          child: ui.Column({
            mainAxisAlignment: ui.MainAxisAlignment.start,
            crossAxisAlignment: ui.CrossAxisAlignment.start,
            children: [
              TextView({
                data: "Company Information",
                style: new ui.TextStyle({
                  fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                  fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                }),
                className: "x6d5",
                key: "0",
              }),
              TextValueField({
                value: this.lead.company === "" ? " --- " : this.lead.company,
                label: "Company",
                className: "x11f h",
                key: "1",
              }),
              TextValueField({
                label: "Industry Name",
                value: this.lead?.industry ?? "",
                className: "x64b h",
                key: "2",
              }),
              TextValueField({
                label: "Company Size",
                value: this.lead?.companySize ?? "",
                className: "x4f2 h",
                key: "3",
              }),
              TextValueField({
                label: "Revenue",
                value: this.lead?.companyRevenue ?? "",
                className: "x06d h",
                key: "4",
              }),
              TextValueWithButton({
                label: "Website",
                value: this.lead?.companyWebsite ?? "",
                onOpen: () => {
                  this.onWebsiteLabelButtonHandler();
                },
                className: "x2b7 h",
                key: "5",
              }),
              TextValueCollectionView({
                label: "Previous Positions",
                items: this.lead?.previousPositions ?? [],
                className: "xd35 h",
                key: "6",
              }),
              TextValueCollectionView({
                label: "Skills",
                items: this.lead.skills,
                className: "x51a h",
                key: "7",
              }),
              TextValueField({
                label: "Budget",
                value: this.lead?.budget.toString() ?? "",
                className: "xb60 h",
                key: "8",
              }),
              TextValueField({
                label: "Desicion Making Role",
                value: this.lead?.decisionMakingRole ?? "",
                className: "xe28 h",
                key: "9",
              }),
              TextValueField({
                label: "Prefered Contact Method",
                value: this.lead?.preferredContactMethod ?? "",
                className: "x0437 h",
                key: "10",
              }),
              Divider({ className: "x4e4 h", key: "11" }),
              TextView({
                data: "Family Information",
                style: new ui.TextStyle({
                  fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                  fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                }),
                className: "x407",
                key: "12",
              }),
              TextValueField({
                label: "Spouse Name",
                value: this.info?.spouseName ?? "",
                className: "x5ffd h",
                key: "13",
              }),
              TextValueField({
                label: "Marital Status",
                value: this.info?.marritalStatus?.name ?? "",
                className: "x649 h",
                key: "14",
              }),
              TextValueField({
                label: "Family Members",
                value: this.info?.familyMembers.toString() ?? "",
                className: "xff2 h",
                key: "15",
              }),
              TextValueField({
                label: "Family Income",
                value: this.info?.familyIncome.toString() ?? "",
                className: "xa48 h",
                key: "16",
              }),
              TextValueField({
                label: "Aniversary Date",
                value:
                  this.info !== null && this.info.annisversaryDate !== null
                    ? this.info.annisversaryDate.toString()
                    : "No Aniversary Date",
                className: "xae2 h",
                key: "17",
              }),
              this.info !== null && this.info.children.isNotEmpty
                ? ui.Row({
                    mainAxisAlignment: ui.MainAxisAlignment.start,
                    crossAxisAlignment: ui.CrossAxisAlignment.start,
                    children: [
                      TextView({
                        data: "Children",
                        className: "x74fd hc",
                        key: "0",
                      }),
                      ui.Row({
                        mainAxisAlignment: ui.MainAxisAlignment.start,
                        crossAxisAlignment: ui.CrossAxisAlignment.start,
                        children: [
                          TextView({ data: ":", className: "xdc46", key: "0" }),
                          this.info.children.expand((child) => [
                            ui.Row({
                              mainAxisAlignment: ui.MainAxisAlignment.start,
                              crossAxisAlignment: ui.CrossAxisAlignment.start,
                              children: [
                                TextView({
                                  data:
                                    child.name +
                                    (this.info.children.indexOf(child) ===
                                    this.info.children.length - 1
                                      ? ""
                                      : ",") +
                                    " (" +
                                    (child?.ageGroup ?? ""),
                                  className: "xcc5",
                                  key: "0",
                                }),
                              ],
                              className: "x502",
                              key: child?.ident,
                            }),
                          ]),
                        ],
                        key: "1",
                      }),
                    ],
                    className: "xf51",
                  })
                : [],
              Divider({ className: "x41a h", key: "19" }),
              TextView({
                data: "Lead Preferences",
                style: new ui.TextStyle({
                  fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                  fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                }),
                className: "x00d",
                key: "20",
              }),
              TextValueField({
                value: this.lead.createdDate.toString().isEmpty
                  ? " --- "
                  : this.lead.createdDate.toString(),
                label: "Date Created ",
                className: "x03a h",
                key: "21",
              }),
              TextValueWithButton({
                label: "Linkedin Profile",
                value: this.lead?.linkedInProfile ?? "",
                onOpen: () => {
                  this.onLinkedinLabelButtonHandler();
                },
                className: "x71 h",
                key: "22",
              }),
              TextValueWithButton({
                label: "Twitter Profile",
                value: this.lead?.twitterHandle ?? "",
                onOpen: () => {
                  this.onTwitterLabelHandler();
                },
                className: "x72e h",
                key: "23",
              }),
              TextValueWithButton({
                label: "Facebook Profile",
                value: this.lead?.facebookProfile ?? "",
                onOpen: () => {
                  this.onFacebookLabelHandler();
                },
                className: "x1c7 h",
                key: "24",
              }),
              TextValueCollectionView({
                label: "Languages",
                items: this.lead?.languages ?? [],
                className: "x4e5 h",
                key: "25",
              }),
              TextValueCollectionView({
                label: "Interests",
                items: this.lead?.interests ?? [],
                className: "xf3c h",
                key: "26",
              }),
              this.lead.educationHistory.isNotEmpty
                ? Divider({ className: "x92b5 h" })
                : [],
              this.lead.educationHistory.isNotEmpty
                ? TextView({
                    data: "Education Information",
                    style: new ui.TextStyle({
                      fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                      fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
                    }),
                    className: "x5a84",
                  })
                : [],
              this.lead.educationHistory.isNotEmpty
                ? this.lead.educationHistory.expand((education) => [
                    ui.Column({
                      crossAxisAlignment: ui.CrossAxisAlignment.start,
                      children: [
                        TextValueField({
                          value: education?.institution ?? "",
                          label: "Institution Name",
                          className: "x593 h",
                          key: "0",
                        }),
                        TextValueField({
                          value: education?.degree ?? "",
                          label: "Degree",
                          className: "xd7a h",
                          key: "1",
                        }),
                        TextValueField({
                          value: education?.fieldOfStudy ?? "",
                          label: "Field of Study",
                          className: "xa91 h",
                          key: "2",
                        }),
                        TextValueField({
                          value: education?.startDate.toString() ?? "",
                          label: "Graduation Start Year",
                          className: "xd2b h",
                          key: "3",
                        }),
                        TextValueField({
                          value: education?.endDate.toString() ?? "",
                          label: "Graduation End Year",
                          className: "xebb h",
                          key: "4",
                        }),
                        Divider({ className: "x4886 h", key: "5" }),
                      ],
                      className: "x1a61 h",
                      key: education?.ident,
                    }),
                  ])
                : [],
            ],
            key: "0",
          }),
          scrollDirection: ui.Axis.vertical,
          className: "x50d vc h v",
          controller: this.columnScrollController,
        }),
      ],
      className: ui.join(
        this.props.className,
        "LeadInformationWidget xa6a vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onLinkedinLabelButtonHandler = (): void => {
    Browser.open(this.lead.linkedInProfile);
  };
  public onWebsiteLabelButtonHandler = (): void => {
    Browser.open(this.lead.companyWebsite);
  };
  public onTwitterLabelHandler = (): void => {
    Browser.open(this.lead.twitterHandle);
  };
  public onFacebookLabelHandler = (): void => {
    Browser.open(this.lead.facebookProfile);
  };
}
export default function LeadInformationWidget(
  props: LeadInformationWidgetProps
) {
  return React.createElement(_LeadInformationWidgetState, {
    ..._LeadInformationWidgetState.defaultProps,
    ...props,
  });
}
