import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ScrollView2 from "./ScrollView2";
import Lead from "../models/Lead";
import TextView from "./TextView";
import NeedsCardView from "./NeedsCardView";
import PainsCardView from "./PainsCardView";
import { BuildContext } from "../classes/BuildContext";

export interface NeedsAndPainsCardViewProps extends BaseUIProps {
  key?: string;
  lead: Lead;
}

class _NeedsAndPainsCardViewState extends ObservableComponent<NeedsAndPainsCardViewProps> {
  static defaultProps = { lead: null };
  columnScrollController: ui.ScrollController = new ui.ScrollController();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: NeedsAndPainsCardViewProps) {
    super(props);

    this.initState();
  }
  public get lead(): Lead {
    return this.props.lead;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("lead", this.props.lead);

    this.on(["lead"], this.rebuild);
  }
  public componentDidUpdate(prevProps: NeedsAndPainsCardViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.lead !== this.props.lead) {
      this.updateObservable("lead", prevProps.lead, this.props.lead);

      this.fire("lead", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      mainAxisAlignment: ui.MainAxisAlignment.start,
      children: [
        TextView({
          data: "Needs & Pains",
          style: new ui.TextStyle({
            fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
            fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
          }),
          className: "xea0",
          key: "0",
        }),
        ScrollView2({
          child: ui.Column({
            children: [
              ui.Container({
                expand: true,
                child: NeedsCardView({ lead: this.lead }),
                key: "0",
                className: "xef9 vc h v",
              }),
              ui.Container({
                expand: true,
                margin: ui.EdgeInsets.fromLTRB(0.0, 10.0, 0.0, 0.0, new Map()),
                child: PainsCardView({ lead: this.lead }),
                key: "1",
                className: "x3c8 vc h v",
              }),
            ],
            key: "1",
          }),
          scrollDirection: ui.Axis.vertical,
          className: "x5f9 vc h v",
          controller: this.columnScrollController,
        }),
      ],
      className: ui.join(
        this.props.className,
        "NeedsAndPainsCardView xda18 vc h v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function NeedsAndPainsCardView(
  props: NeedsAndPainsCardViewProps
) {
  return React.createElement(_NeedsAndPainsCardViewState, {
    ..._NeedsAndPainsCardViewState.defaultProps,
    ...props,
  });
}
