import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import RoundedIconButton from "./RoundedIconButton";
import { BuildContext } from "../classes/BuildContext";

type _SuccessMessageViewOnClosePressed = () => void;

export interface SuccessMessageViewProps extends BaseUIProps {
  key?: string;
  msg: string;
  onClosePressed?: _SuccessMessageViewOnClosePressed;
}

class _SuccessMessageViewState extends ObservableComponent<SuccessMessageViewProps> {
  static defaultProps = { msg: "", onClosePressed: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: SuccessMessageViewProps) {
    super(props);

    this.initState();
  }
  public get msg(): string {
    return this.props.msg;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["msg"], this.rebuild);
  }
  public componentDidUpdate(prevProps: SuccessMessageViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.msg !== this.props.msg) {
      this.fire("msg", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.end,
      children: [
        ui.Container({ className: "xbd4 hc vc h", key: "0" }),
        ui.Row({
          children: [
            TextView({
              data: this.msg,
              style: new ui.TextStyle({
                color: cStyle.c14,
                fontWeight: ui.FontWeight.bold,
                fontSize: 14,
              }),
              className: "x21e1 vc",
              key: "0",
            }),
            ui.Container({
              margin: ui.EdgeInsets.fromLTRB(0.0, 5.0, 0.0, 0.0, new Map()),
              child: RoundedIconButton({
                icon: MaterialIcons.cancel,
                color: cStyle.c14,
                onPressed: () => {
                  this.onPressedcloseMessageHandler();
                },
              }),
              key: "1",
              className: "xa6e vc",
            }),
          ],
          className: "x35ee vc",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "SuccessMessageView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onPressedcloseMessageHandler = (): void => {
    this.onClosePressed();
  };
  public get onClosePressed(): _SuccessMessageViewOnClosePressed {
    return this.props.onClosePressed;
  }
}
export default function SuccessMessageView(props: SuccessMessageViewProps) {
  return React.createElement(_SuccessMessageViewState, {
    ..._SuccessMessageViewState.defaultProps,
    ...props,
  });
}
