import CallData from "./CallData";
import Lead from "../models/Lead";
import Interaction from "../models/Interaction";
import DBObject from "../utils/DBObject";

export default class CallInteraction extends DBObject {
  private _id: number = DBObject.nextStructId();
  private _interaction: Interaction = null;
  private _data: CallData = null;
  private _lead: Lead = null;
  public constructor(
    d3eParams?: Partial<{
      data: CallData;
      interaction: Interaction;
      lead: Lead;
    }>
  ) {
    super();

    if (d3eParams?.data) {
      this.setData(d3eParams?.data);
    }

    if (d3eParams?.interaction) {
      this.setInteraction(d3eParams?.interaction);
    }

    if (d3eParams?.lead) {
      this.setLead(d3eParams?.lead);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "CallInteraction";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get interaction(): Interaction {
    return this._interaction;
  }
  public setInteraction(val: Interaction): void {
    let isValChanged: boolean = this._interaction !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("interaction", this._interaction, val);

    this._interaction = val;

    this.fire("interaction", this);
  }
  public get data(): CallData {
    return this._data;
  }
  public setData(val: CallData): void {
    let isValChanged: boolean = this._data !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("data", this._data, val);

    this._data = val;

    this.fire("data", this);
  }
  public get lead(): Lead {
    return this._lead;
  }
  public setLead(val: Lead): void {
    let isValChanged: boolean = this._lead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("lead", this._lead, val);

    this._lead = val;

    this.fire("lead", this);
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof CallInteraction &&
        this._interaction === other._interaction &&
        this._data.equals(other._data) &&
        this._lead === other._lead)
    );
  }
  public get hashCode(): number {
    return (
      (this._interaction?.hashCode ?? 0) +
      (this._data?.hashCode ?? 0) +
      (this._lead?.hashCode ?? 0)
    );
  }
}
