import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import RecentCreatedLeads from "../classes/RecentCreatedLeads";
import RecentConnectionItemProfileView from "./RecentConnectionItemProfileView";
import Popup from "./Popup";
import MaterialIcons from "../icons/MaterialIcons";
import User from "../models/User";
import IconButton from "./IconButton";
import AddNewConnectionView from "./AddNewConnectionView";
import Query from "../classes/Query";
import MessageDispatch from "../rocket/MessageDispatch";
import ListWrapper from "../utils/ListWrapper";
import Lead from "../models/Lead";
import TextView from "./TextView";
import RecentCreatedLeadsRequest from "../models/RecentCreatedLeadsRequest";
import CollectionUtils from "../utils/CollectionUtils";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

type _RecentConnectionViewOnLeadClick = (lead: Lead) => void;

export interface RecentConnectionViewProps extends BaseUIProps {
  key?: string;
  user: User;
  onLeadClick: _RecentConnectionViewOnLeadClick;
}

class _RecentConnectionViewState extends ObservableComponent<RecentConnectionViewProps> {
  static defaultProps = { user: null, onLeadClick: null };
  idController: ui.ScrollController = new ui.ScrollController();
  lead: Lead = null;
  selectedLead: Lead = null;
  request: RecentCreatedLeadsRequest = null;
  leadsList: RecentCreatedLeads = null;
  leads: Array<Lead> = ListWrapper.widget(this, "leads");
  addConnectionPopupPopup: Popup;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: RecentConnectionViewProps) {
    super(props);

    this.initState();
  }
  public get user(): User {
    return this.props.user;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;

    this.onInit();
  }
  public initListeners(): void {
    this.updateSyncProperty("user", this.props.user);

    this.on(["request", "request.user"], this.computeLeadsList);

    this.computeLeadsList();

    this.on(["leadsList", "leadsList.items"], this.computeLeads);

    this.computeLeads();

    this.on(["leads", "selectedLead"], this.rebuild);
  }
  public componentDidUpdate(prevProps: RecentConnectionViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.user !== this.props.user) {
      this.updateObservable("user", prevProps.user, this.props.user);

      this.fire("user", this);
    }
  }
  public setLead(val: Lead): void {
    let isValChanged: boolean = this.lead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("lead", this.lead, val);

    this.lead = val;

    this.fire("lead", this);
  }
  public setSelectedLead(val: Lead): void {
    let isValChanged: boolean = this.selectedLead !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("selectedLead", this.selectedLead, val);

    this.selectedLead = val;

    this.fire("selectedLead", this);
  }
  public setRequest(val: RecentCreatedLeadsRequest): void {
    let isValChanged: boolean = this.request !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("request", this.request, val);

    this.request = val;

    this.fire("request", this);
  }
  public setLeadsList(val: RecentCreatedLeads): void {
    let isValChanged: boolean = this.leadsList !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("leadsList", this.leadsList, val);

    MessageDispatch.get().dispose(this.leadsList);

    this.leadsList = val;

    this.fire("leadsList", this);
  }
  public computeLeadsList = async (): Promise<void> => {
    try {
      this.setLeadsList(
        await Query.get().getRecentCreatedLeads(
          UsageConstants.QUERY_GETRECENTCREATEDLEADS_RECENTCONNECTIONVIEW_PROPERTIES_LEADSLIST_COMPUTATION,
          this.request,
          { "synchronize": true }
        )
      );
    } catch (exception) {
      console.log(" exception in computeLeadsList : " + exception.toString());

      this.setLeadsList(null);
    }
  };
  public setLeads(val: Array<Lead>): void {
    let isValChanged: boolean = CollectionUtils.isNotEquals(this.leads, val);

    if (!isValChanged) {
      return;
    }

    this.updateObservableColl("leads", this.leads, val);

    this.leads.clear();

    this.leads.addAll(val);

    this.fire("leads", this);
  }
  public addToLeads(val: Lead, index: number = -1): void {
    if (index === -1) {
      if (!this.leads.contains(val)) this.leads.add(val);
    } else {
      this.leads.remove(this.leads.elementAt(index));

      this.leads.add(val);
    }

    this.fire("leads", this, val, true);

    this.updateObservable("leads", null, val);
  }
  public removeFromLeads(val: Lead): void {
    this.leads.remove(val);

    this.fire("leads", this, val, false);

    this.removeObservable("leads", val);
  }
  public computeLeads = (): void => {
    try {
      this.setLeads(
        Array.from(this.leadsList.items.isNotEmpty ? this.leadsList.items : [])
      );
    } catch (exception) {
      console.log(" exception in computeLeads : " + exception.toString());

      this.setLeads([]);
    }
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Column({
      crossAxisAlignment: ui.CrossAxisAlignment.start,
      children: [
        ui.Row({
          mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
          children: [
            TextView({
              data: "Recent Connections",
              style: new ui.TextStyle({
                fontSize: cStyle.tTextViewHeadlineFourFontSizeOn,
                fontWeight: cStyle.tTextViewHeadlineFourFontWeightOn,
              }),
              className: "x0b6",
              key: "0",
            }),
            IconButton({
              icon: MaterialIcons.add,
              tooltip: "Add Connection",
              onPressed: () => {
                this.onIconButtonClick();
              },
              key: "1",
            }),
          ],
          className: "xa6b",
          key: "0",
        }),
        ui.Container({ className: "xab8 vc", key: "1" }),
        ui.ListView({
          controller: this.idController,
          children: [
            this.leads.expand((item) => [
              ui.Container({
                margin: ui.EdgeInsets.fromLTRB(0.0, 3.0, 0.0, 12.0, new Map()),
                child: RecentConnectionItemProfileView({
                  lead: item,
                  selectedLead: this.selectedLead,
                  onLeadClick: (lead) => {
                    this.onRecentLeadButtonPressed(lead, item);
                  },
                }),
                key: item?.ident,
                className: "xeb4",
              }),
            ]),
          ],
          className: "x917 vc v",
          key: "2",
        }),
      ],
      className: ui.join(
        this.props.className,
        "RecentConnectionView xf36 vc v"
      ),
      ...copyBaseUIProps(this.props),
    });
  }
  public onInit = (): void => {
    this.setRequest(new RecentCreatedLeadsRequest({ user: this.user }));
  };
  public onIconButtonClick = (): void => {
    this.setLead(new Lead({ createdBy: this.user }));

    this.showAddConnectionPopup();
  };
  public onRecentLeadButtonPressed = (lead: Lead, item: Lead): void => {
    this.onLeadClick(item);

    this.setSelectedLead(item);
  };
  public get onLeadClick(): _RecentConnectionViewOnLeadClick {
    return this.props.onLeadClick;
  }
  public dispose(): void {
    MessageDispatch.get().dispose(this.leadsList);

    this.addConnectionPopupPopup?.dispose();

    super.dispose();
  }
  public showAddConnectionPopup(
    d3eParams?: Partial<{
      autoClose: boolean;
      model: boolean;
      float: boolean;
      takeFocus: boolean;
    }>
  ): void {
    let autoClose = d3eParams?.autoClose;

    let model = d3eParams?.model;

    let float = d3eParams?.float;

    let takeFocus = d3eParams?.takeFocus;

    this.addConnectionPopupPopup?.dispose();

    this.addConnectionPopupPopup = new Popup({
      autoClose: autoClose,
      model: model,
      float: float,
      takeFocus: takeFocus,
      position: ui.PopUpPosition.Center,
      child: ui.Container({
        width: 550,
        child: AddNewConnectionView({ lead: this.lead, user: this.user }),
        className: "x7f2 hc vc",
      }),
    });

    this.addConnectionPopupPopup.showPopup(this.context);
  }
  public hideAddConnectionPopup(): void {
    this.addConnectionPopupPopup?.dispose();
  }
}
export default function RecentConnectionView(props: RecentConnectionViewProps) {
  return React.createElement(_RecentConnectionViewState, {
    ..._RecentConnectionViewState.defaultProps,
    ...props,
  });
}
