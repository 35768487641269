import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import CallStatus from "../classes/CallStatus";
import Button from "./Button";
import D3EDisposable from "../rocket/D3EDisposable";
import InteractionType from "../classes/InteractionType";
import TextView from "./TextView";
import Interaction from "../models/Interaction";
import LeadUtils from "../classes/LeadUtils";
import MessageDispatch from "../rocket/MessageDispatch";
import Query from "../classes/Query";
import { UsageConstants } from "../rocket/D3ETemplate";
import { BuildContext } from "../classes/BuildContext";

export interface ProfileWidgetProps extends BaseUIProps {
  key?: string;
  interaction: Interaction;
}
/// To store state data for ProfileWidget
class ProfileWidgetRefs {
  public button: ButtonState = new ButtonState();
}

interface ButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: ProfileWidgetRefs;
}

class ButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _ButtonWithState extends ObservableComponent<ButtonWithStateProps> {
  buttonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: ButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get button(): ButtonState {
    return this.props.d3eState.button;
  }
  public get d3eState(): ProfileWidgetRefs {
    return this.props.d3eState;
  }
  public initState() {
    super.initState();

    this.updateObservable("button", null, this.button);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["button", "button.", "button.hover"], this.rebuild);
  }
  public buttonOnEnter(event): void {
    return this.button.setHover(true);
  }
  public buttonOnExit(event): void {
    return this.button.setHover(false);
  }
  public dispose(): void {
    this.button.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return Button({
      disable: this.button.disable,
      onFocusChange: (val) => {},
      child: TextView({ data: "An Important Email" }),
      onEnter: (event) => {
        this.buttonOnEnter(event);
      },
      onExit: (event) => {
        this.buttonOnExit(event);
      },
      className: "x7c8",
    });
  }
}
function ButtonWithState(props: ButtonWithStateProps) {
  return React.createElement(_ButtonWithState, props);
}

class _ProfileWidgetState extends ObservableComponent<ProfileWidgetProps> {
  static defaultProps = { interaction: null };
  d3eState: ProfileWidgetRefs = new ProfileWidgetRefs();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public interactionDisposable: D3EDisposable;
  public constructor(props: ProfileWidgetProps) {
    super(props);

    this.initState();
  }
  public get interaction(): Interaction {
    return this.props.interaction;
  }
  public initState() {
    super.initState();

    this.interactionDisposable = MessageDispatch.get().syncObject(
      this.props.interaction,
      UsageConstants.SUBSCRIPTION_ONINTERACTIONCHANGE_PROFILEWIDGET_INTERACTION_SYNCHRONISE
    );

    this.runFetchDataQueryForInteraction();

    this.interactionDisposable = MessageDispatch.get().syncObject(
      this.props.interaction,
      UsageConstants.QUERY_GETINTERACTIONBYID_PROFILEWIDGET_INTERACTION_FETCHDATA
    );

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.updateSyncProperty("interaction", this.props.interaction);

    this.on(
      [
        "interaction",
        "interaction.callStatus",
        "interaction.createdDate",
        "interaction.isIncoming",
        "interaction.type",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: ProfileWidgetProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.interaction !== this.props.interaction) {
      this.updateObservable(
        "interaction",
        prevProps.interaction,
        this.props.interaction
      );

      this.runFetchDataQueryForInteraction();

      this.fire("interaction", this);
    }
  }
  public runFetchDataQueryForInteraction = (): void => {
    if (this.interaction == null) {
      return;
    }

    Query.get().getInteractionById(
      UsageConstants.QUERY_GETINTERACTIONBYID_PROFILEWIDGET_INTERACTION_FETCHDATA,
      this.interaction.id
    );
  };
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.spaceBetween,
      children: [
        ui.Column({
          children: [
            ui.Row({
              mainAxisAlignment: this.interaction.isIncoming
                ? ui.MainAxisAlignment.start
                : ui.MainAxisAlignment.end,
              children: [
                TextView({
                  data:
                    this.interaction.createdDate !== null
                      ? LeadUtils.toGMTFromDateTime(
                          this.interaction.createdDate,
                          { format: "EEE, MMM y HH:mm" }
                        )
                      : "",
                  style: new ui.TextStyle({
                    color: cStyle.c19,
                    fontSize: 9,
                    fontWeight: cStyle.tTextViewHeadlineSixFontWeightOn,
                  }),
                  className: "x1b5",
                  key: "0",
                }),
                this.interaction.type === InteractionType.Call
                  ? TextView({
                      data:
                        this.interaction.callStatus === CallStatus.Cancelled
                          ? "Missed Call"
                          : this.interaction.callStatus.name,
                      states: ui.joinStates(
                        {
                          "data-c0":
                            this.interaction.callStatus ===
                            CallStatus.Initiated,
                          "data-c1":
                            this.interaction.callStatus ===
                            CallStatus.InProgress,
                          "data-c2":
                            this.interaction.callStatus === CallStatus.Ringing,
                          "data-c3":
                            this.interaction.callStatus === CallStatus.Answered,
                          "data-c4":
                            this.interaction.callStatus ===
                            CallStatus.Completed,
                          "data-c5":
                            this.interaction.callStatus === CallStatus.Failed,
                          "data-c6":
                            this.interaction.callStatus === CallStatus.Busy,
                          "data-c7":
                            this.interaction.callStatus ===
                            CallStatus.Unanswered,
                          "data-c8":
                            this.interaction.callStatus === CallStatus.Unknown,
                          "data-c9":
                            this.interaction.callStatus ===
                            CallStatus.Connecting,
                          "data-c10":
                            this.interaction.callStatus ===
                            CallStatus.Cancelled,
                          "data-c11":
                            this.interaction.callStatus === CallStatus.Rejected,
                        },
                        {}
                      ),
                      style: new ui.TextStyle({
                        fontWeight: ui.FontWeight.w500,
                        color:
                          this.interaction.callStatus === CallStatus.Initiated
                            ? cStyle.c10
                            : this.interaction.callStatus ===
                              CallStatus.InProgress
                            ? cStyle.c10
                            : this.interaction.callStatus === CallStatus.Ringing
                            ? cStyle.c10
                            : this.interaction.callStatus ===
                              CallStatus.Answered
                            ? cStyle.c1
                            : this.interaction.callStatus ===
                              CallStatus.Completed
                            ? cStyle.c1
                            : this.interaction.callStatus === CallStatus.Failed
                            ? cStyle.c10
                            : this.interaction.callStatus === CallStatus.Busy
                            ? cStyle.c10
                            : this.interaction.callStatus ===
                              CallStatus.Unanswered
                            ? cStyle.c10
                            : this.interaction.callStatus === CallStatus.Unknown
                            ? cStyle.c10
                            : this.interaction.callStatus ===
                              CallStatus.Connecting
                            ? cStyle.c10
                            : this.interaction.callStatus ===
                              CallStatus.Cancelled
                            ? cStyle.c10
                            : this.interaction.callStatus ===
                              CallStatus.Rejected
                            ? cStyle.c10
                            : null,
                      }),
                      className: "x49d",
                    })
                  : [],
              ],
              className: "x0d69 hc",
              key: "0",
            }),
            this.interaction.type === InteractionType.Email
              ? ui.Column({
                  crossAxisAlignment: ui.CrossAxisAlignment.start,
                  mainAxisAlignment: ui.MainAxisAlignment.start,
                  children: [
                    TextView({
                      data: "Mail Recieved",
                      className: "x98",
                      key: "0",
                    }),
                    TextView({
                      data: "Mailchimp Legal",
                      className: "x704",
                      key: "1",
                    }),
                    ButtonWithState({ d3eState: this.d3eState, key: "2" }),
                    TextView({
                      data: "Hello, I am writing to you to inform you about the new updates in our company. Please check the attachment for more details.",
                      className: "x370",
                      key: "3",
                    }),
                  ],
                  className: "xd60",
                })
              : [],
          ],
          key: "0",
        }),
      ],
      className: ui.join(this.props.className, "ProfileWidget"),
      ...copyBaseUIProps(this.props),
    });
  }
  public dispose(): void {
    this.interactionDisposable?.dispose();

    super.dispose();
  }
  public get button() {
    return this.d3eState.button;
  }
}
export default function ProfileWidget(props: ProfileWidgetProps) {
  return React.createElement(_ProfileWidgetState, {
    ..._ProfileWidgetState.defaultProps,
    ...props,
  });
}
