import DataPoint from "./DataPoint";
import DataSet from "./DataSet";
import LeadStatus from "./LeadStatus";
import Lead from "../models/Lead";

export default class ChartDataHelper {
  public constructor() {}
  public static async getStatusBasedChartData(
    leads: Array<Lead>
  ): Promise<Array<DataSet>> {
    //  Status counts

    let newCount: number = leads
      .where((c) => c.status.name === "New")
      .length.toDouble();

    let inProgressCount: number = leads
      .where((c) => c.status.name === "Inprogress")
      .length.toDouble();

    let contactedCount: number = leads
      .where((c) => c.status.name === "Contacted")
      .length.toDouble();

    let closedCount: number = leads
      .where((c) => c.status.name === "Closed")
      .length.toDouble();

    //  Prepare chart data based on status

    let data: Array<DataSet> = [];

    data.add(
      new DataSet({
        name: "New",
        data: [new DataPoint({ value: newCount })],
        color: "ffe5a000",
      })
    );

    data.add(
      new DataSet({
        name: "In Progress",
        data: [new DataPoint({ value: inProgressCount })],
        color: "ffd732a8",
      })
    );

    data.add(
      new DataSet({
        name: "Contacted",
        data: [new DataPoint({ value: contactedCount })],
        color: "ff6c73ff",
      })
    );

    data.add(
      new DataSet({
        name: "Closed",
        data: [new DataPoint({ value: closedCount })],
        color: "ff14ae5c",
      })
    );

    return data;
  }
  public static getCount(dataSets: Array<DataSet>): string {
    let count: string = "";

    for (let dataSet of dataSets.where((c) => c.data.isNotEmpty)) {
      count = count + dataSet.data[0].value.toString() + ", ";
    }

    return count.toString();
  }
}
