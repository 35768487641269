import CallStatus from "./CallStatus";
import DBObject from "../utils/DBObject";

export default class CallData extends DBObject {
  private _id: number = DBObject.nextStructId();
  private _callSid: string = "";
  private _deviceReady: boolean = false;
  private _from: string = "";
  private _to: string = "";
  private _callStatus: CallStatus = CallStatus.Initiated;
  private _connecting: boolean = false;
  public constructor(
    d3eParams?: Partial<{
      callSid: string;
      callStatus: CallStatus;
      connecting: boolean;
      deviceReady: boolean;
      from: string;
      to: string;
    }>
  ) {
    super();

    if (d3eParams?.callSid) {
      this.setCallSid(d3eParams?.callSid);
    }

    if (d3eParams?.callStatus) {
      this.setCallStatus(d3eParams?.callStatus);
    }

    if (d3eParams?.connecting) {
      this.setConnecting(d3eParams?.connecting);
    }

    if (d3eParams?.deviceReady) {
      this.setDeviceReady(d3eParams?.deviceReady);
    }

    if (d3eParams?.from) {
      this.setFrom(d3eParams?.from);
    }

    if (d3eParams?.to) {
      this.setTo(d3eParams?.to);
    }
  }
  public get id(): number {
    return this._id;
  }
  public set id(id: number) {
    this._id = id;
  }
  public get d3eType(): string {
    return "CallData";
  }
  public clear(): void {}
  public initListeners(): void {
    super.initListeners();
  }
  public get callSid(): string {
    return this._callSid;
  }
  public setCallSid(val: string): void {
    let isValChanged: boolean = this._callSid !== val;

    if (!isValChanged) {
      return;
    }

    this._callSid = val;

    this.fire("callSid", this);
  }
  public get deviceReady(): boolean {
    return this._deviceReady;
  }
  public setDeviceReady(val: boolean): void {
    let isValChanged: boolean = this._deviceReady !== val;

    if (!isValChanged) {
      return;
    }

    this._deviceReady = val;

    this.fire("deviceReady", this);
  }
  public get from(): string {
    return this._from;
  }
  public setFrom(val: string): void {
    let isValChanged: boolean = this._from !== val;

    if (!isValChanged) {
      return;
    }

    this._from = val;

    this.fire("from", this);
  }
  public get to(): string {
    return this._to;
  }
  public setTo(val: string): void {
    let isValChanged: boolean = this._to !== val;

    if (!isValChanged) {
      return;
    }

    this._to = val;

    this.fire("to", this);
  }
  public get callStatus(): CallStatus {
    return this._callStatus;
  }
  public setCallStatus(val: CallStatus): void {
    let isValChanged: boolean = this._callStatus !== val;

    if (!isValChanged) {
      return;
    }

    this._callStatus = val;

    this.fire("callStatus", this);
  }
  public get connecting(): boolean {
    return this._connecting;
  }
  public setConnecting(val: boolean): void {
    let isValChanged: boolean = this._connecting !== val;

    if (!isValChanged) {
      return;
    }

    this._connecting = val;

    this.fire("connecting", this);
  }
  public equals(other: any): boolean {
    return (
      this === other ||
      (other instanceof CallData &&
        this._callSid === other._callSid &&
        this._deviceReady === other._deviceReady &&
        this._from === other._from &&
        this._to === other._to &&
        this._callStatus === other._callStatus &&
        this._connecting === other._connecting)
    );
  }
  public get hashCode(): number {
    return (
      (this._callSid?.hashCode ?? 0) +
      (this._deviceReady?.hashCode ?? 0) +
      (this._from?.hashCode ?? 0) +
      (this._to?.hashCode ?? 0) +
      (this._callStatus?.hashCode ?? 0) +
      (this._connecting?.hashCode ?? 0)
    );
  }
}
