import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import { BuildContext } from "../classes/BuildContext";

export interface DividerProps extends BaseUIProps {
  key?: string;
}

class _DividerState extends ObservableComponent<DividerProps> {
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: DividerProps) {
    super(props);

    this.initState();
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {}
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      children: [ui.Container({ className: "x1c2 hc vc h", key: "0" })],
      className: ui.join(this.props.className, "Divider x214"),
      ...copyBaseUIProps(this.props),
    });
  }
}
export default function Divider(props: DividerProps) {
  return React.createElement(_DividerState, props);
}
