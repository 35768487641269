import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import MaterialIcons from "../icons/MaterialIcons";
import TextView from "./TextView";
import RoundedIconButton from "./RoundedIconButton";
import { BuildContext } from "../classes/BuildContext";

type _InfoMessageViewOnClosePressed = () => void;

export interface InfoMessageViewProps extends BaseUIProps {
  key?: string;
  message: string;
  onClosePressed?: _InfoMessageViewOnClosePressed;
}

class _InfoMessageViewState extends ObservableComponent<InfoMessageViewProps> {
  static defaultProps = { message: "", onClosePressed: null };
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: InfoMessageViewProps) {
    super(props);

    this.initState();
  }
  public get message(): string {
    return this.props.message;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["message"], this.rebuild);
  }
  public componentDidUpdate(prevProps: InfoMessageViewProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.message !== this.props.message) {
      this.fire("message", this);
    }
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Row({
      mainAxisAlignment: ui.MainAxisAlignment.end,
      children: [
        ui.Container({ className: "x7ae hc vc h", key: "0" }),
        ui.Row({
          children: [
            TextView({
              data: this.message,
              style: new ui.TextStyle({
                color: cStyle.c14,
                fontWeight: ui.FontWeight.bold,
                fontSize: 14,
              }),
              className: "x78ca vc",
              key: "0",
            }),
            ui.Container({
              margin: ui.EdgeInsets.fromLTRB(0.0, 0.0, 5.0, 0.0, new Map()),
              child: RoundedIconButton({
                icon: MaterialIcons.info,
                color: cStyle.c14,
                onPressed: () => {
                  this.onCloseMessage();
                },
              }),
              key: "1",
              className: "x3a0 vc",
            }),
          ],
          className: "xff88 vc",
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "InfoMessageView hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public onCloseMessage = (): void => {
    this.onClosePressed();
  };
  public get onClosePressed(): _InfoMessageViewOnClosePressed {
    return this.props.onClosePressed;
  }
}
export default function InfoMessageView(props: InfoMessageViewProps) {
  return React.createElement(_InfoMessageViewState, {
    ..._InfoMessageViewState.defaultProps,
    ...props,
  });
}
