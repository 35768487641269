import React from "react";
import { ReactNode } from "react";
import ObservableState from "../utils/ObservableState";
import * as ui from "../native";
import ObservableComponent from "./ObservableComponent";
import BaseUIProps, { copyBaseUIProps } from "../native/ui/BaseUIProps";
import ObjectObservable from "../utils/ObjectObservable";
import Button from "./Button";
import ListWrapper from "../utils/ListWrapper";
import SuccessMessage from "../classes/SuccessMessage";
import EventBus from "../utils/EventBus";
import User from "../models/User";
import TextView from "./TextView";
import PageNavigator from "../classes/PageNavigator";
import PhoneNumberDetails from "../classes/PhoneNumberDetails";
import LabelDropdown from "./LabelDropdown";
import RPCServices from "../rpc/RPCServices";
import PopupWrapperView from "./PopupWrapperView";
import { BuildContext } from "../classes/BuildContext";

type _AssignTwiloNumberOnReload = () => void;

type _CancelButtonOnPressed = (d3eState: AssignTwiloNumberRefs) => void;

type _UserButtonOnPressed = (d3eState: AssignTwiloNumberRefs) => void;

type _UserDropdownOnChanged = (
  text: User,
  d3eState: AssignTwiloNumberRefs
) => void;

export interface AssignTwiloNumberProps extends BaseUIProps {
  key?: string;
  activeNumbersUsers: Array<User>;
  obj: PhoneNumberDetails;
  _activeNumbersUsersHash?: number;
  onReload: _AssignTwiloNumberOnReload;
}
/// To store state data for AssignTwiloNumber
class AssignTwiloNumberRefs {
  public cancelButton: CancelButtonState = new CancelButtonState();
  public userButton: UserButtonState = new UserButtonState();
}

interface UserButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AssignTwiloNumberRefs;
  _onAddUserHandler?: _UserButtonOnPressed;
}

class UserButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _UserButtonWithState extends ObservableComponent<UserButtonWithStateProps> {
  userButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: UserButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get userButton(): UserButtonState {
    return this.props.d3eState.userButton;
  }
  public get d3eState(): AssignTwiloNumberRefs {
    return this.props.d3eState;
  }
  public get _onAddUserHandler(): _UserButtonOnPressed {
    return this.props._onAddUserHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("userButton", null, this.userButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(["userButton", "userButton.", "userButton.hover"], this.rebuild);
  }
  public userButtonOnEnter(event): void {
    return this.userButton.setHover(true);
  }
  public userButtonOnExit(event): void {
    return this.userButton.setHover(false);
  }
  public dispose(): void {
    this.userButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      child: Button({
        padding: this.userButton.hover
          ? cStyle.tButtonPrimaryPaddingOnHover
          : cStyle.tButtonPrimaryPaddingOn,
        decoration: this.userButton.hover
          ? cStyle.tButtonPrimaryDecorationOnHover
          : cStyle.tButtonPrimaryDecorationOn,
        disable: this.userButton.disable,
        onPressed: () => {
          this._onAddUserHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Confirm" }),
        onEnter: (event) => {
          this.userButtonOnEnter(event);
        },
        onExit: (event) => {
          this.userButtonOnExit(event);
        },
      }),
      className: "xda2",
    });
  }
}
function UserButtonWithState(props: UserButtonWithStateProps) {
  return React.createElement(_UserButtonWithState, props);
}

interface CancelButtonWithStateProps extends BaseUIProps {
  key?: string;
  d3eState: AssignTwiloNumberRefs;
  _cancelButtonHandler?: _CancelButtonOnPressed;
}

class CancelButtonState extends ObjectObservable {
  private _disable: boolean = false;
  public _hover: boolean = false;
  public get disable(): boolean {
    return this._disable;
  }
  public setDisable(val: boolean) {
    let isValChanged: boolean = this._disable !== val;

    if (!isValChanged) {
      return;
    }

    this._disable = val;

    this.fire("disable", this);
  }
  public get hover(): boolean {
    return this._hover;
  }
  public setHover(val: boolean) {
    let isValChanged: boolean = this._hover !== val;

    if (!isValChanged) {
      return;
    }

    this._hover = val;

    this.fire("hover", this);
  }
}

class _CancelButtonWithState extends ObservableComponent<CancelButtonWithStateProps> {
  cancelButtonFocusNode: ui.FocusNode = new ui.FocusNode();
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: CancelButtonWithStateProps) {
    super(props);

    this.initState();
  }
  public get cancelButton(): CancelButtonState {
    return this.props.d3eState.cancelButton;
  }
  public get d3eState(): AssignTwiloNumberRefs {
    return this.props.d3eState;
  }
  public get _cancelButtonHandler(): _CancelButtonOnPressed {
    return this.props._cancelButtonHandler;
  }
  public initState() {
    super.initState();

    this.updateObservable("cancelButton", null, this.cancelButton);

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.on(
      ["cancelButton", "cancelButton.", "cancelButton.hover"],
      this.rebuild
    );
  }
  public cancelButtonOnEnter(event): void {
    return this.cancelButton.setHover(true);
  }
  public cancelButtonOnExit(event): void {
    return this.cancelButton.setHover(false);
  }
  public dispose(): void {
    this.cancelButton.setHover(false);

    super.dispose();
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return ui.Container({
      expand: true,
      child: Button({
        padding: this.cancelButton.hover
          ? cStyle.tButtonSecondaryPaddingOnHover
          : cStyle.tButtonSecondaryPaddingOn,
        decoration: this.cancelButton.hover
          ? cStyle.tButtonSecondaryDecorationOnHover
          : cStyle.tButtonSecondaryDecorationOn,
        disable: this.cancelButton.disable,
        onPressed: () => {
          this._cancelButtonHandler(this.d3eState);
        },
        onFocusChange: (val) => {},
        child: TextView({ data: "Cancel" }),
        onEnter: (event) => {
          this.cancelButtonOnEnter(event);
        },
        onExit: (event) => {
          this.cancelButtonOnExit(event);
        },
      }),
      className: "x480",
    });
  }
}
function CancelButtonWithState(props: CancelButtonWithStateProps) {
  return React.createElement(_CancelButtonWithState, props);
}

class _AssignTwiloNumberState extends ObservableComponent<AssignTwiloNumberProps> {
  static defaultProps = { obj: null, activeNumbersUsers: [], onReload: null };
  d3eState: AssignTwiloNumberRefs = new AssignTwiloNumberRefs();
  user: User = null;
  static contextType = BuildContext;
  context: React.ContextType<typeof BuildContext>;
  public constructor(props: AssignTwiloNumberProps) {
    super(props);

    this.initState();
  }
  public get activeNumbersUsers(): Array<User> {
    return this.props.activeNumbersUsers;
  }
  public get obj(): PhoneNumberDetails {
    return this.props.obj;
  }
  public initState() {
    super.initState();

    this.initListeners();

    this.enableBuild = true;
  }
  public initListeners(): void {
    this.subscribeToList(this.activeNumbersUsers, "activeNumbersUsers");

    this.updateSyncCollProperty(
      "activeNumbersUsers",
      this.props.activeNumbersUsers
    );

    this.updateSyncProperty("obj", this.props.obj);

    this.on(
      [
        "activeNumbersUsers",
        "activeNumbersUsers.firstName",
        "activeNumbersUsers.lastName",
        "activeNumbersUsers.twilioNumber",
        "user",
      ],
      this.rebuild
    );
  }
  public componentDidUpdate(prevProps: AssignTwiloNumberProps): void {
    super.componentDidUpdate(prevProps);

    if (prevProps.activeNumbersUsers !== this.props.activeNumbersUsers) {
      this.updateObservableColl(
        "activeNumbersUsers",
        prevProps.activeNumbersUsers,
        this.props.activeNumbersUsers
      );

      this.fire("activeNumbersUsers", this);
    }

    if (prevProps.obj !== this.props.obj) {
      this.updateObservable("obj", prevProps.obj, this.props.obj);

      this.fire("obj", this);
    }
  }
  public setUser(val: User): void {
    let isValChanged: boolean = this.user !== val;

    if (!isValChanged) {
      return;
    }

    this.updateObservable("user", this.user, val);

    this.user = val;

    this.fire("user", this);
  }
  public render(): ReactNode {
    let cStyle = this.context.theme;

    return PopupWrapperView({
      title: "Assign Twilio Number To User",
      content: ui.Column({
        children: [
          LabelDropdown<User>({
            name: "Assign Number",
            placeHolder: "---Select User---",
            value: this.user,
            items: this.activeNumbersUsers.where(
              (u) => u.twilioNumber === null || u.twilioNumber === ""
            ),
            width: 340,
            onChanged: (text) => {
              this.userDropdownonChanged(text, this.d3eState);
            },
            builder: (context, item) => {
              return TextView({
                data: item.firstName + " " + (item?.lastName ?? ""),
              });
            },
            className: "x88b hc h",
            key: "0",
          }),
        ],
        className: "x1d hc h",
      }),
      buttons: [
        CancelButtonWithState({
          d3eState: this.d3eState,
          _cancelButtonHandler: this.cancelButtonHandler,
          key: "0",
        }),
        UserButtonWithState({
          d3eState: this.d3eState,
          _onAddUserHandler: this.onAddUserHandler,
          key: "1",
        }),
      ],
      className: ui.join(this.props.className, "AssignTwiloNumber hc vc"),
      ...copyBaseUIProps(this.props),
    });
  }
  public cancelButtonHandler = (d3eState: AssignTwiloNumberRefs): void => {
    this.navigator.close();
  };
  public onAddUserHandler = async (
    d3eState: AssignTwiloNumberRefs
  ): Promise<void> => {
    let configureNumberWebhooks: boolean =
      await RPCServices.getCallService().configureNumberWebhooks(
        this.user,
        this.obj.phoneNumber,
        this.obj.sid,
        this.user.company.messageServiceId
      );

    if (configureNumberWebhooks) {
      EventBus.get().fire(
        new SuccessMessage({ message: "Number configured successfully" })
      );

      this.onReload();

      this.navigator.close();
    }
  };
  public get onReload(): _AssignTwiloNumberOnReload {
    return this.props.onReload;
  }
  public userDropdownonChanged = (
    val: User,
    d3eState: AssignTwiloNumberRefs
  ): void => {
    this.setUser(val);
  };
  public get navigator(): PageNavigator {
    return PageNavigator.of(this.context);
  }
  public get cancelButton() {
    return this.d3eState.cancelButton;
  }
  public get userButton() {
    return this.d3eState.userButton;
  }
}
export default function AssignTwiloNumber(props: AssignTwiloNumberProps) {
  return React.createElement(
    _AssignTwiloNumberState,
    { ..._AssignTwiloNumberState.defaultProps, ...props },
    ListWrapper.fromInput<User>(props.activeNumbersUsers, "activeNumbersUsers")
  );
}
